export enum ResellerSiderItem {
  MY_RECEPTIONISTS = 'My Receptionists',
  TRIAL_RECEPTIONIST = 'Trial Receptionist',
  EMBED = 'Embed',
  BRANDING = 'Branding',
  TUTORIALS = 'Tutorials',
  SUBSCRIPTION = 'Subscription',
  PHONE_NUMBERS = 'Phone Numbers',
  IMPORT_NUMBER = 'Import Twilio',
  ACADEMY = 'Academy',
  OUTBOUND_ROOT = 'Outbound',
  OUTBOUND_MY_CAMPAIGNS = 'My Campaigns',
  OUTBOUND_BYOT = 'BYOT',
}
