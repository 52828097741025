import { CallLog, TextLog, VoiceMailLog } from '@/interfaces/ILogs';
import { LastEvaluatedKey } from '@/types/TCRM';
import apiClient from '@/utils/apiClient';

const CALL_LOGS_API_PATH = '/api/v2/receptionist-dashboard/call-logs';
const GET_CALL_RECORDING_API_PATH = '/api/v2/receptionist-dashboard/call-recording';
const USER_TIMEZONE_API_PATH = '/api/v2/receptionist-dashboard/user-timezone';
const TEXT_LOGS_API_PATH = '/api/v2/receptionist-dashboard/text-logs';
const VOICEMAIL_LOGS_API_PATH = '/api/v2/receptionist-dashboard/voicemail-logs';
const DOWNLOAD_CALL_LOGS = '/api/v2/receptionist-dashboard/download/call-logs';
const CALL_LOG_BY_CALL_SID = '/api/v2/receptionist-dashboard/call-log';
export const getCallLogs = async (queryParam: {
  limit: number | null;
  lastKey?: LastEvaluatedKey;
  startDate?: number | null;
  endDate?: number | null;
  from?: string | null;
}): Promise<{
  callLogs: CallLog[];
  lastKey: LastEvaluatedKey | undefined;
}> => {
  try {
    let queryParamString = '?';

    if (queryParam.limit) {
      queryParamString += `limit=${queryParam.limit}`;
    }

    if (queryParam.lastKey) {
      queryParamString += `&lastKey=${JSON.stringify(queryParam.lastKey)}`;
    }

    if (queryParam.startDate) {
      queryParamString += `&startDate=${queryParam.startDate}`;
    }

    if (queryParam.endDate) {
      queryParamString += `&endDate=${queryParam.endDate}`;
    }

    if (queryParam.from) {
      queryParamString += `&from=${encodeURIComponent(queryParam.from)}`;
    }

    const response = await apiClient.request({
      method: 'GET',
      endPoint: `${CALL_LOGS_API_PATH}${queryParamString}`,
    });

    return {
      lastKey: response.lastEvaluatedKey,
      callLogs: response.callLogs,
    };
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};

export const getCallRecording = async (callSid: string) => {
  const response = await apiClient.request({
    method: 'GET',
    endPoint: `${GET_CALL_RECORDING_API_PATH}/${callSid}`,
    isBlobResponse: true,
  });

  const blob = new Blob([response], { type: 'audio/mpeg' });
  const url = URL.createObjectURL(blob);

  return { callSid, url };
};

export const deleteCallLog = async ({
  callSid,
  date,
}: {
  callSid: string;
  date: string;
}): Promise<{ success: boolean }> => {
  try {
    const response = await apiClient.request({
      method: 'DELETE',
      endPoint: `${CALL_LOGS_API_PATH}/${callSid}?date=${date}`,
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};

export const updateCallLog = async ({
  callSid,
  date,
  data,
}: {
  callSid: string;
  date: string;
  data: any;
}): Promise<{ success: boolean; data: any }> => {
  try {
    const response = await apiClient.request({
      method: 'PUT',
      endPoint: `${CALL_LOGS_API_PATH}/${callSid}?date=${date}`,
      body: data,
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};

export const updateUserTimeZone = async (payload: { timeZone: string }): Promise<{ success: boolean }> => {
  try {
    const response = await apiClient.request({
      method: 'PUT',
      endPoint: USER_TIMEZONE_API_PATH,
      body: payload,
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};

export const getTextLogs = async (): Promise<{ textLogs: TextLog[] }> => {
  try {
    const response = await apiClient.request({
      method: 'GET',
      endPoint: TEXT_LOGS_API_PATH,
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};

export const deleteTextLog = async (payload: { textLogDate: string }): Promise<{ success: boolean }> => {
  try {
    const response = await apiClient.request({
      method: 'DELETE',
      endPoint: TEXT_LOGS_API_PATH,
      body: payload,
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};

export const getVoiceMailLogs = async (): Promise<{ voiceMailLogs: VoiceMailLog[] }> => {
  try {
    const response = await apiClient.request({
      method: 'GET',
      endPoint: VOICEMAIL_LOGS_API_PATH,
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};

export const deleteVoiceMailLog = async (payload: { voiceMailLogDate: string }): Promise<{ success: boolean }> => {
  try {
    const response = await apiClient.request({
      method: 'DELETE',
      endPoint: VOICEMAIL_LOGS_API_PATH,
      body: payload,
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};

export const fetchCallInformation = async (shareableLink: string): Promise<{ data: CallLog }> => {
  try {
    const response = await apiClient.request({
      method: 'GET',
      endPoint: `${CALL_LOGS_API_PATH}/${shareableLink}`,
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};

export const downloadCallLogs = async (queryParam: {
  startDate?: number | null;
  endDate?: number | null;
  isNoviUser: boolean;
}): Promise<void> => {
  try {
    let queryParamString = '?';

    if (queryParam.startDate) {
      queryParamString += `&startDate=${queryParam.startDate}`;
    }

    if (queryParam.endDate) {
      queryParamString += `&endDate=${queryParam.endDate}`;
    }

    if (queryParam.isNoviUser) {
      queryParamString += `&novi=${queryParam.isNoviUser}`;
    }

    const response = await apiClient.request({
      method: 'GET',
      endPoint: `${DOWNLOAD_CALL_LOGS}${queryParamString}`,
    });

    const blob = new Blob([response.callReport], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `call_logs_${new Date().toISOString()}.csv`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};

export const getCallLogByCallSid = async (callSid: string): Promise<{ success: boolean; data?: CallLog }> => {
  try {
    const response = await apiClient.request({
      method: 'GET',
      endPoint: `${CALL_LOG_BY_CALL_SID}/${callSid}`,
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};
