export enum ReceptionistTestingSteps {
  STEP_0 = 'Test your Receptionist',
  STEP_1 = 'Take a tour',
  STEP_2 = 'Start Free Trial',
  STEP_3 = 'Forward Calls',
}

export enum DebrandReceptionistTestingSteps {
  STEP_0 = 'Forward Calls',
}

export enum OnboardingSteps {
  STEP_0 = 'Goodbye, Missed Calls. 👋',
  STEP_1 = 'Your AI Will Call You! 🤖',
  STEP_2 = 'Did you speak with your AI? 🤔',
  STEP_3 = 'Let’s Explore! 🔍',
  STEP_4 = '(Optional) Book a Free Onboarding Demo Call! 🗺️',
  STEP_5 = 'Activate your Receptionist! 🔓',
  STEP_6 = 'Welcome to the AI Front Desk Family! 🤗',
  STEP_7 = 'Get your AI Phone Number. ☎️',
  STEP_ADDITIONAL_TESTING_OPTIONS = 'Hmm, let’s fix this. Select one of the options below 🛠️!',
  STEP_AI_CALLING = 'Ring Ring! 🔔',
}

export enum NoviOnboardingSteps {
  STEP_0 = 'Welcome to Smarter Outbound Calls! 🚀',
  STEP_1 = 'Discover Outbound Calling in Action! 🔍',
  STEP_2 = 'Unlock the Power of AI Calling Today! 💸',
  STEP_3 = 'Welcome to My AI Front Desk family! 🤗',
}
