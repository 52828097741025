import React from 'react';
import Fade from 'react-reveal/Fade';
import classes from '../Register.module.css';
import posthog from 'posthog-js';
import { Button } from 'antd';

interface Step1Props {
  stepState: number;
  setIsUsingATemplate: (isUsing: boolean) => void;
  setBusinessType: (type: string) => void;
  isUsingATemplate: boolean;
  businessType: string;
  isCampaign: boolean | undefined;
  setStepState: (state: number) => void;
  handleStepTransition: (
    nextStep: number,
    validationFn?: () => Promise<boolean>,
    stateMutationFn?: () => Promise<void>
  ) => Promise<void>;
  step2Validation: () => Promise<boolean>;
  step2StateMutation: () => Promise<void>;
  stepLoading: boolean;
}

const Step1: React.FC<Step1Props> = ({
  stepState,
  setIsUsingATemplate,
  setBusinessType,
  isUsingATemplate,
  businessType,
  handleStepTransition,
  step2StateMutation,
  step2Validation,
  stepLoading,
}) => {
  console.log(isUsingATemplate);
  return (
    <>
      <div
        className={classes.box}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Fade duration={1000} bottom>
          <>
            <h3
              className={classes.heading}
              style={{
                textAlign: 'center',
              }}
            >
              Business Type
            </h3>
            <select
              className={classes.input}
              value={businessType}
              onChange={e => {
                setIsUsingATemplate(false);
                setIsUsingATemplate(true);
                setBusinessType(e.target.value);
                posthog.capture('business_type_selected', {
                  business_type: e.target.value,
                });
              }}
            >
              <option value=''>Select Business Type</option>
              <option value='HVAC'>HVAC</option>
              <option value='Beauty'>Beauty</option>
              <option value='Car Wash'>Car Wash</option>
              <option value='Construction'>Construction</option>
              <option value='Automotive'>Automotive</option>
              <option value='Retail'>Retail</option>
              <option value='Food'>Food</option>
              <option value='Legal'>Legal</option>
              <option value='Marketing'>Marketing</option>
              <option value='Technology'>Technology</option>
              <option value='Entertainment'>Entertainment</option>
              <option value='Real Estate'>Real Estate</option>
              <option value='Financial Services'>Financial Services</option>
              <option value='Nonprofit'>Nonprofit</option>
              <option value='Healthcare'>Healthcare</option>
              <option value='Education'>Education</option>
              <option value='Consulting'>Consulting</option>
              <option value='Other'>Other</option>
            </select>

            {isUsingATemplate ? (
              <p
                style={{
                  maxWidth: '336px',
                  margin: '1rem auto 0',
                  fontSize: '13px',
                }}
              >
                ✨ Great! We’ve applied our {businessType?.replace(' Services', '') || 'default'} services template to
                your receptionist. Now, let’s tweak it.
              </p>
            ) : (
              ''
            )}
            <Button
              type='primary'
              onClick={() => handleStepTransition(stepState + 1, step2Validation, step2StateMutation)}
              className={classes.button}
              style={{ marginTop: '2rem' }}
              loading={stepLoading}
            >
              Continue
            </Button>
          </>
        </Fade>
      </div>
    </>
  );
};

export default Step1;
