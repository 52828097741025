import apiClient from '@/utils/apiClient';

const TEXTING_CAMPAIGN_URI = '/api/novi/campaigns/text';

const NOVI = 'NOVI';

export const createTextingCampaign = async (payload: {
  campaignId: string;
  campaignData: {
    name: string;
    message: string;
    schedule: string;
  };
}): Promise<{ message: string }> => {
  try {
    const response = await apiClient.request({
      method: 'POST',
      endPoint: TEXTING_CAMPAIGN_URI,
      body: payload,
      clientType: NOVI,
    });

    return response;
  } catch (error) {
    throw error;
  }
};

export const getAllTextingCampaigns = async (clientId: string): Promise<any[]> => {
  try {
    const response = await apiClient.request({
      method: 'GET',
      endPoint: `${TEXTING_CAMPAIGN_URI}/${clientId}`,
      clientType: NOVI,
    });

    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteATextingCampaign = async (
  campaignId: string,
  textCampaignId: string
): Promise<{ message: string }> => {
  try {
    const response = await apiClient.request({
      method: 'DELETE',
      endPoint: `${TEXTING_CAMPAIGN_URI}/${textCampaignId}`,
      body: { campaignId },
      clientType: NOVI,
    });

    return response;
  } catch (error) {
    throw error;
  }
};

export const toggleTextingCampaignStatus = async (payload: {
  campaignId: string;
  textCampaignId: string;
  isActive: boolean;
}): Promise<{ message: string }> => {
  try {
    const response = await apiClient.request({
      method: 'PATCH',
      endPoint: `${TEXTING_CAMPAIGN_URI}/${payload.textCampaignId}/toggle-status`,
      body: payload,
      clientType: NOVI,
    });

    return response;
  } catch (error) {
    throw error;
  }
};

export const editTextingCampaign = async (payload: {
  campaignId: string;
  textCampaignId: string;
  campaignData: {
    name: string;
    message: string;
    schedule: string;
  };
}): Promise<{ message: string }> => {
  try {
    const response = await apiClient.request({
      method: 'PATCH',
      endPoint: `${TEXTING_CAMPAIGN_URI}/${payload.textCampaignId}`,
      body: payload,
      clientType: NOVI,
    });

    return response;
  } catch (error) {
    throw error;
  }
};
