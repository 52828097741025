import { getCallLogs } from '@/api/logs';
//@ts-ignore
import IntakeFormsV1 from '../../../v1/components/dashboard/Notifications/IntakeForms';
//@ts-ignore
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { getLoggedInUser } from '@/store/account/selector';
import IntakeFormComponent from './IntakeFormComponent';
import { Loading } from '@/components/Loading';
import { CallLog } from '@/interfaces/ILogs';
import { FaClipboardList, FaSort, FaChartBar } from 'react-icons/fa';
import isEmpty from '@/utils/isEmpty';
import { Alert } from 'antd';

const IntakeForms = () => {
  const loggedInUser = useAppSelector(getLoggedInUser);
  const [startDate] = useState<string | null>(null);
  const [endDate] = useState<string | null>(null);
  const [callLogData, setcallLogData] = useState<CallLog[]>([]);
  const { data, isLoading } = useQuery({
    queryKey: ['call-logs'],
    queryFn: () => {
      return getCallLogs({ limit: null });
    },
  });

  const filterDataByDateRange = (data: any[], startDate: string | null, endDate: string | null) => {
    if (!startDate && !endDate) return data;

    return data.filter((row: any) => {
      const date = moment.utc(row['date']).tz(loggedInUser?.time_zone ?? 'UTC');
      return (!startDate || date?.isSameOrAfter(startDate, 'day')) && (!endDate || date.isSameOrBefore(endDate, 'day'));
    });
  };

  useEffect(() => {
    if (data) {
      let callLogs = Array.isArray(data) ? data : data.callLogs;

      let callLogData =
        callLogs && callLogs.length > 0
          ? [...callLogs].sort((a, b) => moment(a.date).unix() - moment(b.date).unix()).reverse()
          : [];
      callLogData = filterDataByDateRange(callLogData, startDate, endDate);
      setcallLogData(callLogData);
    }
  }, [data, startDate, endDate, loggedInUser?.time_zone]);

  const renderEmptyState = () => (
    <div className='bg-white min-h-screen w-full flex flex-col items-center justify-start p-4 sm:p-8 pt-8 sm:pt-16'>
      <p className='text-lg sm:text-xl text-gray-600 mb-8 text-center max-w-2xl px-4'>
        Streamline your data collection process with AI-powered Intake Forms. Automatically gather important information
        from your callers. Our AI will ask these questions during calls and extract the answers here.
      </p>
      <div className='grid grid-cols-1 sm:grid-cols-3 gap-4 sm:gap-8 mb-8 sm:mb-12 w-full max-w-4xl'>
        <FeatureCard
          icon={<FaClipboardList className='text-3xl sm:text-4xl mb-2 sm:mb-4 text-blue-500' />}
          title='Centralized Response Dashboard'
          description='View all intake form responses in this dedicated section.'
        />
        <FeatureCard
          icon={<FaSort className='text-3xl sm:text-4xl mb-2 sm:mb-4 text-purple-500' />}
          title='Customizable Sorting'
          description='Sort and organize responses to quickly find the information you need.'
        />
        <FeatureCard
          icon={<FaChartBar className='text-3xl sm:text-4xl mb-2 sm:mb-4 text-yellow-500' />}
          title='Data Insights'
          description='Gain valuable insights from collected data to improve your business processes.'
        />
      </div>
      <p className='text-lg text-gray-600 mb-4 text-center max-w-2xl px-4'>
        To get started, set up your intake forms in the "Configure" section. Your AI will automatically ask these
        questions during calls.
      </p>
    </div>
  );

  return (
    <div className='p-4'>
      <Alert className='overview-info' closable description='Go through all the Intake Forms response! 👇' />
      <div className='mb-10 w-full mx-auto text-center'>
        <h1 className='text-4xl mb-4 text-center font-bold'>Intake Forms</h1>
      </div>
      {isLoading ? (
        <div className='text-xl relative bottom-80'>
          <Loading />
        </div>
      ) : callLogData.some(log => {
          let obj = log.intakeResponses;
          try {
            if (log.intakeResponses) obj = JSON.parse(log.intakeResponses);
          } catch (err) {}
          return !isEmpty(obj);
        }) ? (
        <IntakeFormComponent sortedData={callLogData} />
      ) : (
        // renderEmptyState()
        renderEmptyState()
      )}
    </div>
  );
};

const FeatureCard: React.FC<{ icon: React.ReactNode; title: string; description: string }> = ({
  icon,
  title,
  description,
}) => (
  <div className='border border-gray-200 rounded-lg p-4 sm:p-6 text-left w-full shadow-sm hover:shadow-md transition-shadow duration-300'>
    {icon}
    <h3 className='text-lg sm:text-xl font-semibold mb-2 text-gray-800'>{title}</h3>
    <p className='text-sm text-gray-600'>{description}</p>
  </div>
);

export default IntakeForms;
