import { useState } from 'react';
import { EditIcon, TrashIcon } from 'lucide-react';
import { Button, Card, Empty, Modal, notification, Typography } from 'antd';
import { useMutation } from '@tanstack/react-query';
import { deleteLeadNote, updateLeadNote } from '@/api/crm/leads';
import { TCRMNote } from '@/types/TCRM';
import NoteForm from './NoteForm';

const { Text } = Typography;

interface NotesInfoProps {
  leadID: string;
  notes: TCRMNote[];
  refetchLead: (leadID: string) => void;
}

const NotesInfo: React.FC<NotesInfoProps> = ({ leadID, notes, refetchLead }) => {
  const [editableNote, setEditableNote] = useState<TCRMNote | null>(null);

  const updateNoteMutation = useMutation({
    mutationFn: updateLeadNote,
    onSuccess: () => {
      notification.success({
        message: 'Note updated successfully',
      });
      setEditableNote(null);
      refetchLead(leadID);
    },
    onError: () => {
      notification.error({
        message: 'Error updating note',
      });
      setEditableNote(null);
    },
  });

  const deleteNoteMutation = useMutation({
    mutationFn: deleteLeadNote,
    onSuccess: () => {
      notification.success({
        message: 'Note deleted successfully',
      });
      refetchLead(leadID);
    },
  });

  const handleEditNote = (note: TCRMNote) => {
    setEditableNote(note);
  };

  const handleCancelEdit = () => {
    setEditableNote(null);
  };

  const handleUpdateNote = (note: TCRMNote) => {
    updateNoteMutation.mutate({ leadID, note: note });
  };

  const handleDeleteNote = (note: TCRMNote) => {
    deleteNoteMutation.mutate({ leadID, noteID: note.note_id });
  };

  return (
    <>
      {notes?.length ? (
        <>
          {notes.map((note, index) => (
            <Card key={index} size='small'>
              <div className='flex justify-between items-start'>
                <Text className='text-xs'>{note.content}</Text>
                <div className='flex items-center space-x-2'>
                  <Button onClick={() => handleEditNote(note)} size='small' type='text'>
                    <EditIcon className='h-4 w-4' onClick={() => handleEditNote} />
                  </Button>
                  <Button onClick={() => handleDeleteNote(note)} size='small' type='text'>
                    <TrashIcon className='h-4 w-4 text-red-500' onClick={() => handleDeleteNote} />
                  </Button>
                </div>
              </div>
              <div className='mt-1'>
                <Text className='text-xs' type='secondary'>
                  - Added on {new Date(note.createdAt).toLocaleString()}
                </Text>
              </div>
            </Card>
          ))}
        </>
      ) : (
        <Empty description='No notes found' />
      )}
      <Modal title={'Edit Note'} open={!!editableNote} onCancel={handleCancelEdit} footer={null}>
        <NoteForm
          isLoading={updateNoteMutation.isPending}
          onSubmit={handleUpdateNote}
          onCancel={handleCancelEdit}
          editingNote={editableNote as TCRMNote}
          initialValues={editableNote as TCRMNote}
        />
      </Modal>
      <Modal
        title={'Delete Note'}
        open={deleteNoteMutation.isPending}
        onCancel={() => deleteNoteMutation.reset()}
        footer={null}
      >
        <div className='flex items-center justify-center space-x-2'>
          <Text>Are you sure you want to delete this note?</Text>
          <Button type='text' danger onClick={() => handleDeleteNote}>
            Delete
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default NotesInfo;
