import { Avatar, Button, Col, Dropdown, Layout, Row, Space, Typography, theme, Tour, TourProps, Tag } from 'antd';
import SiderMenu from './Sidebar/Sidebar';
import NoviContent from './Content/Content';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { cleanState, setNoviCurrentPage, setNoviSelectedCampaignId } from '@/store/novi/user/slice';
import { ENoviDashboardSider } from '../../enums/ENoviDashboardSider';
import {
  getLoggedInNoviUser,
  getNoviCurrentPage,
  getSelectedCampaign,
  getSelectedCampaignId,
} from '@/store/novi/user/selector';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useMobileDeviceCheck } from 'src/common/hooks/useMobile';
import { Header } from 'antd/es/layout/layout';
import { ArrowLeftOutlined, LogoutOutlined } from '@mui/icons-material';
import { UserOutlined } from '@ant-design/icons';
import LocalStorageUtility from '@/utils/localStorage';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'src/enums/ERoutes';
import ResellerDashboard from '@/pages/ResellerDashboard/ResellerDashboard';
import { ResellerSiderItem } from 'src/enums/IResellerSiderItems';
import { NoviOnboarding } from '../../components/NoviOnboarding';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateNoviOnboardingSteps } from '@/api/novi/user';
import { setInstructionSteps } from '@/store/account/slice';
import { getResellerData } from '@/store/reseller/selector';
import NoviSubCancelPromo from './NoviSubCancelPromo/NoviSubCancelPromo';
import { CampaignSetup } from '../CampaignSetup/CampaignSetup';
import { CustomModal } from '@/components/CustomModal';
import { closeModal } from '@/store/modal/slice';
import { MODAL_IDS } from 'src/enums/EModal';

const { Content } = Layout;
const { useToken } = theme;
const { Title } = Typography;

const MainComponent: React.FC<{
  selectedCampaignId: string | null;
  handleBack: () => void;
  token: any;
  handleLogout: () => void;
  noviUser: any;
  currentPage: ENoviDashboardSider;
  resellerView?: boolean;
  createCampaignButtonRef: React.MutableRefObject<HTMLDivElement | null>;
  isStandaloneCampaign?: boolean;
  resellerLogoUrl?: string;
}> = ({
  selectedCampaignId,
  handleBack,
  token,
  handleLogout,
  noviUser,
  currentPage,
  resellerView = false,
  createCampaignButtonRef,
  isStandaloneCampaign = false,
  resellerLogoUrl,
}) => {
  return (
    <Layout>
      {(!resellerView || selectedCampaignId) && (
        <Header>
          <Row justify='space-between' align='middle' style={{ width: '100%', height: '100%' }}>
            <Col>
              <Space align='center'>
                {selectedCampaignId && !isStandaloneCampaign && (
                  <Button
                    onClick={handleBack}
                    type='default'
                    style={{
                      border: 'none',
                      background: token.colorBgTextHover,
                      borderRadius: token.borderRadiusLG,
                      height: '40px',
                      display: 'flex',
                      alignItems: 'center',
                      padding: '0 16px',
                    }}
                  >
                    <Space>
                      <ArrowLeftOutlined style={{ fontSize: '14px' }} />
                      <Title
                        level={5}
                        style={{
                          margin: 0,
                          fontSize: '14px',
                          fontWeight: 500,
                        }}
                      >
                        My Campaigns
                      </Title>
                    </Space>
                  </Button>
                )}
              </Space>
            </Col>

            {!resellerView && (
              <Col>
                <Space align='center' size={24}>
                  <Dropdown
                    menu={{
                      items: [
                        {
                          key: 'logout',
                          label: 'Logout',
                          icon: <LogoutOutlined style={{ fontSize: '14px' }} />,
                          onClick: handleLogout,
                        },
                      ],
                    }}
                    placement='bottomRight'
                    trigger={['click']}
                  >
                    <Avatar
                      size={40}
                      style={{
                        backgroundColor: '#ffffff',
                      }}
                      icon={
                        noviUser.logo || resellerLogoUrl ? (
                          <img src={!isStandaloneCampaign ? noviUser.logo : resellerLogoUrl} alt='logo' />
                        ) : (
                          <UserOutlined />
                        )
                      }
                    />
                  </Dropdown>
                </Space>
              </Col>
            )}
          </Row>
        </Header>
      )}

      <Content>
        <NoviContent
          resellerView={resellerView}
          currentPage={currentPage}
          createCampaignButtonRef={createCampaignButtonRef}
          isStandaloneCampaign={isStandaloneCampaign}
        />
      </Content>
    </Layout>
  );
};

export const NoviDashboard: React.FC<{
  resellerView?: boolean;
  isStandaloneCampaign?: boolean;
}> = ({ resellerView = false, isStandaloneCampaign = false }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentPage = useAppSelector(getNoviCurrentPage);
  const selectedCampaignId = useAppSelector(getSelectedCampaignId);
  const selectedCampaign = useAppSelector(getSelectedCampaign);
  const noviUser = useAppSelector(getLoggedInNoviUser);
  const { isMobile } = useMobileDeviceCheck();
  const currentReseller = useAppSelector(getResellerData);
  const { token } = useToken();
  const [opened, setOpened] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [tourOpen, setTourOpen] = useState(false);
  const [createCampaignTourOpen, setCreateCampaignTourOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const campaignsRef = useRef<HTMLElement | null>(null);
  const brandingRef = useRef<HTMLElement | null>(null);
  const createCampaignButtonRef = useRef<HTMLDivElement | null>(null);
  const byotRef = useRef<HTMLElement | null>(null);
  const queryClient = useQueryClient();

  const handleMenuSelect = (key: ENoviDashboardSider) => {
    dispatch(setNoviCurrentPage(key));
  };

  const handleBack = () => {
    dispatch(setNoviSelectedCampaignId(null));
  };

  const handleLogout = () => {
    queryClient.clear();
    dispatch(cleanState());
    LocalStorageUtility.clearLocalData();
    if (isStandaloneCampaign) {
      navigate(AppRoutes.WHITE_LABEL_CAMPAIGN_LOGIN);
    } else {
      navigate(AppRoutes.NOVI_PORTAL_LOGIN, { replace: true });
    }
    return;
  };

  useEffect(() => {
    if (selectedCampaignId) {
      dispatch(setNoviCurrentPage(ENoviDashboardSider.CAMPAIGN_SETUP));
    } else {
      dispatch(setNoviCurrentPage(ENoviDashboardSider.MY_CAMPAIGNS));
    }
  }, [selectedCampaignId]);

  const getCampaignsTarget = useCallback(() => campaignsRef.current || document.createElement('div'), [campaignsRef]);
  const getCreateCampaignsTarget = useCallback(
    () => createCampaignButtonRef.current || document.createElement('div'),
    [createCampaignButtonRef]
  );
  const getBrandingTarget = useCallback(() => brandingRef.current || document.createElement('div'), [brandingRef]);
  const getByotTarget = useCallback(() => byotRef.current || document.createElement('div'), [byotRef]);

  const noviWelcomeTourSteps: TourProps['steps'] = [
    {
      title: 'Customize Your Campaigns!',
      description: 'Set up and fine-tune your campaign settings to maximize engagement and performance.',
      target: getCampaignsTarget,
    },
    {
      title: 'Brand Your Experience!',
      description: 'Manage your logo, brand name and other branding details to ensure a cohesive look.',
      target: getBrandingTarget,
    },
    {
      title: 'Integrate Twilio Effortlessly!',
      description: 'Easily connect your Twilio account to streamline communications and enhance lead management.',
      target: getByotTarget,
    },
  ];

  const noviCreateCampaignBtnTourSteps: TourProps['steps'] = [
    {
      title: 'Launch Your Campaign!',
      description: 'Easily create and configure a campaign to engage your audience and boost your results.',
      target: getCreateCampaignsTarget,
    },
  ];

  const { mutate } = useMutation({
    mutationKey: ['updateNoviOnboardingSteps'],
    mutationFn: updateNoviOnboardingSteps,
    onSuccess(data: { success: boolean; updated_onboarding_steps: number[] }) {
      setTourOpen(false);
      dispatch(setInstructionSteps({ instructionSteps: data.updated_onboarding_steps }));
    },
  });

  const onTourClose = () => {
    setTourOpen(false);
    setOpened(true);
    setCurrentStep(2);
    mutate({ stepNumber: 1 });
  };

  const onCreateCampaignTourClose = () => {
    setCreateCampaignTourOpen(false);
  };

  if (resellerView) {
    return (
      <ResellerDashboard activeKey={ResellerSiderItem.OUTBOUND_ROOT}>
        {selectedCampaignId ? (
          <CustomModal
            modalId={MODAL_IDS.SELECTED_CAMPAIGN_MODAL_RESELLER_OUTBOUND}
            width={'95%'}
            footer={false}
            title={
              <Tag color='blue' className='text-lg'>
                {selectedCampaign?.number}
              </Tag>
            }
            cancelAction={() => {
              dispatch(setNoviSelectedCampaignId(null));
              dispatch(closeModal());
            }}
          >
            <Layout>
              {selectedCampaignId && (
                <SiderMenu
                  currentPage={currentPage}
                  handleMenuSelect={handleMenuSelect}
                  selectedCampaignId={selectedCampaignId}
                  noviUser={noviUser}
                  isMobile={isMobile}
                  showBusinessInfo={false}
                  menuRef={menuRef}
                  campaignsRef={campaignsRef}
                  byotRef={byotRef}
                  brandingRef={brandingRef}
                  collapsible={false}
                />
              )}
              <MainComponent
                selectedCampaignId={selectedCampaignId}
                handleBack={handleBack}
                token={token}
                handleLogout={handleLogout}
                noviUser={noviUser}
                currentPage={currentPage}
                resellerView={resellerView}
                createCampaignButtonRef={createCampaignButtonRef}
                isStandaloneCampaign={isStandaloneCampaign}
              />
              {selectedCampaignId && currentPage !== ENoviDashboardSider.CAMPAIGN_SETUP && (
                <CampaignSetup
                  resellerView={resellerView}
                  isPageView={false}
                  campaignId={selectedCampaignId}
                  standAloneCampaignView={isStandaloneCampaign}
                />
              )}
            </Layout>
          </CustomModal>
        ) : (
          <Layout>
            <MainComponent
              selectedCampaignId={selectedCampaignId}
              handleBack={handleBack}
              token={token}
              handleLogout={handleLogout}
              noviUser={noviUser}
              currentPage={currentPage}
              resellerView={resellerView}
              createCampaignButtonRef={createCampaignButtonRef}
              isStandaloneCampaign={isStandaloneCampaign}
            />
          </Layout>
        )}
      </ResellerDashboard>
    );
  }

  return (
    <>
      {!isStandaloneCampaign && noviUser && <NoviSubCancelPromo />}
      <Layout>
        {noviUser && !isStandaloneCampaign && (
          <>
            <NoviOnboarding
              tourOpened={false}
              openedOnboarding={opened}
              setOpenedOnboarding={setOpened}
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
              setTourOpen={setTourOpen}
              tourOpen={tourOpen}
              setCreateCampaignTourOpen={setCreateCampaignTourOpen}
              createCampaignTourOpen={createCampaignTourOpen}
            />
            {tourOpen && <Tour open={tourOpen} onClose={onTourClose} steps={noviWelcomeTourSteps} closeIcon={false} />}{' '}
            {createCampaignTourOpen && (
              <Tour
                open={createCampaignTourOpen}
                onClose={onCreateCampaignTourClose}
                steps={noviCreateCampaignBtnTourSteps}
                closeIcon={false}
              />
            )}
          </>
        )}
        <SiderMenu
          currentPage={currentPage}
          handleMenuSelect={handleMenuSelect}
          selectedCampaignId={selectedCampaignId}
          noviUser={noviUser}
          isMobile={isMobile}
          menuRef={menuRef}
          campaignsRef={campaignsRef}
          byotRef={byotRef}
          brandingRef={brandingRef}
          isStandaloneCampaign={isStandaloneCampaign}
          resellerLogoUrl={currentReseller.logoUrl}
        />
        <MainComponent
          selectedCampaignId={selectedCampaignId}
          handleBack={handleBack}
          token={token}
          handleLogout={handleLogout}
          noviUser={noviUser}
          currentPage={currentPage}
          createCampaignButtonRef={createCampaignButtonRef}
          isStandaloneCampaign={isStandaloneCampaign}
          resellerLogoUrl={currentReseller.logoUrl}
        />

        {selectedCampaignId && currentPage !== ENoviDashboardSider.CAMPAIGN_SETUP && (
          <CampaignSetup
            isPageView={false}
            campaignId={selectedCampaignId}
            resellerView={false}
            standAloneCampaignView={isStandaloneCampaign}
          />
        )}
      </Layout>
    </>
  );
};
