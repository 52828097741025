import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ReceptionistLoginForm } from '@/pages/ReceptionistLogin/index.js';
import { Page404 } from '@/pages/Page404';
import { ReceptionistDashboard } from '@/pages/ReceptionistDashboard';
import { CallInformation } from '@/pages/CallInformation';
import { AppRoutes, UNBRANDED_DOMAINS } from 'src/enums/ERoutes';
import { ForgotPassword } from '@/pages/ForgotPassword/index.js';

import { Toaster } from 'react-hot-toast';

//@ts-ignore
import RegisterPageV3 from '../v1/RegisterPageV3.jsx';
import ReactiveAccount from '@/pages/ReactivateAccount/ReactiveAccount.js';
import ShortURLRedirect from '@/pages/ShortURLRedirect/ShortURLRedirect.tsx';
import PaymentSuccess from '@/pages/PaymentSuccessful/PaymentSuccess.js';
import Intercom from '@intercom/messenger-js-sdk';
import { ResetPassword } from '@/pages/ResetPassword/index.js';
import WatermarkRemovalSuccess from '@/pages/WatermarkRemovalSuccess/WatermarkRemovalSuccess.js';
import UnbrandedReceptionist from '@/pages/UnbrandedReceptionist/UnbrandedReceptionist.tsx';
// import RegisterReseller from '@/pages/RegisterReseller/RegisterReseller.tsx';
// import RegisterCampaign from '@/pages/RegisterCampaign/RegisterCampaign.tsx';
// import CampaignPortal from '@/pages/CampaignPortal/CampaignPortal.tsx';
// import UnbrandedResellerPortal from '@/pages/UnbrandedResellerPortal/UnbrandedResellerPortal.tsx';
// import UnbrandedResellerRegistration from '@/pages/UnbrandedResellerRegistration/UnbrandedResellerRegistration.tsx';
import UnbrandedRegistration from '@/pages/UnbrandedRegistration/UnbrandedRegistration.tsx';
import { PrivateResellerRoute } from './privateReseller.js';
import ReselleReceptionist from '@/pages/ResellerDashboard/ResellerReceptionist/ResellerReceptionist.js';
import ResellerBranding from '@/pages/ResellerDashboard/ResellerBranding/ResellerBranding.js';
import ResellerTutorials from '@/pages/ResellerDashboard/ResellerTutorials/ResellerTutorials.js';
import ResellerSubscription from '@/pages/ResellerDashboard/ResellerSubscription/ResellerSubscription.js';
import ResellerPhoneNumbers from '@/pages/ResellerDashboard/ResellerPhoneNumbers/ResellerPhoneNumbers.js';
import ImportPhoneNumbers from '@/pages/ResellerDashboard/ImportPhoneNumbers/ImportPhoneNumbers.js';
import Blogs from '@/pages/Blogs/Blogs.tsx';
import BlogPage from '@/pages/BlogPage/BlogPage.tsx';
import IntegrationsPage from '@/pages/IntegrationsPage/IntegrationsPage.tsx';
import Integrations from '@/pages/Integrations/Integrations.tsx';
import ResellerEmbed from '@/pages/ResellerDashboard/ResellerEmbed/ResellerEmbed.tsx';
import NoviRoutes from '@/pages/Novi/routes/index.js';
import { CampaignLogin } from '@/pages/Novi/pages/Login/CampaignLogin.js';
import NoviPrivateRoute from '@/pages/Novi/routes/private.js';
import NoviPublicRoute from '@/pages/Novi/routes/public.js';
import { CampaignRegister } from '@/pages/Novi/pages/Register/CampaignRegister.js';
import PublicResellerRoute from './publicReseller.js';
import PublicReceptionistRoute from '@/routes/publicReceptionist.js';
import PrivateReceptionistRoute from '@/routes/privateReceptionist.js';
import AffiliateProgramPage from '@/pages/AffiliateProgram/AffiliateProgram.tsx';
import ResellerQualificationForm from '@/pages/ResellerQualificationForm/ResellerQualificationForm.js';
import TestReceptionistPage from '@/pages/TestReceptionist/TestReceptionist.js';
// @ts-ignore
import ResellerRegistration from '../v1/Whitelabel/ResellerRegistration.jsx';
import CustomOfferThankYou from '@/pages/ThankyouReseller/ThankyouReseller.js';
import { useEffect } from 'react';
import { getLoggedInUser } from '@/store/account/selector';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import TrialReceptionist from '@/pages/ResellerDashboard/TrialReceptionist/TrialReceptionist.js';
import { ResellerForgotPassword } from '@/pages/ResellerDashboard/ResellerForgotPassword/index.js';
import { ResellerResetPassword } from '@/pages/ResellerDashboard/ResellerResetPassword/index.js';
import { getResellerData } from '@/store/reseller/selector.js';
import OAuthCallBackPage from '@/pages/OAuth/callback.js';
import ResellerAcademy from '../pages/ResellerAcademy/ResellerAcademy.jsx';
import ChapterDetail from '@/pages/ResellerAcademy/ChapterDetail/ChapterDetail.js';
import VideoPlayer from '@/pages/ResellerAcademy/VideoPlayer/VideoPlayer.js';
import PdfViewer from '@/pages/ResellerAcademy/PDFViewer/PdfViewer.js';
import ForwardingSetupInstructions from '@/pages/GettingStarted/ForwardingSetupInstructions/index.js';
// import { useFeatureFlagVariantKey } from 'posthog-js/react';
import { POSTHOG_FEATURE_FLAGS } from 'src/constants.js';
import { useDispatch } from 'react-redux';
import { setFeatureFlag } from '@/store/app/slice.js';
import RegisterPage from '@/pages/Register/RegisterPage.js';
import RegisterPageV2 from '@/pages/Register/RegisterPageV2/RegisterPageV2.js';
import { NoviDashboard } from '@/pages/Novi/pages/Dashboard/NoviDashboard.js';
import { WhitelabelCampaignLogin } from '@/pages/Novi/pages/Dashboard/Campagins/CampaignLogin/WhitelabelCampaignLogin.js';
import WhitelabelCampaignDashboard from '@/pages/Novi/pages/Dashboard/Campagins/CampaignDashboard/WhitelabelCampaignDashboard.js';
import WhitelabelCampaignPublicRoute from '@/pages/Novi/routes/whitelabelCampaignPublic.js';
import WhitelabelCampaignPrivateRoute from '@/pages/Novi/routes/whitelabelCampaignPrivate.js';
import posthog from 'posthog-js';

const AppRouter: React.FC = () => {
  const domain = window.location.hostname;
  const currentReseller = useAppSelector(getResellerData);
  const location = window.location;
  const dispatch = useDispatch();
  const variantKey = posthog.getFeatureFlag(POSTHOG_FEATURE_FLAGS.ONBOARDING_REGISTRATION_REVAMP.key) || 'new';

  useEffect(() => {
    if (variantKey) {
      dispatch(
        setFeatureFlag({
          key: 'ONBOARDING_REGISTRATION_REVAMP',
          variant: variantKey,
        })
      );
    }
  }, [variantKey]);

  useEffect(() => {
    // Only show Intercom if domain is branded AND reseller is branded
    if (
      UNBRANDED_DOMAINS.some(unbrandedDomain => domain.includes(unbrandedDomain)) ||
      currentReseller?.permanently_unbranded ||
      currentReseller?.branded
    ) {
      // Hide/Remove Intercom
      if (window.Intercom) {
        window.Intercom('shutdown');
      }
    } else {
      Intercom({
        app_id: import.meta.env.VITE_APP_INTERCOM_BOT_APP_ID,
        vertical_padding: 50,
      });
    }
  }, [currentReseller?.branded]);

  const loggedInUser = useAppSelector(getLoggedInUser);

  const newRegistrationFlow = variantKey === POSTHOG_FEATURE_FLAGS.ONBOARDING_REGISTRATION_REVAMP.variants.NEW;

  return (
    <Router>
      <Routes>
        {/* Receptionist Public/Authenticated Routes */}
        <Route element={<PublicReceptionistRoute />}>
          <Route element={<ReceptionistLoginForm />} path={AppRoutes.RECEPTIONIST_LOGIN} />
          <Route element={<ForgotPassword />} path={AppRoutes.FORGOT_PASSWORD} />
          <Route element={<ResetPassword />} path={AppRoutes.RESET_PASSWORD} />
          <Route element={<OAuthCallBackPage />} path={AppRoutes.OAUTH_CALLBACK} />
          <Route
            element={
              <>
                {/* @ts-ignore */}
                <RegisterPageV3 />
                <Toaster />
              </>
            }
            path={AppRoutes.REGISTER_OLD}
          />
          <Route
            element={
              <>
                {newRegistrationFlow ? <RegisterPageV2 /> : <RegisterPage />}
                <Toaster />
              </>
            }
            path={AppRoutes.REGISTER}
          />
          {/* <Route element={<RegisterCampaign />} path={AppRoutes.REGISTER_CAMPAIGN} /> // [deprecated] */}
          {/* Below three are unbranded ones */}
          <Route element={<UnbrandedRegistration />} path={AppRoutes.UNBRANDED_REGISTER} />
          <Route element={<UnbrandedRegistration />} path={AppRoutes.GENERIC_DOMAIN_REGISTER} />
          <Route element={<UnbrandedRegistration test={true} />} path={AppRoutes.UNBRANDED_CLIENT_TRIAL} />
          {/* <Route element={<UnbrandedResellerRegistration />} path={AppRoutes.REGISTER_RESELLER_UNBRANDED} /> */}
          <Route path={AppRoutes.CUSTOM_OFFER} element={<ResellerRegistration />} />
          <Route path={AppRoutes.THANK_YOU_RESELLER} element={<CustomOfferThankYou />} />
        </Route>

        {/* Receptionist Private/Authenticated Routes */}
        <Route element={<PrivateReceptionistRoute />}>
          <Route
            element={<ReceptionistDashboard debrand={!!loggedInUser?.resellerClient} />}
            path={AppRoutes.UNBRANDED_DASHBOARD}
          />
          <Route element={<ForwardingSetupInstructions />} path={AppRoutes.FORWARDING_SETUP_INSTRUCTIONS} />
          <Route
            element={
              UNBRANDED_DOMAINS.some(unbrandedDomain => domain.includes(unbrandedDomain)) &&
              location.pathname === '/' ? (
                <Page404 />
              ) : (
                <ReceptionistDashboard debrand={!!loggedInUser?.resellerClient} />
              )
            }
            path={AppRoutes.DASHBOARD}
          />
          <Route element={<PaymentSuccess />} path={AppRoutes.PAYMENT_SUCCESS} />
          <Route element={<WatermarkRemovalSuccess />} path={AppRoutes.WATERMARK_REMOVAL_SUCCESS} />
          <Route element={<UnbrandedReceptionist />} path={AppRoutes.UNBRANDED_RECEPTIONIST} />
        </Route>

        {/* Reseller Public/Authenticated Routes */}
        <Route element={<PublicResellerRoute />}>
          {/* <Route element={<RegisterReseller />} path={AppRoutes.REGISTER_RESELLER} /> */}
          <Route path={AppRoutes.RESELLER_SURVEY_FORM} element={<ResellerQualificationForm />} />
          <Route element={<ResellerForgotPassword />} path={AppRoutes.RESELLER_FORGOT_PASSWORD} />
          <Route element={<ResellerResetPassword />} path={AppRoutes.RESELLER_RESET_PASSWORD} />
          <Route path={AppRoutes.AFFILIATE_PROGRAM} element={<AffiliateProgramPage />} />
          <Route path={AppRoutes.TEST_RECEPTIONIST} element={<TestReceptionistPage />} />
        </Route>

        {/* Reseller Private/Authenticated Routes */}
        <Route element={<PrivateResellerRoute />}>
          <Route element={<ReselleReceptionist />} path={AppRoutes.RESELLER_PORTAL} />
          <Route element={<ResellerEmbed />} path={AppRoutes.RESELLER_EMBED} />
          <Route element={<ResellerBranding />} path={AppRoutes.RESELLER_BRANDING} />
          <Route element={<ResellerTutorials />} path={AppRoutes.RESELLER_TUTORIALS} />
          <Route element={<TrialReceptionist />} path={AppRoutes.TRIAL_RECEPTIONIST} />
          <Route element={<ResellerSubscription />} path={AppRoutes.RESELLER_SUBSCRIPTION} />
          <Route element={<ResellerPhoneNumbers />} path={AppRoutes.RESELLER_PHONE_NUMBERS} />
          <Route element={<ImportPhoneNumbers />} path={AppRoutes.RESELLER_IMPORT_NUMBER} />
          <Route element={<PaymentSuccess reseller={true} />} path={AppRoutes.PAYMENT_SUCCESS_RESELLER} />
          <Route element={<ResellerAcademy />} path={AppRoutes.RESELLER_ACADEMY} />
          <Route element={<ChapterDetail />} path={AppRoutes.RESELLER_ACADEMY_CHAPTER} />
          <Route element={<VideoPlayer />} path={AppRoutes.RESELLER_ACADEMY_VIDEO} />
          <Route element={<PdfViewer />} path={AppRoutes.RESELLER_ACADEMY_PDF} />
          <Route element={<NoviDashboard resellerView={true} />} path={AppRoutes.RESELLER_OUTBOUND} />
        </Route>
        {/* <Route element={<CampaignPortal />} path={AppRoutes.CAMPAIGN_PORTAL} />  [deprecated] */}
        {/* <Route element={<UnbrandedResellerPortal />} path={AppRoutes.RESELLER_PORTAL_UNBRANDED} /> */}
        <Route element={<CallInformation />} path={AppRoutes.CALL_INFORMATION} />
        <Route element={<ShortURLRedirect />} path={AppRoutes.SHORT_URL_REDIRECT} />
        <Route element={<Page404 />} path={AppRoutes.NOT_FOUND} />
        <Route element={<ReactiveAccount />} path={AppRoutes.REACTIVATE_ACCOUNT} />
        {/* Blog routes */}
        <Route element={<Blogs />} path={AppRoutes.BLOGS} />
        <Route element={<BlogPage />} path={AppRoutes.BLOG_PAGE} />

        <Route element={<Integrations />} path={AppRoutes.INTEGRATIONS} />
        <Route element={<IntegrationsPage />} path={AppRoutes.INTEGRATIONS_PAGE} />

        <Route element={<NoviPrivateRoute />}>
          <Route element={<NoviRoutes />} path={AppRoutes.NOVI_PORTAL} />
        </Route>
        <Route element={<NoviPublicRoute />}>
          <Route element={<CampaignLogin />} path={AppRoutes.NOVI_PORTAL_LOGIN} />
          <Route element={<CampaignRegister />} path={AppRoutes.NOVI_PORTAL_CAMPAIGN_REGISTER} />
        </Route>

        <Route element={<WhitelabelCampaignPublicRoute />}>
          <Route element={<WhitelabelCampaignLogin />} path={AppRoutes.WHITE_LABEL_CAMPAIGN_LOGIN} />
          <Route
            element={<UnbrandedRegistration isCampaign={true} isStandaloneCampaign={true} />}
            path={AppRoutes.WHITE_LABEL_CAMPAIGN_REGISTER}
          />
        </Route>
        <Route element={<WhitelabelCampaignPrivateRoute />}>
          <Route element={<WhitelabelCampaignDashboard />} path={AppRoutes.WHITE_LABEL_CAMPAIGN_DASHBOARD} />
        </Route>
      </Routes>
    </Router>
  );
};

export default AppRouter;
