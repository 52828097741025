export const languageExamples = {
  English: 'Hello, how may I help you?',
  Spanish: 'Hola, ¿en qué puedo ayudarte?',
  French: 'Bonjour, comment puis-je vous aider ?',
  Italian: 'Ciao, come posso aiutarti?',
  German: 'Hallo, wie kann ich Ihnen helfen?',
  'Swiss German': 'Hallo, wie kann ich Ihnen helfen?',
  Portuguese: 'Olá, como posso ajudá-lo?',
  Japanese: 'こんにちは、どのようなお手伝いができますか？',
  Mandarin: '你好，我可以帮助你吗？',
  Arabic: 'مرحبًا، كيف يمكنني مساعدتك؟',
  Russian: 'Привет, чем я могу помочь вам?',
  Hindi: 'नमस्ते, मैं आपकी कैसे मदद कर सकता हूँ?',
  Dutch: 'Hallo, hoe kan ich je helpen?',
  Romanian: 'Bună, cum te pot ajuta?',
  Armenian: 'Բարև, ինչպես կարող եմ օգնել քեզ?',
  Korean: '안녕하세요, 어떻게 도와 드릴까요?',
  Vietnamese: 'Xin chào, tôi có thể giúp gì cho bạn?',
  Turkish: 'Merhaba, size nasıl yardımcı olabilirim?',
  Danish: 'Hej, hvordan kan jeg hjælpe dig?',
};
