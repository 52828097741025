import { CustomModal } from '@/components/CustomModal';
import { InputNumber, Typography } from 'antd';
import { useState } from 'react';
import { MODAL_IDS } from 'src/enums/EModal';

const { Title, Text } = Typography;

interface Props {
  onBuyMoreCampaigns: (seats: number) => void;
  loading: boolean;
}

const BuyMoreCampaignsModal = ({ onBuyMoreCampaigns, loading = false }: Props) => {
  const [campaignsToBuy, setCampaignsToBuy] = useState<number>(1);

  return (
    <CustomModal
      title='Buy More Campaigns'
      confirmAction={() => onBuyMoreCampaigns(campaignsToBuy)}
      modalId={MODAL_IDS.NOVI_BUY_MORE_CAMPAIGNS}
      loading={loading}
    >
      <Title level={4}>Buy more campaigns for your outbound portal</Title>
      <Text>How many campaigns would you like to purchase?</Text>
      <div style={{ marginTop: 16 }}>
        <InputNumber
          min={1}
          value={campaignsToBuy}
          onChange={value => setCampaignsToBuy(value as number)}
          style={{ width: '100%' }}
        />
      </div>
    </CustomModal>
  );
};

export default BuyMoreCampaignsModal;
