import React, { useState } from 'react';
import { Badge, Button, Card, Tooltip } from 'antd';
import { ChatBubbleOutline, DiamondOutlined, MailOutline, WorkspacePremiumOutlined, Edit } from '@mui/icons-material';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { getLoggedInUser } from '@/store/account/selector';
import { PlusOutlined } from '@ant-design/icons';
import PreviewEmail from './PreviewEmail';
import PreviewSms from './PreviewSms';
import AddEmails from './AddEmails';
import AddPhones from './AddPhones';
import { Show } from '@/interfaces/INotifications';
import LearnMore from '@/components/Buttons/LearnMore';
import { getResellerData } from '@/store/reseller/selector';
import PreviewButton from './PreviewButton';
import { NotifKey } from 'src/enums/ENotifKey';

interface NotificationCategory {
  heading: string;
  label: string;
  description: string;
  cardDescription: string;
  email: boolean;
  sms: boolean;
  voicemail: boolean;
  emails: string[];
  phones: string[];
  notifKey: string;
  saveEmails: (val: string[]) => void;
  savePhones: (val: string[]) => void;
  saveCheck: (method: 'email' | 'sms' | 'voicemail') => void;
  show: Show | null;
  onShowChange?: (newShow: Show) => void;
  tutorialId: string;
}

const NotificationCard: React.FC<NotificationCategory> = ({
  heading,
  label,
  description,
  cardDescription,
  email,
  sms,
  voicemail,
  emails,
  phones,
  notifKey,
  saveEmails,
  savePhones,
  show,
  onShowChange,
  tutorialId,
}) => {
  const loggedInUser = useAppSelector(getLoggedInUser);
  const categories = {
    heading,
    label,
    description,
    cardDescription,
    email,
    sms,
    voicemail,
    emails,
    phones,
  };
  const [emailAddClicked, setEmailAddClicked] = useState(false);
  const [phoneAddClicked, setPhoneAddClicked] = useState(false);
  const [previewEmail, setPreviewEmail] = useState(false);
  const [previewSms, setPreviewSms] = useState(false);
  const currentReseller = useAppSelector(getResellerData);

  const isDisabled = loggedInUser?.resellerClient ? false : loggedInUser.subscriptionStatus !== 'active';

  const isPhoneDisabled = loggedInUser?.resellerClient
    ? false
    : loggedInUser.subscriptionStatus !== 'active' || loggedInUser.subscriptionType?.toLocaleLowerCase() !== 'pro';

  return (
    <Card className='w-full mx-auto bg-white rounded-lg shadow-lg'>
      <div>
        <div className='flex flex-col mb-4'>
          <div className='flex justify-between items-center'>
            <div className='flex items-center space-x-4'>
              <h3 className='text-lg font-semibold'>{categories.heading}</h3>
              <LearnMore tutorialId={tutorialId} />
            </div>
            <div className='flex items-center space-x-4'>
              <PreviewButton
                title={notifKey === NotifKey.DirectCall ? 'Customize Email Notification' : 'Preview Email Notification'}
                setPreviewEmail={setPreviewEmail}
                children={<MailOutline fontSize='small' />}
              />
              <PreviewButton
                title={
                  notifKey === NotifKey.DirectCall ? 'Customize Text SMS Notification' : 'Preview Text SMS Notification'
                }
                setPreviewEmail={setPreviewSms}
                children={<ChatBubbleOutline fontSize='small' />}
              />
            </div>
          </div>
          <span>{categories.cardDescription}</span>
        </div>

        <h4 className='font-medium'>{categories.label}</h4>

        <div className='flex flex-col'>
          <div className='flex items-center gap-2'>
            <Tooltip title='Included in Basic plan'>
              <WorkspacePremiumOutlined fontSize='small' className='text-blue-500 cursor-pointer' />
            </Tooltip>
            <span className='font-semibold italic text-xs'>Send Email To:</span>

            <Edit
              className={`text-[8px] flex items-center justify-center w-10 h-10 border-2 rounded-md p-1 
    ${isDisabled ? 'text-gray-400 border-gray-400 cursor-not-allowed' : 'text-blue-500 border-blue-500 cursor-pointer'}`}
              onClick={() => {
                if (!isDisabled) {
                  setEmailAddClicked(!emailAddClicked);
                }
              }}
            />
          </div>
          <div className='flex flex-wrap gap-2 items-center'>
            {categories.emails.map((item: string, index: number) => {
              return <Badge key={index} count={item} className='badge-disabled' />;
            })}
            <Button
              disabled={loggedInUser?.resellerClient ? false : loggedInUser.subscriptionStatus !== 'active'}
              type='link'
              icon={<PlusOutlined />}
              onClick={() => setEmailAddClicked(!emailAddClicked)}
            >
              Add Email
            </Button>
          </div>
        </div>

        <div className='flex flex-col mt-2'>
          <div className='flex items-center gap-2'>
            <Tooltip title='Included in Pro plan'>
              <DiamondOutlined fontSize='small' className='text-yellow-500 cursor-pointer' />
            </Tooltip>
            <span className='font-semibold italic text-xs'>Send Text To:</span>
            <Edit
              className={`text-[8px] flex items-center justify-center w-10 h-10 border-2 rounded-md p-1 
                ${isPhoneDisabled ? 'text-gray-400 border-gray-400 cursor-not-allowed' : 'text-blue-500 border-blue-500 cursor-pointer'}`}
              onClick={() => {
                if (!isPhoneDisabled) {
                  setPhoneAddClicked(!phoneAddClicked);
                }
              }}
            />
          </div>
          <div className='flex flex-wrap gap-2 items-center'>
            {categories.phones.map((item: string, index: number) => {
              return (
                <Badge
                  key={index}
                  className='badge-disabled font-medium text-xs py-1 px-2 rounded-lg hover:bg-blue-100'
                >
                  {item}
                </Badge>
              );
            })}
            <Button
              disabled={
                loggedInUser?.resellerClient
                  ? false
                  : loggedInUser.subscriptionStatus !== 'active' ||
                    loggedInUser.subscriptionType?.toLocaleLowerCase() !== 'pro'
              }
              type='link'
              icon={<PlusOutlined />}
              onClick={() => setPhoneAddClicked(!phoneAddClicked)}
            >
              Add Phone
            </Button>
          </div>
        </div>

        <PreviewEmail
          open={previewEmail}
          setOpen={setPreviewEmail}
          disabled={loggedInUser?.resellerClient ? false : loggedInUser.subscriptionStatus !== 'active'}
          notifKey={notifKey}
          show={show}
          onShowChange={onShowChange}
          resellerName={currentReseller?.reseller_name}
          resellerLogo={currentReseller?.logoUrl}
          resellerClient={loggedInUser?.resellerClient}
        />
        <PreviewSms
          open={previewSms}
          setOpen={setPreviewSms}
          disabled={
            loggedInUser?.resellerClient
              ? false
              : loggedInUser.subscriptionStatus !== 'active' ||
                loggedInUser.subscriptionType?.toLocaleLowerCase() !== 'pro'
          }
          notifKey={notifKey}
          show={show}
          onShowChange={onShowChange}
        />
        <AddEmails
          open={emailAddClicked}
          setOpen={val => setEmailAddClicked(val as boolean)}
          emails={emails}
          saveEmails={saveEmails}
        />
        <AddPhones
          open={phoneAddClicked}
          setOpen={val => setPhoneAddClicked(val as boolean)}
          phones={phones}
          savePhones={savePhones}
        />
      </div>
    </Card>
  );
};

export default NotificationCard;
