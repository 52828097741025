import { LabelWithDescription } from '@/components/LabelWithdescription';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { DeleteOutline } from '@mui/icons-material';
import { Button, Card, Flex, Form, Input, notification, Spin, Switch } from 'antd';
import { useEffect, useState } from 'react';
import { OverViewCardForm } from '../OverviewCardForm';
import { QueryObserverResult, RefetchOptions, useMutation, UseMutationResult } from '@tanstack/react-query';
import { openModal } from '@/store/modal/slice';
import { MODAL_IDS } from 'src/enums/EModal';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { getLoggedInUser, getUserFeatures } from '@/store/account/selector';
import { closeModal } from '@/store/modal/slice';
import { useAppDispatch } from '@/store/hooks/useAppDispatch';
import './TextingWorkflowInfo.scss';
import { User } from '@/types/TAccounts';
import ImageUpload from './ImageUpload';
import { uploadToS3 } from '@/api/voice';
import ShortUniqueId from 'short-unique-id';

const uid = new ShortUniqueId({ length: 10 });

interface TextingWorkflowFormProps {
  initialValues?: any;
  showAddFieldButton?: boolean;
  refetch: (options?: RefetchOptions) => Promise<QueryObserverResult<User, Error>>;
  actionType: 'ADD' | 'EDIT';
  mutation: UseMutationResult<{}, Error, any, unknown>;
  scenarioIndex?: number;
  debrand: boolean;
  isNoviUser: boolean;
}

const TextingWorkflowForm: React.FC<TextingWorkflowFormProps> = ({
  initialValues,
  showAddFieldButton = true,
  refetch,
  actionType,
  mutation,
  scenarioIndex,
  debrand,
  isNoviUser,
}) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const features = useAppSelector(getUserFeatures);
  const loggedInUser = useAppSelector(getLoggedInUser);

  const [isFieldTouchReset, setIsFieldTouchReset] = useState<boolean>(true);
  const [imageFiles, setImageFiles] = useState<{ [key: number]: { file: File; s3Key?: string } }>({});

  const { mutate: uploadToS3Fn } = useMutation({
    mutationKey: ['uploadToS3'],
    mutationFn: uploadToS3,
    onError: (error: any) => {
      notification.error({
        message: error.message || 'An error occurred during the upload.',
      });
    },
  });

  useEffect(() => {
    if (initialValues && actionType === 'EDIT') {
      const formValues = {
        workflows: initialValues.workflows.map((workflow: any) => ({
          ...workflow,
          id: workflow.id,
        })),
      };
      form.setFieldsValue(formValues);
    }

    if (actionType === 'ADD') {
      form.resetFields();
    }

    return () => {
      form.resetFields();
    };
  }, [initialValues, form, actionType]);

  const uploadImageToS3 = async (file: File, index: number) => {
    const formData = new FormData();

    const now = new Date();
    const timestamp = `${String(now.getMonth() + 1).padStart(2, '0')}${String(now.getDate()).padStart(2, '0')}${now.getFullYear()}${String(now.getHours()).padStart(2, '0')}${String(now.getMinutes()).padStart(2, '0')}${String(now.getSeconds()).padStart(2, '0')}`;
    // Extract the file extension from the file name
    const fileExtension = file?.name.substring(file?.name.lastIndexOf('.'));
    // Construct the S3 key with the user's number and timestamp
    const s3Key = `${loggedInUser?.number}_${timestamp}${fileExtension}`;

    file && formData.append('file', file);
    file && formData.append('s3Key', s3Key);

    try {
      await uploadToS3Fn(formData);

      setImageFiles(prev => ({
        ...prev,
        [index]: { file, s3Key },
      }));

      return s3Key;
    } catch (error) {
      notification.error({
        message: 'Image upload failed',
        description: 'Could not upload image to S3',
      });
      return null;
    }
  };

  const handleSubmit = async (values: {
    workflows: {
      id?: string;
      description: string;
      voiceResponse: string;
      textMessage: URL | string;
      askForPermissionOnText: boolean;
      shortURLEnabled: boolean;
      customTextFooter: string;
      imageS3Key?: string;
      presignedUrl?: string;
      postExecutionPrompt?: string;
    }[];
  }) => {
    try {
      if (features?.workflows?.textWroflows?.value.toString() !== '-1') {
        if (initialValues?.length || 0 >= parseInt(features?.workflows?.textWroflows?.value.toString() || '1')) {
          dispatch(openModal({ modalId: MODAL_IDS.SUBSCRIPTION_MODAL }));
        }
      }

      let formData: {
        id: string;
        description: string;
        textMessage: string | URL;
        voiceResponse: string;
        shortURLEnabled: boolean;
        customTextFooter: string;
        imageS3Key?: string;
        presignedUrl?: string;
        postExecutionPrompt?: string;
      }[] = [];

      let updatedScenario = values.workflows.map(scenario => ({
        id: scenario.id ?? uid.rnd(),
        description: scenario.description,
        textMessage: scenario.textMessage,
        voiceResponse: scenario.voiceResponse,
        postExecutionPrompt: scenario.postExecutionPrompt,
        askForPermissionOnText: scenario.askForPermissionOnText,
        shortURLEnabled: scenario.shortURLEnabled === undefined ? true : scenario.shortURLEnabled,
        customTextFooter: scenario.customTextFooter,
        imageS3Key: scenario?.imageS3Key,
        presignedUrl: scenario?.presignedUrl,
      }));

      updatedScenario =
        imageFiles &&
        (await Promise.all(
          updatedScenario.map(async (scenario, index) => {
            const imageFile = imageFiles[index]?.file;
            const res = imageFile ? await uploadImageToS3(imageFile, index) : null;
            const s3ImageKey = imageFile ? res : null;

            return {
              ...scenario,
              imageS3Key: s3ImageKey || scenario?.imageS3Key, // Add S3 key to the scenario data
            };
          })
        ));

      // checking if it exsisted before and now is being removed
      const scenario = values.workflows[0];
      if (Object.keys(imageFiles || {})?.length <= 0 && scenario?.imageS3Key) {
        updatedScenario[0].imageS3Key = undefined;
      }

      //@ts-ignore
      if (initialValues?.workflows[0].previousWorkflowData) {
        //@ts-ignore
        scenario.previousWorkflowData = initialValues.workflows[0].previousWorkflowData;
      }
      //@ts-ignore
      const scenarioIndex = scenario?.index ?? 0;

      //@ts-ignore
      if (scenario?.previousWorkflowData) {
        //@ts-ignore
        formData = [...scenario.previousWorkflowData];
      }

      if (actionType === 'EDIT') {
        let prevFormId = formData[scenarioIndex]?.id;

        if (prevFormId && prevFormId !== updatedScenario[0].id) {
          updatedScenario[0].id = prevFormId;
        }

        formData.splice(scenarioIndex, updatedScenario.length, ...updatedScenario);
      } else if (actionType === 'ADD') {
        formData = [...formData, ...updatedScenario];
      }

      await mutation.mutateAsync({ workflows: formData });
      refetch();
      notification.success({
        key: 'form-success',
        message: 'Details has been saved successfully!',
        duration: 3,
      });
      form.resetFields();
      setIsFieldTouchReset(false);
      dispatch(closeModal());
    } catch (err) {
      console.log('Error: ', err);
      notification.error({
        message: 'Something went wrong, please try again later!',
      });
    }
  };

  const baseClass = 'texting-workflow';

  const FormItems = () => (
    <Form.List name='workflows' initialValue={[{}]}>
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name, ...restField }, index) => (
            <Flex key={key} className='mb-2' gap={8}>
              <Card
                className='flex-1'
                title={
                  <div className='flex justify-between'>
                    <span className='font-bold text-base'>{`Scenario ${(scenarioIndex ?? 0) + index + 1}`}</span>
                    {fields.length > 1 && showAddFieldButton && (
                      <Button type='primary' danger icon={<DeleteOutline />} onClick={() => remove(name)}>
                        Delete
                      </Button>
                    )}
                  </div>
                }
              >
                <Form.Item {...restField} name={[name, 'id']} hidden>
                  <Input />
                </Form.Item>
                <div className='border-b'>
                  <LabelWithDescription
                    customClassName='mt-2'
                    label='Enable Short URLs'
                    description='Enable short URLs to keep track of the links and get analytics.'
                  />
                  <Form.Item className=' mt-2 pt-2 ' {...restField} name={[name, 'shortURLEnabled']}>
                    <Switch defaultChecked className=' mt-2 mb-4' />
                  </Form.Item>
                </div>
                <div className='border-b'>
                  <LabelWithDescription
                    customClassName='mt-2'
                    label='Scenario Description'
                    description='Describe the scenario in which you would like to send the caller a text message. The AI is intelligent enough to know when the scenario is triggered.'
                  />
                  <Form.Item
                    className=' mt-2 pt-2 '
                    {...restField}
                    name={[name, 'description']}
                    rules={[
                      {
                        validator: (_, value) => {
                          if (!value || value.trim() === '') {
                            return Promise.reject("Description can't be blank");
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Input.TextArea
                      placeholder='e.g. Send the caller a copy of the menu. Execute whenever caller asks for menu or prices.'
                      autoSize={{ minRows: 1, maxRows: 3 }}
                      data-testid='textingworkflow-scenario-desc-input'
                    />
                  </Form.Item>
                </div>

                <div className='border-b'>
                  <LabelWithDescription
                    customClassName='mt-2 mb-2'
                    label='Text Message'
                    description='Specify the text you want your AI receptionist to send when the scenario is triggered.'
                  />
                  <Form.Item
                    className='mt-2 pt-2'
                    {...restField}
                    name={[name, 'textMessage']}
                    rules={[
                      {
                        validator: (_, value) => {
                          if (!value || value.trim() === '') {
                            return Promise.reject("Text Message Can't be blank");
                          }
                          return Promise.resolve();
                        },
                      },
                      {
                        max: 1000,
                        message: 'Max 1000 characters allowed',
                      },
                    ]}
                  >
                    <Input.TextArea
                      className={`${baseClass}_input_text_area`}
                      aria-multiline
                      placeholder='e.g. Here is our menu: www.restaurant.com/menu'
                      data-testid='textingworkflow-text-message-input'
                    />
                  </Form.Item>
                  <div className='text-blue-700 text-xs font-semibold mb-2'>* Max 1000 characters allowed </div>
                </div>

                <div className='space-y-2'>
                  {/* Deprecated Voice Response Field */}
                  <div className='border-b bg-gray-100 p-4 rounded-md relative'>
                    <LabelWithDescription
                      customClassName='mt-2'
                      label='Voice Response (Deprecated)'
                      description={
                        <>
                          This field is deprecated. Please use 'Post-Execution Prompt' for updated functionality.{' '}
                          <b className='text-black'>Though it'll not affect your current calling experience.</b>
                        </>
                      }
                    />
                    <Form.Item
                      className='mt-2 pt-2'
                      {...restField}
                      name={[name, 'voiceResponse']}
                      rules={[
                        {
                          validator: (_, value) => {
                            if (!value || value.trim() === '') {
                              return Promise.reject("Voice Response can't be blank");
                            }
                            return Promise.resolve();
                          },
                        },
                      ]}
                    >
                      <Input.TextArea
                        placeholder="This feature is deprecated, please use 'Post-Execution Prompt' instead"
                        autoSize={{ minRows: 1, maxRows: 2 }}
                        className='border-gray-300 text-gray-400'
                        data-testid='textingworkflow-text-voice-response-input'
                      />
                    </Form.Item>
                    <span className='text-xs text-red-600 absolute top-2 right-4 font-bold'>Deprecated</span>
                  </div>

                  {/* New Post-Execution Prompt Field */}
                  <div className='border-b bg-white p-4 rounded-md'>
                    <LabelWithDescription
                      customClassName='mt-2 mb-2'
                      label='Post-Execution Prompt'
                      description='Provide instructions for the AI receptionist to teach it how to conclude the action.'
                    />
                    <Form.Item className='mt-2 pt-2' {...restField} name={[name, 'postExecutionPrompt']}>
                      <Input.TextArea
                        placeholder="e.g., Tell the caller that you've sent the text message in their inbox, and ask if they need assistance with anything else."
                        autoSize={{ maxRows: 2 }}
                        data-testid='textingworkflow-text-post-execution-input'
                      />
                    </Form.Item>
                  </div>
                </div>
                {debrand || isNoviUser ? (
                  <div>
                    <LabelWithDescription
                      customClassName='mt-2'
                      label='Custom Text Footer'
                      description='Add a custom text footer to your text message.'
                    />
                    <Form.Item
                      className=' mt-2 pt-2 '
                      {...restField}
                      name={[name, 'customTextFooter']}
                      rules={[
                        {
                          max: 100,
                          message: 'Max 100 characters allowed',
                        },
                      ]}
                    >
                      <Input.TextArea
                        placeholder='e.g. Sent by AI Receptionist'
                        autoSize={{ minRows: 1, maxRows: 2 }}
                      />
                    </Form.Item>
                    <div className='text-blue-700 text-xs font-semibold mb-2'>* Max 100 characters allowed </div>
                  </div>
                ) : null}
                <div className='border-b'>
                  <LabelWithDescription
                    customClassName='mt-2'
                    label='Ask before sending Text SMS'
                    description='If checked in, above message will be sent when the caller confirms for the text sms'
                  />
                  <Form.Item className='pt-2 ' {...restField} name={[name, 'askForPermissionOnText']}>
                    <Switch />
                  </Form.Item>
                </div>
                <Form.Item label='Image Upload' name={[name, 'image']}>
                  <ImageUpload
                    data-testid='textingworkflow-image-upload-input'
                    value={imageFiles[index]?.file}
                    onChange={(imageFile: File | null) => {
                      if (imageFile) {
                        setImageFiles(prev => ({
                          ...prev,
                          [index]: { file: imageFile },
                        }));
                      }
                    }}
                    presignedUrl={initialValues?.workflows[0]?.previousWorkflowData?.[index]?.presignedUrl || ''}
                  />
                </Form.Item>
              </Card>
            </Flex>
          ))}
          {showAddFieldButton && (
            <Form.Item>
              <Button
                type='dashed'
                onClick={() => {
                  if (features?.workflows?.textWroflows?.value.toString() === '-1') {
                    add();
                    return;
                  }

                  if (
                    initialValues?.length ||
                    0 < parseInt(features?.workflows?.textWroflows?.value.toString() || '1')
                  ) {
                    add();
                  } else {
                    dispatch(openModal({ modalId: MODAL_IDS.SUBSCRIPTION_MODAL }));
                  }
                }}
                block
                icon={<PlusOutlined />}
              >
                Add Texting Workflow
              </Button>
            </Form.Item>
          )}
        </>
      )}
    </Form.List>
  );

  return (
    <>
      <Spin
        spinning={mutation.isPending}
        indicator={<LoadingOutlined style={{ fontSize: '48px' }} spin data-testid='textingworkflow-form-spinner' />}
      >
        <OverViewCardForm
          form={form}
          initialValues={actionType === 'EDIT' && initialValues}
          formItems={<FormItems />}
          handleSubmit={handleSubmit}
          isFieldTouchReset={isFieldTouchReset}
        />
      </Spin>
    </>
  );
};

export default TextingWorkflowForm;
