import React, { ChangeEvent } from 'react';
import Fade from 'react-reveal/Fade';
import classes from '../Register.module.css';

type CallTemplate = {
  scenarioDescription: string;
  phoneNumber: string;
  voiceResponse: string;
};

type Step7BProps = {
  setCallTransferTemplateModalActive: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedCallTemplate: React.Dispatch<React.SetStateAction<CallTemplate | null>>;
  callTransferTemplates: CallTemplate[];
  selectedCallTemplate: CallTemplate | null;
  callTransferTemplateModalActive: boolean;
  setCallWorkflows: any;
  callWorkflows: any;
};

const Step7B: React.FC<Step7BProps> = ({
  setCallTransferTemplateModalActive,
  setSelectedCallTemplate,
  callTransferTemplates,
  selectedCallTemplate,
  callTransferTemplateModalActive,
  callWorkflows,
  setCallWorkflows,
}) => {
  return (
    <>
      <div
        className={`${classes.box} ${classes.modal} ${callTransferTemplateModalActive && classes.active}`}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <div className={classes.close} onClick={() => setCallTransferTemplateModalActive(false)}>
          &times;
        </div>
        <Fade duration={1000} bottom>
          <div className='flex items-center justify-center flex-col'>
            <h3
              className={classes.heading}
              style={{
                textAlign: 'center',
              }}
            >
              Prompt Templates
            </h3>

            <div className={`${classes.subtitle}`} style={{ marginBottom: '.5rem' }}>
              Select a template
            </div>

            <select
              style={{ marginBottom: '1rem' }}
              className={classes.input}
              onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                const selectedTemplate = callTransferTemplates.find(el => el.scenarioDescription === e.target.value);
                setSelectedCallTemplate(selectedTemplate || null); // If no match is found, set to null
              }}
            >
              <option value=''>Choose an option</option>
              {callTransferTemplates.map(el => (
                <option key={el.scenarioDescription} value={el.scenarioDescription}>
                  {el.scenarioDescription}
                </option>
              ))}
            </select>

            <div style={{ width: '100%' }}>
              <h5 className={classes.label}>Trigger</h5>
              <p>{selectedCallTemplate?.scenarioDescription}</p>
              <h5 className={classes.label}>Voice Response</h5>
              <p>{selectedCallTemplate?.voiceResponse}</p>
              <h5 className={classes.label}>Phone Number</h5>
              <p>{selectedCallTemplate?.phoneNumber}</p>
            </div>

            <button
              type='button'
              onClick={() => {
                const updateDescription = (
                  words: string[],
                  currentIndex: number,
                  setFunction: 'description' | 'voiceResponse' | 'workflowForwardingNumber'
                ) => {
                  const modifiedWorkflows = [...callWorkflows];
                  const lastWorkflow = modifiedWorkflows[modifiedWorkflows.length - 1];
                  lastWorkflow[setFunction] = '';
                  const interval = setInterval(() => {
                    if (currentIndex < words.length) {
                      lastWorkflow[setFunction] += words?.[currentIndex] + ' ';
                      currentIndex++;
                      setCallWorkflows(modifiedWorkflows);
                    } else {
                      clearInterval(interval);
                    }
                  }, 50);
                };

                const scenarioDescription = selectedCallTemplate?.scenarioDescription || '';
                const scenarioWords = scenarioDescription.split(' ');
                let currentIndex1 = 0;
                const voiceResponse = selectedCallTemplate?.voiceResponse || '';
                const voiceWords = voiceResponse.split(' ');
                let currentIndex2 = 0;
                const phoneNum = selectedCallTemplate?.phoneNumber || '';
                const phoneDigits = phoneNum.split('');
                let currentIndex3 = 0;

                updateDescription(scenarioWords, currentIndex1, 'description');
                updateDescription(voiceWords, currentIndex2, 'voiceResponse');
                updateDescription(phoneDigits, currentIndex3, 'workflowForwardingNumber');
                setCallTransferTemplateModalActive(false);
              }}
              className={classes.button}
              style={{
                marginTop: '2rem',
              }}
            >
              Use Template
            </button>
          </div>
        </Fade>
      </div>
    </>
  );
};

export default Step7B;
