import React from 'react';
import Fade from 'react-reveal/Fade';
import classes from '../Register.module.css';
import { Button } from 'antd';
import posthog from 'posthog-js';

interface Step3Props {
  isCampaign: boolean | undefined;
  reseller_email?: string;
  campaign_user_email?: string;
  businessName: string;
  setBusinessName: (name: string) => void;
  stepState: number;
  setStepState: (state: number) => void;
  systemPhrase?: string;
  handleStepTransition: (
    nextStep: number,
    validationFn?: () => Promise<boolean>,
    stateMutationFn?: () => Promise<void>
  ) => Promise<void>;
  step4Validation: () => Promise<boolean>;
  step4StateMutation: () => Promise<void>;
  stepLoading: boolean;
}

const Step3: React.FC<Step3Props> = ({
  isCampaign,
  reseller_email,
  campaign_user_email,
  businessName,
  setBusinessName,
  stepState,
  handleStepTransition,
  step4StateMutation,
  step4Validation,
  stepLoading,
}) => {
  return (
    <>
      <div className={classes.box}>
        <Fade duration={1000} bottom>
          <>
            {React.createElement(() => {
              posthog.capture('business_name_entered');
              return null;
            })}
            <h3
              className={classes.heading}
              style={{
                textAlign: 'center',
              }}
            >
              {isCampaign ? 'Campaign Name' : 'Business Name'}
            </h3>
            <div
              className={classes.subtitle}
              style={{
                textAlign: 'center',
              }}
            >
              The name of{' '}
              {reseller_email ? "your client's business" : campaign_user_email ? 'your campaign' : 'your business'}
            </div>

            <input
              value={businessName}
              className={`${classes.input} ${classes.centered}`}
              required
              onChange={event => {
                const value = event.target.value;
                setBusinessName(value);
              }}
              placeholder={businessName}
            />

            <Button
              type='primary'
              onClick={() => handleStepTransition(stepState + 1, step4Validation, step4StateMutation)}
              className={classes.button + ' ' + classes.centered}
              style={{ marginTop: '2rem' }}
              loading={stepLoading}
            >
              Continue
            </Button>
          </>
        </Fade>
      </div>
    </>
  );
};

export default Step3;
