import React from 'react';
import classes from '../RegisterPageV3.module.css';
import posthog from 'posthog-js';
import Fade from 'react-reveal/Fade';
import { Button } from 'antd';

const Step4A = ({
  businessName,
  systemPhrase,
  setSystemPhrase,
  setGreetingTemplateModalActive,
  isUsingATemplate,
  selectedLanguages,
  languageGreetings,
  languageExamples,
  stepState,
  businessType,
  handleStepTransition,
  step5StateMutation,
  step5Validation,
  stepLoading,
}) => {
  return (
    <>
      <div className={classes.box}>
        <Fade duration={1000} bottom>
          <h3
            className={classes.heading}
            style={{
              textAlign: 'center',
              marginTop: '1rem',
            }}
          >
            Greeting Phrase
          </h3>
          <div className={classes.subtitle} style={{ textAlign: 'center' }}>
            This is the opening line your receptionist will use when answering the phone. Shorter greeting phrases do
            better.
          </div>
          {React.createElement(() => {
            posthog.capture('greeting_phrase_entered');
            return null;
          })}

          <textarea
            className={classes.input + ' ' + classes.textarea}
            style={{ fontFamily: 'Quicksand, sans-serif' }}
            required
            rows={7}
            value={systemPhrase}
            onChange={event => setSystemPhrase(event.target.value)}
            placeholder={`e.g. Hi, welcome to ${businessName}. We're currently doing a 10% off summer sale. I can help you book an appointment or answer any questions you may have. How may I help you?`}
          />

          <button
            className={classes.whiteButton + ' ' + classes.flexCentered}
            style={{
              marginTop: '1rem',
              width: 'max-content',
              maxWidth: 'max-content',
            }}
            onClick={event => {
              event.preventDefault(); // Prevent the button from scrolling up
              setGreetingTemplateModalActive(true);
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                style={{
                  strokeWidth: '1.5px',
                  height: '16px',
                  width: '16px',
                  marginRight: '8px',
                }}
                viewBox='0 0 24 24'
                stroke-width='1.5'
                stroke='currentColor'
                aria-hidden='true'
              >
                <path
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  d='M2.25 12.75V12A2.25 2.25 0 014.5 9.75h15A2.25 2.25 0 0121.75 12v.75m-8.69-6.44l-2.12-2.12a1.5 1.5 0 00-1.061-.44H4.5A2.25 2.25 0 002.25 6v12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9a2.25 2.25 0 00-2.25-2.25h-5.379a1.5 1.5 0 01-1.06-.44z'
                ></path>
              </svg>
              {isUsingATemplate && systemPhrase ? `Using ${businessType} Template` : 'Use a Template'}
            </div>
          </button>

          {selectedLanguages.map(language =>
            language !== 'English' ? (
              <>
                <label
                  key={language}
                  className={classes.heading}
                  style={{
                    marginTop: '1rem',
                    display: 'block',
                  }}
                >
                  Greeting Phrase for {language}
                </label>
                <p className={classes.subtitle}>
                  {' '}
                  Please type this in {language}. This is the opening line your receptionist will use when answering the
                  phone if the caller selects {language}{' '}
                </p>
                <textarea
                  type='text'
                  value={languageGreetings[language]}
                  onChange={event => {
                    handleGreetingChange(language, event.target.value);
                  }}
                  style={{ fontFamily: 'Quicksand, sans-serif' }}
                  required
                  className={classes.input + ' ' + classes.textarea}
                  placeholder={languageExamples[language]}
                  rows={5}
                />
              </>
            ) : (
              <></>
            )
          )}

          <Button
            type='primary'
            onClick={() => handleStepTransition(stepState + 1, step5Validation, step5StateMutation)}
            className={classes.button + ' ' + classes.centered}
            style={{ marginTop: '2rem' }}
            loading={stepLoading}
          >
            Continue
          </Button>
        </Fade>
      </div>
    </>
  );
};

export default Step4A;
