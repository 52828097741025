import { Select, notification } from 'antd';
import momentTimeZone from 'moment-timezone';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { getLoggedInUser } from '@/store/account/selector';
import { useLocalTimezone } from 'src/common/hooks/useLocalTimezone';
import { useMutation } from '@tanstack/react-query';
import { updateUserTimeZone } from '@/api/logs';
import { useDispatch } from 'react-redux';
import { setUserTimeZone } from '@/store/account/slice';

const AddTimeZone: React.FC = () => {
  const dispatch = useDispatch();
  const currentUser = useAppSelector(getLoggedInUser);
  const { timeZone, setTimeZone } = useLocalTimezone(currentUser?.time_zone);

  const timeZoneList = momentTimeZone.tz.names();

  const updateTimeZoneMutation = useMutation({
    mutationKey: ['updateTimeZone'],
    mutationFn: updateUserTimeZone,
    onSuccess(_, values) {
      dispatch(setUserTimeZone({ timeZone: values.timeZone }));
      notification.success({
        key: 'form-success',
        message: 'Time Zone has been saved successfully!',
        duration: 3,
      });
    },
  });

  const handleTimeZoneChange = (value: string) => {
    setTimeZone(value);
    updateTimeZoneMutation.mutate({ timeZone: value });
  };
  return (
    <div>
      <Select
        value={timeZone}
        onChange={handleTimeZoneChange}
        showSearch
        placeholder='Select a time zone'
        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
        options={timeZoneList.map(zone => ({ label: zone, value: zone }))}
        className='tz-filter'
      />
    </div>
  );
};

export default AddTimeZone;
