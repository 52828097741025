import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { generateRandomColour } from '../utils/helper';
import { countries } from './countriesData';
import { prod } from './config';
import posthog from 'posthog-js';
import {
  businessInformationsTemplates,
  businessNameTemplates,
  greetingsPromptTemplates,
  textingWorkflowsTemplates,
  callTransferTemplates,
} from './constants/prompt-templates';
import classes from './RegisterPageV3.module.css';
import { soundMapping } from '../constants/voiceModels';
import { notification } from 'antd';
import { AppRoutes } from '../enums/ERoutes';
import Step0 from './Register/Step0';
import Step1 from './Register/Step1';
import Step2 from './Register/Step2';
import Step3 from './Register/Step3';
import Step4 from './Register/Step4';
import Step4A from './Register/Step4A';
import Step4B from './Register/Step4B';
import Step5 from './Register/Step5';
import Step5A from './Register/Step5A';
import Step5B from './Register/Step5B';
import Step6A from './Register/Step6A';
import Step6B from './Register/Step6B';
import Step7 from './Register/Step7';
import Step7A from './Register/Step7A';
import Step7B from './Register/Step7B';
import Step8 from './Register/Step8';
import Step9 from './Register/Step9';
import Step10 from './Register/Step10';
import Step6 from './Register/Step6';
import { v4 as uuidv4 } from 'uuid';
import { useMutation } from '@tanstack/react-query';
import { createClient } from '../api/user';

const RegisterPage = ({
  debrand,
  reseller_email = '',
  campaign_user_email = '',
  purchase_quotes = '',
  campaign_user_name = '',
  campaign_user_password = '',
  debranded_registration_modal_active_setter,
  debrand_modal_instructions_setter,
  logo_s3,
  reroute_to_unbranded_login,
  isCampaign,
  auto_scale = false,
  unreservedReceptionists,
  test = false,
  logoDimensions,
  features = {},
}) => {
  const [testingNumber, setTestingNumber] = useState('+1');
  const [businessName, setBusinessName] = useState('');
  const [businessType, setBusinessType] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [referralCode, setReferralCode] = useState('');
  const [findOutAboutUs, setFindOutAboutUs] = useState('');
  const [systemPhrase, setSystemPhrase] = useState('');
  const [campaignVariables, setCampaignVariables] = useState({});
  const [inputVariables, setInputVariables] = useState('');
  const [termsAndConditions, setTermsAndConditions] = useState(false);
  const [prompt, setPrompt] = useState(
    "From now on, you are a phone receptionist at that customers can call to ask questions. You always answer in one or two short, concise sentences, and you never make up information that you don't know."
  );
  const [showBusinessInformationModal, setShowBusinessInformationModal] = useState(false);
  const [showCallTransferringModal, setShowCallTransferringModal] = useState(false);
  const [showLinkTextingModal, setShowLinkTextingModal] = useState(false);
  const [companyInfo, setCompanyInfo] = useState('');
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordMatchError, setPasswordMatchError] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const captchaRef = useRef(null);
  const [stepState, setStepState] = useState(0);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [greetingTemplateModalActive, setGreetingTemplateModalActive] = useState(false);
  const [businessInformationTemplateModalActive, setBusinessInformationTemplateModalActive] = useState(false);
  const [linksTextingTemplateModalActive, setLinksTextingTemplateModalActive] = useState(false);
  const [textingTemplateModalActive, setTextingTemplateModalActive] = useState(false);
  const [callTransferTemplateModalActive, setCallTransferTemplateModalActive] = useState(false);
  const [selectedGreetingTemplate, setSelectedGreetingTemplate] = useState(false);
  const [selectedBusinessInfoTemplate, setSelectedBusinessInfoTemplate] = useState(false);
  const [selectedTextingTemplate, setSelectedTextingTemplate] = useState(false);
  const [selectedCallTemplate, setSelectedCallTemplate] = useState(false);
  const [sampleQA, setSampleQA] = useState('');
  const [isReferralUser, setIsReferralUser] = useState(false);
  const [isUsingATemplate, setIsUsingATemplate] = useState(false);
  const [voiceModel, setVoiceModel] = useState('');
  const [voiceProvider, setVoiceProvider] = useState('');
  const soundPlayerRef = useRef();
  const [createAccLoading, setCreateAccLoading] = useState(false);
  const [errors, setErrors] = useState();
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);
  const [stepLoading, setStepLoading] = useState(false);
  const navigate = useNavigate();

  const TOTAL_STEPS_FOR_SMBS = 11;
  const TOTAL_STEPS_FOR_NON_SMBS = 11;
  const TOTAL_STEPS_FOR_RESELLER = 10;

  const createClientStepByStep = useMutation({
    mutationKey: ['createClientStepByStep'],
    mutationFn: createClient,
    onSuccess: data => {
      if (data.existingData) {
        let {
          email_address,
          business_name,
          testing_number,
          system_phrase,
          business_information,
          selected_languages,
          language_greetings,
          workflows: existingWorkflows,
          callWorkflows: existingCallWorkflows,
          english_voice_selected,
          businessType: existingBusinessType,
        } = data.existingData;

        if (email_address) setEmailAddress(email_address);
        if (business_name) setBusinessName(business_name);
        if (testing_number) {
          const selectedCountry = countries.find(c => c.code === testing_number?.substring(0, 3));
          selectedCountry && setCountry(selectedCountry);
          setTestingNumber(testing_number);
        }
        if (system_phrase) setSystemPhrase(system_phrase);
        if (business_information) setCompanyInfo(business_information);
        if (existingBusinessType) {
          // not sure why we are like doing it like this but it was this in the selction
          setIsUsingATemplate(false);
          setIsUsingATemplate(true);
          setBusinessType(existingBusinessType);
        }
        if (english_voice_selected) {
          try {
            const voiceData = JSON.parse(english_voice_selected);
            setVoiceModel(voiceData.voiceId);
            setVoiceProvider(voiceData.provider);
          } catch (e) {
            console.error('Error parsing english_voice_selected:', e);
          }
        }

        if (selected_languages) {
          try {
            setSelectedLanguages(JSON.parse(selected_languages));
          } catch (e) {
            console.error('Error parsing selected_languages:', e);
            setSelectedLanguages(['English']);
          }
        }

        if (language_greetings) {
          try {
            setLanguageGreetings(JSON.parse(language_greetings));
          } catch (e) {
            console.error('Error parsing language_greetings:', e);
            setLanguageGreetings({ English: '' });
          }
        }

        if (existingWorkflows) {
          setWorkflows(existingWorkflows);
        }
        if (existingCallWorkflows) setCallWorkflows(existingCallWorkflows);

        notification.success({
          message: 'Welcome Back!',
          description: "We've restored your previous progress. You can continue where you left off.",
          duration: 4,
        });
      } else if (data.token) {
        if (isCampaign) {
          navigate('/novi-portal/login');
        } else if (!window.location.href.includes(AppRoutes.RESELLER_PORTAL)) {
          localStorage.setItem('AUTH_TOKEN', JSON.stringify(data.token));
        }

        if (!debrand) {
          navigate('/');
        }
      }
    },
    onError: err => {
      console.log('Error creating client:', err);
      notification.error({ message: err.message || 'Error creating client', duration: 1 });
    },
  });

  const handleStepTransition = async (nextStep, validationFn, stateMutationFn) => {
    console.log('fdgfdgfdgfdgf');
    try {
      setStepLoading(true);

      if (validationFn) {
        const isValid = await validationFn();
        if (!isValid) {
          return;
        }
      }

      if (stateMutationFn) {
        await stateMutationFn();
      }

      if (!reseller_email && !campaign_user_email) {
        await createClientStepByStep.mutateAsync({
          email: emailAddress,
          businessName,
          testingNumber,
          systemPhrase,
          companyInfo,
          password: password,
          referralCode,
          findOutAboutUs,
          sampleQA,
          selectedLanguages,
          languageGreetings,
          workflows,
          callWorkflows,
          businessType,
          reseller_email,
          campaign_user_email,
          purchase_quotes,
          username: username,
          english_voice_selected: JSON.stringify({
            voiceId: voiceModel,
            provider: voiceProvider,
          }),
          avatarBg: generateRandomColour(),
          voicemails_enabled: true,
          call_recording_enabled: true,
          currentStep: stepState,
          remainingSteps: Math.max(TOTAL_STEPS_FOR_SMBS - stepState, 0),
        });
      }

      setStepState(nextStep);
    } catch (error) {
      console.error('Step transition error:', error);
      notification.error({
        message: 'Error saving progress',
        description: 'Please try again or contact support if the problem persists.',
        duration: 3,
      });
    } finally {
      setStepLoading(false);
    }
  };

  const [playingModel, setPlayingModel] = useState(null); // Keeps track of the currently playing model

  const handlePlayPause = model => {
    if (playingModel === model.value) {
      // Pause the currently playing sound
      soundPlayerRef.current.pause();
      setPlayingModel(null);
    } else {
      // Play a new sound
      if (soundPlayerRef.current) {
        soundPlayerRef.current.pause();
      }
      soundPlayerRef.current = new Audio(soundMapping[model.value]);
      soundPlayerRef.current.load();

      const promise = soundPlayerRef.current.play();
      if (promise !== undefined) {
        promise
          .then(() => {
            setPlayingModel(model.value); // Set the current model as playing
          })
          .catch(error => {
            console.log(error);
          });
      }

      // Add an event listener to handle when the sound ends
      soundPlayerRef.current.onended = () => {
        setPlayingModel(null); // Reset when the sound finishes
      };
    }
  };

  /* -------------------- TEXTING WORKFLOW LOGIC ----------------- */
  const [workflows, setWorkflows] = useState([
    {
      description:
        'Send the caller a link to schedule an appointment. Execute whenever caller asks to schedule an appointment or consultation, or if they ask to speak to a human. ',
      voiceResponse:
        "Great! I've just texted you a link to schedule your appointment. Is there anything else I can help you with?",
      textMessage: 'Here is a link to schedule your appointment: [Put your link here]',
    },
  ]);

  // Add workflow function
  const addWorkflow = e => {
    e.preventDefault();
    setWorkflows([...workflows, { description: '', voiceResponse: '', textMessage: '' }]);
  };

  const handleDescriptionChange = (e, index) => {
    e.preventDefault();
    const updatedWorkflows = [...workflows];
    updatedWorkflows[index].description = e.target.value;
    setWorkflows(updatedWorkflows);
  };

  const handleVoiceResponseChange = (e, index) => {
    e.preventDefault();
    const updatedWorkflows = [...workflows];
    updatedWorkflows[index].voiceResponse = e.target.value;
    setWorkflows(updatedWorkflows);
  };

  const handleTextMessageChange = (e, index) => {
    e.preventDefault();
    const updatedWorkflows = [...workflows];
    updatedWorkflows[index].textMessage = e.target.value;
    setWorkflows(updatedWorkflows);
  };

  const removeWorkflow = (e, index) => {
    e.preventDefault();
    const updatedWorkflows = workflows.filter((_, i) => i !== index);
    setWorkflows(updatedWorkflows);
  };
  /* -------------------- END TEXTING WORKFLOW LOGIC ----------------- */

  /* -------------------- CALL WORKFLOW LOGIC ----------------- */
  const [callWorkflows, setCallWorkflows] = useState([]);

  // Add workflow function
  const addCallWorkflow = e => {
    e.preventDefault();
    setCallWorkflows([
      ...callWorkflows,
      {
        description: '',
        voiceResponse: '',
        workflowForwardingNumber: countries[0] ? countries[0].code : 0,
        country: countries[0] ? countries[0] : {},
      },
    ]);
  };

  const handleCallWorkflowDescriptionChange = (e, index) => {
    e.preventDefault();
    const updatedCallWorkflows = [...callWorkflows];
    updatedCallWorkflows[index].description = e.target.value;
    setCallWorkflows(updatedCallWorkflows);
  };

  const handleCallWorkflowVoiceResponseChange = (e, index) => {
    e.preventDefault();
    const updatedCallWorkflows = [...callWorkflows];
    updatedCallWorkflows[index].voiceResponse = e.target.value;
    setCallWorkflows(updatedCallWorkflows);
  };

  const handleCallWorkflowCountryChange = (e, index) => {
    e.preventDefault();
    const selectedCountry = countries.find(c => c.name === e.target.value);
    const updatedCallWorkflows = [...callWorkflows];
    updatedCallWorkflows[index].workflowForwardingNumber = selectedCountry.code;
    updatedCallWorkflows[index].country = selectedCountry;
    setCallWorkflows(updatedCallWorkflows);
  };

  const handleCallWorkflowForwardingNumber = (e, index) => {
    e.preventDefault();
    const updatedCallWorkflows = [...callWorkflows];
    let value = e.target.value.replace(/[^0-9+]/g, ''); // Remove non-digit characters except '+'
    if (!value.startsWith(updatedCallWorkflows[index].country.code)) {
      value = updatedCallWorkflows[index].country.code;
    }
    updatedCallWorkflows[index].workflowForwardingNumber = value;
    setCallWorkflows(updatedCallWorkflows);
  };

  const removeCallWorkflow = (e, index) => {
    e.preventDefault();
    const updatedCallWorkflows = callWorkflows.filter((_, i) => i !== index);
    setCallWorkflows(updatedCallWorkflows);
  };

  const step0StateMutation = async () => {
    posthog.capture('email_address_entered');
  };

  // Step validations
  const step0Validation = async () => {
    if (!emailAddress) {
      notification.error({ message: 'Please enter your email address', duration: 1 });
      return false;
    }
    if (!validateEmail(emailAddress)) {
      notification.error({ message: 'Please enter a valid email address', duration: 1 });
      return false;
    }
    return true;
  };

  const step1StateMutation = async () => {
    soundPlayerRef?.current?.pause();
    posthog.capture('voice_selected');
  };

  const step1Validation = async () => {
    if (voiceModel.length === 0) {
      notification.error({ message: 'Please select a voice to continue', duration: 1 });
      return false;
    }
    return true;
  };

  const step2StateMutation = async () => {
    posthog.capture('business_type_selected', { business_type: businessType });
  };

  const step2Validation = async () => {
    if (!businessType) {
      notification.error({ message: 'Please select a business type', duration: 1 });
      return false;
    }
    return true;
  };

  const step3Validation = async () => {
    if (selectedLanguages.length === 0) {
      notification.error({ message: 'Please select at least one language', duration: 1 });
      return false;
    }
    return true;
  };

  const step4StateMutation = async () => {
    posthog.capture('business_name_entered');
  };

  const step4Validation = async () => {
    if (!businessName) {
      notification.error({ message: 'Please enter the name of your business', duration: 1 });
      return false;
    }
    return true;
  };

  const step5StateMutation = async () => {
    posthog.capture('greeting_phrase_entered');
  };

  const step5Validation = async () => {
    if (!systemPhrase) {
      notification.error({ message: 'Please enter the greeting phrase', duration: 1 });
      return false;
    }
    const languagesWithoutGreeting = Object.keys(languageGreetings).filter(
      language => language !== 'English' && !languageGreetings[language]
    );
    if (languagesWithoutGreeting.length > 0) {
      notification.error({
        message: `Please enter the greeting phrase for: ${languagesWithoutGreeting.join(', ')}`,
        duration: 1,
      });
      return false;
    }
    return true;
  };

  useEffect(() => {
    window.gtag('event', 'RegisterPageView', {
      event_category: 'Pageview',
    });

    if (reseller_email) console.log('reseller email is ' + reseller_email);
    if (campaign_user_email) console.log('campaign email is ' + campaign_user_email);
  }, []);

  useEffect(() => {
    if (reseller_email) console.log('reseller email is ' + reseller_email);
    if (campaign_user_email) console.log('campaign email is ' + campaign_user_email);
  }, []);

  useEffect(() => {
    console.log('USEEFFECT RUN FOR THE FIRST TIME');
    if (!isUsingATemplate) {
      console.log('NOT USING A TEMPLATE');
      setSelectedGreetingTemplate('');
      if (!systemPhrase) {
        setSystemPhrase('');
      }
    } else {
      console.log('USING A TEMPLATE');
      console.log('BUSINESS NAME: ', businessName);
      const businessNameTemplate = businessNameTemplates.find(el => el.label === businessType)?.template;
      if (!businessName) setBusinessName(businessNameTemplate);

      const greetingTemplate = greetingsPromptTemplates.find(el => el.label === businessType)?.template;

      if (!systemPhrase) setSystemPhrase(greetingTemplate?.replace('[BUSINESS NAME HERE]', businessNameTemplate));

      const businessInfoTemplate = businessInformationsTemplates.find(el => el.label === businessType)?.template;

      if (!companyInfo) setCompanyInfo(businessInfoTemplate?.replace('[BUSINESS NAME HERE]', businessNameTemplate));
    }
  }, [businessType, isUsingATemplate]);

  useEffect(() => {
    if (!reseller_email && !campaign_user_email) {
      console.log('Creating');
      const script = document.createElement('script');
      script.id = 'ze-snippet';
      script.src = 'https://static.zdassets.com/ekr/snippet.js?key=a1895b38-5ada-4a38-b4b1-580c900fcc1e';
      script.async = true;

      document.body.appendChild(script);

      return () => {
        // Cleanup when component unmounts
        document.body.removeChild(script);
      };
    }
  }, []);

  // use effect for step state changing to fire GTag analytics
  useEffect(() => {
    // just record what step number we are on
    var eventName = 'RegisterPageStep' + stepState;
    window.gtag('event', eventName, {
      event_category: 'RegisterPage.js',
      event_label: 'Step # ' + stepState,
    });
  }, [stepState]);

  const serverURL = import.meta.env.VITE_APP_API_BASE_URL;

  if (prod) {
    console.log = function () {};
    console.error = function () {};
  }

  useEffect(() => {
    (function (w, r) {
      w._rwq = r;
      w[r] =
        w[r] ||
        function () {
          (w[r].q = w[r].q || []).push(arguments);
        };
    })(window, 'rewardful');
    let s = document.createElement('script');
    s.async = true;
    s.src = 'https://r.wdfl.co/rw.js';
    s.setAttribute('data-rewardful', '30c5c6');
    s.setAttribute('data-domains', 'myaifrontdesk.com, myaifrontdeskdashboard.com');
    document.head.appendChild(s);

    window.rewardful('ready', function () {
      if (window.Rewardful.referral) {
      }
    });
  }, []);

  useEffect(() => {
    // Create a URLSearchParams object to access the query parameters
    const queryParams = new URLSearchParams(window.location.search);

    // Get the 'referralCode' parameter
    const referralCode = queryParams.get('referralCode');

    if (referralCode) {
      // Do something with the referralCode
      console.log('Referral Code:', referralCode);
      if (referralCode) {
        // The user has a referral code, so set the state to true
        setIsReferralUser(true);
        setReferralCode(referralCode);
      }
    }
  }, []);

  const handleSubmit = async event => {
    if (!reseller_email && !campaign_user_email) {
      setStepLoading(true);
      await saveStepInDB();
      setStepLoading(false);
      return;
    }
    event.preventDefault();
    posthog.capture('create_account_button_clicked');

    setIsSubmittingForm(true);

    if (password !== confirmPassword) {
      setPasswordMatchError(true);
      return;
    }

    const campaignUsername = uuidv4();

    // Before registering user, make sure client does not exist
    setCreateAccLoading(true);
    try {
      let usernameTaken = false;

      await fetch(serverURL + '/clientExistsNoPassword', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: isCampaign ? campaignUsername : username,
        }),
      })
        .then(response => response.json())
        .then(data => {
          const status = data.status;
          console.log('Status: ' + status);
          if (status == 'true') {
            console.log('Client already exists');
            alert('This username is already registered. Please login or register with a different phone number.');
            usernameTaken = true; // Set the variable to true if the phone number is taken
            window.gtag('event', 'phone_number_exists', {
              event_category: 'RegisterPage.js',
            });
          }
        })
        .catch(error => {
          setCreateAccLoading(false);
          console.error(error);
          window.gtag('event', 'couldnt_create_client', {
            event_category: 'RegisterPage.js',
            event_label: 'error',
          });
          alert('We are having trouble registering you. Please try again or email contact@myaifrontdesk.com.');
        });

      if (usernameTaken) {
        console.log('Phone num taken, returning');
        setCreateAccLoading(false);
        return; // Return from the main function if the phone number is taken
      }
    } catch (error) {
      setCreateAccLoading(false);
      console.error(error);
    }

    var email = emailAddress;
    if (reseller_email) {
      email = reseller_email;
    }
    if (campaign_user_email) {
      email = campaign_user_email;
    }
    // Make server call using fetch to /createClient

    try {
      let featuresSubAccount = JSON.parse(JSON.stringify(features));
      let feauturesNotEmpty = Object.keys(featuresSubAccount).length > 0;
      if (test && feauturesNotEmpty) {
        featuresSubAccount.visibility.featureVisibilitySidebar = features.visibility.featureTrialVisibilitySideBar;
        featuresSubAccount.gating.featureGatingSidebar = features.gating.featureTrialGatingSideBar;
        featuresSubAccount.visibility.configureVisibility = features.visibility.configureVisibilityTrial;
        featuresSubAccount.gating.configureGating = features.gating.configureGatingTrial;
        featuresSubAccount.visibility.advanceFeaturesVisibility = features.visibility.advanceFeaturesVisibilityTrial;
        featuresSubAccount.gating.advanceFeaturesGating = features.gating.advanceFeaturesGatingTrial;
      }

      const bodyObject = {
        email,
        businessName,
        testingNumber,
        systemPhrase,
        // variables: campaignVariables,
        companyInfo,
        password: isCampaign ? uuidv4().substring(0, 8) : password,
        referralCode,
        findOutAboutUs,
        sampleQA,
        selectedLanguages, // List of selected languages
        languageGreetings, // Dict of language to greeting phrase
        workflows,
        callWorkflows,
        businessType,
        reseller_email,
        campaign_user_email,
        purchase_quotes,
        username: isCampaign ? campaignUsername : username,
        english_voice_selected: JSON.stringify({
          voiceId: voiceModel,
          provider: voiceProvider,
        }),
        auto_scale: auto_scale,
        unreservedReceptionists,
        avatarBg: generateRandomColour(),
        voicemails_enabled: true,
        call_recording_enabled: true,
        test: test,
        featuresSubAccount: featuresSubAccount,
      };

      if (isCampaign) {
        bodyObject.resellerOutbound =
          window.location.href.includes(AppRoutes.RESELLER_PORTAL) && window.location.href.includes('outbound');
      }

      let body = JSON.stringify(bodyObject);

      if (isCampaign) {
        const filteredCampaignVariables = {};
        Object.keys(campaignVariables || {}).forEach(el => {
          if (body.includes(`{{${el}}}`)) {
            filteredCampaignVariables[el] = campaignVariables[el];
          }
        });
        bodyObject.variables = filteredCampaignVariables;
        body = JSON.stringify(bodyObject);
      }

      const response = await fetch(`${serverURL}/createClient`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body,
      });

      if (!response.ok) {
        window.gtag('event', 'couldnt_create_client', {
          event_category: 'RegisterPage.js',
          event_label: 'error',
        });
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      const status = data.success;
      if (status) {
        setIsSubmittingForm(false);
        window.gtag('event', 'create_client', {
          event_category: 'RegisterPage.js',
          event_label: 'success',
        });

        if (data.token) {
          /** ToDo:: Token localstorage logic will be changed in future. For now I have just ignore saving token
           for reseller embedded receptionist **/

          if (isCampaign) {
            navigate('/novi-portal/login');
          } else if (!window.location.href.includes(AppRoutes.RESELLER_PORTAL)) {
            localStorage.setItem('AUTH_TOKEN', JSON.stringify(data.token));
          }

          if (!debrand) {
            navigate('/');
          }
        }
        // Debranded post-creation flow is different, keep them on the same page
        // No need to navigate to login page

        if (debrand && reroute_to_unbranded_login) {
          navigate('/dashboard', {
            state: { username: username, password: password },
          });
        }

        if (debrand) {
          debrand_modal_instructions_setter(true); // Open debrand modal telling reseller client is created
          debranded_registration_modal_active_setter(false); // Close existing registration modal
          return;
        }
      }
    } catch (error) {
      window.gtag('event', 'couldnt_create_client', {
        event_category: 'RegisterPage.js',
        event_label: 'error',
      });
      console.error(error);
    } finally {
      setCreateAccLoading(false);
    }
  };

  const [country, setCountry] = useState(countries[0]);

  const handleCountryChange = event => {
    const selectedCountry = countries.find(c => c.name === event.target.value);
    setCountry(selectedCountry);
    setTestingNumber(selectedCountry.code);
  };

  const handletestingNumberChange = event => {
    let value = event.target.value.replace(/[^0-9+]/g, ''); // Remove non-digit characters except '+'
    if (!value.startsWith(country.code)) {
      value = country.code + value;
    }
    setTestingNumber(value);
  };

  const handleUsernameChange = event => {
    const newValue = event.target.value?.toLowerCase();
    const allowedReg = /^(?!.*\s)[a-z0-9._#@!$%]*$/;

    setUsername(newValue);

    if (allowedReg.test(newValue)) {
      setErrors(prev => ({ ...prev, username: '' }));
    } else {
      setErrors(prev => ({ ...prev, username: 'Only these characters are allowed: a-z, 0-9, _ # @ ! $ %' }));
    }
  };

  const languages = [
    'English',
    'Spanish',
    'French',
    'German',
    'Swiss German',
    'Portuguese',
    'Japanese',
    'Mandarin',
    'Arabic',
    'Russian',
    'Hindi',
    'Dutch',
    'Romanian',
    'Armenian',
    'Korean',
    'Vietnamese',
    'Turkish',
    'Danish',
  ];

  const [selectedLanguages, setSelectedLanguages] = useState(['English']);

  const handleLanguageChange = language => {
    if (selectedLanguages.includes(language)) {
      setSelectedLanguages(selectedLanguages.filter(lang => lang !== language));
      const updatedGreetings = { ...languageGreetings };
      delete updatedGreetings[language];
      setLanguageGreetings(updatedGreetings);
    } else {
      setSelectedLanguages([...selectedLanguages, language]);
      setLanguageGreetings({
        ...languageGreetings,
        [language]: languageExamples[language],
      });
    }
  };

  const handleGreetingChange = (language, value) => {
    setLanguageGreetings({ ...languageGreetings, [language]: value });
  };

  const [languageGreetings, setLanguageGreetings] = useState({
    English: '',
  });

  const languageExamples = {
    English: 'Hello, how may I help you?',
    Spanish: 'Hola, ¿en qué puedo ayudarte?',
    French: 'Bonjour, comment puis-je vous aider ?',
    Italian: 'Ciao, come posso aiutarti?',
    German: 'Hallo, wie kann ich Ihnen helfen?',
    'Swiss German': 'Hallo, wie kann ich Ihnen helfen?',
    Portuguese: 'Olá, como posso ajudá-lo?',
    Japanese: 'こんにちは、どのようなお手伝いができますか？',
    Mandarin: '你好，我可以帮助你吗？',
    Arabic: 'مرحبًا، كيف يمكنني مساعدتك؟',
    Russian: 'Привет, чем я могу помочь вам?',
    Hindi: 'नमस्ते, मैं आपकी कैसे मदद कर सकता हूँ?',
    Romanian: 'Bună, cum te pot ajuta?',
    Armenian: 'Բարև, ինչպես կարող եմ օգնել քեզ?',
    Korean: '안녕하세요, 어떻게 도와 드릴까요?',
    Vietnamese: 'Xin chào, tôi có thể giúp gì cho bạn?',
    Turkish: 'Merhaba, size nasıl yardımcı olabilirim?',
    Danish: 'Hej, hvordan kan jeg hjælpe dig?',
  };

  const isFormValid = testingNumber && systemPhrase && companyInfo;

  const header = (
    <div
      className={classes.header}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '16px 0',
        fontSize: '18px',
        background: '#f8f8f8',
        color: 'rgb(119, 129, 145)',
      }}
    >
      <button
        className={classes.navItem}
        onClick={() => window.open('https://www.myaifrontdesk.com', '_blank')}
        style={{
          padding: '8px 12px',
          border: 'none',
          background: 'transparent',
          color: 'inherit',
        }}
      >
        Product
      </button>
      <button
        className={classes.navItem}
        onClick={() => (window.location.href = '/')}
        style={{
          textDecoration: 'none',
          cursor: 'pointer',
          border: 'none',
          padding: '8px 12px',
          background: 'transparent',
          color: 'inherit',
        }}
      >
        Login
      </button>
      <button
        className={classes.navItem}
        onClick={() => window.open('https://www.myaifrontdesk.com/book-a-consultation', '_blank')}
        style={{
          textDecoration: 'none',
          cursor: 'pointer',
          padding: '8px 12px',
          border: 'none',
          background: 'transparent',
          color: 'inherit',
        }}
      >
        Book Consultation
      </button>
      <button
        className={classes.navItem}
        onClick={() =>
          (window.location.href =
            'mailto:contact@myaifrontdesk.com?subject=Contact Us&body=Please let us know how we can assist you.')
        }
        style={{
          textDecoration: 'none',
          cursor: 'pointer',
          padding: '8px 12px',
          border: 'none',
          background: 'transparent',
          color: 'inherit',
        }}
      >
        Contact
      </button>
    </div>
  );

  const welcomeScreen = <></>;

  const [variables, setVariables] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [variableName, setVariableName] = useState('');
  const [isMandatory, setIsMandatory] = useState(true);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const insertVariable = name => {
    const variable = `{{${name}}}`;
    const textArea = document.getElementById('systemPhraseTextArea');
    const startPos = textArea.selectionStart;
    const endPos = textArea.selectionEnd;
    const newText = systemPhrase.slice(0, startPos) + variable + systemPhrase.slice(endPos);
    setSystemPhrase(newText);
    setVariables([...variables, { name, isMandatory }]);
    closeModal();
  };

  const handleEditVariable = variableName => {
    // Implement edit variable functionality
    console.log('Edit variable:', variableName);
  };

  const validateEmail = email => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const saveStepInDB = async () => {
    try {
      const bodyObject = {
        email: emailAddress,
        businessName,
        testingNumber,
        systemPhrase,
        companyInfo,
        password: password,
        referralCode,
        findOutAboutUs,
        sampleQA,
        selectedLanguages,
        languageGreetings,
        workflows,
        callWorkflows,
        businessType,
        reseller_email,
        campaign_user_email,
        purchase_quotes,
        username: username,
        english_voice_selected: JSON.stringify({
          voiceId: voiceModel,
          provider: voiceProvider,
        }),
        avatarBg: generateRandomColour(),
        voicemails_enabled: true,
        call_recording_enabled: true,
      };
      if (!reseller_email && !campaign_user_email) {
        bodyObject.currentStep = stepState;
        bodyObject.remaingingSteps = Math.max(stepState - TOTAL_STEPS_FOR_SMBS, 0);
        createClientStepByStep.mutate(bodyObject);
      }
    } catch (err) {
      console.log(err);
      notification.error({ message: 'Error in saving step', duration: 1 });
    }
  };

  return (
    <>
      <div
        className={(isDarkMode ? classes.darkMode : '') + ' ' + classes.rootPage}
        style={{
          height: '100%',
          overflow: 'auto',
          overflowX: 'hidden',
          transition: '.4s ease',
        }}
      >
        {!debrand && header}

        <script src='https://www.google.com/recaptcha/api.js' async defer></script>

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            maxwidth: '100%',
            padding: '1rem 1rem 3rem',
            borderRadius: '10px',
            margin: '1rem auto',
            minHeight: 'calc(100vh - 163px)',
            position: 'relative',
          }}
        >
          <div
            style={{
              color: 'rgb(40, 42, 48)',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '1rem',
              // overflow: "auto",
              '@media screen and (max-width: 480px)': {
                padding: '0.5rem',
              },
              '@media screen and (max-width: 375px)': {
                padding: '0.25rem',
              },
            }}
          >
            <div
              style={{
                width: '100%',
              }}
            >
              {debrand && (
                <>
                  {logo_s3 && (
                    <img
                      className={classes.centered}
                      style={{
                        position: 'absolute',
                        top: 10,
                        left: 10,
                        margin: '1rem',
                        width: `${logoDimensions?.width?.N || 64}px`,
                        height: `${logoDimensions?.height?.N || 64}px`,
                        objectFit: 'contain',
                      }}
                      width='50rem'
                      height='50rem'
                      src={logo_s3}
                      alt='AI Receptionist'
                    />
                  )}
                </>
              )}

              {stepState === 0 && !reseller_email && !campaign_user_email && (
                <Step8
                  emailAddress={emailAddress}
                  setEmailAddress={setEmailAddress}
                  businessName={businessName}
                  handleStepTransition={handleStepTransition}
                  step0Validation={step0Validation}
                  step0StateMutation={step0StateMutation}
                  stepLoading={stepLoading}
                />
              )}

              {((stepState === 1 && !reseller_email && !campaign_user_email) ||
                (stepState === 0 && (reseller_email || campaign_user_email))) && (
                <Step0
                  voiceModel={voiceModel}
                  isCampaign={isCampaign}
                  setVoiceModel={setVoiceModel}
                  setVoiceProvider={setVoiceProvider}
                  handleStepTransition={handleStepTransition}
                  step1Validation={step1Validation}
                  step1StateMutation={step1StateMutation}
                  stepLoading={stepLoading}
                  handlePlayPause={handlePlayPause}
                  playingModel={playingModel}
                />
              )}

              {stepState === 2 && (
                <Step1
                  stepState={stepState}
                  setIsUsingATemplate={setIsUsingATemplate}
                  isCampaign={isCampaign}
                  businessType={businessType}
                  setBusinessType={setBusinessType}
                  isUsingATemplate={isUsingATemplate}
                  handleStepTransition={handleStepTransition}
                  step2Validation={step2Validation}
                  step2StateMutation={step2StateMutation}
                  stepLoading={stepLoading}
                />
              )}

              {stepState === 3 && (
                <Step2
                  stepState={stepState}
                  languages={languages}
                  handleLanguageChange={handleLanguageChange}
                  selectedLanguages={selectedLanguages}
                  handleStepTransition={handleStepTransition}
                  step3Validation={step3Validation}
                  stepLoading={stepLoading}
                />
              )}

              {stepState === 4 && (
                <Step3
                  isCampaign={isCampaign}
                  reseller_email={reseller_email}
                  campaign_user_email={campaign_user_email}
                  businessName={businessName}
                  setBusinessName={setBusinessName}
                  stepState={stepState}
                  handleStepTransition={handleStepTransition}
                  step4Validation={step4Validation}
                  step4StateMutation={step4StateMutation}
                  stepLoading={stepLoading}
                />
              )}

              {isCampaign
                ? stepState === 5 && (
                    <Step4
                      setSystemPhrase={setSystemPhrase}
                      setCampaignVariables={setCampaignVariables}
                      systemPhrase={systemPhrase}
                      campaignVariables={campaignVariables}
                      businessName={businessName}
                      handleStepTransition={handleStepTransition}
                      step5Validation={step5Validation}
                      step5StateMutation={step5StateMutation}
                      stepLoading={stepLoading}
                      stepState={stepState}
                    />
                  )
                : stepState === 5 && (
                    <Step4A
                      businessName={businessName}
                      systemPhrase={systemPhrase}
                      setSystemPhrase={setSystemPhrase}
                      setGreetingTemplateModalActive={setGreetingTemplateModalActive}
                      isUsingATemplate={isUsingATemplate}
                      selectedLanguages={selectedLanguages}
                      languageGreetings={languageGreetings}
                      languageExamples={languageExamples}
                      stepState={stepState}
                      businessType={businessType}
                      handleStepTransition={handleStepTransition}
                      step5Validation={step5Validation}
                      step5StateMutation={step5StateMutation}
                      stepLoading={stepLoading}
                    />
                  )}

              {stepState === 5 && greetingTemplateModalActive && <div className={classes.modalOverlay}></div>}
              {stepState === 5 && (
                <Step4B
                  setGreetingTemplateModalActive={setGreetingTemplateModalActive}
                  setSelectedGreetingTemplate={setSelectedGreetingTemplate}
                  greetingsPromptTemplates={greetingsPromptTemplates}
                  selectedGreetingTemplate={selectedGreetingTemplate}
                  setSystemPhrase={setSystemPhrase}
                  greetingTemplateModalActive={greetingTemplateModalActive}
                />
              )}

              {stepState === 6 && (
                <Step5
                  setShowBusinessInformationModal={setShowBusinessInformationModal}
                  setStepState={setStepState}
                  stepState={stepState}
                />
              )}

              {stepState === 6 && showBusinessInformationModal && <div className={classes.modalOverlay} />}

              {stepState === 6 && (
                <Step5A
                  setShowBusinessInformationModal={setShowBusinessInformationModal}
                  companyInfo={companyInfo}
                  campaignVariables={campaignVariables}
                  setCompanyInfo={setCompanyInfo}
                  setCampaignVariables={setCampaignVariables}
                  businessName={businessName}
                  setBusinessInformationTemplateModalActive={setBusinessInformationTemplateModalActive}
                  isUsingATemplate={isUsingATemplate}
                  businessType={businessType}
                  stepState={stepState}
                  showBusinessInformationModal={showBusinessInformationModal}
                  isCampaign={isCampaign}
                  handleStepTransition={handleStepTransition}
                  stepLoading={stepLoading}
                  createClientStepByStep={createClientStepByStep}
                />
              )}

              {stepState === 6 && businessInformationTemplateModalActive && (
                <div className={classes.modalOverlay}></div>
              )}

              {stepState === 6 && (
                <Step5B
                  setSelectedBusinessInfoTemplate={setSelectedBusinessInfoTemplate}
                  businessInformationsTemplates={businessInformationsTemplates}
                  businessName={businessName}
                  greetingsPromptTemplates={greetingsPromptTemplates}
                  selectedBusinessInfoTemplate={selectedBusinessInfoTemplate}
                  setCompanyInfo={setCompanyInfo}
                  setBusinessInformationTemplateModalActive={setBusinessInformationTemplateModalActive}
                  businessInformationTemplateModalActive={businessInformationTemplateModalActive}
                />
              )}

              {stepState === 7 && (
                <Step6
                  setStepState={setStepState}
                  stepState={stepState}
                  setShowLinkTextingModal={setShowLinkTextingModal}
                />
              )}

              {stepState === 7 && showLinkTextingModal && <div className={classes.modalOverlay} />}
              {stepState === 7 && (
                <Step6A
                  showLinkTextingModal={showLinkTextingModal}
                  workflows={workflows}
                  setShowLinkTextingModal={setShowLinkTextingModal}
                  isCampaign={isCampaign}
                  campaignVariables={campaignVariables}
                  handleDescriptionChange={handleDescriptionChange}
                  setCampaignVariables={setCampaignVariables}
                  handleVoiceResponseChange={handleVoiceResponseChange}
                  removeWorkflow={removeWorkflow}
                  setTextingTemplateModalActive={setTextingTemplateModalActive}
                  isUsingATemplate={isUsingATemplate}
                  systemPhrase={systemPhrase}
                  businessType={businessType}
                  stepState={stepState}
                  addWorkflow={addWorkflow}
                  handleStepTransition={handleStepTransition}
                  stepLoading={stepLoading}
                />
              )}

              {stepState === 7 && textingTemplateModalActive && <div className={classes.modalOverlay}></div>}

              {stepState === 7 && (
                <Step6B
                  setTextingTemplateModalActive={setTextingTemplateModalActive}
                  setSelectedTextingTemplate={setSelectedTextingTemplate}
                  textingWorkflowsTemplates={textingWorkflowsTemplates}
                  selectedTextingTemplate={selectedTextingTemplate}
                  textingTemplateModalActive={textingTemplateModalActive}
                  setWorkflows={setWorkflows}
                  workflows={workflows}
                />
              )}

              {stepState === 8 && (
                <Step7
                  setShowCallTransferringModal={setShowCallTransferringModal}
                  reseller_email={reseller_email}
                  setStepState={setStepState}
                  stepState={stepState}
                />
              )}

              {stepState === 8 && (
                <Step7A
                  callWorkflows={callWorkflows}
                  setShowCallTransferringModal={setShowCallTransferringModal}
                  isCampaign={isCampaign}
                  campaignVariables={campaignVariables}
                  handleCallWorkflowDescriptionChange={handleCallWorkflowDescriptionChange}
                  setCampaignVariables={setCampaignVariables}
                  handleCallWorkflowVoiceResponseChange={handleCallWorkflowVoiceResponseChange}
                  handleCallWorkflowCountryChange={handleCallWorkflowCountryChange}
                  countries={countries}
                  handleCallWorkflowForwardingNumber={handleCallWorkflowForwardingNumber}
                  removeCallWorkflow={removeCallWorkflow}
                  addCallWorkflow={addCallWorkflow}
                  setCallTransferTemplateModalActive={setCallTransferTemplateModalActive}
                  isUsingATemplate={isUsingATemplate}
                  systemPhrase={systemPhrase}
                  businessType={businessType}
                  reseller_email={reseller_email}
                  stepState={stepState}
                  showCallTransferringModal={showCallTransferringModal}
                  callTransferTemplateModalActive={callTransferTemplateModalActive}
                  handleStepTransition={handleStepTransition}
                  stepLoading={stepLoading}
                />
              )}

              {stepState === 8 && callTransferTemplateModalActive && <div className={classes.modalOverlay}></div>}

              {stepState === 8 && (
                <Step7B
                  setCallTransferTemplateModalActive={setCallTransferTemplateModalActive}
                  setSelectedCallTemplate={setSelectedCallTemplate}
                  callTransferTemplates={callTransferTemplates}
                  selectedCallTemplate={selectedCallTemplate}
                  callTransferTemplateModalActive={callTransferTemplateModalActive}
                />
              )}

              {((stepState == 9 && !reseller_email) ||
                (stepState === 15 && reseller_email && !campaign_user_email)) && (
                <Step9
                  reseller_email={reseller_email}
                  campaign_user_email={campaign_user_email}
                  country={country}
                  handleCountryChange={handleCountryChange}
                  handletestingNumberChange={handletestingNumberChange}
                  testingNumber={testingNumber}
                  countries={countries}
                  stepState={stepState}
                  handleStepTransition={handleStepTransition}
                  stepLoading={stepLoading}
                />
              )}

              {(stepState === 10 || (reseller_email && stepState === 9)) && (
                <Step10
                  isCampaign={isCampaign}
                  reseller_email={reseller_email}
                  campaign_user_email={campaign_user_email}
                  username={username}
                  handleUsernameChange={handleUsernameChange}
                  errors={errors}
                  password={password}
                  setPassword={setPassword}
                  setErrors={setErrors}
                  confirmPassword={confirmPassword}
                  setConfirmPassword={setConfirmPassword}
                  debrand={debrand}
                  termsAndConditions={termsAndConditions}
                  setTermsAndConditions={setTermsAndConditions}
                  createAccLoading={createAccLoading}
                  handleSubmit={handleSubmit}
                />
              )}

              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: '2rem',
                  justifyContent: 'center',
                  position: 'absolute',
                  left: '50%',
                  transform: 'translateX(-50%)',
                  bottom: '1rem',
                }}
              >
                <div
                  style={{
                    marginRight: '1rem',
                    display: 'flex',
                    alignItems: 'center',
                    opacity: stepState === 0 ? 0.5 : 1,
                    pointerEvents: stepState === 0 ? 'none' : 'all',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    setStepState(prev => {
                      let newState = prev - 1;
                      // skipping language for campaign
                      if (newState === 2) newState = 1;
                      if (newState <= 0) return 0;
                      return newState;
                    });
                  }}
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    xmlnsXlink='http://www.w3.org/1999/xlink'
                    fill='#000000'
                    height='20px'
                    width='20px'
                    version='1.1'
                    id='Capa_1'
                    viewBox='0 0 219.151 219.151'
                    xmlSpace='preserve'
                  >
                    <g>
                      <path d='M109.576,219.151c60.419,0,109.573-49.156,109.573-109.576C219.149,49.156,169.995,0,109.576,0S0.002,49.156,0.002,109.575   C0.002,169.995,49.157,219.151,109.576,219.151z M109.576,15c52.148,0,94.573,42.426,94.574,94.575   c0,52.149-42.425,94.575-94.574,94.576c-52.148-0.001-94.573-42.427-94.573-94.577C15.003,57.427,57.428,15,109.576,15z' />
                      <path d='M94.861,156.507c2.929,2.928,7.678,2.927,10.606,0c2.93-2.93,2.93-7.678-0.001-10.608l-28.82-28.819l83.457-0.008   c4.142-0.001,7.499-3.358,7.499-7.502c-0.001-4.142-3.358-7.498-7.5-7.498l-83.46,0.008l28.827-28.825   c2.929-2.929,2.929-7.679,0-10.607c-1.465-1.464-3.384-2.197-5.304-2.197c-1.919,0-3.838,0.733-5.303,2.196l-41.629,41.628   c-1.407,1.406-2.197,3.313-2.197,5.303c0.001,1.99,0.791,3.896,2.198,5.305L94.861,156.507z' />
                    </g>
                  </svg>
                </div>
                {new Array(
                  reseller_email
                    ? TOTAL_STEPS_FOR_RESELLER
                    : campaign_user_email
                      ? TOTAL_STEPS_FOR_NON_SMBS
                      : TOTAL_STEPS_FOR_SMBS
                )
                  .fill(0)
                  .map((el, idx) => {
                    return (
                      <div
                        key={'dot-indicator-' + idx}
                        style={{
                          padding: '6px',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          // skipping language for campaign
                          if (idx === 2) {
                            setStepState(1);
                            return;
                          }
                          if (idx < stepState) setStepState(idx);
                        }}
                      >
                        <div className={classes.dot + ' ' + (idx === stepState ? classes.active : '')}></div>
                      </div>
                    );
                  })}
                <div style={{ visibility: 'hidden' }}>➡️</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegisterPage;
