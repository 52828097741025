import React from 'react';
import { Badge, Button, Card, Empty, List, Modal, notification, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { OverViewCardInfo } from '../OverviewCardInfo';
import { LabelWithDescription } from '@/components/LabelWithdescription';
import { DeleteOutline, EditOutlined, UnfoldLess, UnfoldMore } from '@mui/icons-material';
import ApiWorkflowForm from './ApiWorkflowForm';
import classNames from 'classnames';
import { useState } from 'react';
import { CustomModal } from '@/components/CustomModal';
import { QueryObserverResult, RefetchOptions, UseMutationResult } from '@tanstack/react-query';
import { MODAL_IDS } from 'src/enums/EModal';
import './ApiWorkflowInfo.scss';
import { User } from '@/types/TAccounts';
import { useAppDispatch } from '@/store/hooks/useAppDispatch';
import { openModal } from '@/store/modal/slice';
import { TUTORIALS } from 'src/enums/ETutorials';
import { API_WORKFLOW_TYPES } from 'src/constants';

export const ApiWorkflowInfo: React.FC<{
  data: any;
  refetch: (options?: RefetchOptions) => Promise<QueryObserverResult<User, Error>>;
  mutation: UseMutationResult<{}, Error, any, unknown>;
  debrand?: boolean;
}> = ({ data, refetch, mutation, debrand }) => {
  let apiWorkflowData: any = {};

  if (typeof data?.call_webhook_workflows === 'string') {
    const parsedData = JSON.parse(data.call_webhook_workflows);

    if (Array.isArray(parsedData)) {
      apiWorkflowData = { call_webhook_workflows: parsedData };
    } else if (typeof parsedData === 'object') {
      apiWorkflowData = parsedData;
    }
  } else {
    apiWorkflowData = { call_webhook_workflows: [] };
  }

  console.info('data', data);
  console.info('apiWorkflowData', apiWorkflowData);
  const dispatch = useAppDispatch();
  const [expandedItems, setExpandedItems] = useState<number[]>([]);

  const toggleExpandCollapse = (index: number) => {
    setExpandedItems(items =>
      items.includes(index) ? items.filter(itemIndex => itemIndex !== index) : [...items, index]
    );
  };

  const [formInitialValues, setFormInitialValues] = useState<any>(null);
  const [editWorkflowIndex, setEditWorkflowIndex] = useState<number>(-1);

  const handleEditScenario = (scenario: any, index: number) => {
    const initialValues = {
      call_webhook_workflows: [
        {
          id: scenario.id,
          name: scenario.name,
          prompt: scenario.prompt,
          scenario: scenario.scenario,
          workflowType: scenario.workflowType,
          webhookInformation: scenario.webhookInformation,
          extractedCallInfo: scenario.extractedCallInfo,
          timeoutSeconds: scenario.timeoutSeconds,
          speechToEmit: scenario.speechToEmit,
          isQueryParams: scenario.isQueryParams,
        },
      ],
    };
    setEditWorkflowIndex(index);
    setFormInitialValues(initialValues);
    dispatch(
      openModal({
        modalId: MODAL_IDS.EDIT_API_WORKFLOW_MODAL,
      })
    );
  };

  const handleDeleteScenario = (index: number) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this scenario?',
      content: `Scenario ${index + 1} will be permanently deleted.`,
      okButtonProps: { danger: true },
      okText: 'Delete',
      onOk: () => {
        try {
          // Retrieve the current form values
          const currentWorkflows = apiWorkflowData?.call_webhook_workflows || []; // Use apiWorkflowData instead of form.getFieldValue

          // Filter out the scenario that needs to be deleted based on the index
          const updatedWorkflows = currentWorkflows.filter((_: any, i: number) => i !== index);

          // Call mutation to update the backend with the updated workflows
          mutation.mutate(
            { call_webhook_workflows: JSON.stringify(updatedWorkflows) },
            {
              onSuccess: () => {
                notification.success({ message: `Scenario ${index + 1} deleted successfully.` });
                refetch();
              },
              onError: () => {
                notification.error({ message: 'Something went wrong, please try again later!' });
              },
            }
          );
        } catch (err) {
          console.log(err);
          notification.error({ message: 'Something went wrong, please try again later!' });
        }
      },
    });
  };

  const InfoComponent = () => {
    return (
      <Spin spinning={mutation.isPending} indicator={<LoadingOutlined style={{ fontSize: '48px' }} spin />}>
        {apiWorkflowData && apiWorkflowData?.call_webhook_workflows?.length ? (
          apiWorkflowData?.call_webhook_workflows?.map((apiWorkflow: any, index: number) => (
            <Card
              key={index}
              className='mt-3 mb-3'
              title={
                <div className={`${baseClass}_button-container`}>
                  <span className='font-bold text-base'>{apiWorkflow.name}</span>
                  <div>
                    <Button type='primary' danger icon={<DeleteOutline />} onClick={() => handleDeleteScenario(index)}>
                      <div>Delete</div>
                    </Button>
                    <Button
                      type='primary'
                      className='ml-2'
                      icon={<EditOutlined />}
                      onClick={() => handleEditScenario(apiWorkflow, index)}
                    >
                      <div>Edit</div>
                    </Button>
                    <Button
                      type='primary'
                      className='ml-2'
                      icon={expandedItems.includes(index) ? <UnfoldLess /> : <UnfoldMore />}
                      onClick={() => toggleExpandCollapse(index)}
                    >
                      <div>{expandedItems.includes(index) ? 'Collapse' : 'Expand'}</div>
                    </Button>
                  </div>
                </div>
              }
            >
              {expandedItems.includes(index) ? (
                <>
                  {/* Scenario Information */}

                  <div className='border-b'>
                    <div className='border-b'>
                      <LabelWithDescription customClassName='mt-2' label='Workflow Type' />
                      <span className='pl-4 w-8/12 mt-2 pt-2 pb-4'>
                        {apiWorkflow.workflowType == 2 ? 'Knowledge Base Loader (Beginning of call)' : 'Scenario'}
                      </span>
                    </div>
                  </div>

                  {apiWorkflow.workflowType == API_WORKFLOW_TYPES.knowledgeBaseLoader && (
                    <div className='border-b'>
                      <div className='border-b'>
                        <LabelWithDescription customClassName='mt-2' label='User Prompt' />
                        <span className='pl-4 w-8/12 mt-2 pt-2 pb-4'>{apiWorkflow.prompt}</span>
                      </div>
                    </div>
                  )}

                  {apiWorkflow.workflowType != API_WORKFLOW_TYPES.knowledgeBaseLoader && (
                    <div className='border-b'>
                      <div className='border-b'>
                        <LabelWithDescription
                          customClassName='mt-2'
                          label='Scenario Information'
                          description='This webhook will be triggered when a specific scenario is met during the call.'
                        />
                        <span className='pl-4 w-8/12 mt-2 pt-2 pb-4'>{apiWorkflow.scenario}</span>
                      </div>
                      <div>
                        <span className='!flex justify-between mb-4 mt-2'>
                          <LabelWithDescription
                            customClassName='mt-2'
                            label='This webhook can trigger multiple times in a call'
                          />
                          <Badge className='badge-success' count='On' />
                        </span>
                      </div>
                    </div>
                  )}

                  {/* Extracted Call Information Section */}
                  {apiWorkflow.workflowType != 2 && (
                    <div className='border-b'>
                      <div className='border-b'>
                        <LabelWithDescription
                          customClassName='mt-2'
                          label='Extract Call Information'
                          description='Information will be extracted from the call into variables and these variables will be used in your webhook parameters.'
                        />
                      </div>
                      {apiWorkflow.extractedCallInfo && apiWorkflow.extractedCallInfo.length > 0 ? (
                        <>
                          <div className='mt-2'>
                            <span className='!flex justify-between mb-4'>
                              <LabelWithDescription customClassName='mt-2' label='Query Params' />
                              <Badge
                                className={classNames({
                                  'badge-success': !!apiWorkflow.isQueryParams,
                                  'badge-error': !apiWorkflow.isQueryParams,
                                })}
                                count={apiWorkflow.isQueryParams ? 'On' : 'Off'}
                              />
                            </span>
                          </div>
                          <div className='items-center mb-4'>
                            <List>
                              {apiWorkflow.extractedCallInfo.map((info: any, infoIndex: number) => (
                                <List.Item key={infoIndex} className='ml-4'>
                                  <div className='flex w-full justify-between'>
                                    <strong>{info.name}</strong>
                                    <span>Type: {info.type}</span>
                                    <span>Description: {info.description}</span>
                                  </div>
                                </List.Item>
                              ))}
                            </List>
                          </div>
                        </>
                      ) : (
                        <span className='ml-4 text-gray-300 mb-2'>No Extracted Call Information</span>
                      )}
                    </div>
                  )}

                  {/* Webhook Details */}
                  <div className='border-b'>
                    <LabelWithDescription
                      customClassName='mt-2'
                      label='Webhook'
                      description='Configure the webhook URL and fields like headers, method, body, and response data information.'
                    />
                    <div className='mt-2 border-b mb-4'>
                      <span className='!flex justify-between mb-4'>
                        <strong>HTTP Method:</strong>
                        <span>{apiWorkflow.webhookInformation.method}</span>
                      </span>
                      <span className='!flex justify-between mb-4'>
                        <strong>URL:</strong>
                        <span>{apiWorkflow.webhookInformation.url}</span>
                      </span>
                    </div>

                    {/* Headers Section */}
                    <div className='items-center mb-4 border-b'>
                      <span className='!flex justify-between mb-4'>
                        <LabelWithDescription
                          customClassName='mt-2'
                          label='Headers'
                          description='Headers for the webhook'
                        />
                        <Badge
                          className={classNames({
                            'badge-success': !!apiWorkflow.webhookInformation?.headers?.length,
                            'badge-error': !apiWorkflow.webhookInformation?.headers?.length,
                          })}
                          count={apiWorkflow.webhookInformation?.headers?.length ? 'On' : 'Off'}
                        />
                      </span>

                      {apiWorkflow.webhookInformation?.headers && apiWorkflow.webhookInformation?.headers.length > 0 ? (
                        <div className='items-center mb-4'>
                          <List>
                            {apiWorkflow.webhookInformation.headers.map((header: any, headerIndex: number) => (
                              <List.Item key={headerIndex} className='ml-4'>
                                <strong>{header.key}</strong>
                                <div className='flex w-3/12 justify-between'>
                                  <span>{header.value}</span>
                                </div>
                              </List.Item>
                            ))}
                          </List>
                        </div>
                      ) : (
                        <span className='ml-4 text-gray-300 mb-2'>No Headers</span>
                      )}
                    </div>

                    {apiWorkflow.workflowType != 2 && (
                      <>
                        {/* Request Body Section */}
                        <div className='border-b'>
                          <span className='!flex justify-between mb-4'>
                            <strong>Request Body</strong>
                            <Badge
                              className={classNames({
                                'badge-success': !!apiWorkflow.webhookInformation?.body,
                                'badge-error': !apiWorkflow.webhookInformation?.body,
                              })}
                              count={apiWorkflow.webhookInformation?.body ? 'On' : 'Off'}
                            />
                          </span>

                          {apiWorkflow.webhookInformation.body ? (
                            <div className='items-center mb-4'>
                              <List>
                                <List.Item className='ml-4'>
                                  <pre>{JSON.stringify(apiWorkflow.webhookInformation.body, null, 2)}</pre>
                                </List.Item>
                              </List>
                            </div>
                          ) : (
                            <span className='ml-4 text-gray-300 mb-2'>No Request Body</span>
                          )}
                        </div>

                        {/* Response Data Information */}
                        <div className='border-b'>
                          <span className='!flex justify-between mb-4 mt-4'>
                            <strong>Response Data Information</strong>
                            <Badge
                              className={classNames({
                                'badge-success': !!apiWorkflow.webhookInformation?.responseData?.length,
                                'badge-error': !apiWorkflow.webhookInformation?.responseData?.length,
                              })}
                              count={apiWorkflow.webhookInformation?.responseData?.length ? 'On' : 'Off'}
                            />
                          </span>

                          {apiWorkflow.webhookInformation.responseData &&
                          apiWorkflow.webhookInformation.responseData.length > 0 ? (
                            <div className='items-center mb-4'>
                              <List>
                                {apiWorkflow.webhookInformation.responseData.map(
                                  (response: any, responseIndex: number) => (
                                    <List.Item key={responseIndex} className='ml-4'>
                                      <div className='flex w-full justify-between'>
                                        <strong>{response.variableName}</strong>
                                        <span>JSON Path: {response.jsonPath}</span>
                                        <span>Prompt: {response.prompt}</span>
                                      </div>
                                    </List.Item>
                                  )
                                )}
                              </List>
                            </div>
                          ) : (
                            <span className='ml-4 text-gray-300 mb-2'>No Response Data</span>
                          )}
                        </div>
                      </>
                    )}

                    {/* Timeout Duration */}
                    <span className='!flex justify-between mb-4'>
                      <LabelWithDescription customClassName='mt-2' label='Timeout Duration' />
                      <span>{apiWorkflow.timeoutSeconds} seconds</span>
                    </span>
                  </div>

                  {/* Speech to Emit */}
                  {apiWorkflow.workflowType != API_WORKFLOW_TYPES.knowledgeBaseLoader && (
                    <div className='mt-2'>
                      <span className='!flex justify-between mb-4'>
                        <LabelWithDescription customClassName='mt-2' label='Speech to Emit' />
                        <span>{apiWorkflow?.speechToEmit}</span>
                      </span>
                    </div>
                  )}
                </>
              ) : (
                <span className='text-base text-gray-300 text-center'>Expand To View Details</span>
              )}
            </Card>
          ))
        ) : (
          <Empty image={`/empty.png`} imageStyle={{ justifyItems: 'center' }} description='No Workflow Found' />
        )}
      </Spin>
    );
  };

  const baseClass = 'api-workflow';

  return (
    <div className={baseClass}>
      <OverViewCardInfo
        width={'50%'}
        formActionType='Add'
        form={<ApiWorkflowForm apiWorkflowData={apiWorkflowData} refetch={refetch} />}
        formTitle='Add Workflow'
        info={<InfoComponent />}
        footer={null}
        modalId={MODAL_IDS.API_WORKFLOW_MODAL}
        tutorialId={TUTORIALS.API_WORKFLOW}
        displayTutorialActionBtn={!debrand}
      />

      <CustomModal
        width={'50%'}
        title={<span className='font-bold'>Edit Scenerio</span>}
        children={
          <ApiWorkflowForm
            apiWorkflowData={apiWorkflowData}
            showAddFieldButton={false}
            call_webhook_workflows={formInitialValues}
            editWorkflowIndex={editWorkflowIndex}
            refetch={refetch}
          />
        }
        footer={null}
        modalId={MODAL_IDS.EDIT_API_WORKFLOW_MODAL}
      />
    </div>
  );
};
