import axios from 'axios';
import { useEffect, useState } from 'react';
import { notification } from 'antd';
import { serverURL } from '../../config';
import { useSearchParams } from 'react-router-dom';
import RegisterPage from './RegisterPage';
import { CircularProgress } from '@material-ui/core';
import SoldOutPage from './SoludOut/SoldOutPage';

interface ResellerInfo {
  reseller_name?: string;
  logo_s3?: string;
  reseller_email?: string;
  purchase_quotes?: string;
  auto_scale?: boolean;
  unreservedReceptionists?: number;
  unConfiguredCampaigns?: number;
}

interface RegisterPageWrapperProps {
  test?: boolean;
  isCampaign?: boolean;
  isStandaloneCampaign?: boolean;
}

const RegisterPageWrapper: React.FC<RegisterPageWrapperProps> = ({
  test = false,
  isCampaign = false,
  isStandaloneCampaign = false,
}) => {
  const [resellerInfo, setResellerInfo] = useState<ResellerInfo>({});
  const [imageUrl, setImageUrl] = useState<string>('');
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (typeof window !== 'undefined' && resellerInfo?.reseller_name) {
      try {
        // Update the document title (tab title)
        document.title = `${resellerInfo.reseller_name} Portal Login`;

        // Update the meta description
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
          metaDescription.setAttribute('content', `${resellerInfo.reseller_name} Dashboard/Portal login`);
        }

        // If you still need to update an element with id 'page-title'
        const pageTitle = document.getElementById('page-title');
        if (pageTitle) {
          pageTitle.textContent = `${resellerInfo.reseller_name} Portal Login`;
        }
      } catch (err) {
        console.error('Error updating page metadata:', err);
      }
    }
  }, [resellerInfo?.reseller_name]);

  useEffect(() => {
    const fetchReseller = async (resellerCode: string) => {
      try {
        const response = await axios.post(`${serverURL}/get-reseller-from-reseller-code`, {
          resellerCode,
        });

        if (response?.data?.reseller?.logo_s3) {
          // Fetch the logo image
          const logoResponse = await fetch(`${serverURL}/download_reseller_logo`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              key: response?.data?.reseller?.logo_s3,
            }),
          });

          const blob = await logoResponse.blob();
          const objectUrl = URL.createObjectURL(blob);
          setImageUrl(objectUrl);
        }

        setResellerInfo(response.data.reseller);
      } catch (err) {
        console.error(err);
        notification.error({
          message: 'Error',
          description: 'Something went wrong',
        });
      } finally {
        setLoading(false);
      }
    };

    const resellerCode = searchParams.get('reseller_code') || searchParams.get('code');
    if (resellerCode) {
      setLoading(true);
      fetchReseller(resellerCode);
    } else {
      setLoading(false);
    }
  }, [searchParams]);

  if (loading) {
    return (
      <div className='flex w-screen h-screen items-center justify-center'>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div>
      {/* <Toaster position="top-center" reverseOrder={false} /> */}

      {(resellerInfo && !isCampaign && (resellerInfo.unreservedReceptionists! > 0 || resellerInfo.auto_scale)) ||
      (isCampaign && resellerInfo.unConfiguredCampaigns! > 0) ||
      (test && !isCampaign) ? (
        <RegisterPage
          debrand={true}
          reseller_email={isCampaign ? undefined : resellerInfo.reseller_email}
          purchase_quotes={resellerInfo.purchase_quotes}
          logo_s3={imageUrl}
          reroute_to_unbranded_login={true}
          auto_scale={resellerInfo.auto_scale}
          unreservedReceptionists={resellerInfo.unreservedReceptionists}
          test={test}
          debranded_registration_modal_active_setter={undefined} // Handle if required
          debrand_modal_instructions_setter={undefined} // Handle if required
          isCampaign={isCampaign || undefined} // Handle if required
          campaign_user_email={isCampaign ? resellerInfo.reseller_email : undefined}
          isStandaloneCampaign={isStandaloneCampaign || false}
        />
      ) : (
        <SoldOutPage
          registerText={isCampaign ? 'Campaign' : 'Receptionist'}
          supportEmail={resellerInfo.reseller_email}
          companyName={resellerInfo?.reseller_name}
        />
      )}
    </div>
  );
};

export default RegisterPageWrapper;
