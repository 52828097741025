import React from 'react';
import Fade from 'react-reveal/Fade';
import classes from '../Register.module.css';

interface GreetingPromptTemplate {
  label: string;
  template: string;
}

interface Step4BProps {
  setGreetingTemplateModalActive: (value: boolean) => void;
  setSelectedGreetingTemplate: (template: string) => void;
  greetingsPromptTemplates: GreetingPromptTemplate[];
  selectedGreetingTemplate: string;
  setSystemPhrase: (phrase: string) => void;
  greetingTemplateModalActive: boolean;
  businessName: string; // assuming businessName should be passed as a prop
}

const Step4B: React.FC<Step4BProps> = ({
  setGreetingTemplateModalActive,
  setSelectedGreetingTemplate,
  greetingsPromptTemplates,
  selectedGreetingTemplate,
  setSystemPhrase,
  greetingTemplateModalActive,
  businessName,
}) => {
  return (
    <>
      <div
        className={`${classes.box} ${classes.modal} ${greetingTemplateModalActive && classes.active}`}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <div className={classes.close} onClick={() => setGreetingTemplateModalActive(false)}>
          &times;
        </div>
        <Fade duration={1000} bottom>
          <div className='flex items-center justify-center flex-col'>
            <h3
              className={classes.heading}
              style={{
                textAlign: 'center',
              }}
            >
              Prompt Templates
            </h3>

            <div className={`${classes.subtitle}`} style={{ marginBottom: '.5rem' }}>
              Select a template
            </div>

            <select
              style={{ marginBottom: '1rem' }}
              className={classes.input}
              onChange={e => {
                const selectedTemplate = greetingsPromptTemplates.find(el => el.label === e.target.value);
                if (selectedTemplate) {
                  setSelectedGreetingTemplate(selectedTemplate.template.replace('[BUSINESS NAME HERE]', businessName));
                }
              }}
            >
              <option value=''>Choose an option</option>
              {greetingsPromptTemplates.map(el => {
                return (
                  <option key={el.label} value={el.label}>
                    {el.label}
                  </option>
                );
              })}
            </select>

            <p>{selectedGreetingTemplate}</p>

            <button
              type='button'
              onClick={() => {
                setSystemPhrase(selectedGreetingTemplate);
                setGreetingTemplateModalActive(false);
              }}
              className={classes.button}
              style={{
                marginTop: '2rem',
              }}
            >
              Use Template
            </button>
          </div>
        </Fade>
      </div>
    </>
  );
};

export default Step4B;
