import React from 'react';
import { Input } from 'antd';
import { MailIcon } from 'lucide-react';
import StepWrapper from './StepWrapper';

interface StepCOneProps {
  emailAddress: string;
  setEmailAddress: (email: string) => void;
  phoneNumber: string;
  setPhoneNumber: (number: string) => void;
  handleNext: () => void;
}

const EmailInput: React.FC<StepCOneProps> = ({ emailAddress, setEmailAddress, handleNext }) => {
  return (
    <StepWrapper handleNext={handleNext} disableNext={!emailAddress}>
      <div className='w-full flex flex-col'>
        <div className='flex flex-col items-start w-full'>
          <h3 className='sm:text-3xl text-xl font-bold text-gray-900 text-start mb-0'>What's your business email?</h3>
          <p className='text-gray-500 sm:text-lg text-sm'>
            Your business email helps us keep your AI assistant safe and personalized
          </p>
        </div>

        <div>
          <Input
            id='email'
            type='email'
            value={emailAddress}
            onChange={e => setEmailAddress(e.target.value)}
            placeholder='contact@business.com'
            prefix={<MailIcon className='h-5 w-5 text-gray-400 mr-1' />}
            className='h-12'
            size='large'
          />
        </div>
      </div>
    </StepWrapper>
  );
};

export default EmailInput;
