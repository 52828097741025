import { useState } from 'react';
import { Typography, Row, Col, Card, Space, Spin, Tag, Button, Alert, notification } from 'antd';
import { EditOutlined, ClockCircleOutlined, CalendarOutlined, GlobalOutlined } from '@ant-design/icons';
import ScheduleInfoForm from './CallScheduleInfoForm';
import { useMutation } from '@tanstack/react-query';
import { updateScheduleStatus } from '@/api/novi/schedule';
import { CampaignSchedule } from '@/pages/Novi/types/Schedule';

const { Text } = Typography;

const daysOptions = [
  { value: 0, label: 'Sunday' },
  { value: 1, label: 'Monday' },
  { value: 2, label: 'Tuesday' },
  { value: 3, label: 'Wednesday' },
  { value: 4, label: 'Thursday' },
  { value: 5, label: 'Friday' },
  { value: 6, label: 'Saturday' },
];

type ScheduleInfoProps = {
  campaignId: string;
  scheduleData: { schedule: CampaignSchedule | null } | undefined;
  isLoading: boolean;
  isRefetching: boolean;
  refetch: () => void;
};

const ScheduleInfo = ({ campaignId, scheduleData, isLoading, isRefetching, refetch }: ScheduleInfoProps) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isToggling, setIsToggling] = useState<boolean>(false);

  const { mutate: updateSchedule, isPending } = useMutation({
    mutationFn: async (params: { campaign_id: string; status: string }) => {
      setIsToggling(true);
      try {
        await updateScheduleStatus(params);
      } finally {
        setIsToggling(false);
      }
    },
    onSuccess: () => {
      notification.success({
        message: 'Schedule Status Updated',
        description: 'The schedule status has been updated successfully.',
        duration: 3,
      });
      refetch();
    },
    onError: (error: Error) => {
      notification.error({
        message: 'Failed to Update Schedule Status',
        description: error.message || 'An error occurred while updating the schedule status.',
        duration: 4,
      });
      refetch();
    },
  });

  const handleStatusToggle = async () => {
    if (isToggling || !scheduleData) return;
    const newStatus = scheduleData.schedule?.status?.toLowerCase() === 'active' ? 'INACTIVE' : 'ACTIVE';
    updateSchedule({
      campaign_id: campaignId,
      status: newStatus,
    });
  };

  const renderStatusBadge = (status: string) => {
    const statusConfig = {
      active: { color: '#52c41a', text: 'ACTIVE' },
      inactive: { color: '#ff4d4f', text: 'INACTIVE' },
    };
    const config = statusConfig[status?.toLowerCase() as keyof typeof statusConfig] || statusConfig.inactive;
    return (
      <Tag color={config.color} style={{ padding: '2px 12px', borderRadius: '12px', fontSize: '12px' }}>
        {config.text}
      </Tag>
    );
  };

  const renderScheduleControls = () => {
    const isActive = scheduleData?.schedule?.status?.toLowerCase() === 'active';

    return (
      <Button
        type={isActive ? 'default' : 'primary'}
        onClick={handleStatusToggle}
        loading={isToggling}
        disabled={isPending}
        style={{
          borderRadius: '6px',
          padding: '4px 12px',
          height: '28px',
          backgroundColor: isActive ? '#f5f5f5' : undefined,
          borderColor: isActive ? '#d9d9d9' : undefined,
        }}
      >
        <Space size={4}>
          <div
            style={{
              width: '8px',
              height: '8px',
              borderRadius: '50%',
              backgroundColor: isActive ? '#52c41a' : '#ff4d4f',
              display: 'inline-block',
              marginRight: '4px',
            }}
          />
          {isActive ? 'Turn Off Schedule' : 'Turn On Schedule'}
        </Space>
      </Button>
    );
  };

  if (isLoading || isRefetching) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
        <Spin size='small' />
      </div>
    );
  }

  if (isEditing || !scheduleData?.schedule) {
    return (
      <div>
        <ScheduleInfoForm
          existingSettings={scheduleData?.schedule}
          onCancel={() => setIsEditing(false)}
          onSuccess={() => {
            setIsEditing(false);
            refetch();
          }}
        />
      </div>
    );
  }

  return (
    <div className='w-full'>
      <Card
        bordered={false}
        size='small'
        style={{
          borderRadius: '8px',
          boxShadow: '0 1px 2px rgba(0, 0, 0, 0.03)',
        }}
      >
        <Row justify='space-between' align='middle' style={{ marginBottom: '16px' }}>
          <Space align='center' size={8}>
            <Text strong style={{ fontSize: '16px', margin: 0 }}>
              Campaign Schedule
            </Text>
            {renderStatusBadge(scheduleData?.schedule?.status as string)}
          </Space>
          <Space size={12}>
            {renderScheduleControls()}
            <Button
              type='primary'
              icon={<EditOutlined />}
              onClick={() => setIsEditing(true)}
              disabled={isToggling}
              size='middle'
              style={{
                borderRadius: '6px',
                padding: '0 12px',
                height: '28px',
                display: 'flex',
                alignItems: 'center',
                gap: '4px',
              }}
            >
              Edit Schedule
            </Button>
          </Space>
        </Row>

        <Row gutter={[16, 16]}>
          {[
            {
              icon: <ClockCircleOutlined style={{ color: '#1890ff', fontSize: '16px' }} />,
              title: 'Retry Settings',
              content: [
                {
                  label: 'Maximum Retries',
                  value: scheduleData?.schedule?.allowed_retries,
                },
                {
                  label: 'Hours Between Retries',
                  value: `${scheduleData?.schedule?.time_between_retries}h`,
                },
              ],
            },
            {
              icon: <CalendarOutlined style={{ color: '#52c41a', fontSize: '16px' }} />,
              title: 'Calling Hours',
              content: [
                {
                  label: 'Active Time Window',
                  value: `${scheduleData?.schedule?.allowed_times_start} - ${scheduleData?.schedule?.allowed_times_end}`,
                },
              ],
              extra: (
                <div style={{ marginTop: '8px' }}>
                  <Text type='secondary' style={{ fontSize: '12px', display: 'block', marginBottom: '4px' }}>
                    Active Days
                  </Text>
                  <Space wrap size={[4, 4]} style={{ marginTop: '4px' }}>
                    {scheduleData?.schedule?.days?.map((day: string) => (
                      <Tag
                        key={day}
                        color='blue'
                        style={{
                          padding: '1px 8px',
                          borderRadius: '12px',
                          fontSize: '12px',
                          margin: 0,
                        }}
                      >
                        {daysOptions.find(opt => opt.value === parseInt(day, 10))?.label}
                      </Tag>
                    ))}
                  </Space>
                </div>
              ),
            },
            {
              icon: <GlobalOutlined style={{ color: '#722ed1', fontSize: '16px' }} />,
              title: 'Time Zone',
              content: [
                {
                  label: 'Campaign Time Zone',
                  value: scheduleData?.schedule?.timezone,
                },
              ],
            },
          ].map((section, index) => (
            <Col xs={24} sm={24} md={8} key={index}>
              <Card
                size='small'
                bordered={false}
                style={{
                  height: '100%',
                  minHeight: '140px',
                  borderRadius: '8px',
                  backgroundColor: '#fafafa',
                }}
              >
                <Space direction='vertical' style={{ width: '100%' }} size={8}>
                  <Space size={8}>
                    {section.icon}
                    <Text strong style={{ fontSize: '14px' }}>
                      {section.title}
                    </Text>
                  </Space>

                  <Space direction='vertical' style={{ width: '100%' }} size={4}>
                    {section.content.map((item, idx) => (
                      <div key={idx}>
                        <Text type='secondary' style={{ fontSize: '12px', display: 'block' }}>
                          {item.label}
                        </Text>
                        <Text strong style={{ fontSize: '14px' }}>
                          {item.value}
                        </Text>
                      </div>
                    ))}
                    {section.extra}
                  </Space>
                </Space>
              </Card>
            </Col>
          ))}
        </Row>

        <Alert
          type='warning'
          showIcon
          message='Important Note'
          description='Changes to schedule settings will affect all future communications.'
          style={{ marginTop: '16px', borderRadius: '6px', fontSize: '12px' }}
        />
      </Card>
    </div>
  );
};

export default ScheduleInfo;
