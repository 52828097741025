import { Navigate, Outlet, useSearchParams } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { useQuery } from '@tanstack/react-query';
import { useAppDispatch } from '@/store/hooks/useAppDispatch';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { getCampaignDetails } from '@/api/novi/campaigns';
import { Loading } from '@/components/Loading';
import LocalStorageUtility from '@/utils/localStorage';
import { LocalStorageKeys } from 'src/enums/ELocalStorage';
import { AppRoutes } from 'src/enums/ERoutes';
import { ENoviDashboardSider } from '../enums/ENoviDashboardSider';
import { setNoviCurrentPage, setNoviSelectedCampaign, setNoviSelectedCampaignId } from '@/store/novi/user/slice';
import { getNoviCurrentPage, getSelectedCampaign } from '@/store/novi/user/selector';
import { getResellerByCode } from '@/api/unbrandedReceptionist';
import { useEffect } from 'react';
import { setReseller } from '@/store/reseller/slice';
import { getResellerData } from '@/store/reseller/selector';

interface DecodedToken {
  exp: number;
  [key: string]: any;
}

interface NavigationState {
  debrand: boolean;
  resellerCode?: string;
}

const validateToken = (token: string | null): boolean => {
  if (!token) return false;

  try {
    const cleanToken = typeof token === 'string' ? token.replace('Bearer ', '').trim() : JSON.stringify(token);

    const { exp } = jwtDecode<DecodedToken>(cleanToken);
    return exp >= Math.floor(Date.now() / 1000);
  } catch {
    return false;
  }
};

const useResellerCode = () => {
  const [searchParams] = useSearchParams();
  return searchParams.get('reseller_code') || searchParams.get('code') || undefined;
};

const useRedirectToLogin = (state: NavigationState) => (
  <Navigate to={AppRoutes.WHITE_LABEL_CAMPAIGN_LOGIN} state={state} replace />
);

const useCampaignQuery = (isTokenValid: boolean, selectedCampaign: any) => {
  return useQuery({
    queryKey: ['getCampaignDetails'],
    queryFn: async () => {
      if (!selectedCampaign) {
        return getCampaignDetails();
      }
      return { data: selectedCampaign };
    },
    enabled: isTokenValid,
    retry: false,
  });
};

const WhitelabelCampaignPrivateRoute: React.FC = () => {
  const dispatch = useAppDispatch();
  const resellerCode = useResellerCode();
  const selectedCampaign = useAppSelector(getSelectedCampaign);
  const currentPage = useAppSelector(getNoviCurrentPage);
  const currentReseller = useAppSelector(getResellerData);

  const authToken = LocalStorageUtility.getLocalData(LocalStorageKeys.NOVI_RECEPTIONIST_AUTH_TOKEN);
  const isTokenValid = validateToken(authToken as string);

  const { data, isLoading, isError } = useCampaignQuery(isTokenValid, selectedCampaign);

  const navigationState = { debrand: true, resellerCode };

  const { data: resellerData } = useQuery({
    queryKey: ['reseller-code', resellerCode],
    queryFn: () => getResellerByCode({ resellerCode: resellerCode as string }),
    enabled: !!(resellerCode && !currentReseller),
  });

  useEffect(() => {
    if (resellerData?.reseller && !currentReseller) {
      dispatch(setReseller(resellerData.reseller));
    }
  }, [resellerData]);

  if (!isTokenValid) {
    LocalStorageUtility.removeLocalData(LocalStorageKeys.NOVI_RECEPTIONIST_AUTH_TOKEN);
    LocalStorageUtility.removeLocalData(LocalStorageKeys.NOVI_AUTH_TOKEN);
    LocalStorageUtility.removeLocalData(LocalStorageKeys.NOVI_USER);
    return useRedirectToLogin(navigationState);
  }

  if (isLoading) return <Loading />;

  if (isError) {
    console.error('Error fetching campaign details');
    LocalStorageUtility.removeLocalData(LocalStorageKeys.NOVI_RECEPTIONIST_AUTH_TOKEN);
    LocalStorageUtility.removeLocalData(LocalStorageKeys.NOVI_AUTH_TOKEN);
    LocalStorageUtility.removeLocalData(LocalStorageKeys.NOVI_USER);
    return useRedirectToLogin(navigationState);
  }

  if (data?.data) {
    const { data: campaignData } = data;

    dispatch(setNoviSelectedCampaign(campaignData));
    dispatch(setNoviSelectedCampaignId(campaignData.number));

    if (currentPage === ENoviDashboardSider.MY_CAMPAIGNS) {
      dispatch(setNoviCurrentPage(ENoviDashboardSider.LEADS));
    }
  }

  return <Outlet context={{ debrand: true, resellerCode }} />;
};

export default WhitelabelCampaignPrivateRoute;
