import { useEffect, useState } from 'react';
import { Typography, Row, Col, Card, Space, Spin, Tag, Button, Alert, notification, Empty } from 'antd';
import { EditOutlined, ClockCircleOutlined, ScheduleOutlined } from '@ant-design/icons';
import TextScheduleForm from './TextScheduleForm';
import { useMutation, useQuery } from '@tanstack/react-query';
import { editTextingCampaign, getAllTextingCampaigns, toggleTextingCampaignStatus } from '@/api/novi/textingCampaign';
import { setNoviCurrentPage } from '@/store/novi/user/slice';
import { ENoviDashboardSider } from '@/pages/Novi/enums/ENoviDashboardSider';
import { useAppDispatch } from '@/store/hooks/useAppDispatch';

const { Text, Title } = Typography;

interface TextScheduleInfoProps {
  clientId: string;
}

const TextScheduleInfo = ({ clientId }: TextScheduleInfoProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isToggling, setIsToggling] = useState(false);
  const dispatch = useAppDispatch();

  const {
    data: campaigns,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ['textCampaigns', clientId],
    queryFn: () => getAllTextingCampaigns(clientId),
  });

  const campaign = campaigns?.[0];

  // Set isEditing to true if campaign exists but has no schedule
  useEffect(() => {
    if (campaign && !campaign.schedule) {
      setIsEditing(true);
    }
  }, [campaign]);

  const updateScheduleMutation = useMutation({
    mutationFn: scheduleData =>
      editTextingCampaign({
        campaignId: clientId,
        textCampaignId: campaign.id,
        campaignData: {
          ...campaign,
          schedule: scheduleData,
        },
      }),
    onSuccess: () => {
      setIsEditing(false);
      notification.success({
        message: 'Schedule Updated',
        description: 'Delivery schedule has been updated successfully.',
      });
      refetch();
    },
  });

  const toggleStatusMutation = useMutation({
    mutationFn: async () => {
      setIsToggling(true);
      try {
        await toggleTextingCampaignStatus({
          campaignId: clientId,
          textCampaignId: campaign.id,
          isActive: !campaign.is_active,
        });
      } finally {
        setIsToggling(false);
      }
    },
    onSuccess: () => {
      notification.success({
        message: 'Delivery Status Updated',
        description: 'The delivery schedule status has been updated successfully.',
      });
      refetch();
    },
  });

  if (isLoading) {
    return (
      <div className='flex justify-center items-center h-48'>
        <Spin />
      </div>
    );
  }

  if (!campaign) {
    return (
      <Card bordered={false} className='text-center py-12'>
        <Empty
          image={<ScheduleOutlined className='text-6xl text-blue-500' />}
          description={
            <Space direction='vertical' size='small'>
              <Title level={5}>No Text Configuration Found</Title>
              <Text type='secondary'>Please configure before setting up the delivery schedule.</Text>
            </Space>
          }
        >
          <Button
            onClick={() => {
              dispatch(setNoviCurrentPage(ENoviDashboardSider.TEXT_CONFIGURATION));
            }}
            type='primary'
            size='large'
            className='mt-4'
          >
            Configure Text Campaign
          </Button>
        </Empty>
      </Card>
    );
  }

  if (isEditing || !campaign.schedule) {
    return (
      <TextScheduleForm
        initialValues={campaign.schedule}
        onSubmit={values => updateScheduleMutation.mutate(values)}
        onCancel={() => setIsEditing(false)}
        isLoading={updateScheduleMutation.isPending}
        showCancelBtn={!!campaign.schedule}
        mode={isEditing ? 'edit' : 'create'}
      />
    );
  }

  return (
    <Card bordered={false} className='shadow-sm'>
      <div className='flex justify-between items-center mb-4'>
        <Space align='center'>
          <Text strong className='text-lg'>
            Delivery Schedule
          </Text>
          <Tag color={campaign?.is_active ? '#52c41a' : '#ff4d4f'} className='uppercase px-3 py-1 rounded-full text-xs'>
            {campaign?.is_active ? 'ACTIVE' : 'INACTIVE'}
          </Tag>
        </Space>
        <Space size={12}>
          <Button
            type={campaign?.is_active ? 'default' : 'primary'}
            onClick={() => toggleStatusMutation.mutate()}
            loading={isToggling}
            disabled={toggleStatusMutation.isPending}
            className='rounded-lg flex items-center gap-1'
          >
            <span
              className={`w-2 h-2 rounded-full inline-block ${campaign?.is_active ? 'bg-green-500' : 'bg-red-500'}`}
            />
            {campaign?.is_active ? 'Turn Off Schedule' : 'Turn On Schedule'}
          </Button>
          <Button
            type='primary'
            icon={<EditOutlined />}
            onClick={() => setIsEditing(true)}
            disabled={isToggling}
            className='rounded-lg'
          >
            Edit Schedule
          </Button>
        </Space>
      </div>

      {/* Schedule Information Cards */}
      <Row gutter={[16, 16]}>
        {/* Delivery Type Card */}
        <Col xs={24} md={8}>
          <Card className='bg-gray-50 h-full'>
            <Space direction='vertical' className='w-full'>
              <Space>
                <ClockCircleOutlined className='text-blue-500' />
                <Text strong>Delivery Type</Text>
              </Space>
              <Text strong>{campaign.schedule?.type === 'immediate' ? 'Immediate' : `Delayed`}</Text>
              {campaign.schedule?.type === 'delayed' && (
                <Tag color='green'>{campaign.schedule?.delay?.value} hours</Tag>
              )}
            </Space>
          </Card>
        </Col>
      </Row>

      <Alert
        type='warning'
        showIcon
        message='Important Note'
        description='Changes to delivery settings will affect all future text messages.'
        className='mt-4 rounded-lg'
      />
    </Card>
  );
};

export default TextScheduleInfo;
