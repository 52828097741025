import React from 'react';
import posthog from 'posthog-js';
import { Col, Row } from 'antd';
import { PauseCircle, PlayCircle } from '@mui/icons-material';
import StepWrapper from './StepWrapper';
import { AIModelsWithHumanNames } from 'src/constants/voiceModels';

interface Step0Props {
  stepState: number;
  setStepState: (step: number) => void;
  setVoiceModel: (model: string) => void;
  setVoiceProvider: (provider: string) => void;
  voiceModel: string;
  soundPlayerRef: React.MutableRefObject<HTMLAudioElement | null>;
  handleStepTransition: (nextStep: number) => Promise<void>;
  handlePlayPause: (model: any) => void;
  playingModel: string | null;
}

const StepZero: React.FC<Step0Props> = ({
  stepState,
  setVoiceModel,
  setVoiceProvider,
  voiceModel,
  handleStepTransition,
  handlePlayPause,
  playingModel,
}) => {
  return (
    <StepWrapper handleNext={() => handleStepTransition(stepState + 1)} disableNext={!voiceModel}>
      <div className='flex flex-col items-start w-full space-y-2'>
        <h3 className='sm:text-3xl text-xl font-bold text-gray-900 text-start mb-0'>
          Choose your AI assistant's personality
        </h3>
        <p className='text-gray-500 sm:text-lg text-sm'>Select a communication style that best represents your brand</p>
      </div>

      <div className={'w-full mx-auto'}>
        <Row gutter={[12, 12]} className='justify-center mt-20'>
          {AIModelsWithHumanNames.slice(0, 10).map(model => (
            <Col lg={8} xs={12}>
              <div
                className={`mb-14 voice-card rounded-t-xl ${voiceModel === model.value ? 'active' : ''} ${
                  AIModelsWithHumanNames.indexOf(model) >= AIModelsWithHumanNames.length - 3 ? '!mb-3' : ''
                }`}
                style={{
                  background: voiceModel === model.value ? 'rgb(230, 232, 237)' : undefined,
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setVoiceModel(model.value);
                  setVoiceProvider(model.provider);
                  posthog.capture('voice_selected');
                }}
              >
                <div className='h-12 rounded-t-xl' style={{ backgroundColor: model.background }}>
                  <img
                    style={{
                      height: '7rem',
                      position: 'absolute',
                      top: '-4rem',
                      left: '50%',
                      transform: 'translateX(-50%)',
                    }}
                    src={model.icon}
                  />
                </div>
                <div className='p-1 text-center border-x-2 border-b-2'>
                  <p className='mb-1 text-base font-bold font-merriweather'>{model.name}</p>
                  <button
                    onClick={e => {
                      e.stopPropagation();
                      handlePlayPause(model);
                    }}
                    className='play-pause-button'
                  >
                    {playingModel === model.value ? <PauseCircle /> : <PlayCircle />}
                  </button>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </StepWrapper>
  );
};

export default StepZero;
