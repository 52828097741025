import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { generateRandomColour } from '../../utils/helper';
import { prod, serverURL } from '../../config';
import posthog from 'posthog-js';
import { countries } from '../../constants/countriesData';
import {
  businessInformationsTemplates,
  businessNameTemplates,
  greetingsPromptTemplates,
  textingWorkflowsTemplates,
  callTransferTemplates,
} from '../../constants/prompt-templates';
import classes from './Register.module.css';
import { soundMapping } from '../../constants/voiceModels';

import { notification } from 'antd';
import { AppRoutes } from '../../enums/ERoutes';
import { useMutation } from '@tanstack/react-query';
import { createClient } from '@/api/user';
import Step0 from './Steps/Step0';
import Step1 from './Steps/Step1';
import Step2 from './Steps/Step2';
import Step3 from './Steps/Step3';
import Step4 from './Steps/Step4';
import Step4A from './Steps/Step4A';
import Step4B from './Steps/Step4B';
import Step5 from './Steps/Step5';
import Step5A from './Steps/Step5A';
import Step5B from './Steps/Step5B';
import Step6 from './Steps/Step6';
import Step6A from './Steps/Step6A';
import Step6B from './Steps/Step6B';
import Step7 from './Steps/Step7';
import Step7A from './Steps/Step7A';
import Step7B from './Steps/Step7B';
import Step8 from './Steps/Step8';
import Step9 from './Steps/Step9';
import Step10 from './Steps/Step10';
import momentTimeZone from 'moment-timezone';

declare global {
  interface Window {
    gtag: (...args: any[]) => void;
    _rwq?: (...args: any[]) => void;
    rewardful?: {
      (...args: any[]): void;
      q?: any[];
      referral?: string | null;
    };
    Rewardful?: {
      referral?: string | null;
    };
  }
}

interface RegisterPageProps {
  debrand?: boolean;
  reseller_email?: string;
  reseller_name?: string;
  campaign_user_email?: string;
  purchase_quotes?: string;
  campaign_user_name?: string;
  campaign_user_password?: string;
  debranded_registration_modal_active_setter?: (active: boolean) => void;
  debrand_modal_instructions_setter?: (instructions: string) => void;
  logo_s3?: string;
  reroute_to_unbranded_login?: boolean;
  isCampaign?: boolean;
  auto_scale?: boolean;
  unreservedReceptionists?: number | any; // Replace `any[]` with a more specific type if available
  test?: boolean;
  features?: any;
  handleCampaignCreated?: () => void;
  isStandaloneCampaign?: boolean;
  resellerOutbound?: boolean;
}

interface CallTemplate {
  scenarioDescription: string;
  phoneNumber: string;
  voiceResponse: string;
}

interface TextingTemplate {
  scenarioDescription: string;
  textMessage: string;
  voiceResponse: string;
}

const RegisterPage: React.FC<RegisterPageProps> = ({
  debrand,
  reseller_email = '',
  campaign_user_email = '',
  purchase_quotes = '',
  campaign_user_name = '',
  campaign_user_password = '',
  debranded_registration_modal_active_setter,
  debrand_modal_instructions_setter,
  logo_s3,
  reroute_to_unbranded_login,
  isCampaign,
  auto_scale = false,
  unreservedReceptionists,
  test = false,
  features = {},
  handleCampaignCreated = () => {},
  isStandaloneCampaign = false,
  resellerOutbound = false,
}) => {
  // Define types for your state variables
  const [testingNumber, setTestingNumber] = useState<string>('');
  const [businessName, setBusinessName] = useState<string>('');
  const [businessType, setBusinessType] = useState<string>('');
  const [emailAddress, setEmailAddress] = useState<string>('');
  const [referralCode, setReferralCode] = useState<string>('');
  // const [findOutAboutUs, setFindOutAboutUs] = useState<string>('');
  const [findOutAboutUs] = useState<string>('');
  const [systemPhrase, setSystemPhrase] = useState<string>('');
  const [campaignVariables, setCampaignVariables] = useState<Record<string, Variable>>(); // Assuming it's an object with dynamic keys
  // const [inputVariables, setInputVariables] = useState<string>('');
  const [termsAndConditions, setTermsAndConditions] = useState<boolean>(false);
  // const [prompt, setPrompt] = useState<string>(
  //   "From now on, you are a phone receptionist at that customers can call to ask questions. You always answer in one or two short, concise sentences, and you never make up information that you don't know."
  // );
  const [showBusinessInformationModal, setShowBusinessInformationModal] = useState<boolean>(false);
  const [showCallTransferringModal, setShowCallTransferringModal] = useState<boolean>(false);
  const [showLinkTextingModal, setShowLinkTextingModal] = useState<boolean>(false);
  const [companyInfo, setCompanyInfo] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [username, setUsername] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  // const [passwordMatchError, setPasswordMatchError] = useState<boolean>(false);
  // const [showForm, setShowForm] = useState<boolean>(false);
  // const captchaRef = useRef<HTMLDivElement | null>(null); // Assuming the ref is for a div
  const [stepState, setStepState] = useState<number>(0);
  // const [isDarkMode, setIsDarkMode] = useState<boolean>(false);
  const isDarkMode = false;
  const [greetingTemplateModalActive, setGreetingTemplateModalActive] = useState<boolean>(false);
  const [businessInformationTemplateModalActive, setBusinessInformationTemplateModalActive] = useState<boolean>(false);
  // const [linksTextingTemplateModalActive, setLinksTextingTemplateModalActive] = useState<boolean>(false);
  const [textingTemplateModalActive, setTextingTemplateModalActive] = useState<boolean>(false);
  const [callTransferTemplateModalActive, setCallTransferTemplateModalActive] = useState<boolean>(false);
  const [selectedGreetingTemplate, setSelectedGreetingTemplate] = useState<any>();
  const [selectedBusinessInfoTemplate, setSelectedBusinessInfoTemplate] = useState<any>();
  const [selectedTextingTemplate, setSelectedTextingTemplate] = useState<TextingTemplate>();
  const [selectedCallTemplate, setSelectedCallTemplate] = useState<CallTemplate | null>(null);
  // const [sampleQA, setSampleQA] = useState<string>('');
  const sampleQA = '';
  // const [isReferralUser, setIsReferralUser] = useState<boolean>(false);
  const [isUsingATemplate, setIsUsingATemplate] = useState<boolean>(false);
  const [voiceModel, setVoiceModel] = useState<string>('');
  const [voiceProvider, setVoiceProvider] = useState<string>('');
  const soundPlayerRef = useRef<HTMLAudioElement | null>(null); // Assuming it's for an HTMLAudioElement
  const [createAccLoading, setCreateAccLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<{}>({}); // Assuming `errors` is a string or undefined
  // const [isSubmittingForm, setIsSubmittingForm] = useState<boolean>(false);
  const [stepLoading, setStepLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  console.log('campaign_user_email', campaign_user_email);
  console.log('StepState: ', stepState);

  const TOTAL_STEPS_FOR_SMBS = 10;
  const TOTAL_STEPS_FOR_NON_SMBS = 9;
  const TOTAL_STEPS_FOR_RESELLER = 9;

  interface ExistingData {
    email_address?: string;
    business_name?: string;
    testing_number?: string;
    system_phrase?: string;
    business_information?: string;
    selected_languages?: string;
    language_greetings?: string;
    workflows?: Record<string, any>[]; // Replace `any` with the appropriate type
    callWorkflows?: Record<string, any>[]; // Replace `any` with the appropriate type
    english_voice_selected?: string;
    businessType?: string;
  }

  interface CreateClientResponse {
    existingData?: ExistingData;
    token?: string;
  }

  const createClientStepByStep = useMutation({
    mutationKey: ['createClientStepByStep'],
    mutationFn: createClient,
    onSuccess: (data: CreateClientResponse) => {
      if (data.existingData) {
        let {
          email_address,
          business_name,
          testing_number,
          system_phrase,
          business_information,
          selected_languages,
          language_greetings,
          workflows: existingWorkflows,
          callWorkflows: existingCallWorkflows,
          english_voice_selected,
          businessType: existingBusinessType,
        } = data.existingData;

        if (email_address) setEmailAddress(email_address);
        if (business_name) setBusinessName(business_name);
        if (testing_number) {
          setTestingNumber(testing_number);
        }
        if (system_phrase) setSystemPhrase(system_phrase);
        if (business_information) setCompanyInfo(business_information);
        if (existingBusinessType) {
          setIsUsingATemplate(false);
          setIsUsingATemplate(true);
          setBusinessType(existingBusinessType);
        }
        if (english_voice_selected) {
          try {
            const voiceData = JSON.parse(english_voice_selected);
            setVoiceModel(voiceData.voiceId);
            setVoiceProvider(voiceData.provider);
          } catch (e) {
            console.error('Error parsing english_voice_selected:', e);
          }
        }

        if (selected_languages) {
          try {
            setSelectedLanguages(JSON.parse(selected_languages));
          } catch (e) {
            console.error('Error parsing selected_languages:', e);
            setSelectedLanguages(['English']);
          }
        }

        if (language_greetings) {
          try {
            setLanguageGreetings(JSON.parse(language_greetings));
          } catch (e) {
            console.error('Error parsing language_greetings:', e);
            setLanguageGreetings({ English: '' });
          }
        }

        if (existingWorkflows) {
          setWorkflows(existingWorkflows as Workflow[]);
        }
        if (existingCallWorkflows) setCallWorkflows(existingCallWorkflows as CallWorkflow[]);

        notification.success({
          message: 'Welcome Back!',
          description: "We've restored your previous progress. You can continue where you left off.",
          duration: 4,
        });
      } else if (data.token) {
        if (isCampaign) {
          navigate('/novi-portal/login');
        } else if (!window.location.href.includes(AppRoutes.RESELLER_PORTAL)) {
          localStorage.setItem('AUTH_TOKEN', JSON.stringify(data.token));
        }

        if (!debrand) {
          navigate('/');
        }
      }
    },
    onError: (err: any) => {
      console.log('Error creating client:', err);
      notification.error({ message: err.message || 'Error creating client', duration: 1 });
    },
  });

  const handleStepTransition = async (
    nextStep: number,
    validationFn?: () => Promise<boolean>,
    stateMutationFn?: () => Promise<void>
  ): Promise<void> => {
    try {
      setStepLoading(true);

      if (validationFn) {
        const isValid = await validationFn();
        if (!isValid) {
          return;
        }
      }

      if (stateMutationFn) {
        await stateMutationFn();
      }

      if (!reseller_email && !campaign_user_email) {
        await createClientStepByStep.mutateAsync({
          email: emailAddress,
          businessName,
          testingNumber,
          systemPhrase,
          companyInfo,
          password,
          referralCode,
          findOutAboutUs,
          sampleQA,
          selectedLanguages,
          languageGreetings,
          workflows,
          callWorkflows,
          businessType,
          reseller_email,
          campaign_user_email,
          purchase_quotes,
          username,
          time_zone: momentTimeZone.tz.guess(),
          english_voice_selected: JSON.stringify({
            voiceId: voiceModel,
            provider: voiceProvider,
          }),
          avatarBg: generateRandomColour(),
          voicemails_enabled: true,
          call_recording_enabled: true,
          currentStep: stepState,
          remainingSteps: Math.max(TOTAL_STEPS_FOR_SMBS - stepState, 0),
        });
      }

      setStepState(nextStep);
    } catch (error) {
      console.error('Step transition error:', error);
      notification.error({
        message: 'Error saving progress',
        description: 'Please try again or contact support if the problem persists.',
        duration: 3,
      });
    } finally {
      setStepLoading(false);
    }
  };

  // Define a type for a single workflow item
  interface Workflow {
    description: string;
    voiceResponse: string;
    textMessage: string;
  }

  const [workflows, setWorkflows] = useState<Workflow[]>([
    {
      description:
        'Send the caller a link to schedule an appointment. Execute whenever caller asks to schedule an appointment or consultation, or if they ask to speak to a human.',
      voiceResponse:
        'Great! I’ve just texted you a link to schedule your appointment. Is there anything else I can help you with?',
      textMessage: 'Here is a link to schedule your appointment: [Put your link here]',
    },
  ]);

  // Add workflow function with TypeScript typing
  const addWorkflow = (e: React.FormEvent) => {
    e.preventDefault();
    setWorkflows([...workflows, { description: '', voiceResponse: '', textMessage: '' }]);
  };

  // Handle description change with TypeScript typing
  const handleDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    e.preventDefault();
    const updatedWorkflows = [...workflows];
    updatedWorkflows[index].description = e.target.value;
    setWorkflows(updatedWorkflows);
  };

  // Handle voice response change with TypeScript typing
  const handleVoiceResponseChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    e.preventDefault();
    const updatedWorkflows = [...workflows];
    updatedWorkflows[index].voiceResponse = e.target.value;
    setWorkflows(updatedWorkflows);
  };

  // Handle text message change with TypeScript typing
  const handleTextMessageChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    e.preventDefault();
    const updatedWorkflows = [...workflows];
    updatedWorkflows[index].textMessage = e.target.value;
    setWorkflows(updatedWorkflows);
  };

  // Define a type for a single call workflow item
  interface CallWorkflow {
    description: string;
    voiceResponse: string;
    workflowForwardingNumber: string; // It's a string, ensuring consistency
    country: Country; // The country is now typed as `Country`
  }

  interface Country {
    code: string; // Assuming `code` is a string, can also be a number if needed
    name: string;
    [key: string]: any; // You can replace this with more specific fields if needed
  }

  const [callWorkflows, setCallWorkflows] = useState<CallWorkflow[]>([]);

  // Remove workflow function
  const removeWorkflow = (e: React.FormEvent, index: number): void => {
    e.preventDefault();
    const updatedWorkflows = workflows.filter((_, i) => i !== index);
    setWorkflows(updatedWorkflows);
  };

  /* -------------------- CALL WORKFLOW LOGIC ----------------- */

  // Add workflow function
  const addCallWorkflow = (e: React.FormEvent): void => {
    e.preventDefault();
    setCallWorkflows([
      ...callWorkflows,
      {
        description: '',
        voiceResponse: '',
        workflowForwardingNumber: countries[0] ? countries[0].code : '', // Default to empty string if no country
        country: countries[0] ? countries[0] : ({} as Country), // Default to an empty object as `Country`
      },
    ]);
  };

  // Handle description change for call workflow
  const handleCallWorkflowDescriptionChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ): void => {
    e.preventDefault();
    const updatedCallWorkflows = [...callWorkflows];
    updatedCallWorkflows[index].description = e.target.value;
    setCallWorkflows(updatedCallWorkflows);
  };

  // Handle voice response change for call workflow
  const handleCallWorkflowVoiceResponseChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    index: number
  ): void => {
    e.preventDefault();
    const updatedCallWorkflows = [...callWorkflows];
    updatedCallWorkflows[index].voiceResponse = e.target.value;
    setCallWorkflows(updatedCallWorkflows);
  };

  // Handle forwarding number change for call workflow
  const handleCallWorkflowForwardingNumber = (testing_number: string, index: number): void => {
    const updatedCallWorkflows = [...callWorkflows];

    updatedCallWorkflows[index].workflowForwardingNumber = testing_number;
    setCallWorkflows(updatedCallWorkflows);
  };

  // Remove a specific call workflow
  const removeCallWorkflow = (e: React.FormEvent, index: number): void => {
    e.preventDefault();
    const updatedCallWorkflows = callWorkflows.filter((_, i) => i !== index);
    setCallWorkflows(updatedCallWorkflows);
  };

  const step0StateMutation = async (): Promise<void> => {
    posthog.capture('email_address_entered');
  };

  const step0Validation = async (): Promise<boolean> => {
    if (!emailAddress) {
      notification.error({ message: 'Please enter your email address', duration: 1 });
      return false;
    }
    if (!validateEmail(emailAddress)) {
      notification.error({ message: 'Please enter a valid email address', duration: 1 });
      return false;
    }
    return true;
  };

  const step1StateMutation = async (): Promise<void> => {
    soundPlayerRef?.current?.pause();
    posthog.capture('voice_selected');
  };

  const step1Validation = async (): Promise<boolean> => {
    if (voiceModel.length === 0) {
      notification.error({ message: 'Please select a voice to continue', duration: 1 });
      return false;
    }
    return true;
  };

  const step2StateMutation = async (): Promise<void> => {
    posthog.capture('business_type_selected', { business_type: businessType });
  };

  const step2Validation = async (): Promise<boolean> => {
    if (!businessType) {
      notification.error({ message: 'Please select a business type', duration: 1 });
      return false;
    }
    return true;
  };

  const step3Validation = async (): Promise<boolean> => {
    if (selectedLanguages.length === 0) {
      notification.error({ message: 'Please select at least one language', duration: 1 });
      return false;
    }
    return true;
  };

  const step4StateMutation = async (): Promise<void> => {
    posthog.capture('business_name_entered');
  };

  const step4Validation = async (): Promise<boolean> => {
    if (!businessName) {
      notification.error({ message: 'Please enter the name of your business', duration: 1 });
      return false;
    }
    return true;
  };

  const step5StateMutation = async (): Promise<void> => {
    posthog.capture('greeting_phrase_entered');
  };

  const step5Validation = async (): Promise<boolean> => {
    if (!systemPhrase) {
      notification.error({ message: 'Please enter the greeting phrase', duration: 1 });
      return false;
    }
    const languagesWithoutGreeting = Object.keys(languageGreetings).filter(
      language => language !== 'English' && !languageGreetings[language]
    );
    if (languagesWithoutGreeting.length > 0) {
      notification.error({
        message: `Please enter the greeting phrase for: ${languagesWithoutGreeting.join(', ')}`,
        duration: 1,
      });
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (!isUsingATemplate) {
      setSelectedGreetingTemplate(false);
      setSystemPhrase('');
    } else {
      const businessNameTemplate = businessNameTemplates.find(el => el.label === businessType)?.template;

      setBusinessName(businessNameTemplate || '');

      const greetingTemplate = greetingsPromptTemplates.find(el => el.label === businessType)?.template;

      setSystemPhrase((greetingTemplate ?? '').replace('[BUSINESS NAME HERE]', businessNameTemplate || ''));

      const businessInfoTemplate = businessInformationsTemplates.find(el => el.label === businessType)?.template;

      setCompanyInfo(businessInfoTemplate?.replace('[BUSINESS NAME HERE]', businessNameTemplate || '') || '');
    }
  }, [businessType, isUsingATemplate, businessNameTemplates, greetingsPromptTemplates, businessInformationsTemplates]);

  useEffect(() => {
    window.gtag('event', 'RegisterPageView', {
      event_category: 'Pageview',
    });

    if (reseller_email) console.log('reseller email is ' + reseller_email);
    if (campaign_user_email) console.log('campaign email is ' + campaign_user_email);
  }, [reseller_email, campaign_user_email]);

  useEffect(() => {
    if (reseller_email) console.log('reseller email is ' + reseller_email);
    if (campaign_user_email) console.log('campaign email is ' + campaign_user_email);
  }, []);

  useEffect(() => {
    if (!reseller_email && !campaign_user_email) {
      console.log('Creating');
      const script = document.createElement('script');
      script.id = 'ze-snippet';
      script.src = 'https://static.zdassets.com/ekr/snippet.js?key=a1895b38-5ada-4a38-b4b1-580c900fcc1e';
      script.async = true;

      document.body.appendChild(script);

      return () => {
        // Cleanup when component unmounts
        document.body.removeChild(script);
      };
    }
  }, [reseller_email, campaign_user_email]);

  // useEffect for step state changing to fire GTag analytics
  useEffect(() => {
    // just record what step number we are on
    const eventName = `RegisterPageStep${stepState}`;

    // Trigger the Google Analytics event
    window.gtag('event', eventName, {
      event_category: 'RegisterPage.js',
      event_label: `Step # ${stepState}`,
    });
  }, [stepState]);

  // Disable console logs and errors in production
  if (prod) {
    console.log = function () {};
    console.error = function () {};
  }

  useEffect(() => {
    (function (w: Window, r: 'rewardful') {
      w._rwq = w[r];
      w[r] =
        w[r] ||
        function (...args: any[]) {
          (w[r]!.q = w[r]!.q || []).push(args);
        };
    })(window, 'rewardful');

    const s = document.createElement('script');
    s.async = true;
    s.src = 'https://r.wdfl.co/rw.js';
    s.setAttribute('data-rewardful', '30c5c6');
    s.setAttribute('data-domains', 'myaifrontdesk.com, myaifrontdeskdashboard.com');
    document.head.appendChild(s);

    window.rewardful?.('ready', () => {
      if (window.Rewardful?.referral) {
        console.log('Current referral ID:', window.Rewardful.referral);
      } else {
        console.log('No referral present.');
      }
    });
  }, []);

  useEffect(() => {
    // Create a URLSearchParams object to access the query parameters
    const queryParams = new URLSearchParams(window.location.search);

    // Get the 'referralCode' parameter
    const referralCode = queryParams.get('referralCode');

    if (referralCode) {
      // Do something with the referralCode
      console.log('Referral Code:', referralCode);

      // setIsReferralUser(true);
      setReferralCode(referralCode);
    } else {
      // Handle the case when referralCode is not present in the URL
      // console.log("Referral Code is not present in the URL");
    }
  }, []);

  interface Country {
    name: string;
    code: string;
  }

  interface CreateClientBody {
    email: string;
    businessName: string;
    testingNumber: string;
    systemPhrase: string;
    companyInfo: any;
    password: string;
    referralCode: string | null;
    findOutAboutUs: string;
    sampleQA: any;
    selectedLanguages: string[];
    languageGreetings: Record<string, string>;
    workflows: any;
    callWorkflows: any;
    businessType: string;
    reseller_email: string | null;
    campaign_user_email: string | null;
    purchase_quotes: any;
    username: string;
    time_zone: string;
    english_voice_selected: string;
    auto_scale: boolean;
    unreservedReceptionists: boolean;
    avatarBg: string;
    voicemails_enabled: boolean;
    call_recording_enabled: boolean;
    test: boolean;
    variables?: Record<string, any>;
    featuresSubAccount: any;
    resellerOutbound?: boolean;
  }

  interface CreateClientBodyFinal {
    email: string;
    businessName: string;
    testingNumber: string;
    systemPhrase: string;
    companyInfo: any;
    password: string;
    referralCode: string | null;
    findOutAboutUs: string;
    sampleQA: any;
    selectedLanguages: string[];
    languageGreetings: Record<string, string>;
    workflows: any;
    callWorkflows: any;
    businessType: string;
    reseller_email: string | null;
    campaign_user_email: string | null;
    purchase_quotes: any;
    username: string;
    time_zone: string;
    english_voice_selected: string;
    avatarBg: string;
    voicemails_enabled: boolean;
    call_recording_enabled: boolean;
    currentStep?: number;
    remaingingSteps?: number;
  }

  const saveStepInDB = async () => {
    try {
      const bodyObject: CreateClientBodyFinal = {
        email: emailAddress,
        businessName,
        testingNumber,
        systemPhrase,
        companyInfo,
        password: password,
        referralCode,
        findOutAboutUs,
        sampleQA,
        selectedLanguages,
        languageGreetings,
        workflows,
        callWorkflows,
        businessType,
        reseller_email,
        campaign_user_email,
        purchase_quotes,
        username: username,
        time_zone: momentTimeZone.tz.guess(),
        english_voice_selected: JSON.stringify({
          voiceId: voiceModel,
          provider: voiceProvider,
        }),
        avatarBg: generateRandomColour(),
        voicemails_enabled: true,
        call_recording_enabled: true,
      };
      if (!reseller_email && !campaign_user_email) {
        bodyObject.currentStep = stepState;
        bodyObject.remaingingSteps = Math.max(stepState - TOTAL_STEPS_FOR_SMBS, 0);
        createClientStepByStep.mutate(bodyObject);
      }
    } catch (err) {
      console.log(err);
      notification.error({ message: 'Error in saving step', duration: 1 });
    }
  };

  const handleSubmit = async (event: React.FormEvent) => {
    if (!reseller_email && !campaign_user_email) {
      setStepLoading(true);
      await saveStepInDB();
      setStepLoading(false);
      return;
    }
    event.preventDefault();
    posthog.capture('create_account_button_clicked');

    // setIsSubmittingForm(true);

    if (password !== confirmPassword) {
      // setPasswordMatchError(true);
      return;
    }

    const d = Date.now();
    const campaignUsername = `${campaign_user_name}-${businessName}-${d}`.replace(/ /g, '-');

    // Before registering user, make sure client does not exist
    setCreateAccLoading(true);

    try {
      let usernameTaken = false;

      await fetch(`${serverURL}/clientExistsNoPassword`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: isCampaign && !isStandaloneCampaign && !resellerOutbound ? campaignUsername : username,
        }),
      })
        .then(response => response.json())
        .then(data => {
          const status = data.status;
          console.log('Status: ' + status);
          if (status === 'true') {
            console.log('Client already exists');
            alert('This username is already registered. Please login or register with a different phone number.');
            usernameTaken = true; // Set the variable to true if the phone number is taken
            window.gtag('event', 'phone_number_exists', {
              event_category: 'RegisterPage.js',
            });
          }
        })
        .catch(error => {
          setCreateAccLoading(false);
          console.error(error);
          window.gtag('event', 'couldnt_create_client', {
            event_category: 'RegisterPage.js',
            event_label: 'error',
          });
          alert('We are having trouble registering you. Please try again or email contact@myaifrontdesk.com.');
        });

      if (usernameTaken) {
        console.log('Phone num taken, returning');
        setCreateAccLoading(false);
        return; // Return from the main function if the phone number is taken
      }
    } catch (error) {
      setCreateAccLoading(false);
      console.error(error);
    }

    let email = emailAddress;
    if (reseller_email) {
      email = reseller_email;
    }
    if (campaign_user_email) {
      email = campaign_user_email;
    }
    console.log('Creating client with testingNumber ' + testingNumber);

    // Make server call using fetch to /createClient
    try {
      let featuresSubAccount = JSON.parse(JSON.stringify(features));
      let feauturesNotEmpty = Object.keys(featuresSubAccount).length > 0;
      if (test && feauturesNotEmpty) {
        featuresSubAccount.visibility.featureVisibilitySidebar = features.visibility.featureTrialVisibilitySideBar;
        featuresSubAccount.gating.featureGatingSidebar = features.gating.featureTrialGatingSideBar;
        featuresSubAccount.visibility.configureVisibility = features.visibility.configureVisibilityTrial;
        featuresSubAccount.gating.configureGating = features.gating.configureGatingTrial;
        featuresSubAccount.visibility.advanceFeaturesVisibility = features.visibility.advanceFeaturesVisibilityTrial;
        featuresSubAccount.gating.advanceFeaturesGating = features.gating.advanceFeaturesGatingTrial;
      }
      const bodyObject: CreateClientBody = {
        email,
        businessName,
        testingNumber,
        systemPhrase,
        companyInfo,
        password:
          isCampaign && !isStandaloneCampaign && !resellerOutbound
            ? campaign_user_password || Date.now().toString()
            : password,
        referralCode,
        findOutAboutUs,
        sampleQA,
        selectedLanguages, // List of selected languages
        languageGreetings, // Dict of language to greeting phrase
        workflows,
        callWorkflows,
        businessType,
        reseller_email,
        campaign_user_email,
        purchase_quotes,
        username: isCampaign && !isStandaloneCampaign && !resellerOutbound ? campaignUsername : username,
        time_zone: momentTimeZone.tz.guess(),
        english_voice_selected: JSON.stringify({
          voiceId: voiceModel,
          provider: voiceProvider,
        }),
        auto_scale,
        unreservedReceptionists,
        avatarBg: generateRandomColour(),
        voicemails_enabled: true,
        call_recording_enabled: true,
        test,
        featuresSubAccount: featuresSubAccount,
      };

      if (isCampaign) {
        bodyObject.resellerOutbound =
          (window.location.href.includes(AppRoutes.RESELLER_PORTAL) && window.location.href.includes('outbound')) ||
          window.location.href.includes(AppRoutes.WHITE_LABEL_CAMPAIGN_REGISTER);
      }

      let body = JSON.stringify(bodyObject);

      if (isCampaign) {
        const filteredCampaignVariables: Record<string, any> = {};
        Object.keys(campaignVariables || {}).forEach(el => {
          if (body.includes(`{{${el}}}`)) {
            filteredCampaignVariables[el] = campaignVariables?.[el];
          }
        });
        bodyObject.variables = filteredCampaignVariables;
        body = JSON.stringify(bodyObject);
      }

      const response = await fetch(`${serverURL}/createClient`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body,
      });

      if (!response.ok) {
        window.gtag('event', 'couldnt_create_client', {
          event_category: 'RegisterPage.js',
          event_label: 'error',
        });
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      const status = data.success;
      if (status) {
        // Track successful client creation
        window.gtag('event', 'create_client', {
          event_category: 'RegisterPage.js',
          event_label: 'success',
        });

        if (data.token) {
          // Store token if not in reseller portal
          if (!window.location.href.includes(AppRoutes.RESELLER_PORTAL)) {
            localStorage.setItem('AUTH_TOKEN', JSON.stringify(data.token));
          }

          // Handle campaign-related navigation
          if (isCampaign) {
            // Handle reseller outbound navigation
            if (window.location.href.includes(AppRoutes.RESELLER_OUTBOUND)) {
              return handleCampaignCreated();
            } else if (window.location.href.includes('/novi-portal')) {
              return handleCampaignCreated();
            }
            // Handle debranded campaign navigation
            return navigate(debrand ? AppRoutes.WHITE_LABEL_CAMPAIGN_LOGIN : '/novi-portal/login');
          }

          // Default navigation when not debranded
          if (!debrand) {
            return navigate('/');
          }
        }

        // Handle campaign without token
        if (isCampaign) {
          if (window.location.href.includes(AppRoutes.RESELLER_OUTBOUND)) {
            return handleCampaignCreated();
          }
          if (debrand) {
            return navigate(AppRoutes.WHITE_LABEL_CAMPAIGN_LOGIN);
          }
        }

        // Handle debranded scenarios
        if (debrand) {
          if (reroute_to_unbranded_login) {
            return navigate('/dashboard', {
              state: { username, password },
            });
          }

          // Update debrand modals
          debrand_modal_instructions_setter?.('true');
          debranded_registration_modal_active_setter?.(false);
          return;
        }
      }
    } catch (error) {
      window.gtag('event', 'couldnt_create_client', {
        event_category: 'RegisterPage.js',
        event_label: 'error',
      });
      console.error(error);
    } finally {
      setCreateAccLoading(false);
    }
  };

  // Define the types for the state and events
  interface LanguageGreetings {
    [key: string]: string;
  }

  const languages = [
    'English',
    'Spanish',
    'French',
    'German',
    'Portuguese',
    'Japanese',
    'Mandarin',
    'Arabic',
    'Russian',
    'Hindi',
    'Dutch',
    'Italian',
    'Romanian',
    'Armenian',
    'Korean',
    'Vietnamese',
    'Turkish',
    'Danish',
  ];

  const [selectedLanguages, setSelectedLanguages] = useState<string[]>(['English']);
  const [languageGreetings, setLanguageGreetings] = useState<LanguageGreetings>({ English: '' });

  const languageExamples: { [key: string]: string } = {
    English: 'Hello, how may I help you?',
    Spanish: 'Hola, ¿en qué puedo ayudarte?',
    French: 'Bonjour, comment puis-je vous aider ?',
    German: 'Hallo, wie kann ich Ihnen helfen?',
    Portuguese: 'Olá, como posso ajudá-lo?',
    Japanese: 'こんにちは、どのようなお手伝いができますか？',
    Mandarin: '你好，我可以帮助你吗？',
    Arabic: 'مرحبًا، كيف يمكنني مساعدتك؟',
    Russian: 'Привет, чем я могу помочь вам?',
    Hindi: 'नमस्ते, मैं आपकी कैसे मदद कर सकता हूँ?',
    Italian: 'Ciao, come posso aiutarti?',
    Romanian: 'Bună, cum te pot ajuta?',
    Armenian: 'Բարև, ինչպես կարող եմ օգնել քեզ?',
    Korean: '안녕하세요, 어떻게 도와 드릴까요?',
    Vietnamese: 'Xin chào, tôi có thể giúp gì cho bạn?',
    Turkish: 'Merhaba, size nasıl yardımcı olabilirim?',
  };

  const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value?.toLowerCase();
    const allowedReg = /^(?!.*\s)[a-z0-9._#@!$%]*$/;

    setUsername(newValue || '');

    if (allowedReg.test(newValue || '')) {
      setErrors(prev => ({
        ...(prev || {}),
        username: '',
      }));
    } else {
      setErrors(prev => ({
        ...(prev || {}),
        username: 'Only these characters are allowed: a-z, 0-9, _ # @ ! $ %',
      }));
    }
  };

  const handleLanguageChange = (language: string) => {
    if (selectedLanguages.includes(language)) {
      setSelectedLanguages(prev => prev.filter(lang => lang !== language));
      const updatedGreetings = { ...languageGreetings };
      delete updatedGreetings[language];
      setLanguageGreetings(updatedGreetings);
    } else {
      setSelectedLanguages(prev => [...prev, language]);
      setLanguageGreetings({
        ...languageGreetings,
        [language]: languageExamples[language],
      });
    }
  };

  const handleGreetingChange = (language: string, value: string) => {
    setLanguageGreetings(prev => ({ ...prev, [language]: value }));
  };

  // const isFormValid = testingNumber && systemPhrase && companyInfo;

  const header = (
    <div
      className={classes.header}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '16px 0',
        fontSize: '18px', // Increased font size for better readability
        background: '#f8f8f8', // Light background color
        color: 'rgb(119, 129, 145)',
      }}
    >
      <button
        className={classes.navItem}
        onClick={() => window.open('https://www.myaifrontdesk.com', '_blank')}
        style={{
          padding: '8px 12px', // Added padding for better button appearance
          border: 'none',
          background: 'transparent', // Transparent background
          color: 'inherit',
        }}
      >
        Product
      </button>
      <button
        className={classes.navItem}
        onClick={() => (window.location.href = '/')}
        style={{
          textDecoration: 'none',
          cursor: 'pointer',
          border: 'none',
          padding: '8px 12px',
          background: 'transparent',
          color: 'inherit',
        }}
      >
        Login
      </button>
      <button
        className={classes.navItem}
        onClick={() => window.open('https://www.myaifrontdesk.com/book-a-consultation', '_blank')}
        style={{
          textDecoration: 'none',
          cursor: 'pointer',
          padding: '8px 12px',
          border: 'none',
          background: 'transparent',
          color: 'inherit',
        }}
      >
        Book Consultation
      </button>
      <button
        className={classes.navItem}
        onClick={() =>
          (window.location.href =
            'mailto:contact@myaifrontdesk.com?subject=Contact Us&body=Please let us know how we can assist you.')
        }
        style={{
          textDecoration: 'none',
          cursor: 'pointer',
          padding: '8px 12px',
          border: 'none',
          background: 'transparent',
          color: 'inherit',
        }}
      >
        Contact
      </button>
    </div>
  );

  // const welcomeScreen = <></>;
  interface Variable {
    name: string;
    isMandatory: boolean;
  }

  // const [variables, setVariables] = useState<Variable[]>([]); // Typed as an array of `Variable` objects
  // const [showModal, setShowModal] = useState<boolean>(false); // Boolean state for modal visibility
  // const [variableName, setVariableName] = useState<string>(''); // String state for variable name
  // const [isMandatory, setIsMandatory] = useState<boolean>(true); // Boolean state for mandatory flag

  // const openModal = () => {
  //   setShowModal(true);
  // };

  // const closeModal = () => {
  //   setShowModal(false);
  // };

  // const insertVariable = (name: string) => {
  //   const variable = `{{${name}}}`;
  //   const textArea = document.getElementById('systemPhraseTextArea') as HTMLTextAreaElement; // Typecast to HTMLTextAreaElement
  //   if (textArea) {
  //     const startPos = textArea.selectionStart ?? 0; // Ensure startPos has a default value if null
  //     const endPos = textArea.selectionEnd ?? 0; // Ensure endPos has a default value if null
  //     const newText = systemPhrase.slice(0, startPos) + variable + systemPhrase.slice(endPos);
  //     setSystemPhrase(newText);
  //   }
  //   setVariables([...variables, { name, isMandatory }]);
  //   closeModal();
  // };

  // const handleEditVariable = (variableName: string) => {
  //   // Implement edit variable functionality
  //   console.log('Edit variable:', variableName);
  // };

  const validateEmail = (email: string): boolean => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const [playingModel, setPlayingModel] = useState<string>(''); // Keeps track of the currently playing model

  const handlePlayPause = (model: any) => {
    if (playingModel === model.value) {
      // Pause the currently playing sound
      soundPlayerRef?.current?.pause();
      setPlayingModel('');
    } else {
      // Play a new sound
      if (soundPlayerRef.current) {
        soundPlayerRef.current.pause();
      }
      soundPlayerRef.current = new Audio(soundMapping[model.value]);
      soundPlayerRef.current.load();

      const promise = soundPlayerRef.current.play();
      if (promise !== undefined) {
        promise
          .then(() => {
            setPlayingModel(model.value); // Set the current model as playing
          })
          .catch(error => {
            console.log(error);
          });
      }

      // Add an event listener to handle when the sound ends
      soundPlayerRef.current.onended = () => {
        setPlayingModel(''); // Reset when the sound finishes
      };
    }
  };

  return (
    <>
      <div
        className={(isDarkMode ? classes.darkMode : '') + ' ' + classes.rootPage}
        style={{
          height: '100%',
          overflow: 'auto',
          // fontFamily: "Roboto, sans-serif",
          overflowX: 'hidden',
          transition: '.4s ease',
        }}
      >
        {!debrand && header}

        <script src='https://www.google.com/recaptcha/api.js' async defer></script>

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            maxWidth: '100%',
            padding: '1rem 1rem 3rem',
            borderRadius: '10px',
            // fontFamily: "Quicksand, sans-serif",
            margin: '1rem auto', // Centering the card on the page
            minHeight: 'calc(100vh - 163px)',
            position: 'relative',
          }}
        >
          <div className={classes.registerPageContainer}>
            <div
              style={{
                width: '100%',
              }}
            >
              {debrand && (
                <>
                  {logo_s3 && (
                    <img
                      className={classes.centered}
                      style={{
                        position: 'absolute', // Add position absolute
                        top: 10, // Align to the top
                        left: 10, // Align to the left
                        margin: '1rem', // Add some margin for spacing
                      }}
                      width='50rem'
                      height='50rem'
                      src={logo_s3}
                      alt='AI Receptionist'
                    />
                  )}
                </>
              )}

              {stepState === 0 && !reseller_email && !campaign_user_email && (
                <Step8
                  emailAddress={emailAddress}
                  setEmailAddress={setEmailAddress}
                  businessName={businessName}
                  stepState={stepState}
                  handleStepTransition={handleStepTransition}
                  step0Validation={step0Validation}
                  step0StateMutation={step0StateMutation}
                  stepLoading={stepLoading}
                />
              )}

              {((stepState === 1 && !reseller_email && !campaign_user_email) ||
                (stepState === 0 && (reseller_email || campaign_user_email))) && (
                <Step0
                  stepState={stepState}
                  soundPlayerRef={soundPlayerRef}
                  voiceModel={voiceModel}
                  setStepState={setStepState}
                  isCampaign={isCampaign}
                  setVoiceModel={setVoiceModel}
                  setVoiceProvider={setVoiceProvider}
                  handleStepTransition={handleStepTransition}
                  step1Validation={step1Validation}
                  step1StateMutation={step1StateMutation}
                  stepLoading={stepLoading}
                  handlePlayPause={handlePlayPause}
                  playingModel={playingModel}
                />
              )}

              {((stepState === 2 && !reseller_email && !campaign_user_email) ||
                (stepState === 1 && (reseller_email || campaign_user_email))) && (
                <Step1
                  stepState={stepState}
                  setIsUsingATemplate={setIsUsingATemplate}
                  setStepState={setStepState}
                  isCampaign={isCampaign}
                  businessType={businessType}
                  setBusinessType={setBusinessType}
                  isUsingATemplate={isUsingATemplate}
                  handleStepTransition={handleStepTransition}
                  step2Validation={step2Validation}
                  step2StateMutation={step2StateMutation}
                  stepLoading={stepLoading}
                />
              )}

              {stepState === 2 && !reseller_email && campaign_user_email && (
                <Step2
                  stepState={stepState}
                  setStepState={setStepState}
                  languages={languages}
                  handleLanguageChange={handleLanguageChange}
                  selectedLanguages={selectedLanguages}
                  handleStepTransition={handleStepTransition}
                  step3Validation={step3Validation}
                  stepLoading={stepLoading}
                />
              )}

              {((stepState === 3 && !reseller_email && !campaign_user_email) ||
                (stepState === 2 && reseller_email && !campaign_user_email) ||
                (stepState === 3 && !reseller_email && campaign_user_email)) && (
                <Step3
                  isCampaign={isCampaign}
                  reseller_email={reseller_email}
                  campaign_user_email={campaign_user_email}
                  businessName={businessName}
                  setBusinessName={setBusinessName}
                  stepState={stepState}
                  setStepState={setStepState}
                  systemPhrase={systemPhrase}
                  handleStepTransition={handleStepTransition}
                  step4Validation={step4Validation}
                  step4StateMutation={step4StateMutation}
                  stepLoading={stepLoading}
                />
              )}

              {isCampaign
                ? stepState === 4 && (
                    <Step4
                      setSystemPhrase={setSystemPhrase}
                      setCampaignVariables={setCampaignVariables}
                      setStepState={setStepState}
                      systemPhrase={systemPhrase}
                      campaignVariables={campaignVariables}
                      businessName={businessName}
                      handleStepTransition={handleStepTransition}
                      step5Validation={step5Validation}
                      step5StateMutation={step5StateMutation}
                      stepLoading={stepLoading}
                      stepState={stepState}
                    />
                  )
                : ((stepState === 4 && !reseller_email) || (stepState === 3 && reseller_email)) && (
                    <Step4A
                      businessName={businessName}
                      systemPhrase={systemPhrase}
                      setSystemPhrase={setSystemPhrase}
                      setGreetingTemplateModalActive={setGreetingTemplateModalActive}
                      isUsingATemplate={isUsingATemplate}
                      selectedLanguages={selectedLanguages}
                      languageGreetings={languageGreetings}
                      languageExamples={languageExamples}
                      stepState={stepState}
                      setStepState={setStepState}
                      businessType={businessType}
                      handleStepTransition={handleStepTransition}
                      step5Validation={step5Validation}
                      step5StateMutation={step5StateMutation}
                      stepLoading={stepLoading}
                      handleGreetingChange={handleGreetingChange}
                    />
                  )}

              {((stepState === 4 && !reseller_email) || (stepState === 3 && reseller_email)) &&
                greetingTemplateModalActive && <div className={classes.modalOverlay}></div>}
              {((stepState === 4 && !reseller_email) || (stepState === 3 && reseller_email)) && (
                <Step4B
                  setGreetingTemplateModalActive={setGreetingTemplateModalActive}
                  setSelectedGreetingTemplate={setSelectedGreetingTemplate}
                  greetingsPromptTemplates={greetingsPromptTemplates}
                  selectedGreetingTemplate={selectedGreetingTemplate}
                  setSystemPhrase={setSystemPhrase}
                  greetingTemplateModalActive={greetingTemplateModalActive}
                  businessName={businessName}
                />
              )}

              {((stepState === 5 && !reseller_email && !campaign_user_email) ||
                (stepState === 4 && reseller_email && !campaign_user_email) ||
                (stepState === 5 && !reseller_email && campaign_user_email)) && (
                <Step5
                  setShowBusinessInformationModal={setShowBusinessInformationModal}
                  setStepState={setStepState}
                  stepState={stepState}
                />
              )}

              {((stepState === 5 && !reseller_email && !campaign_user_email) ||
                (stepState === 4 && reseller_email && !campaign_user_email) ||
                (stepState === 5 && !reseller_email && campaign_user_email)) &&
                showBusinessInformationModal && <div className={classes.modalOverlay} />}

              {((stepState === 5 && !reseller_email && !campaign_user_email) ||
                (stepState === 4 && reseller_email && !campaign_user_email) ||
                (stepState === 5 && !reseller_email && campaign_user_email)) && (
                <Step5A
                  setShowBusinessInformationModal={setShowBusinessInformationModal}
                  companyInfo={companyInfo}
                  campaignVariables={campaignVariables}
                  setCompanyInfo={setCompanyInfo}
                  setCampaignVariables={setCampaignVariables}
                  businessName={businessName}
                  setBusinessInformationTemplateModalActive={setBusinessInformationTemplateModalActive}
                  isUsingATemplate={isUsingATemplate}
                  businessType={businessType}
                  setStepState={setStepState}
                  stepState={stepState}
                  showBusinessInformationModal={showBusinessInformationModal}
                  isCampaign={isCampaign}
                  handleStepTransition={handleStepTransition}
                  stepLoading={stepLoading}
                  createClientStepByStep={createClientStepByStep}
                />
              )}

              {((stepState === 5 && !reseller_email && !campaign_user_email) ||
                (stepState === 4 && reseller_email && !campaign_user_email) ||
                (stepState === 5 && !reseller_email && campaign_user_email)) &&
                businessInformationTemplateModalActive && <div className={classes.modalOverlay}></div>}

              {((stepState === 5 && !reseller_email && !campaign_user_email) ||
                (stepState === 4 && reseller_email && !campaign_user_email) ||
                (stepState === 5 && !reseller_email && campaign_user_email)) && (
                <Step5B
                  setSelectedBusinessInfoTemplate={setSelectedBusinessInfoTemplate}
                  businessInformationsTemplates={businessInformationsTemplates}
                  businessName={businessName}
                  greetingsPromptTemplates={greetingsPromptTemplates}
                  selectedBusinessInfoTemplate={selectedBusinessInfoTemplate}
                  setCompanyInfo={setCompanyInfo}
                  setBusinessInformationTemplateModalActive={setBusinessInformationTemplateModalActive}
                  businessInformationTemplateModalActive={businessInformationTemplateModalActive}
                />
              )}

              {((stepState === 6 && !reseller_email && !campaign_user_email) ||
                (stepState === 5 && reseller_email && !campaign_user_email) ||
                (stepState === 6 && !reseller_email && campaign_user_email)) && (
                <Step6
                  setStepState={setStepState}
                  stepState={stepState}
                  setShowLinkTextingModal={setShowLinkTextingModal}
                />
              )}

              {((stepState === 6 && !reseller_email && !campaign_user_email) ||
                (stepState === 5 && reseller_email && !campaign_user_email) ||
                (stepState === 6 && !reseller_email && campaign_user_email)) &&
                showLinkTextingModal && <div className={classes.modalOverlay} />}
              {((stepState === 6 && !reseller_email && !campaign_user_email) ||
                (stepState === 5 && reseller_email && !campaign_user_email) ||
                (stepState === 6 && !reseller_email && campaign_user_email)) && (
                <Step6A
                  showLinkTextingModal={showLinkTextingModal}
                  workflows={workflows}
                  setShowLinkTextingModal={setShowLinkTextingModal}
                  isCampaign={isCampaign}
                  campaignVariables={campaignVariables}
                  handleDescriptionChange={handleDescriptionChange}
                  handleTextMessageChange={handleTextMessageChange}
                  setCampaignVariables={setCampaignVariables}
                  handleVoiceResponseChange={handleVoiceResponseChange}
                  removeWorkflow={removeWorkflow}
                  setTextingTemplateModalActive={setTextingTemplateModalActive}
                  isUsingATemplate={isUsingATemplate}
                  systemPhrase={systemPhrase}
                  businessType={businessType}
                  setStepState={setStepState}
                  stepState={stepState}
                  addWorkflow={addWorkflow}
                  handleStepTransition={handleStepTransition}
                  stepLoading={stepLoading}
                />
              )}

              {((stepState === 6 && !reseller_email && !campaign_user_email) ||
                (stepState === 5 && reseller_email && !campaign_user_email) ||
                (stepState === 6 && !reseller_email && campaign_user_email)) &&
                textingTemplateModalActive && <div className={classes.modalOverlay}></div>}

              {((stepState === 6 && !reseller_email && !campaign_user_email) ||
                (stepState === 5 && reseller_email && !campaign_user_email) ||
                (stepState === 6 && !reseller_email && campaign_user_email)) && (
                <Step6B
                  setTextingTemplateModalActive={setTextingTemplateModalActive}
                  setSelectedTextingTemplate={setSelectedTextingTemplate}
                  textingWorkflowsTemplates={textingWorkflowsTemplates}
                  selectedTextingTemplate={selectedTextingTemplate}
                  textingTemplateModalActive={textingTemplateModalActive}
                  setWorkflows={setWorkflows}
                  workflows={workflows}
                />
              )}

              {((stepState === 7 && !reseller_email && !campaign_user_email) ||
                (stepState === 6 && reseller_email && !campaign_user_email) ||
                (stepState === 7 && !reseller_email && campaign_user_email)) && (
                <Step7
                  setShowCallTransferringModal={setShowCallTransferringModal}
                  setStepState={setStepState}
                  stepState={stepState}
                />
              )}

              {((stepState === 7 && !reseller_email && !campaign_user_email) ||
                (stepState === 6 && reseller_email && !campaign_user_email) ||
                (stepState === 7 && !reseller_email && campaign_user_email)) && (
                <Step7A
                  callWorkflows={callWorkflows}
                  setShowCallTransferringModal={setShowCallTransferringModal}
                  isCampaign={isCampaign}
                  campaignVariables={campaignVariables}
                  handleCallWorkflowDescriptionChange={handleCallWorkflowDescriptionChange}
                  setCampaignVariables={setCampaignVariables}
                  handleCallWorkflowVoiceResponseChange={handleCallWorkflowVoiceResponseChange}
                  handleCallWorkflowForwardingNumber={handleCallWorkflowForwardingNumber}
                  removeCallWorkflow={removeCallWorkflow}
                  addCallWorkflow={addCallWorkflow}
                  setCallTransferTemplateModalActive={setCallTransferTemplateModalActive}
                  isUsingATemplate={isUsingATemplate}
                  systemPhrase={systemPhrase}
                  businessType={businessType}
                  reseller_email={reseller_email}
                  setStepState={setStepState}
                  stepState={stepState}
                  showCallTransferringModal={showCallTransferringModal}
                  handleStepTransition={handleStepTransition}
                  stepLoading={stepLoading}
                />
              )}

              {((stepState === 7 && !reseller_email && !campaign_user_email) ||
                (stepState === 6 && reseller_email && !campaign_user_email) ||
                (stepState === 7 && !reseller_email && campaign_user_email)) &&
                callTransferTemplateModalActive && <div className={classes.modalOverlay}></div>}

              {((stepState === 7 && !reseller_email && !campaign_user_email) ||
                (stepState === 6 && reseller_email && !campaign_user_email) ||
                (stepState === 7 && !reseller_email && campaign_user_email)) && (
                <Step7B
                  callWorkflows={callWorkflows}
                  setCallWorkflows={setCallWorkflows}
                  setCallTransferTemplateModalActive={setCallTransferTemplateModalActive}
                  setSelectedCallTemplate={setSelectedCallTemplate}
                  callTransferTemplates={callTransferTemplates}
                  selectedCallTemplate={selectedCallTemplate}
                  callTransferTemplateModalActive={callTransferTemplateModalActive}
                />
              )}

              {((stepState === 8 && !reseller_email && !campaign_user_email) ||
                (stepState === 7 && reseller_email && !campaign_user_email)) && (
                <Step9
                  reseller_email={reseller_email}
                  campaign_user_email={campaign_user_email}
                  testingNumber={testingNumber}
                  stepState={stepState}
                  setTestingNumber={setTestingNumber}
                  handleStepTransition={handleStepTransition}
                  stepLoading={stepLoading}
                />
              )}

              {((stepState === 9 && !reseller_email && !campaign_user_email) ||
                (stepState === 8 && reseller_email && !campaign_user_email) ||
                (stepState === 8 && campaign_user_email && !reseller_email)) && (
                <Step10
                  isCampaign={isCampaign}
                  isStandaloneCampaign={isStandaloneCampaign}
                  resellerOutbound={resellerOutbound}
                  reseller_email={reseller_email}
                  campaign_user_email={campaign_user_email}
                  username={username}
                  handleUsernameChange={handleUsernameChange}
                  errors={errors}
                  password={password}
                  setPassword={setPassword}
                  setErrors={setErrors}
                  confirmPassword={confirmPassword}
                  setConfirmPassword={setConfirmPassword}
                  debrand={debrand}
                  termsAndConditions={termsAndConditions}
                  setTermsAndConditions={setTermsAndConditions}
                  createAccLoading={createAccLoading}
                  handleSubmit={handleSubmit}
                  createClientStepByStepLoading={createClientStepByStep.isPending}
                  stepLoading={stepLoading}
                />
              )}

              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: '2rem',
                  justifyContent: 'center',
                  position: 'absolute',
                  left: '50%',
                  transform: 'translateX(-50%)',
                  bottom: '1rem',
                }}
              >
                <div
                  style={{
                    marginRight: '1rem',
                    display: 'flex',
                    alignItems: 'center',
                    opacity: stepState === 0 ? 0.5 : 1,
                    pointerEvents: stepState === 0 ? 'none' : 'all',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    setStepState(prev => {
                      let newState = prev - 1;
                      // skipping language for campaign
                      if (newState <= 0) return 0;
                      return newState;
                    });
                  }}
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    xmlnsXlink='http://www.w3.org/1999/xlink'
                    fill='#000000'
                    height='20px'
                    width='20px'
                    version='1.1'
                    id='Capa_1'
                    viewBox='0 0 219.151 219.151'
                    xmlSpace='preserve'
                  >
                    <g>
                      <path d='M109.576,219.151c60.419,0,109.573-49.156,109.573-109.576C219.149,49.156,169.995,0,109.576,0S0.002,49.156,0.002,109.575   C0.002,169.995,49.157,219.151,109.576,219.151z M109.576,15c52.148,0,94.573,42.426,94.574,94.575   c0,52.149-42.425,94.575-94.574,94.576c-52.148-0.001-94.573-42.427-94.573-94.577C15.003,57.427,57.428,15,109.576,15z' />
                      <path d='M94.861,156.507c2.929,2.928,7.678,2.927,10.606,0c2.93-2.93,2.93-7.678-0.001-10.608l-28.82-28.819l83.457-0.008   c4.142-0.001,7.499-3.358,7.499-7.502c-0.001-4.142-3.358-7.498-7.5-7.498l-83.46,0.008l28.827-28.825   c2.929-2.929,2.929-7.679,0-10.607c-1.465-1.464-3.384-2.197-5.304-2.197c-1.919,0-3.838,0.733-5.303,2.196l-41.629,41.628   c-1.407,1.406-2.197,3.313-2.197,5.303c0.001,1.99,0.791,3.896,2.198,5.305L94.861,156.507z' />
                    </g>
                  </svg>
                </div>
                {new Array(
                  reseller_email
                    ? TOTAL_STEPS_FOR_RESELLER
                    : campaign_user_email
                      ? TOTAL_STEPS_FOR_NON_SMBS
                      : TOTAL_STEPS_FOR_SMBS
                )
                  .fill(0)
                  .map((el, idx) => {
                    console.log(el);
                    return (
                      <div
                        key={'dot-indicator-' + idx}
                        className='p-1.5 cursor-pointer'
                        onClick={() => {
                          // skipping language for campaign

                          if (idx < stepState) setStepState(idx);
                        }}
                      >
                        <div className={classes.dot + ' ' + (idx === stepState ? classes.active : '')}></div>
                      </div>
                    );
                  })}
                <div className='hidden'>➡️</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegisterPage;
