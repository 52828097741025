import React from 'react';
import StepWrapper from './StepWrapper';

interface CompanySizeSelectorProps {
  stepState: number;
  selectedPeople: string | null;
  setSelectedPeople: (value: string) => void;
  selectedRevenue: string | null;
  setSelectedRevenue: (value: string) => void;
  handleStepTransition: (nextStep: number) => void;
  businessType?: string;
}

const companySizes = ['Just me', '2-3 people', '4-10 people', '11-19 people', '20+ people'] as const;

type CompanySize = (typeof companySizes)[number];

const CompanySizeSelector: React.FC<CompanySizeSelectorProps> = ({
  stepState,
  selectedPeople,
  setSelectedPeople,
  handleStepTransition,
}) => {
  const handleSelection = (value: CompanySize) => {
    setSelectedPeople(value);
  };

  return (
    <StepWrapper handleNext={() => handleStepTransition(stepState + 1)} disableNext={!selectedPeople}>
      <div className='w-full flex flex-col'>
        <div className='flex flex-col items-start w-full'>
          <h3 className='sm:text-3xl text-xl font-bold text-gray-900 text-start mb-0'>Tell us about your team size</h3>
          <p className='text-gray-500 sm:text-lg text-sm'>This helps us customize your AI assistant's capabilities</p>
        </div>

        <div className='grid grid-cols-2 sm:grid-cols-3 gap-3'>
          {companySizes.map(option => (
            <button
              key={option}
              onClick={() => handleSelection(option)}
              className={`p-3 rounded-full border text-center sm:text-sm text-xs font-medium transition-all ${
                selectedPeople === option
                  ? 'bg-button-success text-white border-button-success'
                  : 'bg-white text-gray-800 border-gray-300 hover:bg-gray-100'
              }`}
            >
              {option}
            </button>
          ))}
        </div>
      </div>
    </StepWrapper>
  );
};

export default CompanySizeSelector;
