import React from 'react';
import Select, { SingleValue } from 'react-select';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Input, Tooltip } from 'antd';

interface Option {
  value: string;
  label: string;
}

interface SearchFilterProps {
  provider: string;
  gender: string;
  language: string;
  searchText: string;
  accent: string;
  setSearchText: (text: string) => void;
  onFilterChange: (filterType: string, value: string) => void;
  uniqueLanguages: string[];
  uniqueGenders: string[];
  uniqueAccents: string[];
  uniqueProviders: string[];
}

const SearchFilter: React.FC<SearchFilterProps> = ({
  provider,
  gender,
  language,
  accent,
  searchText,
  setSearchText,
  onFilterChange,
  uniqueLanguages,
  uniqueGenders,
  uniqueAccents,
  uniqueProviders,
}) => {
  const handleSelectChange = (filterType: string) => (selectedOption: SingleValue<Option>) => {
    if (selectedOption) {
      onFilterChange(filterType, selectedOption.value);
    }
  };

  return (
    <div>
      <div className='flex gap-5 flex-wrap'>
        <label className='w-64'>
          <span className='font-medium'>Search</span>
          <Input
            value={searchText}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchText(e.target.value)}
            className='w-full py-1.5 rounded-md'
            placeholder='Type something...'
          />
        </label>

        <label className='w-32'>
          <span className='font-medium'>Provider</span>
          <Select
            options={['All', ...uniqueProviders].map(provider => ({ value: provider, label: provider }))}
            value={{ value: provider, label: provider }}
            onChange={handleSelectChange('provider')}
          />
        </label>

        <label className='w-32'>
          <span className='font-medium'>Gender</span>
          <Select
            options={['All', ...uniqueGenders].map(gender => ({ value: gender, label: gender }))}
            value={{ value: gender, label: gender }}
            onChange={handleSelectChange('gender')}
          />
        </label>

        <label className='w-32'>
          <div className='flex items-center gap-2'>
            <span className='font-medium'>Language</span>
            <Tooltip
              id='my-tooltip'
              className='!max-w-56 !max-h-40 !overflow-y-auto !opacity-100'
              title='By selecting Other Language you can enable access to different languages ie Spanish, French, German, Portuguese, Japanese, Italian, Mandarin, Arabic, Russian, Hindi, Dutch, Romanian, Armenian, Korean, Vietnamese, Turkish.'
            >
              <InfoCircleOutlined />
            </Tooltip>
          </div>
          <Select
            options={['All', ...uniqueLanguages].map(language => ({ value: language, label: language }))}
            value={{ value: language, label: language }} // Handle selected value for accent (if applicable)
            onChange={handleSelectChange('language')}
          />
        </label>

        <label className='w-32'>
          <span className='font-medium'>Accent</span>
          <Select
            options={['All', ...uniqueAccents].map(accent => ({ value: accent, label: accent }))}
            value={{ value: accent, label: accent }} // Handle selected value for accent (if applicable)
            onChange={handleSelectChange('accent')}
          />
        </label>
      </div>
    </div>
  );
};

export default SearchFilter;
