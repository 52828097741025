import React from 'react';
import { Tabs, Typography, Card, Row, Col, Space } from 'antd';
import { PhoneOutlined, MessageOutlined, InfoCircleOutlined, SyncOutlined, DatabaseOutlined } from '@ant-design/icons';
import CallScheduleInfo from './CallSchedules/CallScheduleInfo';
import TextScheduleInfo from './TextSchedules/TextScheduleInfo';
import { CampaignSchedulingProps } from './types';

const { Title, Text } = Typography;

const CampaignAlerts = () => {
  return (
    <>
      {/* Campaign Flexibility Section */}
      <div className='bg-[#f0f5ff] rounded-lg mb-6 p-4 sm:p-6 relative'>
        {/* Icon for larger screens */}
        <div className='hidden sm:block absolute left-6 top-7'>
          <InfoCircleOutlined className='text-[20px] text-[#1677ff]' />
        </div>

        <div className='sm:ml-8'>
          {/* Mobile header with icon */}
          <Space className='sm:hidden mb-2' align='center'>
            <InfoCircleOutlined className='text-[20px] text-[#1677ff]' />
            <Title level={4} className='!mb-0 !font-medium'>
              Campaign Flexibility
            </Title>
          </Space>

          {/* Desktop header */}
          <Title level={4} className='hidden sm:block !mb-2 !font-medium'>
            Campaign Flexibility
          </Title>

          <Text className='block mb-4'>Choose the communication channels that work best for your campaign:</Text>

          <Row
            gutter={[
              { xs: 8, sm: 16 },
              { xs: 8, sm: 16 },
            ]}
          >
            <Col xs={24} sm={24} md={8}>
              <Card bordered className='border-[#e6f4ff] bg-white'>
                <Text strong className='block mb-1'>
                  📞 Calls Only
                </Text>
                <Text type='secondary'>Focus solely on call campaigns</Text>
              </Card>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Card bordered className='border-[#e6f4ff] bg-white'>
                <Text strong className='block mb-1'>
                  💬 Texts Only
                </Text>
                <Text type='secondary'>Run text-based campaigns</Text>
              </Card>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Card bordered className='border-[#e6f4ff] bg-white'>
                <Text strong className='block mb-1'>
                  🎯 Combined Approach
                </Text>
                <Text type='secondary'>Utilize both channels together</Text>
              </Card>
            </Col>
          </Row>
        </div>
      </div>

      {/* Campaign Information Section */}
      <div className='bg-[#f0f5ff] rounded-lg p-4 sm:p-6 relative'>
        {/* Icon for larger screens */}
        <div className='hidden sm:block absolute left-6 top-7'>
          <InfoCircleOutlined className='text-[20px] text-[#1677ff]' />
        </div>

        <div className='sm:ml-8'>
          {/* Mobile header with icon */}
          <Space className='sm:hidden mb-2' align='center'>
            <InfoCircleOutlined className='text-[20px] text-[#1677ff]' />
            <Title level={4} className='!mb-0 !font-medium'>
              Campaign Information
            </Title>
          </Space>

          {/* Desktop header */}
          <Title level={4} className='hidden sm:block !mb-4 !font-medium'>
            Campaign Information
          </Title>

          <Row
            gutter={[
              { xs: 8, sm: 24 },
              { xs: 8, sm: 24 },
            ]}
          >
            <Col xs={24} sm={24} md={8}>
              <Card bordered className='border-[#e6f4ff] bg-white'>
                <Space direction='vertical' size={8}>
                  <Space>
                    <DatabaseOutlined className='text-[20px] text-[#1677ff]' />
                    <Text strong>Shared Lead Database</Text>
                  </Space>
                  <Text type='secondary'>
                    Both calling and texting campaigns utilize the same lead database. Manage all your leads through the
                    Leads tab.
                  </Text>
                </Space>
              </Card>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Card bordered className='border-[#e6f4ff] bg-white'>
                <Space direction='vertical' size={8}>
                  <Space>
                    <SyncOutlined className='text-[20px] text-[#1677ff]' />
                    <Text strong>Status Synchronization</Text>
                  </Space>
                  <Text type='secondary'>
                    Lead status updates are synchronized between call and text campaigns, ensuring consistent tracking
                    across all communications.
                  </Text>
                </Space>
              </Card>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Card bordered className='border-[#e6f4ff] bg-white'>
                <Space direction='vertical' size={8}>
                  <Space>
                    <InfoCircleOutlined className='text-[20px] text-[#1677ff]' />
                    <Text strong>Important Note</Text>
                  </Space>
                  <Text type='secondary'>
                    Once a lead's status is updated by either campaign type, they will not be included in subsequent
                    campaigns of any type.
                  </Text>
                </Space>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

const CampaignScheduling: React.FC<CampaignSchedulingProps> = ({
  campaignId,
  scheduleData,
  refetch,
  isLoading,
  isRefetching,
  clientId,
}) => {
  const items = [
    {
      key: 'call',
      label: (
        <span className='flex items-center gap-2'>
          <PhoneOutlined /> Call Schedule
        </span>
      ),
      children: (
        <div className='py-4'>
          <CallScheduleInfo
            campaignId={campaignId}
            scheduleData={scheduleData as any}
            isLoading={isLoading}
            isRefetching={isRefetching}
            refetch={refetch}
          />
        </div>
      ),
    },
    {
      key: 'text',
      label: (
        <span className='flex items-center gap-2'>
          <MessageOutlined /> Text Schedule
        </span>
      ),
      children: (
        <div className='py-4'>
          <TextScheduleInfo clientId={clientId} />
        </div>
      ),
    },
  ];

  return (
    <div className='max-w-7xl mx-auto p-4'>
      <Card bordered={false} className='shadow-sm rounded-lg' bodyStyle={{ padding: '24px' }}>
        <div className='mb-6'>
          <Title level={4} className='!mb-2'>
            Campaign Scheduling
          </Title>
          <Text type='secondary'>Manage your campaign's call schedule and text messaging settings.</Text>
        </div>

        <div className='mb-6'>
          <CampaignAlerts />
        </div>
        <Tabs
          defaultActiveKey='call'
          items={items}
          type='card'
          className='campaign-schedule-tabs'
          destroyInactiveTabPane
        />
      </Card>
    </div>
  );
};

export default CampaignScheduling;
