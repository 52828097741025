import LearnMore from '@/components/Buttons/LearnMore';
import { CustomModal } from '@/components/CustomModal';
import { useAppDispatch } from '@/store/hooks/useAppDispatch';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { getModalData } from '@/store/modal/selector';
import { closeModal } from '@/store/modal/slice';
import { FacebookOutlined, LinkOutlined, MailOutlined, WhatsAppOutlined, XOutlined } from '@ant-design/icons';
import { Button, notification, Tooltip } from 'antd';
import { MODAL_IDS } from 'src/enums/EModal';
import { TUTORIALS } from 'src/enums/ETutorials';

const ShareCallLogModal = () => {
  const dispatch = useAppDispatch();
  const data = useAppSelector(getModalData) as {
    shareableLink: string;
    debrand: boolean;
  };
  const shareOptions = [
    { key: 'Copy', icon: <LinkOutlined />, color: '#0000EE', label: 'Copy Link' },
    { key: 'Email', icon: <MailOutlined />, color: '#D44638', label: 'Email' },
    { key: 'Facebook', icon: <FacebookOutlined />, color: '#3b5998', label: 'Facebook' },
    { key: 'Twitter', icon: <XOutlined />, color: '#000000', label: 'X' },
    { key: 'WhatsApp', icon: <WhatsAppOutlined />, color: '#25D366', label: 'WhatsApp' },
  ];

  const handleShareLink = async (platform: string) => {
    try {
      if (!data.shareableLink) {
        throw new Error('shareableLink not found');
      }

      let baseUrl = import.meta.env.VITE_APP_BASE_URL;

      if (data.debrand) {
        baseUrl = import.meta.env.VITE_APP_WIHTELABEL_URL;
      }

      const url = `${baseUrl}/call-information/${data.shareableLink}`;

      if (platform === 'Facebook') {
        window.open(`https://www.facebook.com/sharer/sharer.php?u=${url}`, '_blank');
      } else if (platform === 'Copy') {
        try {
          await navigator.clipboard.writeText(url);
          notification.success({ message: 'Link copied to clipboard' });
        } catch (err) {
          // Fallback method
          const textArea = document.createElement('textarea');
          try {
            // Set up the temporary element
            textArea.value = url;
            // Make it invisible but functional
            textArea.style.cssText = 'position:fixed;pointer-events:none;opacity:0;';
            document.body.appendChild(textArea);

            // Handle iOS specific issues
            if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
              textArea.contentEditable = 'true';
              textArea.readOnly = true;
              const range = document.createRange();
              range.selectNodeContents(textArea);
              const selection = window.getSelection();
              if (selection) {
                selection.removeAllRanges();
                selection.addRange(range);
              }
              textArea.setSelectionRange(0, 999999);
            } else {
              textArea.select();
            }

            // Execute copy command
            const successful = document.execCommand('copy');
            if (!successful) {
              throw new Error('Copy command failed');
            }
            notification.success({ message: 'Link copied to clipboard' });
          } catch (fallbackError) {
            console.error('Fallback clipboard copy failed:', fallbackError);
            notification.error({
              message: 'Could not copy to clipboard. Please try copying manually.',
            });
          } finally {
            // Clean up
            if (document.body.contains(textArea)) {
              document.body.removeChild(textArea);
            }
          }
        }
      } else if (platform === 'Email') {
        window.open(
          `mailto:?subject=Check out this call recording&body=Hey, I thought you might be interested in this call recording: ${url}`,
          '_blank'
        );
      } else if (platform === 'X') {
        window.open(`https://twitter.com/intent/tweet?text=Check out this call recording: ${url}`, '_blank');
      } else if (platform === 'Whatsapp') {
        window.open(`https://api.whatsapp.com/send?text=Check out this call recording: ${url}`, '_blank');
      }
    } catch (error) {
      console.error('Error sharing call log', error);
      notification.error({ message: 'Error copy/sharing call log' });
    } finally {
      dispatch(closeModal());
    }
  };

  return (
    <CustomModal
      title={
        <div className='flex items-center gap-4'>
          <>Share Call Log</>
          <LearnMore tutorialId={TUTORIALS.SHARABLE_CALL_LINK} debrand={data?.debrand} />
        </div>
      }
      modalId={MODAL_IDS.SHARE_CALL_LOG_MODAL}
      centered
      footer={null}
    >
      <div className='flex flex-col items-center space-y-6'>
        <div className='flex justify-center space-x-4'>
          {shareOptions.map(option => (
            <Tooltip key={option.key} title={option.label}>
              <Button
                type='default'
                shape='circle'
                size='large'
                icon={option.icon}
                style={{
                  color: option.color,
                  borderColor: option.color,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '48px',
                  height: '48px',
                }}
                onClick={() => handleShareLink(option.key)}
              />
            </Tooltip>
          ))}
        </div>
      </div>
    </CustomModal>
  );
};

export default ShareCallLogModal;
