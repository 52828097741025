import apiClient from '@/utils/apiClient';

const UPDATE_EMBEDDABLE_API_PATH = '/api/embeddables';
const GET_EMBEDDABLE_API_PATH = '/api/embeddables';

export const updateEmbeddableConfig = async (payload: any): Promise<{ message: string; embeddableVoiceId: string }> => {
  try {
    const response = await apiClient.request({
      method: 'POST',
      endPoint: UPDATE_EMBEDDABLE_API_PATH,
      body: payload,
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};

export const getEmbeddableConfig = async (embeddableVoiceId: string): Promise<any> => {
  try {
    const response = await apiClient.request({
      method: 'GET',
      endPoint: `${GET_EMBEDDABLE_API_PATH}/${embeddableVoiceId}`,
    });

    return response;
  } catch (err) {
    console.log('Error:: Failed to fetch embeddable config', err);
    throw err;
  }
};
