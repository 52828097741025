import { useEffect, useState } from 'react';
import { getDateFormatFromPhoneNumber } from '@/utils/helper';
import { LocalStorageKeys } from 'src/enums/ELocalStorage';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { getLoggedInUser } from '@/store/account/selector';

export const useDateFormatFromCountry = () => {
  const loggedInUser = useAppSelector(getLoggedInUser);

  const [dateFormat, setDateFormat] = useState<string>(
    localStorage.getItem(LocalStorageKeys.DATE_FORMAT) || 'DD/MM/YYYY'
  );

  useEffect(() => {
    let format = localStorage.getItem(LocalStorageKeys.DATE_FORMAT) || 'DD/MM/YYYY';
    if (loggedInUser.provisionedNumber) {
      format = getDateFormatFromPhoneNumber(loggedInUser.provisionedNumber);
    } else if (loggedInUser.testing_number) {
      format = getDateFormatFromPhoneNumber(loggedInUser.testing_number);
    }
    setDateFormat(format);
    localStorage.setItem(LocalStorageKeys.DATE_FORMAT, format);
  }, [loggedInUser.provisionedNumber, loggedInUser.testing_number]);

  return { dateFormat };
};
