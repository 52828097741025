import { useState } from 'react';
import { Alert, Form, notification, Spin } from 'antd';
import { LabelWithDescription } from '@/components/LabelWithdescription';
import { OverViewCardForm } from '../OverviewCardForm';
import { RefetchOptions, QueryObserverResult, useMutation } from '@tanstack/react-query';
import { updateUser } from '@/api/user';
import { LoadingOutlined } from '@ant-design/icons';
import { useAppDispatch } from '@/store/hooks/useAppDispatch';
import { closeModal } from '@/store/modal/slice';
import { User } from '@/types/TAccounts';
import { LanguageGreet } from './GreetingPhraseInfo';
// @ts-ignore
import TextEditor from '../../../../../v1/components/TextEditor/TextEditor';
import TextArea from 'antd/es/input/TextArea';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { getSelectedCampaign } from '@/store/novi/user/selector';
import { updateCampaignVariables } from '@/api/novi/CamapaignPortalApis';
import CopyableText from '@/components/LabelWithdescription/CopyableText';

export const GreetingPhraseForm: React.FC<{
  system_phrase?: string;
  languageGreets: LanguageGreet[];
  refetch: (options?: RefetchOptions) => Promise<QueryObserverResult<User, Error>>;
  isNoviUser?: boolean;
}> = ({ system_phrase, refetch, languageGreets, isNoviUser }) => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm<{ system_phrase: string }>();
  const [isFieldTouchReset, setIsFieldTouchReset] = useState<boolean>(true);

  let selectedCampaign: any;

  if (isNoviUser) {
    selectedCampaign = useAppSelector(getSelectedCampaign);
  }

  const [variables, setVariables] = useState<any[]>(
    selectedCampaign?.variables.length ? JSON.parse(selectedCampaign?.variables) : []
  );
  const mutation = useMutation({
    mutationKey: ['update-client'],
    mutationFn: updateUser,
    onSuccess: () => {
      refetch();
      notification.success({
        key: 'form-success',
        message: 'Greeting Phrase has been saved successfully!',
        duration: 3,
      });
      setIsFieldTouchReset(false);
      dispatch(closeModal());
    },
  });

  const handleSubmit = (value: any) => {
    const languageGreetsObject = value?.languageGreets
      ? value.languageGreets.reduce(
          (acc: any, item: any) => {
            acc[item.language] = item.greeting;
            return acc;
          },
          {} as Record<string, string>
        )
      : {};

    mutation.mutate({
      system_phrase: value.system_phrase,
      language_greetings: JSON.stringify(languageGreetsObject),
    });
  };

  const { mutate: updateVariables } = useMutation({
    mutationKey: ['update-variables'],
    mutationFn: () => updateCampaignVariables(selectedCampaign?.number, variables),
    onSuccess: () => {
      refetch();
      notification.success({
        key: 'form-success',
        message: 'Variables have been saved successfully!',
        duration: 3,
      });
      setIsFieldTouchReset(false);
    },
  });

  const handleVariableChange = (variables: any) => {
    console.log(variables);
    setVariables(variables);
    updateVariables();
  };

  const FormItems = () => (
    <>
      <Form.Item
        label={
          <div>
            <LabelWithDescription
              customClassName='mt-2 w-full '
              label={
                <div className='flex items-center gap-1'>
                  Greeting Phrase
                  <CopyableText text={system_phrase || ''} hideText />
                </div>
              }
              description='This is the opening line your receptionist will use when answering the phone.'
            />
          </div>
        }
        name='system_phrase'
        className='!mb-0'
      >
        {isNoviUser ? (
          // @ts-ignore
          <TextEditor
            placeholder='Please enter your greeting phrase'
            vvariables={variables}
            // @ts-ignore
            onChange={(_, variables) => {
              if (variables) {
                handleVariableChange(variables);
              }
            }}
          />
        ) : (
          <TextArea rows={8} placeholder='Please enter your greeting phrase' />
        )}
      </Form.Item>
      <Form.List name='languageGreets'>
        {fields => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <Form.Item
                key={key}
                label={
                  <div>
                    <LabelWithDescription
                      customClassName='mt-2 w-full'
                      label={
                        <div className='flex items-center gap-1'>
                          {'Greeting Phrase for ' + (languageGreets[name]?.language || 'Unknown')}
                          <CopyableText text={languageGreets[name]?.greeting || ''} hideText />
                        </div>
                      }
                      description='This is the opening line your receptionist will use when answering the phone.'
                    />
                  </div>
                }
                name={[name, 'greeting']}
                className='!mb-0'
              >
                <TextArea
                  {...restField}
                  rows={3}
                  placeholder='Please enter your greeting phrase for the selected language'
                />
              </Form.Item>
            ))}
          </>
        )}
      </Form.List>
      <Alert
        className='mt-4'
        type='info'
        closable={false}
        message={
          <>
            <b>Please Note:</b>
            <p className='text-justify'>
              Your greeting phrase should be short and concise, like "Welcome to [business_name], how may I help you?".
              If it is too long, people will think the AI is just a voicemail and hang up.
            </p>
          </>
        }
      />
    </>
  );

  return (
    <div>
      <Spin spinning={mutation.isPending} indicator={<LoadingOutlined style={{ fontSize: '48px' }} spin />}>
        <OverViewCardForm
          form={form}
          initialValues={{
            system_phrase,
            languageGreets,
          }}
          formItems={<FormItems />}
          handleSubmit={handleSubmit}
          isFieldTouchReset={isFieldTouchReset}
        />
      </Spin>
    </div>
  );
};
