import { TAnalytics, TWeeklyCallLogs } from '@/types/TAnalytics';
import { Column } from '@ant-design/plots';
import moment from 'moment';
import { useDateFormatFromCountry } from 'src/common/hooks/useDateFormatFromCountry';

export const WeeklyCallLogs = ({ analyticsData }: TAnalytics) => {
  const { dateFormat } = useDateFormatFromCountry();

  const config = {
    data: analyticsData,
    xField: (row: TWeeklyCallLogs) => moment(row.date).format(`ddd ${dateFormat}`),
    yField: 'value',
    style: {
      radiusTopLeft: 10,
      radiusTopRight: 10,
      fill: 'linear-gradient(90deg, rgba(110, 121, 214, 1) 0%, rgba(51, 51, 153, 1) 100%)',
    },
    tooltip: {
      name: 'Total Calls',
      channel: 'y',
    },
  };

  return <Column {...config} />;
};
