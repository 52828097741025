import React, { useEffect, useState } from 'react';
import { notification } from 'antd';
import {
  Phone,
  Mic,
  Palette,
  Layout,
  Type,
  Code,
  Save,
  Link2,
  Link,
  Image,
  Ban,
  AlignHorizontalJustifyStart,
  AlignHorizontalJustifyCenter,
  AlignHorizontalJustifyEnd,
  Copy,
} from 'lucide-react';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Switch } from 'antd';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getEmbeddableConfig, updateEmbeddableConfig } from '@/api/embeddables';
import { useAppDispatch } from '@/store/hooks/useAppDispatch';
import { updateUser } from '@/store/account/slice';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { getLoggedInUser } from '@/store/account/selector';
import { getResellerData } from '@/store/reseller/selector';
import { RECEPTIONIST_NUMBER, RECEPTIONIST_NUMBER_US_RESELLER } from 'src/constants';
import { test } from 'src/config';

// Types

export type WidgetVariant = 'contained' | 'compact';

export type Position = {
  left?: string;
  right?: string;
  top?: string;
  bottom?: string;
};

export type IconType = 'call' | 'microphone' | 'voiceNote';

export type BackgroundType =
  | {
      type: 'solid';
      color: string;
      colorInCall: string;
    }
  | {
      type: 'gradient';
      color: string;
      colorInCall: string;
      gradientFrom: string;
      gradientTo: string;
      gradientDirection: string;
    };

export type BorderRadius = {
  topLeft: string;
  topRight: string;
  bottomLeft: string;
  bottomRight: string;
};

export type Border = {
  width: string;
  style: 'solid' | 'dashed' | 'dotted';
  color: string;
};

export type BoxShadow = {
  enabled: boolean;
  blur: string;
  spread: string;
  color: string;
  opacity: number;
};

interface Spacing {
  top: string;
  right: string;
  bottom: string;
  left: string;
}

interface Container {
  width: string;
  height: string;
  background: BackgroundType;
  borderRadius: BorderRadius;
  border?: Border;
  boxShadow: BoxShadow;
  textColor?: string;
  padding: Spacing;
  // spacing: Spacing;
  animation?: {
    type: 'none' | 'voiceNote' | 'custom';
    color: string;
    size: number;
    customUrl?: string;
  };
}

export interface WidgetConfig {
  variant: WidgetVariant;
  From: string;
  To: string;
  Called: string;
  rounded?: boolean;
  positionType: 'fixed' | 'static';
  position: Position;
  icon: {
    type: IconType;
    size: number;
    color: string;
    animation?: boolean;
    position: 'start' | 'center' | 'end';
  };
  container: Container;
  button: {
    width: string;
    height: string;
    background: BackgroundType;
    borderRadius: BorderRadius;
    border?: Border;
    boxShadow: BoxShadow;
    padding: string;
  };
  text: {
    primary: string;
    secondary: string;
    fontSize: string;
    color: string;
    feedbackSettingUp: string;
    feedbackClick: string;
    feedbackConnecting: string;
  };
  brandIcon?: {
    url: string;
    size: number;
  };
  layout: {
    direction: 'horizontal' | 'vertical';
    spacing: string;
  };
  floatingText?: {
    enabled: boolean;
    text: string;
    color: string;
    fontSize: string;
    href: string;
  };
}

type DimensionUnit = 'px' | '%' | 'auto';

interface DimensionValue {
  value: number;
  unit: DimensionUnit;
}

// Helper function to parse dimension string into DimensionValue
const parseDimension = (dimension: string): DimensionValue => {
  if (dimension === 'auto') {
    return { value: 0, unit: 'auto' };
  }
  const match = dimension.match(/^(-?\d+)(px|%)$/);
  if (!match) {
    return { value: 240, unit: 'px' }; // Default value
  }
  return {
    value: parseInt(match[1]),
    unit: match[2] as DimensionUnit,
  };
};

const SpacingControl: React.FC<{
  value: Spacing;
  onChange: (value: Spacing) => void;
  label: string;
  maxValue?: number;
}> = ({ value, onChange, label, maxValue = 50 }) => {
  // Helper to parse pixel values
  const getPxValue = (str: string) => parseInt(str.replace('px', ''));

  // Handle individual side changes
  const handleChange = (side: keyof Spacing, newValue: number) => {
    onChange({
      ...value,
      [side]: `${newValue}px`,
    });
  };

  // Link all sides together
  const [linked, setLinked] = useState(true);
  const handleLinkedChange = (newValue: number) => {
    onChange({
      top: `${newValue}px`,
      right: `${newValue}px`,
      bottom: `${newValue}px`,
      left: `${newValue}px`,
    });
  };

  return (
    <div className='space-y-4'>
      <div className='flex items-center justify-between mb-2'>
        <label className='block text-sm font-medium text-gray-700'>{label}</label>
        <button
          onClick={() => setLinked(!linked)}
          className={`p-2 rounded-md transition-colors ${
            linked ? 'bg-blue-100 text-blue-600' : 'bg-gray-100 text-gray-600'
          }`}
        >
          {linked ? <Link2 size={16} /> : <Link size={16} />}
        </button>
      </div>

      <div className='relative p-14 bg-gray-50 rounded-lg'>
        {/* Visual container representation */}
        <div className='absolute inset-0 m-14 border-2 border-dashed border-gray-300 bg-white rounded'></div>

        {/* Top spacing control */}
        <div className='absolute left-1/2 top-0 -translate-x-1/2'>
          <input
            type='number'
            value={getPxValue(value.top)}
            onChange={e => {
              const newValue = Math.min(Math.max(0, parseInt(e.target.value) || 0), maxValue);
              if (linked) {
                handleLinkedChange(newValue);
              } else {
                handleChange('top', newValue);
              }
            }}
            className='w-16 p-1 text-center border rounded'
          />
        </div>

        {/* Right spacing control */}
        <div className='absolute right-0 top-1/2 -translate-y-1/2'>
          <input
            type='number'
            value={getPxValue(value.right)}
            onChange={e => {
              const newValue = Math.min(Math.max(0, parseInt(e.target.value) || 0), maxValue);
              if (linked) {
                handleLinkedChange(newValue);
              } else {
                handleChange('right', newValue);
              }
            }}
            className='w-16 p-1 text-center border rounded'
          />
        </div>

        {/* Bottom spacing control */}
        <div className='absolute left-1/2 bottom-0 -translate-x-1/2'>
          <input
            type='number'
            value={getPxValue(value.bottom)}
            onChange={e => {
              const newValue = Math.min(Math.max(0, parseInt(e.target.value) || 0), maxValue);
              if (linked) {
                handleLinkedChange(newValue);
              } else {
                handleChange('bottom', newValue);
              }
            }}
            className='w-16 p-1 text-center border rounded'
          />
        </div>

        {/* Left spacing control */}
        <div className='absolute left-0 top-1/2 -translate-y-1/2'>
          <input
            type='number'
            value={getPxValue(value.left)}
            onChange={e => {
              const newValue = Math.min(Math.max(0, parseInt(e.target.value) || 0), maxValue);
              if (linked) {
                handleLinkedChange(newValue);
              } else {
                handleChange('left', newValue);
              }
            }}
            className='w-16 p-1 text-center border rounded'
          />
        </div>

        {/* Quick presets */}
        <div className='absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 space-y-2'>
          <div className='flex gap-2 justify-center'>
            {[0, 8, 16, 24, 32].map(currentValue => (
              <button
                key={currentValue}
                onClick={() => handleLinkedChange(currentValue)}
                className={`px-2 py-1 text-xs rounded ${
                  Object.values(value).every(v => getPxValue(v) === currentValue)
                    ? 'bg-blue-100 text-blue-600'
                    : 'bg-white border border-blue-100 border-solid hover:bg-gray-50'
                }`}
              >
                {currentValue}px
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

// New component for dimension control
const DimensionControl: React.FC<{
  label: string;
  value: string;
  onChange: (value: string) => void;
  allowAuto?: boolean;
  minValue?: number;
  maxValue?: number;
}> = ({ label, value, onChange, allowAuto = true, minValue = 0, maxValue = 1000 }) => {
  const parsed = parseDimension(value);

  return (
    <div className='space-y-2'>
      <label className='block text-sm font-medium text-gray-700'>{label}</label>
      <div className='grid grid-cols-5 gap-2'>
        <div className='col-span-3'>
          <input
            type='range'
            min={minValue}
            max={maxValue}
            value={parsed.unit === 'auto' ? 0 : parsed.value}
            onChange={e => {
              const newValue = parseInt(e.target.value);
              onChange(`${newValue}${parsed.unit === 'auto' ? 'px' : parsed.unit}`);
            }}
            disabled={parsed.unit === 'auto'}
            className='w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer accent-blue-600'
          />
        </div>
        <div className='col-span-1'>
          <input
            type='number'
            min={minValue}
            max={maxValue}
            value={parsed.unit === 'auto' ? '' : parsed.value}
            onChange={e => {
              const newValue = e.target.value ? parseInt(e.target.value) : 0;
              onChange(`${newValue}${parsed.unit === 'auto' ? 'px' : parsed.unit}`);
            }}
            disabled={parsed.unit === 'auto'}
            className='w-full px-2 py-1 border rounded text-sm'
          />
        </div>
        <div className='col-span-1'>
          <select
            value={parsed.unit}
            onChange={e => {
              const newUnit = e.target.value as DimensionUnit;
              if (newUnit === 'auto') {
                onChange('auto');
              } else {
                onChange(`${parsed.unit === 'auto' ? 100 : parsed.value}${newUnit}`);
              }
            }}
            className='w-full px-2 py-1 border rounded text-sm'
          >
            <option value='px'>px</option>
            <option value='%'>%</option>
            {allowAuto && <option value='auto'>auto</option>}
          </select>
        </div>
      </div>
    </div>
  );
};

// Add positionType to the existing WidgetConfig interface
export type PositionType = 'fixed' | 'static';

const IconControl: React.FC<{
  value: WidgetConfig['icon'];
  onChange: (value: WidgetConfig['icon']) => void;
}> = ({ value, onChange }) => {
  return (
    <div className='space-y-6'>
      <div>
        <label className='block text-sm font-medium text-gray-700 mb-2'>Icon Type</label>
        <div className='grid grid-cols-3 gap-4'>
          <button
            onClick={() => onChange({ ...value, type: 'call' as IconType })}
            className={`p-4 flex justify-center items-center rounded-lg border-2 transition-colors ${
              value.type === 'call' ? 'border-blue-600 bg-blue-50' : 'border-gray-200 hover:border-gray-300'
            }`}
          >
            <Phone size={24} className='me-1' />
            Phone
          </button>
          <button
            onClick={() => onChange({ ...value, type: 'microphone' as IconType })}
            className={`p-4 flex justify-center items-center rounded-lg border-2 transition-colors ${
              value.type === 'microphone' ? 'border-blue-600 bg-blue-50' : 'border-gray-200 hover:border-gray-300'
            }`}
          >
            <Mic size={24} className='me-1' /> <span>Microphone</span>
          </button>
          <button
            onClick={() => onChange({ ...value, type: 'voiceNote' as IconType })}
            className={`p-4 flex justify-center items-center rounded-lg border-2 transition-colors ${
              value.type === 'voiceNote' ? 'border-blue-600 bg-blue-50' : 'border-gray-200 hover:border-gray-300'
            }`}
          >
            <VoiceAnimation size={24} className='me-1' color='#000' /> Voice Note
          </button>
        </div>
      </div>

      <DimensionControl
        label='Icon Size'
        value={`${value.size}px`}
        onChange={newSize => {
          const parsed = parseDimension(newSize);
          onChange({ ...value, size: parsed.value });
        }}
        allowAuto={false}
        minValue={12}
        maxValue={100}
      />

      <div>
        <label className='block text-sm font-medium text-gray-700 mb-2'>Icon Position</label>
        <div className='grid grid-cols-3 gap-4'>
          <button
            onClick={() => onChange({ ...value, position: 'start' })}
            className={`p-4 flex justify-center items-center rounded-lg border-2 transition-colors ${
              value.position === 'start' ? 'border-blue-600 bg-blue-50' : 'border-gray-200 hover:border-gray-300'
            }`}
          >
            <AlignHorizontalJustifyStart size={24} className='me-1' />
            Start
          </button>
          <button
            onClick={() => onChange({ ...value, position: 'center' })}
            className={`p-4 flex justify-center items-center rounded-lg border-2 transition-colors ${
              value.position === 'center' ? 'border-blue-600 bg-blue-50' : 'border-gray-200 hover:border-gray-300'
            }`}
          >
            <AlignHorizontalJustifyCenter size={24} className='me-1' /> <span>Center</span>
          </button>
          <button
            onClick={() => onChange({ ...value, position: 'end' })}
            className={`p-4 flex justify-center items-center rounded-lg border-2 transition-colors ${
              value.position === 'end' ? 'border-blue-600 bg-blue-50' : 'border-gray-200 hover:border-gray-300'
            }`}
          >
            <AlignHorizontalJustifyEnd size={24} className='me-1' /> End
          </button>
        </div>
      </div>

      <div>
        <label className='block text-sm font-medium text-gray-700 mb-2'>Icon Color</label>
        <input
          type='color'
          value={value.color}
          onChange={e => onChange({ ...value, color: e.target.value })}
          className='w-full h-10 rounded-md cursor-pointer'
        />
      </div>
    </div>
  );
};

const PositionSelector: React.FC<{
  value: PositionType;
  onChange: (positionType: PositionType) => void;
}> = ({ value, onChange }) => {
  return (
    <div className='grid grid-cols-2 gap-4'>
      <button
        onClick={() => onChange('fixed')}
        className={`p-6 rounded-xl border-2 transition-all ${
          value === 'fixed'
            ? 'border-blue-600 bg-blue-50 ring-2 ring-blue-200'
            : 'border-gray-200 hover:border-gray-300'
        }`}
      >
        <div className='flex items-center justify-center'>
          {/* Fixed position illustration */}
          <div className='w-48 h-32 bg-white shadow-sm rounded-lg relative'>
            <div className='absolute bottom-4 right-4 w-16 h-16 bg-gray-300 rounded-lg flex items-center justify-center'>
              <div className='w-8 h-8 bg-gray-400 rounded' />
            </div>
          </div>
        </div>
        <div className='mt-4 text-left'>
          <h4 className='font-medium text-gray-900'>Fixed Position</h4>
          <p className='text-sm text-gray-500 mt-1'>Widget stays in place while scrolling</p>
        </div>
      </button>

      <button
        onClick={() => onChange('static')}
        className={`p-6 rounded-xl border-2 transition-all ${
          value === 'static'
            ? 'border-blue-600 bg-blue-50 ring-2 ring-blue-200'
            : 'border-gray-200 hover:border-gray-300'
        }`}
      >
        <div className='flex items-center justify-center'>
          {/* Static position illustration */}
          <div className='w-48 h-32 bg-white shadow-sm rounded-lg flex items-center justify-center'>
            <div className='w-16 h-16 bg-gray-300 rounded-lg flex items-center justify-center'>
              <div className='w-8 h-8 bg-gray-400 rounded' />
            </div>
          </div>
        </div>
        <div className='mt-4 text-left'>
          <h4 className='font-medium text-gray-900'>Static Position</h4>
          <p className='text-sm text-gray-500 mt-1'>Widget scrolls with the page content</p>
        </div>
      </button>
    </div>
  );
};

const VariantSelector: React.FC<{
  value: WidgetVariant;
  onChange: (variant: WidgetVariant) => void;
}> = ({ value, onChange }) => {
  return (
    <div className='grid grid-cols-2 gap-4'>
      <button
        onClick={() => onChange('contained')}
        className={`p-6 rounded-xl border-2 transition-all ${
          value === 'contained'
            ? 'border-blue-600 bg-blue-50 ring-2 ring-blue-200'
            : 'border-gray-200 hover:border-gray-300'
        }`}
      >
        <div className='h-20 flex items-center justify-center'>
          {/* Skeleton that matches the actual widget UI layout */}
          <div className='w-56 bg-white rounded-xl p-4 shadow-sm'>
            <div className='space-y-3'>
              {/* "Need help?" text skeleton */}
              <div className='h-4 w-24 bg-gray-200 rounded' />
              {/* Button skeleton */}
              <div className='h-9 bg-gray-300 rounded-lg flex items-center justify-center gap-2 px-4'>
                <div className='w-4 h-4 rounded-full bg-gray-400' />
                <div className='w-20 h-3 bg-gray-400 rounded' />
              </div>
            </div>
          </div>
        </div>
        <div className='mt-4 text-left'>
          <h4 className='font-medium text-gray-900'>Contained</h4>
          <p className='text-sm text-gray-500 mt-1'>Complete widget with header text and button</p>
        </div>
      </button>

      <button
        onClick={() => onChange('compact')}
        className={`p-6 rounded-xl border-2 transition-all ${
          value === 'compact'
            ? 'border-blue-600 bg-blue-50 ring-2 ring-blue-200'
            : 'border-gray-200 hover:border-gray-300'
        }`}
      >
        <div className='h-20 flex items-center justify-center'>
          {/* Just the button skeleton for compact mode */}
          <div className='h-9 bg-gray-300 rounded-lg flex items-center justify-center gap-2 px-4'>
            <div className='w-4 h-4 rounded-full bg-gray-400' />
            <div className='w-20 h-3 bg-gray-400 rounded' />
          </div>
        </div>
        <div className='mt-4 text-left'>
          <h4 className='font-medium text-gray-900'>Compact</h4>
          <p className='text-sm text-gray-500 mt-1'>Simple button with icon and text</p>
        </div>
      </button>
    </div>
  );
};

// Helper Components
// Replace the existing VoiceAnimation component with this SVG version
const VoiceAnimation = ({ className, size, color }: { className?: string; size: number | string; color: string }) => (
  <div className={'flex items-center' + className ? ' ' + className : ''} style={{ color: color }}>
    <svg
      xmlns='http://www.w3.org/2000/svg'
      style={{ height: size, width: size }}
      width={size}
      height={size}
      viewBox='0 0 100 100'
      fill='currentColor'
    >
      <rect x='15' y='30' width='10' height='40'>
        <animate attributeName='height' values='40;70;40' dur='1s' repeatCount='indefinite' />
        <animate attributeName='y' values='30;15;30' dur='1s' repeatCount='indefinite' />
      </rect>
      <rect x='35' y='30' width='10' height='40'>
        <animate attributeName='height' values='40;80;40' dur='1s' begin='0.2s' repeatCount='indefinite' />
        <animate attributeName='y' values='30;10;30' dur='1s' begin='0.2s' repeatCount='indefinite' />
      </rect>
      <rect x='55' y='30' width='10' height='40'>
        <animate attributeName='height' values='40;70;40' dur='1s' begin='0.4s' repeatCount='indefinite' />
        <animate attributeName='y' values='30;15;30' dur='1s' begin='0.4s' repeatCount='indefinite' />
      </rect>
      <rect x='75' y='30' width='10' height='40'>
        <animate attributeName='height' values='40;80;40' dur='1s' begin='0.6s' repeatCount='indefinite' />
        <animate attributeName='y' values='30;10;30' dur='1s' begin='0.6s' repeatCount='indefinite' />
      </rect>
    </svg>
  </div>
);

const IconComponent = ({ type, size, color }: { type: IconType; size: number; color: string }) => {
  switch (type) {
    case 'call':
      return <Phone size={size} color={color} />;
    case 'microphone':
      return <Mic size={size} color={color} />;
    case 'voiceNote':
      return <VoiceAnimation size={size} color={color} />;
    default:
      return null;
  }
};

// Utility functions
const getBackground = (bg: BackgroundType) => {
  if (bg.type === 'gradient' && bg.gradientFrom && bg.gradientTo) {
    return `linear-gradient(${bg.gradientDirection || '45deg'}, ${bg.gradientFrom}, ${bg.gradientTo})`;
  }
  return bg.color;
};

const getBoxShadow = (shadow: BoxShadow) => {
  if (!shadow.enabled) return 'none';
  const { blur, spread, color, opacity } = shadow;
  const rgba = `rgba(${parseInt(color.slice(1, 3), 16)}, ${parseInt(color.slice(3, 5), 16)}, ${parseInt(color.slice(5, 7), 16)}, ${opacity})`;
  return `0 0 ${blur} ${spread} ${rgba}`;
};

const getBorderRadius = (radius: BorderRadius) => ({
  borderTopLeftRadius: radius.topLeft,
  borderTopRightRadius: radius.topRight,
  borderBottomLeftRadius: radius.bottomLeft,
  borderBottomRightRadius: radius.bottomRight,
});

const PositionControl: React.FC<{
  value: Position;
  onChange: (value: Position) => void;
}> = ({ value, onChange }) => {
  // Helper function to convert position value to pixel string
  const getPositionValue = (pos: string | undefined) => {
    if (!pos) return '20';
    return pos.replace(/[^0-9|-]/g, '');
  };

  // Helper to determine which edges are active
  const horizontal = value.left ? 'left' : 'right';
  const vertical = value.top ? 'top' : 'bottom';

  return (
    <div className='space-y-6'>
      {/* Horizontal Position (Left/Right) */}
      <div className='space-y-2'>
        <label className='block text-sm font-medium text-gray-700'>Horizontal Position</label>
        <div className='grid grid-cols-2 gap-4'>
          <button
            onClick={() => {
              const newPosition = { ...value, left: value.right, right: undefined };
              onChange(newPosition);
            }}
            className={`p-4 flex justify-center items-center rounded-lg border-2 transition-colors ${
              horizontal === 'left' ? 'border-blue-600 bg-blue-50' : 'border-gray-200 hover:border-gray-300'
            }`}
          >
            <div className='w-32 h-16 bg-gray-100 rounded-lg relative'>
              <div className='absolute left-2 top-1/2 -translate-y-1/2 w-3 h-3 rounded-full bg-gray-400' />
            </div>
            <span className='ml-2'>Left</span>
          </button>

          <button
            onClick={() => {
              const newPosition = { ...value, right: value.left, left: undefined };
              onChange(newPosition);
            }}
            className={`p-4 flex justify-center items-center rounded-lg border-2 transition-colors ${
              horizontal === 'right' ? 'border-blue-600 bg-blue-50' : 'border-gray-200 hover:border-gray-300'
            }`}
          >
            <div className='w-32 h-16 bg-gray-100 rounded-lg relative'>
              <div className='absolute right-2 top-1/2 -translate-y-1/2 w-3 h-3 rounded-full bg-gray-400' />
            </div>
            <span className='ml-2'>Right</span>
          </button>
        </div>

        <div className='mt-4'>
          <DimensionControl
            label={`Distance from ${horizontal}`}
            value={`${getPositionValue(value[horizontal])}px`}
            onChange={newValue => {
              const parsed = parseDimension(newValue);
              const newPosition = {
                ...value,
                [horizontal]: `${parsed.value}px`,
              };
              console.log(newPosition);
              onChange(newPosition);
            }}
            allowAuto={false}
            minValue={-300}
            maxValue={500}
          />
        </div>
      </div>

      {/* Vertical Position (Top/Bottom) */}
      <div className='space-y-2'>
        <label className='block text-sm font-medium text-gray-700'>Vertical Position</label>
        <div className='grid grid-cols-2 gap-4'>
          <button
            onClick={() => {
              const newPosition = { ...value, top: value.bottom, bottom: undefined };
              onChange(newPosition);
            }}
            className={`p-4 flex justify-center items-center rounded-lg border-2 transition-colors ${
              vertical === 'top' ? 'border-blue-600 bg-blue-50' : 'border-gray-200 hover:border-gray-300'
            }`}
          >
            <div className='w-32 h-16 bg-gray-100 rounded-lg relative'>
              <div className='absolute top-2 left-1/2 -translate-x-1/2 w-3 h-3 rounded-full bg-gray-400' />
            </div>
            <span className='ml-2'>Top</span>
          </button>

          <button
            onClick={() => {
              const newPosition = { ...value, bottom: value.top, top: undefined };
              onChange(newPosition);
            }}
            className={`p-4 flex justify-center items-center rounded-lg border-2 transition-colors ${
              vertical === 'bottom' ? 'border-blue-600 bg-blue-50' : 'border-gray-200 hover:border-gray-300'
            }`}
          >
            <div className='w-32 h-16 bg-gray-100 rounded-lg relative'>
              <div className='absolute bottom-2 left-1/2 -translate-x-1/2 w-3 h-3 rounded-full bg-gray-400' />
            </div>
            <span className='ml-2'>Bottom</span>
          </button>
        </div>

        <div className='mt-4'>
          <DimensionControl
            label={`Distance from ${vertical}`}
            value={`${getPositionValue(value[vertical])}px`}
            onChange={newValue => {
              const parsed = parseDimension(newValue);
              const newPosition = {
                ...value,
                [vertical]: `${parsed.value}px`,
              };
              onChange(newPosition);
            }}
            allowAuto={false}
            minValue={vertical === 'bottom' ? 30 : -300}
            maxValue={500}
          />
        </div>
      </div>
    </div>
  );
};

const FloatingTextControl: React.FC<{
  value: WidgetConfig['floatingText'];
  onChange: (value: WidgetConfig['floatingText']) => void;
}> = ({ value, onChange }) => {
  if (!value) return null;

  return (
    <div className='space-y-6'>
      <div className='flex items-center justify-start gap-4'>
        <Switch
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          checked={value.enabled}
          onChange={checked => onChange({ ...value, enabled: checked })}
        />
        <label className='block text-sm font-medium text-gray-700'>Enable Floating Text</label>
      </div>

      {value.enabled && (
        <>
          <div>
            <label className='block text-sm font-medium text-gray-700 mb-2'>Text</label>
            <input
              type='text'
              value={value.text}
              onChange={e => onChange({ ...value, text: e.target.value })}
              className='w-full px-4 py-2 border rounded-lg'
              placeholder='Powered by XYZ'
            />
          </div>

          <div>
            <label className='block text-sm font-medium text-gray-700 mb-2'>Link URL</label>
            <input
              type='url'
              value={value.href}
              onChange={e => onChange({ ...value, href: e.target.value })}
              className='w-full px-4 py-2 border rounded-lg'
              placeholder='https://example.com'
            />
          </div>

          <Slider
            label='Font Size'
            value={parseInt(value.fontSize)}
            onChange={v => onChange({ ...value, fontSize: `${v}px` })}
            min={10}
            max={60}
          />

          <div>
            <label className='block text-sm font-medium text-gray-700 mb-2'>Text Color</label>
            <input
              type='color'
              value={value.color}
              onChange={e => onChange({ ...value, color: e.target.value })}
              className='w-full h-10 rounded-md cursor-pointer'
            />
          </div>
        </>
      )}
    </div>
  );
};

// Control Components
export const Slider: React.FC<{
  value: number;
  onChange: (value: number) => void;
  min: number;
  max: number;
  step?: number;
  label: string;
  unit?: string;
}> = ({ value, onChange, min, max, step = 1, label, unit = 'px' }) => (
  <div className='space-y-2'>
    <div className='flex justify-between items-center'>
      <span className='text-sm font-medium text-gray-700'>{label}</span>
      <span className='text-sm text-gray-500'>
        {value}
        {unit}
      </span>
    </div>
    <input
      type='range'
      min={min}
      max={max}
      step={step}
      value={value}
      onChange={e => onChange(Number(e.target.value))}
      className='w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer accent-blue-600'
    />
  </div>
);

export const BorderRadiusControl: React.FC<{
  value: BorderRadius;
  onChange: (value: BorderRadius) => void;
  label: string;
}> = ({ value, onChange, label }) => {
  const [isChained, setIsChained] = useState(true);

  const handleRadiusChange = (corner: keyof BorderRadius, newValue: number) => {
    if (isChained) {
      const newRadius = `${newValue}px`;
      onChange({
        topLeft: newRadius,
        topRight: newRadius,
        bottomLeft: newRadius,
        bottomRight: newRadius,
      });
    } else {
      onChange({
        ...value,
        [corner]: `${newValue}px`,
      });
    }
  };

  return (
    <div className='space-y-4'>
      <div className='flex justify-between items-center'>
        <label className='block text-sm font-medium text-gray-700'>{label}</label>
        <button
          onClick={() => setIsChained(!isChained)}
          className={`p-2 rounded-md transition-colors ${
            isChained ? 'bg-blue-100 text-blue-600' : 'bg-gray-100 text-gray-600'
          }`}
        >
          {isChained ? <Link2 size={16} /> : <Link size={16} />}
        </button>
      </div>
      <div className='grid grid-cols-2 gap-4'>
        <Slider
          label='Top Left'
          value={parseInt(value.topLeft)}
          onChange={v => handleRadiusChange('topLeft', v)}
          min={0}
          max={100}
        />
        <Slider
          label='Top Right'
          value={parseInt(value.topRight)}
          onChange={v => handleRadiusChange('topRight', v)}
          min={0}
          max={100}
        />
        <Slider
          label='Bottom Left'
          value={parseInt(value.bottomLeft)}
          onChange={v => handleRadiusChange('bottomLeft', v)}
          min={0}
          max={100}
        />
        <Slider
          label='Bottom Right'
          value={parseInt(value.bottomRight)}
          onChange={v => handleRadiusChange('bottomRight', v)}
          min={0}
          max={100}
        />
      </div>
    </div>
  );
};

const Section: React.FC<{
  title: string;
  children: React.ReactNode;
  isActive?: boolean;
  onClick?: () => void;
}> = ({ title, children, isActive, onClick }) => (
  <div
    className={`mb-8 bg-white p-6 rounded-lg shadow-sm transition-all cursor-pointer
      ${isActive ? 'ring-2 ring-blue-500 shadow-md' : 'hover:shadow-md'}`}
    onClick={onClick}
  >
    <h3 className='text-lg font-semibold mb-4'>{title}</h3>
    <div className='space-y-6'>{children}</div>
  </div>
);

const PresetDesigns: React.FC<{
  presetDesigns: Record<string, WidgetConfig>;
  onSelect: (config: WidgetConfig) => void;
}> = ({ presetDesigns, onSelect }) => {
  // Type guard to check if background is gradient type
  const isGradientBackground = (
    bg: BackgroundType
  ): bg is BackgroundType & {
    type: 'gradient';
    gradientFrom: string;
    gradientTo: string;
    gradientDirection: string;
  } => {
    return (
      bg.type === 'gradient' &&
      typeof bg.gradientFrom === 'string' &&
      typeof bg.gradientTo === 'string' &&
      typeof bg.gradientDirection === 'string'
    );
  };

  // Updated getBackground function with proper type checking
  const getBackground = (bg: BackgroundType): string => {
    if (isGradientBackground(bg)) {
      return `linear-gradient(${bg.gradientDirection}, ${bg.gradientFrom}, ${bg.gradientTo})`;
    }
    return bg.color;
  };

  return (
    <div className='grid grid-cols-2 gap-4'>
      {Object.entries(presetDesigns).map(([key, design]) => (
        <button
          key={key}
          onClick={() => onSelect(design as WidgetConfig)}
          className='p-4 rounded-lg border-2 border-gray-200 hover:border-blue-500 transition-all'
        >
          <div className='text-sm font-medium mb-2 capitalize'>{key}</div>
          <div
            className='h-20 rounded-md relative'
            style={{
              background: getBackground(design.container.background as BackgroundType),
            }}
          >
            <div
              className='absolute bottom-2 right-2 px-3 py-1 rounded-md text-xs'
              style={{
                background: getBackground(design.button.background as BackgroundType),
                color: design.text.color,
              }}
            >
              Preview
            </div>
          </div>
        </button>
      ))}
    </div>
  );
};

const BoxShadowControl: React.FC<{
  value: BoxShadow;
  onChange: (value: BoxShadow) => void;
  label: string;
}> = ({ value, onChange, label }) => (
  <div className='space-y-4'>
    <div className='flex gap-4 items-center justify-start'>
      <label className='flex items-center space-x-2'>
        <Switch
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          checked={value.enabled}
          onChange={checked => onChange({ ...value, enabled: checked })}
        />
        {/* <span className='text-sm text-gray-500'>Enable Shadow</span> */}
      </label>
      <label className='block text-sm font-medium text-gray-700'>{label}</label>
    </div>

    {value.enabled && (
      <div className='grid grid-cols-2 gap-4'>
        <Slider
          label='Blur'
          value={parseInt(value.blur)}
          onChange={v => onChange({ ...value, blur: `${v}px` })}
          min={0}
          max={100}
        />
        <Slider
          label='Spread'
          value={parseInt(value.spread)}
          onChange={v => onChange({ ...value, spread: `${v}px` })}
          min={0}
          max={50}
        />
        <div>
          <label className='block text-sm font-medium text-gray-700 mb-2'>Shadow Color</label>
          <input
            type='color'
            value={value.color}
            onChange={e => onChange({ ...value, color: e.target.value })}
            className='w-full h-10 rounded-md cursor-pointer'
          />
        </div>
        <Slider
          label='Opacity'
          value={value.opacity}
          onChange={v => onChange({ ...value, opacity: v })}
          min={0}
          max={1}
          step={0.05}
          unit=''
        />
      </div>
    )}
  </div>
);

const BorderControl: React.FC<{
  value: Border;
  onChange: (value: Border) => void;
  label: string;
}> = ({ value, onChange, label }) => (
  <div className='space-y-4'>
    <label className='block text-sm font-medium text-gray-700'>{label}</label>
    <div className='grid grid-cols-2 gap-4'>
      <Slider
        label='Width'
        value={parseInt(value.width)}
        onChange={v => onChange({ ...value, width: `${v}px` })}
        min={0}
        max={10}
      />
      <div>
        <label className='block text-sm font-medium text-gray-700 mb-2'>Style</label>
        <select
          value={value.style}
          onChange={e => onChange({ ...value, style: e.target.value as Border['style'] })}
          className='w-full px-3 py-2 border rounded-md'
        >
          <option value='solid'>Solid</option>
          <option value='dashed'>Dashed</option>
          <option value='dotted'>Dotted</option>
        </select>
      </div>
      <div className='col-span-2'>
        <label className='block text-sm font-medium text-gray-700 mb-2'>Border Color</label>
        <input
          type='color'
          value={value.color}
          onChange={e => onChange({ ...value, color: e.target.value })}
          className='w-full h-10 rounded-md cursor-pointer'
        />
      </div>
    </div>
  </div>
);

const BackgroundControl: React.FC<{
  value: BackgroundType;
  onChange: (value: BackgroundType) => void;
  label: string;
}> = ({ value, onChange, label }) => {
  const createGradientBackground = (): BackgroundType => ({
    type: 'gradient',
    color: value.color,
    colorInCall: value.colorInCall,
    gradientFrom: '#4F46E5',
    gradientTo: '#7C3AED',
    gradientDirection: '45deg',
  });

  return (
    <div className='space-y-4'>
      <label className='block text-sm font-medium text-gray-700'>{label}</label>
      <div className='grid grid-cols-2 gap-4'>
        <button
          onClick={() => onChange({ ...value, type: 'solid' })}
          className={`p-4 rounded-lg border-2 transition-colors ${
            value.type === 'solid' ? 'border-blue-600 bg-blue-50' : 'border-gray-200 hover:border-gray-300'
          }`}
        >
          Solid Color
        </button>
        <button
          onClick={() => onChange(createGradientBackground())}
          className={`p-4 rounded-lg border-2 transition-colors ${
            value.type === 'gradient' ? 'border-blue-600 bg-blue-50' : 'border-gray-200 hover:border-gray-300'
          }`}
        >
          Gradient
        </button>
      </div>

      {value.type === 'solid' ? (
        <div className='mt-4'>
          <label className='block text-sm font-medium text-gray-700 mb-2'>Color</label>
          <input
            type='color'
            value={value.color}
            onChange={e => onChange({ ...value, color: e.target.value })}
            className='w-full h-10 rounded-md cursor-pointer'
          />
        </div>
      ) : (
        <div className='mt-4 space-y-4'>
          <div className='grid grid-cols-2 gap-4'>
            <div>
              <label className='block text-sm font-medium text-gray-700 mb-2'>Start Color</label>
              <input
                type='color'
                value={value.gradientFrom || '#000000'}
                onChange={e => onChange({ ...value, gradientFrom: e.target.value })}
                className='w-full h-10 rounded-md cursor-pointer'
              />
            </div>
            <div>
              <label className='block text-sm font-medium text-gray-700 mb-2'>End Color</label>
              <input
                type='color'
                value={value.gradientTo || '#000000'}
                onChange={e => onChange({ ...value, gradientTo: e.target.value })}
                className='w-full h-10 rounded-md cursor-pointer'
              />
            </div>
          </div>
          <div>
            <label className='block text-sm font-medium text-gray-700 mb-2'>Direction (degrees)</label>
            <input
              type='range'
              min='0'
              max='360'
              value={parseInt(value.gradientDirection || '45')}
              onChange={e => onChange({ ...value, gradientDirection: `${e.target.value}deg` })}
              className='w-full'
            />
          </div>
        </div>
      )}

      <div className='mt-4'>
        <label className='block text-sm font-medium text-gray-700 mb-2'>Color (while in-call)</label>
        <input
          type='color'
          value={value.colorInCall}
          onChange={e => onChange({ ...value, colorInCall: e.target.value })}
          className='w-full h-10 rounded-md cursor-pointer'
        />
      </div>
    </div>
  );
};

interface AnimationControlValue {
  type: 'none' | 'voiceNote' | 'custom';
  color: string;
  size: number;
  customUrl?: string;
}

type AnimationControlOnChange = (value: AnimationControlValue) => void;

const AnimationControl: React.FC<{
  value: AnimationControlValue;
  onChange: AnimationControlOnChange;
}> = ({ value, onChange }) => {
  return (
    <div className='space-y-4'>
      <label className='block text-sm font-medium text-gray-700'>Side Animation</label>
      <div className='grid grid-cols-3 gap-4'>
        <button
          onClick={() => onChange({ ...value, type: 'none' })}
          className={`p-4 flex justify-center items-center rounded-lg border-2 transition-colors ${
            value.type === 'none' ? 'border-blue-600 bg-blue-50' : 'border-gray-200 hover:border-gray-300'
          }`}
        >
          <Ban size={24} className='me-1' />
          None
        </button>
        <button
          onClick={() => onChange({ ...value, type: 'voiceNote' })}
          className={`p-4 flex justify-center items-center rounded-lg border-2 transition-colors ${
            value.type === 'voiceNote' ? 'border-blue-600 bg-blue-50' : 'border-gray-200 hover:border-gray-300'
          }`}
        >
          <VoiceAnimation size={24} className='me-1' color='#000' /> <span>Voice Wave</span>
        </button>
        <button
          onClick={() => onChange({ ...value, type: 'custom' })}
          className={`p-4 flex justify-center items-center rounded-lg border-2 transition-colors ${
            value.type === 'custom' ? 'border-blue-600 bg-blue-50' : 'border-gray-200 hover:border-gray-300'
          }`}
        >
          <Image size={24} className='me-1' /> Custom Image
        </button>
      </div>

      {value.type === 'custom' && (
        <div className='mt-4'>
          <label className='block text-sm font-medium text-gray-700 mb-2'>Image URL</label>
          <input
            type='text'
            value={value.customUrl || ''}
            onChange={e => onChange({ ...value, type: 'custom', customUrl: e.target.value })}
            className='w-full px-4 py-2 border rounded-lg'
            placeholder='Enter image URL'
          />
          <p className='mt-2 text-sm text-gray-500'>Recommended size: 48x48 pixels. Supports GIF animations.</p>
        </div>
      )}

      {value.type !== 'none' && (
        <DimensionControl
          label='Side Animation Size'
          value={`${value.size}px`}
          onChange={newSize => {
            const parsed = parseDimension(newSize);
            onChange({ ...value, size: parsed.value });
          }}
          allowAuto={false}
          minValue={12}
          maxValue={100}
        />
      )}

      <div>
        <label className='block text-sm font-medium text-gray-700 mb-2'>Side Animation Color</label>
        <input
          type='color'
          value={value.color}
          onChange={e => onChange({ ...value, type: value.type, color: e.target.value })}
          className='w-full h-10 rounded-md cursor-pointer'
        />
      </div>
    </div>
  );
};

const ContainerStyleSection: React.FC<{
  config: WidgetConfig;
  updateConfig: (path: string, value: any) => void;
  setActiveSection: (section: string | null) => void;
  activeSection: string | null;
}> = ({ config, updateConfig, setActiveSection, activeSection }) => {
  return (
    <Section
      title='Container Style'
      isActive={activeSection === 'container'}
      onClick={() => setActiveSection('container')}
    >
      <div className='space-y-6'>
        {/* Dimensions */}
        <DimensionControl
          label='Width'
          value={config.container.width}
          onChange={value => updateConfig('container.width', value)}
          minValue={0}
          maxValue={800}
        />
        <DimensionControl
          label='Height'
          value={config.container.height}
          onChange={value => updateConfig('container.height', value)}
          minValue={0}
          maxValue={600}
        />

        {/* Spacing Controls */}
        <SpacingControl
          label='Container Padding'
          value={config.container.padding}
          onChange={value => updateConfig('container.padding', value)}
          maxValue={50}
        />

        {/* <SpacingControl
          label='Element Spacing'
          value={config.container.spacing}
          onChange={value => updateConfig('container.spacing', value)}
          maxValue={50}
        /> */}

        {/* Animation */}
        <AnimationControl
          value={config.container.animation || { type: 'none', size: 26, color: '#000' }}
          onChange={value => updateConfig('container.animation', value)}
        />

        {/* Background */}
        <BackgroundControl
          label='Container Background'
          value={config.container.background}
          onChange={value => updateConfig('container.background', value)}
        />

        {/* Border Radius */}
        <BorderRadiusControl
          label='Container Corners'
          value={config.container.borderRadius}
          onChange={value => updateConfig('container.borderRadius', value)}
        />

        {/* Box Shadow */}
        <BoxShadowControl
          label='Container Shadow'
          value={config.container.boxShadow}
          onChange={value => updateConfig('container.boxShadow', value)}
        />

        {/* Border */}
        <BorderControl
          label='Container Border'
          value={config.container.border || { width: '0px', style: 'solid', color: '#000000' }}
          onChange={value => updateConfig('container.border', value)}
        />
      </div>
    </Section>
  );
};

// Main widget preview component
const WidgetPreview: React.FC<{ config: WidgetConfig }> = ({ config }) => {
  // Create a wrapper div for positioning context
  const WrapperComponent: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const wrapperStyle = {
      position: config.positionType === 'fixed' ? 'fixed' : 'relative',
      ...(config.positionType === 'fixed' ? config.position : {}),
    } as const;

    return (
      <div style={wrapperStyle} className='min-w-[220px] flex justify-center items-center'>
        {children}
      </div>
    );
  };

  const floatingTextStyle = {
    position: 'absolute',
    width: '100%',
    textAlign: 'center',
    left: '50%',
    bottom: '-24px', // Adjust based on font size
    transform: 'translateX(-50%)',
    color: config.floatingText?.color || '#6B7280',
    fontSize: config.floatingText?.fontSize || '11px',
    textDecoration: 'none',
    transition: 'opacity 0.2s ease-in-out',
    opacity: 0.8,
    ':hover': {
      opacity: 1,
    },
  } as const;

  const buttonStyle = {
    background: getBackground(config.button.background),
    ...getBorderRadius(config.button.borderRadius),
    border: config.button.border
      ? `${config.button.border.width} ${config.button.border.style} ${config.button.border.color}`
      : undefined,
    width: config.button.width,
    height: config.button.height,
    boxShadow: getBoxShadow(config.button.boxShadow),
    padding: config.button.padding,
    display: 'flex',
    alignItems: 'center',
    // justifyContent: 'center',
    gap: '8px',
    transition: 'all 0.2s ease',
    cursor: 'pointer',
  } as const;
  const renderAnimation = () => {
    if (!config.container.animation || config.container.animation.type === 'none') {
      return null;
    }

    if (config.container.animation.type === 'voiceNote') {
      return (
        <div>
          <VoiceAnimation
            size={config.container.animation.size}
            color={config.container.animation.color || '#000000'}
          />
        </div>
      );
    }

    if (config.container.animation.type === 'custom' && config.container.animation.customUrl) {
      return (
        <img
          src={config.container.animation.customUrl}
          alt='Custom animation'
          className='h-5 w-5 object-contain'
          style={{ width: config.container.animation.size, height: config.container.animation.size }}
        />
      );
    }

    return null;
  };

  const renderButton = () => {
    const iconElement = <IconComponent type={config.icon.type} size={config.icon.size} color={config.icon.color} />;

    const textElement = config.text.secondary && (
      <span
        style={{
          color: config.text.color,
          fontSize: config.text.fontSize,
          fontWeight: 500,
        }}
      >
        {config.text.secondary}
      </span>
    );

    const content = () => {
      switch (config.icon.position) {
        case 'start':
          return (
            <>
              {iconElement}
              {textElement}
            </>
          );
        case 'center':
          return (
            <>
              {textElement}
              {iconElement}
              {textElement && <div style={{ width: config.icon.size }} />} {/* Spacer for centering */}
            </>
          );
        case 'end':
          return (
            <>
              {textElement}
              {iconElement}
            </>
          );
        default:
          return (
            <>
              {iconElement}
              {textElement}
            </>
          );
      }
    };

    return (
      <button
        className='hover:scale-105 transition-transform'
        style={{
          ...buttonStyle,
          justifyContent: config.icon.position === 'center' ? 'center' : 'flex-start',
        }}
      >
        {content()}
      </button>
    );
  };

  if (config.variant === 'compact') {
    const positionStyle: React.CSSProperties = {
      position: config.positionType === 'fixed' ? 'fixed' : 'relative',
      ...(config.positionType === 'fixed' ? config.position : {}),
    } as const;

    return (
      <div className='min-w-[220px] flex justify-center items-center' style={positionStyle}>
        {renderButton()}
        {config.floatingText?.enabled && (
          <a
            href={config.floatingText.href}
            target='_blank'
            rel='noopener noreferrer'
            className='hover:opacity-100 transition-opacity'
            style={floatingTextStyle}
          >
            {config.floatingText.text}
          </a>
        )}
      </div>
    );
  }

  const containerStyle = {
    // position: config.positionType === 'fixed' ? 'fixed' : 'static',
    // ...(config.positionType === 'fixed' ? config.position : {}),
    background: getBackground(config.container.background),
    width: config.container.width,
    height: config.container.height,
    ...getBorderRadius(config.container.borderRadius),
    border: config.container.border
      ? `${config.container.border.width} ${config.container.border.style} ${config.container.border.color}`
      : undefined,
    boxShadow: getBoxShadow(config.container.boxShadow),
    paddingLeft: config.container.padding.left,
    paddingTop: config.container.padding.top,
    paddingRight: config.container.padding.right,
    paddingBottom: config.container.padding.bottom,
  } as const;

  return (
    <WrapperComponent>
      <div style={containerStyle} className='transition-all'>
        <div className='flex items-center gap-4 h-full'>
          {/* Left side - Animation */}
          {renderAnimation()}

          {/* Right side - Content */}
          <div className='flex-1 flex flex-col gap-4'>
            {config.text.primary && (
              <span
                style={{
                  color: config.container.textColor || '#000000',
                  fontSize: config.text.fontSize,
                  fontWeight: 500,
                }}
              >
                {config.text.primary}
              </span>
            )}

            {renderButton()}
          </div>

          {config.floatingText?.enabled && (
            <a
              href={config.floatingText.href}
              target='_blank'
              rel='noopener noreferrer'
              className='hover:opacity-100 transition-opacity'
              style={floatingTextStyle}
            >
              {config.floatingText.text}
            </a>
          )}
        </div>
      </div>
    </WrapperComponent>
  );
};

const EmbedCodeSection = ({ embeddableVoiceId }: { embeddableVoiceId: string }) => {
  const [copied, setCopied] = useState(false);

  const embedCode = `<embeddable-voice id="${embeddableVoiceId}"></embeddable-voice>
<script src="https://${test ? 'testing-v2-1.embeddable-widgets.pages.dev' : 'cdn.247aireceptionist.com'}/call-embeddable-widget-v2.js"></script>`;

  const copyToClipboard = () => {
    navigator.clipboard.writeText(embedCode);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <div className='mt-8 p-6 bg-gray-50 rounded-lg border border-gray-200'>
      <div className='flex items-center justify-between mb-2'>
        <h3 className='text-sm font-medium text-gray-900'>Widget Embed Code</h3>
        <button
          onClick={copyToClipboard}
          className='inline-flex items-center px-3 py-1 text-sm font-medium text-gray-700 bg-white rounded-md border border-gray-300 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
        >
          <Copy className='w-4 h-4 mr-2' />
          {copied ? 'Copied!' : 'Copy code'}
        </button>
      </div>
      <div className='mt-2 p-3 bg-gray-900 rounded-md'>
        <code className='text-sm text-gray-100 font-mono break-all'>{embedCode}</code>
      </div>
      <p className='mt-2 text-xs text-gray-500'>
        Paste this code into your website where you want the widget to appear
      </p>
    </div>
  );
};

// Main customizer component
const WidgetCustomizer: React.FC = () => {
  const { reseller_name } = useAppSelector(getResellerData);
  const { resellerClient, provisionedNumber, testing_number } = useAppSelector(getLoggedInUser);
  let From, To, Called;
  if (provisionedNumber) {
    From = provisionedNumber;
    To = provisionedNumber;
    Called = provisionedNumber;
  } else if (testing_number) {
    const receptionistNumber = resellerClient ? RECEPTIONIST_NUMBER_US_RESELLER : RECEPTIONIST_NUMBER;
    From = testing_number;
    To = receptionistNumber;
    Called = receptionistNumber;
  } else {
    From = '';
    To = '';
    Called = '';
  }
  // Default configuration
  const defaultConfig: WidgetConfig = {
    variant: 'contained',
    positionType: 'fixed',
    From,
    To,
    Called,
    position: {
      bottom: '40px',
      right: '20px',
    },
    icon: {
      type: 'call',
      size: 20,
      color: '#FFFFFF',
      animation: false,
      position: 'start',
    },
    container: {
      width: '240px',
      height: 'auto',
      textColor: '#000000',
      background: {
        type: 'solid',
        color: '#FFFFFF',
        colorInCall: '#dc2626',
      },
      borderRadius: {
        topLeft: '16px',
        topRight: '16px',
        bottomLeft: '16px',
        bottomRight: '16px',
      },
      border: {
        width: '1px',
        style: 'solid',
        color: '#E5E7EB',
      },
      boxShadow: {
        enabled: true,
        blur: '20px',
        spread: '0px',
        color: '#000000',
        opacity: 0.1,
      },
      padding: { bottom: '16px', left: '16px', right: '16px', top: '16px' },
      // spacing: { bottom: '12px', left: '12px', right: '12px', top: '12px' },
      animation: {
        type: 'none',
        size: 20,
        color: '#000',
      },
    },
    button: {
      width: 'auto',
      height: 'auto',
      background: {
        type: 'solid',
        color: '#000000',
        colorInCall: '#dc2626',
      },
      borderRadius: {
        topLeft: '12px',
        topRight: '12px',
        bottomLeft: '12px',
        bottomRight: '12px',
      },
      boxShadow: {
        enabled: true,
        blur: '8px',
        spread: '0px',
        color: '#000000',
        opacity: 0.1,
      },
      padding: '12px',
    },
    text: {
      primary: 'Need help?',
      secondary: 'Start a call',
      fontSize: '15px',
      color: '#FFFFFF',
      feedbackSettingUp: 'Setting up the call',
      feedbackClick: 'Click to get support',
      feedbackConnecting: 'Connecting the line...',
    },
    layout: {
      direction: 'vertical',
      spacing: '12px',
    },
    floatingText: {
      enabled: true,
      text: `Powered by ${resellerClient ? reseller_name || '' : 'AI Frontdesk'}`,
      color: '#6B7280',
      fontSize: '12px',
      href: resellerClient ? 'https://example.com' : 'https://www.myaifrontdesk.com',
    },
  };

  // Preset designs
  const presetDesigns: Record<string, WidgetConfig> = {
    minimal: defaultConfig,
    modern: {
      ...defaultConfig,
      icon: {
        ...defaultConfig.icon,
        color: '#4F46E5',
      },
      container: {
        ...defaultConfig.container,
        background: {
          type: 'gradient',
          color: '#4F46E5',
          colorInCall: '#dc2626',
          gradientFrom: '#4F46E5',
          gradientTo: '#7C3AED',
          gradientDirection: '45deg',
        },
        textColor: '#ffffff',
        borderRadius: {
          topLeft: '16px',
          topRight: '16px',
          bottomLeft: '16px',
          bottomRight: '16px',
        },
      },
      button: {
        ...defaultConfig.button,
        background: {
          type: 'solid',
          color: '#ffffff',
          colorInCall: '#dc2626',
        },
        borderRadius: {
          topLeft: '12px',
          topRight: '12px',
          bottomLeft: '12px',
          bottomRight: '12px',
        },
      },
      text: {
        ...defaultConfig.text,
        color: '#4F46E5',
      },
    },
    playful: {
      ...defaultConfig,
      container: {
        ...defaultConfig.container,
        background: {
          type: 'solid',
          color: '#FFD700',
          colorInCall: '#dc2626',
        },
        borderRadius: {
          topLeft: '30px',
          topRight: '4px',
          bottomLeft: '4px',
          bottomRight: '30px',
        },
      },
      button: {
        ...defaultConfig.button,
        background: {
          type: 'solid',
          color: '#FF6B6B',
          colorInCall: '#dc2626',
        },
        borderRadius: {
          topLeft: '20px',
          topRight: '4px',
          bottomLeft: '4px',
          bottomRight: '20px',
        },
      },
    },
    glassmorphic: {
      ...defaultConfig,
      container: {
        ...defaultConfig.container,
        background: {
          type: 'solid',
          color: '#ffffff80',
          colorInCall: '#dc2626',
        },
        borderRadius: {
          topLeft: '24px',
          topRight: '24px',
          bottomLeft: '24px',
          bottomRight: '24px',
        },
        boxShadow: {
          enabled: true,
          blur: '16px',
          spread: '0px',
          color: '#ffffff',
          opacity: 0.3,
        },
      },
      button: {
        ...defaultConfig.button,
        background: {
          type: 'gradient',
          color: '#3B82F6',
          gradientFrom: '#3B82F6',
          gradientTo: '#2563EB',
          gradientDirection: '45deg',
          colorInCall: '#dc2626',
        },
      },
    },
    sleek: {
      ...defaultConfig,
      variant: 'contained',
      icon: {
        type: 'call',
        size: 20,
        color: '#FFFFFF',
        animation: false,
        position: 'start',
      },
      container: {
        width: '240px',
        height: 'auto',
        textColor: '#333333',
        background: {
          type: 'solid',
          color: '#F3F4F6',
          colorInCall: '#dc2626',
        },
        borderRadius: {
          topLeft: '12px',
          topRight: '12px',
          bottomLeft: '12px',
          bottomRight: '12px',
        },
        border: {
          width: '1px',
          style: 'solid',
          color: '#E5E7EB',
        },
        boxShadow: {
          enabled: true,
          blur: '12px',
          spread: '0px',
          color: '#000000',
          opacity: 0.08,
        },
        padding: {
          left: '16px',
          top: '16px',
          right: '16px',
          bottom: '16px',
        },
      },
      button: {
        width: 'auto',
        height: 'auto',
        background: {
          type: 'solid',
          color: '#2563EB',
          colorInCall: '#dc2626',
        },
        borderRadius: {
          topLeft: '8px',
          topRight: '8px',
          bottomLeft: '8px',
          bottomRight: '8px',
        },
        boxShadow: {
          enabled: false,
          blur: '8px',
          spread: '0px',
          color: '#000000',
          opacity: 0.1,
        },
        padding: '12px',
      },
      text: {
        ...defaultConfig.text,
        primary: "Questions? Let's chat!",
        secondary: 'Start conversation',
        fontSize: '14px',
        color: '#FFFFFF',
      },
      layout: {
        direction: 'vertical',
        spacing: '12px',
      },
    },
    neon: {
      ...defaultConfig,
      container: {
        ...defaultConfig.container,
        textColor: '#00ff00',
        background: {
          type: 'solid',
          color: '#000000',
          colorInCall: '#dc2626',
        },
        border: {
          width: '2px',
          style: 'solid',
          color: '#00ff00',
        },
        boxShadow: {
          enabled: true,
          blur: '20px',
          spread: '0px',
          color: '#00ff00',
          opacity: 0.5,
        },
      },
      button: {
        ...defaultConfig.button,
        background: {
          type: 'solid',
          color: '#00ff00',
          colorInCall: '#dc2626',
        },
        boxShadow: {
          enabled: true,
          blur: '10px',
          spread: '2px',
          color: '#00ff00',
          opacity: 0.5,
        },
      },
      text: {
        ...defaultConfig.text,
        color: '#000000',
      },
    },
    futuristic: {
      ...defaultConfig,
      container: {
        ...defaultConfig.container,
        background: {
          type: 'gradient',
          color: '#000428',
          colorInCall: '#dc2626',
          gradientFrom: '#000428',
          gradientTo: '#004e92',
          gradientDirection: '135deg',
        },
        textColor: '#FFFFFF',
        borderRadius: {
          topLeft: '20px',
          topRight: '20px',
          bottomLeft: '20px',
          bottomRight: '20px',
        },
        boxShadow: {
          enabled: true,
          blur: '30px',
          spread: '0px',
          color: '#004e92',
          opacity: 0.6,
        },
      },
      button: {
        ...defaultConfig.button,
        background: {
          type: 'gradient',
          color: '#004e92',
          colorInCall: '#dc2626',
          gradientFrom: '#004e92',
          gradientTo: '#000428',
          gradientDirection: '45deg',
        },
        borderRadius: {
          topLeft: '12px',
          topRight: '12px',
          bottomLeft: '12px',
          bottomRight: '12px',
        },
        boxShadow: {
          enabled: true,
          blur: '10px',
          spread: '0px',
          color: '#004e92',
          opacity: 0.5,
        },
      },
      text: {
        ...defaultConfig.text,
        color: '#FFFFFF',
      },
    },
    round: {
      ...defaultConfig,
      rounded: true,
      variant: 'compact',
      positionType: 'fixed',
      icon: {
        type: 'call',
        size: 30,
        color: '#FFFFFF',
        animation: false,
        position: 'center',
      },
      container: {
        width: 'auto',
        height: 'auto',
        textColor: '#000000',
        background: {
          type: 'solid',
          color: '#FFFFFF',
          colorInCall: '#dc2626',
        },
        borderRadius: {
          topLeft: '16px',
          topRight: '16px',
          bottomLeft: '16px',
          bottomRight: '16px',
        },
        border: {
          width: '1px',
          style: 'solid',
          color: '#E5E7EB',
        },
        boxShadow: {
          enabled: true,
          blur: '20px',
          spread: '0px',
          color: '#000000',
          opacity: 0.1,
        },
        padding: {
          left: '16px',
          top: '16px',
          right: '16px',
          bottom: '16px',
        },
        animation: {
          type: 'none',
          size: 20,
          color: '#000',
        },
      },
      button: {
        width: '80px',
        height: '80px',
        background: {
          type: 'solid',
          color: '#000000',
          colorInCall: '#dc2626',
        },
        borderRadius: {
          topLeft: '50px',
          topRight: '50px',
          bottomLeft: '50px',
          bottomRight: '50px',
        },
        boxShadow: {
          enabled: true,
          blur: '8px',
          spread: '0px',
          color: '#000000',
          opacity: 0.1,
        },
        padding: '12px',
      },
      text: {
        ...defaultConfig.text,
        primary: '',
        secondary: '',
        fontSize: '15px',
        color: '#FFFFFF',
      },
      layout: {
        direction: 'vertical',
        spacing: '12px',
      },
    },
  };

  const [config, setConfig] = useState<WidgetConfig>(defaultConfig);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [activeTab, setActiveTab] = useState<'presets' | 'style' | 'layout' | 'text' | 'json'>('presets');
  const [activeSection, setActiveSection] = useState<string | null>(null);
  const dispatch = useAppDispatch();
  const { embeddableVoiceId } = useAppSelector(getLoggedInUser);

  // Query for fetching saved config
  const { data: savedConfig, isLoading: isLoadingConfig } = useQuery({
    queryKey: ['embeddableConfig', embeddableVoiceId],
    queryFn: () => embeddableVoiceId && getEmbeddableConfig(embeddableVoiceId),
    enabled: !!embeddableVoiceId, // Only run query if embeddableVoiceId exists
    retry: 1,
  });

  const mutation = useMutation({
    mutationFn: updateEmbeddableConfig,
    onSuccess: (data: { message: string; embeddableVoiceId: string }) => {
      if (data.embeddableVoiceId) {
        dispatch(updateUser({ user: { embeddableVoiceId: data.embeddableVoiceId } }));
      }
      setHasUnsavedChanges(false);
      notification.success({
        message: 'Success',
        description: data.message || 'Widget configuration saved successfully.',
        placement: 'bottomRight',
      });
    },
    onError: () => {
      notification.error({
        message: 'Error',
        description: 'Failed to save widget configuration. Please try again.',
        placement: 'bottomRight',
      });
    },
  });

  // Fetch saved configuration on component mount
  useEffect(() => {
    if (savedConfig) {
      setConfig(prevState => {
        return { ...prevState, savedConfig };
      });
      setHasUnsavedChanges(false);
    }
  }, [savedConfig]);

  // Track changes by comparing with saved config
  useEffect(() => {
    if (savedConfig) {
      const configHasChanged = JSON.stringify(config) !== JSON.stringify(savedConfig);
      setHasUnsavedChanges(configHasChanged);
    } else {
      setHasUnsavedChanges(true);
    }
  }, [config, savedConfig]);

  const updateConfig = (path: string, value: any) => {
    setConfig(prev => {
      const newConfig = { ...prev };
      const pathArray = path.split('.');
      let current: any = newConfig;
      for (let i = 0; i < pathArray.length - 1; i++) {
        current[pathArray[i]] = { ...current[pathArray[i]] };
        current = current[pathArray[i]];
      }
      current[pathArray[pathArray.length - 1]] = value;
      console.log(newConfig);
      return newConfig;
    });
  };

  const handleSave = () => {
    mutation.mutate(config);
  };

  const tabs = [
    { id: 'presets', label: 'Presets', icon: <Palette size={20} /> },
    { id: 'style', label: 'Style', icon: <Layout size={20} /> },
    { id: 'text', label: 'Text', icon: <Type size={20} /> },
    { id: 'json', label: 'JSON', icon: <Code size={20} /> },
  ];

  if (isLoadingConfig) {
    return (
      <div className='min-h-screen bg-white flex items-center justify-center'>
        <div className='text-center'>
          <div className='animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600 mx-auto'></div>
          <p className='mt-4 text-gray-600'>Loading widget configuration...</p>
        </div>
      </div>
    );
  }

  return (
    <div className='min-h-screen bg-white'>
      <div className='max-w-7xl mx-auto p-6'>
        {/* Header with Save Button */}
        <div className='px-2 sticky top-0 z-10 py-4 bg-white flex justify-between items-center mb-6'>
          <h1 className='text-2xl font-semibold text-gray-900'>
            <span className='text-blue-500'>Chatwave</span> Embeddable Widget Customizer
          </h1>
          <button
            onClick={handleSave}
            disabled={mutation.isPending}
            className={`relative flex items-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors disabled:opacity-50 disabled:cursor-not-allowed ${
              hasUnsavedChanges || !embeddableVoiceId ? 'animate-pulse' : ''
            }`}
          >
            <Save size={20} className={hasUnsavedChanges || !embeddableVoiceId ? 'animate-bounce' : ''} />
            {mutation.isPending ? 'Saving...' : 'Save Changes'}
            {(hasUnsavedChanges || !embeddableVoiceId) && (
              <span className='absolute -top-1 -right-1 flex h-3 w-3'>
                <span className='animate-ping absolute inline-flex h-full w-full rounded-full bg-blue-200 opacity-75'></span>
                <span className='relative inline-flex rounded-full h-3 w-3 bg-blue-400'></span>
              </span>
            )}
          </button>
        </div>

        <div className='px-2 grid grid-cols-3 gap-8'>
          {/* Left panel */}
          <div className='col-span-2 space-y-6'>
            {/* Tabs */}
            <div className='bg-white rounded-lg p-1 flex shadow-sm'>
              {tabs.map(tab => (
                <button
                  key={tab.id}
                  onClick={() => setActiveTab(tab.id as any)}
                  className={`flex-1 px-6 py-3 rounded-md flex items-center justify-center space-x-2 transition-colors ${
                    activeTab === tab.id ? 'bg-blue-50 text-blue-600' : 'text-gray-600 hover:bg-gray-50'
                  }`}
                >
                  {tab.icon}
                  <span>{tab.label}</span>
                </button>
              ))}
            </div>

            {/* Tab content */}
            {activeTab === 'presets' && (
              <>
                <Section title='Widget Type'>
                  <VariantSelector
                    value={config.variant}
                    onChange={variant => {
                      if (config.rounded && variant === 'contained') return;
                      else
                        setConfig(prev => ({
                          ...prev,
                          variant,
                          container: {
                            ...prev.container,
                            width: variant === 'compact' ? 'auto' : '240px',
                          },
                          text: {
                            ...prev.text,
                            secondary: variant === 'compact' && !config.rounded ? 'Start a call' : prev.text.secondary,
                          },
                        }));
                    }}
                  />
                </Section>

                <Section title='Position Type'>
                  <PositionSelector
                    value={config.positionType}
                    onChange={positionType => {
                      setConfig(prev => ({
                        ...prev,
                        positionType,
                      }));
                    }}
                  />
                </Section>

                <Section title='Pre-made Designs'>
                  <PresetDesigns
                    presetDesigns={presetDesigns}
                    onSelect={e => {
                      if (e.rounded) setConfig({ ...e, variant: 'compact', positionType: config.positionType });
                      else setConfig({ ...e, variant: config.variant, positionType: config.positionType });
                    }}
                  />
                </Section>
              </>
            )}

            {activeTab === 'style' && (
              <>
                {config.positionType === 'fixed' && (
                  <Section
                    title='Position Settings'
                    isActive={activeSection === 'position'}
                    onClick={() => setActiveSection('position')}
                  >
                    <PositionControl value={config.position} onChange={value => updateConfig('position', value)} />
                  </Section>
                )}
                {config.variant === 'contained' && (
                  <ContainerStyleSection
                    config={config}
                    updateConfig={updateConfig}
                    setActiveSection={setActiveSection}
                    activeSection={activeSection}
                  />
                )}

                {/* <Section
                  title='Position Settings'
                  isActive={activeSection === 'position'}
                  onClick={() => setActiveSection('position')}
                >
                  <PositionControl value={config.position} onChange={value => updateConfig('position', value)} />
                </Section> */}

                <Section
                  title='Icon Settings'
                  isActive={activeSection === 'icon'}
                  onClick={() => setActiveSection('icon')}
                >
                  <IconControl value={config.icon} onChange={value => updateConfig('icon', value)} />
                </Section>
                <Section
                  title='Button Style'
                  isActive={activeSection === 'button'}
                  onClick={() => setActiveSection('button')}
                >
                  {' '}
                  <DimensionControl
                    label='Width'
                    value={config.button.width || '100%'}
                    onChange={value => updateConfig('button.width', value)}
                    minValue={0}
                    maxValue={800}
                  />
                  <DimensionControl
                    label='Height'
                    value={config.button.height || 'auto'}
                    onChange={value => updateConfig('button.height', value)}
                    minValue={0}
                    maxValue={800}
                  />
                  <div className='space-y-6'>
                    <BackgroundControl
                      label='Button Background'
                      value={config.button.background}
                      onChange={value => updateConfig('button.background', value)}
                    />
                    <BorderRadiusControl
                      label='Button Corners'
                      value={config.button.borderRadius}
                      onChange={value => updateConfig('button.borderRadius', value)}
                    />
                    <BoxShadowControl
                      label='Button Shadow'
                      value={config.button.boxShadow}
                      onChange={value => updateConfig('button.boxShadow', value)}
                    />
                    <BorderControl
                      label='Button Border'
                      value={config.button.border || { width: '0px', style: 'solid', color: '#000000' }}
                      onChange={value => updateConfig('button.border', value)}
                    />
                  </div>
                </Section>
              </>
            )}

            {activeTab === 'text' && (
              <Section title='Text Settings'>
                <div className='space-y-6'>
                  {config.variant === 'contained' && (
                    <>
                      <div>
                        <label className='block text-sm font-medium text-gray-700 mb-2'>Primary Text</label>
                        <input
                          type='text'
                          value={config.text.primary}
                          onChange={e => updateConfig('text.primary', e.target.value)}
                          className='w-full px-4 py-2 border rounded-lg'
                          placeholder='Need help?'
                        />
                      </div>

                      <div>
                        <label className='block text-sm font-medium text-gray-700 mb-2'>Container Text Color</label>
                        <input
                          type='color'
                          value={config.container.textColor || '#000000'}
                          onChange={e => updateConfig('container.textColor', e.target.value)}
                          className='w-full h-10 rounded-md cursor-pointer'
                        />
                      </div>
                    </>
                  )}

                  <div>
                    <label className='block text-sm font-medium text-gray-700 mb-2'>Button Text</label>
                    <input
                      type='text'
                      value={config.text.secondary}
                      onChange={e => updateConfig('text.secondary', e.target.value)}
                      className='w-full px-4 py-2 border rounded-lg'
                      placeholder='Start a call'
                    />
                  </div>

                  <div>
                    <label className='block text-sm font-medium text-gray-700 mb-2'>
                      Feedback Text while Setting Up the Widget
                    </label>
                    <input
                      type='text'
                      value={config.text.feedbackSettingUp}
                      onChange={e => updateConfig('text.feedbackSettingUp', e.target.value)}
                      className='w-full px-4 py-2 border rounded-lg'
                      placeholder='Start a call'
                    />
                  </div>

                  <div>
                    <label className='block text-sm font-medium text-gray-700 mb-2'>
                      Feedback Text for Widget Interaction Help
                    </label>
                    <input
                      type='text'
                      value={config.text.feedbackClick}
                      onChange={e => updateConfig('text.feedbackClick', e.target.value)}
                      className='w-full px-4 py-2 border rounded-lg'
                      placeholder='Start a call'
                    />
                  </div>

                  <div>
                    <label className='block text-sm font-medium text-gray-700 mb-2'>
                      Feedback Text while Connecting Call
                    </label>
                    <input
                      type='text'
                      value={config.text.feedbackConnecting}
                      onChange={e => updateConfig('text.feedbackConnecting', e.target.value)}
                      className='w-full px-4 py-2 border rounded-lg'
                      placeholder='Start a call'
                    />
                  </div>

                  <div className='space-y-4'>
                    <Slider
                      label='Font Size'
                      value={parseInt(config.text.fontSize)}
                      onChange={value => updateConfig('text.fontSize', `${value}px`)}
                      min={12}
                      max={60}
                    />

                    <div>
                      <label className='block text-sm font-medium text-gray-700 mb-2'>Text Color</label>
                      <input
                        type='color'
                        value={config.text.color}
                        onChange={e => updateConfig('text.color', e.target.value)}
                        className='w-full h-10 rounded-md cursor-pointer'
                      />
                    </div>
                  </div>

                  {resellerClient && (
                    <FloatingTextControl
                      value={config.floatingText}
                      onChange={value => updateConfig('floatingText', value)}
                    />
                  )}
                </div>
              </Section>
            )}

            {activeTab === 'json' && (
              <Section title='JSON Configuration'>
                <div className='space-y-4'>
                  <textarea
                    className='w-full h-[600px] font-mono text-sm p-4 border rounded-lg'
                    value={JSON.stringify(config, null, 2)}
                    onChange={e => {
                      try {
                        setConfig(JSON.parse(e.target.value));
                      } catch (err) {
                        // Invalid JSON, ignore
                      }
                    }}
                  />
                  <p className='text-sm text-gray-500'>
                    You can copy this JSON configuration to save your widget settings or share them with others.
                  </p>
                </div>
              </Section>
            )}
          </div>

          {/* Right panel - Preview */}

          <div>
            <div className='sticky top-24 space-y-4'>
              <WidgetPreview config={config} />

              {config.positionType === 'static' && <div className='h-10' />}

              {/* Show embed code section if we have an ID */}
              {embeddableVoiceId ? (
                <EmbedCodeSection embeddableVoiceId={embeddableVoiceId} />
              ) : (
                <div className='mt-8 p-6 bg-blue-50 rounded-lg border border-blue-100'>
                  <div className='flex items-center justify-between'>
                    <div className='flex-1'>
                      <h3 className='text-sm font-medium text-blue-900'>Save settings to get your embed code</h3>
                      <p className='mt-1 text-sm text-blue-700'>
                        Click the Save Changes button above to generate your unique embed code.
                      </p>
                    </div>
                    <div className='ml-4'>
                      <Save className='w-5 h-5 text-blue-500' />
                    </div>
                  </div>
                </div>
              )}

              {/* Mobile Save Button */}
              <button
                onClick={handleSave}
                disabled={mutation.isPending}
                className='w-full flex items-center justify-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors disabled:opacity-50 disabled:cursor-not-allowed md:hidden'
              >
                <Save size={20} />
                {mutation.isPending ? 'Saving...' : 'Save Changes'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WidgetCustomizer;
