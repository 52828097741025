import { useState, useRef } from 'react';
import VirtualList from 'rc-virtual-list';
import { Typography, Tag, Space, Button, Tooltip, Modal, List, Empty } from 'antd';
import { Phone, ChevronRight, PlayCircle, FileText, Clock, NotebookTabs } from 'lucide-react';
import { CallLog } from '@/types/TCRM';
import { TranscriptMessage } from '@/types/TCallLogs';
import { useMutation } from '@tanstack/react-query';
import { getCallRecording } from '@/api/logs';

const { Text } = Typography;

interface CallLogsViewProps {
  callLogs: CallLog[];
  loading: boolean;
  onLoadMore: () => void;
  hasMore: boolean;
}

const ITEM_HEIGHT = 64;
const EXPANDED_HEIGHT = 140;

const CallLogsView: React.FC<CallLogsViewProps> = ({ callLogs, loading, onLoadMore, hasMore }) => {
  const [selectedCall, setSelectedCall] = useState<string | null>(null);
  const [activeRecording, setActiveRecording] = useState<{ callSid: string; url: string } | null>(null);
  const [handleViewSummary, setHandleViewSummary] = useState<any | null>(null);
  const [handleViewTranscript, setHandleViewTranscript] = useState<any | null>(null);
  const [modalKey, setModalKey] = useState(0); // Add this to force modal re-renders
  const audioRef = useRef<HTMLAudioElement>(null);

  const { mutate: callRecordingMutation, isPending } = useMutation({
    mutationKey: ['getCallRecording'],
    mutationFn: getCallRecording,
    onSuccess: (data, callSid) => {
      setActiveRecording({
        callSid: callSid,
        url: data.url,
      });
      setModalKey(prev => prev + 1);
    },
  });

  const handleCloseAudioModal = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
    setActiveRecording(null);
  };

  const handleCallTransacript = (call: CallLog) => {
    let script = call.transcript
      ? (call.transcript
          .split('\n')
          .map((message: string, index: number) => {
            if (message.startsWith('assistant@')) {
              return { key: index, sender: 'Assistant', message: message.replace('assistant@', '') };
            } else if (message.startsWith('user@')) {
              return { key: index, sender: 'User', message: message.replace('user@', '') };
            }
            return null;
          })
          .filter(Boolean) as TranscriptMessage[])
      : [];

    setHandleViewTranscript(script);
  };

  const renderItem = (call: CallLog) => {
    const isSelected = selectedCall === call.callSid;

    return (
      <div
        key={call.callSid}
        className={`
          cursor-pointer hover:bg-gray-50 transition-all duration-200 border-b border-gray-100
          ${isSelected ? 'bg-blue-50' : ''}
        `}
        style={{
          height: isSelected ? EXPANDED_HEIGHT : ITEM_HEIGHT,
          transition: 'height 0.2s ease',
        }}
      >
        <div
          className='flex items-center justify-between w-full px-4 py-2'
          onClick={() => setSelectedCall(isSelected ? null : call.callSid)}
        >
          <Space size='large'>
            <div className='flex items-center gap-3'>
              <div className='flex flex-col items-center'>
                <Text strong>{call.date}</Text>
                <Text type='secondary' className='text-xs'>
                  {new Date(call.date).toLocaleDateString()}
                </Text>
              </div>
              <div
                className={`h-8 w-8 rounded-full flex items-center justify-center 
                ${call.isRecorded ? 'bg-blue-100 text-blue-600' : 'bg-gray-100 text-gray-600'}`}
              >
                <Phone className='w-4 h-4' />
              </div>
            </div>

            <Space>
              <Clock className='w-4 h-4 text-gray-400' />
              <Text>{call.duration}</Text>
            </Space>
          </Space>

          <Space>
            {call.isRecorded && (
              <Tag color='blue' className='m-0'>
                Recorded
              </Tag>
            )}
            <Tooltip title='Click to view details'>
              <ChevronRight
                className={`w-4 h-4 transition-transform duration-200
                  ${isSelected ? 'rotate-90' : ''}`}
              />
            </Tooltip>
          </Space>
        </div>

        {isSelected && (
          <div className='w-full px-4 py-3 bg-gray-50 border-t border-gray-100'>
            <Space size='large' className='w-full'>
              <Button
                type='text'
                icon={<PlayCircle className='w-4 h-4' />}
                loading={isPending && selectedCall === call.callSid}
                onClick={() => callRecordingMutation(call.callSid)}
              >
                Play Recording
              </Button>
              <Button
                type='text'
                icon={<NotebookTabs className='w-4 h-4' />}
                onClick={() => setHandleViewSummary(call.callSummary)}
              >
                Call Summary
              </Button>
              <Button type='text' icon={<FileText className='w-4 h-4' />} onClick={() => handleCallTransacript(call)}>
                View Transcript
              </Button>
            </Space>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className='flex flex-col max-w-full'>
      <div className='flex-grow'>
        <VirtualList data={callLogs} height={window.innerHeight - 280} itemHeight={ITEM_HEIGHT} itemKey='callSid'>
          {renderItem}
        </VirtualList>
      </div>

      {hasMore && (
        <div className='sticky bottom-0 w-full bg-white border-t border-gray-100 p-4 text-center'>
          <Button onClick={onLoadMore} loading={loading} type='dashed'>
            Load More Calls
          </Button>
        </div>
      )}
      <Modal
        key={modalKey}
        title='Call Recording'
        open={activeRecording !== null}
        onCancel={handleCloseAudioModal}
        footer={null}
        destroyOnClose
      >
        <audio ref={audioRef} controls key={activeRecording?.url}>
          <source src={activeRecording?.url} type='audio/mpeg' />
          Your browser does not support the audio element.
        </audio>
      </Modal>
      <Modal
        title='Call Summary'
        open={handleViewSummary !== null}
        onCancel={() => setHandleViewSummary(null)}
        footer={null}
      >
        <div
          className='p-4'
          style={{
            maxHeight: '50vh',
            overflowY: 'auto',
          }}
        >
          {handleViewSummary ? <span>{handleViewSummary}</span> : <Empty description='No Summary' />}
        </div>
      </Modal>
      <Modal
        title='Call Transcript'
        open={handleViewTranscript !== null}
        onCancel={() => setHandleViewTranscript(null)}
        footer={null}
      >
        <div
          className='p-4'
          style={{
            maxHeight: '50vh',
            overflowY: 'auto',
          }}
        >
          <Text>
            <List
              dataSource={handleViewTranscript as TranscriptMessage[]}
              renderItem={item =>
                item && (
                  <List.Item
                    style={{
                      display: 'flex',
                      justifyContent: item.sender === 'Assistant' ? 'flex-start' : 'flex-end',
                      textAlign: item.sender === 'Assistant' ? 'left' : 'right',
                    }}
                  >
                    <List.Item.Meta title={<strong>{item.sender}</strong>} description={item.message} />
                  </List.Item>
                )
              }
            />
          </Text>
        </div>
      </Modal>
    </div>
  );
};

export default CallLogsView;
