import React from 'react';
import classes from '../Register.module.css';
import clsx from 'clsx';
import TextEditor from '../../../components/TextEditor/TextEditor';
import { Button } from 'antd';
import posthog from 'posthog-js';

interface Variable {
  isMandatory: boolean;
  defaultValue?: string;
}

interface Step4Props {
  setSystemPhrase: (phrase: string) => void;
  setCampaignVariables: (variables: any) => void; // Allow undefined
  setStepState: (state: number) => void;
  systemPhrase: string;
  campaignVariables: any;
  businessName: string;
  handleStepTransition: (
    nextStep: number,
    validationFn?: () => Promise<boolean>,
    stateMutationFn?: () => Promise<void>
  ) => Promise<void>;
  step5Validation: () => Promise<boolean>;
  step5StateMutation: () => Promise<void>;
  stepLoading: boolean;
  stepState: number;
}

const Step4: React.FC<Step4Props> = ({
  setSystemPhrase,
  setCampaignVariables,
  systemPhrase,
  campaignVariables,
  businessName,
  handleStepTransition,
  step5StateMutation,
  step5Validation,
  stepLoading,
  stepState,
}) => {
  return (
    <div className={clsx(classes.box, '')}>
      <div>
        <h3
          className={classes.heading}
          style={{
            textAlign: 'center',
            marginTop: '1rem',
          }}
        >
          Greeting Phrase
        </h3>
        <div className={classes.subtitle} style={{ textAlign: 'center' }}>
          This is the opening line your receptionist will use when answering the phone. Shorter greeting phrases do
          better.
        </div>
        {React.createElement(() => {
          posthog.capture('greeting_phrase_entered');
          return null;
        })}
        <TextEditor
          html={systemPhrase}
          variables={campaignVariables}
          onChange={(value: string, variables?: Record<string, Variable>) => {
            setSystemPhrase(value);

            if (variables) {
              setCampaignVariables(variables); // Only call if variables is defined
            }
          }}
          placeholder={`e.g. Hi, welcome to ${businessName}. We're currently doing a 10% off summer sale. I can help you book an appointment or answer any questions you may have. How may I help you?`}
        />

        <Button
          type='primary'
          onClick={() => handleStepTransition(stepState + 1, step5Validation, step5StateMutation)}
          className={classes.button + ' ' + classes.centered}
          style={{ marginTop: '4rem' }}
          loading={stepLoading}
        >
          Continue
        </Button>
      </div>
    </div>
  );
};

export default Step4;
