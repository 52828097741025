import { Content } from 'antd/es/layout/layout';
import './ContentComponent.scss';
import Branding from '../Branding/Branding';
import { ENoviDashboardSider } from '@/pages/Novi/enums/ENoviDashboardSider';
import NoviCampaigns from '../Campagins/Campaigns';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { getSelectedCampaignId } from '@/store/novi/user/selector';
import OverviewCard from '@/pages/ReceptionistDashboard/Overview/Cards/OverviewCard';
import { Analytics } from '@/pages/ReceptionistDashboard/Analytics';
import VoiceLibrary from '@/pages/ReceptionistDashboard/VoiceLibrary/voiceLibrary';
import IntakeForms from '@/pages/ReceptionistDashboard/IntakeForms/IntakeForms';
import { TextingPlatform } from '@/pages/ReceptionistDashboard/TextingPlatForm';
import CallLogs from '@/pages/ReceptionistDashboard/LogsComponent/CallLogs/CallLogs';
import TextLogs from '@/pages/ReceptionistDashboard/LogsComponent/TextLogs/TextLogs';
import VoiceMailLogs from '@/pages/ReceptionistDashboard/LogsComponent/VoiceMailLogs/VoiceMailLogs';
import CrmDashboard from '@/pages/ReceptionistDashboard/CrmDashboard/CrmDashboard';
import TwilioSetup from '../Byot/Byot';
import LeadsInfo from '../Campagins/Leads/LeadsInfo';
import CampaignSchedule from '../../CampaignSchedule/CampaignSchedule';
import { useQuery } from '@tanstack/react-query';
import { getSchedule } from '@/api/novi/schedule';
import TextConfigureInfo from '../../TextConfiguration/TextConfigureInfo';
import Subscription from '@/pages/Novi/pages/Dashboard/Subscription/Subscription';
import PhoneNumber from '../../PhoneNumber/PhoneNumber';
import { CampaignSetup } from '../../CampaignSetup/CampaignSetup';

interface ContentComponentProps {
  currentPage: ENoviDashboardSider;
  createCampaignButtonRef: React.RefObject<HTMLDivElement>;
  resellerView?: boolean;
  isStandaloneCampaign: boolean;
}

const NoviContent: React.FC<ContentComponentProps> = ({
  currentPage,
  createCampaignButtonRef,
  resellerView = false,
  isStandaloneCampaign,
}) => {
  const selectedCampaignId = useAppSelector(getSelectedCampaignId);

  const {
    data: campaignSchedule,
    isLoading,
    refetch,
    isRefetching,
  } = useQuery({
    queryKey: ['campaign-schedule', selectedCampaignId],
    queryFn: () => getSchedule(selectedCampaignId as string),
    enabled: !!selectedCampaignId,
    retry: 2,
    staleTime: 30000,
  });

  const renderContent = () => {
    switch (currentPage) {
      case ENoviDashboardSider.MY_CAMPAIGNS:
        return <NoviCampaigns resellerView={resellerView} createCampaignButtonRef={createCampaignButtonRef} />;
      case ENoviDashboardSider.BRANDING:
        return <Branding />;
      case ENoviDashboardSider.LEADS:
        return (
          <LeadsInfo
            campaignId={selectedCampaignId as string}
            isScheduleActive={campaignSchedule?.schedule?.status === 'ACTIVE'}
          />
        );
      case ENoviDashboardSider.SCHEDULE:
        return (
          <CampaignSchedule
            campaignId={selectedCampaignId as string}
            scheduleData={campaignSchedule}
            refetch={refetch}
            isLoading={isLoading}
            isRefetching={isRefetching}
            clientId={selectedCampaignId as string}
          />
        );
      case ENoviDashboardSider.CONFIGURE:
        return <OverviewCard isNoviUser={true} onCompletionChange={() => {}} debrand={true} />;
      case ENoviDashboardSider.ANALYTICS:
        return <Analytics debrand={true} />;
      case ENoviDashboardSider.VOICE_LIBRARY:
        return <VoiceLibrary debrand={true} />;
      case ENoviDashboardSider.INTAKE_FORMS:
        return <IntakeForms />;
      case ENoviDashboardSider.TEXTING_PLATFORM:
        return <TextingPlatform debrand={true} isNoviUser={true} />;
      case ENoviDashboardSider.CALL_LOGS:
        return <CallLogs debrand={true} isNoviUser={true} campaignId={selectedCampaignId as string} />;
      case ENoviDashboardSider.TEXT_LOGS:
        return <TextLogs />;
      case ENoviDashboardSider.VOICE_MAIL_LOGS:
        return <VoiceMailLogs />;
      case ENoviDashboardSider.CRM:
        return <CrmDashboard isNoviUser={true} />;
      case ENoviDashboardSider.BYOT:
        return <TwilioSetup resellerView={resellerView} />;
      case ENoviDashboardSider.SUBSCRIPTION:
        return <Subscription />;
      case ENoviDashboardSider.RESERVE_NUMBER:
        return <PhoneNumber />;
      case ENoviDashboardSider.CAMPAIGN_SETUP:
        return (
          <CampaignSetup
            isPageView={true}
            resellerView={false}
            campaignId={selectedCampaignId as string}
            standAloneCampaignView={isStandaloneCampaign}
          />
        );
      case ENoviDashboardSider.TEXT_CONFIGURATION:
        return <TextConfigureInfo clientId={selectedCampaignId as string} />;
      default:
        break;
    }
  };
  const baseClass = 'content-component';
  return (
    <Content style={{ margin: '24px 16px' }}>
      <div className={`${baseClass}_content`}>{renderContent()}</div>
    </Content>
  );
};

export default NoviContent;
