import { CRMGroup, GroupData } from '@/types/TCRM';
import apiClient from '@/utils/apiClient';

const GROUPS_URI = '/api/crm2/groups';

export const getCrmGroups = async (isNoviUser: boolean, clientID: string): Promise<{ crmGroups: CRMGroup[] }> => {
  try {
    const response = await apiClient.request({
      method: 'GET',
      endPoint: `${GROUPS_URI}?clientID=${clientID}`,
      clientType: isNoviUser ? 'NOVI' : 'RECEPTIONIST',
    });

    return response;
  } catch (error) {
    console.error('Error fetching groups:', error);
    throw error;
  }
};

export const createAGroup = async (payload: {
  clientID: string;
  groupData: GroupData;
}): Promise<{ message: string }> => {
  try {
    const response = await apiClient.request({
      method: 'POST',
      endPoint: GROUPS_URI,
      clientType: 'NOVI',
      body: payload,
    });

    return response;
  } catch (error) {
    console.error('Error creating a group:', error);
    throw error;
  }
};

export const updateAGroup = async (payload: {
  clientID: string;
  groupID: string;
  groupData: CRMGroup;
}): Promise<{ message: string }> => {
  try {
    const response = await apiClient.request({
      method: 'PATCH',
      endPoint: `${GROUPS_URI}/${payload.groupID}`,
      clientType: 'NOVI',
      body: payload,
    });

    return response;
  } catch (error) {
    console.error('Error updating a group:', error);
    throw error;
  }
};

export const deleteAGroup = async (payload: { clientID: string; groupID: string }): Promise<{ message: string }> => {
  try {
    const response = await apiClient.request({
      method: 'DELETE',
      endPoint: `${GROUPS_URI}/${payload.groupID}`,
      clientType: 'NOVI',
      body: payload,
    });

    return response;
  } catch (error) {
    console.error('Error deleting a group:', error);
    throw error;
  }
};
