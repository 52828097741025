import { CRMLead, GetCRMCallLogsResponse, LastEvaluatedKey, TextLog } from '@/types/TCRM';
import apiClient from '@/utils/apiClient';

const LEADS = '/api/crm2/leads';

export const createCRMLead = async (payload: {
  clientID: string;
  leadData: Partial<CRMLead>;
}): Promise<{ message: string }> => {
  try {
    const response = await apiClient.request({
      method: 'POST',
      endPoint: LEADS,
      body: payload,
    });

    return response;
  } catch (error) {
    console.error('Error creating a group:', error);
    throw error;
  }
};

export const getCRMLeads = async (clientID: string): Promise<{ crmLeads: CRMLead[] }> => {
  try {
    const response = await apiClient.request({
      method: 'GET',
      endPoint: `${LEADS}?clientID=${clientID}`,
    });

    return response;
  } catch (error) {
    console.error('Error fetching leads:', error);
    throw error;
  }
};

export const fetchLeadById = async (leadID: string): Promise<CRMLead> => {
  try {
    const response = await apiClient.request({
      method: 'GET',
      endPoint: `${LEADS}/${leadID}`,
    });

    return response;
  } catch (error) {
    console.error('Error fetching lead:', error);
    throw error;
  }
};

export const updateCRMLead = async (payload: {
  clientID: string;
  leadID: string;
  leadData: Partial<CRMLead>;
}): Promise<{ meassage: string }> => {
  try {
    const response = await apiClient.request({
      method: 'PATCH',
      endPoint: `${LEADS}/${payload.leadID}`,
      body: payload,
    });

    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteCRMLead = async (payload: { clientID: string; leadID: string }): Promise<{ message: string }> => {
  try {
    const response = await apiClient.request({
      method: 'DELETE',
      endPoint: `${LEADS}/${payload.leadID}`,
    });

    return response;
  } catch (error) {
    console.error('Error deleting lead:', error);
    throw error;
  }
};

export const addANewNote = async (payload: {
  clientID: string;
  leadID: string;
  note: string;
}): Promise<{ message: string }> => {
  try {
    const response = await apiClient.request({
      method: 'POST',
      endPoint: `${LEADS}/notes`,
      body: payload,
    });

    return response;
  } catch (error) {
    console.error('Error adding a note:', error);
    throw error;
  }
};

export const updateLeadGroup = async (payload: { leadID: string; groupID: string }): Promise<{ message: string }> => {
  try {
    const response = await apiClient.request({
      method: 'PATCH',
      endPoint: `${LEADS}/${payload.leadID}/group`,
      body: payload,
    });

    return response;
  } catch (error) {
    console.error('Error updating lead group:', error);
    throw error;
  }
};

export const updateLeadNote = async (payload: {
  leadID: string;
  note: {
    note_id: string;
    content: string;
  };
}): Promise<{ message: string }> => {
  try {
    const response = await apiClient.request({
      method: 'PATCH',
      endPoint: `${LEADS}/notes`,
      body: payload,
    });

    return response;
  } catch (error) {
    console.error('Error updating lead note:', error);
    throw error;
  }
};

export const deleteLeadNote = async (payload: { leadID: string; noteID: string }): Promise<{ message: string }> => {
  try {
    const response = await apiClient.request({
      method: 'DELETE',
      endPoint: `${LEADS}/${payload.leadID}/notes/${payload.noteID}`,
    });

    return response;
  } catch (error) {
    console.error('Error deleting lead note:', error);
    throw error;
  }
};

export const getCRMCallLogs = async (payload: {
  clientID: string;
  limit: number;
  lastKey?: LastEvaluatedKey;
  to?: string;
  from?: string;
}): Promise<GetCRMCallLogsResponse> => {
  let URL = `${LEADS}/logs/call?clientID=${payload.clientID}`;

  if (payload.to) {
    URL += `&to=${encodeURIComponent(payload.to)}`;
  }

  if (payload.from) {
    URL += `&from=${encodeURIComponent(payload.from)}`;
  }

  if (payload.lastKey) {
    URL += `&lastKey=${JSON.stringify(payload.lastKey)}`;
  }

  URL += `&limit=${payload.limit}`;

  try {
    const response = await apiClient.request({
      method: 'GET',
      endPoint: URL,
    });

    return response;
  } catch (error) {
    console.error('Error fetching call logs:', error);
    throw error;
  }
};

export const getCRMTextLogs = async (payload: { clientID: string; to: string }): Promise<TextLog[]> => {
  let URL = `${LEADS}/logs/text?clientID=${payload.clientID}`;

  if (payload.to) {
    URL += `&to=${encodeURIComponent(payload.to)}`;
  }

  try {
    const response = await apiClient.request({
      method: 'GET',
      endPoint: URL,
    });

    return response;
  } catch (error) {
    console.error('Error fetching text logs:', error);
    throw error;
  }
};
