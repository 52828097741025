import React from 'react';
import Fade from 'react-reveal/Fade';
import classes from '../RegisterPageV3.module.css';
import posthog from 'posthog-js';
import { notification, Button } from 'antd';

const Step10 = ({
  isCampaign,
  reseller_email,
  campaign_user_email,
  username,
  handleUsernameChange,
  errors,
  password,
  setPassword,
  setErrors,
  confirmPassword,
  setConfirmPassword,
  debrand,
  termsAndConditions,
  setTermsAndConditions,
  createAccLoading,
  handleSubmit,
}) => {
  return (
    <>
      <div className={classes.box}>
        {React.createElement(() => {
          posthog.capture('create_credentials_entered');
          return null;
        })}
        <Fade duration={1000} bottom>
          <h3
            className={classes.heading}
            style={{
              textAlign: 'center',
            }}
          >
            Final Step: Create Account
          </h3>
          {!isCampaign ? (
            <div
              className={classes.subtitle}
              style={{
                textAlign: 'center',
              }}
            >
              {reseller_email || campaign_user_email
                ? 'Your client will use this'
                : 'Before you can test your receptionist, create an account. You will use this'}{' '}
              username and password to log in to the dashboard
            </div>
          ) : (
            <></>
          )}

          {!isCampaign ? (
            <>
              <label
                style={{
                  textAlign: 'center',
                  marginTop: '1.5rem',
                  display: 'block',
                }}
              >
                <p className={classes.heading} style={{ textAlign: 'center' }}>
                  Username
                </p>
                <input
                  className={classes.input + ' ' + classes.centered}
                  type='text'
                  value={username}
                  required
                  onChange={handleUsernameChange}
                />
                {errors && <span className='text-red-500 text-center'>{errors?.username}</span>}
              </label>

              <label
                style={{
                  textAlign: 'center',
                  marginTop: '1.5rem',
                  display: 'block',
                }}
              >
                <p className={classes.heading} style={{ textAlign: 'center' }}>
                  Password
                </p>
                <p className={classes.subtitle}>You will use this password to log in to your dashboard</p>
                <input
                  type='password'
                  value={password}
                  className={classes.input + ' ' + classes.centered}
                  required
                  onChange={event => {
                    setPassword(event.target.value);

                    if (confirmPassword && confirmPassword !== event.target.value) {
                      setErrors(prev => ({
                        ...prev,
                        password: 'Password & Confirm Password does not match.',
                      }));
                    } else {
                      setErrors(prev => ({ ...prev, password: '' }));
                    }
                  }}
                />
              </label>

              {password.length > 0 ? (
                <label
                  style={{
                    textAlign: 'center',
                    marginTop: '1.5rem',
                    display: 'block',
                  }}
                >
                  <p className={classes.heading} style={{ textAlign: 'center' }}>
                    Confirm Password
                  </p>
                  <p className={classes.subtitle}>Please enter your password once again </p>
                  <input
                    className={classes.input + ' ' + classes.centered}
                    type='password'
                    value={confirmPassword}
                    required
                    onChange={event => {
                      setConfirmPassword(event.target.value);
                      if (password !== event.target.value) {
                        setErrors(prev => ({
                          ...prev,
                          password: 'Password & Confirm Password does not match.',
                        }));
                      } else {
                        setErrors(prev => ({ ...prev, password: '' }));
                      }
                    }}
                  />
                </label>
              ) : (
                <></>
              )}
              <div
                style={{
                  height: '26px',
                  width: '100%',
                  marginTop: '.5rem',
                }}
              >
                {errors?.password && (
                  <p
                    className={classes.label}
                    style={{
                      color: 'red',
                      textAlign: 'center',
                      margin: 0,
                    }}
                  >
                    {errors.password}
                  </p>
                )}
              </div>
            </>
          ) : (
            <></>
          )}

          <div style={{ textAlign: 'center' }}>
            {!debrand && password && confirmPassword && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <input
                  type='checkbox'
                  name='termsAndConditions'
                  value={termsAndConditions}
                  onChange={e => setTermsAndConditions(e.target.checked)}
                />
                <label htmlFor='termsAndConditions' className='inline-block ml-3'>
                  I agree to the{' '}
                  <a
                    href='https://www.myaifrontdesk.com/terms-of-service'
                    target='_blank'
                    rel='noopener noreferrer'
                    className='text-blue-600 hover:text-black'
                  >
                    terms and conditions
                  </a>
                </label>
              </div>
            )}
          </div>

          <Button
            disabled={(!isCampaign && password !== confirmPassword) || (!debrand && !termsAndConditions)}
            className={classes.button + ' ' + classes.centered}
            style={{
              marginTop: '2rem',
            }}
            loading={createAccLoading}
            onClick={e => {
              if (!isCampaign && (!password || !confirmPassword)) {
                e.preventDefault();
                notification.error({ message: 'Please enter a new password', duration: 1 });
              }
              if (!username && !isCampaign) {
                e.preventDefault();
                notification.error({ message: 'Please enter a username', duration: 1 });
              }

              handleSubmit(e);
            }}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                if (!isCampaign && (!password || !confirmPassword)) {
                  e.preventDefault();
                  notification.error({ message: 'Please enter a new password', duration: 1 });
                }
                if (!username && !isCampaign) {
                  e.preventDefault();
                  notification.error({ message: 'Please enter a username', duration: 1 });
                }

                handleSubmit(e);
              }
            }}
          >
            Create My AI!
          </Button>
        </Fade>
      </div>
    </>
  );
};

export default Step10;
