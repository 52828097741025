import { useState, useEffect, useRef } from 'react';
import { notification, Button } from 'antd';
import { RedoOutlined } from '@ant-design/icons';

interface ServiceWorkerRegistration {
  waiting: ServiceWorker | null;
  installing: ServiceWorker | null;
  update: () => Promise<void>;
  addEventListener: (event: string, callback: (event: Event) => void) => void;
}

const VersionChecker: React.FC = () => {
  const [registration, setRegistration] = useState<ServiceWorkerRegistration | null>(null);
  const abortControllerRef = useRef<AbortController | null>(null);
  const notificationShown = useRef<boolean>(false);

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      registerServiceWorker();
    }
  }, []);

  useEffect(() => {
    if (registration) {
      const intervalId = setInterval(
        () => {
          registration.update();
        },
        30 * 60 * 1000
      ); // Check for updates every 30 minutes

      return () => clearInterval(intervalId);
    }
  }, [registration]);

  const registerServiceWorker = async (): Promise<void> => {
    try {
      const existingRegistration = await navigator.serviceWorker.getRegistration();

      if (existingRegistration) {
        setRegistration(existingRegistration as ServiceWorkerRegistration);

        existingRegistration.update();

        existingRegistration.addEventListener('updatefound', () => {
          const newWorker = existingRegistration.installing;

          if (newWorker) {
            newWorker.addEventListener('statechange', () => {
              if (newWorker.state === 'installed' && navigator.serviceWorker.controller) {
                if (!notificationShown.current) {
                  showUpdateNotification();
                  notificationShown.current = true;
                }
              }
            });
          }
        });
      } else {
        const newRegistration = (await navigator.serviceWorker.register(
          '/service-worker.js'
        )) as ServiceWorkerRegistration;
        setRegistration(newRegistration);

        newRegistration.addEventListener('updatefound', () => {
          const newWorker = newRegistration.installing;

          if (newWorker) {
            newWorker.addEventListener('statechange', () => {
              if (newWorker.state === 'installed' && navigator.serviceWorker.controller) {
                if (!notificationShown.current) {
                  showUpdateNotification();
                  notificationShown.current = true;
                }
              }
            });
          }
        });
      }
    } catch (error) {
      console.error('Service Worker registration failed:', error);
    }
  };

  const handleRefresh = (): void => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    if (registration?.waiting) {
      registration.waiting.postMessage('checkForUpdates');

      navigator.serviceWorker.addEventListener('controllerchange', () => {
        window.location.reload();
      });
    } else {
      window.location.reload();
    }
  };

  const showUpdateNotification = (): void => {
    notification.open({
      message: 'New Version Available',
      description: 'A new version of the website is available. Please refresh to update.',
      icon: <RedoOutlined style={{ color: '#108ee9' }} />,
      btn: (
        <Button type='primary' size='small' onClick={handleRefresh}>
          Refresh Now
        </Button>
      ),
      duration: 0,
      onClose: () => console.log('Notification closed'),
    });
  };

  return null;
};

export default VersionChecker;
