import React, { useEffect, useMemo } from 'react';
import { Navigate, Outlet, useLocation, useSearchParams } from 'react-router-dom';
import useAuth from '@/routes/hooks/useAuth';
import { Loading } from '@/components/Loading';
import { AppRoutes, BRANDED_DOMAINS, UNBRANDED_DOMAINS } from 'src/enums/ERoutes';
import Favicon from '@/assets/images/favicon.ico';
import { useQuery } from '@tanstack/react-query';
import { getResellerByCode, getResellerLogo } from '@/api/unbrandedReceptionist';
import { LocalStorageKeys } from 'src/enums/ELocalStorage';

const PublicReceptionistRoute: React.FC = () => {
  const { isAuthenticated, isLoading } = useAuth();
  const location = useLocation();
  const domain = window.location.hostname;

  const isUnbrandedPath =
    location.pathname === AppRoutes.UNBRANDED_RECEPTIONIST ||
    location.pathname === AppRoutes.UNBRANDED_DASHBOARD ||
    UNBRANDED_DOMAINS.some(unbrandedDomain => domain.includes(unbrandedDomain));

  const [searchParams] = useSearchParams();
  const resellerCode = searchParams.get('reseller_code') || searchParams.get('code');

  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      (location.pathname === AppRoutes.UNBRANDED_RECEPTIONIST || location.pathname === AppRoutes.UNBRANDED_DASHBOARD)
    ) {
      try {
        // Update the document title (tab title)
        document.title = `Portal`;

        // Update the meta description
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
          metaDescription.setAttribute('content', `Dashboard/Portal`);
        }

        // If you still need to update an element with id 'page-title'
        const pageTitle = document.getElementById('page-title');
        if (pageTitle) {
          pageTitle.textContent = `Portal`;
        }
      } catch (err) {
        console.error('Error updating page metadata:', err);
      }
    }
  }, [isUnbrandedPath]);

  const { data, refetch } = useQuery({
    queryKey: ['reseller-code', resellerCode],
    queryFn: () => getResellerByCode({ resellerCode: resellerCode || '' }),
    enabled: !!(isUnbrandedPath && resellerCode),
  });

  const { data: resellerLogo } = useQuery({
    queryKey: ['reseller-logo'],
    queryFn: () => getResellerLogo({ key: data.reseller.logo_s3 }),
    enabled: !!(isUnbrandedPath && data?.reseller?.logo_s3),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  useEffect(() => {
    if (BRANDED_DOMAINS.find(item => item === domain)) {
      setFavicon(Favicon);
    } else {
      if (resellerCode) {
        refetch();
      } else {
        setFaviconToNone();
      }
    }
  }, [isUnbrandedPath, resellerCode]);

  const resellerLogoUrl = useMemo(() => {
    if (!resellerLogo) return;

    return URL.createObjectURL(resellerLogo);
  }, [resellerLogo]);

  const convertToFavicon = async (imageSource: any) => {
    try {
      const img = new Image();

      // Create a promise to handle image loading
      const imageLoadPromise = new Promise((resolve, reject) => {
        img.onload = () => resolve(img);
        img.onerror = () => reject(new Error('Failed to load image'));
      });

      // If it's a presigned URL, we need to handle CORS
      if (typeof imageSource === 'string') {
        img.crossOrigin = 'anonymous';
        img.src = imageSource;
      } else {
        // Handle File object
        const reader = new FileReader();
        reader.onload = (e: any) => {
          img.src = e.target.result;
        };
        reader.readAsDataURL(imageSource);
      }

      // Wait for image to load
      await imageLoadPromise;

      // Create canvas for the favicon
      const canvas = document.createElement('canvas');
      canvas.width = 48;
      canvas.height = 48;
      const ctx = canvas.getContext('2d');

      // Draw the image scaled down to 16x16
      ctx?.drawImage(img, 0, 0, 48, 48);

      // Convert to favicon URL
      const faviconUrl = canvas.toDataURL('image/png');
      localStorage.setItem(LocalStorageKeys.FAVICON_URL, faviconUrl);
      return faviconUrl;
    } catch (err) {
      console.error('Error converting image:', err);
      return;
    }
  };

  // Function to change the favicon
  const setFavicon = (url: string) => {
    const link: HTMLLinkElement | null = document.querySelector("link[rel*='icon']");
    if (link) {
      link.href = url;
    } else {
      const newLink = document.createElement('link');
      newLink.rel = 'icon';
      newLink.href = url;
      document.head.appendChild(newLink);
    }
  };

  const setFaviconToNone = () => {
    const link: HTMLLinkElement | null = document.querySelector("link[rel*='icon']");
    if (link) {
      link.href = 'data:,';
    } else {
      const newLink = document.createElement('link');
      newLink.rel = 'icon';
      newLink.href = 'data:,';
      document.head.appendChild(newLink);
    }
  };

  useEffect(() => {
    const fetch = async () => {
      if (!resellerLogoUrl) {
        return;
      }

      let faviconUrl = localStorage.getItem(LocalStorageKeys.FAVICON_URL) || '';
      if (!faviconUrl) {
        faviconUrl = (await convertToFavicon(resellerLogoUrl)) || '';
      }

      // Change favicon based on conditions
      if (faviconUrl) {
        setFavicon(faviconUrl);
      } else {
        console.log('called private');
        setFavicon(Favicon);
      }
    };

    fetch();
  }, [resellerLogoUrl]);

  if (isLoading) {
    return <Loading />;
  }

  if (!isAuthenticated && isUnbrandedPath) {
    return <Outlet context={{ debrand: isUnbrandedPath }} />;
  } else if (!isAuthenticated && !isUnbrandedPath) {
    <Outlet />;
  }

  if (isAuthenticated && isUnbrandedPath) {
    return (
      <Navigate to='/dashboard' state={{ from: location.pathname, debrand: isUnbrandedPath, resellerCode }} replace />
    );
  } else if (isAuthenticated && !isUnbrandedPath) {
    return <Navigate to='/' />;
  }

  return <Outlet />;
};

export default PublicReceptionistRoute;
