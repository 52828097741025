import { Card, Space, Tag, Typography, Button, Modal, notification, Tooltip } from 'antd';
import { DeleteOutlined, EditOutlined, InfoCircleOutlined, PhoneOutlined, SyncOutlined } from '@ant-design/icons';
import { Lead, LeadsDashboardProps } from '@/pages/Novi/types/Leads';
import { useCallback, useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { deleteLead, getPollingLeads, makeACall } from '@/api/novi/leads';
import LeadsInfoForm from './LeadsInfoForm';
import VirtualList from 'rc-virtual-list';

const { Text } = Typography;

const POLLING_INTERVAL = 50000;
const PAGE_SIZE = 100;

const LeadsDashboard: React.FC<LeadsDashboardProps> = ({ leads, campaignID, isScheduleActive, refetchLeads }) => {
  const [editingLead, setEditingLead] = useState<Lead | null>(null);
  const [deleteLeadId, setDeleteLeadId] = useState<string | null>(null);
  const [callLead, setCallLead] = useState<Lead | null>(null);
  const [lastPollingTime, setLastPollingTime] = useState(() => new Date().getTime());

  const [leadsData, setLeadsData] = useState<Record<string, any>>({
    NEW: {
      leads: [],
      hasMore: true,
      nextKey: null,
      loading: false,
      tooltip: 'Lead which has been added recently and has not been utilized in any campaign',
    },
    CONTACTED: {
      leads: [],
      hasMore: true,
      nextKey: null,
      loading: false,
      tooltip: 'Leads that have been successfully reached and communicated with',
    },
    PROCESSING: {
      leads: [],
      hasMore: true,
      nextKey: null,
      loading: false,
      tooltip: 'Leads currently being processed in active campaigns or pending follow-up actions',
    },
    FAILED: {
      leads: [],
      hasMore: true,
      nextKey: null,
      loading: false,
      tooltip: "Leads that couldn't be reached after maximum retry attempts or encountered communication errors",
    },
  });

  // const screens = useBreakpoint();

  useEffect(() => {
    if (leads) {
      setLeadsData({ ...leads });
    }
  }, [leads]);

  const pollUpdatedLeads = useCallback(async () => {
    try {
      const updatedLeadsResponses = await Promise.all(
        Object.keys(leadsData).map(status =>
          getPollingLeads(campaignID, lastPollingTime, {
            status,
            nextKey: leadsData[status].nextKey || '',
            limit: PAGE_SIZE,
          })
        )
      );

      const updatedLeadsMap: Record<string, Lead[]> = {};
      updatedLeadsResponses.forEach((response, index) => {
        const status = Object.keys(leadsData)[index];
        updatedLeadsMap[status] = response.leads;
      });

      const updatedLeadsById = Object.values(updatedLeadsMap)
        .flat()
        .reduce<Record<string, { lead: Lead; status: string }>>((acc, lead) => {
          acc[lead.lead_id] = {
            lead,
            status: Object.keys(updatedLeadsMap).find(s => updatedLeadsMap[s].includes(lead))!,
          };
          return acc;
        }, {});

      setLeadsData(prev => {
        const newData = { ...prev };

        Object.keys(newData).forEach(status => {
          const existingLeads = newData[status].leads;

          const filteredLeads = existingLeads.filter(
            (lead: Lead) => !updatedLeadsById[lead.lead_id] || updatedLeadsById[lead.lead_id].status === status
          );

          const updatedLeads = updatedLeadsMap[status] || [];
          const mergedLeads = [...updatedLeads, ...filteredLeads].reduce<Lead[]>((acc, lead) => {
            if (!acc.some(l => l.lead_id === lead.lead_id)) {
              acc.push(lead);
            }
            return acc;
          }, []);

          newData[status].leads = mergedLeads;
        });

        return newData;
      });

      setLastPollingTime(new Date().getTime());
    } catch (error) {
      console.error('Polling Error:', error);
    }
  }, [leadsData, lastPollingTime, campaignID]);

  useEffect(() => {
    if (!isScheduleActive) {
      console.log('Polling skipped: isScheduleActive is false');
      return;
    }

    console.log('Polling started');
    const interval = setInterval(() => {
      pollUpdatedLeads();
    }, POLLING_INTERVAL);

    return () => {
      console.log('Polling stopped');
      clearInterval(interval);
    };
  }, [pollUpdatedLeads, isScheduleActive]);

  const handleScroll = useCallback(
    async (status: string, e: any) => {
      const { scrollHeight, scrollTop, clientHeight } = e.target;
      const threshold = scrollHeight - clientHeight;

      if (scrollTop >= threshold * 0.8 && leadsData[status].hasMore && !leadsData[status].loading) {
        refetchLeads(status, leadsData[status].nextKey);
      }
    },
    [leadsData, refetchLeads]
  );

  // const { mutate: updateStatus } = useMutation({
  //   mutationFn: updateLeadStatus,
  //   onSuccess: () => {
  //     Object.keys(leadsData).forEach(status => refetchLeads(status));
  //   },
  //   onError: () => {
  //     notification.error({
  //       message: 'Error',
  //       description: 'Failed to update lead status',
  //     });
  //   },
  // });

  const { mutate: immediateCall } = useMutation({
    mutationFn: makeACall,
    onSuccess: () => {
      notification.success({
        message: 'Call initiated',
        description: 'Call has been initiated successfully',
      });
    },
    onError: () => {
      notification.error({
        message: 'Error',
        description: 'Failed to initiate call',
      });
    },
  });

  const { mutate: deleteALead, isPending } = useMutation({
    mutationFn: deleteLead,
    onSuccess: () => {
      Object.keys(leadsData).forEach(status => refetchLeads(status));
      setDeleteLeadId(null);
    },
    onError: () => {
      notification.error({
        message: 'Error',
        description: 'Failed to delete lead',
      });
    },
  });

  // const handleDragEnd = (result: DropResult) => {
  //   const { destination, source, draggableId } = result;

  //   if (!destination || (destination.droppableId === source.droppableId && destination.index === source.index)) {
  //     return;
  //   }

  //   const sourceStatus = source.droppableId;
  //   const lead = leadsData[sourceStatus].leads.find((lead: Lead) => lead.lead_id === draggableId);

  //   if (lead) {
  //     setLeadsData(prev => {
  //       const sourceLeads = prev[sourceStatus].leads.filter((l: Lead) => l.lead_id !== draggableId);
  //       const destinationLeads = [lead, ...prev[destination.droppableId].leads];

  //       return {
  //         ...prev,
  //         [sourceStatus]: { ...prev[sourceStatus], leads: sourceLeads },
  //         [destination.droppableId]: { ...prev[destination.droppableId], leads: destinationLeads },
  //       };
  //     });

  //     updateStatus({
  //       lead_id: lead.lead_id,
  //       status: destination.droppableId,
  //       campaign_id: campaignID,
  //     });
  //   }
  // };

  const renderLeadCard = useCallback(
    (lead: Lead) => (
      <Card
        size='small'
        className='hover:bg-gray-50 transition-colors'
        styles={{ body: { padding: '8px', backgroundColor: '#fafafa' } }}
        style={{ marginBottom: '8px', borderRadius: '8px' }}
      >
        <div className='space-y-1'>
          <div className='flex items-center justify-between'>
            <Text className='text-sm'>{lead.phone_number}</Text>
            <Tag
              className={`
              text-xs rounded-full border-0 px-2 py-0
              ${
                lead.type === 'ZAPIER'
                  ? 'bg-purple-50 text-purple-600'
                  : lead.type === 'MANUAL'
                    ? 'bg-green-50 text-green-600'
                    : 'bg-orange-50 text-orange-600'
              }
            `}
            >
              {lead.type}
            </Tag>
          </div>

          <Space size='small'>
            {/* <Button
              type='text'
              size='small'
              className='px-1 hover:bg-gray-100'
              icon={<InfoCircleOutlined className='text-yellow-500 text-xs' />}
              onClick={() => console.log('Lead Info:', lead)}
            /> */}
            <Button
              type='text'
              size='small'
              className='px-1 hover:bg-gray-100'
              icon={<EditOutlined className='text-blue-500 text-xs' />}
              onClick={() => setEditingLead(lead)}
            />
            <Button
              type='text'
              size='small'
              className='px-1 hover:bg-gray-100'
              icon={<DeleteOutlined className='text-red-500 text-xs' />}
              onClick={() => setDeleteLeadId(lead.lead_id)}
            />
            <Button
              type='text'
              size='small'
              className='px-1 hover:bg-gray-100'
              icon={<PhoneOutlined className='text-green-500 text-xs' />}
              onClick={() => setCallLead(lead)}
            />
          </Space>
        </div>
      </Card>
    ),
    []
  );

  console.log(leadsData);

  return (
    <div className='h-[530px] bg-gray-100 overflow-x-auto overflow-y-hidden'>
      <div className='min-w-[1024px] h-full p-4'>
        <div className='grid grid-cols-4 gap-4 h-full'>
          {Object.entries(leadsData).map(([status, { leads, loading, tooltip }]) => (
            <div key={status} className='flex flex-col h-full min-w-[250px]'>
              <div className='flex items-center justify-between mb-1 px-2'>
                <div className='flex flex-row items-center gap-3'>
                  <Tooltip title={tooltip}>
                    <InfoCircleOutlined />
                  </Tooltip>
                  <Text className='text-xs font-medium text-gray-600'>{status}</Text>
                </div>
                <Tag className='rounded-full text-xs px-2 py-0 bg-gray-50 border-0 text-gray-500'>{leads.length}</Tag>
              </div>
              <div className='flex-1 overflow-y-auto bg-white rounded-lg shadow-sm p-2 h-[calc(100%-30px)]'>
                <VirtualList
                  height={430}
                  itemHeight={80}
                  itemKey='lead_id'
                  data={leads}
                  onScroll={e => handleScroll(status, e)}
                >
                  {lead => renderLeadCard(lead)}
                </VirtualList>
                {loading && (
                  <div className='text-center py-2'>
                    <SyncOutlined spin /> Loading...
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>

      <Modal
        title='Edit Lead'
        open={!!editingLead}
        onCancel={() => setEditingLead(null)}
        footer={null}
        destroyOnClose
        width={600}
      >
        {editingLead && (
          <LeadsInfoForm
            lead={editingLead}
            onClose={() => setEditingLead(null)}
            refetchLeads={() => {
              Object.keys(leadsData).forEach(status => refetchLeads(status));
            }}
          />
        )}
      </Modal>

      <Modal
        open={!!deleteLeadId}
        onCancel={() => setDeleteLeadId(null)}
        footer={
          <Space size='small'>
            <Button size='small' onClick={() => setDeleteLeadId(null)}>
              Cancel
            </Button>
            <Button
              size='small'
              danger
              loading={isPending}
              onClick={() => {
                deleteALead({
                  campaign_id: campaignID,
                  lead_id: deleteLeadId as string,
                });
              }}
            >
              Delete
            </Button>
          </Space>
        }
        width={400}
      >
        <Text className='text-sm'>Are you sure you want to delete this lead?</Text>
      </Modal>

      <Modal
        open={!!callLead}
        onCancel={() => setCallLead(null)}
        footer={
          <Space size='small' style={{ borderTop: 'none' }}>
            <Button size='small' onClick={() => setCallLead(null)}>
              Cancel
            </Button>
            <Button
              size='small'
              type='primary'
              onClick={() => {
                immediateCall({
                  campaign_id: campaignID,
                  lead_id: callLead?.lead_id as string,
                });
                setCallLead(null);
              }}
            >
              Make a call
            </Button>
          </Space>
        }
        width={400}
      >
        <div className='p-3'>
          <Text className='text-sm'>Do you want to make a call to {callLead?.phone_number}?</Text>
        </div>
      </Modal>
    </div>
  );
};

export default LeadsDashboard;
