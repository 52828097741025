import { useState } from 'react';
import { Modal, Button } from 'antd';
import { Promo } from '@/components/Promo';
import { getLoggedInUser } from '@/store/account/selector';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { BOOK_CALL_CALENDLY_URL, MY_AI_FRONT_DESK_CONTACT_EMAIL } from 'src/constants';
import { useDispatch } from 'react-redux';
import { setCurrentPage } from '@/store/account/slice';
import { ReceptionistDashboardSiderItem } from 'src/enums/EReceptionistDashboardSiderItem';

const ReceptionistPromo = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const loggedInUser = useAppSelector(getLoggedInUser);
  const dispatch = useDispatch();

  const handleSetupCallNowClick = () => {
    if (loggedInUser.demo) {
      setIsModalVisible(true);
    } else {
      window.open(BOOK_CALL_CALENDLY_URL, '_blank');
    }
  };

  const handleStartFreeTrial = () => {
    dispatch(setCurrentPage(ReceptionistDashboardSiderItem.GET_STARTED));
    setIsModalVisible(false);
  };

  return (
    <>
      <Promo>
        Schedule a <strong>complimentary onboarding session</strong> with our team for personalized setup assistance.{' '}
        <a onClick={handleSetupCallNowClick} className='ml-2' type='primary'>
          Book Call Now
        </a>
        <a href={`mailto:${MY_AI_FRONT_DESK_CONTACT_EMAIL}`} className='ml-2' type='primary'>
          Contact Support
        </a>
      </Promo>

      <Modal
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
        centered
        className='rounded-lg'
      >
        <div className='py-6 text-center space-y-6'>
          <div className='flex flex-col items-center space-y-4'>
            <div className='text-4xl'>✨</div>
            <h3 className='text-xl font-semibold text-gray-900'>Complete Your Setup Journey</h3>
            <p className='text-gray-600 max-w-sm'>
              To make the most of your consultation, let's complete your initial setup and activate your free trial
              first.
            </p>
          </div>

          <Button
            type='primary'
            onClick={handleStartFreeTrial}
            className='bg-blue-600 hover:bg-blue-700 h-12 px-8 font-medium'
          >
            Complete Setup
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default ReceptionistPromo;
