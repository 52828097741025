import React, { useState } from 'react';
import { Input, notification } from 'antd';
import { getBusinessInfoFromUrl } from '@/api/user';
import StepWrapper from './StepWrapper';
import { ArrowRight } from 'lucide-react';

interface StepAOneProps {
  businessName: string;
  setBusinessName: (name: string) => void;
  companyInfo: string;
  setCompanyInfo: (name: string) => void;
  websiteLink: string;
  setWebsiteLink: (link: string) => void;
  handleStepTransition: () => void;
}

const StepAOne: React.FC<StepAOneProps> = ({
  setBusinessName,
  websiteLink,
  setWebsiteLink,
  handleStepTransition,
  setCompanyInfo,
}) => {
  const [isFetching, setIsFetching] = useState(false);
  const [websiteError, setWebsiteError] = useState<string | null>(null);

  const fetchBusinessName = async () => {
    setIsFetching(true);
    try {
      let sanitizedUrl = websiteLink.trim();

      if (!sanitizedUrl.startsWith('http://') && !sanitizedUrl.startsWith('https://')) {
        sanitizedUrl = `https://${sanitizedUrl}`;
      }

      const urlPattern = new RegExp(
        '^(https?:\\/\\/)?' + '(([a-zA-Z0-9-]+\\.)+[a-zA-Z]{2,})' + '(\\:[0-9]{1,5})?' + '(\\/.*)?$',
        'i'
      );

      if (!urlPattern.test(sanitizedUrl)) {
        notification.error({ message: 'Invalid URL. Please enter a valid domain or URL.', duration: 2 });
        return;
      } else {
        const businessInfo = await getBusinessInfoFromUrl({ url: sanitizedUrl });

        if (businessInfo) {
          setBusinessName(businessInfo.businessName || '');
          if (businessInfo.businessInfo) {
            setCompanyInfo(businessInfo.businessInfo);
          }
        }

        setIsFetching(false);
        handleStepTransition();
      }
    } catch (error: any) {
      setIsFetching(false);
      handleStepTransition();
    } finally {
      setIsFetching(false);
    }
  };

  const validateAndFetch = () => {
    if (!websiteLink.trim()) {
      notification.error({ message: 'Please enter your website', duration: 1 });
      setWebsiteError('Please enter a valid website domain.');
      return;
    }

    setWebsiteError(null);
    fetchBusinessName();
  };

  const skipForLater = () => {
    setBusinessName('');
    setCompanyInfo('');
    handleStepTransition();
  };

  return (
    <StepWrapper handleNext={validateAndFetch} isLoading={isFetching}>
      <div className='flex-grow w-full flex flex-col items-center'>
        <div className='flex flex-col items-start w-full'>
          <h3 className='sm:text-3xl text-xl font-bold text-gray-900 text-start mb-0'>Enter your business URL</h3>
          <p className='text-gray-500 sm:text-lg text-sm'>Our AI will learn from your website</p>
        </div>

        <div className='w-full flex flex-col items-start'>
          <div className='relative w-full mb-4'>
            <Input
              value={websiteLink}
              onChange={event => setWebsiteLink(event.target.value)}
              className='border border-gray-300'
              style={{ height: '50px' }}
              placeholder='yourbusiness.com'
            />
          </div>

          {websiteError && <p className='text-red-500 mt-2 text-sm'>{websiteError}</p>}

          <p
            className='text-button-success flex items-center font-bold cursor-pointer m-0 hover:bg-gray-100  px-2 rounded-lg sm:text-lg text-sm'
            onClick={skipForLater}
          >
            <div>I don&apos;t have a website</div> <ArrowRight />
          </p>
        </div>
      </div>
    </StepWrapper>
  );
};

export default StepAOne;
