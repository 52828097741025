import React, { useRef, useState } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { Typography, Card, Button, Empty, notification, FormInstance, Col, Space, Row } from 'antd';
import { CheckCircleOutlined, InfoCircleOutlined, PhoneOutlined } from '@ant-design/icons';
import './PhoneNumber.scss';
import { CustomModal } from '@/components/CustomModal';
import { provisionNumberRequest } from '@/api/reservedNumbers';
import GetChooseNumberForm from '@/pages/ReceptionistDashboard/TestItOut/Steps/GetChooseNumberForm';
import { GetStandAloneNumberForm } from '@/pages/ReceptionistDashboard/TestItOut/Steps/GetStandAloneNumberForm';
import { getCampaignReservedNumber } from '@/api/novi/campaigns';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { getSelectedCampaign } from '@/store/novi/user/selector';

const { Title, Text, Paragraph } = Typography;

const QUERY_KEYS = {
  RESERVED_NUMBER: 'reservedNumber',
  PROVISION_NUMBER: 'provisionNumber',
} as const;

const PhoneNumber: React.FC = () => {
  const queryClient = useQueryClient();
  const formRef = useRef<FormInstance>(null);

  // State management
  const [modals, setModals] = useState({
    standAloneNumber: false,
    chooseNumber: false,
    bookNumber: false,
  });
  const [selectedCountry, setSelectedCountry] = useState<string>('');
  const selectedCampaign = useAppSelector(getSelectedCampaign);

  // Query for fetching reserved number
  const { data: reservedNumber } = useQuery({
    queryKey: [QUERY_KEYS.RESERVED_NUMBER],
    queryFn: getCampaignReservedNumber,
    retry: 2,
    staleTime: 5 * 60 * 1000, // 5 minutes
  });

  // Mutation for provisioning number
  const { mutate: provisionNumber, isPending: isProvisioning } = useMutation({
    mutationFn: provisionNumberRequest,
    onSuccess: () => {
      notification.success({ message: 'Number provisioned successfully' });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.RESERVED_NUMBER] });
      handleCloseModal('bookNumber');
      handleCloseModal('standAloneNumber');
    },
    onError: (error: any) => {
      const errorMsg = error?.message?.includes('Authenticate')
        ? 'Invalid Twilio credentials'
        : 'Failed to provision number';
      notification.error({ message: errorMsg });
    },
  });

  // Modal handlers
  const handleOpenModal = (modalName: keyof typeof modals) => {
    setModals(prev => ({ ...prev, [modalName]: true }));
  };

  const handleCloseModal = (modalName: keyof typeof modals) => {
    setModals(prev => ({ ...prev, [modalName]: false }));
  };

  // Form handlers
  const handleFormSubmit = (values: { country: string }) => {
    setSelectedCountry(values.country);
    handleCloseModal('standAloneNumber');
    handleOpenModal('bookNumber');
  };

  const handleConfirmAction = () => {
    formRef.current?.submit();
  };

  const handleBookNumber = async () => {
    if (!selectedCountry) return;

    const countryCode = selectedCountry.split(' - ')[2];
    const payload = {
      username: selectedCampaign?.number,
      countryCode,
      isNovi: true,
    };

    provisionNumber(payload);
  };

  // Render the component
  return (
    <div className='reserved-number-container'>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <div className='header-section'>
            <Title level={4} className='page-title'>
              Reserved Number Management
            </Title>
            <Paragraph className='header-description'>
              Manage your dedicated phone number for business communications. Reserved number can be used for sending
              calls, SMS messages, and automated responses.
            </Paragraph>
          </div>
        </Col>

        <Col xs={24} lg={16}>
          <Card className='number-management-card'>
            <Title level={5}>Active Phone Number</Title>
            {reservedNumber?.data ? (
              <div className='number-info'>
                <PhoneOutlined className='phone-icon' />
                <div className='number-details'>
                  <Text className='phone-number'>{reservedNumber?.data?.reservedNumber}</Text>
                  <div className='status-badge'>
                    <CheckCircleOutlined /> Active
                  </div>
                </div>
              </div>
            ) : (
              <div className='empty-state'>
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={
                    <Space direction='vertical' align='center'>
                      <Text>No number reserved yet</Text>
                      <Text type='secondary'>Reserve a number to start receiving calls and messages</Text>
                    </Space>
                  }
                >
                  <Button
                    type='primary'
                    size='middle'
                    icon={<PhoneOutlined />}
                    onClick={() => handleOpenModal('standAloneNumber')}
                    loading={isProvisioning}
                  >
                    Reserve a Number
                  </Button>
                </Empty>
              </div>
            )}
          </Card>
        </Col>

        <Col xs={24} lg={8}>
          <Card className='info-card'>
            <Title level={5}>Number Benefits</Title>
            <ul className='benefits-list'>
              <li>
                <CheckCircleOutlined /> Dedicated business line
              </li>
              <li>
                <CheckCircleOutlined /> SMS & voice capabilities
              </li>
              <li>
                <CheckCircleOutlined /> Auto-attendant ready
              </li>
              <li>
                <CheckCircleOutlined /> Call analytics included
              </li>
            </ul>
            <div className='info-section'>
              <InfoCircleOutlined className='info-icon' />
              <Text type='secondary'>
                Reserved numbers are automatically configured for your business needs and integrate with all platform
                features.
              </Text>
            </div>
          </Card>
        </Col>
      </Row>

      {/* Modals */}
      <CustomModal
        title='Get your standalone number'
        isModalOpen={modals.standAloneNumber}
        confirmAction={handleConfirmAction}
        cancelAction={() => handleCloseModal('standAloneNumber')}
        okButtonProps={{ loading: isProvisioning }}
        okText='Get Random Number'
      >
        <GetStandAloneNumberForm
          debrand
          isNovi
          ref={formRef}
          setCountry={setSelectedCountry}
          onSubmit={handleFormSubmit}
          setChooseNumberModalOpen={() => handleOpenModal('chooseNumber')}
        />
      </CustomModal>

      <CustomModal
        title='Save your changes'
        isModalOpen={modals.bookNumber}
        confirmAction={handleBookNumber}
        cancelAction={() => handleCloseModal('bookNumber')}
      >
        <Title level={4}>Click Ok to confirm you want to buy number for {selectedCountry.split('-')[1]}</Title>
      </CustomModal>

      <CustomModal
        title='Get Your Favourite Area Code'
        isModalOpen={modals.chooseNumber}
        confirmAction={() => {
          handleCloseModal('chooseNumber');
          handleCloseModal('bookNumber');
        }}
        cancelAction={() => handleCloseModal('chooseNumber')}
        footer={null}
      >
        <GetChooseNumberForm
          isOpen={modals.chooseNumber}
          onClose={() => handleCloseModal('chooseNumber')}
          selectedCountryCode={selectedCountry.split(' - ')[2]}
          bookNumberRequest={handleBookNumber}
        />
      </CustomModal>
    </div>
  );
};

export default PhoneNumber;
