import React from 'react';
import { Button, Typography } from 'antd';
import { MailOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import './SoldOut.scss';

const { Title, Paragraph } = Typography;

interface SoldOutPageProps {
  supportEmail?: string;
  companyName?: string;
  registerText?: string;
}

const SoldOutPage: React.FC<SoldOutPageProps> = ({
  supportEmail,
  companyName = 'Our Company',
  registerText = 'Receptionist',
}) => {
  const handleContactSupport = () => {
    window.location.href = `mailto:${supportEmail}?subject=Receptionist%20Registration%20Issue&body=Hello%20${companyName}%20Support%2C%0A%0AI%20am%20experiencing%20issues%20registering%20a%20receptionist.%20Could%20you%20please%20assist%3F%0A%0ABest%20regards`;
  };

  return (
    <div className='registration-issue'>
      <div className='registration-issue__content'>
        <div className='registration-issue__icon'>
          <ExclamationCircleOutlined />
        </div>

        <Title level={2} className='registration-issue__title'>
          Unable to Register {registerText}
        </Title>

        <div className='registration-issue__messages'>
          <Paragraph>We're currently experiencing issues with {registerText.toLowerCase()} registration.</Paragraph>
          <Paragraph>Our support team is ready to assist you in completing your registration.</Paragraph>
        </div>

        {supportEmail && (
          <Button
            type='primary'
            size='large'
            icon={<MailOutlined />}
            onClick={handleContactSupport}
            className='registration-issue__button'
          >
            Contact Support
          </Button>
        )}

        <Paragraph className='registration-issue__footer'>
          {companyName} Support Team is available 24/7 to help you
        </Paragraph>
      </div>
    </div>
  );
};

export default SoldOutPage;
