import React, { ChangeEvent } from 'react';
import Fade from 'react-reveal/Fade';
import classes from '../Register.module.css';
import { Button, notification } from 'antd';
import TextEditor from '../../../components/TextEditor/TextEditor';
import PhoneInput from 'react-phone-input-2';

type Country = {
  name: string;
  code: string;
};

type CallWorkflow = {
  description: string;
  voiceResponse: string;
  workflowForwardingNumber: string;
  country: Country;
};

type Step7AProps = {
  callWorkflows: CallWorkflow[];
  setShowCallTransferringModal: (active: boolean) => void;
  isCampaign: boolean | undefined;
  campaignVariables: any; // Replace with specific type if available
  handleCallWorkflowDescriptionChange: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => void;
  setCampaignVariables: (variables: any) => void; // Replace with specific type if available
  handleCallWorkflowVoiceResponseChange: (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    index: number
  ) => void;
  handleCallWorkflowForwardingNumber: (testing_number: string, index: number) => void;
  removeCallWorkflow: (event: React.MouseEvent<HTMLButtonElement>, index: number) => void;
  addCallWorkflow: (e: React.FormEvent) => void;
  setCallTransferTemplateModalActive: (active: boolean) => void;
  isUsingATemplate: boolean;
  systemPhrase?: string;
  businessType?: string;
  reseller_email?: string;
  setStepState: (state: number) => void;
  stepState: number;
  showCallTransferringModal: boolean;
  handleStepTransition: (
    nextStep: number,
    validationFn?: () => Promise<boolean>,
    stateMutationFn?: () => Promise<void>
  ) => Promise<void>;
  stepLoading: boolean;
};

const Step7A: React.FC<Step7AProps> = ({
  callWorkflows,
  setShowCallTransferringModal,
  isCampaign,
  campaignVariables,
  handleCallWorkflowDescriptionChange,
  setCampaignVariables,
  handleCallWorkflowVoiceResponseChange,
  handleCallWorkflowForwardingNumber,
  removeCallWorkflow,
  addCallWorkflow,
  setCallTransferTemplateModalActive,
  isUsingATemplate,
  systemPhrase,
  businessType,
  stepState,
  showCallTransferringModal,
  handleStepTransition,
  stepLoading,
}) => {
  return (
    <>
      <div className={`${classes.box} ${classes.modal} ${showCallTransferringModal && classes.active}`}>
        <div
          className={classes.close}
          onClick={() => {
            if (
              callWorkflows.some(
                workflow =>
                  !workflow.description ||
                  !workflow.voiceResponse ||
                  !workflow.workflowForwardingNumber ||
                  !workflow.country.name ||
                  workflow.workflowForwardingNumber.length < 6
              )
            ) {
              notification.error({
                message: 'Please fill out all workflows, or remove empty workflows',
                duration: 1,
              });
              return;
            }
            setShowCallTransferringModal(false);
          }}
        >
          &times;
        </div>
        <Fade duration={1000} bottom>
          <>
            <h3 className={classes.heading} style={{ textAlign: 'center' }}>
              Call Transferring
            </h3>

            <div className={classes.subtitle}>
              Describe when you want your receptionist to transfer calls during a conversation. For example, you can
              program your receptionist to transfer the call to the billing department anytime the caller asks for a
              refund or receipt.
            </div>

            {callWorkflows.map((phoneWorkflow, index) => (
              <div
                key={index}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginBottom: '1rem',
                  border: '2px dashed #ccc',
                  borderRadius: '10px',
                  padding: '1rem',
                }}
              >
                <label className={classes.label}>Trigger</label>
                <div className={classes.subtitle} style={{ marginBottom: '10px' }}>
                  Describe the scenario in which you would like to transfer the call.
                </div>

                {isCampaign ? (
                  <TextEditor
                    html={phoneWorkflow.description}
                    variables={campaignVariables}
                    onChange={(value: string, variables: any) => {
                      handleCallWorkflowDescriptionChange(
                        {
                          target: { value },
                        } as ChangeEvent<HTMLTextAreaElement>,
                        index
                      );
                      setCampaignVariables(variables);
                    }}
                    placeholder='e.g. Transfer the caller to the billing department. Execute whenever caller asks for a refund or receipt.'
                  />
                ) : (
                  <textarea
                    style={{ marginBottom: '16px', fontFamily: 'Quicksand, sans-serif' }}
                    rows={4}
                    className={`${classes.input} ${classes.textarea}`}
                    value={phoneWorkflow.description}
                    required
                    onChange={e => handleCallWorkflowDescriptionChange(e, index)}
                    placeholder='e.g. Transfer the caller to the billing department. Execute whenever caller asks for a refund or receipt.'
                  />
                )}

                <label className={classes.label}>Voice Response</label>
                <div className={classes.subtitle} style={{ marginBottom: '10px' }}>
                  Enter the response to be spoken by the phone receptionist right before the call is transferred.
                </div>

                {isCampaign ? (
                  <TextEditor
                    html={phoneWorkflow.voiceResponse}
                    variables={campaignVariables}
                    onChange={(value: string, variables: any) => {
                      handleCallWorkflowVoiceResponseChange(
                        {
                          target: { value },
                        } as ChangeEvent<HTMLTextAreaElement>,
                        index
                      );
                      setCampaignVariables(variables);
                    }}
                    placeholder='e.g. Sounds good, I am transferring you to the billing department right now. Please hold.'
                  />
                ) : (
                  <textarea
                    style={{ marginBottom: '16px', fontFamily: 'Quicksand, sans-serif' }}
                    rows={4}
                    className={`${classes.input} ${classes.textarea}`}
                    value={phoneWorkflow.voiceResponse}
                    required
                    onChange={e => handleCallWorkflowVoiceResponseChange(e, index)}
                    placeholder='e.g. Sounds good, I am transferring you to the billing department right now. Please hold.'
                  />
                )}

                <label className={classes.label}>Phone Number</label>
                <div className={classes.subtitle} style={{ marginBottom: '10px' }}>
                  Enter the phone number the receptionist should call when this scenario is triggered.
                </div>

                <PhoneInput
                  placeholder='Enter Phone Number'
                  country='us'
                  value={phoneWorkflow.workflowForwardingNumber}
                  preferredCountries={['us', 'ca', 'gb']}
                  autoFormat={true}
                  containerStyle={{ marginBottom: '1rem' }}
                  inputStyle={{ height: '30px', fontSize: '14px' }}
                  inputClass={`${classes.input} font-medium`}
                  onChange={(value: string) =>
                    handleCallWorkflowForwardingNumber(value.startsWith('+') ? value : `+${value}`, index)
                  }
                />

                <button
                  type='button'
                  style={{
                    background: 'rgb(237 35 35)',
                    color: '#fff',
                    border: 'none',
                    height: '36px',
                    borderRadius: '4px',
                    cursor: 'pointer',
                  }}
                  onClick={e => removeCallWorkflow(e, index)}
                >
                  Remove
                </button>
              </div>
            ))}

            <button onClick={addCallWorkflow} className={classes.whiteButton} style={{ maxWidth: '300px' }}>
              + Add Call Transferring Workflow?
            </button>
            {callWorkflows.length !== 0 && (
              <button
                className={`${classes.whiteButton} ${classes.flexCentered}`}
                style={{
                  marginTop: '1rem',
                  width: 'max-content',
                  maxWidth: 'max-content',
                }}
                onClick={event => {
                  event.preventDefault(); // Prevent the button from scrolling up
                  setCallTransferTemplateModalActive(true);
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    style={{
                      strokeWidth: '1.5px',
                      height: '16px',
                      width: '16px',
                      marginRight: '8px',
                    }}
                    viewBox='0 0 24 24'
                    strokeWidth='1.5'
                    stroke='currentColor'
                    aria-hidden='true'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      d='M2.25 12.75V12A2.25 2.25 0 014.5 9.75h15A2.25 2.25 0 0121.75 12v.75m-8.69-6.44l-2.12-2.12a1.5 1.5 0 00-1.061-.44H4.5A2.25 2.25 0 002.25 6v12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9a2.25 2.25 0 00-2.25-2.25h-5.379a1.5 1.5 0 01-1.06-.44z'
                    ></path>
                  </svg>
                  {isUsingATemplate && systemPhrase ? `Using ${businessType} Template` : 'Use a Template'}
                </div>
              </button>
            )}

            <Button
              type='primary'
              onClick={async () => {
                if (
                  callWorkflows.some(
                    workflow =>
                      !workflow.description ||
                      !workflow.voiceResponse ||
                      !workflow.workflowForwardingNumber ||
                      !workflow.country.name ||
                      workflow.workflowForwardingNumber.length < 6
                  )
                ) {
                  notification.error({
                    message: 'Please fill out all workflows, or remove empty workflows',
                    duration: 1,
                  });
                  return;
                }
                await handleStepTransition(stepState + 1);
                setShowCallTransferringModal(false);
              }}
              className={classes.button + ' ' + classes.centered}
              style={{ marginTop: '2rem' }}
              loading={stepLoading}
            >
              Continue
            </Button>
          </>
        </Fade>
      </div>
    </>
  );
};

export default Step7A;
