import React, { useEffect, useState } from 'react';
import { Button, Spin, message, Tooltip, Badge } from 'antd';
import { CheckCircleOutlined, LeftOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Repeat, X, InfoIcon, Sparkles, AlertTriangle, MessageSquare, Lightbulb, Check } from 'lucide-react';
import { getUser, generateSuggestions, markSuggestionResolved } from '@/api/user';
import { useMobileDeviceCheck } from 'src/common/hooks/useMobile';

interface Suggestion {
  label: string;
  issue?: string;
  suggestions: string;
  id?: string;
}

interface FeatureRecommendation {
  featureTitle: string;
  description: string;
  usage: string;
}

interface SuggestionData {
  suggestions: Suggestion[];
  featureRecommendations: FeatureRecommendation[];
  generationCount?: number;
  lastGenerationDate?: string;
}

const SuggestionsPanel: React.FC = () => {
  const [visible, setVisible] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [generateCount, setGenerateCount] = useState(0);
  const dailyLimit = 5;
  const { isMobile } = useMobileDeviceCheck();

  const { data, isLoading, refetch } = useQuery<any>({
    queryKey: ['receptionist'],
    queryFn: getUser,
  });

  const [suggestions, setSuggestions] = useState<SuggestionData>({
    suggestions: [],
    featureRecommendations: [],
  });

  useEffect(() => {
    if (data?.suggestions) {
      setSuggestions(data.suggestions);
    }

    if (data?.suggestions?.generationCount && data?.suggestions?.lastGenerationDate) {
      const currentDate = new Date().toISOString().split('T')[0];
      if (currentDate === data.suggestions.lastGenerationDate) {
        setGenerateCount(data.suggestions.generationCount);
      }
    }
  }, [data]);

  const removeSuggestionMutation = useMutation({
    mutationFn: markSuggestionResolved,
    onSuccess: () => {
      refetch();
      message.success('Suggestion marked as resolved!');
    },
    onError: () => {
      message.error('Failed to resolve suggestion. Please try again.');
    },
  });

  const handleResolveIssue = async (suggestionId: string | undefined) => {
    if (!suggestionId) {
      return;
    }
    try {
      await removeSuggestionMutation.mutateAsync({ suggestionId });
    } catch (error) {
      console.error('Error resolving suggestion:', error);
    }
  };

  const togglePanel = () => {
    setVisible(!visible);
  };

  const handleGenerateSuggestions = async () => {
    if (generateCount >= dailyLimit) {
      message.warning(`Daily limit of ${dailyLimit} suggestions generations reached. Try again tomorrow!`);
      return;
    }

    setIsGenerating(true);
    try {
      const newSuggestions = await generateSuggestions();
      setSuggestions(newSuggestions as any);
      setGenerateCount(prev => prev + 1);
      message.success('Generated new suggestions successfully!');
    } catch (error) {
      console.error('Error generating suggestions:', error);
      message.error('Failed to generate suggestions. Please try again later.');
    } finally {
      setIsGenerating(false);
    }
  };

  const activeIssuesCount = suggestions.suggestions?.length + suggestions.featureRecommendations?.length;

  // Card component example
  const SuggestionCard = ({ suggestion, onResolve }: any) => (
    <div className='bg-white rounded-xl border border-gray-100 shadow-sm hover:shadow-md transition-all duration-200 mb-4'>
      {/* Header Section */}
      <div className='flex items-start justify-between p-5 border-b border-gray-50'>
        <div className='flex items-end gap-3'>
          <div className='p-2 bg-blue-50 rounded-lg flex items-center justify-center'>
            <MessageSquare className='w-5 h-5 text-blue-500' />
          </div>
          <h3 className='text-lg font-semibold text-gray-900'>{suggestion.label}</h3>
        </div>

        <Tooltip title={'Mark as resolved'}>
          <button
            onClick={onResolve}
            className='p-2 text-gray-400 hover:text-red-500 hover:bg-red-50 rounded-full transition-all duration-200'
          >
            <Check className='w-4 h-4' />
          </button>
        </Tooltip>
      </div>

      {/* Issue Section */}
      {suggestion.issue && (
        <div className='px-5 pt-4'>
          <div className='p-3 bg-red-50 border border-red-100 rounded-lg'>
            <div className='flex items-start gap-2'>
              <AlertTriangle className='w-4 h-4 text-red-500 mt-0.5 flex-shrink-0' />
              <p className='text-sm text-red-600'>{suggestion.issue}</p>
            </div>
          </div>
        </div>
      )}

      {/* Suggestion Section */}
      <div className='p-5'>
        <div className='flex items-start gap-3'>
          <div className='p-2 bg-amber-50 rounded-lg flex-shrink-0'>
            <Lightbulb className='w-4 h-4 text-amber-500' />
          </div>
          <div className='flex-1'>
            <h4 className='text-sm font-semibold text-gray-700 mb-2'>Suggestion</h4>
            <p className='text-sm text-gray-600 leading-relaxed'>{suggestion.suggestions}</p>
          </div>
        </div>
      </div>
    </div>
  );

  // Feature Recommendation Card
  const FeatureCard = ({ feature }: { feature: any }) => (
    <div className='mb-4 rounded-lg bg-white border border-gray-200 hover:shadow-lg transition-all duration-200 overflow-hidden'>
      <div className='p-5'>
        {/* Header */}
        <div className='flex items-center gap-3'>
          <div className='p-2 rounded-lg bg-blue-50'>
            <Sparkles className='w-5 h-5 text-blue-500' />
          </div>
          <h4 className='text-lg font-semibold text-gray-900'>{feature.featureTitle}</h4>
        </div>

        {/* Description */}
        <div className='mt-4 pl-12'>
          <p className='text-gray-600 text-sm leading-relaxed'>{feature.description}</p>
        </div>

        {/* Usage Section */}
        <div className='mt-4 p-4 bg-gray-50 rounded-lg border border-gray-100'>
          <div className='flex items-start gap-2'>
            <InfoIcon className='w-4 h-4 text-gray-400 flex-shrink-0 mt-0.5' />
            <p className='text-sm text-gray-500'>{feature.usage}</p>
          </div>
        </div>
      </div>
    </div>
  );

  const suggestionsCount = suggestions?.suggestions?.length + suggestions?.featureRecommendations?.length;

  return (
    <div className='relative'>
      {/* Sticky Button */}
      <div
        className={`fixed ${isMobile ? 'top-1/4' : 'top-1/3'} right-0 z-[9999] flex items-center bg-white justify-center text-white rounded-l-lg px-3 py-2 cursor-pointer transition-transform duration-300 shadow-lg ${
          visible ? 'translate-x-full' : ''
        }`}
        onClick={togglePanel}
      >
        {visible ? (
          <LeftOutlined />
        ) : (
          <div className='relative z-[9999] bg-white flex flex-col items-center justify-center space-y-2'>
            {/* Ping Animation */}
            {suggestionsCount > 0 && (
              <div className='absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 animate-ping'>
                <div className='w-4 h-4 bg-red-500 rounded-full opacity-75'></div>
              </div>
            )}

            {/* Red Badge with Notification */}
            <div className='relative bg-red-100 rounded-lg p-2 flex items-center justify-center w-12 h-12'>
              <span className='text-red-500 text-xl font-bold'>i</span>
              <div className='absolute -top-2 -right-2 bg-red-500 text-white text-xs font-bold w-5 h-5 flex items-center justify-center rounded-full'>
                {suggestionsCount || 0}
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Main Panel */}
      <div
        className={`fixed top-0 right-0 z-[9999] h-full w-[400px] bg-white shadow-xl transition-transform duration-300 transform ${
          visible ? 'translate-x-0' : 'translate-x-full'
        }`}
      >
        {/* Header */}
        <div className='p-4 border-b bg-button-success'>
          <div className='flex items-center justify-between'>
            <div className='flex items-center space-x-2'>
              <span className='text-xl font-bold text-white'>AI Suggestions</span>
              <Badge count={activeIssuesCount || 0} className='ml-2' />
            </div>
            <Button type='text' icon={<X className='w-5 h-5 text-white' />} onClick={togglePanel} />
          </div>
          <p className='text-blue-100 mb-0 text-sm'>
            {activeIssuesCount > 0
              ? `We found ${activeIssuesCount} area${activeIssuesCount > 1 ? 's' : ''} for improvement`
              : 'Your AI assistant is well configured!'}
          </p>
        </div>

        {/* Generate Button Section */}
        <div className='p-4 border-b bg-gray-50'>
          <Tooltip
            title={
              generateCount >= dailyLimit
                ? 'Daily limit reached. Try again tomorrow!'
                : 'Generate new suggestions based on your current settings'
            }
          >
            <Button
              type='primary'
              block
              onClick={handleGenerateSuggestions}
              loading={isGenerating}
              disabled={isGenerating || generateCount >= dailyLimit}
              icon={<Repeat className='w-4 h-4 mr-2' />}
            >
              {generateCount >= dailyLimit
                ? 'Daily Limit Reached'
                : `${activeIssuesCount ? 'Refresh' : 'Generate'} Suggestions (${generateCount}/${dailyLimit})`}
            </Button>
          </Tooltip>
        </div>

        {/* Content Area */}
        <div className='overflow-y-auto h-[calc(100vh-200px)] p-4'>
          {isLoading || isGenerating ? (
            <div className='flex flex-col items-center justify-center h-full space-y-4'>
              <Spin size='large' />
              <p className='text-gray-500'>
                {isGenerating ? 'Generating new suggestions...' : 'Loading suggestions...'}
              </p>
            </div>
          ) : activeIssuesCount === 0 ? (
            <div className='text-center p-8'>
              <div className='bg-gray-50 p-8 rounded-lg'>
                <CheckCircleOutlined className='text-4xl text-green-500 mb-4' />
                <h3 className='text-xl font-semibold mb-2'>All Clear!</h3>
                <p className='text-gray-600'>Your AI assistant is optimally configured. Great job!</p>
              </div>
            </div>
          ) : (
            <>
              {(!suggestionsCount || suggestionsCount === 0) && <>No suggestions!</>}
              {/* Suggestions Section */}
              {suggestions?.suggestions?.length > 0 && (
                <div className='mb-6'>
                  <h3 className='text-lg font-semibold mb-4'>Configuration Suggestions</h3>
                  {suggestions.suggestions.map((suggestion, index) => (
                    <SuggestionCard
                      key={index}
                      suggestion={suggestion}
                      onResolve={() => handleResolveIssue(suggestion?.id)}
                      isResolved={false}
                    />
                  ))}
                </div>
              )}

              {/* Feature Recommendations Section */}
              {suggestions?.featureRecommendations?.length > 0 && (
                <div className='mt-6'>
                  <h3 className='text-lg font-semibold mb-4'>Recommended Features</h3>
                  {suggestions.featureRecommendations.map((feature, index) => (
                    <FeatureCard key={index} feature={feature} />
                  ))}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default SuggestionsPanel;
