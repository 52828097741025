import { TextLog } from '@/types/TCRM';
import { List, Typography, Space, Card } from 'antd';
import { MessageCircle, ExternalLink } from 'lucide-react';

const { Text, Link } = Typography;

// URL detection regex
const urlRegex = /(https?:\/\/[^\s]+)/g;

const TextLogsView: React.FC<{ textLogs: TextLog[] }> = ({ textLogs }) => {
  const renderMessageWithLinks = (message: string) => {
    const parts = message.split(urlRegex);
    return parts.map((part, index) => {
      if (part.match(urlRegex)) {
        return (
          <Link key={index} href={part} target='_blank' className='text-blue-500 hover:text-blue-600'>
            <Space size={2}>
              {part}
              <ExternalLink size={12} />
            </Space>
          </Link>
        );
      }
      return part;
    });
  };

  return (
    <List
      dataSource={textLogs}
      renderItem={text => (
        <List.Item>
          <Card className='w-full bg-gray-50 hover:bg-gray-100 transition-colors duration-200' bordered={false}>
            <Space direction='vertical' className='w-full' size={1}>
              <div className='flex items-start gap-3'>
                <div className='mt-1'>
                  <div className='h-8 w-8 rounded-full bg-blue-100 text-blue-600 flex items-center justify-center'>
                    <MessageCircle className='text-lg' />
                  </div>
                </div>
                <div className='flex-1'>
                  <Text className='text-sm whitespace-pre-wrap'>{renderMessageWithLinks(text.text)}</Text>
                  <div className='mt-2'>
                    <Text type='secondary' className='text-xs'>
                      {new Date(text.date).toLocaleString('en-US', {
                        weekday: 'short',
                        month: 'short',
                        day: 'numeric',
                        year: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: true,
                      })}
                    </Text>
                  </div>
                </div>
              </div>
            </Space>
          </Card>
        </List.Item>
      )}
      pagination={{
        pageSize: 10,
        size: 'small',
        total: textLogs?.length,
        showTotal: total => `${total} messages`,
      }}
    />
  );
};

export default TextLogsView;
