import { Routes, Route } from 'react-router-dom';
import NoviPrivateRoute from './private';
import { NoviDashboard } from '../pages/Dashboard/NoviDashboard';
import PaymentSuccess from '../pages/PaymentSuccessful/PaymentSuccess';

const NoviRoutes: React.FC = () => {
  return (
    <Routes>
      <Route element={<NoviPrivateRoute />}>
        <Route element={<NoviDashboard />} path='/' />
        <Route element={<PaymentSuccess />} path='/payment-success' />
      </Route>
    </Routes>
  );
};

export default NoviRoutes;
