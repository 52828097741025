// import { useLocation, useOutletContext } from 'react-router-dom';
import { getReceptionistReseller, getResellerLogo } from '@/api/unbrandedReceptionist';
import { NoviDashboard } from '../../NoviDashboard';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { getSelectedCampaign } from '@/store/novi/user/selector';
import { useEffect } from 'react';
import { useLocation, useOutletContext } from 'react-router-dom';
import { useAppDispatch } from '@/store/hooks/useAppDispatch';
import { setReseller } from '@/store/reseller/slice';
import { getResellerData } from '@/store/reseller/selector';

function WhitelabelCampaignDashboard() {
  const outletContext = useOutletContext<any>();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const currentReseller = useAppSelector(getResellerData);
  const selectedCampaign = useAppSelector(getSelectedCampaign);
  const {
    // from,
    debrand: debrandFromLocation,
    // resellerCode: resellerCodeFromLocation,
  } = (location.state as {
    from: string;
    debrand: boolean;
    resellerCode: string;
  }) || {
    from: '/',
    debrand: false,
  };

  const debrand = outletContext?.debrand || debrandFromLocation;
  // const resellerCode = outletContext?.resellerCode || resellerCodeFromLocation;

  const { data: resellerData } = useQuery({
    queryKey: ['getResellerInfoByReceptionist'],
    queryFn: () => getReceptionistReseller({ resellerEmail: selectedCampaign.campaign_user_email as string }),
    enabled: !!(debrand && selectedCampaign.campaign_user_email && !currentReseller.email_address),
  });

  const { mutate: logoMutation } = useMutation({
    mutationKey: ['reseller-logo'],
    mutationFn: getResellerLogo,
    onSuccess(data) {
      if (!data) return;
      const url = URL.createObjectURL(data);
      dispatch(setReseller({ ...currentReseller, logoUrl: url }));
    },
  });

  useEffect(() => {
    if (resellerData?.reseller) {
      dispatch(setReseller(resellerData.reseller));
      if (resellerData?.reseller.logo_s3) {
        logoMutation({ key: resellerData.reseller.logo_s3 });
      }
    }
  }, [resellerData]);

  return <NoviDashboard isStandaloneCampaign={true} />;
}

export default WhitelabelCampaignDashboard;
