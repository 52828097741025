import React from 'react';
import Fade from 'react-reveal/Fade';
import classes from '../RegisterPageV3.module.css';
import { Button } from 'antd';

const Step2 = ({
  stepState,
  languages,
  handleLanguageChange,
  selectedLanguages,
  handleStepTransition,
  step3Validation,
  stepLoading,
}) => {
  return (
    <>
      <div className={classes.box} style={{}}>
        <Fade duration={1000} bottom>
          <h3
            className={classes.heading}
            style={{
              textAlign: 'center',
            }}
          >
            Select Languages (Optional)
          </h3>

          <div
            className={classes.subtitle}
            style={{
              textAlign: 'center',
            }}
          >
            Select the languages you want your receptionist to speak.
          </div>

          <Fade duration={1000} bottom>
            <div className={classes.themeChooser + ' ' + classes.languagesChooser}>
              {languages.map(language => (
                <div
                  onClick={() => handleLanguageChange(language)}
                  className={classes.themeBox + ' ' + (selectedLanguages.includes(language) ? classes.active : '')}
                >
                  {language}
                </div>
              ))}
            </div>
          </Fade>
        </Fade>

        <Button
          type='primary'
          onClick={() => handleStepTransition(stepState + 1, step3Validation)}
          className={classes.button + ' ' + classes.centered}
          style={{ marginTop: '2rem' }}
          loading={stepLoading}
        >
          Continue
        </Button>
      </div>
    </>
  );
};

export default Step2;
