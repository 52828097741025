import { forwardRef, useEffect, useState } from 'react';
import { Button, Card, Form, Space, InputNumber, Radio, FormInstance, Typography, Tooltip } from 'antd';
import { QuestionCircleOutlined, SaveOutlined, CloseOutlined } from '@ant-design/icons';

const { Title } = Typography;

interface TextCampaignFormProps {
  initialValues?: any;
  onSubmit?: (values: any) => void;
  onEdit?: (values: any) => void;
  onCancel?: () => void;
  isLoading: boolean;
  form?: FormInstance;
  mode?: 'create' | 'edit';
  showCancelBtn?: boolean;
}

const TextScheduleForm = forwardRef<FormInstance, TextCampaignFormProps>(
  ({ onSubmit, onEdit, onCancel, isLoading, form: parentForm, initialValues, mode = 'create', showCancelBtn }, ref) => {
    const [form] = Form.useForm();
    const formInstance = parentForm || form;
    const [triggerType, setTriggerType] = useState<string>(initialValues?.schedule?.type || 'immediate');

    useEffect(() => {
      if (initialValues && Object.keys(initialValues).length > 0) {
        formInstance.resetFields();
        formInstance.setFieldsValue({
          name: initialValues.name,
          description: initialValues.description,
          message: initialValues.message,
          schedule: {
            type: initialValues.schedule?.type || 'immediate',
            delay: initialValues.schedule?.delay,
            customTime: initialValues.schedule?.customTime,
          },
        });
        setTriggerType(initialValues.schedule?.type || 'immediate');
      }
    }, [initialValues, formInstance]);

    const handleSubmit = (values: any) => {
      if (mode === 'edit' && onEdit) {
        onEdit(values);
      } else if (onSubmit) {
        onSubmit(values);
      }
    };

    const handleReset = () => {
      formInstance.resetFields();
      setTriggerType('immediate');
      if (initialValues) {
        formInstance.setFieldsValue(initialValues);
        setTriggerType(initialValues.schedule?.type || 'immediate');
      }
    };

    return (
      <Card bordered={false} className='shadow-sm'>
        <div className='flex justify-between items-center mb-6'>
          <Title level={4} className='!mb-0'>
            {mode === 'edit' ? 'Edit Text Campaign' : 'Create Text Campaign'}
          </Title>
          {onCancel && showCancelBtn && (
            <Button icon={<CloseOutlined />} onClick={onCancel}>
              Cancel
            </Button>
          )}
        </div>

        <Form
          ref={ref}
          form={formInstance}
          layout='vertical'
          onFinish={handleSubmit}
          className='space-y-6'
          initialValues={{
            schedule: { type: 'immediate' },
            ...initialValues,
          }}
        >
          <Card title='Delivery Settings' className='shadow-sm'>
            <Form.Item
              name={['type']}
              label='When should this campaign be triggered?'
              rules={[{ required: true, message: 'Please select when to send messages' }]}
            >
              <Radio.Group onChange={e => setTriggerType(e.target.value)} value={triggerType}>
                <Radio value='immediate'>Immediately</Radio>
                <Radio value='delayed'>After a specific delay</Radio>
              </Radio.Group>
            </Form.Item>

            {triggerType === 'delayed' && (
              <Form.Item
                name={['delay', 'value']}
                label={
                  <Space>
                    <span>Delay Duration</span>
                    <Tooltip title='How long to wait before sending messages'>
                      <QuestionCircleOutlined className='text-gray-400' />
                    </Tooltip>
                  </Space>
                }
                rules={[{ required: true, message: 'Please specify delay duration' }]}
              >
                <InputNumber min={1} placeholder='Enter number of hours' addonAfter='hours' />
              </Form.Item>
            )}
          </Card>

          <div className='flex justify-end gap-3'>
            <Button onClick={handleReset}>Reset Form</Button>
            <Button type='primary' htmlType='submit' loading={isLoading} icon={<SaveOutlined />}>
              {mode === 'edit' ? 'Update Schedule' : 'Create Schedule'}
            </Button>
          </div>
        </Form>
      </Card>
    );
  }
);

TextScheduleForm.displayName = 'TextScheduleForm';

export default TextScheduleForm;
