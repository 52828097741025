import React from 'react';
import Fade from 'react-reveal/Fade';
import AIModels from '../../../constants/voiceModels';
import classes from '../Register.module.css';
import posthog from 'posthog-js';
import { Button, Col, Row } from 'antd';
import { PauseCircle, PlayCircle } from '@mui/icons-material';

interface Step0Props {
  stepState: number;
  setStepState: (step: number) => void;
  isCampaign?: boolean;
  setVoiceModel: (model: string) => void;
  setVoiceProvider: (provider: string) => void;
  voiceModel: string;
  soundPlayerRef: React.MutableRefObject<HTMLAudioElement | null>;
  handleStepTransition: (
    nextStep: number,
    validationFn?: () => Promise<boolean>,
    stateMutationFn?: () => Promise<void>
  ) => Promise<void>;
  step1Validation: () => Promise<boolean>;
  step1StateMutation: () => Promise<void>;
  stepLoading: boolean;
  handlePlayPause: (model: any) => void;
  playingModel: string | null;
}

const Step0: React.FC<Step0Props> = ({
  stepState,
  isCampaign,
  setVoiceModel,
  setVoiceProvider,
  voiceModel,
  handleStepTransition,
  step1Validation,
  step1StateMutation,
  stepLoading,
  handlePlayPause,
  playingModel,
}) => {
  return (
    <>
      <div
        style={{
          color: 'rgb(40, 42, 48)',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          fontFamily: 'Roboto, sans-serif',
        }}
      >
        <Fade duration={1000} bottom>
          <>
            <h3
              style={{
                fontSize: 42,
                marginTop: '0.5rem',
                marginBottom: '0.5rem',
                textAlign: 'center',
                fontFamily: 'Roboto, sans-serif',
              }}
            >
              Build your {isCampaign ? 'Campaign' : 'AI Receptionist'} in Minutes!
            </h3>

            <div className={classes.box}>
              <Fade duration={1000} bottom>
                <>
                  <div
                    className={classes.subtitle}
                    style={{
                      textAlign: 'center',
                      fontSize: '1.5rem',
                    }}
                  >
                    Start by choosing a voice.
                  </div>

                  <Fade duration={1000} bottom>
                    <Row gutter={[12, 12]} className='justify-center mt-20'>
                      {AIModels.slice(0, 10).map(model => (
                        <Col lg={8} xs={12}>
                          <div
                            className={'mb-14 voice-card  rounded-t-xl ' + (voiceModel === model.value ? 'active' : '')}
                            style={{
                              background: voiceModel === model.value ? 'rgb(230, 232, 237)' : undefined,
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              setVoiceModel(model.value);
                              setVoiceProvider(model.provider);
                              posthog.capture('voice_selected');
                            }}
                          >
                            <div className='h-20 rounded-t-xl' style={{ backgroundColor: model.background }}>
                              <img
                                style={{
                                  height: '9rem',
                                  position: 'absolute',
                                  top: '-4rem',
                                  left: '50%',
                                  transform: 'translateX(-50%)',
                                }}
                                src={model.icon}
                              />
                            </div>
                            <div className='p-2 text-center border-x-2 border-b-2'>
                              <p className='mb-1 text-base'>{model.name}</p>
                              <button
                                onClick={e => {
                                  e.stopPropagation();
                                  handlePlayPause(model);
                                }}
                                className='play-pause-button'
                              >
                                {playingModel === model.value ? <PauseCircle /> : <PlayCircle />}
                              </button>
                            </div>
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </Fade>
                </>
              </Fade>
              <Button
                type='primary'
                onClick={() => handleStepTransition(stepState + 1, step1Validation, step1StateMutation)}
                className={classes.button + ' ' + classes.centered}
                style={{ marginTop: '2rem' }}
                loading={stepLoading}
              >
                Continue
              </Button>
            </div>
          </>
        </Fade>
      </div>
    </>
  );
};

export default Step0;
