import { API_WORKFLOW_TYPES } from 'src/constants';

export const API_TEMPLATE = {
  id: 1,
  name: 'Get MacBook Details',
  workflowType: API_WORKFLOW_TYPES.scenario,
  scenario: 'Trigger this action whenever the caller asks anything about the MacBook.',
  webhookInformation: {
    url: 'https://api.restful-api.dev/objects/7',
    method: 'GET',
    headers: [
      { key: 'Authorization', value: 'Bearer <API_KEY>' },
      { key: 'Content-Type', value: 'application/json' },
    ],
    responseData: [
      { variableName: 'mackBookName', jsonPath: '$.name', prompt: 'The MackBook name is {{MackBookName}}.' },
      { variableName: 'price', jsonPath: '$.data.price', prompt: 'The Price of MackBook is ${{price}}.' },
      { variableName: 'data', jsonPath: '$.data', prompt: 'Other details of MackBook is ${{data}}.' },
    ],
  },
  isQueryParams: true,
  extractedCallInfo: [
    { name: 'hardDisk', type: 'string', description: 'Hard disk size for MacBook.' },
    { name: 'core', type: 'string', description: 'Core size for MacBook.' },
  ],
  timeoutSeconds: 20,
  speechToEmit: 'Fetching MackBook details. Please hold on for a moment.',
  isRepeatable: true,
};

export const API_TEMPLATE_KNOWLEDGE_BASE_LOADER = {
  id: 1,
  name: 'Get MacBook Details',
  prompt: 'New Update Price of MackBook.',
  workflowType: API_WORKFLOW_TYPES.knowledgeBaseLoader,
  scenario: null,
  timeoutSeconds: 10,
  webhookInformation: {
    url: 'https://api.restful-api.dev/objects/7',
    method: 'GET',
    headers: [
      { key: 'Authorization', value: 'Bearer <API_KEY>' },
      { key: 'Content-Type', value: 'text' },
    ],
  },
};
