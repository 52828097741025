import { useAppDispatch } from '@/store/hooks/useAppDispatch';
import { closeModal } from '@/store/modal/slice';
import { UseMutationResult } from '@tanstack/react-query';
import { MODAL_IDS } from 'src/enums/EModal';
import { CustomModal } from '../CustomModal';
import { Button, Form, Input, Spin } from 'antd';
import { MIN_10_WORDS_REGEX } from 'src/constants';

const CancelSubReasonModal = ({
  mutation,
  redirecting = false,
}: {
  mutation: UseMutationResult<{}, Error, any, unknown>;
  redirecting?: boolean;
}) => {
  const [form] = Form.useForm<{
    reason: string;
  }>();

  const dispatch = useAppDispatch();

  const handleSubmit = async (values: { reason: string }) => {
    try {
      await mutation.mutateAsync({ reason: values.reason });
    } catch (e) {
      console.error(e);
    }
  };

  const handleExpertCall = () => {
    window.open('https://calendly.com/d/crj3-htt-fw8/ai-front-desk-pre-cancellation-consultation', '_blank');
  };

  const baseClass = 'cancel-sub-reason-modal';

  return (
    <CustomModal
      width={'50%'}
      title={'What was our product missing?'}
      modalId={MODAL_IDS.CANCEL_REASON_MODAL}
      onClose={() => {
        dispatch(closeModal());
      }}
      footer={null}
    >
      <Spin
        size='large'
        className='text-yellow-500 font-bold !text-lg'
        tip='Redirecting to Cancel Page'
        spinning={redirecting}
      >
        <Form
          form={form}
          onFinish={handleSubmit}
          layout='vertical'
          name='cancel-sub-reason-form'
          className={`${baseClass}`}
        >
          <Form.Item
            label='Reason'
            name='reason'
            className={`${baseClass}__input_item`}
            rules={[
              { required: true, message: 'Please input your reason!' },
              {
                // regex to check if the input contains minimum 10 words
                pattern: MIN_10_WORDS_REGEX,
                message: 'Please enter at least 10 words',
              },
            ]}
          >
            <Input placeholder='Reason' />
          </Form.Item>
          <p className='font-bold text-blue-800 text-lg'>Our whole team reads every answer</p>

          <div className='mt-6 mb-4 p-4 bg-blue-50 rounded-lg'>
            <p className='text-blue-800 mb-2'>
              Having trouble finding what you need? Let us help! Schedule a one-on-one call with our product expert to
              get personalized guidance.
            </p>
            <Button type='default' onClick={handleExpertCall} className='bg-white hover:bg-blue-50'>
              Book a Call with Expert
            </Button>
          </div>

          <Form.Item>
            <Button
              htmlType='submit'
              loading={mutation.isPending}
              type='primary'
              className={`${baseClass}__submit-button mt-2`}
            >
              Proceed to cancel
            </Button>
            <p className='text-gray-500 text-sm mt-2'>
              Please wait, after this you'll be redirected to complete the cancellation process.
            </p>
          </Form.Item>
        </Form>
      </Spin>
    </CustomModal>
  );
};

export default CancelSubReasonModal;
