import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '..';

const appState = (state: RootState) => state.app;

export const getAppLoadingStatus = createSelector([appState], appState => appState.isLoading);

export const getAppTheme = createSelector([appState], appState => appState.dashboardTheme);

export const getFeatureFlags = createSelector([appState], appState => appState.featureFlags);
