import React, { useState } from 'react';
import { Badge, Button, Card, Tooltip } from 'antd';
import { ChatBubbleOutline, DiamondOutlined, MailOutline, WorkspacePremiumOutlined, Edit } from '@mui/icons-material';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { getLoggedInUser } from '@/store/account/selector';
import { PlusOutlined } from '@ant-design/icons';
import AddScenarios from '../components/AddScenario';
import AddEmails from '../components/AddEmails';
import AddPhones from '../components/AddPhones';
import { InfoTypeScenario } from '@/interfaces/INotifications';
import PreviewEmail from '../components/PreviewEmail';
import PreviewSms from '../components/PreviewSms';
import { NotifKey } from 'src/enums/ENotifKey';
import LearnMore from '@/components/Buttons/LearnMore';
import { TUTORIALS } from 'src/enums/ETutorials';
import { getResellerData } from '@/store/reseller/selector';
import PreviewButton from '../components/PreviewButton';

interface props {
  scenarioNotif: InfoTypeScenario[];
  updateUser: (val: string) => void;
}

const ScenarioBasedNotification: React.FC<props> = ({ scenarioNotif, updateUser }) => {
  const loggedInUser = useAppSelector(getLoggedInUser);
  const [categories, setCategories] = useState<InfoTypeScenario[]>(scenarioNotif);
  const [scenarioAddClicked, setScenarioAddClicked] = useState(false);
  const [emailAddClicked, setEmailAddClicked] = useState<number | null>(null);
  const [phoneAddClicked, setPhoneAddClicked] = useState<number | null>(null);
  const [previewEmail, setPreviewEmail] = useState(false);
  const [previewSms, setPreviewSms] = useState(false);
  const currentReseller = useAppSelector(getResellerData);

  const saveEmailsFn = (val: string[], categoryIndex: number) => {
    const newCat = categories.map((item, index) => {
      let emails = item.emails;
      if (index === categoryIndex) {
        emails = val;
      }
      return {
        ...item,
        email_recipients: emails,
        emails,
      };
    });
    setCategories(newCat);
    updateUser(JSON.stringify(newCat));
  };

  const savePhonesFn = (val: string[], categoryIndex: number) => {
    const newCat = categories.map((item, index) => {
      let phones = item.phones;
      if (index === categoryIndex) {
        phones = val;
      }
      return {
        ...item,
        text_recipients: phones,
        phones,
      };
    });
    setCategories(newCat);
    updateUser(JSON.stringify(newCat));
  };

  const isDisabled = loggedInUser?.resellerClient ? false : loggedInUser.subscriptionStatus !== 'active';
  const isPhoneDisabled = loggedInUser?.resellerClient
    ? false
    : loggedInUser.subscriptionStatus !== 'active' || loggedInUser.subscriptionType?.toLocaleLowerCase() !== 'pro';

  return (
    <Card className='w-full mx-auto bg-white rounded-lg shadow-lg'>
      <div className='flex flex-col mb-4'>
        <div className='flex justify-between items-center'>
          <div className='flex items-center space-x-4'>
            <h3 className='text-lg font-semibold'>Scenario Based Notification Settings</h3>
            <LearnMore tutorialId={TUTORIALS.POST_CALL_NOTIFICATION} />
          </div>
          <div className='flex items-center space-x-4'>
            <PreviewButton
              title='Preview Email Notification'
              setPreviewEmail={setPreviewEmail}
              children={<MailOutline fontSize='small' />}
            />
            <PreviewButton
              title='Preview Text SMS Notification'
              setPreviewEmail={setPreviewSms}
              children={<ChatBubbleOutline fontSize='small' />}
            />
          </div>
        </div>
        <span>Receive email and phone notifications when any of your custom scenarios triggered.</span>
      </div>
      {categories.map((category, categoryIndex) => (
        <div key={categoryIndex}>
          <div className='my-4'>
            <h4 className='font-semibold'>{category.scenario}</h4>
            <div className='flex flex-col px-4'>
              <div className='flex items-center gap-2'>
                <Tooltip title='Included in Basic plan'>
                  <WorkspacePremiumOutlined fontSize='small' className='text-blue-500 cursor-pointer' />
                </Tooltip>
                <span className='font-semibold italic text-xs'>Send Email To:</span>
                <Edit
                  className={`text-[8px] flex items-center justify-center w-10 h-10 border-2 rounded-md p-1 
                    ${isDisabled ? 'text-gray-400 border-gray-400 cursor-not-allowed' : 'text-blue-500 border-blue-500 cursor-pointer'}`}
                  onClick={() => {
                    if (!isDisabled) {
                      setEmailAddClicked(categoryIndex);
                    }
                  }}
                />
              </div>
              <div className='flex gap-2 items-center'>
                {category.emails.map((item: string, index: number) => {
                  return <Badge key={index} count={item} className='badge-disabled' />;
                })}
                <Button
                  disabled={loggedInUser?.resellerClient ? false : loggedInUser.subscriptionStatus !== 'active'}
                  type='link'
                  icon={<PlusOutlined />}
                  onClick={() => setEmailAddClicked(categoryIndex)}
                >
                  Add Email
                </Button>
                <AddEmails
                  open={emailAddClicked === categoryIndex}
                  setOpen={val => setEmailAddClicked(val as null)}
                  emails={category.emails}
                  saveEmails={(val: string[]) => saveEmailsFn(val, categoryIndex)}
                />
              </div>
            </div>

            <div className='flex flex-col mt-2 px-4'>
              <div className='flex items-center gap-2'>
                <Tooltip title='Included in Pro plan'>
                  <DiamondOutlined fontSize='small' className='text-yellow-500 cursor-pointer' />
                </Tooltip>
                <span className='font-semibold italic text-xs'>Send Text To:</span>
                <Edit
                  className={`text-[8px] flex items-center justify-center w-10 h-10 border-2 rounded-md p-1 
                    ${isPhoneDisabled ? 'text-gray-400 border-gray-400 cursor-not-allowed' : 'text-blue-500 border-blue-500 cursor-pointer'}`}
                  onClick={() => {
                    if (!isPhoneDisabled) {
                      setPhoneAddClicked(categoryIndex);
                    }
                  }}
                />
              </div>
              <div className='flex gap-2 items-center'>
                {category.phones.map((item: string, index: number) => {
                  return (
                    <Badge
                      key={index}
                      className='badge-disabled font-medium text-xs py-1 px-2 rounded-lg hover:bg-blue-100'
                    >
                      {item}
                    </Badge>
                  );
                })}
                <Button
                  disabled={
                    loggedInUser?.resellerClient
                      ? false
                      : loggedInUser.subscriptionStatus !== 'active' ||
                        loggedInUser.subscriptionType?.toLocaleLowerCase() !== 'pro'
                  }
                  type='link'
                  icon={<PlusOutlined />}
                  onClick={() => setPhoneAddClicked(categoryIndex)}
                >
                  Add Phone
                </Button>
                <AddPhones
                  open={phoneAddClicked === categoryIndex}
                  setOpen={val => setPhoneAddClicked(val as null)}
                  phones={category.phones}
                  savePhones={(val: string[]) => savePhonesFn(val, categoryIndex)}
                />
              </div>
            </div>
          </div>
        </div>
      ))}
      <PreviewEmail
        open={previewEmail}
        setOpen={setPreviewEmail}
        disabled={loggedInUser?.resellerClient ? false : loggedInUser.subscriptionStatus !== 'active'}
        notifKey={NotifKey.Workflow}
        show={null}
        resellerName={currentReseller?.reseller_name}
        resellerLogo={currentReseller?.logoUrl}
        resellerClient={loggedInUser?.resellerClient}
      />
      <PreviewSms
        open={previewSms}
        setOpen={setPreviewSms}
        disabled={
          loggedInUser?.resellerClient
            ? false
            : loggedInUser.subscriptionStatus !== 'active' ||
              loggedInUser.subscriptionType?.toLocaleLowerCase() !== 'pro'
        }
        notifKey={NotifKey.Workflow}
        show={null}
      />
      <Button type='dashed' onClick={() => setScenarioAddClicked(true)}>
        Add Scenario
      </Button>
      <AddScenarios
        open={scenarioAddClicked}
        setOpen={setScenarioAddClicked}
        scenarios={categories}
        updateUser={updateUser}
        setCategories={setCategories}
      />
    </Card>
  );
};

export default ScenarioBasedNotification;
