import React from 'react';
import classes from '../RegisterPageV3.module.css';
import posthog from 'posthog-js';
import clsx from 'clsx';
import TextEditor from '../components/TextEditor/TextEditor';
import { Button } from 'antd';

const Step4 = ({
  setSystemPhrase,
  setCampaignVariables,
  systemPhrase,
  campaignVariables,
  businessName,
  handleStepTransition,
  step5StateMutation,
  step5Validation,
  stepLoading,
  stepState,
}) => {
  return (
    <>
      <div className={clsx(classes.box, '')}>
        <div>
          <h3
            className={classes.heading}
            style={{
              textAlign: 'center',
              marginTop: '1rem',
            }}
          >
            Greeting Phrase
          </h3>
          <div className={classes.subtitle} style={{ textAlign: 'center' }}>
            This is the opening line your receptionist will use when answering the phone. Shorter greeting phrases do
            better.
          </div>
          {React.createElement(() => {
            posthog.capture('greeting_phrase_entered');
            return null;
          })}

          <TextEditor
            html={systemPhrase}
            vvariables={campaignVariables}
            onChange={(value, variables) => {
              setSystemPhrase(value);
              setCampaignVariables(variables);
            }}
            placeholder={`e.g. Hi, welcome to ${businessName}. We're currently doing a 10% off summer sale. I can help you book an appointment or answer any questions you may have. How may I help you?`}
          />
          <Button
            type='primary'
            onClick={() => handleStepTransition(stepState + 1, step5Validation, step5StateMutation)}
            className={classes.button + ' ' + classes.centered}
            style={{ marginTop: '4rem' }}
            loading={stepLoading}
          >
            Continue
          </Button>
        </div>
      </div>
    </>
  );
};

export default Step4;
