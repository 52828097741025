import { Button, Card, notification } from 'antd';
import { OverViewCardInfo } from '../OverviewCardInfo';
import { LabelWithDescription } from '@/components/LabelWithdescription';
import { DeleteOutline, EditOutlined } from '@mui/icons-material';
import './PostCallMessage.scss';
import { useEffect, useState } from 'react';
import { CustomModal } from '@/components/CustomModal';
import PostCallMessageForm from './PostCallMessageForm';
import { RefetchOptions, QueryObserverResult, UseMutationResult } from '@tanstack/react-query';
import { MODAL_IDS } from 'src/enums/EModal';
import { useAppDispatch } from '@/store/hooks/useAppDispatch';
import { openModal } from '@/store/modal/slice';
import { deepEqual } from '@/utils/helper';
import { User } from '@/types/TAccounts';
import { TUTORIALS } from 'src/enums/ETutorials';

interface PostCallMessageInfoProps {
  data: any;
  refetch: (options?: RefetchOptions) => Promise<QueryObserverResult<User, Error>>;
  mutation: UseMutationResult<{}, Error, any, unknown>;
  deleteMutation: UseMutationResult<{}, Error, any, unknown>;
  debrand?: boolean;
}

export const PostCallMessageInfo: React.FC<PostCallMessageInfoProps> = ({ data, refetch, mutation, debrand }) => {
  const dispatch = useAppDispatch();
  const baseClass = 'phone-form';
  const [formInitialValues, setFormInitialValues] = useState<any>(null);
  const [scenarioIndex, setScenarioIndex] = useState<number>();

  let scenarios: [] = [];
  try {
    scenarios = (data?.postCallMessage && JSON.parse(data?.postCallMessage)) || [];
  } catch (err) {
    console.log(err);
  }

  useEffect(() => {
    if (!deepEqual(scenarios, formInitialValues?.scenarios)) {
      setFormInitialValues({ scenarios });
    }
  }, [scenarios, formInitialValues]);

  const handleEditScenario = (scenario: any, index: number) => {
    setFormInitialValues({ scenarios: [scenario] });
    setScenarioIndex(index);
    dispatch(openModal({ modalId: MODAL_IDS.PHONE_WORKFLOW_EDIT_MODAL }));
  };

  const handleDeleteScenario = async (index: number) => {
    try {
      const updatedScenarios = [...scenarios];
      updatedScenarios.splice(index, 1);

      await mutation.mutateAsync({ formBuilders: updatedScenarios });
      notification.success({ message: `Scenario ${index + 1} deleted successfully.` });
      refetch();
    } catch (err) {
      console.error(err);
      notification.error({ message: 'Something went wrong, please try again later!' });
    }
  };

  const getTriggerTypeLabel = (type: string) => {
    switch (type) {
      case 'every-call':
        return 'After Every Call';
      case 'duration-check':
        return 'Duration Check';
      case 'custom-scenario':
        return 'Custom Scenario';
      default:
        return type;
    }
  };

  const InfoComponent = () => {
    return (
      <>
        {scenarios.map((scenario: any, index: number) => (
          <Card
            key={index}
            className={index > 0 ? 'mt-4' : ''}
            title={
              <div className={`${baseClass}_button-container`}>
                <span className='font-bold text-base'>Scenario {index + 1}</span>
                <span>
                  <Button
                    type='primary'
                    danger
                    icon={<DeleteOutline />}
                    loading={mutation.isPending && mutation.variables?.scenarioIndex === index}
                    onClick={() => handleDeleteScenario(index)}
                  >
                    <div>Delete</div>
                  </Button>
                  <Button
                    type='primary'
                    className='ml-2'
                    icon={<EditOutlined />}
                    onClick={() => handleEditScenario(scenario, index)}
                  >
                    <div>Edit</div>
                  </Button>
                </span>
              </div>
            }
          >
            <div className='border-b pb-4'>
              <LabelWithDescription
                customClassName='mt-2'
                label='Trigger Type'
                description='When to send the text message'
              />
              <div className='pl-4 mt-2'>{getTriggerTypeLabel(scenario.triggerType)}</div>
            </div>

            {scenario.triggerType === 'duration-check' && (
              <div className='border-b pb-4'>
                <LabelWithDescription
                  customClassName='mt-2'
                  label='Duration'
                  description='Call duration threshold in seconds'
                />
                <div className='pl-4 mt-2'>{scenario.duration} seconds</div>
              </div>
            )}

            {scenario.triggerType === 'custom-scenario' && (
              <div className='border-b pb-4'>
                <LabelWithDescription
                  customClassName='mt-2'
                  label='Custom Scenario'
                  description='Specific condition for sending the message'
                />
                <div className='pl-4 mt-2'>{scenario.customScenario}</div>
              </div>
            )}

            <div className='pb-4'>
              <LabelWithDescription
                customClassName='mt-2'
                label='Text Message'
                description='Message to be sent to the user'
              />
              <div className='pl-4 mt-2 whitespace-pre-line'>{scenario.textMessage}</div>
            </div>
          </Card>
        ))}
      </>
    );
  };

  return (
    <div className={baseClass}>
      <OverViewCardInfo
        width={'50%'}
        formActionType='Add'
        form={
          <PostCallMessageForm
            mutation={mutation}
            initialValues={formInitialValues}
            refetch={refetch}
            actionType='ADD'
          />
        }
        formTitle={<span className='font-bold'>Add New Scenario</span>}
        info={<InfoComponent />}
        footer={null}
        modalId={MODAL_IDS.PHONE_WORKFLOW_MODAL}
        tutorialId={TUTORIALS.INTAKE_FORM_WORKFLOW}
        displayTutorialActionBtn={!debrand}
      />
      <CustomModal
        width={'50%'}
        key={MODAL_IDS.PHONE_WORKFLOW_EDIT_MODAL}
        title={<span className='font-bold'>Edit Scenario</span>}
        children={
          <PostCallMessageForm
            mutation={mutation}
            showAddFieldButton={false}
            initialValues={formInitialValues}
            refetch={refetch}
            actionType='EDIT'
            scenarioIndex={scenarioIndex}
          />
        }
        modalId={MODAL_IDS.PHONE_WORKFLOW_EDIT_MODAL}
        footer={null}
      />
    </div>
  );
};
