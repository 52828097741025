import { Alert, Layout, Skeleton } from 'antd';
import ResellerSider from './ResellerSider';
import { ResellerSiderItem } from 'src/enums/IResellerSiderItems';
import './ResellerDashboard.scss';
import { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import ResellerPromo from './ResellerPromo';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'src/enums/ERoutes';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { getResellerData } from '@/store/reseller/selector';
import { getResellerLogo } from '@/api/unbrandedReceptionist';
import { useQuery } from '@tanstack/react-query';
import { Loading } from '@/components/Loading';
import { useDispatch } from 'react-redux';
import { updateReseller } from '@/store/reseller/slice';
import ResellerPendingPaymentPromo from './ResellerPenfdingPaymentPromo';
import ClearPaymentModal from '@/components/ClearPaymentModal/ClearPaymentModal';
import ResellerSubCancelPromo from './ResellerSubCancelPromo';
import { setAppTheme } from '@/store/app/slice';
import { setNoviCurrentPage, setNoviSelectedCampaignId } from '@/store/novi/user/slice';

const menuToRouteMap: Record<ResellerSiderItem, string> = {
  [ResellerSiderItem.MY_RECEPTIONISTS]: AppRoutes.RESELLER_PORTAL,
  [ResellerSiderItem.EMBED]: AppRoutes.RESELLER_EMBED,
  [ResellerSiderItem.BRANDING]: AppRoutes.RESELLER_BRANDING,
  [ResellerSiderItem.TUTORIALS]: AppRoutes.RESELLER_TUTORIALS,
  [ResellerSiderItem.SUBSCRIPTION]: AppRoutes.RESELLER_SUBSCRIPTION,
  [ResellerSiderItem.TRIAL_RECEPTIONIST]: AppRoutes.TRIAL_RECEPTIONIST,
  [ResellerSiderItem.PHONE_NUMBERS]: AppRoutes.RESELLER_PHONE_NUMBERS,
  [ResellerSiderItem.IMPORT_NUMBER]: AppRoutes.RESELLER_IMPORT_NUMBER,
  [ResellerSiderItem.ACADEMY]: AppRoutes.RESELLER_ACADEMY,
  [ResellerSiderItem.OUTBOUND_ROOT]: AppRoutes.RESELLER_OUTBOUND,
  [ResellerSiderItem.OUTBOUND_MY_CAMPAIGNS]: AppRoutes.RESELLER_OUTBOUND,
  [ResellerSiderItem.OUTBOUND_BYOT]: AppRoutes.RESELLER_OUTBOUND,
};
const ResellerDashboard: FC<{
  children: ReactNode;
  activeKey: ResellerSiderItem;
  isPageLoading?: boolean;
  showHeader?: boolean;
}> = ({ children, activeKey, isPageLoading, showHeader = true }) => {
  const navigate = useNavigate();
  const currentReseller = useAppSelector(getResellerData);
  const [loading, setLoading] = useState<boolean>(true);
  const dispatch = useDispatch();
  const dashboardTheme = useAppSelector(state => state.app.dashboardTheme);

  useEffect(() => {
    if (dashboardTheme) {
      dispatch(setAppTheme(dashboardTheme));
    } else if (currentReseller?.dashboardTheme) {
      dispatch(setAppTheme(currentReseller.dashboardTheme));
    }
  }, [dashboardTheme]);

  const handleMenuSelect = (key: ResellerSiderItem) => {
    const route = menuToRouteMap[key];
    const outboundRoutes = ['my campaigns', 'byot'];
    if (route) {
      if (outboundRoutes.includes(key.toLowerCase())) {
        dispatch(setNoviSelectedCampaignId(null));
        dispatch(setNoviCurrentPage(key as any));
        if (key === 'My Campaigns') {
          navigate(route);
        }
      } else {
        navigate(route);
      }
    }
  };

  const { data: resellerLogo, isLoading } = useQuery({
    queryKey: ['reseller-logo'],
    queryFn: () => getResellerLogo({ key: currentReseller.logo_s3 }),
    enabled: !!currentReseller.logo_s3,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  const resellerLogoUrl = useMemo(() => {
    if (!resellerLogo) return;
    const logoUrl = URL.createObjectURL(resellerLogo);
    //@ts-ignore
    dispatch(updateReseller({ logoUrl }));
    return logoUrl;
  }, [resellerLogo]);

  const baseClass = 'reseller-dashboard-container';
  return (
    <>
      {currentReseller?.permanently_unbranded || currentReseller?.branded ? (
        ''
      ) : (
        <Alert className={`${baseClass}_reseller-promo`} description={<ResellerPromo />} closable={true} />
      )}
      {currentReseller?.needPaymentClearance && (
        <Alert
          message='Important: Outstanding Balance'
          description={<ResellerPendingPaymentPromo />}
          type='warning'
          showIcon
        />
      )}
      <ResellerSubCancelPromo />
      <Layout className={baseClass}>
        <ResellerSider setLoading={setLoading} activeMenuItem={activeKey} onMenuSelect={handleMenuSelect} />
        {loading ? (
          <Loading />
        ) : (
          <Layout>
            {showHeader && (
              <div className='px-4 pt-4 flex items-center gap-2'>
                {isLoading ? (
                  <Skeleton.Avatar size={'large'} shape='square' />
                ) : resellerLogoUrl ? (
                  <img
                    className=' rounded-lg'
                    src={resellerLogoUrl}
                    style={{
                      width: `${currentReseller.logoDimensions?.width || 64}px`,
                      height: `${currentReseller.logoDimensions?.width || 64}px`,
                      objectFit: 'contain',
                    }}
                    alt='logo'
                  />
                ) : (
                  ''
                )}
                <span className='font-semibold text-2xl'>{currentReseller.reseller_name}</span>
              </div>
            )}

            {isPageLoading ? <Loading /> : children}
          </Layout>
        )}
        <ClearPaymentModal />
      </Layout>
    </>
  );
};

export default ResellerDashboard;
