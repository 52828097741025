import React, { useState, useEffect } from 'react';
import {
  Brain,
  Loader2,
  Search,
  Globe,
  Database,
  FileText,
  CalendarCheck,
  Bell,
  Link,
  MessageSquare,
  BarChart2,
  Phone,
} from 'lucide-react';

interface TipsLoadingScreenProps {
  title: string;
  variant?: 'default' | 'scraping';
}

const getContentByVariant = (variant: 'default' | 'scraping' = 'default') => {
  const content = {
    default: {
      features: [
        {
          Icon: CalendarCheck,
          title: 'Appointment Scheduling',
          description: 'Setting up your smart booking system...',
        },
        {
          Icon: Bell,
          title: 'Smart Notifications',
          description: 'Configuring real-time alerts...',
        },
        {
          Icon: Link,
          title: 'API Integration',
          description: 'Connecting with your business tools...',
        },
        {
          Icon: MessageSquare,
          title: 'Smart Communication',
          description: 'Personalizing conversation patterns...',
        },
      ],
      tips: [
        {
          Icon: Database,
          text: 'Thousands of businesses trust AI Front Desk to manage their calls effectively.',
        },
        {
          Icon: BarChart2,
          text: 'On average, AI Front Desk can help you achieve a 40%+ increase in customer conversions.',
        },
        {
          Icon: Phone,
          text: 'Your new AI receptionist is 100x more cost-effective than a human and is always available.',
        },
      ],
      subtitle: "We're setting up your AI assistant with advanced features",
    },
    scraping: {
      features: [
        {
          Icon: Search,
          title: 'Website Analysis',
          description: 'Scanning your website content...',
        },
        {
          Icon: Database,
          title: 'Data Processing',
          description: 'Processing business information...',
        },
        {
          Icon: Globe,
          title: 'Service Detection',
          description: 'Identifying your key services...',
        },
        {
          Icon: FileText,
          title: 'Content Learning',
          description: 'Learning from your website content...',
        },
      ],
      subtitle: 'Our AI is learning from your website',
      tips: [
        {
          Icon: Globe,
          text: 'Our AI is analyzing your website content to understand your business better.',
        },
        {
          Icon: Database,
          text: "We extract key information to personalize your AI assistant's responses.",
        },
        {
          Icon: FileText,
          text: 'The AI learns from your website to provide accurate information to your customers.',
        },
      ],
    },
  };

  return content[variant];
};

const TipsLoadingScreen: React.FC<TipsLoadingScreenProps> = ({ title, variant = 'default' }) => {
  const [progress, setProgress] = useState(0);
  const [currentFeatureIndex, setCurrentFeatureIndex] = useState(0);
  const [featuresCompleted, setFeaturesCompleted] = useState(false);

  const content = getContentByVariant(variant);
  const totalFeatures = content.features.length;

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress(prev => {
        const newProgress = prev + 1;
        if (newProgress >= 99) {
          clearInterval(timer);
          return 99;
        }
        return newProgress;
      });
    }, 50);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (progress < 100) {
      const featureInterval = setInterval(() => {
        setCurrentFeatureIndex(prev => {
          const nextIndex = (prev + 1) % (featuresCompleted ? content.tips.length : totalFeatures);
          if (nextIndex === 0 && progress > 80 && !featuresCompleted) {
            setFeaturesCompleted(true);
          }
          return nextIndex;
        });
      }, 3000);

      return () => clearInterval(featureInterval);
    }
  }, [progress, totalFeatures, featuresCompleted, content.tips.length]);

  const currentFeature = content.features[currentFeatureIndex];

  return (
    <div className='flex items-center justify-center p-6'>
      <div className='max-w-2xl w-full space-y-8'>
        <div className='text-center space-y-4'>
          <div className='flex justify-center'>
            <Brain className='w-16 h-16 text-blue-500 animate-pulse' />
          </div>

          <h1 className='text-3xl font-bold text-gray-900'>{title}</h1>
          <p className='text-lg text-gray-600'>{content.subtitle}</p>
        </div>

        <div className='space-y-6'>
          {/* Progress Bar */}
          <div className='relative'>
            <div className='h-2 bg-gray-200 rounded-full overflow-hidden'>
              <div
                className='h-full bg-blue-500 transition-all duration-300 rounded-full'
                style={{ width: `${progress}%` }}
              />
            </div>
            <div className='mt-2 text-right text-sm text-gray-500'>{progress}%</div>
          </div>

          {/* Feature/Tip Card */}
          <div className='bg-white rounded-lg p-6 shadow-lg border border-gray-100'>
            <div className='flex items-center space-x-4'>
              {featuresCompleted ? (
                <>
                  <div className='bg-blue-100 p-3 rounded-full'>
                    {React.createElement(content.tips[currentFeatureIndex].Icon, {
                      className: 'w-6 h-6 text-blue-600',
                    })}
                  </div>
                  <p className='text-gray-600 flex-1'>{content.tips[currentFeatureIndex].text}</p>
                </>
              ) : (
                <>
                  <div className='bg-blue-100 p-3 rounded-full'>
                    <currentFeature.Icon className='w-6 h-6 text-blue-600' />
                  </div>
                  <div>
                    <h3 className='font-medium text-gray-900'>{currentFeature.title}</h3>
                    <p className='text-gray-500 text-sm'>{currentFeature.description}</p>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        <div className='flex justify-center space-x-2'>
          <Loader2 className='w-5 h-5 text-blue-500 animate-spin' />
          <span className='text-sm text-gray-500'>
            {variant === 'scraping' ? 'Analyzing your website...' : 'This usually takes less than a minute.'}
          </span>
        </div>
      </div>
    </div>
  );
};

export default TipsLoadingScreen;
