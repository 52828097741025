import { Navigate, Outlet, useSearchParams } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { useQuery } from '@tanstack/react-query';
import { useAppDispatch } from '@/store/hooks/useAppDispatch';
import { getCampaignDetails } from '@/api/novi/campaigns';
import { Loading } from '@/components/Loading';
import LocalStorageUtility from '@/utils/localStorage';
import { LocalStorageKeys } from 'src/enums/ELocalStorage';
import { AppRoutes } from 'src/enums/ERoutes';
import { ENoviDashboardSider } from '../enums/ENoviDashboardSider';
import { setNoviCurrentPage, setNoviSelectedCampaign, setNoviSelectedCampaignId } from '@/store/novi/user/slice';
import { getResellerByCode } from '@/api/unbrandedReceptionist';
import { useEffect } from 'react';
import { setReseller } from '@/store/reseller/slice';

interface DecodedToken {
  exp: number;
  [key: string]: any;
}

interface OutletContextType {
  debrand: boolean;
  resellerCode?: string;
}

const TOKEN_CLEANUP = {
  clearAndReturnOutlet: (context: OutletContextType) => {
    LocalStorageUtility.removeLocalData(LocalStorageKeys.NOVI_RECEPTIONIST_AUTH_TOKEN);
    LocalStorageUtility.removeLocalData(LocalStorageKeys.NOVI_AUTH_TOKEN);
    LocalStorageUtility.removeLocalData(LocalStorageKeys.NOVI_USER);
    return <Outlet context={context} />;
  },
};

const validateToken = (token: string | null): boolean => {
  if (!token) return false;

  try {
    const cleanToken = typeof token === 'string' ? token.replace('Bearer ', '').trim() : JSON.stringify(token);

    const { exp } = jwtDecode<DecodedToken>(cleanToken);
    return exp >= Math.floor(Date.now() / 1000);
  } catch {
    return false;
  }
};

const useResellerCode = () => {
  const [searchParams] = useSearchParams();
  return searchParams.get('reseller_code') || searchParams.get('code') || undefined;
};

//* NO NEED TO CARE FOR UNBRANDED DOMAINS ETC SINCE THIS IS A WHITELABEL CAMPAIGN PUBLIC ROUTE FOR NOW

const WhitelabelCampaignPublicRoute: React.FC = () => {
  const dispatch = useAppDispatch();
  const resellerCode = useResellerCode();
  const authToken = LocalStorageUtility.getLocalData(LocalStorageKeys.NOVI_RECEPTIONIST_AUTH_TOKEN);
  const isTokenValid = validateToken(authToken as string);
  console.log('resellerCode in WhitelabelCampaignPublicRoute', resellerCode);

  const { data, isLoading, isError } = useQuery({
    queryKey: ['getCampaignDetails'],
    queryFn: getCampaignDetails,
    enabled: isTokenValid,
    retry: false,
  });

  const { data: resellerData } = useQuery({
    queryKey: ['reseller-code', resellerCode],
    queryFn: () => getResellerByCode({ resellerCode: resellerCode as string }),
    enabled: !!resellerCode,
  });

  useEffect(() => {
    if (resellerData?.reseller) {
      dispatch(setReseller(resellerData.reseller));
    }
  }, [resellerData]);

  if (!isTokenValid) {
    return TOKEN_CLEANUP.clearAndReturnOutlet({ debrand: true, resellerCode });
  }

  if (isLoading) return <Loading />;

  if (isError) {
    return TOKEN_CLEANUP.clearAndReturnOutlet({ debrand: true, resellerCode });
  }

  if (data?.data) {
    const { data: campaignData } = data;

    dispatch(setNoviSelectedCampaign(campaignData));
    dispatch(setNoviSelectedCampaignId(campaignData.number));
    dispatch(setNoviCurrentPage(ENoviDashboardSider.LEADS));

    return <Navigate to={AppRoutes.WHITE_LABEL_CAMPAIGN_DASHBOARD} state={{ debrand: true, resellerCode }} replace />;
  }

  return <Outlet context={{ debrand: true, resellerCode }} />;
};

export default WhitelabelCampaignPublicRoute;
