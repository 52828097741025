import { Button, Form, message, Slider, Select, Input, Radio, Tooltip, Checkbox } from 'antd';
import React, { useState, useEffect } from 'react';
import { OverViewCardForm } from '../../../OverviewCardForm';
import { addCalComCalendar, getAllCalComApiKeys, getAllUserEventTypes } from '@/api/calendars/calcom';
import { useDispatch } from 'react-redux';
import { closeModal } from '@/store/modal/slice';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { getLoggedInUser } from '@/store/account/selector';
import ZoomLogo from '@/assets/images/calendars/zoom.png'; // Replace with actual paths to logos
import GoogleMeetLogo from '@/assets/images/calendars/meet.png';
import CalComLogo from '@/assets/images/calendars/calcom.png';
import { InfoOutlined } from '@mui/icons-material';
import { LabelWithDescription } from '@/components/LabelWithdescription';
import { InfoCircleOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { getSelectedCampaign } from '@/store/novi/user/selector';

export const ConnectCalComWorkflowForm: React.FC<{
  existingCalendars?: any[];
  isNoviUser: boolean;
  refetch?: any;
}> = ({ existingCalendars = [], isNoviUser, refetch }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [eventTypes, setEventTypes] = useState<{ id: string; name: string }[]>([]);
  const [apiKeys, setApiKeys] = useState<{ apiKey: string }[]>([]);
  const [activeFieldsArray, setActiveFieldsArray] = useState<number[]>([]);
  const [selectedEventLocations, setSelectedEventLocations] = useState<{ [key: number]: string[] }>({});
  const dispatch = useDispatch();
  let loggedInUser;

  if (isNoviUser) {
    loggedInUser = useAppSelector(getSelectedCampaign);
  } else {
    loggedInUser = useAppSelector(getLoggedInUser);
  }

  useEffect(() => {
    const fetchApiKeys = async () => {
      try {
        const keys = await getAllCalComApiKeys(loggedInUser.number);
        setApiKeys(keys || []);
      } catch (error) {
        console.error('Error loading API keys:', error);
        message.error('Failed to load existing API keys.');
      }
    };
    fetchApiKeys();
  }, []);

  useEffect(() => {
    if (existingCalendars.length) {
      form.setFieldsValue({ calendars: existingCalendars });
      const activeFields = existingCalendars.map((_, idx) => idx);
      setActiveFieldsArray(activeFields);
    }
  }, [existingCalendars, form]);

  const handleConnect = async (index: number) => {
    try {
      const values = await form.validateFields();
      const calendars = values.calendars || [];
      const { apiKey } = calendars[index];

      if (!apiKey) {
        message.error('Please enter an API key.');
        return;
      }

      setLoading(true);

      const data = await getAllUserEventTypes({ apiKey });

      if (data) {
        const fetchedEventTypes = data.map((event: any) => ({
          id: event.id,
          name: event.title,
          locations: event.locations,
        }));
        setEventTypes(fetchedEventTypes);
        message.success(`Successfully fetched event types for Calendar ${index + 1}.`);
        setActiveFieldsArray(prev => [...prev, index]);
      } else {
        message.error('No event types found. Please check your API key.');
      }
    } catch (error) {
      console.error('Error connecting to Cal.com:', error);
      message.error('Failed to fetch event types. Please check your input and try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (values: any) => {
    try {
      await addCalComCalendar({ ...values, username: loggedInUser.number });
      message.success('Calendars saved successfully!');
      dispatch(closeModal());
      refetch();
    } catch (error) {
      console.error('Error saving calendars:', error);
      message.error('Failed to save calendars. Please try again.');
    }
  };

  const renderLabelWithDescription = (label: string, description?: string, className: string = ''): JSX.Element => (
    <LabelWithDescription
      customClassName={`w-full ${className}`}
      label={<span className='text-sm font-semibold'>{label}</span>}
      description={description}
    />
  );

  const renderTooltip = (title: string, content: string): JSX.Element => (
    <Tooltip
      placement='top'
      title={
        <span>
          <span className='font-medium mr-1'>{title}:</span>
          {content}
        </span>
      }
    >
      <InfoCircleOutlined />
    </Tooltip>
  );

  const handleEventTypeChange = (value: string, index: number) => {
    const eventType: any = eventTypes.find(event => event.id === value);
    const locations = eventType?.locations?.map((loc: any) => loc.type) || [];

    setSelectedEventLocations(prev => ({
      ...prev,
      [index]: locations,
    }));

    form.setFieldsValue({
      calendars: form
        .getFieldValue('calendars')
        .map((calendar: any, idx: number) =>
          idx === index ? { ...calendar, eventTypeId: value, location: locations[0] || '' } : calendar
        ),
    });
  };

  return (
    <div>
      <OverViewCardForm
        form={form}
        initialValues={{
          calendars: existingCalendars.length
            ? existingCalendars
            : [
                {
                  apiKey: null,
                  daysOffering: 3,
                  slotsPerDay: 3,
                  hoursBetweenSlots: 3,
                  eventTypeId: '',
                  location: null,
                  smsTemplate:
                    'Your meeting has been successfully scheduled on [DATE] at [TIME]. You can join using the following link: [LINK]',
                  scenario: 'Whenever the caller wants to book or schedule any meetings or appointments.',
                },
              ],
        }}
        formItems={
          <Form.List name='calendars'>
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }, index) => (
                  <div key={key} className='mb-8 bg-white p-8 rounded-lg shadow-sm border border-gray-200'>
                    <div className='flex justify-between items-center mb-6'>
                      <span className='text-lg font-semibold'>Calendar {index + 1}</span>
                      <Button type='primary' danger onClick={() => remove(name)}>
                        Delete
                      </Button>
                    </div>

                    {/* API Key Field */}
                    <div className='mb-8'>
                      <Tooltip
                        title={
                          <>
                            Your Cal.com API key to connect your calendar.
                            <a
                              href='https://cal.com/docs/api-reference/v1/authentication'
                              target='_blank'
                              className='font-bold underline'
                            >
                              Learn how to get your Cal.com API key
                            </a>
                          </>
                        }
                      >
                        <span className='text-sm font-semibold inline-flex items-center space-x-2'>
                          <span>API Key</span>
                          <InfoOutlined />
                        </span>
                      </Tooltip>
                      {form.getFieldValue('calendars')?.[name]?.accessToken ? (
                        <div>{form.getFieldValue('calendars')?.[name]?.accessToken}</div>
                      ) : (
                        <Form.Item
                          {...restField}
                          name={[name, 'apiKey']}
                          rules={[{ required: true, message: 'Please select or enter an API key.' }]}
                        >
                          <Select
                            mode='tags'
                            showSearch
                            placeholder='Select or enter an API key'
                            options={apiKeys.map(key => ({ value: key.apiKey, label: key.apiKey }))}
                            filterOption={(input, option) =>
                              option?.label.toLowerCase().includes(input.toLowerCase()) || false
                            }
                            onChange={value => {
                              const singleValue = Array.isArray(value) ? value[0] : value;

                              if (singleValue.trim() && !apiKeys.some(key => key.apiKey === singleValue)) {
                                setApiKeys(prevKeys => [...prevKeys, { apiKey: singleValue }]);
                              }

                              form.setFieldsValue({
                                calendars: form
                                  .getFieldValue('calendars')
                                  .map((calendar: any, idx: number) =>
                                    idx === name ? { ...calendar, apiKey: singleValue } : calendar
                                  ),
                              });
                            }}
                            value={form.getFieldValue('calendars')?.[name]?.apiKey}
                          />
                        </Form.Item>
                      )}
                    </div>

                    {/* Connect Button */}
                    {!activeFieldsArray.includes(index) && (
                      <div className='mb-8'>
                        <Button type='primary' onClick={() => handleConnect(index)} loading={loading}>
                          Connect
                        </Button>
                      </div>
                    )}

                    {/* Additional Fields */}
                    {activeFieldsArray.includes(index) && (
                      <>
                        {/* Scenario */}
                        <div className='mb-8'>
                          <Tooltip title='The scenario on based of which you want to trigger the scheduling. You can use it to connect to multiple calendars.'>
                            <span className='text-sm font-semibold inline-flex items-center space-x-2'>
                              <span>Scenario</span>
                              <InfoOutlined />
                            </span>
                          </Tooltip>
                          <Form.Item
                            {...restField}
                            name={[name, 'scenario']}
                            rules={[{ required: true, message: 'Please enter a scenario.' }]}
                          >
                            <Input placeholder='Enter the scenario for this calendar' />
                          </Form.Item>
                        </div>

                        {/* SMS Template */}
                        <div className='mb-8'>
                          <Tooltip title='This is the text and format of confirmation SMS which will be sent after booking is successfully created.'>
                            <span className='text-sm font-semibold inline-flex items-center space-x-2'>
                              <span>SMS Template</span>
                              <InfoOutlined />
                            </span>
                          </Tooltip>
                          <Form.Item {...restField} name={[name, 'smsTemplate']} rules={[{ required: false }]}>
                            <Input.TextArea
                              placeholder='Enter SMS template (use [DATE], [TIME], [LINK] as placeholders)'
                              rows={3}
                            />
                          </Form.Item>
                        </div>

                        <div className='mb-8'>
                          <Tooltip title='The number of days to suggest slots.'>
                            <span className='text-sm font-semibold inline-flex items-center space-x-2'>
                              <span>Days for Offering Dates</span>
                              <InfoOutlined />
                            </span>
                          </Tooltip>
                          <Form.Item {...restField} name={[name, 'daysOffering']} initialValue={3}>
                            <Slider
                              min={1}
                              max={10}
                              marks={{
                                1: '1',
                                2: '2',
                                3: '3',
                                4: '4',
                                5: '5',
                                6: '6',
                                7: '7',
                                8: '8',
                                9: '9',
                                10: '10',
                              }}
                            />
                          </Form.Item>
                        </div>

                        <div className='mb-8'>
                          <Tooltip title='The number of slots to suggest per day.'>
                            <span className='text-sm font-semibold inline-flex items-center space-x-2'>
                              <span>Appointment Slots Per Day</span>
                              <InfoOutlined />
                            </span>
                          </Tooltip>
                          <Form.Item {...restField} name={[name, 'slotsPerDay']} initialValue={3}>
                            <Slider
                              min={1}
                              max={10}
                              marks={{
                                1: '1',
                                2: '2',
                                3: '3',
                                4: '4',
                                5: '5',
                                6: '6',
                                7: '7',
                                8: '8',
                                9: '9',
                                10: '10',
                              }}
                            />
                          </Form.Item>
                        </div>

                        <div className='mb-8'>
                          <Tooltip title='The minimum gap between the suggest slots (in hours).'>
                            <span className='text-sm font-semibold inline-flex items-center space-x-2'>
                              <span>Hours Between Slots</span>
                              <InfoOutlined />
                            </span>
                          </Tooltip>
                          <Form.Item {...restField} name={[name, 'hoursBetweenSlots']} initialValue={3}>
                            <Slider
                              min={1}
                              max={5}
                              marks={{
                                1: '1',
                                2: '2',
                                3: '3',
                                4: '4',
                                5: '5',
                              }}
                            />
                          </Form.Item>
                        </div>

                        <div className='mb-8'>
                          <Tooltip title='The event of Cal.com which you want to book meetings on.'>
                            <span className='text-sm font-semibold inline-flex items-center space-x-2'>
                              <span>Event</span>
                              <InfoOutlined />
                            </span>
                          </Tooltip>
                          <Form.Item
                            {...restField}
                            name={[name, 'eventTypeId']}
                            rules={[{ required: true, message: 'Please select an event type.' }]}
                          >
                            <Select
                              placeholder='Select event type'
                              options={eventTypes.map(type => ({ value: type.id, label: type.name }))}
                              onChange={value => handleEventTypeChange(value, index)}
                              disabled={eventTypes.length === 0}
                            />
                          </Form.Item>
                        </div>

                        {/* Location Selector */}
                        {selectedEventLocations && Object.keys(selectedEventLocations).length > 0 && (
                          <div className='mb-8'>
                            <Tooltip
                              title={
                                <>
                                  The location of meeting conference where you want to join the meeting. Make sure it's
                                  also supported / added on event type in Cal.com.{' '}
                                  <a
                                    href='https://cal.com/help/en/articles/9929572-how-to-add-a-location-to-your-event-type'
                                    target='_blank'
                                    className='font-bold underline'
                                  >
                                    Learn more
                                  </a>
                                </>
                              }
                            >
                              <span className='text-sm font-semibold inline-flex items-center space-x-2'>
                                <span>Location</span>
                                <InfoOutlined />
                              </span>
                            </Tooltip>
                            <Form.Item
                              {...restField}
                              name={[name, 'location']}
                              rules={[{ required: true, message: 'Please select a valid location.' }]}
                            >
                              <Radio.Group size='large' className='flex items-center'>
                                {selectedEventLocations[name]?.map(loc => (
                                  <Radio.Button
                                    key={loc}
                                    value={loc}
                                    className='inline-flex items-center justify-center'
                                  >
                                    {loc === 'integrations:daily' && (
                                      <img src={CalComLogo} alt='Daily' style={{ width: 60 }} />
                                    )}
                                    {loc === 'integrations:zoom' && (
                                      <img src={ZoomLogo} alt='Zoom' style={{ width: 60 }} />
                                    )}
                                    {loc === 'integrations:google:meet' && (
                                      <img src={GoogleMeetLogo} alt='Google Meet' style={{ width: 60 }} />
                                    )}
                                  </Radio.Button>
                                ))}
                              </Radio.Group>
                            </Form.Item>
                          </div>
                        )}

                        {renderLabelWithDescription(
                          'Information to collect',
                          'These are details you want to ask from the user who is interacting with your AI receptionist for booking a calendar meeting.',
                          'mt-6'
                        )}

                        <Form.List name={[name, 'askOtherDetails']}>
                          {(fields, { add, remove }) => (
                            <>
                              {fields.length > 0 && (
                                <div className='w-full flex items-center mb-2 text-sm font-medium'>
                                  <div className='w-1/5 flex items-center gap-1 justify-start'>
                                    Spell Out
                                    {renderTooltip(
                                      'Spell Out',
                                      "If you want the AI receptionist to repeat the user's input for the provided field, specify here by checking the box."
                                    )}
                                  </div>
                                  <div className='w-2/5 flex items-center gap-1 justify-start'>
                                    Field
                                    {renderTooltip(
                                      'Field',
                                      'The name of the field to be asked from the user (please avoid any spaces).'
                                    )}
                                  </div>
                                  <div className='w-2/5 flex items-center gap-1 justify-start'>
                                    Description
                                    {renderTooltip(
                                      'Description',
                                      'A description related to the field for the AI receptionist to understand what information needs to be collected or asked.'
                                    )}
                                  </div>
                                </div>
                              )}

                              {fields.map(({ key, name, ...restField }) => (
                                <div key={key} className='flex gap-2 mb-2'>
                                  <Form.Item
                                    {...restField}
                                    name={[name, 'spellOut']}
                                    className='w-1/5 flex-auto'
                                    valuePropName='checked'
                                  >
                                    <Checkbox />
                                  </Form.Item>
                                  <Form.Item
                                    {...restField}
                                    name={[name, 'field']}
                                    className='w-2/5 flex-auto'
                                    rules={[{ required: true, message: 'Missing field' }]}
                                  >
                                    <Input placeholder={`First Name`} />
                                  </Form.Item>
                                  <Form.Item
                                    {...restField}
                                    name={[name, 'description']}
                                    className='w-2/5 flex-auto'
                                    rules={[{ required: true, message: 'Missing description' }]}
                                  >
                                    <Input placeholder={`The caller's first name`} />
                                  </Form.Item>
                                  {fields.length > 0 && (
                                    <MinusCircleOutlined className='pb-4' onClick={() => remove(name)} />
                                  )}
                                </div>
                              ))}
                              <Form.Item>
                                <Button type='dashed' onClick={() => add()} block icon={<PlusOutlined />}>
                                  Add Other Details
                                </Button>
                              </Form.Item>
                            </>
                          )}
                        </Form.List>
                      </>
                    )}
                  </div>
                ))}
                <Form.Item>
                  <Button type='dashed' onClick={() => add()} block>
                    Add Another Calendar
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        }
        handleSubmit={handleSubmit}
      />
    </div>
  );
};
