import React from 'react';
import Fade from 'react-reveal/Fade';
import classes from '../Register.module.css';
import { Button, notification } from 'antd';
import clsx from 'clsx';
import TextEditor from '../../../components/TextEditor/TextEditor';

interface Workflow {
  description: string;
  textMessage: string;
  voiceResponse: string;
}

interface Step6AProps {
  showLinkTextingModal: boolean;
  workflows: Workflow[];
  setShowLinkTextingModal: React.Dispatch<React.SetStateAction<boolean>>;
  isCampaign: boolean | undefined;
  campaignVariables: any;
  handleDescriptionChange: (e: any, index: number) => void;
  handleTextMessageChange: (e: any, index: number) => void;
  setCampaignVariables: (variables: any) => void;
  handleVoiceResponseChange: (e: any, index: number) => void;
  removeWorkflow: (e: React.MouseEvent<HTMLButtonElement>, index: number) => void;
  setTextingTemplateModalActive: React.Dispatch<React.SetStateAction<boolean>>;
  isUsingATemplate: boolean;
  systemPhrase: string | null;
  businessType: string;
  setStepState: React.Dispatch<React.SetStateAction<number>>;
  stepState: number;
  addWorkflow: (e: React.FormEvent) => void;
  handleStepTransition: (
    nextStep: number,
    validationFn?: () => Promise<boolean>,
    stateMutationFn?: () => Promise<void>
  ) => Promise<void>;
  stepLoading: boolean;
}

const Step6A: React.FC<Step6AProps> = ({
  showLinkTextingModal,
  workflows,
  setShowLinkTextingModal,
  isCampaign,
  campaignVariables,
  handleDescriptionChange,
  handleTextMessageChange,
  setCampaignVariables,
  handleVoiceResponseChange,
  removeWorkflow,
  setTextingTemplateModalActive,
  isUsingATemplate,
  systemPhrase,
  businessType,
  stepState,
  addWorkflow,
  handleStepTransition,
  stepLoading,
}) => {
  return (
    <>
      <div className={classes.box + ' ' + classes.modal + ' ' + (showLinkTextingModal && classes.active)}>
        <div
          className={classes.close}
          onClick={() => {
            if (workflows.some(workflow => !workflow.description || !workflow.textMessage || !workflow.voiceResponse)) {
              notification.error({
                message: 'Please fill out all workflows, or remove empty workflows',
                duration: 1,
              });
              return;
            }
            setShowLinkTextingModal(false);
          }}
        >
          &times;
        </div>
        <Fade duration={1000} bottom>
          <>
            <h3
              className={classes.heading}
              style={{
                textAlign: 'center',
              }}
            >
              Texting Workflows
            </h3>

            <div className={classes.subtitle}>
              Send custom texts to callers in real-time. For example, if the caller asks to schedule an appointment, the
              receptionist can text them a link to schedule their appointment.
            </div>

            {workflows.map((workflow, index) => (
              <div
                key={index}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginBottom: '1rem',
                  border: '2px dashed #ccc',
                  borderRadius: '10px',
                  padding: '1rem',
                }}
              >
                <label className={classes.label}> Trigger</label>
                <div
                  className={classes.subtitle}
                  style={{
                    marginBottom: '10px',
                  }}
                >
                  Describe the scenario in which you would like to send this text message.
                </div>
                {isCampaign ? (
                  <TextEditor
                    html={workflow.description}
                    variables={campaignVariables}
                    onChange={(value, variables) => {
                      handleDescriptionChange(
                        {
                          preventDefault: () => {},
                          target: { value },
                        },
                        index
                      );
                      setCampaignVariables(variables);
                    }}
                    placeholder='e.g. Send the caller a copy of the menu. Execute whenever caller asks for menu or prices.'
                  />
                ) : (
                  <textarea
                    style={{
                      marginBottom: '16px',
                      fontFamily: 'Quicksand, sans-serif',
                    }}
                    rows={4}
                    className={classes.input + ' ' + classes.textarea}
                    value={workflow.description}
                    required
                    onChange={e => handleDescriptionChange(e, index)}
                    placeholder='e.g. Send the caller a copy of the menu. Execute whenever caller asks for menu or prices.'
                  />
                )}
                <label className={classes.label}>Text Message</label>
                <div
                  className={classes.subtitle}
                  style={{
                    marginBottom: '10px',
                  }}
                >
                  Enter the text message to be sent to the user when this scenario is triggered.
                </div>
                {isCampaign ? (
                  <TextEditor
                    html={workflow.textMessage}
                    variables={campaignVariables}
                    onChange={(value, variables) => {
                      // Create a mock event object with the expected structure
                      const event = {
                        preventDefault: () => {},
                        target: { value } as HTMLInputElement, // Cast to HTMLInputElement or HTMLTextAreaElement
                      };

                      handleTextMessageChange(event, index);
                      setCampaignVariables(variables);
                    }}
                    placeholder='e.g. Here is our menu: www.restaurant.com/menu'
                  />
                ) : (
                  <textarea
                    style={{
                      marginBottom: '16px',
                      fontFamily: 'Quicksand, sans-serif',
                    }}
                    rows={4}
                    className={classes.input + ' ' + classes.textarea}
                    value={workflow.textMessage}
                    required
                    onChange={e => handleTextMessageChange(e, index)}
                    placeholder='e.g. Here is our menu: www.restaurant.com/menu'
                  />
                )}
                <label className={classes.label}>Voice Response</label>
                <div
                  className={classes.subtitle}
                  style={{
                    marginBottom: '10px',
                  }}
                >
                  Enter the response to be spoken by the phone receptionist after the text is sent.
                </div>
                {isCampaign ? (
                  <TextEditor
                    html={workflow.voiceResponse}
                    variables={campaignVariables}
                    onChange={(value, variables) => {
                      handleVoiceResponseChange(
                        {
                          preventDefault: () => {},
                          target: { value },
                        },
                        index
                      );
                      setCampaignVariables(variables);
                    }}
                    placeholder='e.g. Great! I’ve just texted you a copy of the menu'
                  />
                ) : (
                  <textarea
                    style={{
                      marginBottom: '16px',
                      fontFamily: 'Quicksand, sans-serif',
                    }}
                    rows={4}
                    className={classes.input + ' ' + classes.textarea}
                    value={workflow.voiceResponse}
                    required
                    onChange={e => handleVoiceResponseChange(e, index)}
                    placeholder='e.g. Great! I’ve just texted you a copy of the menu'
                  />
                )}
                <button
                  className={clsx(isCampaign && 'mt-6')}
                  type='button'
                  style={{
                    background: 'rgb(237 35 35)',
                    color: '#fff',
                    border: 'none',
                    height: '36px',
                    borderRadius: '4px',
                    cursor: 'pointer',
                  }}
                  onClick={e => removeWorkflow(e, index)}
                >
                  Remove
                </button>
              </div>
            ))}

            <button onClick={addWorkflow} style={{ maxWidth: '300px' }} className={classes.whiteButton}>
              + Add Texting Workflow?
            </button>

            <>
              {workflows.length !== 0 && (
                <button
                  className={classes.whiteButton + ' ' + classes.flexCentered}
                  style={{
                    marginTop: '1rem',
                    width: 'max-content',
                    maxWidth: 'max-content',
                  }}
                  onClick={event => {
                    event.preventDefault(); // Prevent the button from scrolling up

                    setTextingTemplateModalActive(true);
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      style={{
                        strokeWidth: '1.5px',
                        height: '16px',
                        width: '16px',
                        marginRight: '8px',
                      }}
                      viewBox='0 0 24 24'
                      strokeWidth='1.5'
                      stroke='currentColor'
                      aria-hidden='true'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M2.25 12.75V12A2.25 2.25 0 014.5 9.75h15A2.25 2.25 0 0121.75 12v.75m-8.69-6.44l-2.12-2.12a1.5 1.5 0 00-1.061-.44H4.5A2.25 2.25 0 002.25 6v12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9a2.25 2.25 0 00-2.25-2.25h-5.379a1.5 1.5 0 01-1.06-.44z'
                      ></path>
                    </svg>
                    {isUsingATemplate && systemPhrase ? `Using ${businessType} Template` : 'Use a Template'}
                  </div>
                </button>
              )}
            </>

            <Button
              type='primary'
              onClick={async () => {
                if (
                  workflows.some(workflow => !workflow.description || !workflow.textMessage || !workflow.voiceResponse)
                ) {
                  notification.error({
                    message: 'Please fill out all workflows, or remove empty workflows',
                    duration: 1,
                  });
                  return;
                }
                await handleStepTransition(stepState + 1);
                setShowLinkTextingModal(false);
              }}
              className={classes.button + ' ' + classes.centered}
              style={{ marginTop: '2rem' }}
              loading={stepLoading}
            >
              Continue
            </Button>
          </>
        </Fade>
      </div>
    </>
  );
};

export default Step6A;
