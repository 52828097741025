import type { MenuProps } from 'antd';
import Badge from 'antd/es/badge';
import React from 'react';

// Import icons
import {
  AnalyticsOutlined,
  ArticleOutlined,
  ContactSupportOutlined,
  CallOutlined,
  FeedOutlined,
  VoicemailOutlined,
  TextsmsOutlined,
  TextSnippetOutlined,
  StackedBarChartOutlined,
  PhoneIphoneOutlined,
  CasesOutlined,
  TryOutlined,
  ElectricalServicesOutlined,
} from '@mui/icons-material';

import { SettingOutlined, SoundOutlined } from '@ant-design/icons';

import { ReceptionistDashboardSiderItem } from './EReceptionistDashboardSiderItem';
import { Rocket } from 'lucide-react';

// Define the type for menu items
type MenuItem = Required<MenuProps>['items'][number];

// Export the items array
export const sidebarItems: MenuItem[] = [
  {
    key: ReceptionistDashboardSiderItem.GET_STARTED,
    icon: React.createElement(Rocket),
    label: ReceptionistDashboardSiderItem.GET_STARTED,
  },
  {
    key: ReceptionistDashboardSiderItem.CONFIGURE,
    icon: React.createElement(SettingOutlined),
    label: ReceptionistDashboardSiderItem.CONFIGURE,
  },
  {
    key: ReceptionistDashboardSiderItem.PLAYGROUND,
    icon: React.createElement(TryOutlined),
    label: ReceptionistDashboardSiderItem.PLAYGROUND,
  },
  {
    key: ReceptionistDashboardSiderItem.EMBEDDABLES,
    icon: React.createElement(ElectricalServicesOutlined),
    label: React.createElement(
      Badge,
      { count: 'Beta', offset: [20, 0] },
      React.createElement('span', null, ReceptionistDashboardSiderItem.EMBEDDABLES)
    ),
  },
  {
    key: ReceptionistDashboardSiderItem.ANALYTICS,
    icon: React.createElement(AnalyticsOutlined),
    label: ReceptionistDashboardSiderItem.ANALYTICS,
  },
  {
    key: ReceptionistDashboardSiderItem.VOICE_LIBRARY,
    icon: React.createElement(SoundOutlined),
    label: ReceptionistDashboardSiderItem.VOICE_LIBRARY,
  },
  {
    key: ReceptionistDashboardSiderItem.INTAKE_FORMS,
    icon: React.createElement(TextSnippetOutlined),
    label: ReceptionistDashboardSiderItem.INTAKE_FORMS,
  },
  {
    key: ReceptionistDashboardSiderItem.TEXTING_PLATFORM,
    icon: React.createElement(TextsmsOutlined),
    label: ReceptionistDashboardSiderItem.TEXTING_PLATFORM,
  },
  {
    key: ReceptionistDashboardSiderItem.LOGS,
    icon: React.createElement(ArticleOutlined),
    label: ReceptionistDashboardSiderItem.LOGS,
    className: 'no-indent pl-2',
    children: [
      {
        key: ReceptionistDashboardSiderItem.CALL_LOGS,
        icon: React.createElement(CallOutlined),
        label: ReceptionistDashboardSiderItem.CALL_LOGS,
      },
      {
        key: ReceptionistDashboardSiderItem.TEXT_LOGS,
        icon: React.createElement(FeedOutlined),
        label: ReceptionistDashboardSiderItem.TEXT_LOGS,
      },
      {
        key: ReceptionistDashboardSiderItem.VOICE_MAIL_LOGS,
        icon: React.createElement(VoicemailOutlined),
        label: ReceptionistDashboardSiderItem.VOICE_MAIL_LOGS,
      },
    ],
  },
  {
    key: ReceptionistDashboardSiderItem.CRM,
    icon: React.createElement(StackedBarChartOutlined),
    label: React.createElement(
      Badge,
      { count: 'New', offset: [30, 0] },
      React.createElement('span', null, ReceptionistDashboardSiderItem.CRM)
    ),
  },
  {
    key: ReceptionistDashboardSiderItem.SETUP_INSTRUCTIONS,
    icon: React.createElement(PhoneIphoneOutlined),
    label: ReceptionistDashboardSiderItem.SETUP_INSTRUCTIONS,
  },
  {
    key: ReceptionistDashboardSiderItem.CONTACT_SUPPORT,
    icon: React.createElement(ContactSupportOutlined),
    label: ReceptionistDashboardSiderItem.CONTACT_SUPPORT,
  },
  {
    key: ReceptionistDashboardSiderItem.EXPLORE_FEATURES,
    icon: React.createElement(CasesOutlined),
    label: ReceptionistDashboardSiderItem.EXPLORE_FEATURES,
  },
];

// Helper function to get filtered items
export const getFilteredItems = (): MenuItem[] => {
  return sidebarItems.filter(
    item =>
      item?.key !== ReceptionistDashboardSiderItem.CONTACT_SUPPORT &&
      item?.key !== ReceptionistDashboardSiderItem.EXPLORE_FEATURES
  );
};
