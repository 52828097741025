import { CustomModal } from '@/components/CustomModal';
import { ForwardCallsProps } from '@/types/TTestItOut';
import { Button, Card, FormInstance, notification, Space, Spin, Typography } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { GetStandAloneNumberForm } from './GetStandAloneNumberForm';
import Title from 'antd/es/typography/Title';
import { useMutation } from '@tanstack/react-query';
import {
  provisionNumberRequest,
  getNonUsNumberRequirement,
  resetTwilioBundle,
  updateTestingNumber,
  makeTestCall,
} from '@/api/reservedNumbers';
import { getLoggedInUser } from '@/store/account/selector';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import GetChooseNumberForm from './GetChooseNumberForm';
import { AvailableNumber } from '@/interfaces/IReservedNumber';
import GetBundleApproveForm from './GetBundleApproveForm';
import { incrementTotalTestCalls, updatePendingTestingStatus } from '@/api/user';
import ConfettiExplosion from 'react-confetti-explosion';

import posthog from 'posthog-js';
import {
  BuildOutlined,
  CheckCircleOutlined,
  LoadingOutlined,
  PhoneOutlined,
  ShoppingOutlined,
} from '@ant-design/icons';
import Paragraph from 'antd/es/typography/Paragraph';
import { setTestingNumber, updateUser } from '@/store/account/slice';
import { useAppDispatch } from '@/store/hooks/useAppDispatch';
import { MAX_TRIAL_TEST_CALLS } from 'src/enums/ERoutes';
import { ChangeTestingNumberForm } from './ChangeTestingNumberForm';
import CustomOnboardingButton from '@/components/Onboarding/CustomButton';
import LearnMore from '@/components/Buttons/LearnMore';
import { TUTORIALS } from 'src/enums/ETutorials';
import { getResellerData } from '@/store/reseller/selector';
import { getCurrentReceptionistNumber } from '@/utils/helper';
import CopyableText from '@/components/LabelWithdescription/CopyableText';

const ForwardCalls = ({
  debrand,
  provisionedNumber,
  setProvisionedNumber,
  getProvisionNumberMutation,
  getBundleIdMutation,
  setOpenedOnboarding,
  renderCentralComponent,
}: ForwardCallsProps) => {
  const TOTAL_CALLS_LIMIT = MAX_TRIAL_TEST_CALLS;
  const formRef = useRef<FormInstance>(null);
  const testingNumberFormRef = useRef<FormInstance>(null);
  const [isStandAloneNumberModalVisible, setIsStandAloneNumberModalVisible] = useState<boolean>(false);
  const [isBookNumberModalVisible, setIsBookNumberModalVisible] = useState<boolean>(false);
  const [selectedCountry, setSelectedCountry] = useState<string>('');
  const [ChooseNumberModalOpen, setChooseNumberModalOpen] = useState<boolean>(false);
  const [isNonUSDetailsModalOpen, setIsNonUSDetailsModalOpen] = useState(false);
  const [celeberate, setCeleberate] = useState(false);
  const dispatch = useAppDispatch();
  const [twilioDocsRequirements, setTwilioDocsRequirements] = useState<any>([]);
  const [totalCalls, setTotalCalls] = useState<number>(0);
  const [changeTestingModalVisible, setIsChangeTestingModalVisible] = useState(false);
  const freeTestCallsLimitExceeded = totalCalls >= TOTAL_CALLS_LIMIT;
  const loggedInUser = useAppSelector(getLoggedInUser);
  const currentReseller = useAppSelector(getResellerData);

  const UpdatePendingTestingStatusMutation = useMutation({
    mutationKey: ['updatePendingTestingStatus'],
    mutationFn: updatePendingTestingStatus,
  });
  const handleSetPendingTesting = (status: boolean) => {
    UpdatePendingTestingStatusMutation.mutate({ pendingTesting: status });
  };

  // provision Number Logic

  const { mutate: getNonUsNumberRequirementMutation, isPending: isRequirementDispatchPending } = useMutation({
    mutationKey: ['getNonUsNumberRequirement'],
    mutationFn: getNonUsNumberRequirement,
    onSuccess: response => {
      setTwilioDocsRequirements(response);
      setIsNonUSDetailsModalOpen(true);
    },
  });

  const { mutate: provisionNumberRequestMutation, isPending: isProvisionApiPending } = useMutation({
    mutationKey: ['provisionNumberRequest'],
    mutationFn: provisionNumberRequest,
    onSuccess: response => {
      const data = response;
      if (data.status && data.status === 'success' && data.provisionedNumber) {
        getProvisionNumberMutation.mutate();
        setProvisionedNumber(data.provisionedNumber);
        setIsBookNumberModalVisible(false);
        handleSetPendingTesting(false);
        setCeleberate(true);
      }
    },
    onError: error => {
      console.log('error', error);

      notification.error({ message: error.message, description: 'Failed to provision number' });
    },
  });

  const { mutate: resetBundleMutation } = useMutation({
    mutationKey: ['resetBundle'],
    mutationFn: resetTwilioBundle,
    onSuccess: () => {
      dispatch(updateUser({ user: { twilioBundleId: null, twilioBundleStatus: null } }));
      notification.success({ message: 'Bundle reset successfully' });
    },
    onError: () => {
      notification.error({ message: 'Failed to reset bundle' });
    },
  });

  const bookNumberRequest = async (localChoosen: AvailableNumber | null) => {
    if (!selectedCountry) {
      return;
    }

    if (!loggedInUser?.twilioBundleId) {
      if (selectedCountry !== '( +1 ) - United States - US' && selectedCountry !== '( +1 ) - Canada - CA') {
        getNonUsNumberRequirementMutation({ countryCodeNumber: selectedCountry.split(' - ')[2] });
        setIsNonUSDetailsModalOpen(true);
        setIsBookNumberModalVisible(false);
        return;
      }
    }

    var countryCode = selectedCountry.split(' - ')[2]; // Something like "US"

    let payload = {
      username: loggedInUser.number,
      countryCode,
      localChoosen: null as string | null | AvailableNumber, // Update the type to allow both string, null, and AvailableNumber
      bundleId: null as string | null,
      addressId: null as string | null,
    };
    if (localChoosen !== null) {
      payload.localChoosen = localChoosen;
    }
    if (loggedInUser?.twilioBundleId && loggedInUser?.twilioAddressSids) {
      payload = {
        ...payload,
        bundleId: loggedInUser.twilioBundleId,
        addressId: loggedInUser.twilioAddressSids[0],
      };
    }

    provisionNumberRequestMutation(payload);
    setIsBookNumberModalVisible(false);
    closeStandAloneNumberModal();
  };
  // End of provision Number Logic

  const handleFormSubmit = (values: { country: string }) => {
    setSelectedCountry(values.country);
    closeStandAloneNumberModal();
    openBookNumberModal();
  };

  const handleConfirmAction = () => {
    if (formRef.current) {
      formRef.current.submit();
    }
  };
  const handleChangeTestingNumberConfirmAction = () => {
    if (testingNumberFormRef.current) {
      testingNumberFormRef.current.submit();
    }
  };

  const openStandAloneNumberModal = () => {
    posthog.capture('trying_to_get_standalone_number');
    setIsStandAloneNumberModalVisible(true);
  };

  const closeStandAloneNumberModal = () => {
    setIsStandAloneNumberModalVisible(false);
  };

  const openBookNumberModal = () => {
    setIsBookNumberModalVisible(true);
  };

  const closeBookNumberModal = () => {
    setIsBookNumberModalVisible(false);
  };

  const { mutate: changeTestingNumberMutation, status: changingTestingNumberStatus } = useMutation({
    mutationKey: ['changeTestingNumber'],
    mutationFn: updateTestingNumber,
    onSuccess(_, variables) {
      setIsChangeTestingModalVisible(false);
      dispatch(setTestingNumber({ testing_number: variables.testing_number }));
      notification.success({ message: 'Testing number changed successfully' });
    },
  });

  const handleChangeTestingNumberFormSubmit = (values: { testing_number: string }) => {
    changeTestingNumberMutation(values);
  };

  const IncrementTotalTestCallsMutation = useMutation({
    mutationKey: ['updatePendingTestingStatus'],
    mutationFn: incrementTotalTestCalls,
    onSuccess({ totalTestCalls }) {
      setTotalCalls(totalTestCalls);
    },
  });

  const makeVoidTestCall = useMutation({
    mutationKey: ['testCall'],
    mutationFn: makeTestCall,
    onSuccess() {
      setTotalCalls(totalCalls + 1);
      IncrementTotalTestCallsMutation.mutate();
      dispatch(updateUser({ user: { totalTestCalls: totalCalls + 1 } }));
    },
    onError() {
      setTotalCalls(totalCalls + 1);
      IncrementTotalTestCallsMutation.mutate();
      dispatch(updateUser({ user: { totalTestCalls: totalCalls + 1 } }));
    },
  });

  useEffect(() => {
    if (!loggedInUser.number) {
      return;
    }
    // getTotalCallsMutation.mutate();
    setTotalCalls(loggedInUser.totalTestCalls || 0);
  }, [loggedInUser]);

  if (isProvisionApiPending || isRequirementDispatchPending) {
    return (
      <div className='text-center'>
        <Spin />
      </div>
    );
  }

  return (
    <Card>
      {celeberate && (
        <ConfettiExplosion zIndex={1000} force={1} height='220vh' width={3000} particleCount={200} duration={5000} />
      )}
      {renderCentralComponent && (
        <>
          {!provisionedNumber ? (
            <div className='relative flex items-center justify-center'>
              <span className='animate-ping absolute inline-flex h-16 w-16 rounded-full bg-blue-400 opacity-75'></span>
              <Button
                className='bg-gradient-to-r py-6 from-blue-500 to-blue-700 text-white font-bold text-lg px-8 rounded-xl shadow-lg hover:from-blue-600 hover:to-blue-800 transform hover:scale-105 transition-transform duration-300 relative'
                onClick={openStandAloneNumberModal}
              >
                Get your Standalone Number <PhoneOutlined />
              </Button>
            </div>
          ) : (
            <div className='flex flex-col items-center justify-center'>
              <div className='flex flex-col items-center'>
                <h1 className='sm:text-6xl text-3xl font-bold text-button-success mb-2'>
                  <CopyableText text={provisionedNumber} />
                </h1>
                <p className='text-gray-700 sm:text-lg text-sm'>
                  This is your new AI number. You can forward calls to this number in the next step.
                </p>
              </div>
            </div>
          )}
        </>
      )}
      {!renderCentralComponent ? (
        !loggedInUser.isTrialAccount ? (
          <Space direction='vertical' size='large' style={{ width: '100%' }}>
            <Title level={2}>
              <PhoneOutlined /> Reserve your standalone number
            </Title>
            <Spin spinning={getProvisionNumberMutation.isPending}>
              {provisionedNumber ? (
                <>
                  <Paragraph>
                    Forward calls to <strong>{provisionedNumber}</strong> from your existing business phone. Or, use
                    this number as your business phone directly.
                  </Paragraph>
                  <Paragraph>
                    For instructions on how to forward calls,{' '}
                    <a
                      className='text-themeColor font-bold'
                      href='https://www.lifewire.com/how-to-forward-calls-4689010'
                    >
                      click here
                    </a>{' '}
                    .
                  </Paragraph>
                </>
              ) : loggedInUser?.twilioBundleId && loggedInUser?.twilioBundleStatus == 'twilio-approved' ? (
                <>
                  <Paragraph type='success'>
                    <CheckCircleOutlined /> Regulatory process successfully completed. You can now get your standalone
                    number.
                  </Paragraph>
                  <Button type='primary' onClick={openStandAloneNumberModal} icon={<PhoneOutlined />}>
                    Get your standalone number
                  </Button>
                </>
              ) : (loggedInUser?.twilioBundleId && loggedInUser?.twilioBundleStatus == 'in-review') ||
                loggedInUser?.twilioBundleStatus == 'pending-review' ? (
                <Paragraph>
                  <LoadingOutlined /> Your information is under review. It can take up to 2 to 3 business days to get it
                  approved.
                </Paragraph>
              ) : loggedInUser?.twilioBundleId && loggedInUser?.twilioBundleStatus == 'twilio-rejected' ? (
                <>
                  <Paragraph>Your regulatory process failed due to the following reasons:</Paragraph>
                  <Paragraph type='danger'>{loggedInUser?.twilioBundleFailureReason}</Paragraph>
                  <Button onClick={() => resetBundleMutation(loggedInUser?.number)}>Restart regulatory process</Button>
                </>
              ) : (
                <>
                  <Paragraph>
                    {loggedInUser.demo ? (
                      <>
                        You can test your receptionist by calling at{' '}
                        <b>{getCurrentReceptionistNumber(loggedInUser.testing_number as string, false)}</b> with your
                        testing number: <b>{loggedInUser.testing_number}</b>. Until you start your trial and reserve a
                        standalone number.
                      </>
                    ) : (
                      `Once you get your standalone number, you can forward calls to this number or use it as your business
                  number directly. It will always be linked to this account.`
                    )}
                  </Paragraph>
                  <div className='flex items-center gap-2'>
                    {loggedInUser.demo ? (
                      <>
                        <Button
                          type='primary'
                          onClick={setOpenedOnboarding ? () => setOpenedOnboarding(true) : undefined}
                          icon={<BuildOutlined />}
                        >
                          Complete onboarding to get an AI phone number
                        </Button>
                        <Button
                          type='primary'
                          onClick={() => setIsChangeTestingModalVisible(true)}
                          icon={<BuildOutlined />}
                        >
                          Change testing number
                        </Button>
                      </>
                    ) : (
                      <Button type='primary' onClick={openStandAloneNumberModal} icon={<PhoneOutlined />}>
                        Get your standalone number
                      </Button>
                    )}
                    <LearnMore tutorialId={TUTORIALS.PHONE_AND_TEXTING} />
                  </div>
                </>
              )}
            </Spin>
          </Space>
        ) : (
          <Space direction='vertical' size='large' style={{ width: '100%' }}>
            <Title level={2}>
              <PhoneOutlined /> Here is the testing number for your trial Account
            </Title>
            <Paragraph>
              Make call to <strong>{getCurrentReceptionistNumber(loggedInUser.testing_number as string, true)}</strong>{' '}
              to test the receptionist.
            </Paragraph>
            <Paragraph>
              {!freeTestCallsLimitExceeded ? (
                <div className='flex flex-col space-y-3'>
                  <p>
                    You have made <strong>{totalCalls}</strong> calls out of {TOTAL_CALLS_LIMIT} free test calls.
                  </p>
                  {currentReseller?.purchaseRedirectLink && (
                    <div className='mb-4'>
                      <p className='text-gray-700 mb-2'>
                        If you are satisfied with our call service press following button to purchase
                      </p>
                      <Button
                        type='primary'
                        icon={<ShoppingOutlined />}
                        className='!rounded-2xl min-w-[300px] max-w-[300px] min-h-[40px] bg-green-600 hover:bg-green-700'
                        onClick={() => window.open(currentReseller.purchaseRedirectLink, '_blank')}
                      >
                        Purchase Now
                      </Button>
                    </div>
                  )}
                  <Button
                    type='primary'
                    className='!rounded-2xl min-w-[300px] max-w-[300px] min-h-[40px]'
                    loading={makeVoidTestCall.isPending}
                    onClick={() => {
                      makeVoidTestCall.mutate();
                    }}
                  >
                    Make A Call
                  </Button>
                  <CustomOnboardingButton
                    onClick={() => {
                      setIsChangeTestingModalVisible(true);
                    }}
                    className='bg-red-400 max-w-[300px] max-h-11 !text-white hover:!text-stone-950'
                  >
                    <Typography.Text className='!text-white hover:!text-stone-950 min-w-[300px] min-h-[40px] !text-center !flex !justify-center !items-center'>
                      I don't have {loggedInUser.testing_number || '--'} with me.
                    </Typography.Text>
                  </CustomOnboardingButton>
                </div>
              ) : (
                <span>
                  You have made <strong>{totalCalls}</strong> calls out of {TOTAL_CALLS_LIMIT} free test calls. Your
                  free test calls limit has been exceeded.
                </span>
              )}
            </Paragraph>
          </Space>
        )
      ) : (
        <></>
      )}

      <CustomModal
        title={
          <span>
            Get your standalone number <LearnMore tutorialId={TUTORIALS.PICK_YOUR_AREA_CODE} />
          </span>
        }
        isModalOpen={isStandAloneNumberModalVisible}
        confirmAction={handleConfirmAction}
        cancelAction={closeStandAloneNumberModal}
        okButtonProps={{ loading: isProvisionApiPending }}
        okText='Get Random Number'
      >
        <GetStandAloneNumberForm
          ref={formRef}
          debrand={debrand}
          setCountry={(country: string) => setSelectedCountry(country)}
          onSubmit={handleFormSubmit}
          setChooseNumberModalOpen={setChooseNumberModalOpen}
        />
      </CustomModal>
      <CustomModal
        title='Get Your Favourite Area Code'
        isModalOpen={ChooseNumberModalOpen}
        confirmAction={() => {
          setChooseNumberModalOpen(false);
          setIsBookNumberModalVisible(false);
        }}
        cancelAction={() => setChooseNumberModalOpen(false)}
        footer={null}
      >
        <GetChooseNumberForm
          isOpen={ChooseNumberModalOpen}
          onClose={() => setChooseNumberModalOpen(false)}
          selectedCountryCode={selectedCountry.split(' - ')[2]}
          bookNumberRequest={props => bookNumberRequest(props ?? null)}
        />
      </CustomModal>
      <CustomModal
        title={null}
        isModalOpen={isNonUSDetailsModalOpen}
        confirmAction={() => setIsNonUSDetailsModalOpen(false)}
        cancelAction={() => setIsNonUSDetailsModalOpen(false)}
        loading={isRequirementDispatchPending}
        width={700}
        footer={null}
      >
        <GetBundleApproveForm
          countryCodeNumber={selectedCountry.split(' - ')[2]}
          twilioDocsRequirements={twilioDocsRequirements.requirements}
          regulationSid={twilioDocsRequirements.regulationSid}
          setClientDetails={(bundleId: string) => {
            getBundleIdMutation.mutate(bundleId);
          }}
          onClose={() => setIsNonUSDetailsModalOpen(false)}
        />
      </CustomModal>
      <CustomModal
        title='Save your changes'
        isModalOpen={isBookNumberModalVisible}
        confirmAction={() => bookNumberRequest(null)}
        cancelAction={closeBookNumberModal}
      >
        <Title level={4}>Click Ok to confirm you want to buy number for {selectedCountry.split('-')[1]} </Title>
      </CustomModal>
      {/* Change testing number modal  */}
      <CustomModal
        title='Change Testing Number'
        isModalOpen={changeTestingModalVisible}
        confirmAction={handleChangeTestingNumberConfirmAction}
        cancelAction={() => setIsChangeTestingModalVisible(false)}
        footer={[
          <Button key='back' onClick={() => setIsChangeTestingModalVisible(false)}>
            Cancel
          </Button>,
          <Button
            key='submit'
            type='primary'
            onClick={handleChangeTestingNumberConfirmAction}
            loading={changingTestingNumberStatus === 'pending'}
          >
            Change
          </Button>,
        ]}
      >
        <ChangeTestingNumberForm ref={testingNumberFormRef} onSubmit={handleChangeTestingNumberFormSubmit} />
      </CustomModal>
    </Card>
  );
};

export default ForwardCalls;
