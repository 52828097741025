import React from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import StepWrapper from './StepWrapper';

interface StepCOneIIProps {
  emailAddress: string;
  setEmailAddress: (email: string) => void;
  phoneNumber: string;
  setPhoneNumber: (number: string) => void;
  handleNext: () => void;
}

const PhoneNumberInput: React.FC<StepCOneIIProps> = ({ phoneNumber, setPhoneNumber, handleNext }) => {
  return (
    <StepWrapper handleNext={handleNext} disableNext={!phoneNumber}>
      <div className='w-full flex flex-col'>
        <div className='flex flex-col items-start w-full'>
          <h3 className='sm:text-3xl text-xl font-bold text-gray-900 text-start mb-0'>What's your phone number?</h3>
          <p className='text-gray-500 sm:text-lg text-sm'>
            We'll use this for account security and important notifications
          </p>
        </div>

        <PhoneInput
          placeholder='Enter phone number'
          country={'us'}
          preferredCountries={['us', 'ca', 'gb']}
          onChange={value => setPhoneNumber(value)}
          value={phoneNumber}
          autoFormat={true}
          containerStyle={{
            width: '100%',
          }}
          disableDropdown
          inputStyle={{
            width: '100%',
            height: '48px',
            fontSize: '16px',
            paddingLeft: '48px',
            borderRadius: '6px',
            border: '1px solid #d1d5db',
          }}
          buttonStyle={{
            border: '1px solid #d1d5db',
            borderRight: 'none',
            borderRadius: '6px 0 0 6px',
            backgroundColor: 'white',
          }}
          dropdownStyle={{
            width: 'max-content',
          }}
        />
      </div>
    </StepWrapper>
  );
};

export default PhoneNumberInput;
