import React from 'react';
import Fade from 'react-reveal/Fade';
import classes from '../Register.module.css';
import posthog from 'posthog-js';

interface Step5Props {
  setShowBusinessInformationModal: (value: boolean) => void;
  setStepState: (state: number) => void;
  stepState: number;
}

const Step5: React.FC<Step5Props> = ({ setShowBusinessInformationModal, setStepState, stepState }) => {
  return (
    <>
      <div className={classes.box}>
        {React.createElement(() => {
          posthog.capture('business_information_entered');
          return null;
        })}
        <Fade duration={1000} bottom>
          <>
            <h3
              className={classes.heading}
              style={{
                textAlign: 'center',
                marginBottom: '0rem',
              }}
            >
              (Optional) Business Information
            </h3>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <ul className={classes.list}>
                <li>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    height='20px'
                    width='20px'
                    version='1.1'
                    id='Capa_1'
                    viewBox='0 0 17.837 17.837'
                    style={{
                      marginRight: '.5rem',
                      transform: 'translateY(3px)',
                    }}
                  >
                    <g>
                      <path
                        style={{ fill: 'rgb(110, 121, 214)' }}
                        d='M16.145,2.571c-0.272-0.273-0.718-0.273-0.99,0L6.92,10.804l-4.241-4.27   c-0.272-0.274-0.715-0.274-0.989,0L0.204,8.019c-0.272,0.271-0.272,0.717,0,0.99l6.217,6.258c0.272,0.271,0.715,0.271,0.99,0   L17.63,5.047c0.276-0.273,0.276-0.72,0-0.994L16.145,2.571z'
                      />
                    </g>
                  </svg>
                  Your receptionist will be customized to your business
                </li>
                <li>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    height='20px'
                    width='20px'
                    version='1.1'
                    id='Capa_1'
                    viewBox='0 0 17.837 17.837'
                    style={{
                      marginRight: '.5rem',
                      transform: 'translateY(3px)',
                    }}
                  >
                    <g>
                      <path
                        style={{ fill: 'rgb(110, 121, 214)' }}
                        d='M16.145,2.571c-0.272-0.273-0.718-0.273-0.99,0L6.92,10.804l-4.241-4.27   c-0.272-0.274-0.715-0.274-0.989,0L0.204,8.019c-0.272,0.271-0.272,0.717,0,0.99l6.217,6.258c0.272,0.271,0.715,0.271,0.99,0   L17.63,5.047c0.276-0.273,0.276-0.72,0-0.994L16.145,2.571z'
                      />
                    </g>
                  </svg>
                  You can paste 20 pages of information into your receptionist
                </li>
                <li>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    height='20px'
                    width='20px'
                    version='1.1'
                    id='Capa_1'
                    viewBox='0 0 17.837 17.837'
                    style={{
                      marginRight: '.5rem',
                      transform: 'translateY(3px)',
                    }}
                  >
                    <g>
                      <path
                        style={{ fill: 'rgb(110, 121, 214)' }}
                        d='M16.145,2.571c-0.272-0.273-0.718-0.273-0.99,0L6.92,10.804l-4.241-4.27   c-0.272-0.274-0.715-0.274-0.989,0L0.204,8.019c-0.272,0.271-0.272,0.717,0,0.99l6.217,6.258c0.272,0.271,0.715,0.271,0.99,0   L17.63,5.047c0.276-0.273,0.276-0.72,0-0.994L16.145,2.571z'
                      />
                    </g>
                  </svg>
                  Your receptionist will form a knowledge base around it
                </li>
              </ul>
            </div>

            <button
              type='button'
              onClick={() => setShowBusinessInformationModal(true)}
              className={`${classes.button} ${classes.centered}`}
              style={{
                marginTop: '2rem',
              }}
            >
              Enter Business Information
            </button>

            <div onClick={() => setStepState(stepState + 1)} className={classes.later}>
              I'll do this later
            </div>
          </>
        </Fade>
      </div>
    </>
  );
};

export default Step5;
