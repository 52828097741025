import React, { useState } from 'react';
import { Input } from 'antd';
import { UserIcon } from 'lucide-react';
import StepWrapper from './StepWrapper';

interface StepCTwoProps {
  username: string;
  setUsername: (username: string) => void;
  password: string;
  setPassword: (password: string) => void;
  handleNext: () => void;
}

const UsernameInput: React.FC<StepCTwoProps> = ({ username, setUsername, handleNext }) => {
  const [error, setError] = useState<string>('');

  const handleUsernameChange = (value: string) => {
    const cleaned = value.replace(/[^a-zA-Z0-9_-]/g, '');
    setUsername(cleaned);

    if (!cleaned) {
      setError('Username is required');
    } else if (cleaned.length < 3) {
      setError('Username must be at least 3 characters');
    } else if (cleaned.length > 20) {
      setError('Username must be less than 20 characters');
    } else {
      setError('');
    }
  };

  return (
    <StepWrapper handleNext={handleNext} disableNext={!username || !!error}>
      <div className='w-full flex flex-col'>
        <div className='flex flex-col items-start w-full'>
          <h3 className='sm:text-3xl text-xl font-bold text-gray-900 text-start mb-0'>Choose your username</h3>
          <p className='text-gray-500 sm:text-lg text-sm'>This will be your unique identifier for your AI assistant</p>
        </div>

        <div>
          <Input
            id='username'
            value={username}
            onChange={e => handleUsernameChange(e.target.value)}
            placeholder='Enter username'
            prefix={<UserIcon className='h-5 w-5 text-gray-400 mr-1' />}
            className={`h-12 ${error ? 'border-red-500' : ''}`}
            size='large'
            maxLength={20}
            status={error ? 'error' : ''}
          />
          {error && <p className='mt-2 text-sm text-red-500'>{error}</p>}
          <p className='mt-2 text-sm text-gray-500 mb-0'>
            Use letters, numbers, underscores or hyphens (3-20 characters)
          </p>
        </div>
      </div>
    </StepWrapper>
  );
};

export default UsernameInput;
