import { Col, Typography } from 'antd';
import './IconCard.scss';

const { Title, Paragraph } = Typography;

interface IconCardProps {
  onClick?: (e: any) => void;
  title: string;
  description: string;
  icon?: React.ReactNode;
  className?: string;
  comingSoon?: boolean;
}

const IconCard = ({ onClick, title, description, icon, comingSoon, className }: IconCardProps) => {
  return (
    <Col
      className={`relative ${className} ${comingSoon ? 'pointer-events-none' : ''} flex flex-col m-2 justify-center text-center items-center space-y-4 border-dotted border p-6 cursor-pointer rounded-2xl hover:shadow-lg icon-card`}
      onClick={onClick}
      span={10}
    >
      {comingSoon && (
        <div className='absolute -top-1 -right-1 w-32 h-32 overflow-hidden'>
          <div className='absolute transform rotate-45 bg-blue-500 text-white font-semibold py-1 right-[-35px] top-[32px] w-[170px] text-center'>
            Coming Soon
          </div>
        </div>
      )}
      <div className={`flex flex-col items-center justify-center ${comingSoon ? 'opacity-20' : ''}`}>
        {icon && <>{icon}</>}
        <Title level={4}>{title}</Title>
        <Paragraph className='opacity-50'>{description}</Paragraph>
      </div>
    </Col>
  );
};

export default IconCard;
