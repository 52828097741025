import React, { useState } from 'react';
import { Copy, Check } from 'lucide-react';

const CopyableText: React.FC<{ displayText?: string; text: string; hideText?: boolean }> = ({
  displayText,
  text,
  hideText = false,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(text);
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000);
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  return (
    <div
      className='group relative inline-flex items-center gap-1'
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {!hideText && <span>{displayText || text}</span>}
      <button
        type='button'
        onClick={handleCopy}
        className={`
          flex items-center justify-center
          w-6 h-6 rounded-full
          transition-all duration-300 ease-in-out
          ${isHovered ? 'opacity-100 scale-100' : 'opacity-0 scale-75'}
          hover:bg-gray-100 active:bg-gray-200
        `}
        aria-label='Copy to clipboard'
      >
        {isCopied ? <Check className='w-4 h-4 text-green-500' /> : <Copy className='w-4 h-4 text-gray-500' />}
      </button>
    </div>
  );
};

export default CopyableText;

// // Example usage
// export default function Demo() {
//   return (
//     <div className='p-8 space-y-4'>
//       <CopyableText text='Hello, hover over me!' />
//     </div>
//   );
// }
