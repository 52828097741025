import React from 'react';
import Fade from 'react-reveal/Fade';
import classes from '../RegisterPageV3.module.css';
import TextEditor from '../components/TextEditor/TextEditor';
import { Button } from 'antd';

const Step5A = ({
  setShowBusinessInformationModal,
  companyInfo,
  campaignVariables,
  setCompanyInfo,
  setCampaignVariables,
  businessName,
  setBusinessInformationTemplateModalActive,
  isUsingATemplate,
  businessType,
  stepState,
  showBusinessInformationModal,
  isCampaign,
  handleStepTransition,
  stepLoading,
  createClientStepByStep,
}) => {
  return (
    <>
      <div
        className={classes.box + ' ' + classes.modal + ' ' + (showBusinessInformationModal && classes.active)}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <div className={classes.close} onClick={() => setShowBusinessInformationModal(false)}>
          &times;
        </div>
        <Fade duration={1000} bottom>
          <h3
            className={classes.heading}
            style={{
              textAlign: 'center',
            }}
          >
            Enter Business Information
          </h3>

          <div className={classes.subtitle + ' '}>
            Paste any information about your business. Your receptionist will use this to answer questions. For example,
            your business services, prices, and mission. Don't worry about formatting. The AI is smart enough to
            effectively interpret. Maximum 20 pages of text.
          </div>

          {isCampaign ? (
            <TextEditor
              html={companyInfo}
              vvariables={campaignVariables}
              onChange={(value, variables) => {
                setCompanyInfo(value);
                setCampaignVariables(variables);
              }}
              placeholder={`e.g. ${businessName} is a popular spot for men looking for a classic haircut experience. Here is some more information about our business and services...`}
            />
          ) : (
            <textarea
              className={classes.input + ' ' + classes.textarea}
              required
              rows={7}
              style={{ fontFamily: 'Quicksand, sans-serif' }}
              value={companyInfo}
              onChange={event => {
                const value = event.target.value;
                const words = value.trim().split(/\s+/);
                if (words.length <= 7000) {
                  setCompanyInfo(value);
                }
              }}
              placeholder={`e.g. ${businessName} is a popular spot for men looking for a classic haircut experience. Here is some more information about our business and services...`}
            ></textarea>
          )}

          <button
            className={classes.whiteButton + ' ' + classes.flexCentered}
            style={{
              marginTop: '1rem',
              width: 'max-content',
              maxWidth: 'max-content',
            }}
            onClick={event => {
              event.preventDefault(); // Prevent the button from scrolling up

              setBusinessInformationTemplateModalActive(true);
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                style={{
                  strokeWidth: '1.5px',
                  height: '16px',
                  width: '16px',
                  marginRight: '8px',
                }}
                viewBox='0 0 24 24'
                stroke-width='1.5'
                stroke='currentColor'
                aria-hidden='true'
              >
                <path
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  d='M2.25 12.75V12A2.25 2.25 0 014.5 9.75h15A2.25 2.25 0 0121.75 12v.75m-8.69-6.44l-2.12-2.12a1.5 1.5 0 00-1.061-.44H4.5A2.25 2.25 0 002.25 6v12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9a2.25 2.25 0 00-2.25-2.25h-5.379a1.5 1.5 0 01-1.06-.44z'
                ></path>
              </svg>
              {isUsingATemplate && companyInfo ? `Using ${businessType} Template` : 'Use a Template'}
            </div>
          </button>

          <Button
            type='primary'
            onClick={async () => {
              await handleStepTransition(stepState + 1);
              setShowBusinessInformationModal(false);
            }}
            className={classes.button + ' ' + classes.centered}
            style={{ marginTop: '2rem' }}
            loading={stepLoading || createClientStepByStep.isPending}
          >
            Continue
          </Button>
        </Fade>
      </div>
    </>
  );
};

export default Step5A;
