import { useState, useEffect, useCallback } from 'react';
import { useAppDispatch } from '@/store/hooks/useAppDispatch';
import { closeModal } from '@/store/modal/slice';
import { MODAL_IDS } from 'src/enums/EModal';
import { Typography, Space } from 'antd';
import { useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { CustomModal } from '@/components/CustomModal';
import { getNoviCustomerSecret } from '@/api/novi/user';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { getModalData } from '@/store/modal/selector';

const { Text, Title } = Typography;

export const NoviPlansModal = () => {
  const dispatch = useAppDispatch();
  const modalData = useAppSelector<any>(getModalData);
  const [plansModalOpen, setPlansModalOpen] = useState(true);
  const [showStripeTable, setShowStripeTable] = useState(false);
  const [customerSecret, setCustomerSecret] = useState<any>(null);
  const clientReferenceId = modalData?.resellerView ? 'WHITELABEL_OUTBOUND' : 'NOVI';

  // UseQuery to get Stripe client secret
  const { data, error, refetch } = useQuery({
    queryKey: ['getNoviCustomerSecret'],
    queryFn: () => getNoviCustomerSecret(),
    enabled: plansModalOpen, // Only run the query if the modal is open
  });

  // Handle success and error within useEffect
  useEffect(() => {
    if (data) {
      if (data.success && data.customerSecret) {
        setCustomerSecret(data.customerSecret);
        setShowStripeTable(true);
      } else {
        // toast.error('Error fetching customer secret. Please try again later.');
      }
    }
    if (error) {
      // toast.error('Error fetching customer secret. Please try again later.');
    }
  }, [data, error]);

  // Function to refresh customer secret manually
  const refreshCustomerSecret = useCallback(async () => {
    const refreshedData = await refetch();
    if (refreshedData.data?.success && refreshedData.data?.customerSecret) {
      setCustomerSecret(refreshedData.data.customerSecret);
      setShowStripeTable(true);
    } else {
      toast.error('Failed to refresh the session. Please try again.');
    }
  }, [refetch]);

  // Set a timer to refresh the client secret before it expires
  useEffect(() => {
    if (showStripeTable && customerSecret) {
      const timer = setTimeout(
        () => {
          refreshCustomerSecret();
        },
        1000 * 60 * 25
      ); // Refresh after 25 minutes

      return () => clearTimeout(timer); // Cleanup the timer on unmount
    }
  }, [showStripeTable, customerSecret, refreshCustomerSecret]);
  return (
    <CustomModal
      width={'80%'}
      title={null}
      modalId={MODAL_IDS.NOVI_PLANS_MODAL}
      onClose={() => {
        dispatch(closeModal());
        setPlansModalOpen(false); // Reset state when modal is closed
        setShowStripeTable(false); // Reset state when modal is closed
      }}
      footer={null}
    >
      {showStripeTable && customerSecret ? (
        // @ts-ignore

        <stripe-pricing-table
          pricing-table-id={
            modalData?.resellerView
              ? import.meta.env.VITE_APP_WHITELABEL_OUTBOUND_PRICING_TABLD_ID
              : import.meta.env.VITE_APP_NOVI_PRICING_TABLE_ID
          }
          publishable-key={
            modalData?.resellerView
              ? import.meta.env.VITE_APP_WHITELABEL_OUTBOUND_PRICING_TABLE_KEY
              : import.meta.env.VITE_APP_NOVI_PRICING_TABLE_KEY
          }
          customer-session-client-secret={customerSecret?.client_secret || undefined}
          client-reference-id={clientReferenceId}
        />
      ) : (
        <Space direction='vertical' size='large' style={{ textAlign: 'center', width: '100%' }}>
          <Title level={4}>Plans</Title>
          <Text type='secondary'>Fetching plans...</Text>
        </Space>
      )}
    </CustomModal>
  );
};

export default NoviPlansModal;
