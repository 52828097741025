import { KeyOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { useAppDispatch } from '@/store/hooks/useAppDispatch';
import { closeModal } from '@/store/modal/slice';
import { setNoviCurrentPage } from '@/store/novi/user/slice';
import { ENoviDashboardSider } from '../../enums/ENoviDashboardSider';
import { CustomModal } from '@/components/CustomModal';
import { MODAL_IDS } from 'src/enums/EModal';

const RedirectTwilioConfigModal = () => {
  const dispatch = useAppDispatch();

  const handleClose = () => {
    dispatch(closeModal());
  };

  const handleConfigure = () => {
    dispatch(setNoviCurrentPage(ENoviDashboardSider.BYOT));
    handleClose();
  };

  return (
    <CustomModal
      width={480}
      title={null}
      modalId={MODAL_IDS.REDIRECT_TWILIO_CONFIG}
      onClose={handleClose}
      footer={null}
      maskClosable={false}
    >
      <div className='flex flex-col items-center text-center p-6'>
        {/* Icon Container */}
        <div className='w-16 h-16 bg-blue-50 rounded-full flex items-center justify-center mb-6'>
          <KeyOutlined className='text-2xl text-blue-500' />
        </div>

        {/* Content */}
        <div className='space-y-4 mb-8'>
          <h3 className='text-xl font-semibold text-gray-900'>Twilio Configuration Required</h3>
          <p className='text-gray-600 text-sm leading-relaxed max-w-sm'>
            To ensure seamless communication with your customers, please configure your Twilio credentials. This is a
            one-time setup that enables voice and messaging capabilities.
          </p>
        </div>

        {/* Feature List */}
        <div className='bg-gray-50 rounded-lg p-4 w-full mb-8'>
          <div className='text-left space-y-3'>
            <div className='flex items-start'>
              <div className='flex-shrink-0 h-5 w-5 relative top-0.5'>
                <span className='flex h-2 w-2 relative top-1.5 left-1.5'>
                  <span className='animate-ping absolute  top-0.4 left-0.4 inline-flex h-full w-full rounded-full bg-blue-400 opacity-75'></span>
                  <span className='relative inline-flex rounded-full h-2 w-2 bg-blue-500'></span>
                </span>
              </div>
              <div className='ml-3'>
                <p className='text-sm font-medium text-gray-900'>Secure Communications</p>
                <p className='text-xs text-gray-500'>Enable encrypted voice and messaging</p>
              </div>
            </div>
            <div className='flex items-start'>
              <div className='flex-shrink-0 h-5 w-5 relative top-0.5'>
                <span className='flex h-2 w-2 relative top-1.5 left-1.5'>
                  <span className='animate-ping absolute top-0.4 left-0.4 inline-flex h-full w-full rounded-full bg-blue-400 opacity-75'></span>
                  <span className='relative inline-flex rounded-full h-2 w-2 bg-blue-500'></span>
                </span>
              </div>
              <div className='ml-3'>
                <p className='text-sm font-medium text-gray-900'>Global Coverage</p>
                <p className='text-xs text-gray-500'>Access worldwide communication network</p>
              </div>
            </div>
          </div>
        </div>

        {/* Actions */}
        <div className='flex flex-col gap-3 w-full'>
          <button
            onClick={handleConfigure}
            className='w-full bg-blue-600 text-white px-4 py-2.5 rounded-lg font-medium hover:bg-blue-700 transition-colors flex items-center justify-center gap-2 group'
          >
            Configure Now
            <ArrowRightOutlined className='group-hover:translate-x-0.5 transition-transform' />
          </button>
          <button
            onClick={handleClose}
            className='w-full px-4 py-2.5 text-gray-600 hover:text-gray-800 transition-colors text-sm'
          >
            I'll do this later
          </button>
        </div>
      </div>
    </CustomModal>
  );
};

export default RedirectTwilioConfigModal;
