import apiClient from '@/utils/apiClient';

export const updateCampaignVariables = async (
  campaign_id: string,
  variables: any
): Promise<{
  message: string;
  variables: any;
}> => {
  if (!campaign_id) {
    return { message: 'Campaign ID is required', variables: [] };
  }
  try {
    const response = await apiClient.request({
      method: 'PATCH',
      endPoint: `/api/novi/campaigns/${campaign_id}/updateVariables`,
      body: { variables },
      clientType: 'NOVI',
    });
    return response.data;
  } catch (error) {
    throw new Error('Error updating campaign variables: ' + (error as Error).message);
  }
};
