import { Navigate, Outlet } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useMemo } from 'react';

import LocalStorageUtility from '@/utils/localStorage';
import { LocalStorageKeys } from 'src/enums/ELocalStorage';
import { NoviUser } from '../types/NoviUser';
import { getNoviUserDetails } from '@/api/novi/user';
import { useAppDispatch } from '@/store/hooks/useAppDispatch';
import { setNoviUser } from '@/store/novi/user/slice';
import { Loading } from '@/components/Loading';
import { AppRoutes } from 'src/enums/ERoutes';

interface DecodedToken {
  exp: number;
  [key: string]: any;
}

const REQUIRED_USER_FIELDS: (keyof NoviUser)[] = ['email_address', 'business_name', 'is_terms_accepted', 'logo'];

const isTokenExpired = (token: string): boolean => {
  try {
    const cleanToken = token.replace(/^Bearer\s+/i, '').trim();
    const { exp } = jwtDecode<DecodedToken>(cleanToken);
    return exp < Math.floor(Date.now() / 1000);
  } catch {
    return true;
  }
};

const isUserDataValid = (user: NoviUser | null): boolean => {
  if (!user) return false;
  return REQUIRED_USER_FIELDS.every(key => user[key] != null);
};

const useAuthToken = () => {
  return useMemo(() => {
    try {
      const token = LocalStorageUtility.getLocalData(LocalStorageKeys.NOVI_AUTH_TOKEN) as string | null;
      return token && !isTokenExpired(token) ? token : null;
    } catch {
      return null;
    }
  }, []);
};

const NoviPublicRoute: React.FC = () => {
  const dispatch = useAppDispatch();
  const authToken = useAuthToken();

  const {
    data: noviUserResponse,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ['getNoviUser'],
    queryFn: getNoviUserDetails,
    enabled: !!authToken,
    retry: false,
  });

  const isAuthenticated = useMemo(() => {
    if (!authToken || isError || !noviUserResponse?.user || !isUserDataValid(noviUserResponse.user)) {
      return false;
    }
    return true;
  }, [authToken, isError, noviUserResponse]);

  useEffect(() => {
    if (noviUserResponse?.user && isUserDataValid(noviUserResponse.user)) {
      dispatch(setNoviUser(noviUserResponse.user));
    }
  }, [noviUserResponse, dispatch]);

  useEffect(() => {
    if (!authToken || isError || (!isLoading && !isAuthenticated)) {
      LocalStorageUtility.clearLocalData();
    }
  }, [authToken, isError, isLoading, isAuthenticated]);

  if (isLoading) {
    return <Loading />;
  }

  return isAuthenticated ? (
    window.location.pathname !== '/novi-portal/' ? (
      <Navigate to='/novi-portal/' replace />
    ) : (
      <Outlet />
    )
  ) : window.location.pathname !== AppRoutes.NOVI_PORTAL_LOGIN ? (
    <Navigate to={AppRoutes.NOVI_PORTAL_LOGIN} replace />
  ) : (
    <Outlet />
  );
};

export default NoviPublicRoute;
