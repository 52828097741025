import { LabelWithDescription } from '@/components/LabelWithdescription';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Card, Flex, Form, Input, notification, Select, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { OverViewCardForm } from '../OverviewCardForm';
import { QueryObserverResult, RefetchOptions, UseMutationResult } from '@tanstack/react-query';
import { InitialScenario } from '@/types/TPhoneForm';
import { useAppDispatch } from '@/store/hooks/useAppDispatch';
import { closeModal } from '@/store/modal/slice';
import { User } from '@/types/TAccounts';

interface PostCallMessageFormProps {
  initialValues?: InitialScenario;
  actionType: 'EDIT' | 'ADD';
  showAddFieldButton?: boolean;
  refetch: (options?: RefetchOptions) => Promise<QueryObserverResult<User, Error>>;
  mutation: UseMutationResult<{}, Error, any, unknown>;
  scenarioIndex?: number;
}

interface Scenario {
  triggerType: 'every-call' | 'duration-check' | 'custom-scenario';
  duration?: number;
  customScenario?: string;
  textMessage: string;
}

const PostCallMessageForm: React.FC<PostCallMessageFormProps> = ({
  initialValues,
  showAddFieldButton = true,
  refetch,
  actionType,
  mutation,
  scenarioIndex = 0,
}) => {
  const [form] = Form.useForm();
  const [isFieldTouchReset, setIsFieldTouchReset] = useState<boolean>(true);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (initialValues && actionType === 'EDIT') {
      form.setFieldsValue(initialValues);
    }

    if (actionType === 'ADD') {
      form.resetFields();
    }

    return () => {
      form.resetFields();
    };
  }, [initialValues, form, actionType]);

  const getTextMessagePlaceholder = (triggerType: string) => {
    switch (triggerType) {
      case 'every-call':
        return "Thank you for calling us today! We appreciate your business. Here's our feedback form: www.example.com/feedback";
      case 'duration-check':
        return 'We noticed your call was brief. Is there anything we can help you with? Please let us know: www.example.com/support';
      case 'custom-scenario':
        return "Based on your interest in our services, here's some additional information that might be helpful: www.example.com/info";
      default:
        return 'Thank you for calling us today! Please let us know if you have any questions.';
    }
  };

  const handleSubmit = async (values: { scenarios: Scenario[] }) => {
    try {
      const cleanedScenarios = values.scenarios.map(scenario => {
        const cleanedScenario: Scenario = {
          triggerType: scenario.triggerType,
          textMessage: scenario.textMessage,
        };

        // Only include duration for duration-check type
        if (scenario.triggerType === 'duration-check') {
          cleanedScenario.duration = scenario.duration;
        }

        // Only include customScenario for custom-scenario type
        if (scenario.triggerType === 'custom-scenario') {
          cleanedScenario.customScenario = scenario.customScenario;
        }

        return cleanedScenario;
      });

      await mutation.mutateAsync({ scenarios: cleanedScenarios });
      refetch();
      notification.success({
        message: 'Details have been saved successfully!',
        duration: 3,
      });
      setIsFieldTouchReset(false);
      dispatch(closeModal());
    } catch (err) {
      console.error(err);
      notification.error({
        message: 'Something went wrong, please try again later!',
      });
    }
  };

  const FormItems = () => (
    <Form.List name='scenarios' initialValue={[{ triggerType: 'every-call', duration: 10 }]}>
      {(fields, { add, remove }) => {
        const formValues = form.getFieldsValue(true);
        const currentScenarios = formValues?.scenarios || [];

        // Safely check for existing trigger types
        const hasEveryCall = currentScenarios.some(
          (scenario: Scenario | undefined) => scenario?.triggerType === 'every-call'
        );
        const hasDurationCheck = currentScenarios.some(
          (scenario: Scenario | undefined) => scenario?.triggerType === 'duration-check'
        );

        return (
          <>
            {fields.map(({ key, name, ...restField }, index) => {
              const currentScenario = currentScenarios[index];

              return (
                <Flex key={key} className='mb-2' gap={8}>
                  <Card
                    className='flex-1'
                    title={
                      <div className='flex justify-between'>
                        <span className='font-bold text-base'>Scenario {scenarioIndex + index + 1}</span>
                        {fields.length > 1 && showAddFieldButton && (
                          <Button type='primary' danger onClick={() => remove(name)}>
                            Delete
                          </Button>
                        )}
                      </div>
                    }
                  >
                    <div className='border-b pb-4'>
                      <LabelWithDescription
                        customClassName='mt-2'
                        label='Trigger Type'
                        description='Specify when to send the text message'
                      />
                      <Form.Item
                        {...restField}
                        name={[name, 'triggerType']}
                        rules={[{ required: true, message: 'Please select a trigger type' }]}
                      >
                        <Select
                          className='mt-1'
                          options={[
                            {
                              value: 'every-call',
                              label: 'After Every Call',
                              disabled: hasEveryCall && currentScenario?.triggerType !== 'every-call',
                            },
                            {
                              value: 'duration-check',
                              label: 'If the duration is less than',
                              disabled: hasDurationCheck && currentScenario?.triggerType !== 'duration-check',
                            },
                            {
                              value: 'custom-scenario',
                              label: 'Custom Scenario',
                            },
                          ]}
                        />
                      </Form.Item>

                      <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) =>
                          prevValues?.scenarios?.[name]?.triggerType !== currentValues?.scenarios?.[name]?.triggerType
                        }
                      >
                        {({ getFieldValue }) => {
                          const triggerType = getFieldValue(['scenarios', name, 'triggerType']);

                          if (triggerType === 'duration-check') {
                            return (
                              <div className='flex items-center gap-4 max-w-xs'>
                                <Form.Item
                                  {...restField}
                                  name={[name, 'duration']}
                                  initialValue={10}
                                  rules={[{ required: true, message: 'Please enter duration' }]}
                                  className='flex-grow !mb-0'
                                >
                                  <Input type='number' min={1} placeholder='10' />
                                </Form.Item>
                                <span>seconds</span>
                              </div>
                            );
                          }

                          if (triggerType === 'custom-scenario') {
                            return (
                              <Form.Item
                                {...restField}
                                name={[name, 'customScenario']}
                                rules={[{ required: true, message: 'Please describe the scenario' }]}
                              >
                                <Input.TextArea placeholder='Describe your custom scenario (e.g., If caller mentioned calling back)' />
                              </Form.Item>
                            );
                          }

                          return null;
                        }}
                      </Form.Item>
                    </div>

                    <div className='mt-4'>
                      <LabelWithDescription
                        label='Text Message'
                        description='The message that will be sent to the user'
                      />
                      <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) =>
                          prevValues?.scenarios?.[name]?.triggerType !== currentValues?.scenarios?.[name]?.triggerType
                        }
                      >
                        {({ getFieldValue }) => {
                          const triggerType = getFieldValue(['scenarios', name, 'triggerType']);
                          return (
                            <Form.Item
                              {...restField}
                              name={[name, 'textMessage']}
                              rules={[{ required: true, message: 'Please enter a text message' }]}
                            >
                              <Input.TextArea className='mt-1' placeholder={getTextMessagePlaceholder(triggerType)} />
                            </Form.Item>
                          );
                        }}
                      </Form.Item>
                    </div>
                  </Card>
                </Flex>
              );
            })}

            {showAddFieldButton && (
              <Form.Item>
                <Button
                  type='dashed'
                  onClick={() => {
                    // Add a new scenario with custom-scenario as default since other types might be used
                    add({ triggerType: 'custom-scenario' });
                  }}
                  block
                  icon={<PlusOutlined />}
                >
                  Add Another Scenario
                </Button>
              </Form.Item>
            )}
          </>
        );
      }}
    </Form.List>
  );

  return (
    <Spin spinning={mutation.isPending} indicator={<LoadingOutlined style={{ fontSize: '48px' }} spin />}>
      <OverViewCardForm
        form={form}
        initialValues={actionType === 'EDIT' && initialValues}
        formItems={<FormItems />}
        handleSubmit={handleSubmit}
        isFieldTouchReset={isFieldTouchReset}
      />
    </Spin>
  );
};

export default PostCallMessageForm;
