import React from 'react';
import Fade from 'react-reveal/Fade';
import classes from '../RegisterPageV3.module.css';
import posthog from 'posthog-js';
import { Button, notification } from 'antd';

const Step9 = ({
  reseller_email,
  campaign_user_email,
  country,
  handleCountryChange,
  handletestingNumberChange,
  testingNumber,
  countries,
  stepState,
  handleStepTransition,
  stepLoading,
}) => {
  return (
    <>
      <div className={classes.box}>
        <Fade duration={1000} bottom>
          {React.createElement(() => {
            posthog.capture('testing_number_entered');
            return null;
          })}

          <h3
            className={classes.heading}
            style={{
              textAlign: 'center',
            }}
          >
            Testing Call: Your Phone Number
          </h3>
          <div
            className={classes.subtitle}
            style={{
              textAlign: 'center',
            }}
          >
            {reseller_email || campaign_user_email
              ? 'Use your client’s phone number here. This is what they will use to login to their unbranded portal. This number has nothing to do with your receptionist’s number, it is just used to login'
              : "You will test your receptionist by calling our test number from your phone. Please enter the number you'll use to make the test call."}
          </div>
          <select
            value={country?.name}
            className={classes.input + ' ' + classes.centered}
            onChange={handleCountryChange}
            style={{
              padding: '0.5rem',
              borderRadius: '10px',
              marginBottom: '1rem',
            }}
          >
            {countries.map(c => (
              <option key={c.name} value={c.name}>
                {c.name}
              </option>
            ))}
          </select>
          <input
            type='tel'
            value={testingNumber}
            className={classes.input + ' ' + classes.centered}
            onChange={handletestingNumberChange}
            required
            style={{
              padding: '0.5rem',
              borderRadius: '10px',
              marginBottom: '1rem',
            }}
            placeholder={country?.code}
            autoComplete='username'
          />

          <Button
            type='primary'
            onClick={() => {
              if (!country?.name) {
                notification.error({ message: 'Please select a country', duration: 1 });
                return;
              }
              if (!testingNumber || testingNumber.length < 6) {
                notification.error({ message: 'Please enter your phone number', duration: 1 });
                return;
              }
              if (reseller_email) {
                handleStepTransition(9);
              } else {
                handleStepTransition(stepState + 1);
              }
            }}
            className={classes.button + ' ' + classes.centered}
            style={{ marginTop: '2rem' }}
            loading={stepLoading}
          >
            Continue
          </Button>
        </Fade>
      </div>
    </>
  );
};

export default Step9;
