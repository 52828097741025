import { TAnalytics, TTextLogs } from '@/types/TAnalytics';
import { Line } from '@ant-design/plots';
import moment from 'moment';
import { useDateFormatFromCountry } from 'src/common/hooks/useDateFormatFromCountry';

export const AllTextLogs = ({ analyticsData }: TAnalytics) => {
  const { dateFormat } = useDateFormatFromCountry();
  const config = {
    data: analyticsData,
    xField: (row: TTextLogs) => moment(row.date).format(`ddd ${dateFormat}`),
    yField: 'value',
    colorField: 'value',
    point: {
      shapeField: 'square',
      sizeField: 4,
    },
    legend: false,
    interaction: {
      tooltip: {
        marker: false,
      },
    },
    style: {
      lineWidth: 3,
      gradient: 'y',
    },
    scale: {
      color: { range: ['#6e79d6'] },
    },
    tooltip: {
      title: 'date',
      items: [{ name: 'Total Texts', channel: 'y' }],
    },
  };
  return <Line {...config} />;
};
