import React from 'react';
import Fade from 'react-reveal/Fade';
import classes from '../Register.module.css';
import { Button } from 'antd';

interface Step2Props {
  stepState: number;
  setStepState: (state: number) => void;
  languages: string[];
  handleLanguageChange: (language: string) => void;
  selectedLanguages: string[];
  handleStepTransition: (
    nextStep: number,
    validationFn?: () => Promise<boolean>,
    stateMutationFn?: () => Promise<void>
  ) => Promise<void>;
  step3Validation: () => Promise<boolean>;
  stepLoading: boolean;
}

const Step2: React.FC<Step2Props> = ({
  stepState,
  languages,
  handleLanguageChange,
  selectedLanguages,
  handleStepTransition,
  step3Validation,
  stepLoading,
}) => {
  return (
    <>
      <div className={classes.box}>
        <Fade duration={1000} bottom>
          <>
            <h3
              className={classes.heading}
              style={{
                textAlign: 'center',
              }}
            >
              Select Languages (Optional)
            </h3>

            <div
              className={classes.subtitle}
              style={{
                textAlign: 'center',
              }}
            >
              Select the languages you want your receptionist to speak.
            </div>

            <Fade duration={1000} bottom>
              <div className={`${classes.themeChooser} ${classes.languagesChooser}`}>
                {languages.map(language => (
                  <div
                    key={language}
                    onClick={() => handleLanguageChange(language)}
                    className={`${classes.themeBox} ${selectedLanguages.includes(language) ? classes.active : ''}`}
                  >
                    {language}
                  </div>
                ))}
              </div>
            </Fade>
          </>
          <Button
            type='primary'
            onClick={() => handleStepTransition(stepState + 1, step3Validation)}
            className={classes.button + ' ' + classes.centered}
            style={{ marginTop: '2rem' }}
            loading={stepLoading}
          >
            Continue
          </Button>
        </Fade>
      </div>
    </>
  );
};

export default Step2;
