import { forwardRef, useEffect, useState } from 'react';
import { Button, Card, Form, Input, Space, FormInstance, Typography, Alert, Tooltip, notification, Tag } from 'antd';
import { QuestionCircleOutlined, SaveOutlined, CloseOutlined } from '@ant-design/icons';
import TextEditor from '../../../../v1/components/TextEditor/TextEditor';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { getSelectedCampaign } from '@/store/novi/user/selector';
import { updateCampaignVariables } from '@/api/novi/CamapaignPortalApis';
import { useMutation } from '@tanstack/react-query';

const { Title } = Typography;

interface TextCampaignFormProps {
  initialValues?: any;
  onSubmit?: (values: any) => void;
  onEdit?: (values: any) => void;
  onCancel?: () => void;
  isLoading: boolean;
  form?: FormInstance;
  mode?: 'create' | 'edit';
  showCancelBtn?: boolean;
}

const TextCampaignForm = forwardRef<FormInstance, TextCampaignFormProps>(
  ({ onSubmit, onEdit, onCancel, isLoading, form: parentForm, initialValues, mode = 'create', showCancelBtn }, ref) => {
    const [form] = Form.useForm();
    const formInstance = parentForm || form;
    const selectedCampaign = useAppSelector(getSelectedCampaign);
    const [variables, setVariables] = useState<any[]>(
      selectedCampaign?.variables?.length ? JSON.parse(selectedCampaign?.variables) : []
    );

    useEffect(() => {
      if (initialValues && Object.keys(initialValues).length > 0) {
        formInstance.resetFields();
        formInstance.setFieldsValue({
          name: initialValues.name,
          description: initialValues.description,
          message: initialValues.message,
          schedule: {
            type: initialValues.schedule?.type || 'immediate',
            delay: initialValues.schedule?.delay,
            customTime: initialValues.schedule?.customTime,
          },
        });
      }
    }, [initialValues, formInstance]);

    const { mutate: updateVariables } = useMutation({
      mutationKey: ['update-variables'],
      mutationFn: () => updateCampaignVariables(selectedCampaign?.number, variables),
      onSuccess: () => {
        notification.success({
          key: 'variables-success',
          message: 'Message variables updated successfully',
          duration: 3,
        });
      },
    });

    const handleVariableChange = (variables: any) => {
      setVariables(variables);
      updateVariables();
    };

    const handleSubmit = (values: any) => {
      if (mode === 'edit' && onEdit) {
        onEdit(values);
      } else if (onSubmit) {
        onSubmit(values);
      }
    };

    const handleReset = () => {
      formInstance.resetFields();
      if (initialValues) {
        formInstance.setFieldsValue(initialValues);
      }
    };

    return (
      <Card bordered={false} className='shadow-sm'>
        <div className='flex justify-between items-center mb-6'>
          <Title level={4} className='!mb-0'>
            {mode === 'edit' ? 'Edit Text Configuration' : 'Create Text Configuration'}{' '}
            <Tag color='orange'>Optional</Tag>
          </Title>
          {onCancel && showCancelBtn && (
            <Button icon={<CloseOutlined />} onClick={onCancel}>
              Cancel
            </Button>
          )}
        </div>

        <Form
          ref={ref}
          form={formInstance}
          layout='vertical'
          onFinish={handleSubmit}
          className='space-y-6'
          initialValues={{
            schedule: { type: 'immediate' },
            ...initialValues,
          }}
        >
          <Alert
            type='info'
            message='Text Configuration Tips'
            description={
              <ul className='list-disc pl-4 mt-2'>
                <li>Give your Text schedule a clear, descriptive name</li>
                <li>Use message variables to personalize your text</li>
                <li>Consider timing for optimal engagement</li>
              </ul>
            }
            className='mb-6'
          />

          <Card title='Text Configuration Details' className='shadow-sm'>
            <Form.Item
              name='name'
              label='Text Schedule Name'
              rules={[{ required: true, message: 'Please enter campaign name' }]}
            >
              <Input placeholder='Enter a descriptive name for your campaign' maxLength={100} showCount />
            </Form.Item>

            <Form.Item
              name='description'
              label='Description'
              rules={[{ required: true, message: 'Please enter campaign description' }]}
            >
              <Input.TextArea placeholder='What is the purpose of this campaign?' rows={3} maxLength={500} showCount />
            </Form.Item>

            <Form.Item
              name='message'
              label={
                <Space>
                  <span>Message Template</span>
                  <Tooltip title='Use variables like {{name}} for personalization'>
                    <QuestionCircleOutlined className='text-gray-400' />
                  </Tooltip>
                </Space>
              }
              rules={[{ required: true, message: 'Please enter a message' }]}
            >
              <TextEditor
                vvariables={variables}
                // @ts-ignore
                fromPortal={'NOVI'}
                initialValue={initialValues?.message}
                onChange={(_: any, variables: any) => {
                  if (variables) {
                    handleVariableChange(variables);
                  }
                }}
              />
            </Form.Item>
          </Card>

          <div className='flex justify-end gap-3'>
            <Button onClick={handleReset}>Reset Form</Button>
            <Button type='primary' htmlType='submit' loading={isLoading} icon={<SaveOutlined />}>
              {mode === 'edit' ? 'Update Configuration' : 'Create Configuration'}
            </Button>
          </div>
        </Form>
      </Card>
    );
  }
);

TextCampaignForm.displayName = 'TextCampaignForm';

export default TextCampaignForm;
