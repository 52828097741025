import { useState } from 'react';
import { Alert, Form, notification, Spin, Switch } from 'antd';
import { LabelWithDescription } from '@/components/LabelWithdescription';
import { OverViewCardForm } from '../OverviewCardForm';
import { RefetchOptions, QueryObserverResult, useMutation } from '@tanstack/react-query';
import { updateUser } from '@/api/user';
import { LoadingOutlined } from '@ant-design/icons';
import { useAppDispatch } from '@/store/hooks/useAppDispatch';
import { closeModal } from '@/store/modal/slice';
import { User } from '@/types/TAccounts';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { getSelectedCampaign } from '@/store/novi/user/selector';
import { updateCampaignVariables } from '@/api/novi/CamapaignPortalApis';
import TextEditor from '../../../../../v1/components/TextEditor/TextEditor';
import CopyableText from '@/components/LabelWithdescription/CopyableText';

interface TextAssistConfig {
  id: string;
  is_active: boolean;
  template: string;
  type: string;
}

export const TextAssistForm: React.FC<{
  textAssistConfig: TextAssistConfig;
  refetch: (options?: RefetchOptions) => Promise<QueryObserverResult<User, Error>>;
}> = ({ textAssistConfig, refetch }) => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm<TextAssistConfig>();
  const [isFieldTouchReset, setIsFieldTouchReset] = useState<boolean>(true);

  let selectedCampaign = useAppSelector(getSelectedCampaign);
  const [variables, setVariables] = useState<any[]>(
    selectedCampaign?.variables.length ? JSON.parse(selectedCampaign?.variables) : []
  );

  const mutation = useMutation({
    mutationKey: ['update-text-assist'],
    mutationFn: updateUser,
    onSuccess: () => {
      refetch();
      notification.success({
        key: 'form-success',
        message: 'Text Assist has been saved successfully!',
        duration: 3,
      });
      setIsFieldTouchReset(false);
      dispatch(closeModal());
    },
  });

  const handleSubmit = (values: TextAssistConfig) => {
    mutation.mutate({
      textAssist: {
        is_active: values?.is_active,
        template: values?.template,
        type: values?.type,
      },
    });
  };

  const { mutate: updateVariables } = useMutation({
    mutationKey: ['update-variables'],
    mutationFn: () => updateCampaignVariables(selectedCampaign?.number, variables),
    onSuccess: () => {
      refetch();
      notification.success({
        key: 'form-success',
        message: 'Variables have been saved successfully!',
        duration: 3,
      });
      setIsFieldTouchReset(false);
    },
  });

  const handleVariableChange = (variables: any) => {
    setVariables(variables);
    updateVariables();
  };

  const FormItems = () => (
    <>
      <Form.Item
        label={
          <div>
            <LabelWithDescription
              customClassName='mt-2 w-full'
              label={<div className='flex items-center gap-1'>Enable Text Assist</div>}
              description='Enable or disable text assistance for failed calls.'
            />
          </div>
        }
        name='is_active'
        valuePropName='checked'
      >
        <Switch />
      </Form.Item>

      <Form.Item
        label={
          <div>
            <LabelWithDescription
              customClassName='mt-2 w-full'
              label={
                <div className='flex items-center gap-1'>
                  Failed Call Message
                  <CopyableText text={textAssistConfig?.template || ''} hideText />
                </div>
              }
              description='This message will be sent when a call is not answered.'
            />
          </div>
        }
        name='template'
        className='!mb-4'
      >
        {/* @ts-ignore */}
        <TextEditor
          vvariables={variables}
          // @ts-ignore
          onChange={(_, variables) => {
            if (variables) {
              handleVariableChange(variables);
            }
          }}
        />
      </Form.Item>

      <Form.Item name='type' hidden initialValue='FAILED_CALL' />

      <Form.Item name='id' hidden />

      <Alert
        className='mt-4'
        type='info'
        closable={false}
        message={
          <>
            <b>Please Note:</b>
            <p className='text-justify'>
              Text Assist is currently configured to handle failed calls only. The message will be sent automatically
              when a call cannot be completed. Use variables in your template to personalize the message for each
              situation. Keep messages professional and concise for better engagement.
            </p>
          </>
        }
      />
    </>
  );

  return (
    <div>
      <Spin spinning={mutation.isPending} indicator={<LoadingOutlined style={{ fontSize: '48px' }} spin />}>
        <OverViewCardForm
          form={form}
          initialValues={{
            id: textAssistConfig?.id,
            is_active: textAssistConfig?.is_active,
            template: textAssistConfig?.template,
            type: textAssistConfig?.type,
          }}
          formItems={<FormItems />}
          handleSubmit={handleSubmit}
          isFieldTouchReset={isFieldTouchReset}
        />
      </Spin>
    </div>
  );
};
