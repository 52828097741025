import { useState, useCallback } from 'react';
import { Button, Typography, Input, Modal, Space, Card, Row, Col, notification } from 'antd';
import { SearchOutlined, CloseOutlined, PlusOutlined } from '@ant-design/icons';
import AddNewLeads from './AddLeads/AddNewLeads';
import LeadsDashboard from './LeadDashboard';
import { debounce } from 'lodash';
import { getLeads } from '@/api/novi/leads';
import { useQuery } from '@tanstack/react-query';

const { Title, Text } = Typography;
const { Search } = Input;

const PAGE_SIZE = 100;

const LeadsInfo: React.FC<{ campaignId: string; isScheduleActive?: boolean }> = ({ campaignId, isScheduleActive }) => {
  const [searchText, setSearchText] = useState('');
  const [addLeadModal, setAddLeadModal] = useState(false);
  const [leadsData, setLeadsData] = useState<Record<string, any>>({
    NEW: {
      leads: [],
      hasMore: true,
      nextKey: null,
      loading: false,
      tooltip: 'Lead which has been added recently and has not been utilized by calling or texting schedule',
    },
    CONTACTED: {
      leads: [],
      hasMore: true,
      nextKey: null,
      loading: false,
      tooltip: 'Leads that have been successfully reached and communicated with',
    },
    PROCESSING: {
      leads: [],
      hasMore: true,
      nextKey: null,
      loading: false,
      tooltip: 'Leads currently being processed in active schedule or pending follow-up actions',
    },
    FAILED: {
      leads: [],
      hasMore: true,
      nextKey: null,
      loading: false,
      tooltip: "Leads that couldn't be reached after maximum retry attempts or encountered communication errors",
    },
  });

  const fetchLeadsByStatus = useCallback(
    async (status: string, nextKey?: string | null) => {
      setLeadsData(prev => ({
        ...prev,
        [status]: { ...prev[status], loading: true },
      }));

      try {
        const response = await getLeads(campaignId, {
          status,
          nextKey: nextKey || '',
          limit: PAGE_SIZE,
        });

        setLeadsData(prev => ({
          ...prev,
          [status]: {
            ...prev[status],
            leads: nextKey ? [...prev[status].leads, ...response.leads] : [...response.leads],
            hasMore: response.pagination.hasMore,
            nextKey: response.pagination.nextKey || null,
            loading: false,
          },
        }));
      } catch (error) {
        notification.error({
          message: 'Error',
          description: 'Failed to fetch leads',
        });
        setLeadsData(prev => ({
          ...prev,
          [status]: { ...prev[status], loading: false },
        }));
      }
    },
    [campaignId]
  );

  useQuery({
    queryKey: ['leads', campaignId],
    queryFn: async () => {
      await Promise.all(Object.keys(leadsData).map(status => fetchLeadsByStatus(status)));
      return leadsData;
    },
    enabled: !!campaignId,
  });

  const handleSearch = debounce((value: string) => {
    setSearchText(value.trim().toLowerCase());
  }, 300);

  const closeAddLeadModal = () => {
    setAddLeadModal(false);
  };

  return (
    <>
      <Space direction='vertical' style={{ width: '100%' }}>
        <Card bordered={false} style={{ background: '#f5f5f5', marginBottom: 16 }}>
          <Row justify='space-between' align='middle' gutter={[16, 16]}>
            <Col xs={24} sm={8} md={6} lg={6}>
              <Title level={4} style={{ margin: 0 }}>
                Leads
              </Title>
            </Col>
            <Col xs={24} sm={8} md={10} lg={12}>
              <Search
                placeholder='Search by phone number or lead type...'
                allowClear
                onChange={e => handleSearch(e.target.value)}
                style={{ width: '100%' }}
                prefix={<SearchOutlined style={{ color: '#8c8c8c' }} />}
              />
            </Col>
            <Col xs={24} sm={8} md={8} lg={6} style={{ textAlign: 'right' }}>
              <Button type='primary' icon={<PlusOutlined />} size='middle' onClick={() => setAddLeadModal(true)}>
                Add Lead
              </Button>
            </Col>
          </Row>
        </Card>
        <LeadsDashboard
          isScheduleActive={isScheduleActive as boolean}
          campaignID={campaignId}
          searchQuery={searchText}
          leads={leadsData}
          refetchLeads={fetchLeadsByStatus}
        />
      </Space>
      <Modal
        open={addLeadModal}
        onCancel={closeAddLeadModal}
        footer={null}
        width={800}
        closeIcon={<CloseOutlined />}
        title={
          <div style={{ padding: '12px 0' }}>
            <Title level={4} style={{ margin: 0 }}>
              Add New Leads
            </Title>
            <Text type='secondary'>Choose how you'd like to import your leads</Text>
          </div>
        }
      >
        <AddNewLeads closeModal={closeAddLeadModal} refetchLeads={fetchLeadsByStatus} />
      </Modal>
    </>
  );
};

export default LeadsInfo;
