import React, { ChangeEvent, KeyboardEvent, MouseEvent } from 'react';
import Fade from 'react-reveal/Fade';
import classes from '../Register.module.css';
import posthog from 'posthog-js';
import { notification, Button } from 'antd';

type Step10Props = {
  isCampaign: boolean | undefined;
  reseller_email?: string;
  campaign_user_email?: string;
  username: string;
  handleUsernameChange: (event: ChangeEvent<HTMLInputElement>) => void;
  errors: any;
  password: string;
  setPassword: (password: string) => void;
  setErrors: (errors: any) => void;
  confirmPassword: string;
  setConfirmPassword: (confirmPassword: string) => void;
  debrand: boolean | undefined;
  termsAndConditions: boolean;
  setTermsAndConditions: (checked: boolean) => void;
  createAccLoading: boolean;
  handleSubmit: (event: MouseEvent<HTMLButtonElement> | KeyboardEvent<HTMLButtonElement>) => void;
  stepLoading: boolean;
  createClientStepByStepLoading: boolean;
  isStandaloneCampaign?: boolean;
  resellerOutbound?: boolean;
};

const Step10: React.FC<Step10Props> = ({
  isCampaign,
  reseller_email,
  campaign_user_email,
  username,
  handleUsernameChange,
  errors,
  password,
  setPassword,
  setErrors,
  confirmPassword,
  setConfirmPassword,
  debrand,
  termsAndConditions,
  setTermsAndConditions,
  createAccLoading,
  handleSubmit,
  stepLoading,
  createClientStepByStepLoading,
  isStandaloneCampaign,
  resellerOutbound,
}) => {
  return (
    <>
      <div className={classes.box}>
        <Fade duration={1000} bottom>
          <>
            {React.createElement(() => {
              posthog.capture('create_credentials_entered');
              return null;
            })}
            <h3
              className={classes.heading}
              style={{
                textAlign: 'center',
              }}
            >
              Final Step: Create Account
            </h3>
            {!isCampaign || isStandaloneCampaign || resellerOutbound ? (
              <div
                className={classes.subtitle}
                style={{
                  textAlign: 'center',
                }}
              >
                {reseller_email || campaign_user_email
                  ? `Your ${isStandaloneCampaign ? '' : 'client'} will use this`
                  : 'Before you can test your receptionist, create an account. You will use this'}{' '}
                username and password to log in to the dashboard
              </div>
            ) : null}

            {!isCampaign || isStandaloneCampaign || resellerOutbound ? (
              <>
                <label
                  style={{
                    textAlign: 'center',
                    marginTop: '1.5rem',
                    display: 'block',
                  }}
                >
                  <p className={classes.heading} style={{ textAlign: 'center' }}>
                    Username
                  </p>
                  <input
                    className={`${classes.input} ${classes.centered}`}
                    type='text'
                    value={username}
                    required
                    onChange={handleUsernameChange}
                  />
                  {errors?.username && <span className='text-red-500 text-center'>{errors.username}</span>}
                </label>

                <label
                  style={{
                    textAlign: 'center',
                    marginTop: '1.5rem',
                    display: 'block',
                  }}
                >
                  <p className={classes.heading} style={{ textAlign: 'center' }}>
                    Password
                  </p>
                  <p className={classes.subtitle}>You will use this password to log in to your dashboard</p>
                  <input
                    type='password'
                    value={password}
                    className={`${classes.input} ${classes.centered}`}
                    required
                    onChange={event => {
                      const value = event.target.value;
                      setPassword(value);

                      if (confirmPassword && confirmPassword !== value) {
                        setErrors((prev: any) => ({
                          ...prev,
                          password: 'Password & Confirm Password does not match.',
                        }));
                      } else {
                        setErrors((prev: any) => ({ ...prev, password: '' }));
                      }
                    }}
                  />
                </label>

                {password.length > 0 && (
                  <label
                    style={{
                      textAlign: 'center',
                      marginTop: '1.5rem',
                      display: 'block',
                    }}
                  >
                    <p className={classes.heading} style={{ textAlign: 'center' }}>
                      Confirm Password
                    </p>
                    <p className={classes.subtitle}>Please enter your password once again </p>
                    <input
                      className={`${classes.input} ${classes.centered}`}
                      type='password'
                      value={confirmPassword}
                      required
                      onChange={event => {
                        const value = event.target.value;
                        setConfirmPassword(value);
                        if (password !== value) {
                          setErrors((prev: any) => ({
                            ...prev,
                            password: 'Password & Confirm Password does not match.',
                          }));
                        } else {
                          setErrors((prev: any) => ({ ...prev, password: '' }));
                        }
                      }}
                    />
                  </label>
                )}
                <div
                  style={{
                    height: '26px',
                    width: '100%',
                    marginTop: '.5rem',
                  }}
                >
                  {errors?.password && (
                    <p
                      className={classes.label}
                      style={{
                        color: 'red',
                        textAlign: 'center',
                        margin: 0,
                      }}
                    >
                      {errors.password}
                    </p>
                  )}
                </div>
              </>
            ) : null}

            <div style={{ textAlign: 'center' }}>
              {!debrand && password && confirmPassword && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <input
                    type='checkbox'
                    name='termsAndConditions'
                    checked={termsAndConditions}
                    onChange={e => setTermsAndConditions(e.target.checked)}
                  />
                  <label htmlFor='termsAndConditions' className='inline-block ml-3'>
                    I agree to the{' '}
                    <a
                      href='https://www.myaifrontdesk.com/terms-of-service'
                      target='_blank'
                      rel='noopener noreferrer'
                      className='text-blue-600 hover:text-black'
                    >
                      terms and conditions
                    </a>
                  </label>
                </div>
              )}
            </div>

            <Button
              disabled={
                ((!isCampaign || isStandaloneCampaign || resellerOutbound) && password !== confirmPassword) ||
                (!debrand && !termsAndConditions)
              }
              className={`${classes.button} ${classes.centered}`}
              style={{
                marginTop: '2rem',
              }}
              loading={createAccLoading || stepLoading || createClientStepByStepLoading}
              onClick={e => {
                if ((!isCampaign || isStandaloneCampaign || resellerOutbound) && (!password || !confirmPassword)) {
                  e.preventDefault();
                  notification.error({ message: 'Please enter a new password', duration: 1 });
                }
                if (!username && (!isCampaign || isStandaloneCampaign || resellerOutbound)) {
                  e.preventDefault();
                  notification.error({ message: 'Please enter a username', duration: 1 });
                }
                handleSubmit(e as MouseEvent<HTMLButtonElement>);
              }}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  if ((!isCampaign || isStandaloneCampaign || resellerOutbound) && (!password || !confirmPassword)) {
                    e.preventDefault();
                    notification.error({ message: 'Please enter a new password', duration: 1 });
                  }
                  if (!username && (!isCampaign || isStandaloneCampaign || resellerOutbound)) {
                    e.preventDefault();
                    notification.error({ message: 'Please enter a username', duration: 1 });
                  }

                  handleSubmit(e as KeyboardEvent<HTMLButtonElement>);
                }
              }}
            >
              Create My AI!
            </Button>
          </>
        </Fade>
      </div>
    </>
  );
};

export default Step10;
