import { Button, Card, notification } from 'antd';
import { OverViewCardInfo } from '../OverviewCardInfo';
import { DeleteOutline, EditOutlined } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { CustomModal } from '@/components/CustomModal';
import PostCallWebhookForm from './PostCallWebhookForm';
import { RefetchOptions, QueryObserverResult, UseMutationResult } from '@tanstack/react-query';
import { MODAL_IDS } from 'src/enums/EModal';
import { useAppDispatch } from '@/store/hooks/useAppDispatch';
import { openModal } from '@/store/modal/slice';
import { LabelWithDescription } from '@/components/LabelWithdescription';
import { deepEqual } from '@/utils/helper';
import { User } from '@/types/TAccounts';
import { TUTORIALS } from 'src/enums/ETutorials';

interface PostCallWebhookProps {
  data: any;
  refetch: (options?: RefetchOptions) => Promise<QueryObserverResult<User, Error>>;
  mutation: UseMutationResult<{}, Error, any, unknown>;
  deleteMutation: UseMutationResult<{}, Error, any, unknown>;
  debrand?: boolean;
}

export const PostCallWebhook: React.FC<PostCallWebhookProps> = ({
  data,
  refetch,
  mutation,
  deleteMutation,
  debrand,
}) => {
  const dispatch = useAppDispatch();

  const webhook = (data?.postCallWebhook ? JSON.parse(data?.postCallWebhook) : null) as {
    endpoint: string;
    method: string;
  };

  const [formInitialValues, setFormInitialValues] = useState<any>(null);

  useEffect(() => {
    if (formInitialValues && deepEqual(formInitialValues, webhook)) return;

    setFormInitialValues(webhook);
  }, [formInitialValues, webhook]);

  const handleEditWebhook = (endpoint: string, method: string) => {
    const initialValues = {
      endpoint,
      method,
    };

    setFormInitialValues(initialValues);
    dispatch(openModal({ modalId: MODAL_IDS.POST_CALL_WEBHOOK_EDIT_MODAL }));
  };

  const handleDeleteWebhook = async () => {
    try {
      if (!formInitialValues) return;

      await deleteMutation.mutateAsync({});

      notification.success({ message: 'Webhook deleted successfully!' });
      refetch();
    } catch (err) {
      console.error(err);
      notification.error({ message: 'Something went wrong, please try again later!' });
    }
  };

  const InfoComponent = () => {
    return (
      <>
        {webhook?.endpoint && (
          <Card
            title={
              <div className='flex justify-between'>
                <span className='font-bold text-base'>Webhook</span>
                <span>
                  <Button
                    type='primary'
                    danger
                    loading={deleteMutation.isPending}
                    icon={<DeleteOutline />}
                    onClick={() => handleDeleteWebhook()}
                  >
                    Delete
                  </Button>
                  <Button
                    type='primary'
                    className='ml-4 text-xs'
                    icon={<EditOutlined />}
                    onClick={() => handleEditWebhook(formInitialValues?.endpoint, formInitialValues?.method)}
                  >
                    Edit
                  </Button>
                </span>
              </div>
            }
          >
            <>
              <div className='border-b'>
                <LabelWithDescription
                  customClassName='mt-2'
                  label='Method'
                  description={'What type of request would you like to make?'}
                />
                <span className='pl-4 w-8/12 mt-2 pt-2 pb-4'>{webhook?.method}</span>
              </div>
              <div>
                <LabelWithDescription customClassName='mt-2' label='Endpoint' description='What is the endpoint?' />
                <span className='pl-4 w-8/12 mt-2 pt-2 pb-4'>{webhook?.endpoint}</span>
              </div>
            </>
          </Card>
        )}
      </>
    );
  };

  return (
    <>
      <OverViewCardInfo
        width={'50%'}
        formActionType='Add'
        displayFormActionBtn={!webhook?.endpoint}
        form={
          <PostCallWebhookForm
            mutation={mutation}
            initialValues={formInitialValues}
            refetch={refetch}
            actionType='ADD'
          />
        }
        formTitle={<span className='font-bold'>Add Webhook</span>}
        info={<InfoComponent />}
        footer={null}
        modalId={MODAL_IDS.POST_CALL_WEBHOOK_MODAL}
        tutorialId={TUTORIALS.POST_CALL_WEBHOOK}
        displayTutorialActionBtn={!debrand}
      />
      <CustomModal
        width={'50%'}
        key={MODAL_IDS.POST_CALL_WEBHOOK_EDIT_MODAL}
        title={<span className='font-bold'>Edit Webhook</span>}
        children={
          <PostCallWebhookForm
            mutation={mutation}
            initialValues={formInitialValues}
            refetch={refetch}
            actionType='EDIT'
          />
        }
        modalId={MODAL_IDS.POST_CALL_WEBHOOK_EDIT_MODAL}
        footer={null}
      />
    </>
  );
};
