import { useEffect, useState } from 'react';
import { Layout, Menu, Drawer, Typography, Button, Avatar, Space } from 'antd';
import type { MenuProps } from 'antd';
import {
  AppstoreOutlined,
  CalendarOutlined,
  RobotOutlined,
  TeamOutlined,
  MenuOutlined,
  MessageOutlined,
  PhoneOutlined,
  FileTextOutlined,
  ApiOutlined,
  BranchesOutlined,
  UserOutlined,
  DollarCircleOutlined,
  SoundOutlined,
  RiseOutlined,
} from '@ant-design/icons';
import { ENoviDashboardSider } from '@/pages/Novi/enums/ENoviDashboardSider';
import { NoviUser } from '@/pages/Novi/types/NoviUser';
import { BarChartOutlined, PhoneIphone, FeedOutlined, VoicemailOutlined, MessageSharp } from '@mui/icons-material';
import { getResellerData } from '@/store/reseller/selector';
import { useAppSelector } from '@/store/hooks/useAppSelector';

const { Sider } = Layout;
const { Title } = Typography;

interface SiderMenuProps {
  currentPage: ENoviDashboardSider | string;
  handleMenuSelect: (key: ENoviDashboardSider) => void;
  selectedCampaignId: string | null;
  noviUser?: NoviUser;
  isMobile: boolean;
  showBusinessInfo?: boolean;
  menuRef: React.RefObject<HTMLDivElement>;
  brandingRef?: React.MutableRefObject<HTMLElement | null>;
  byotRef?: React.MutableRefObject<HTMLElement | null>;
  campaignsRef: React.MutableRefObject<HTMLElement | null>;
  isStandaloneCampaign?: boolean;
  resellerLogoUrl?: string;
  collapsible?: boolean;
}

const SiderMenu: React.FC<SiderMenuProps> = ({
  currentPage,
  handleMenuSelect,
  selectedCampaignId,
  noviUser,
  isMobile,
  showBusinessInfo = true,
  menuRef,
  byotRef,
  brandingRef,
  campaignsRef,
  isStandaloneCampaign = false,
  resellerLogoUrl,
  collapsible = true,
}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const currentReseller = useAppSelector(getResellerData);

  const handleDrawerClose = () => setIsDrawerOpen(false);
  useEffect(() => {
    const setRefs = () => {
      if (menuRef.current) {
        const menuItems = menuRef.current.querySelectorAll('.ant-menu-item');

        let firstItem;
        let secondItem;
        let thirdItem;

        firstItem = menuItems[0];
        secondItem = menuItems[1];
        thirdItem = menuItems[2];

        if (firstItem) {
          campaignsRef.current = firstItem as HTMLElement;
        }
        if (secondItem && brandingRef && !isStandaloneCampaign) {
          brandingRef.current = secondItem as HTMLElement;
        }
        if (thirdItem && byotRef && !isStandaloneCampaign) {
          byotRef.current = thirdItem as HTMLElement;
        }
      }
    };

    setRefs();

    const observer = new MutationObserver(setRefs);
    if (menuRef.current) {
      observer.observe(menuRef.current, { childList: true, subtree: true });
    }

    return () => observer.disconnect();
  }, []);

  const baseMenuItems =
    !selectedCampaignId && !isStandaloneCampaign
      ? [
          {
            key: ENoviDashboardSider.MY_CAMPAIGNS,
            icon: <AppstoreOutlined />,
            label: 'My Campaigns',
          },
          {
            key: ENoviDashboardSider.BRANDING,
            icon: <BranchesOutlined />,
            label: 'Branding',
          },
          {
            key: ENoviDashboardSider.BYOT,
            icon: <ApiOutlined />,
            label: 'Bring Your Twilio',
          },
          {
            key: ENoviDashboardSider.SUBSCRIPTION,
            icon: <DollarCircleOutlined />,
            label: 'Subscription',
          },
        ]
      : [];

  const campaignMenuItems: MenuProps['items'] = selectedCampaignId
    ? [
        {
          type: 'group',
          label: 'Campaign Management',
          children: [
            {
              key: ENoviDashboardSider.CAMPAIGN_SETUP,
              icon: <RiseOutlined />,
              label: 'Campaign Setup',
            },
            isStandaloneCampaign
              ? {
                  key: ENoviDashboardSider.RESERVE_NUMBER,
                  icon: <PhoneIphone />,
                  label: 'Reserve Number',
                }
              : null,
            {
              key: ENoviDashboardSider.CRM,
              icon: <UserOutlined />,
              label: 'CRM',
            },
            {
              key: ENoviDashboardSider.LEADS,
              icon: <TeamOutlined />,
              label: 'Leads',
            },
            {
              key: ENoviDashboardSider.SCHEDULE,
              icon: <CalendarOutlined />,
              label: 'Campaign Schedule',
            },
            {
              key: ENoviDashboardSider.TEXT_CONFIGURATION,
              icon: <MessageSharp />,
              label: 'Text Configuration',
            },
          ],
        },
        {
          type: 'group',
          label: 'AI & Automation',
          children: [
            {
              key: ENoviDashboardSider.CONFIGURE,
              icon: <RobotOutlined />,
              label: 'Configure',
            },
            {
              key: ENoviDashboardSider.VOICE_LIBRARY,
              icon: <SoundOutlined />,
              label: 'Voice Library',
            },
            {
              key: ENoviDashboardSider.INTAKE_FORMS,
              icon: <FileTextOutlined />,
              label: 'Intake Forms',
            },
            {
              key: ENoviDashboardSider.TEXTING_PLATFORM,
              icon: <MessageOutlined />,
              label: 'Texting Platform',
            },
          ],
        },
        {
          type: 'group',
          label: 'Logs & Analytics',
          children: [
            {
              key: ENoviDashboardSider.ANALYTICS,
              icon: <BarChartOutlined />,
              label: 'Analytics',
            },
            {
              key: ENoviDashboardSider.CALL_LOGS,
              icon: <PhoneOutlined />,
              label: 'Call Logs',
            },
            {
              key: ENoviDashboardSider.TEXT_LOGS,
              icon: <FeedOutlined />,
              label: 'Text Logs',
            },
            {
              key: ENoviDashboardSider.VOICE_MAIL_LOGS,
              icon: <VoicemailOutlined />,
              label: 'Voicemail Logs',
            },
          ],
        },
      ]
    : [];

  const getMenuItems = (): MenuProps['items'] => [...campaignMenuItems, ...baseMenuItems];

  const handleMenuClick: MenuProps['onClick'] = ({ key }) => {
    handleMenuSelect(key as ENoviDashboardSider);
    if (isMobile) {
      handleDrawerClose();
    }
  };

  const MenuHeader = ({ collapsed }: { collapsed: boolean }) => (
    <div className='p-4 border-b border-gray-100'>
      <Space direction='vertical' size='small' style={{ width: '100%' }}>
        <Space align='center' style={{ width: '100%', justifyContent: 'space-between' }}>
          <Avatar
            src={isStandaloneCampaign && resellerLogoUrl ? resellerLogoUrl : noviUser ? noviUser.logo : undefined}
            size={48}
            style={{
              padding: 4,
              backgroundColor: '#f5f5f5',
              boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
            }}
          />
        </Space>

        {!collapsed && (
          <Title level={5} style={{ margin: 0 }}>
            {isStandaloneCampaign && currentReseller?.reseller_name
              ? currentReseller.reseller_name
              : noviUser
                ? noviUser.business_name
                : 'Welcome Back'}
          </Title>
        )}
      </Space>
    </div>
  );

  const sidebarContent = (
    <>
      {showBusinessInfo && <MenuHeader collapsed={collapsed} />}
      <div ref={menuRef}>
        <Menu
          mode='inline'
          selectedKeys={[currentPage]}
          onClick={handleMenuClick}
          items={getMenuItems()}
          style={{
            border: 'none',
            padding: '8px',
          }}
          theme='light'
        />
      </div>
    </>
  );

  return isMobile ? (
    <>
      <Button
        type='text'
        icon={<MenuOutlined />}
        onClick={() => setIsDrawerOpen(true)}
        style={{
          position: 'fixed',
          top: '16px',
          left: '16px',
          zIndex: 1000,
        }}
      />
      <Drawer
        title={null}
        placement='left'
        onClose={handleDrawerClose}
        open={isDrawerOpen}
        width={280}
        styles={{
          body: {
            padding: 0,
            backgroundColor: '#fff',
          },
          mask: {
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
          },
        }}
        closable={false}
      >
        {sidebarContent}
      </Drawer>
    </>
  ) : (
    <Sider
      theme='light'
      width={220}
      collapsible={collapsible}
      collapsed={collapsed}
      onCollapse={setCollapsed}
      style={{
        height: '100vh',
        left: 0,
        top: 0,
        bottom: 0,
        backgroundColor: '#fff',
        boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
      }}
    >
      <div
        style={{
          height: '100%',
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
      >
        {sidebarContent}
      </div>
    </Sider>
  );
};

export default SiderMenu;
