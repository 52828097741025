import { useCallback, useEffect, useRef, useState } from 'react';
import {
  Button,
  Tag,
  Space,
  Input,
  Typography,
  Layout,
  Card,
  Row,
  Col,
  notification,
  Checkbox,
  Badge,
  Avatar,
  theme,
  FormInstance,
  Spin,
  Tooltip,
  Form,
  Modal,
  Popconfirm,
} from 'antd';
import {
  PlusOutlined,
  ArrowLeftOutlined,
  BuildOutlined,
  PhoneOutlined,
  RightOutlined,
  ClockCircleOutlined,
  EditOutlined,
  CopyOutlined,
  DeleteFilled,
  KeyOutlined,
} from '@ant-design/icons';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { getLoggedInNoviUser } from '@/store/novi/user/selector';
import { Content, Header } from 'antd/es/layout/layout';
import { useDispatch } from 'react-redux';
import { setNoviSelectedCampaign, setNoviSelectedCampaignId, setNoviUser } from '@/store/novi/user/slice';
import LocalStorageUtility from '@/utils/localStorage';
import { LocalStorageKeys } from 'src/enums/ELocalStorage';
import { CustomModal } from '@/components/CustomModal/CustomModal.js';
import { GetStandAloneNumberForm } from '@/pages/ReceptionistDashboard/TestItOut/Steps/GetStandAloneNumberForm.js';
import { provisionNumberRequest } from '@/api/reservedNumbers.js';
import GetChooseNumberForm from '@/pages/ReceptionistDashboard/TestItOut/Steps/GetChooseNumberForm.js';
import { useMutation, useQuery } from '@tanstack/react-query';
import GetBundleApproveForm from '@/pages/ReceptionistDashboard/TestItOut/Steps/GetBundleApproveForm.js';
import { APP_LANDING_PAGE } from 'src/enums/ERoutes.js';
import { acceptTermsAndConditions, buyMoreCampaigns } from '@/api/novi/user.js';
import {
  deductCampaignFromSubscription,
  deleteCampaign,
  duplicateACampaign,
  getCampaignToken,
  getNoviCampaigns,
  updateCampaignName,
} from '@/api/novi/campaigns.js';
import { getScheduleStatus } from '@/api/novi/schedule';

// @ts-ignore
// import RegisterPageV3 from '../../../../../v1/RegisterPageV3';
import BuyMoreCampaignsModal from '@/pages/Novi/Modals/BuyMoreCampaignsModal';
import { closeModal, openModal } from '@/store/modal/slice';
import { MODAL_IDS } from 'src/enums/EModal';
import { getResellerData } from '@/store/reseller/selector';
import { setReseller } from '@/store/reseller/slice';
import NoviPlansModal from '@/pages/Novi/components/NoviPlansModal';
import RegisterPage from '@/pages/Register/RegisterPage';
import { PersonOutline } from '@mui/icons-material';
import { Reseller } from '@/types/TReseller';
import PaymentFailedModal from '@/components/PaymentFailureModal/PaymentFailureModal';
import RedirectTwilioConfigModal from '@/pages/Novi/Modals/TwilioConfigModal/RedirectTwilioConfigModal';
import NoSubscriptionView from '@/pages/Novi/components/NoSubscriptionView/NoSubscriptionView';

const { Title, Text } = Typography;
const { useToken } = theme;

const NoviCampaigns = ({
  createCampaignButtonRef,
  resellerView = false,
}: {
  createCampaignButtonRef: React.RefObject<HTMLDivElement> | null;
  resellerView?: boolean;
}) => {
  const dispatch = useDispatch();
  const user = resellerView ? useAppSelector(getResellerData) : useAppSelector(getLoggedInNoviUser);
  const formRef = useRef<FormInstance>(null);
  const { token } = useToken();

  const [showCreateCampaign, setShowCreateCampaign] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [isStandAloneNumberModalVisible, setIsStandAloneNumberModalVisible] = useState<boolean>(false);
  const [isBYOTInstructionsModalVisible, setIsBYOTInstructionsModalVisible] = useState<boolean>(false);
  const [isBookNumberModalVisible, setIsBookNumberModalVisible] = useState<boolean>(false);
  const [selectedCountry, setSelectedCountry] = useState<string>('');
  const [ChooseNumberModalOpen, setChooseNumberModalOpen] = useState<boolean>(false);
  const [isNonUSDetailsModalOpen, setIsNonUSDetailsModalOpen] = useState(false);
  const [twilioDocsRequirements] = useState<any>([]);
  const [currentClient, setCurrentClient] = useState('');
  const [isTermsModalVisible, setIsTermsModalVisible] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [editCampaignModal, setEditCampaignModal] = useState<string | null>(null);
  const [duplicateCampaignModal, setDuplicateCampaignModal] = useState<string | null>(null);

  const [updateCampaignNameForm] = Form.useForm();
  const [duplicateCampaignForm] = Form.useForm();

  const headerStyle = {
    background: '#fff',
    padding: '12px 16px',
    boxShadow: '0 1px 2px rgba(0, 0, 0, 0.03)',
    height: 'auto',
    lineHeight: 'normal',
  };

  const cardStyle = {
    borderRadius: '8px',
    background: '#fafafa',
    marginBottom: '16px',
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.05)',
  };

  const {
    data: clients,
    isLoading,
    refetch,
    isRefetching,
  } = useQuery({
    queryKey: ['campaignClients'],
    queryFn: () => getNoviCampaigns(),
    staleTime: 0,
  });

  const { data: scheduleStatus, isLoading: isScheduleLoading } = useQuery({
    queryKey: ['campaign-schedule', clients?.campaigns],
    queryFn: async () => {
      if (!clients?.campaigns) return [];
      const statusPromises = clients.campaigns.map(campaign => getScheduleStatus(campaign.number));
      return Promise.all(statusPromises);
    },
    enabled: !!clients?.campaigns,
  });

  const { mutate: provisionNumberRequestMutation, isPending: isProvisionApiPending } = useMutation({
    mutationKey: ['provisionNumberRequest'],
    mutationFn: provisionNumberRequest,
    onSuccess: () => {
      notification.success({ message: 'Provisioned a number successfully' });
      refetch();
    },
    onError: error => {
      let errorMsg;
      if (error?.message.includes('Authenticate')) errorMsg = 'Invalid Twilio credentials';
      notification.error({ message: errorMsg || 'Failed to provision number' });
    },
  });

  const { mutate: saveTermsAcceptanceMutation, isPending: isSavingTermsAcceptance } = useMutation({
    mutationKey: ['saveTermsAcceptance'],
    mutationFn: () => acceptTermsAndConditions(),
    onSuccess: async () => {
      notification.success({ message: 'Terms acceptance saved successfully' });
      setIsTermsModalVisible(false);
      updateUserLocalState({ is_terms_accepted: true });
    },
    onError: () => {
      notification.error({ message: 'Failed to save terms acceptance' });
    },
  });

  const { mutate: updateCampaignMutation, isPending: isUpdatingName } = useMutation({
    mutationKey: ['updateCampaign'],
    mutationFn: updateCampaignName,
    onSuccess: () => {
      notification.success({ message: 'Campaign updated successfully' });
      refetch();
      handleCancelEditCampaign();
    },
    onError: () => {
      notification.error({ message: 'Failed to update campaign' });
    },
  });

  const { mutate: duplicateCampaignMutation, isPending: isDuplicatingCampaign } = useMutation({
    mutationKey: ['duplicateCampaign'],
    mutationFn: duplicateACampaign,
    onSuccess: () => {
      notification.success({ message: 'Campaign duplicated successfully' });
      refetch();
      updateUserLocalState({ unConfiguredCampaigns: user.unConfiguredCampaigns - 1 });
      handleCancelDuplicateCampaign();
    },
    onError: () => {
      notification.error({ message: 'Failed to duplicate campaign' });
    },
  });

  const { mutate: buyMoreCampaignsMutation, isPending: isBuyingMoreCampaigns } = useMutation({
    mutationKey: ['buyMoreCampaigns'],
    mutationFn: buyMoreCampaigns,
    onSuccess: ({ totalCampaigns, unConfiguredCampaigns }) => {
      notification.success({ message: 'Campaigns purchased successfully' });
      if (totalCampaigns && unConfiguredCampaigns) {
        updateUserLocalState({ totalCampaigns, unConfiguredCampaigns });
      }
      dispatch(closeModal());
    },
    onError: (err, { quantity }) => {
      dispatch(
        openModal({
          modalId: MODAL_IDS.PAYMENT_FAILED_MODAL,
          data: {
            title: 'Unable to Buy More Campaigns',
            errorMessage: err.message || 'Something went wrong.',
            onRetry: () => buyMoreCampaignsMutation({ quantity }),
          },
        })
      );
    },
  });

  const updateUserLocalState = (data: any) => {
    if (resellerView) {
      dispatch(
        setReseller({
          ...user,
          ...data,
        })
      );
    } else {
      dispatch(
        setNoviUser({
          ...user,
          ...data,
        })
      );
    }
  };

  const handleUpdateCampaignName = () => {
    updateCampaignNameForm.validateFields().then(values => {
      updateCampaignMutation({
        campaign_id: editCampaignModal as string,
        business_name: values.campaignName,
      });
    });
  };

  const duplicateCampaign = () => {
    duplicateCampaignForm.validateFields().then(values => {
      let payload: {
        campaign_id: string;
        username?: string;
        password?: string;
        business_name?: string;
      } = {
        campaign_id: duplicateCampaignModal as string,
      };
      if (resellerView) {
        payload.username = values.duplicateCampaignUsername;
        payload.password = values.duplicateCampaignPassword;
      } else {
        payload.business_name = values.duplicateCampaignName;
      }

      duplicateCampaignMutation(payload);
    });
  };

  const handleBuyMoreCampaigns = (quantity: number) => {
    buyMoreCampaignsMutation({ quantity });
  };

  const getToken = async (campaign_id: string) => {
    try {
      const response = await getCampaignToken(campaign_id);
      LocalStorageUtility.setLocalData(LocalStorageKeys.NOVI_RECEPTIONIST_AUTH_TOKEN, response.auth_token);
    } catch (error) {
      console.error('Error fetching token:', error);
    }
  };

  const handleOpenCampaign = async (number: string) => {
    await getToken(number);
    dispatch(setNoviSelectedCampaignId(number));
    dispatch(setNoviSelectedCampaign(clients?.campaigns.find(item => item.number === number)));
    dispatch(
      openModal({
        modalId: MODAL_IDS.SELECTED_CAMPAIGN_MODAL_RESELLER_OUTBOUND,
      })
    );
  };

  const handleSearch = (value: string) => {
    setSearchText(value);
  };

  const openBYOTInstructionsModal = () => {
    setIsBYOTInstructionsModalVisible(true);
  };

  const closeBYOTInstructionsModal = () => {
    setIsBYOTInstructionsModalVisible(false);
  };

  const openStandAloneNumberModal = () => {
    setIsStandAloneNumberModalVisible(true);
  };

  const closeStandAloneNumberModal = () => {
    setIsStandAloneNumberModalVisible(false);
  };

  const openBookNumberModal = () => {
    setIsBookNumberModalVisible(true);
  };

  const closeBookNumberModal = () => {
    setIsBookNumberModalVisible(false);
  };

  const handleFormSubmit = (values: { country: string }) => {
    setSelectedCountry(values.country);
    closeStandAloneNumberModal();
    openBookNumberModal();
  };

  const handleConfirmAction = () => {
    if (formRef.current) {
      formRef.current.submit();
    }
  };

  const handleBack = () => {
    dispatch(setNoviSelectedCampaignId(null));
    dispatch(setNoviSelectedCampaign(null));
    setShowCreateCampaign(false);
  };

  const handleCreateCampaign = () => {
    const isTwilioConfigured =
      (!!user.twilio_account_id && !!user.twilio_access_token) ||
      (resellerView &&
        !!(user as Reseller).outbound_twilio_account_id &&
        !!(user as Reseller).outbound_twilio_access_token);

    if (!isTwilioConfigured) {
      dispatch(
        openModal({
          modalId: MODAL_IDS.REDIRECT_TWILIO_CONFIG,
        })
      );
    } else if (user.unConfiguredCampaigns > 0) {
      // if user has un-configured campaigns, show create campaign modal
      setShowCreateCampaign(true);
    } else {
      // check if user is a reseller and if they have no subscription
      if (user.totalCampaigns <= 0) {
        dispatch(openModal({ modalId: MODAL_IDS.NOVI_PLANS_MODAL, data: { resellerView } }));
      } else {
        // if user has no un-configured campaigns, show buy more campaigns modal
        dispatch(openModal({ modalId: MODAL_IDS.NOVI_BUY_MORE_CAMPAIGNS }));
      }
    }
  };

  const handleEditCampaign = (campaignID: string) => {
    setEditCampaignModal(campaignID);
  };

  const handleCancelEditCampaign = () => {
    setEditCampaignModal(null);
    updateCampaignNameForm.resetFields();
  };

  const handleDuplicateCampaign = (campaignID: string) => {
    if (user.unConfiguredCampaigns <= 0) {
      notification.error({
        message: 'No un-configured campaigns',
        description: 'You have no un-configured campaigns to duplicate. Please buy more campaigns to continue.',
      });
      return;
    }
    setDuplicateCampaignModal(campaignID);
  };

  const handleCancelDuplicateCampaign = () => {
    setDuplicateCampaignModal(null);
    duplicateCampaignForm.resetFields();
  };

  const bookNumberRequest = async () => {
    if (!selectedCountry) {
      return;
    }

    var countryCode = selectedCountry.split(' - ')[2];

    let payload = {
      username: currentClient,
      countryCode,
      isNovi: true,
    };

    provisionNumberRequestMutation(payload);
    setIsBookNumberModalVisible(false);
    closeStandAloneNumberModal();
  };

  const handleTermsSubmit = () => {
    if (termsAccepted) {
      saveTermsAcceptanceMutation();
    } else {
      notification.error({
        message: 'Terms and Conditions',
        description: 'You must accept the Terms and Conditions to continue.',
      });
    }
  };

  const filteredData = clients?.campaigns.filter((item: any) =>
    item.business_name.toLowerCase().includes(searchText.toLowerCase())
  );

  useEffect(() => {
    if (user.email_address && !user.is_terms_accepted) {
      setIsTermsModalVisible(true);
    }
  }, [user.is_terms_accepted]);

  const unConfiguredCampaignsArray = user?.unConfiguredCampaigns
    ? Array.from({ length: user.unConfiguredCampaigns }, (_, index) => index)
    : [];

  const RenderCampaignCard = ({ campaign, index }: { campaign: any; index: number }) => {
    const { mutate: deleteCampaignMutation, isPending: isDeletingCampaign } = useMutation({
      mutationKey: ['deleteCampaign', campaign.number],
      mutationFn: deleteCampaign,
      onSuccess: resp => {
        if (resp.success) {
          notification.success({ message: resp.message });
          refetch(); // refetch the campaigns (configured)
          updateUserLocalState({ unConfiguredCampaigns: user.unConfiguredCampaigns + 1 });
        } else {
          notification.error({ message: resp.message });
        }
      },
      onError: err => {
        notification.error({ message: err.message });
      },
    });

    const handleDeleteCampaign = async (campaignId: string) => {
      // handle delete campaign
      deleteCampaignMutation(campaignId);
    };

    return (
      <Card
        size='small'
        style={cardStyle}
        styles={{
          body: {
            padding: '16px',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
          },
        }}
      >
        <div className='flex flex-col h-full gap-3'>
          <div className='flex items-start justify-between'>
            <Space>
              <Avatar icon={<BuildOutlined />} style={{ backgroundColor: token.colorPrimary }} size='small' />
              <div className='flex flex-col'>
                <Text strong style={{ fontSize: '14px' }}>
                  {campaign.business_name}
                </Text>
                <Space size={4} className='mt-1'>
                  <Tag color='blue' style={{ fontSize: '12px', padding: '0 8px' }}>
                    {campaign.businessType || 'No Business Type'}
                  </Tag>
                  <Tag color={campaign.isActive ? 'success' : 'default'} style={{ fontSize: '12px', padding: '0 8px' }}>
                    {campaign.isActive ? 'Active' : 'Inactive'}
                  </Tag>
                </Space>
              </div>
            </Space>
            <Space>
              <Tooltip title='Edit Campaign'>
                <Button
                  type='text'
                  icon={<EditOutlined />}
                  size='small'
                  onClick={() => handleEditCampaign(campaign.number)}
                />
              </Tooltip>
              <Tooltip title='Duplicate Campaign'>
                <Button
                  type='text'
                  icon={<CopyOutlined />}
                  size='small'
                  onClick={() => handleDuplicateCampaign(campaign.number)}
                />
              </Tooltip>
              <Popconfirm
                title='Delete campaign?'
                description='This will permanently delete the campaign and its reserved number.'
                onConfirm={() => handleDeleteCampaign(campaign.number)}
              >
                <Tooltip title='Delete Campaign'>
                  <Button
                    loading={isDeletingCampaign}
                    type='text'
                    icon={<DeleteFilled className='text-red-500' />}
                    size='small'
                  />
                </Tooltip>
              </Popconfirm>
            </Space>
          </div>

          {resellerView && (
            <div className='flex-1'>
              <div className='grid gap-2'>
                <div className='flex items-center gap-2'>
                  <PersonOutline style={{ color: token.colorTextSecondary, fontSize: '14px' }} />
                  <Text style={{ fontSize: '13px' }}>
                    <span className='font-bold'>username:</span> {campaign.number}
                  </Text>
                </div>

                <div className='grid gap-1'>
                  <div className='flex items-center gap-2'>
                    <KeyOutlined style={{ color: token.colorTextSecondary, fontSize: '14px' }} />
                    <Text style={{ fontSize: '13px' }}>
                      <span className='font-bold'>password:</span> {campaign.password}
                    </Text>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className='flex-1'>
            <div className='grid gap-2'>
              <div className='flex items-center gap-2'>
                <PhoneOutlined style={{ color: token.colorTextSecondary, fontSize: '14px' }} />
                {campaign.reservedNumber ? (
                  <Badge status='success' text={<Text style={{ fontSize: '12px' }}>{campaign.reservedNumber}</Text>} />
                ) : (
                  <Text style={{ fontSize: '12px' }}>No number assigned</Text>
                )}
              </div>

              <div className='grid gap-1'>
                <div className='flex items-center gap-2'>
                  <ClockCircleOutlined style={{ color: token.colorTextSecondary, fontSize: '14px' }} />
                  {scheduleStatus?.[index]?.status === 'ACTIVE' ? (
                    <Badge
                      status='success'
                      text={
                        <Text style={{ fontSize: '12px' }}>
                          Schedule <Tag color='success'>Active</Tag>
                        </Text>
                      }
                    />
                  ) : scheduleStatus?.[index]?.status === 'INACTIVE' ? (
                    <Badge
                      status='warning'
                      text={
                        <Text style={{ fontSize: '12px' }}>
                          Schedule <Tag color='warning'>In Active</Tag>
                        </Text>
                      }
                    />
                  ) : (
                    <Badge status='default' text={<Text style={{ fontSize: '12px' }}>No Schedule</Text>} />
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className='flex flex-wrap gap-2 mt-auto'>
            {!campaign.reservedNumber && (
              <Button
                type='default'
                size='small'
                icon={<PhoneOutlined />}
                onClick={() => {
                  if (
                    (user.twilio_account_id && user.twilio_access_token) ||
                    (resellerView &&
                      !!(user as Reseller).outbound_twilio_account_id &&
                      !!(user as Reseller).outbound_twilio_access_token)
                  ) {
                    setCurrentClient(campaign.number);
                    openStandAloneNumberModal();
                  } else {
                    openBYOTInstructionsModal();
                  }
                }}
                loading={isProvisionApiPending}
                disabled={isProvisionApiPending}
                style={{ fontSize: '12px' }}
              >
                Get standalone number
              </Button>
            )}
            <Button type='primary' size='small' onClick={() => handleOpenCampaign(campaign.number)} className='ml-auto'>
              Open Campaign <RightOutlined style={{ marginLeft: '4px' }} />
            </Button>
          </div>
        </div>
      </Card>
    );
  };

  const RenderUnConfiguredCampaigns = useCallback(() => {
    const [deletingIndex, setDeletingIndex] = useState<number | null>(null);

    if (unConfiguredCampaignsArray?.length < 0) {
      return null;
    }

    const { mutate: deductCampaignSubMutation, isPending: isDeductingCampaignSub } = useMutation({
      mutationKey: ['deductCampaignSub'],
      mutationFn: deductCampaignFromSubscription,
      onSuccess: resp => {
        if (resp.success) {
          notification.success({ message: resp.message || 'Campaign removed from subscription' });
          updateUserLocalState({
            unConfiguredCampaigns: user.unConfiguredCampaigns - 1,
            totalCampaigns: user.totalCampaigns - 1,
          });
        } else {
          notification.error({ message: resp.message });
        }
        setDeletingIndex(null);
      },
      onError: err => {
        notification.error({ message: err.message });
        setDeletingIndex(null);
      },
    });

    const handleRemoveCampaignSubscription = async (index: number) => {
      setDeletingIndex(index);
      deductCampaignSubMutation();
    };

    return (
      <div className='mt-8'>
        <div className='flex items-center gap-2 mb-4'>
          <Title level={4} style={{ margin: 0, fontSize: '16px' }}>
            Unconfigured Campaigns
          </Title>
          <Badge count={unConfiguredCampaignsArray?.length} style={{ backgroundColor: token.colorWarning }} />
        </div>
        <Row gutter={[16, 16]}>
          {unConfiguredCampaignsArray?.map((_, index) => (
            <Col xs={24} sm={12} md={12} lg={8} xl={8} key={index}>
              <Card
                size='small'
                style={{
                  ...cardStyle,
                  background: token.colorWarningBg,
                  border: `1px solid ${token.colorWarningBorder}`,
                }}
              >
                <div className='flex flex-col gap-3'>
                  <Space>
                    <Avatar icon={<BuildOutlined />} style={{ backgroundColor: token.colorWarning }} size='small' />
                    <Text strong style={{ fontSize: '14px' }}>
                      Unconfigured Campaign
                    </Text>
                    <Popconfirm
                      title='Remove from subscription?'
                      description='This will permanently remove the un-configured campaign from your subscription.'
                      onConfirm={() => handleRemoveCampaignSubscription(index)}
                      okButtonProps={{ loading: isDeductingCampaignSub }}
                    >
                      <Tooltip title='Remove un-configured campaign from subscription'>
                        <Button
                          loading={isDeductingCampaignSub && deletingIndex === index}
                          disabled={isDeductingCampaignSub}
                          type='text'
                          icon={<DeleteFilled className='text-red-500' />}
                          size='small'
                        />
                      </Tooltip>
                    </Popconfirm>
                  </Space>
                  <Button type='primary' onClick={handleCreateCampaign} style={{ marginTop: 'auto' }}>
                    Configure Campaign
                  </Button>
                </div>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    );
  }, [unConfiguredCampaignsArray?.length]);

  if (showCreateCampaign) {
    const handleCampaignCreated = () => {
      updateUserLocalState({ unConfiguredCampaigns: user.unConfiguredCampaigns - 1 });
      notification.success({ message: 'Campaign created successfully' });
      refetch();
      setShowCreateCampaign(false);
    };

    return (
      <Layout>
        <Header style={headerStyle}>
          <Button icon={<ArrowLeftOutlined />} onClick={handleBack} size='small' style={{ marginRight: '12px' }}>
            Back to Campaigns
          </Button>
        </Header>
        <Content style={{ padding: '16px' }}>
          <RegisterPage
            debrand
            campaign_user_email={user.email_address}
            campaign_user_name={'business_name' in user ? user.business_name : user.reseller_name}
            isCampaign
            handleCampaignCreated={handleCampaignCreated}
            resellerOutbound={resellerView}
          />
        </Content>
      </Layout>
    );
  }

  if (
    (!(user as Reseller)?.outboundSubscriptionId || (user as Reseller)?.outboundSubscriptionStatus === 'canceled') &&
    resellerView
  ) {
    return (
      <NoSubscriptionView
        resellerView={resellerView}
        title={resellerView ? 'Intelligent Outbound Calls' : 'Upgrade your Experience'}
        subtitle={
          resellerView
            ? 'Offer white labeled outbound campaigns to your clients at a special price'
            : 'Take your business to the next level with our premium features'
        }
      />
    );
  }

  return (
    <Spin spinning={isLoading || isScheduleLoading || isRefetching}>
      <Layout style={{ background: 'transparent' }}>
        <div style={{ padding: '16px' }}>
          <Space direction='vertical' size='middle' style={{ width: '100%' }}>
            <div className='flex flex-wrap justify-between items-center gap-4'>
              <div className='flex items-center gap-2 mb-4'>
                <Title level={4} style={{ margin: 0, fontSize: '16px' }}>
                  My Campaigns
                </Title>
                <Badge count={filteredData?.length || 0} style={{ backgroundColor: token.colorSuccess }} />
              </div>
              <Space wrap>
                <Input.Search
                  placeholder='Search campaigns'
                  onSearch={handleSearch}
                  onChange={e => handleSearch(e.target.value)}
                  style={{ width: '240px' }}
                  size='small'
                  allowClear
                />
                <div ref={createCampaignButtonRef}>
                  <Button type='primary' icon={<PlusOutlined />} onClick={handleCreateCampaign} size='small'>
                    {user?.unConfiguredCampaigns > 0 ? 'Create Campaign' : 'Add Outbound Campaign'}
                  </Button>
                </div>
              </Space>
            </div>

            <Row gutter={[16, 16]}>
              {filteredData?.map((campaign: any, index: number) => (
                <Col xs={24} sm={12} md={12} lg={8} xl={8} key={campaign.number}>
                  <RenderCampaignCard campaign={campaign} index={index} />
                </Col>
              ))}
            </Row>
            <RenderUnConfiguredCampaigns />
          </Space>
        </div>

        <CustomModal
          title='Bring Your Own Twilio'
          isModalOpen={isBYOTInstructionsModalVisible}
          confirmAction={closeBYOTInstructionsModal}
          cancelButtonProps={{ style: { display: 'none' } }}
          className=''
        >
          <div className='text-center'>
            <Text style={{ fontSize: '14px' }}>
              Please head over to the <span className='font-bold'>"Branding"</span> tab to add your twilio config
            </Text>
            <p className='font-bold'>OR</p>
            <p>
              Follow this{' '}
              <a
                className='text-blue-600'
                href='https://www.loom.com/share/ae3b7257107243e39bc1792b5e2c83c0'
                target='_blank'
                rel='noreferrer'
              >
                video
              </a>
            </p>
          </div>
        </CustomModal>

        <CustomModal
          title='Terms and Conditions'
          isModalOpen={isTermsModalVisible}
          confirmAction={handleTermsSubmit}
          cancelAction={() => {}}
          cancelButtonProps={{ style: { display: 'none' } }}
          closable={false}
          maskClosable={false}
          okButtonProps={{ loading: isSavingTermsAcceptance, size: 'small' }}
        >
          <Space direction='vertical' size='small'>
            <Text style={{ fontSize: '14px' }}>
              Please read and accept our Terms and Conditions before creating a campaign.
            </Text>
            <Checkbox checked={termsAccepted} onChange={e => setTermsAccepted(e.target.checked)}>
              <Text style={{ fontSize: '12px' }}>
                I have read and agree to the{' '}
                <a
                  href={`${APP_LANDING_PAGE}/terms-of-service`}
                  target='_blank'
                  rel='noopener noreferrer'
                  className='text-blue-600 hover:text-black'
                >
                  Terms and Conditions
                </a>
              </Text>
            </Checkbox>
          </Space>
        </CustomModal>

        <CustomModal
          title='Get your standalone number'
          isModalOpen={isStandAloneNumberModalVisible}
          confirmAction={handleConfirmAction}
          cancelAction={closeStandAloneNumberModal}
          okButtonProps={{ loading: isProvisionApiPending }}
          okText='Get Random Number'
        >
          <GetStandAloneNumberForm
            debrand
            isNovi
            ref={formRef}
            setCountry={(country: string) => setSelectedCountry(country)}
            onSubmit={handleFormSubmit}
            setChooseNumberModalOpen={setChooseNumberModalOpen}
          />
        </CustomModal>
        <CustomModal
          title='Save your changes'
          isModalOpen={isBookNumberModalVisible}
          confirmAction={() => bookNumberRequest()}
          cancelAction={closeBookNumberModal}
        >
          <Title level={4}>Click Ok to confirm you want to buy number for {selectedCountry.split('-')[1]} </Title>
        </CustomModal>
        <CustomModal
          title='Get Your Favourite Area Code'
          isModalOpen={ChooseNumberModalOpen}
          confirmAction={() => {
            setChooseNumberModalOpen(false);
            setIsBookNumberModalVisible(false);
          }}
          cancelAction={() => setChooseNumberModalOpen(false)}
          footer={null}
        >
          <GetChooseNumberForm
            isOpen={ChooseNumberModalOpen}
            onClose={() => setChooseNumberModalOpen(false)}
            selectedCountryCode={selectedCountry.split(' - ')[2]}
            bookNumberRequest={() => bookNumberRequest()}
          />
        </CustomModal>
        <CustomModal
          title={null}
          isModalOpen={isNonUSDetailsModalOpen}
          confirmAction={() => setIsNonUSDetailsModalOpen(false)}
          cancelAction={() => setIsNonUSDetailsModalOpen(false)}
          width={700}
          footer={null}
        >
          <GetBundleApproveForm
            countryCodeNumber={selectedCountry.split(' - ')[2]}
            twilioDocsRequirements={twilioDocsRequirements.requirements}
            regulationSid={twilioDocsRequirements.regulationSid}
            setClientDetails={() => {}}
            onClose={() => setIsNonUSDetailsModalOpen(false)}
          />
        </CustomModal>
        <Modal
          title='Edit Campaign'
          open={!!editCampaignModal}
          onOk={handleUpdateCampaignName}
          onCancel={handleCancelEditCampaign}
          width={600}
          centered
          footer={[
            <Button key='cancel' onClick={handleCancelEditCampaign}>
              Cancel
            </Button>,
            <Button key='save' type='primary' onClick={handleUpdateCampaignName} loading={isUpdatingName}>
              Save
            </Button>,
          ]}
        >
          <Form form={updateCampaignNameForm} layout='vertical'>
            <Form.Item
              name='campaignName'
              label='Campaign Name'
              rules={[{ required: true, message: 'Please enter a campaign name' }]}
            >
              <Input placeholder='Enter campaign name' />
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          title='Duplicate Campaign'
          open={!!duplicateCampaignModal}
          onCancel={handleCancelDuplicateCampaign}
          footer={[
            <Button key='cancel' onClick={handleCancelDuplicateCampaign}>
              Cancel
            </Button>,
            <Button key='duplicate' type='primary' onClick={duplicateCampaign} loading={isDuplicatingCampaign}>
              Duplicate
            </Button>,
          ]}
        >
          <Form form={duplicateCampaignForm} layout='vertical'>
            {resellerView ? (
              <>
                <Form.Item
                  name='duplicateCampaignUsername'
                  label={'New Campaign Username'}
                  rules={[
                    {
                      required: true,
                      message: 'Please enter a username for the new campaign',
                    },
                    {
                      pattern: /^(?!.*\s)[a-z0-9._#@!$%]*$/,
                      message: 'Spaces are not allowed',
                    },
                  ]}
                  normalize={value => value?.toLowerCase()}
                >
                  <Input placeholder='Enter new campaign username' />
                </Form.Item>
                <Form.Item
                  name='duplicateCampaignPassword'
                  label={'New Campaign Password'}
                  rules={[
                    {
                      required: true,
                      message: 'Please enter a password for the new campaign',
                    },
                  ]}
                >
                  <Input placeholder='Enter new campaign password' />
                </Form.Item>
              </>
            ) : (
              <Form.Item
                name='duplicateCampaignName'
                label={'New Campaign Name'}
                rules={[
                  {
                    required: true,
                    message: 'Please enter a name for the new campaign',
                  },
                ]}
              >
                <Input placeholder='Enter new campaign name' />
              </Form.Item>
            )}
            <Text type='secondary'>The new campaign will be created with the same settings as the original.</Text>
          </Form>
        </Modal>
        <BuyMoreCampaignsModal onBuyMoreCampaigns={handleBuyMoreCampaigns} loading={isBuyingMoreCampaigns} />
        <NoviPlansModal />
        <PaymentFailedModal />
        <RedirectTwilioConfigModal />
      </Layout>
    </Spin>
  );
};

export default NoviCampaigns;
