import React from 'react';
import { Card, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Search, Share2, Users, Megaphone, MessageCircle, HelpCircle } from 'lucide-react';
import StepWrapper from './StepWrapper';

interface StepFinalProps {
  stepState: number;
  whereFoundUs: string | null;
  setWhereFoundUs: (option: string) => void;
  handleNext: () => void;
}

const options = [
  {
    label: 'Google Search',
    Icon: Search,
    info: 'Found us through a search engine like Google or Bing.',
    bgColor: 'bg-blue-50',
  },
  {
    label: 'Social Media',
    Icon: Share2,
    info: 'Discovered us on platforms like Facebook, Instagram, or LinkedIn.',
    bgColor: 'bg-pink-50',
  },
  {
    label: 'A Friend',
    Icon: Users,
    info: 'Heard about us through a recommendation from a friend or colleague.',
    bgColor: 'bg-green-50',
  },
  {
    label: 'Advertisement',
    Icon: Megaphone,
    info: 'Came across our ads on websites, TV, or social media platforms.',
    bgColor: 'bg-yellow-50',
  },
  {
    label: 'Word of Mouth',
    Icon: MessageCircle,
    info: 'Learned about us through conversations or referrals.',
    bgColor: 'bg-purple-50',
  },
  {
    label: 'Other',
    Icon: HelpCircle,
    info: 'Found us through a different source or means.',
    bgColor: 'bg-gray-50',
  },
];

const StepFinal: React.FC<StepFinalProps> = ({ whereFoundUs, setWhereFoundUs, handleNext }) => {
  return (
    <StepWrapper handleNext={handleNext} disableNext={!whereFoundUs} continueBtnText='Build my AI'>
      <div className='w-full flex flex-col'>
        <div className='flex flex-col items-start w-full'>
          <h3 className='sm:text-3xl text-xl font-bold text-gray-900 text-start mb-0'>
            One last thing: how did you find us?
          </h3>
          <p className='text-gray-500 sm:text-lg text-sm'>Help us understand how you discovered our platform</p>
        </div>

        <div className='grid grid-cols-1 sm:grid-cols-2 gap-4'>
          {options.map(({ label, Icon, info, bgColor }) => (
            <Card
              key={label}
              className={`relative cursor-pointer transition-all duration-200 hover:shadow-md ${
                whereFoundUs === label ? 'ring-2 ring-blue-600 shadow-sm' : 'hover:border-gray-300'
              }`}
              onClick={() => setWhereFoundUs(label)}
            >
              <div className='p-4 flex items-center space-x-4'>
                <div className={`p-3 rounded-full ${bgColor}`}>
                  <Icon className={`w-6 h-6 ${whereFoundUs === label ? 'text-blue-600' : 'text-gray-600'}`} />
                </div>
                <div className='flex-grow'>
                  <span className='font-medium text-gray-900'>{label}</span>
                </div>
                <Tooltip title={info}>
                  <InfoCircleOutlined
                    className={`text-lg ${whereFoundUs === label ? 'text-blue-600' : 'text-gray-400'}`}
                  />
                </Tooltip>
              </div>
            </Card>
          ))}
        </div>
      </div>
    </StepWrapper>
  );
};

export default StepFinal;
