import { useState, useEffect } from 'react';
import { Modal } from 'antd';
import { QRCodeCanvas } from 'qrcode.react';
import { useMutation } from '@tanstack/react-query';
import { getProvisionedNumber } from '@/api/reservedNumbers';
import IphoneQRCodeCameraSVG from './IphoneQRCodeCameraSVG';
import AndroidQRCodeCameraSVG from './AndroidQRCodeCameraSVG';

const formatPhoneNumber = (phoneNumber: string): string => {
  return phoneNumber.replace(/[^0-9]/g, '');
};

const QRCodeGenerator = ({ prefix, postfix, isAndroid }: { prefix: string; postfix?: string; isAndroid?: boolean }) => {
  const [provisionedNumber, setProvisionedNumber] = useState<string | null>(null);
  const [isInstantSetupModalOpen, setInstantSetupModalOpen] = useState(false);
  const [isManualSetupModalOpen, setManualSetupModalOpen] = useState(false);

  const getProvisionNumberMutation = useMutation({
    mutationKey: ['provisionedNumberApi'],
    mutationFn: getProvisionedNumber,
    onSuccess(data) {
      const formattedNumber = formatPhoneNumber(data.provisioned_number || '');
      setProvisionedNumber(formattedNumber);
    },
  });

  useEffect(() => {
    getProvisionNumberMutation.mutate();
  }, []);

  const qrValue = provisionedNumber ? `${prefix}${provisionedNumber}${postfix ? postfix : ''}` : '';

  return (
    <div className='max-w-4xl mx-auto p-6 bg-gray-50 rounded-lg shadow-md'>
      <p className='text-lg'>
        When ready, <b>click Start Instant Setup</b> and open the default camera app on your iPhone.{' '}
        <b>Scan the QR code.</b>
      </p>

      <div className='h-44 mb-0.5'>
        <div className='flex h-full w-full justify-center px-sm'>
          {isAndroid ? (
            <AndroidQRCodeCameraSVG>{qrValue}</AndroidQRCodeCameraSVG>
          ) : (
            <IphoneQRCodeCameraSVG>{qrValue}</IphoneQRCodeCameraSVG>
          )}
        </div>
      </div>
      <button
        onClick={() => setInstantSetupModalOpen(true)}
        className='w-full bg-blue-600 text-white font-bold py-4 rounded-lg mb-2 hover:bg-blue-700'
      >
        Start Instant Setup
      </button>
      <button
        onClick={() => setManualSetupModalOpen(true)}
        className='w-full bg-gray-300 text-black font-bold py-4 rounded-lg hover:bg-gray-400'
      >
        Manual Setup
      </button>

      {/* Instant Setup Modal */}
      <Modal
        title={<span className='p-2 text-xl'>Instant Setup</span>}
        open={isInstantSetupModalOpen}
        onCancel={() => setInstantSetupModalOpen(false)}
        footer={null}
      >
        <div className='flex flex-col items-center my-4'>
          {provisionedNumber ? (
            <>
              <QRCodeCanvas value={`tel:${qrValue}`} size={200} />
              <p className='text-gray-600 text-center mt-4'>
                Scan this QR code with your phone's camera to quickly set up your AI receptionist.
              </p>
            </>
          ) : (
            <p className='text-gray-600'>Generating QR code...</p>
          )}
        </div>
      </Modal>

      {/* Manual Setup Modal */}
      <Modal
        title={<span className='py-2 text-xl'>Manual Setup</span>}
        open={isManualSetupModalOpen}
        onCancel={() => setManualSetupModalOpen(false)}
        footer={null}
      >
        <div className='text-center my-4'>
          <p className='text-gray-700 mb-4'>
            To set up manually, please dial the following number and follow the instructions:
          </p>
          <p className='text-3xl font-bold text-blue-600'>{qrValue}</p>
          <p className='text-gray-600 mt-4'>
            Follow the audio prompts to complete the setup. Make sure your phone is connected to the network.
          </p>
        </div>
      </Modal>
    </div>
  );
};

export default QRCodeGenerator;
