import React, { useState, useEffect } from 'react';
import { Upload, message, Image, Form } from 'antd';
import type { UploadFile, UploadProps, RcFile } from 'antd/es/upload/interface';
import { InboxOutlined } from '@ant-design/icons';
import { LabelWithDescription } from '@/components/LabelWithdescription';

const { Dragger } = Upload;

interface ImageUploadProps {
  value?: File;
  onChange?: (file: File | null) => void;
  presignedUrl?: string;
  name?: string;
}

const IMAGE_SIZE_LIMIT = 1024 * 1024; // 1 MB
const ALLOWED_FILE_TYPES = ['image/jpeg', 'image/jpg', 'image/png'];

const ImageUpload: React.FC<ImageUploadProps> = ({ value, onChange, presignedUrl, name = 'image' }) => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [previewUrl, setPreviewUrl] = useState<string>(presignedUrl || '');

  useEffect(() => {
    if (value) {
      const reader = new FileReader();
      reader.readAsDataURL(value);
      reader.onload = () => {
        const base64Image = reader.result as string;
        setPreviewUrl(base64Image);
        setFileList([
          {
            uid: '-1',
            name: value.name,
            status: 'done',
            url: base64Image,
          },
        ]);
      };
    }
  }, [value]);

  useEffect(() => {
    if (presignedUrl) {
      setPreviewUrl(presignedUrl);
      setFileList([
        {
          uid: '-1',
          name: 'Existing Image',
          status: 'done',
          url: presignedUrl,
        },
      ]);
    }
  }, [presignedUrl]);

  const beforeUpload = (file: RcFile): boolean => {
    // File type validation
    if (!ALLOWED_FILE_TYPES.includes(file.type)) {
      message.error('Please upload a JPEG/JPG/PNG file only!');
      return false;
    }

    // File size validation
    if (file.size > IMAGE_SIZE_LIMIT) {
      message.error('Image must be smaller than 1 MB!');
      return false;
    }

    return true;
  };

  const handleChange: UploadProps['onChange'] = ({ file, fileList }) => {
    // Update the file list state
    setFileList(fileList);

    // Handle the file based on status
    if (file.status === 'done') {
      const imageFile = file.originFileObj;
      if (imageFile) {
        const reader = new FileReader();
        reader.readAsDataURL(imageFile);
        reader.onload = () => {
          setPreviewUrl(reader.result as string);
        };
        onChange?.(imageFile);
      }
      message.success(`${file.name} uploaded successfully`);
    } else if (file.status === 'error') {
      message.error(`${file.name} upload failed`);
    } else if (file.status === 'removed') {
      setPreviewUrl('');
      // set the status to remove the file from the server
      setFileList(() => {
        return fileList.map(item => {
          if (item.uid === file.uid) {
            return {
              ...item,
              status: 'removed',
            };
          }
          return item;
        });
      });
      onChange?.(null);
      message.info(`${file.name} removed`);
    }
  };

  const uploadProps: UploadProps = {
    name,
    multiple: false,
    fileList,
    beforeUpload,
    onChange: handleChange,
    onDrop: e => {
      console.log('Dropped files', e.dataTransfer.files);
    },
    customRequest: ({ onSuccess }) => {
      // Mock successful upload
      setTimeout(() => {
        onSuccess?.({} as any);
      }, 0);
    },
    showUploadList: {
      showPreviewIcon: true,
      showRemoveIcon: true,
    },
  };

  return (
    <Form.Item
      name={name}
      valuePropName='file'
      getValueFromEvent={e => {
        if (Array.isArray(e)) {
          return e;
        }
        return e?.fileList;
      }}
    >
      <div className='space-y-4'>
        <LabelWithDescription
          label='Attach Image (Optional)'
          description='Please attach a JPEG/JPG/PNG file not more than 1 MB'
        />

        <Dragger {...uploadProps}>
          <p className='ant-upload-drag-icon'>
            <InboxOutlined />
          </p>
          <p className='ant-upload-text'>Click or drag file to this area to upload</p>
          <p className='ant-upload-hint'>Must be JPEG/JPG/PNG image (Max. 1 MB)</p>
        </Dragger>

        {previewUrl && (
          <div className='mt-4'>
            <Image src={previewUrl} alt='Preview' className='max-w-full h-auto' />
          </div>
        )}
      </div>
    </Form.Item>
  );
};

export default ImageUpload;
