import React from 'react';
import Fade from 'react-reveal/Fade';
import classes from '../RegisterPageV3.module.css';

const Step6B = ({
  setTextingTemplateModalActive,
  setSelectedTextingTemplate,
  textingWorkflowsTemplates,
  selectedTextingTemplate,
  textingTemplateModalActive,
  setWorkflows,
  workflows,
}) => {
  return (
    <>
      <div
        className={classes.box + ' ' + classes.modal + ' ' + (textingTemplateModalActive && classes.active)}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <div className={classes.close} onClick={() => setTextingTemplateModalActive(false)}>
          &times;
        </div>
        <Fade duration={1000} bottom>
          <h3
            className={classes.heading}
            style={{
              textAlign: 'center',
            }}
          >
            Prompt Templates
          </h3>

          <div className={classes.subtitle + ' '} style={{ marginBottom: '.5rem' }}>
            Select a template
          </div>

          <select
            style={{ marginBottom: '1rem' }}
            className={classes.input}
            // value={selectedGreetingTemplate}
            onChange={e =>
              setSelectedTextingTemplate(
                textingWorkflowsTemplates.find(el => el.scenarioDescription === e.target.value)
              )
            }
          >
            <option value=''>Choose an option</option>
            {textingWorkflowsTemplates.map((el, idx) => {
              return <option key={el.scenarioDescription}>{el.scenarioDescription}</option>;
            })}
          </select>

          <div style={{ width: '100%' }}>
            <h5 className={classes.label}>Trigger</h5>
            <p>{selectedTextingTemplate?.scenarioDescription}</p>
            <h5 className={classes.label}>Text Message</h5>
            <p>{selectedTextingTemplate?.textMessage}</p>
            <h5 className={classes.label}>Voice Response</h5>
            <p>{selectedTextingTemplate?.voiceResponse}</p>
          </div>

          <button
            type='button'
            onClick={() => {
              const updateDescription = (description, words, currentIndex, setFunction, intervalName) => {
                const modifiedWorkflows = [...workflows];
                const lastWorkflow = modifiedWorkflows[modifiedWorkflows.length - 1];
                lastWorkflow[setFunction] = '';

                const interval = setInterval(() => {
                  const modifiedWorkflows = [...workflows];
                  const lastWorkflow = modifiedWorkflows[modifiedWorkflows.length - 1];

                  if (currentIndex < words.length) {
                    lastWorkflow[setFunction] += words[currentIndex] + ' ';
                    currentIndex++;
                    setWorkflows(modifiedWorkflows);
                  } else {
                    clearInterval(interval);
                  }
                }, 50);
              };

              const scenarioDescription = selectedTextingTemplate?.scenarioDescription;
              const scenarioWords = scenarioDescription.split(' ');
              let currentIndex1 = 0;

              const textDescription = selectedTextingTemplate?.textMessage;
              const textWords = textDescription.split(' ');
              let currentIndex2 = 0;

              const voiceResponse = selectedTextingTemplate?.voiceResponse;
              const voiceWords = voiceResponse.split(' ');
              let currentIndex3 = 0;

              updateDescription(scenarioDescription, scenarioWords, currentIndex1, 'description', 'interval1');
              updateDescription(textDescription, textWords, currentIndex2, 'textMessage', 'interval2');
              updateDescription(voiceResponse, voiceWords, currentIndex3, 'voiceResponse', 'interval3');
              setTextingTemplateModalActive(false);
            }}
            className={classes.button}
            style={{
              marginTop: '2rem',
            }}
          >
            Use Template
          </button>
        </Fade>
      </div>
    </>
  );
};

export default Step6B;
