import { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Form, Input, Select, Button, Card, Space, Typography, Tooltip } from 'antd';
import { PlusOutlined, FilterOutlined, InfoCircleOutlined, PhoneOutlined } from '@ant-design/icons';
import TextArea from 'antd/es/input/TextArea';
import { MessageSquare } from 'lucide-react';
import { TClient } from '@/types/TClient';
import { CRMGroup, ScenarioTriggerDetails, WorkflowTriggerDetails } from '@/types/TCRM';
import { TCallTransferWorkflow, TTextingWorkflow } from '@/types/TWorkflows';
import { CRM_TRIGGER_TYPE, WORKFLOW_TYPE } from 'src/enums/ECRM';

const { Title, Text } = Typography;
const { Option, OptGroup } = Select;

interface CRMGroupCreatorProps {
  initialData?: CRMGroup;
  onSubmit: (groupData: CRMGroup) => void;
  isLoading: boolean;
  clientData: TClient;
}

export interface CRMGroupCreatorRef {
  resetForm: () => void;
}

const CRMGroupCreator = forwardRef<CRMGroupCreatorRef, CRMGroupCreatorProps>(
  ({ initialData, onSubmit, isLoading, clientData }, ref) => {
    const [form] = Form.useForm();
    const [triggerType, setTriggerType] = useState<'scenario' | 'workflow'>(initialData?.trigger_type || 'scenario');

    let textingWorkflow: Array<TTextingWorkflow> = [];
    let callTransferWorkflow: Array<TCallTransferWorkflow> = [];
    try {
      textingWorkflow = JSON.parse(clientData?.workflows as string);
      callTransferWorkflow = JSON.parse(clientData?.callWorkflows as string);
    } catch (error) {
      console.error('Unable to parse the workflow', error);
    }

    useEffect(() => {
      if (initialData) {
        setTriggerType(initialData.trigger_type);
        form.setFieldsValue({
          groupID: initialData.group_id,
          groupName: initialData.group_name,
          triggerType: initialData.trigger_type,
          condition: initialData.trigger_details?.condition,
          workflow: initialData.trigger_details?.workflow?.workflow_id,
        });
      }
    }, [initialData, form]);

    const resetForm = () => {
      form.resetFields();
      setTriggerType('scenario');
    };

    useImperativeHandle(ref, () => ({
      resetForm,
    }));

    const handleSubmit = () => {
      form
        .validateFields()
        .then(
          (values: {
            groupID: string;
            groupName: string;
            triggerType: string;
            condition: string;
            workflow: string;
          }) => {
            let triggerDetails: ScenarioTriggerDetails | WorkflowTriggerDetails;

            if (triggerType === CRM_TRIGGER_TYPE.SCENARIO) {
              triggerDetails = {
                condition: values.condition as string,
              };
            } else if (triggerType === CRM_TRIGGER_TYPE.WORKFLOW) {
              let selectedWorkflowType: WORKFLOW_TYPE;

              const foundWorkflow = textingWorkflow.find(w => w.id === values.workflow);

              if (foundWorkflow) {
                selectedWorkflowType = WORKFLOW_TYPE.TEXTING;
              } else {
                const foundWorkflow = callTransferWorkflow.find(w => w.id === values.workflow);

                if (foundWorkflow) {
                  selectedWorkflowType = WORKFLOW_TYPE.CALL_TRANSFER;
                } else {
                  console.error('Invalid workflow');
                  throw new Error('Invalid workflow');
                }
              }

              triggerDetails = {
                workflow: {
                  workflow_id: values.workflow,
                  workflow_type: selectedWorkflowType,
                },
              };
            } else {
              console.error('Invalid trigger type');
              throw new Error('Invalid trigger type');
            }

            const groupData: CRMGroup = {
              group_id: initialData?.group_id || '',
              group_name: values.groupName,
              trigger_type: triggerType as CRM_TRIGGER_TYPE,
              trigger_details: triggerDetails,
            };

            onSubmit(groupData);
          }
        )
        .catch(errorInfo => {
          console.log('Validation Failed:', errorInfo);
        });
    };

    return (
      <Card
        style={{
          maxWidth: 800,
          margin: '0 auto',
          boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
          borderRadius: 12,
        }}
      >
        <Title
          level={3}
          style={{
            textAlign: 'center',
            marginBottom: 24,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 12,
            color: '#333',
          }}
        >
          <FilterOutlined style={{ color: '#1890ff' }} />
          {initialData ? 'Edit Lead Group' : 'Create New Lead Group'}
        </Title>

        <Form form={form} layout='vertical' initialValues={{ triggerType: 'scenario' }}>
          <Form.Item
            name='groupName'
            label={
              <Space>
                <Text>Group Name</Text>
                <Tooltip title='Choose a descriptive name for your lead group'>
                  <InfoCircleOutlined style={{ color: '#1890ff' }} />
                </Tooltip>
              </Space>
            }
            rules={[
              {
                required: true,
                message: 'Please enter a group name',
              },
            ]}
          >
            <Input
              placeholder='Enter group name (e.g., High-Value Leads)'
              prefix={<FilterOutlined />}
              style={{ borderRadius: 8 }}
            />
          </Form.Item>

          <Form.Item
            label={
              <Space>
                <Text>Trigger Type</Text>
                <Tooltip title='Choose how leads will be automatically grouped'>
                  <InfoCircleOutlined style={{ color: '#1890ff' }} />
                </Tooltip>
              </Space>
            }
          >
            <Select
              value={triggerType}
              onChange={value => setTriggerType(value)}
              style={{ width: '100%', borderRadius: 8 }}
            >
              <Option value='scenario'>
                <Space>
                  <FilterOutlined /> Scenario-Based
                </Space>
              </Option>
              <Option value='workflow'>
                <Space>
                  <PlusOutlined /> Workflow-Triggered
                </Space>
              </Option>
            </Select>
          </Form.Item>

          {triggerType === 'scenario' && (
            <Card type='inner' title='Scenario Condition' style={{ borderRadius: 8, marginBottom: 16 }}>
              <Form.Item
                name='condition'
                label={
                  <Space>
                    <Text>Condition</Text>
                    <Tooltip title='Choose a condition to trigger this group'>
                      <InfoCircleOutlined style={{ color: '#1890ff' }} />
                    </Tooltip>
                  </Space>
                }
                rules={[{ required: true, message: 'Condition value cannot be empty' }]}
              >
                <TextArea
                  placeholder='Enter condition value'
                  autoSize={{ minRows: 3 }}
                  style={{ width: '100%', borderRadius: 8 }}
                />
              </Form.Item>
            </Card>
          )}

          {triggerType === 'workflow' && (
            <Form.Item
              name='workflow'
              label={
                <Space>
                  <Text>Select Workflow</Text>
                  <Tooltip title='Choose workflow that will trigger this group'>
                    <InfoCircleOutlined className='text-blue-500' />
                  </Tooltip>
                </Space>
              }
              rules={[{ required: true, message: 'Please select at least one workflow' }]}
            >
              <Select
                placeholder='Select workflow that trigger this group'
                className='w-full'
                optionLabelProp='label'
                dropdownStyle={{ padding: 0 }}
                optionRender={option => {
                  const value = option.value as string;
                  const workflow = [...textingWorkflow, ...callTransferWorkflow].find(w => w.id === value);

                  if (!workflow) return null;

                  const isTextWorkflow = 'textMessage' in workflow;

                  return (
                    <div className='p-3 hover:bg-gray-50'>
                      <div className='flex items-start gap-3'>
                        <div className={`p-2 rounded-lg ${isTextWorkflow ? 'bg-green-50' : 'bg-blue-50'}`}>
                          {isTextWorkflow ? (
                            <MessageSquare className='w-5 h-5 text-green-600' />
                          ) : (
                            <PhoneOutlined className='w-5 h-5 text-blue-600' />
                          )}
                        </div>
                        <div className='flex-1'>
                          <div className='font-semibold text-gray-800'>
                            {workflow.description || 'Unnamed Workflow'}
                          </div>
                          <div className='mt-2 space-y-1'>
                            {isTextWorkflow ? (
                              <>
                                <div className='text-sm text-gray-600 flex items-start gap-2'>
                                  <span className='font-medium min-w-24'>Message:</span>
                                  <span className='text-gray-500'>{workflow.textMessage}</span>
                                </div>
                                <div className='text-sm text-gray-600 flex items-start gap-2'>
                                  <span className='font-medium min-w-24'>Voice Response:</span>
                                  <span className='text-gray-500'>{workflow.voiceResponse}</span>
                                </div>
                                {workflow.customTextFooter && (
                                  <div className='text-sm text-gray-600 flex items-start gap-2'>
                                    <span className='font-medium min-w-24'>Footer:</span>
                                    <span className='text-gray-500'>{workflow.customTextFooter}</span>
                                  </div>
                                )}
                              </>
                            ) : (
                              <>
                                {workflow.workflowForwardingNumber && (
                                  <div className='text-sm text-gray-600 flex items-start gap-2'>
                                    <span className='font-medium min-w-24'>Forward to:</span>
                                    <span className='text-gray-500'>{workflow.workflowForwardingNumber}</span>
                                  </div>
                                )}
                                {workflow.workflowForwardingDID && (
                                  <div className='text-sm text-gray-600 flex items-start gap-2'>
                                    <span className='font-medium min-w-24'>DID:</span>
                                    <span className='text-gray-500'>{workflow.workflowForwardingDID}</span>
                                  </div>
                                )}
                                {workflow.voiceResponse && (
                                  <div className='text-sm text-gray-600 flex items-start gap-2'>
                                    <span className='font-medium min-w-24'>Voice Response:</span>
                                    <span className='text-gray-500'>{workflow.voiceResponse}</span>
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                          <div className='mt-2 pt-2 border-t border-gray-100'>
                            <code className='text-xs text-gray-400 font-mono'>ID: {workflow.id}</code>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }}
              >
                <OptGroup
                  label={
                    <div className='flex items-center gap-2 py-3 px-3 bg-gray-50 border-b border-gray-200'>
                      <MessageSquare className='w-5 h-5 text-green-600' />
                      <span className='font-semibold'>Text Message Workflows</span>
                    </div>
                  }
                >
                  {textingWorkflow.map(txtWorkflow => (
                    <Option key={txtWorkflow.id} value={txtWorkflow.id}>
                      {txtWorkflow.description || 'Unnamed Workflow'}
                    </Option>
                  ))}
                </OptGroup>

                <OptGroup
                  label={
                    <div className='flex items-center gap-2 py-3 px-3 bg-gray-50 border-b border-gray-200'>
                      <PhoneOutlined className='w-5 h-5 text-blue-600' />
                      <span className='font-semibold'>Call Transfer Workflows</span>
                    </div>
                  }
                >
                  {callTransferWorkflow.map(callWorkflow => (
                    <Option key={callWorkflow.id} value={callWorkflow.id}>
                      {callWorkflow.description || 'Unnamed Workflow'}
                    </Option>
                  ))}
                </OptGroup>
              </Select>
            </Form.Item>
          )}

          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: 24,
            }}
          >
            <Button
              type='primary'
              onClick={handleSubmit}
              icon={<PlusOutlined />}
              size='large'
              style={{
                borderRadius: 8,
                padding: '0 24px',
              }}
              loading={isLoading}
            >
              {initialData ? 'Update Group' : 'Create Group'}
            </Button>
          </div>
        </Form>
      </Card>
    );
  }
);

export default CRMGroupCreator;
