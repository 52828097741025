import React from 'react';
import { Input, Checkbox } from 'antd';
import { LockIcon } from 'lucide-react';
import StepWrapper from './StepWrapper';

interface StepCTwoIIProps {
  username: string;
  setUsername: (username: string) => void;
  password: string;
  setPassword: (password: string) => void;
  handleNext: () => void;
}

const PasswordInput: React.FC<StepCTwoIIProps> = ({ password, setPassword, handleNext }) => {
  const [termsAccepted, setTermsAccepted] = React.useState(false);

  return (
    <StepWrapper handleNext={handleNext} disableNext={!password || !termsAccepted}>
      <div className='w-full flex flex-col'>
        <div className='flex flex-col items-start w-full'>
          <h3 className='sm:text-3xl text-xl font-bold text-gray-900 text-start mb-0'>Create a secure password</h3>
          <p className='text-gray-500 sm:text-lg text-sm'>Choose a strong password to protect your account</p>
        </div>

        <div className='=space-y-6'>
          <div className='relative'>
            <Input.Password
              value={password}
              onChange={e => setPassword(e.target.value)}
              placeholder='Enter your password'
              prefix={<LockIcon className='h-5 w-5 text-gray-400 mr-1' />}
              className='h-12'
              size='large'
            />
          </div>

          <div className='flex items-end space-x-2 mt-2'>
            <Checkbox checked={termsAccepted} onChange={e => setTermsAccepted(e.target.checked)} className='mt-1' />
            <span className='text-sm text-gray-600'>
              I agree to the{' '}
              <a
                href='https://www.myaifrontdesk.com/terms-of-service'
                target='_blank'
                rel='noopener noreferrer'
                className='text-blue-600 hover:text-blue-800 hover:underline'
              >
                Terms and Conditions
              </a>
            </span>
          </div>
        </div>
      </div>
    </StepWrapper>
  );
};

export default PasswordInput;
