import React from 'react';
import StepWrapper from './StepWrapper';

interface RevenueSelectorProps {
  stepState: number;
  selectedPeople: string | null;
  setSelectedPeople: (value: string) => void;
  selectedRevenue: string | null;
  setSelectedRevenue: (value: string) => void;
  handleStepTransition: (nextStep: number) => void;
  businessType?: string;
}

const revenueRanges = [
  '$0 - $50K',
  '$50K - $150K',
  '$150K - $500K',
  '$500K - $2M',
  '$2M+',
  "I'd prefer not to say",
] as const;

type RevenueRange = (typeof revenueRanges)[number];

const RevenueSelector: React.FC<RevenueSelectorProps> = ({
  stepState,
  selectedRevenue,
  setSelectedRevenue,
  handleStepTransition,
}) => {
  const handleSelection = (value: RevenueRange) => {
    setSelectedRevenue(value);
  };

  return (
    <StepWrapper handleNext={() => handleStepTransition(stepState + 1)} disableNext={!selectedRevenue}>
      <div className='w-full flex flex-col'>
        <div className='flex flex-col items-start w-full'>
          <h3 className='sm:text-3xl text-xl font-bold text-gray-900 text-start mb-0'>
            What's your estimated annual revenue?
          </h3>
          <p className='text-gray-500 sm:text-lg text-sm'>Help us understand your business scale</p>
        </div>

        <div className='grid grid-cols-2 sm:grid-cols-3 gap-3'>
          {revenueRanges.map(revenue => (
            <button
              key={revenue}
              onClick={() => handleSelection(revenue)}
              className={`p-3 rounded-full border text-center sm:text-sm text-xs font-medium transition-all ${
                selectedRevenue === revenue
                  ? 'bg-button-success text-white border-button-success'
                  : 'bg-white text-gray-800 border-gray-300 hover:bg-gray-100'
              }`}
            >
              {revenue}
            </button>
          ))}
        </div>
      </div>
    </StepWrapper>
  );
};

export default RevenueSelector;
