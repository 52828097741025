import { TAnalytics, THourlyCallLogs } from '@/types/TAnalytics';
import { Line } from '@ant-design/plots';
import moment from 'moment';
import { useDateFormatFromCountry } from 'src/common/hooks/useDateFormatFromCountry';

export const HourlyCallLogs = ({ analyticsData, timeZone }: TAnalytics) => {
  const { dateFormat } = useDateFormatFromCountry();
  const hourlyData = analyticsData as THourlyCallLogs[];

  const updatedData = hourlyData.map(row => {
    return {
      ...row,
      time: moment(`${row.date} ${row.time}`)
        .tz(timeZone as string)
        .format('h:mm A'),
    };
  });

  const config = {
    data: updatedData,
    xField: 'time',
    yField: 'value',
    yAxis: {
      title: {
        text: 'Total Calls',
      },
    },
    point: {
      shapeField: 'circle',
      sizeField: 2,
    },
    sizeField: 'value',
    shapeField: 'trail',
    legend: {
      size: false,
      color: {
        layout: {
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        },
        labelFormatter: (datum: Date) => moment(datum).format(`ddd ${dateFormat}`),
      },
    },
    colorField: (row: THourlyCallLogs) => row.date,
    tooltip: {
      title: 'time',
      items: [{ channel: 'y' }],
    },
  };

  return <Line {...config} />;
};
