import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../..';

const noviAccount = (state: RootState) => state.noviUserAccount;

export const getLoggedInNoviUser = createSelector([noviAccount], noviAccount => noviAccount.user);
export const getNoviCurrentPage = createSelector([noviAccount], noviAccount => noviAccount.currentPage);
export const getSelectedCampaignId = createSelector([noviAccount], noviAccount => noviAccount.selectedCampaignId);
export const getSelectedCampaign = createSelector([noviAccount], noviAccount => noviAccount.selectedCampaign);
export const getCrmGroups = createSelector([noviAccount], noviAccount => noviAccount.crmGroups);
