import { AIModelSound } from './enums/EAISounds';
import AURA_ASTERIA_EN from '@/sounds/aura-asteria-en.wav';
import AURA_LUNA_EN from '@/sounds/aura-luna-en.wav';
import AURA_STELLA_EN from '@/sounds/aura-stella-en.wav';
import AURA_ATHENA_EN from '@/sounds/aura-athena-en.wav';
import AURA_ORION_EN from '@/sounds/aura-orion-en.mp3';
import AURA_ARCAS_EN from '@/sounds/aura-arcas-en.mp3';
import AURA_PERSEUS_EN from '@/sounds/aura-perseus-en.wav';
import AURA_ANGUS_EN from '@/sounds/aura-angus-en.wav';
import AURA_HELIOS_EN from '@/sounds/aura-helios-en.wav';
import AURA_ORPHEUS_EN from '@/sounds/aura-orpheus-en.wav';
import AURA_ZEUS_EN from '@/sounds/aura-zeus-en.wav';

export const CONTACT_SUPPORT_EMAIL = 'contact@myaifrontdesk.com';
export const RECEPTIONIST_NUMBER = '+18484209420';
export const RECEPTIONIST_NUMBER_AUSTRALIA = '+61440135624';
export const RECEPTIONIST_NUMBER_UK = '+447533024217';
export const RECEPTIONIST_NUMBER_NL = '+3197010206577';
export const RECEPTIONIST_NUMBER_US_RESELLER = '+18017841398';
export const RECEPTIONIST_NUMBER_UK_RESELLER = '+447883284637';
export const RECEPTIONIST_NUMBER_AU_RESELLER = '+61440137983';
export const RECEPTIONIST_NUMBER_NL_RESELLER = '+3197010257950';

export const AIModelSounds: { [key in AIModelSound]: HTMLAudioElement } = {
  [AIModelSound.AURA_ASTERIA_EN]: new Audio(AURA_ASTERIA_EN),
  [AIModelSound.AURA_LUNA_EN]: new Audio(AURA_LUNA_EN),
  [AIModelSound.AURA_ATHENA_EN]: new Audio(AURA_ATHENA_EN),
  [AIModelSound.AURA_STELLA_EN]: new Audio(AURA_STELLA_EN),
  [AIModelSound.AURA_ORION_EN]: new Audio(AURA_ORION_EN),
  [AIModelSound.AURA_ARCAS_EN]: new Audio(AURA_ARCAS_EN),
  [AIModelSound.AURA_PERSEUS_EN]: new Audio(AURA_PERSEUS_EN),
  [AIModelSound.AURA_ANGUS_EN]: new Audio(AURA_ANGUS_EN),
  [AIModelSound.AURA_HELIOS_EN]: new Audio(AURA_HELIOS_EN),
  [AIModelSound.AURA_ORPHEUS_EN]: new Audio(AURA_ORPHEUS_EN),
  [AIModelSound.AURA_ZEUS_EN]: new Audio(AURA_ZEUS_EN),
};

export const QUESTIONNAIRE_PLACEHOLDER_TEXT = `Q: What services do you offer?
A: We offer haircuts, beard trims, hot towel shaves, and neck cleanups.

Q: I have a complaint
A: If you have a complaint, you can leave a message by saying "leave a message" and we will get back to you as soon as possible.

Q: Do you have any availability right now?
A: You can check for availability on our website.

Q: I want to cancel my appointment.
A: Please note our cancellation rescheduling policy: 24 hours for haircut services and 48 hours for hair coloring services. To cancel your appointment, use the link in your confirmation email or text message.
`;

export const BOOK_CALL_CALENDLY_URL =
  'https://calendly.com/d/cph3-yqw-sfb/invite-only-ai-front-desk-product-consultation';
export const MY_AI_FRONT_DESK_CONTACT_EMAIL = `contact@myaifrontdesk.com`;

export const MOBILE_WIDTH_THREASHOLD = 767;

// Regex Patterns
export const URL_REGEX = /^(http|https):\/\/[^ "]+$/;
export const MIN_10_WORDS_REGEX = /^(?:\b\w+\b[\s\S]*){10,}$/;

export const RESELLER_BOOK_CALL_CALENDLY_URL = 'https://calendly.com/myaifrontdesk/partner-consultation';

export const IntegrationOAuthProviders = {
  Hubspot: 'hubspot',
};

export const POSTHOG_FEATURE_FLAGS = {
  ONBOARDING_REGISTRATION_REVAMP: {
    key: 'registration-onboarding-revamp',
    variants: {
      NEW: 'new',
      OLD: 'old',
    },
  },
};

export const ONBOADING_STEPS_LENGTH = 8;

export const API_WORKFLOW_TYPES = {
  scenario: 1,
  knowledgeBaseLoader: 2,
};
