import { useEffect, useState } from 'react';
import { Card, Row, Col, Avatar, Typography, Tag, Timeline, Button, Space, Empty, Modal, theme, Spin } from 'antd';
import {
  UserOutlined,
  ExpandAltOutlined,
  MailOutlined,
  PlusOutlined,
  PhoneOutlined,
  MessageOutlined,
  HistoryOutlined,
} from '@ant-design/icons';
import { CallLog, CRMGroup, CRMLead, LastEvaluatedKey, TextLog } from '@/types/TCRM';
import NotesCard from './NotesInfo';
import { useInfiniteQuery, UseMutateFunction, useMutation, useQuery } from '@tanstack/react-query';
import { addANewNote, getCRMCallLogs, getCRMTextLogs } from '@/api/crm/leads';
import AddNote from './NoteForm';
import CallLogsView from './CallDetails';
import TextLogsView from './TextDetails';
import { IntakeForm } from '@/types/TCallLogs';

const { Text, Title } = Typography;

type LeadDetailsDashboardProps = {
  isNoviUser: boolean;
  clientID: string;
  lead: CRMLead;
  groups: CRMGroup[];
  refetchLead: UseMutateFunction<CRMLead, Error, string, unknown>;
};

interface ConvertedData {
  intakeResponses: IntakeForm[];
  transcript: string;
  date: string;
  from: string;
  responses: string[];
}

const LeadDetailsDashboard: React.FC<LeadDetailsDashboardProps> = ({
  isNoviUser,
  clientID,
  lead,
  groups,
  refetchLead,
}) => {
  const [expandedCard, setExpandedCard] = useState<string | null>(null);
  const [addNotesModal, setAddNotesModal] = useState<boolean>(false);
  const [callLogsData, setCallLogs] = useState<CallLog[]>([]);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState<LastEvaluatedKey | null>(null);
  const [intakeFormsData, setIntakeFormsData] = useState<any[]>([]);

  const { token } = theme.useToken();

  const cardStyle = {
    background: token.colorBgContainer,
    borderRadius: '8px',
    boxShadow: '0 1px 2px rgba(0, 0, 0, 0.03)',
    '.ant-card-head': {
      minHeight: '40px',
      padding: '8px 12px',
      fontSize: '14px',
    },
    '.ant-card-body': {
      padding: '12px',
    },
  };

  const renderExpandButton = (cardTitle: string) => (
    <Button type='text' size='small' icon={<ExpandAltOutlined />} onClick={() => setExpandedCard(cardTitle)} />
  );

  const renderAddNoteButton = () => (
    <Button type='text' size='small' icon={<PlusOutlined />} onClick={() => setAddNotesModal(true)}>
      Add
    </Button>
  );

  const addNoteMutation = useMutation({
    mutationFn: addANewNote,
    onSuccess: () => {
      setAddNotesModal(false);
      refetchLead(lead.lead_id);
    },
  });

  const { data, isFetching, fetchNextPage, hasNextPage } = useInfiniteQuery({
    queryKey: ['callLogs', lead.lead_id],
    queryFn: async () => {
      if (!lead.phone_number) return { callLogs: [], lastEvaluatedKey: null };

      const props: {
        clientID: string;
        limit: number;
        from?: string;
        lastKey?: LastEvaluatedKey;
        to?: string;
      } = {
        clientID,
        limit: 50,
      };

      if (isNoviUser) {
        props.to = lead.phone_number;
      } else {
        props.from = lead.phone_number;
      }

      if (lastEvaluatedKey) {
        props.lastKey = lastEvaluatedKey;
      }

      return await getCRMCallLogs(props);
    },
    getNextPageParam: lastPage => lastPage?.lastEvaluatedKey || null,
    initialPageParam: null,
    enabled: !!lead.phone_number,
  });

  const fetchMoreCallLogs = async () => {
    if (!lastEvaluatedKey) return;
    await fetchNextPage();
  };

  const { data: textLogs, isPending: isPendingTextLogs } = useQuery({
    queryKey: ['textLogs', lead.lead_id],
    queryFn: () => {
      if (!lead.phone_number) return null;

      return getCRMTextLogs({ clientID, to: lead.phone_number });
    },
  });

  const formatKey = (key: string): string => {
    return key
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };

  const isValidIntakeForms = (intakeForms: any): intakeForms is IntakeForm[] => {
    if (!Array.isArray(intakeForms)) {
      return false;
    }
    for (const form of intakeForms) {
      if (typeof form !== 'object' || Array.isArray(form) || form === null) {
        return false;
      }
      const keys = Object.keys(form);
      const values = Object.values(form);
      if (keys.length === 0 || values.length === 0) {
        return false;
      }
      for (const value of values) {
        if (typeof value !== 'string') {
          return false;
        }
      }
    }
    return true;
  };

  const doSomeShitToIntakeForms = (intakeForms: any): any[] => {
    const convertedData0 = intakeForms
      .map((el: any) => {
        if (!el.intakeResponses) return null;
        if (el.intakeResponses.length === 0) return null;
        let intakeResponses: IntakeForm[];
        try {
          intakeResponses = JSON.parse(el.intakeResponses) as IntakeForm[];
        } catch (err) {
          console.log(err);
          return null;
        }
        return {
          intakeResponses,
          transcript: el.transcript,
          date: el.date,
          from: el.from,
        };
      })
      .filter((el: any): el is ConvertedData => el !== null)
      .filter((el: any) => {
        const intakeDate = new Date(el.date);
        const cutoffDate = new Date('May 18, 2024');
        return intakeDate > cutoffDate;
      })
      .filter((el: any) => isValidIntakeForms(el.intakeResponses));
    const convertedDataMp: { [key: string]: ConvertedData[] } = {};
    convertedData0.forEach((el: any) => {
      if (el?.intakeResponses && Array.isArray(el.intakeResponses)) {
        el.intakeResponses.forEach((form: any) => {
          const key = Object.keys(form).join('~~~');
          const responses = Object.values(form);
          if (convertedDataMp[key]) {
            convertedDataMp[key].push({
              ...el,
              responses,
              intakeResponses: [form],
            });
          } else {
            convertedDataMp[key] = [
              {
                ...el,
                responses,
                intakeResponses: [form],
              },
            ];
          }
        });
      }
    });
    const convertedData1 = Object.entries(convertedDataMp).map(([key, value]) => ({
      questions: key,
      answers: value,
    }));
    return convertedData1;
  };

  useEffect(() => {
    if (data) {
      const callLogs = data?.pages.flatMap(page => page.callLogs) || [];
      const lastEvaluatedKey = data?.pages[data.pages.length - 1]?.lastEvaluatedKey || null;
      setCallLogs(callLogs);
      setLastEvaluatedKey(lastEvaluatedKey);

      const parsedIntakeForms = doSomeShitToIntakeForms(callLogs);

      const finalIntakeShit = parsedIntakeForms.flatMap(item =>
        item.answers.map((answer: any) => ({
          transcript: answer.transcript,
          date: answer.date,
          intakeResponses: answer.intakeResponses,
        }))
      );

      setIntakeFormsData(finalIntakeShit);
    }
  }, [data, lead.phone_number]);

  const renderModalContent = () => {
    switch (expandedCard) {
      case 'status':
        return (
          <Timeline
            items={lead.status_history.map(status => ({
              dot: <HistoryOutlined className='text-xs' />,
              children: (
                <div className='text-xs'>
                  <Text>
                    Lead moved to &nbsp;
                    <Text strong className='block'>
                      {groups.find(group => group.group_id === status.status)?.group_name ?? 'Deleted Group'}
                    </Text>
                  </Text>
                  <Text type='secondary' className='block mt-1'>
                    {new Date(status.createdAt).toLocaleString()}
                  </Text>
                </div>
              ),
            }))}
          />
        );
      case 'calls':
        if (callLogsData.length === 0) {
          return (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<Text className='text-xs'>No call logs</Text>} />
          );
        }

        return (
          <CallLogsView
            callLogs={callLogsData}
            loading={isFetching}
            onLoadMore={fetchMoreCallLogs}
            hasMore={hasNextPage}
          />
        );

      case 'texts':
        if (!textLogs)
          return (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<Text className='text-xs'>No text logs</Text>} />
          );
        return <TextLogsView textLogs={textLogs as TextLog[]} />;

      case 'notes':
        return (
          <Space direction='vertical' className='w-full'>
            <NotesCard leadID={lead.lead_id} notes={lead.notes} refetchLead={refetchLead} />
          </Space>
        );

      case 'info':
        return (
          <Space direction='vertical' className='w-full' size='middle'>
            <div className='flex justify-between items-center'>
              <Text>Lead Type</Text>
              <Tag color='blue'>{lead.lead_type}</Tag>
            </div>
            <div className='flex justify-between items-center'>
              <Text>Current Group</Text>
              <Tag color='green'>{groups.find(group => group.group_id === lead.group_id)?.group_name}</Tag>
            </div>
            {lead.dynamic_fields &&
              Object.keys(lead.dynamic_fields).map((key, index) => (
                <div className='flex justify-between items-center' key={index}>
                  <Text>{formatKey(key)}</Text>
                  <Text>{lead.dynamic_fields[key]}</Text>
                </div>
              ))}
            <div className='flex justify-between items-center'>
              <Text>Phone Number</Text>
              <Text>{lead.phone_number || 'N/A'}</Text>
            </div>
            {lead.email && (
              <div className='flex justify-between items-center'>
                <Text>Email</Text>
                <Text>{lead.email}</Text>
              </div>
            )}
            <div className='flex justify-between items-center'>
              <Text>Created At</Text>
              <Text>{new Date(lead.createdAt).toLocaleString()}</Text>
            </div>
            <div className='flex justify-between items-center'>
              <Text>Last Updated</Text>
              <Text>{new Date(lead.updatedAt).toLocaleString()}</Text>
            </div>
          </Space>
        );

      case 'intake-forms':
        if (intakeFormsData.length === 0) {
          return (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={<Text className='text-xs'>No intake forms</Text>}
            />
          );
        }

        return (
          <Timeline
            items={intakeFormsData.map(form => ({
              dot: <PhoneOutlined className='text-xs' />,
              children: (
                <div className='text-xs'>
                  <Text strong className='block'>
                    {new Date(form.date).toLocaleString()}
                  </Text>
                  <Text type='secondary' className='block'>
                    {form.intakeResponses.map((item: any) =>
                      Object.keys(item).map(key => (
                        <div key={key}>
                          <Text strong>{formatKey(key)} : </Text>
                          <Text>{item[key]}</Text>
                        </div>
                      ))
                    )}
                  </Text>
                </div>
              ),
            }))}
          />
        );

      default:
        return null;
    }
  };

  return (
    <div className='min-h-screen bg-gray-50 p-4'>
      <Card className='mb-4' bodyStyle={{ padding: '12px' }} style={cardStyle}>
        <Space className='w-full justify-between'>
          <Space size='middle'>
            <Avatar size={48} icon={<UserOutlined />} className='bg-blue-500' />
            <div>
              {lead.dynamic_fields?.name ? (
                <Title level={5} style={{ marginBottom: '4px', fontSize: '18px' }}>
                  {lead.dynamic_fields.name}
                </Title>
              ) : (
                <>
                  <Title level={4} style={{ marginBottom: '4px', fontSize: '16px' }}>
                    {lead.phone_number}
                  </Title>
                  {lead.email && (
                    <Text className='text-sm'>
                      <MailOutlined className='mr-1' /> {lead.email}
                    </Text>
                  )}
                </>
              )}
            </div>
          </Space>
          <Tag color='blue' className='text-xs px-2 py-1'>
            {lead.lead_type}
          </Tag>
        </Space>
      </Card>

      <Row gutter={[12, 12]}>
        <Col xs={24} md={8}>
          <Card
            title={<Text className='text-sm font-medium'>Lead Information</Text>}
            extra={renderExpandButton('info')}
            style={cardStyle}
            className='h-full'
          >
            <Space direction='vertical' className='w-full' size='small'>
              {lead.phone_number ? (
                <div className='flex justify-between items-center'>
                  <Text className='text-xs text-gray-500'>Phone Number</Text>
                  <Text className='text-xs'>{lead.phone_number}</Text>
                </div>
              ) : lead.email ? (
                <div className='flex justify-between items-center'>
                  <Text className='text-xs text-gray-500'>Email</Text>
                  <Text className='text-xs'>{lead.email}</Text>
                </div>
              ) : null}
              <div className='flex justify-between items-center'>
                <Text className='text-xs text-gray-500'>Current Group</Text>
                <Tag color='green' className='text-xs px-2 py-0'>
                  {groups.find(group => group.group_id === lead.group_id)?.group_name}
                </Tag>
              </div>
              <div className='flex justify-between items-center'>
                <Text className='text-xs text-gray-500'>Created</Text>
                <Text className='text-xs'>{new Date(lead.createdAt).toLocaleDateString()}</Text>
              </div>
              <div className='flex justify-between items-center'>
                <Text className='text-xs text-gray-500'>Updated</Text>
                <Text className='text-xs'>{new Date(lead.updatedAt).toLocaleDateString()}</Text>
              </div>
            </Space>
          </Card>
        </Col>

        <Col xs={24} md={8}>
          <Card
            title={<Text className='text-sm font-medium'>Call Logs</Text>}
            extra={renderExpandButton('calls')}
            style={cardStyle}
            className='h-full'
          >
            <Spin spinning={isFetching}>
              {callLogsData.length > 0 ? (
                <Timeline
                  items={callLogsData.slice(0, 3).map(call => ({
                    dot: <PhoneOutlined className='text-xs' />,
                    children: (
                      <div className='text-xs'>
                        <Text strong className='block'>
                          {call.duration} seconds &nbsp;
                          <Tag color='blue' className='text-xs px-2 py-0' style={{ fontSize: '10px' }}>
                            {call.isRecorded ? 'Recorded' : 'Not Recorded'}
                          </Tag>
                        </Text>
                        <Text type='secondary' className='block'>
                          {new Date(call.date).toLocaleString()}
                        </Text>
                      </div>
                    ),
                  }))}
                />
              ) : (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={<Text className='text-xs'>No call logs</Text>}
                />
              )}
            </Spin>
          </Card>
        </Col>

        <Col xs={24} md={8}>
          <Card
            title={<Text className='text-sm font-medium'>Text Logs</Text>}
            extra={renderExpandButton('texts')}
            style={cardStyle}
            className='h-full'
          >
            <Spin spinning={isPendingTextLogs}>
              {textLogs && textLogs?.length > 0 ? (
                <Timeline
                  items={textLogs.slice(0, 3).map(log => ({
                    dot: <MessageOutlined className='text-xs' />,
                    children: (
                      <div className='text-xs'>
                        <Text strong className='block'>
                          {log.text.substring(0, 50)}...
                        </Text>
                        <Text type='secondary'>{new Date(log.date).toLocaleString()}</Text>
                      </div>
                    ),
                  }))}
                />
              ) : (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={<Text className='text-xs'>No text logs</Text>}
                />
              )}
            </Spin>
          </Card>
        </Col>

        <Col xs={24} md={24}>
          <Card
            title={<Text className='text-sm font-medium'>Intake Forms</Text>}
            extra={renderExpandButton('intake-forms')}
            style={cardStyle}
            className='h-full'
          >
            <Spin spinning={isFetching}>
              {intakeFormsData.length > 0 ? (
                <Timeline
                  items={intakeFormsData.slice(0, 3).map(form => ({
                    dot: <PhoneOutlined className='text-xs' />,
                    children: (
                      <div className='text-xs'>
                        <Text strong className='block'>
                          {new Date(form.date).toLocaleString()}
                        </Text>
                        <Text type='secondary' className='block'>
                          {form.intakeResponses.map((item: any) =>
                            Object.keys(item).map(key => (
                              <div key={key}>
                                <Text strong>{formatKey(key)} : </Text>
                                <Text>{item[key]}</Text>
                              </div>
                            ))
                          )}
                        </Text>
                      </div>
                    ),
                  }))}
                />
              ) : (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={<Text className='text-xs'>No intake forms</Text>}
                />
              )}
            </Spin>
          </Card>
        </Col>

        <Col xs={24} md={8}>
          <Card
            title={<Text className='text-sm font-medium'>Status History</Text>}
            extra={renderExpandButton('status')}
            style={cardStyle}
            className='h-full'
          >
            <Timeline
              items={lead.status_history.slice(-3).map(status => ({
                dot: <HistoryOutlined className='text-xs' />,
                children: (
                  <div className='text-xs'>
                    <Text>
                      Lead moved to &nbsp;
                      <Text strong className='block'>
                        {groups.find(group => group.group_id === status.status)?.group_name ?? 'Deleted Group'}
                      </Text>
                    </Text>
                    <Text type='secondary' className='block mt-1'>
                      {new Date(status.createdAt).toLocaleString()}
                    </Text>
                  </div>
                ),
              }))}
            />
          </Card>
        </Col>

        <Col xs={24} md={16}>
          <Card
            title={<Text className='text-sm font-medium'>Notes</Text>}
            extra={
              <Space>
                {renderAddNoteButton()}
                {renderExpandButton('notes')}
              </Space>
            }
            style={cardStyle}
            className='h-full'
          >
            {lead.notes?.length > 0 ? (
              <Space direction='vertical' className='w-full' size='small'>
                {lead.notes.slice(-3).map((note, index) => (
                  <Card key={index} size='small' style={{ ...cardStyle, background: token.colorBgContainerDisabled }}>
                    <Text className='text-xs'>{note.content}</Text>
                    <div className='mt-1'>
                      <Text className='text-xs' type='secondary'>
                        - Added on {new Date(note.createdAt).toLocaleString()}
                      </Text>
                    </div>
                  </Card>
                ))}
              </Space>
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<Text className='text-xs'>No notes</Text>} />
            )}
          </Card>
        </Col>
      </Row>

      <Modal
        title={
          <Text className='text-sm font-medium'>
            {expandedCard === 'status'
              ? 'Status History'
              : expandedCard === 'notes'
                ? 'Notes'
                : expandedCard === 'calls'
                  ? 'Call Logs'
                  : expandedCard === 'texts'
                    ? 'Text Logs'
                    : expandedCard === 'info'
                      ? 'Lead Information'
                      : expandedCard === 'intake-forms'
                        ? 'Intake Forms'
                        : ''}
          </Text>
        }
        open={!!expandedCard}
        onCancel={() => setExpandedCard(null)}
        width={900}
        footer={null}
        centered
      >
        {renderModalContent()}
      </Modal>

      <Modal
        title={<Text className='text-sm font-medium'>Add Note</Text>}
        open={addNotesModal}
        onCancel={() => setAddNotesModal(false)}
        footer={null}
        width={900}
      >
        <AddNote
          onSubmit={note => {
            addNoteMutation.mutate({
              clientID,
              leadID: lead.lead_id,
              note: note.content,
            });
          }}
          isLoading={addNoteMutation.isPending}
        />
      </Modal>
    </div>
  );
};

export default LeadDetailsDashboard;
