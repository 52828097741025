export enum LocalStorageKeys {
  AUTH_TOKEN = 'AUTH_TOKEN',
  RESELLER_TOUR = 'RESELLER_TOUR',
  NOVI_AUTH_TOKEN = 'NOVI_AUTH_TOKEN',
  NOVI_USER = 'NOVI_USER',
  RESELLER_AUTH_TOKEN = 'RESELLER_AUTH_TOKEN',
  NOVI_RECEPTIONIST_AUTH_TOKEN = 'NOVI_RECEPTIONIST_AUTH_TOKEN',
  FAVICON_URL = 'FAVICON_URL',
  DATE_FORMAT = 'DATE_FORMAT',
}
