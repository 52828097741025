import React from 'react';
import Fade from 'react-reveal/Fade';
import classes from '../RegisterPageV3.module.css';
import posthog from 'posthog-js';
import { Button, notification } from 'antd';

const Step8 = ({
  emailAddress,
  setEmailAddress,
  businessName,
  handleStepTransition,
  step0Validation,
  step0StateMutation,
  stepLoading,
}) => {
  return (
    <>
      <div className={classes.box}>
        {React.createElement(() => {
          posthog.capture('email_address_entered');
          return null;
        })}
        <Fade duration={1000} bottom>
          <h3
            className={classes.heading}
            style={{
              textAlign: 'center',
            }}
          >
            Email Address
          </h3>
          <div
            className={classes.subtitle}
            style={{
              textAlign: 'center',
            }}
          >
            {' '}
            Your email address. We will never share this with anyone, or send you spam.
          </div>

          <input
            type='email'
            value={emailAddress}
            className={classes.input + ' ' + classes.centered}
            required
            onChange={event => {
              let value = event.target.value;
              setEmailAddress(value);
            }}
            placeholder={`e.g. john@${businessName.toLocaleLowerCase().replace(' ', '')}.com`}
          />

          <Button
            type='primary'
            onClick={() => handleStepTransition(1, step0Validation, step0StateMutation)}
            className={classes.button + ' ' + classes.centered}
            style={{ marginTop: '2rem' }}
            loading={stepLoading}
          >
            Continue
          </Button>
        </Fade>
      </div>
    </>
  );
};

export default Step8;
