import React, { useState, useEffect } from 'react';
import { Card, Typography, Button, Tag, Space, Modal, Progress } from 'antd';
import {
  RobotOutlined,
  BarChartOutlined,
  CheckCircleOutlined,
  PhoneOutlined,
  UserOutlined,
  ArrowRightOutlined,
  MessageOutlined,
  ClockCircleOutlined,
} from '@ant-design/icons';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateCampaignOnboarding } from '@/api/novi/campaigns';
import { ENoviDashboardSider } from '@/pages/Novi/enums/ENoviDashboardSider';
import { setNoviCurrentPage } from '@/store/novi/user/slice';
import { useAppDispatch } from '@/store/hooks/useAppDispatch';
import { FloatingProgress } from './FloatingProgress';
import { Dialpad } from '@mui/icons-material';

const { Title, Paragraph } = Typography;

interface StepItem {
  id: number;
  title: string;
  description: string;
  icon: React.ReactNode;
  path: string;
  additionalContent?: React.ReactNode;
}

interface CampaignSetupContentProps {
  doneSteps: Array<number>;
  totalSteps: number;
  isPageView: boolean;
  resellerView?: boolean;
  standAloneCampaignView?: boolean;
}

export const CampaignSetupContent: React.FC<CampaignSetupContentProps> = ({
  doneSteps,
  totalSteps,
  isPageView,
  resellerView,
  standAloneCampaignView,
}) => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const [stepsDone, setStepsDone] = useState<Array<number>>(doneSteps);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [updatingForAction, setUpdatingForAction] = useState<'SKIP' | 'START' | 'ALL_DONE' | null>(null);

  // Your existing steps configuration
  const steps: StepItem[] = standAloneCampaignView
    ? [
        {
          id: 0,
          title: 'Reserve a Number',
          description: 'Select and reserve a number from available countries to work with the AI.',
          icon: <Dialpad className='text-2xl text-blue-500' />,
          path: ENoviDashboardSider.RESERVE_NUMBER,
        },
        {
          id: 1,
          title: 'Configure Your Outbound AI Agent',
          description: "Set up your AI's personality, knowledge base, and response handling",
          icon: <RobotOutlined className='text-2xl text-blue-500' />,
          path: ENoviDashboardSider.CONFIGURE,
        },
        {
          id: 2,
          title: 'Review CRM Groups',
          description:
            "We've created default lead groups for you (Interested, Not Interested, Meeting Scheduled). Review and customize if needed.",
          icon: <BarChartOutlined className='text-2xl text-blue-500' />,
          path: ENoviDashboardSider.CRM,
          additionalContent: (
            <Space className='mt-4 flex flex-wrap'>
              <Tag>Interested</Tag>
              <Tag>Not Interested</Tag>
              <Tag>Meeting Scheduled</Tag>
            </Space>
          ),
        },
        {
          id: 3,
          title: 'Configure Text Templates',
          description:
            'Create and customize your message templates with variables for personalized, automated text outreach.',
          icon: <MessageOutlined className='text-2xl text-blue-500' />,
          path: ENoviDashboardSider.TEXT_CONFIGURATION,
        },
        {
          id: 4,
          title: 'Configure AI Behavior',
          description:
            "Customize your AI agent's responses, knowledge base, and conversation handling for effective outbound calls.",
          icon: <PhoneOutlined className='text-2xl text-blue-500' />,
          path: ENoviDashboardSider.CONFIGURE,
        },
        {
          id: 5,
          title: 'Add Leads',
          description: 'Import your contact list to begin outreach',
          icon: <UserOutlined className='text-2xl text-blue-500' />,
          path: ENoviDashboardSider.LEADS,
        },
        {
          id: 6,
          title: 'Set Campaign Schedule',
          description:
            "Define your campaign's operation hours, timezone settings, and retry attempts for both calls and texts.",
          icon: <ClockCircleOutlined className='text-2xl text-blue-500' />,
          path: ENoviDashboardSider.SCHEDULE,
        },
      ]
    : [
        {
          id: 0,
          title: 'Configure Your Outbound AI Agent',
          description: "Set up your AI's personality, knowledge base, and response handling",
          icon: <RobotOutlined className='text-2xl text-blue-500' />,
          path: ENoviDashboardSider.CONFIGURE,
        },
        {
          id: 1,
          title: 'Review CRM Groups',
          description:
            "We've created default lead groups for you (Interested, Not Interested, Meeting Scheduled). Review and customize if needed.",
          icon: <BarChartOutlined className='text-2xl text-blue-500' />,
          path: ENoviDashboardSider.CRM,
          additionalContent: (
            <Space className='mt-4 flex flex-wrap'>
              <Tag>Interested</Tag>
              <Tag>Not Interested</Tag>
              <Tag>Meeting Scheduled</Tag>
            </Space>
          ),
        },
        {
          id: 2,
          title: 'Configure Text Templates',
          description:
            'Create and customize your message templates with variables for personalized, automated text outreach.',
          icon: <MessageOutlined className='text-2xl text-blue-500' />,
          path: ENoviDashboardSider.TEXT_CONFIGURATION,
        },
        {
          id: 3,
          title: 'Configure AI Behavior',
          description:
            "Customize your AI agent's responses, knowledge base, and conversation handling for effective outbound calls.",
          icon: <PhoneOutlined className='text-2xl text-blue-500' />,
          path: ENoviDashboardSider.CONFIGURE,
        },
        {
          id: 4,
          title: 'Add Leads',
          description: 'Import your contact list to begin outreach',
          icon: <UserOutlined className='text-2xl text-blue-500' />,
          path: ENoviDashboardSider.LEADS,
        },
        {
          id: 5,
          title: 'Set Campaign Schedule',
          description:
            "Define your campaign's operation hours, timezone settings, and retry attempts for both calls and texts.",
          icon: <ClockCircleOutlined className='text-2xl text-blue-500' />,
          path: ENoviDashboardSider.SCHEDULE,
        },
      ];

  const updateOnboardingMutation = useMutation({
    mutationKey: ['campaign-onboarding'],
    mutationFn: updateCampaignOnboarding,
    onSuccess: data => {
      if (data && data.success && data.steps) {
        let doneSteps = data.steps;
        if (doneSteps.length < totalSteps) {
          setCurrentStep(doneSteps[doneSteps.length - 1] + 1);
        } else {
          queryClient.invalidateQueries({ queryKey: ['campaign-onboarding'] });
        }
        setStepsDone(doneSteps);
      }
      setUpdatingForAction(null);
    },
    onError: () => {
      setUpdatingForAction(null);
    },
  });

  useEffect(() => {
    if (doneSteps && doneSteps.length > 0) {
      let lastStep = doneSteps[doneSteps.length - 1];
      setStepsDone(doneSteps);
      if (lastStep < totalSteps) {
        setCurrentStep(lastStep + 1);
      }
    }
  }, [doneSteps]);

  // Your existing handlers
  const handleStepStart = async (step: StepItem) => {
    setUpdatingForAction('START');
    if (isPageView) {
      await updateOnboardingMutation.mutateAsync({ step: step.id });
    } else {
      updateOnboardingMutation.mutate({ step: step.id });
    }
    dispatch(setNoviCurrentPage(step.path as ENoviDashboardSider));
    handleModalClose();
  };

  const handleStepSkip = (step: StepItem) => {
    setUpdatingForAction('SKIP');
    const newStepsDone = [...stepsDone, step.id];
    setStepsDone(newStepsDone);
    updateOnboardingMutation.mutate({ step: step.id });
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  const handleModalOpen = () => {
    setIsModalVisible(true);
  };

  const handleMarkAllDone = () => {
    setUpdatingForAction('ALL_DONE');
    const stepsArray = Array.from({ length: totalSteps }, (_, index) => index);
    updateOnboardingMutation.mutate({ steps: stepsArray });
    if (currentStep < steps.length - 1) {
      setCurrentStep(totalSteps);
    }
  };

  const progress = Math.round((stepsDone.length / totalSteps) * 100);

  const content = (
    <div className='p-4 md:p-6'>
      <div className='mb-8'>
        <Title level={2} className='text-xl md:text-2xl'>
          Get Started with Outbound AI
        </Title>
        <Paragraph className='text-gray-600'>
          Follow these steps to set up your outbound campaign. We've pre-configured some settings to help you get
          started faster.
        </Paragraph>
      </div>

      <div className='md:hidden mb-6'>
        <Progress
          percent={((currentStep + 1) / steps.length) * 100}
          format={() => `Step ${currentStep + 1} of ${steps.length}`}
        />
      </div>

      <div className='space-y-6'>
        {steps.map(step => (
          <Card
            key={step.id}
            className={`transition-all duration-200 hover:shadow-md ${
              currentStep === step.id ? 'border-blue-400' : ''
            }`}
          >
            <div className='flex flex-col md:flex-row md:items-start gap-4'>
              <div className='flex-1'>
                <div className='flex items-center gap-3 mb-2'>
                  {step.icon}
                  <Title level={5} className='mb-0 text-base md:text-lg'>
                    {step.title}
                    {currentStep === step.id && (
                      <Tag color='blue' className='ml-2'>
                        Current Step
                      </Tag>
                    )}
                    {stepsDone.includes(step.id) && (
                      <Tag color='green' className='ml-2'>
                        Completed
                      </Tag>
                    )}
                  </Title>
                </div>
                <Paragraph className='text-gray-600 mb-4'>{step.description}</Paragraph>
                {step.additionalContent}
              </div>
              {currentStep === step.id && !stepsDone.includes(step.id) && (
                <div className='flex flex-col gap-2 w-full md:w-auto'>
                  <Button
                    type='primary'
                    size='large'
                    icon={<ArrowRightOutlined />}
                    onClick={() => handleStepStart(step)}
                    className='w-full md:w-auto'
                    loading={updateOnboardingMutation.isPending && updatingForAction === 'START'}
                    disabled={updateOnboardingMutation.isPending}
                  >
                    Start Step
                  </Button>
                  <Button
                    type='default'
                    size='large'
                    onClick={() => handleStepSkip(step)}
                    className='w-full md:w-auto'
                    loading={updateOnboardingMutation.isPending && updatingForAction === 'SKIP'}
                    disabled={updateOnboardingMutation.isPending}
                  >
                    Skip Step
                  </Button>
                </div>
              )}
            </div>
          </Card>
        ))}

        <div className='flex justify-between items-center pt-4 border-t'>
          {!isPageView && (
            <Button onClick={handleModalClose} size='large'>
              {stepsDone.length === totalSteps ? 'Close Now' : 'Continue Later'}
            </Button>
          )}
          <Button
            size='large'
            type='primary'
            onClick={handleMarkAllDone}
            loading={updateOnboardingMutation.isPending && updatingForAction === 'ALL_DONE'}
            disabled={updateOnboardingMutation.isPending || stepsDone.length === totalSteps}
          >
            <CheckCircleOutlined />
            Mark all as done
          </Button>
        </div>
      </div>
    </div>
  );

  return (
    <>
      {!isPageView && (
        <FloatingProgress resellerView={resellerView} progress={progress} onOpenModal={handleModalOpen} />
      )}

      {isPageView ? (
        content
      ) : (
        <Modal
          title='Outbound AI Setup'
          open={isModalVisible}
          onCancel={handleModalClose}
          footer={null}
          width='95%'
          style={{ maxWidth: '800px' }}
          centered
          className='onboarding-modal'
        >
          {content}
        </Modal>
      )}
    </>
  );
};
