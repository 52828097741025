import moment from 'moment';
import momentTimeZone from 'moment-timezone';
import {
  Button,
  Checkbox,
  DatePicker,
  Dropdown,
  Modal,
  notification,
  Select,
  Space,
  Spin,
  Table,
  TableProps,
  Tag,
  Tooltip,
  Alert,
  Empty,
  Typography,
} from 'antd';
import PlayForWorkOutlinedIcon from '@mui/icons-material/PlayForWorkOutlined';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import './CallLogs.scss';
import { CustomModal } from '@/components/CustomModal';
import { CallDetails } from './CallDetails';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  deleteCallLog,
  downloadCallLogs,
  getCallLogs,
  getCallRecording,
  updateUserTimeZone,
  getCallLogByCallSid,
  updateCallLog,
  getVoiceMailLogs,
} from '@/api/logs';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { getLoggedInUser } from '@/store/account/selector';
import { useDispatch } from 'react-redux';
import { setCurrentPage, setUserTimeZone } from '@/store/account/slice';
import {
  DeleteOutlined,
  MoreOutlined,
  PlayCircleOutlined,
  RollbackOutlined,
  ShareAltOutlined,
  UserAddOutlined,
} from '@ant-design/icons';
import { CallLog, VoiceMailLog } from '@/interfaces/ILogs';
import { renderPSTDate } from '@/utils/helper';
import { CallIntakeResponse } from './CallIntakeResponse';
import { IntakeForm } from '@/types/TCallLogs';
import { getProvisionedNumber } from '@/api/reservedNumbers';
import { useLocalTimezone } from 'src/common/hooks/useLocalTimezone';
import EllipsisText from '@/components/LabelWithdescription/EllipsisText';
import AlgoliaSearch from '@/components/AlgoliaSearch/AlgoliaSearch';
import CopyableText from '@/components/LabelWithdescription/CopyableText';
import { smallScreen } from 'src/enums/EScreenSize';
import '../../../../components/CustomModal/CustomModal.scss';
import { CheckOutlined, Voicemail } from '@mui/icons-material';
import { TableRowSelection } from 'antd/es/table/interface';
import { openModal } from '@/store/modal/slice';
import { MODAL_IDS } from 'src/enums/EModal';
import ShareCallLogModal from './Modals/ShareCallLogModal';
import CRMLeadCreator, { CRMLeadCreatorRef } from '../../CrmDashboard/Components/crmLeadCreator';
import { getCrmGroups } from '@/api/crm/groups';
import { TClient } from '@/types/TClient';
import { getSelectedCampaign, getSelectedCampaignId } from '@/store/novi/user/selector';
import { CRMGroup, CRMLead } from '@/types/TCRM';
import { TCallTransferWorkflow, TTextingWorkflow } from '@/types/TWorkflows';
import { setNoviCRMGroups } from '@/store/novi/user/slice';
import { createCRMLead } from '@/api/crm/leads';
import { AlertCircle, ArrowRight, Settings2 } from 'lucide-react';
import { ReceptionistDashboardSiderItem } from 'src/enums/EReceptionistDashboardSiderItem';
import PriceDetailModal from './Modals/PriceDetailModal';

const { Text, Title } = Typography;

const timeZoneList = momentTimeZone.tz.names();

const CallLogs: React.FC<{ debrand: boolean; isNoviUser: boolean; campaignId?: string }> = ({
  debrand,
  isNoviUser,
}) => {
  const dispatch = useDispatch();
  const loggedInUser = useAppSelector(getLoggedInUser);
  const { RangePicker } = DatePicker;
  const [callLogs, setCallLogs] = useState<CallLog[]>([]);
  const [showFiltered, setShowFiltered] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [callLog, setCallLog] = useState<CallLog | null>(null);
  const [startDate, setStartDate] = useState<number | null>(null);
  const [endDate, setEndDate] = useState<number | null>(null);
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
  const [selectedLog, setSelectedLog] = useState<{ key: string; callSid: string; date: string } | null>(null);
  const [saveContactModal, setSaveContactModal] = useState<boolean>(false);
  const [selectedContact, setSelectedContact] = useState<string | null>(null);
  const { timeZone, setTimeZone } = useLocalTimezone(loggedInUser?.time_zone);
  const [intakeResponses, setIntakeResponses] = useState<IntakeForm[]>([]);
  const [intakeModal, setIntakeModal] = useState<boolean>(false);
  const [loadingRecordings, setLoadingRecordings] = useState<{ [key: string]: boolean }>({});
  const [isSearchedLog, setIsSearchedLog] = useState(false);
  const [hoveredButton, setHoveredButton] = useState<string | null>(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  // Checkbox for row selection
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection: TableRowSelection<CallLog> = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: record => ({
      disabled: record.isRead, // Disable selection for already read items
    }),
  };

  const hasSelected = selectedRowKeys.length > 0;

  const hideEmptyCalls = (e: any) => {
    setShowFiltered(e.target.checked);
  };

  const { data, refetch, isPending, isRefetching } = useQuery({
    queryKey: ['getCallLogs'],
    queryFn: () => {
      // checking if isSearchedLog is true, then make it false
      if (isSearchedLog) {
        setIsSearchedLog(false);
        setCallLogs([]);
      }

      if (startDate && endDate) {
        return getCallLogs({
          limit: 50,
          startDate,
          endDate,
          from: isNoviUser ? provisionedNumbers?.provisioned_number : null,
        });
      } else {
        return getCallLogs({ limit: 50, from: isNoviUser ? provisionedNumbers?.provisioned_number : null });
      }
    },
  });

  const { data: voicemails } = useQuery({
    queryKey: ['getVoiceMailLogs'],
    queryFn: getVoiceMailLogs,
  });

  const { data: provisionedNumbers, isLoading: isLoadingProvisionedNumber } = useQuery({
    queryKey: ['getProvisionedNumbers'],
    queryFn: () => getProvisionedNumber(),
  });

  const callRecordingMutation = useMutation<
    { callSid: string; url: string }, // Success response type
    Error, // Error type
    string // Parameter type (callSid)
  >({
    mutationFn: getCallRecording,
    onMutate: callSid => {
      setLoadingRecordings(prev => ({ ...prev, [callSid]: true }));
    },
    onSuccess: data => {
      const updatedCallLogs = callLogs.map(log => (log.callSid === data.callSid ? { ...log, audio: data.url } : log));
      setCallLogs(updatedCallLogs);
      setLoadingRecordings(prev => ({ ...prev, [data.callSid]: false }));
    },
    onError: () => {
      notification.error({ message: 'Failed to load recording' });
      setLoadingRecordings(prev => ({ ...prev }));
    },
  });

  const { mutate: deleteCallLogMutation, status: deleteCallLogStatus } = useMutation({
    mutationKey: ['deleteCallLog'],
    mutationFn: deleteCallLog,
    onSuccess() {
      const updatedCallLogs = callLogs.filter((log: CallLog) => log.key !== selectedLog?.key);
      setConfirmDelete(false);
      setSelectedLog(null);
      setCallLogs(updatedCallLogs);
      notification.success({ message: 'Call log deleted successfully' });
    },
  });

  const { mutate: updateCallLogMutation } = useMutation({
    mutationKey: ['updateCallLog'],
    mutationFn: updateCallLog,
    onSuccess({ success, data }) {
      if (success && data) {
        const updatedLogs = callLogs.map((log: CallLog) => {
          if (log.callSid === data.callSid) {
            return data;
          } else {
            return log;
          }
        });
        setCallLog(data);
        setCallLogs(updatedLogs);
        notification.success({ message: 'Thanks for rating!' });
      }
    },
  });

  const { mutate: updateCallLogReadMutation } = useMutation({
    mutationKey: ['updateCallLog'],
    mutationFn: updateCallLog,
    onSuccess({ success, data }) {
      if (success && data) {
        const updatedLogs = callLogs.map((log: CallLog) => {
          if (log.callSid === data.callSid) {
            return data;
          } else {
            return log;
          }
        });
        setCallLog(data);
        const updatedCallLogs = addVoicemailField(updatedLogs || [], voicemails?.voiceMailLogs || []);
        setCallLogs(updatedCallLogs);
      }
    },
  });

  const updateTimeZoneMutation = useMutation({
    mutationKey: ['updateTimeZone'],
    mutationFn: updateUserTimeZone,
    onSuccess(_, values) {
      dispatch(setUserTimeZone({ timeZone: values.timeZone }));
    },
  });

  const { mutate: downloadCallLogsMutation, status: downloadCallLogsStatus } = useMutation({
    mutationKey: ['downloadCallLogs'],
    mutationFn: downloadCallLogs,
  });

  const { mutate: getCallLogByCallSidMutation, isPending: callLogbySidPending } = useMutation({
    mutationKey: ['getCallLogByCallSid'],
    mutationFn: getCallLogByCallSid,
    onSuccess: data => {
      if (data.success && data.data) {
        setIsSearchedLog(true);
        setCallLogs([data.data]);
      }
    },
    onError: () => {
      notification.error({ message: 'Failed to fetch call log' });
    },
  });

  const handleLogSelect = useCallback(
    async (callSid: string) => {
      getCallLogByCallSidMutation(callSid);
    },
    [loggedInUser.number]
  );

  const [groups, setGroups] = useState<CRMGroup[]>([]);
  let clientID: string | null;
  let clientData: TClient | null;

  if (!isNoviUser) {
    const loggedInUser = useAppSelector(getLoggedInUser);
    clientID = loggedInUser?.number;
    clientData = loggedInUser as unknown as TClient;
  } else {
    clientID = useAppSelector(getSelectedCampaignId) as string;
    clientData = useAppSelector(getSelectedCampaign);
  }

  const { data: crmGroupsData } = useQuery({
    queryKey: ['getGroups'],
    queryFn: () => {
      if (!clientID) return;

      return getCrmGroups(isNoviUser, clientID as string);
    },
  });

  const [textingWorkflow, setTextingWorkflow] = useState<TTextingWorkflow[]>([]);
  const [callTransferWorkflow, setCallTransferWorkflow] = useState<TCallTransferWorkflow[]>([]);
  const leadFormRef = useRef<CRMLeadCreatorRef>(null);
  useEffect(() => {
    try {
      const parsedTextingWorkflow = clientData?.workflows ? JSON.parse(clientData.workflows) : [];
      const parsedCallWorkflow = clientData?.callWorkflows ? JSON.parse(clientData.callWorkflows) : [];
      setTextingWorkflow(parsedTextingWorkflow);
      setCallTransferWorkflow(parsedCallWorkflow);
    } catch (error) {
      notification.error({
        message: 'Workflow Error',
        description: 'There was an error parsing the workflow data. Some groups may be unavailable.',
      });
    }
  }, [clientData, clientData?.workflows, clientData?.callWorkflows]);

  const isWorkflowValid = (group: CRMGroup) => {
    if (group.trigger_type !== 'workflow') return true;

    const workflowId = group.trigger_details?.workflow?.workflow_id;
    if (!workflowId) return false;

    return textingWorkflow.some(w => w.id === workflowId) || callTransferWorkflow.some(w => w.id === workflowId);
  };

  useEffect(() => {
    if (crmGroupsData?.crmGroups) {
      const processedGroups = crmGroupsData.crmGroups.map(group => ({
        ...group,
        isDisabled: !isWorkflowValid(group),
      }));

      setGroups(processedGroups);

      if (isNoviUser) {
        const validGroups = processedGroups.filter(group => !group.isDisabled);
        dispatch(setNoviCRMGroups(validGroups));
      }

      const invalidGroups = processedGroups.filter(group => group.isDisabled);
      if (invalidGroups.length > 0) {
        console.warn('Found groups with invalid workflows:', invalidGroups);
      }
    }
  }, [data, isNoviUser]);

  const validGroups = useMemo(() => {
    return groups.filter(group => !group.isDisabled);
  }, [groups]);

  const handleCreateLead = (values: Partial<CRMLead>) => {
    createLeadMutation.mutate({
      clientID: clientID as string,
      leadData: values,
    });
  };

  const createLeadMutation = useMutation({
    mutationFn: createCRMLead,
    onSuccess: () => {
      notification.success({
        message: 'Lead Added',
        description: 'The lead has been successfully added to the group.',
      });
      refetch();
      setSaveContactModal(false);
      setSelectedContact(null);
      leadFormRef.current?.resetForm();
    },
    onError: error => {
      notification.error({
        message: 'Error',
        description: error.message,
      });
    },
  });

  const onNavigateToCRM = () => {
    dispatch(setCurrentPage(ReceptionistDashboardSiderItem.CRM));
  };

  // Add a reset function to restore normal view
  const handleResetSearch = useCallback(() => {
    setIsSearchedLog(false);
    refetch(); // This will reload the original call logs
  }, [refetch]);

  const handleClick = (callLog: CallLog) => {
    setShowModal(true);
    setCallLog(callLog);
  };

  const handleConfirm = () => {
    setShowModal(false);
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  const handleConfirmDeleteModal = (key: string, callSid: string, date: string) => {
    setSelectedLog({ key, callSid, date });
    setConfirmDelete(true);
  };

  const handleCancelDeleteModal = () => {
    setSelectedLog(null);
    setConfirmDelete(false);
  };

  const handleTimeZoneChange = (value: string) => {
    setTimeZone(value);
    updateTimeZoneMutation.mutate({ timeZone: value });
  };

  const handleDateRangeChange = (_: any, dateStrings: [string, string]) => {
    const [start, end] = dateStrings;
    setStartDate(start ? moment(start).valueOf() : null);
    setEndDate(end ? moment(end).valueOf() : null);
  };

  const handleGetCallRecording = (callSid: string) => {
    callRecordingMutation.mutate(callSid);
  };

  const handleDeleteCallLog = () => {
    if (selectedLog !== null) {
      deleteCallLogMutation(selectedLog);
    }
  };

  const handleSaveContact = (from: string) => {
    setSaveContactModal(true);
    setSelectedContact(from);
  };

  const handleCancelSaveContact = () => {
    setSaveContactModal(false);
    setSelectedContact(null);
    leadFormRef.current?.resetForm();
  };

  const handleCallLogsDownload = () => {
    if (startDate && endDate) {
      downloadCallLogsMutation({ startDate, endDate, isNoviUser });
    } else {
      downloadCallLogsMutation({ isNoviUser });
    }
  };

  const parseIntakeResponse = (intakeResponse: string) => {
    try {
      if (!intakeResponse) {
        return [];
      }
      const parsedResponse = JSON.parse(intakeResponse);
      if (Array.isArray(parsedResponse)) {
        const transformedResponse = parsedResponse
          .map(obj => {
            return Object.entries(obj)
              .map(([key, value]) => [
                { key: 'Question', value: key },
                { key: 'Answer', value: value },
              ])
              .flat();
          })
          .flat() as IntakeForm[];
        setIntakeResponses(transformedResponse);
        setIntakeModal(true);
      } else if (typeof parsedResponse === 'object' && parsedResponse !== null) {
        const transformedResponse = Object.entries(parsedResponse)
          .map(([key, value]) => [
            { key: 'Question', value: key },
            { key: 'Answer', value: value },
          ])
          .flat() as IntakeForm[];

        setIntakeResponses(transformedResponse);
        setIntakeModal(true);
      }
    } catch (error) {
      notification.error({ message: 'Error parsing intake response' });
      return [];
    }
  };

  const handleCancelIntakeModal = () => {
    setIntakeModal(false);
  };

  const handleMarkAsRead = () => {
    if (selectedRowKeys.length > 0) {
      selectedRowKeys.forEach(callSid => {
        const logToUpdate = callLogs.find(log => log.key === callSid);
        if (logToUpdate) {
          updateCallLogReadMutation({
            callSid: logToUpdate.callSid,
            date: logToUpdate.date || '', // Ensuring date comes from the correct log
            data: { isRead: true },
          });
        }
      });
      setSelectedRowKeys([]); // Clear selection after marking as read
    } else {
      notification.info({ message: 'Please select at least one row to mark as read.' });
    }
  };

  const handleRowInteraction = (record: CallLog) => {
    if (!record.isRead) {
      updateCallLogReadMutation({
        callSid: record.callSid,
        date: record.date || '',
        data: { isRead: true },
      });

      setCallLogs(prevLogs => prevLogs.map(log => (log.callSid === record.callSid ? { ...log, isRead: true } : log)));
    }
  };

  const ToORFROM = isNoviUser
    ? {
        title: 'To',
        dataIndex: 'to',
        key: 'to',
        render: (to: string, record: CallLog) => {
          const handleTagClick = () => {
            dispatch(setCurrentPage(ReceptionistDashboardSiderItem.CRM));
          };

          return (
            <div>
              {record?.isCRMLead && (
                <Tag color='blue' onClick={handleTagClick} style={{ cursor: 'pointer' }}>
                  CRM Lead
                </Tag>
              )}
              <CopyableText displayText={record?.name || to} text={to} />
              {!record?.isCRMLead && (
                <Tooltip title='Save Contact'>
                  <Button type='text' icon={<UserAddOutlined />} onClick={() => handleSaveContact(to)} size='small' />
                </Tooltip>
              )}
            </div>
          );
        },
      }
    : {
        title: 'From',
        dataIndex: 'from',
        key: 'from',
        render: (from: string, record: CallLog) => {
          const handleTagClick = () => {
            dispatch(setCurrentPage(ReceptionistDashboardSiderItem.CRM));
          };

          return (
            <div>
              {record.isCRMLead && (
                <Tag color='blue' onClick={handleTagClick} style={{ cursor: 'pointer' }}>
                  CRM Lead
                </Tag>
              )}
              <CopyableText displayText={record?.name || from} text={from} />
              {!record.isCRMLead && (
                <Tooltip title='Save Contact'>
                  <Button type='text' icon={<UserAddOutlined />} onClick={() => handleSaveContact(from)} size='small' />
                </Tooltip>
              )}
            </div>
          );
        },
      };

  const columns: TableProps<CallLog>['columns'] = [
    {
      key: 'Date',
      title: 'Dates',
      render: (_, record) => <CopyableText text={renderPSTDate(record.date, timeZone)} />,
    },
    ToORFROM,
    {
      title: 'Recording',
      key: 'recording',
      render: (_, record) =>
        record.isRecorded === 'true' ? (
          record.audio ? (
            <audio controls className='h-[30px]'>
              <source src={record.audio} type='audio/mpeg' />
            </audio>
          ) : (
            <Button
              icon={<PlayCircleOutlined />}
              onClick={() => handleGetCallRecording(record.callSid)}
              loading={loadingRecordings[record.callSid]}
            >
              Listen
            </Button>
          )
        ) : (
          <Tag color='red'>No Recording</Tag>
        ),
    },
    {
      title: 'Voicemail',
      dataIndex: 'voicemail',
      key: 'voicemail',
      render: (_, { voicemail }) => (
        <div className='flex justify-center'>
          {voicemail && voicemail?.voicemail_transcript ? (
            <Tooltip title={voicemail?.voicemail_transcript}>
              <Voicemail className='cursor-pointer' fontSize='small' />
            </Tooltip>
          ) : (
            '-'
          )}
        </div>
      ),
    },
    {
      title: 'Call Summary',
      dataIndex: 'Call Summary',
      key: 'Call Summary',
      render: (_, { callSummary, audio }) => (
        <Space>
          {callSummary ? (
            <div className='flex items-center gap-1'>
              <EllipsisText text={callSummary} length={audio ? 35 : 48} />
              <CopyableText text={callSummary} hideText />
            </div>
          ) : (
            'No User Interaction'
          )}
        </Space>
      ),
    },
    {
      title: 'Call Transcript',
      dataIndex: 'Call Transcript',
      key: 'Call Transcript',
      render: (_, record) => <Button onClick={() => handleClick(record)}>Transcript</Button>,
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Space size='small'>
          <Tooltip title='Share'>
            <Button
              type='text'
              icon={<ShareAltOutlined />}
              disabled={!record.shareableLink}
              danger={!record.shareableLink}
              onClick={() =>
                dispatch(
                  openModal({
                    modalId: MODAL_IDS.SHARE_CALL_LOG_MODAL,
                    data: { shareableLink: record.shareableLink, debrand },
                  })
                )
              }
            />
          </Tooltip>

          <Dropdown
            menu={{
              items: [
                {
                  key: 'intake',
                  label: 'View Intake Response',
                  onClick: () => parseIntakeResponse(record.intakeResponses as string),
                  disabled: !(() => {
                    try {
                      const parsedIntakeResponses = JSON.parse(record.intakeResponses || '[]');

                      // Check if parsed data is a non-empty array or object
                      if (Array.isArray(parsedIntakeResponses)) {
                        return parsedIntakeResponses.length > 0 && Object.keys(parsedIntakeResponses[0]).length > 0;
                      } else if (typeof parsedIntakeResponses === 'object' && parsedIntakeResponses !== null) {
                        return Object.keys(parsedIntakeResponses).length > 0;
                      }
                      return false;
                    } catch (e) {
                      return false;
                    }
                  })(),
                },
                debrand || (!record.costCoveredByFreeMinutes && !record.aiCallCost && !record.transferredCallCost)
                  ? null
                  : {
                      key: 'priceDetails',
                      label: 'View Pricing Details',
                      onClick: () =>
                        dispatch(
                          openModal({
                            modalId: MODAL_IDS.CALL_LOG_PRICE_DETAIL_MODAL,
                            data: {
                              costCoveredByFreeMinutes: record.costCoveredByFreeMinutes,
                              aiCallCost: record.aiCallCost,
                              transferredCallCost: record.transferredCallCost,
                              duration: record.duration,
                              transferredCallDuration: record.transferredCallDuration,
                            },
                          })
                        ),
                      disabled: !record.costCoveredByFreeMinutes && !record.aiCallCost && !record.transferredCallCost,
                    },
              ],
            }}
          >
            <Button type='text' icon={<MoreOutlined />} />
          </Dropdown>
          <Tooltip title='Delete'>
            <Button
              type='text'
              danger
              icon={<DeleteOutlined />}
              onClick={() => handleConfirmDeleteModal(record.key, record.callSid, record.date)}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    if (data) {
      setCallLogs(data?.callLogs);
    }
    if (voicemails && voicemails.voiceMailLogs) {
      const updatedCallLogs = addVoicemailField(data?.callLogs || [], voicemails.voiceMailLogs);
      setCallLogs(updatedCallLogs);
    }
  }, [data?.callLogs, voicemails]);

  const addVoicemailField = (callLogs: CallLog[], voiceMailLogs: VoiceMailLog[]) => {
    const voicemailMap = new Map(voiceMailLogs.map(vm => [vm.startedTimestamp, vm]));

    return callLogs.map(callLog => ({
      ...callLog,
      voicemail: voicemailMap.get(callLog.startedTimestamp) || undefined,
    }));
  };

  useEffect(() => {
    refetch();
  }, [startDate, endDate]);

  const formattedIntakeString = intakeResponses.map(response => `${response.key}: ${response.value}`).join('\n\n');

  const FeedbackButton = ({ rating, onClick }: { rating: string; onClick: () => void }) => (
    <button
      onClick={onClick}
      onMouseEnter={() => setHoveredButton(rating)}
      onMouseLeave={() => setHoveredButton(null)}
      className={`
        px-3 py-1 rounded-full mr-2
        transition-all duration-300 ease-in-out
        hover:scale-110 hover:shadow-lg
        ${hoveredButton === rating ? 'bg-blue-100' : 'bg-gray-100'}
      `}
    >
      <span
        className={`
        transform transition-transform duration-300
        ${hoveredButton === rating ? 'scale-125' : 'scale-100'}
      `}
      >
        {rating === 'good' ? '👍' : '👎'}
      </span>
    </button>
  );

  const getFooterContent = () => {
    return (
      <div className='flex items-center justify-between'>
        {callLog?.rating ? (
          <span>You rated this call log {callLog?.rating === 'good' ? '👍' : '👎'}</span>
        ) : (
          <div className='flex-1 flex items-center'>
            <FeedbackButton
              rating='good'
              onClick={() => {
                updateCallLogMutation({
                  callSid: callLog?.callSid || '',
                  date: callLog?.date || '',
                  data: {
                    rating: 'good',
                  },
                });
              }}
            />
            <FeedbackButton
              rating='poor'
              onClick={() => {
                updateCallLogMutation({
                  callSid: callLog?.callSid || '',
                  date: callLog?.date || '',
                  data: {
                    rating: 'poor',
                  },
                });
              }}
            />
          </div>
        )}
        <div>
          <button onClick={handleConfirm} className='px-4 py-2 mr-2 border rounded hover:bg-gray-100'>
            Cancel
          </button>
          <button onClick={handleConfirm} className='px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600'>
            OK
          </button>
        </div>
      </div>
    );
  };

  const filteredData = showFiltered
    ? callLogs.filter(log => log.callSummary !== undefined && log.callSummary !== 'NA' && log.callSummary !== '')
    : callLogs;

  const baseClass = 'call-logs';
  return (
    <div className={baseClass}>
      <div className={`${baseClass}_header`}>
        <h1>Call Logs</h1>
        {isSearchedLog && (
          <Button type='default' onClick={handleResetSearch} icon={<RollbackOutlined />}>
            Back to All Logs
          </Button>
        )}
        {!isSearchedLog && (
          <Button
            type='primary'
            icon={<PlayForWorkOutlinedIcon />}
            onClick={() => handleCallLogsDownload()}
            disabled={
              isLoadingProvisionedNumber ||
              downloadCallLogsStatus === 'pending' ||
              !provisionedNumbers?.provisioned_number
            }
          >
            {!smallScreen && 'Download Logs'}
          </Button>
        )}
        <Button
          type='primary'
          icon={<RefreshOutlinedIcon />}
          onClick={() => {
            refetch();
          }}
          loading={isPending || isRefetching}
        >
          {!smallScreen && 'Refresh Logs'}
        </Button>
      </div>
      {!isSearchedLog && (
        <div className={`${baseClass}_input-container responsive-input-container`}>
          <RangePicker placement='bottomRight' onChange={handleDateRangeChange} />
          <Select
            value={timeZone}
            onChange={handleTimeZoneChange}
            showSearch
            placeholder='Select a time zone'
            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
            options={timeZoneList.map(zone => ({ label: zone, value: zone }))}
            className='tz-filter'
          />
          <Button type='primary' icon={<CheckOutlined />} disabled={!hasSelected} onClick={handleMarkAsRead}>
            Mark As Read
          </Button>
          <Checkbox onChange={hideEmptyCalls}>Hide Empty Calls</Checkbox>
        </div>
      )}
      {loggedInUser?.algoliaSearchToken && (
        <div className='mb-3'>
          <AlgoliaSearch
            userNumber={loggedInUser.number as string}
            algoliaSearchToken={loggedInUser.algoliaSearchToken as string}
            onLogSelect={handleLogSelect}
            onReset={handleResetSearch}
            isLoading={callLogbySidPending}
          />
        </div>
      )}
      <Alert className='overview-info' closable description='Access and review your Call Logs in minutes! 👇' />
      <Spin spinning={isPending}>
        <div className={`${baseClass}_table-container`}>
          <Table
            rowSelection={{ type: 'checkbox', ...rowSelection }}
            columns={columns}
            dataSource={filteredData}
            pagination={{
              pageSize: 10,
            }}
            onRow={record => ({
              onClick: event => {
                const targetElement = event.target as HTMLElement;
                if (targetElement.tagName === 'INPUT' && (targetElement as HTMLInputElement).type === 'checkbox') {
                  return;
                }
                handleRowInteraction(record);
              },
            })}
            scroll={{ x: 'max-content' }}
            rowClassName={record => (record.isRead ? 'read-row' : 'unread-row')}
          />
        </div>
      </Spin>

      <div className='custom-modal'>
        <Modal
          onCancel={handleCancel}
          title={<strong>Call Log Details</strong>}
          open={showModal}
          onOk={handleConfirm}
          footer={getFooterContent()}
        >
          {<CallDetails callLog={callLog} />}
        </Modal>
      </div>
      <CustomModal
        title={
          <div>
            <strong>Call Intake Form</strong>
            <CopyableText text={formattedIntakeString} hideText />
          </div>
        }
        children={<CallIntakeResponse intakeResponse={intakeResponses} />}
        isModalOpen={intakeModal}
        confirmAction={handleCancelIntakeModal}
        cancelAction={handleCancelIntakeModal}
      />
      <CustomModal
        title={<>Are you sure you want to delete this call log?</>}
        children={<>This action cannot be undone.</>}
        isModalOpen={confirmDelete}
        confirmAction={handleDeleteCallLog}
        cancelAction={handleCancelDeleteModal}
        footer={[
          <Button key='cancel' onClick={handleCancelDeleteModal}>
            Cancel
          </Button>,
          <Button
            key='confirm'
            type='primary'
            onClick={handleDeleteCallLog}
            danger
            loading={deleteCallLogStatus === 'pending'}
          >
            Delete
          </Button>,
        ]}
      />
      <Modal
        title='Save Contact'
        open={saveContactModal}
        onCancel={handleCancelSaveContact}
        footer={null}
        width={800}
        centered
      >
        {crmGroupsData?.crmGroups && crmGroupsData.crmGroups.length > 0 ? (
          <CRMLeadCreator
            key={selectedContact}
            phoneNumber={selectedContact as string}
            ref={leadFormRef}
            onSubmit={handleCreateLead}
            isLoading={createLeadMutation.isPending}
            groups={validGroups as CRMGroup[]}
          />
        ) : (
          <Empty
            image={<Settings2 className='w-16 h-16 text-yellow-500 animate-pulse' />}
            description={
              <Space direction='vertical' className='text-center mt-4' size='large'>
                <Title level={4}>CRM Setup Needed</Title>
                <Space direction='vertical' size='small'>
                  <Text type='secondary'>
                    <AlertCircle className='inline w-4 h-4 mr-2' />
                    Your CRM groups haven't been configured yet
                  </Text>
                  <Text className='text-blue-600'>Set up your groups to start managing contacts effectively</Text>
                </Space>
                <Button type='dashed' size='large' icon={<ArrowRight className='w-4 h-4' />} onClick={onNavigateToCRM}>
                  Configure CRM Now
                </Button>
              </Space>
            }
          />
        )}
      </Modal>
      <PriceDetailModal />
      <ShareCallLogModal />
    </div>
  );
};

export default CallLogs;
