import React from 'react';
import { Alert, Typography, Button, Space, Grid } from 'antd';
import { WarningOutlined } from '@ant-design/icons';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { getLoggedInNoviUser, getSelectedCampaignId } from '@/store/novi/user/selector';
import { useAppDispatch } from '@/store/hooks/useAppDispatch';
import { setNoviCurrentPage, setNoviSelectedCampaignId } from '@/store/novi/user/slice';
import { ENoviDashboardSider } from '@/pages/Novi/enums/ENoviDashboardSider';

const { Text } = Typography;
const { useBreakpoint } = Grid;

const NoviSubCancelPromo: React.FC = () => {
  const screens = useBreakpoint();
  const dispatch = useAppDispatch();
  const noviUser = useAppSelector(getLoggedInNoviUser);
  const selectedCampaignId = useAppSelector(getSelectedCampaignId);

  if (!noviUser?.subCancelOn) {
    return null;
  }

  const handleRenewClick = () => {
    if (selectedCampaignId) {
      dispatch(setNoviSelectedCampaignId(null));
      setTimeout(() => {
        dispatch(setNoviCurrentPage(ENoviDashboardSider.SUBSCRIPTION));
      }, 500);
    } else {
      dispatch(setNoviCurrentPage(ENoviDashboardSider.SUBSCRIPTION));
    }
  };

  return (
    <Alert
      type='warning'
      showIcon
      icon={<WarningOutlined />}
      closable
      message='Subscription Cancellation Notice'
      description={
        <Space direction='vertical' size={screens.md ? 12 : 8} style={{ width: '100%' }}>
          <div>
            <Text style={{ fontSize: screens.md ? '16px' : '14px' }}>
              Your subscription is scheduled to be canceled on{' '}
              <Text strong>
                {new Date(noviUser.subCancelOn).toLocaleDateString('en-US', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                })}
              </Text>
            </Text>
          </div>
          <div>
            <Text type='secondary' style={{ fontSize: screens.md ? '14px' : '12px' }}>
              To keep your access uninterrupted, you can renew your subscription before the cancellation date in the
              Subscription section's Billing portal.
            </Text>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              type='primary'
              size='middle'
              onClick={handleRenewClick}
              style={{
                width: screens.md ? 'auto' : '100%',
              }}
            >
              Renew Subscription
            </Button>
          </div>
        </Space>
      }
      style={{
        border: '1px solid #faad14',
        borderRadius: '6px',
        padding: screens.md ? '16px' : '12px',
      }}
    />
  );
};

export default NoviSubCancelPromo;
