import React from 'react';
import Fade from 'react-reveal/Fade';
import classes from '../Register.module.css';
import posthog from 'posthog-js';
import { Button, notification } from 'antd';
import PhoneInput from 'react-phone-input-2';

type Step9Props = {
  reseller_email?: string;
  campaign_user_email?: string;
  testingNumber: string;
  stepState: number;
  setTestingNumber: (number: string) => void; // Correctly typed
  handleStepTransition: (
    nextStep: number,
    validationFn?: () => Promise<boolean>,
    stateMutationFn?: () => Promise<void>
  ) => Promise<void>;
  stepLoading: boolean;
};

const Step9: React.FC<Step9Props> = ({
  reseller_email,
  campaign_user_email,
  testingNumber,
  stepState,
  setTestingNumber,
  handleStepTransition,
  stepLoading,
}) => {
  const handleContinueClick = () => {
    if (!testingNumber || testingNumber.length < 6) {
      notification.error({ message: 'Please enter a valid phone number', duration: 1 });
      return;
    }
    handleStepTransition(stepState + 1);
  };

  return (
    <div className={classes.box}>
      <Fade duration={1000} bottom>
        <>
          {React.createElement(() => {
            posthog.capture('testing_number_entered');
            return null;
          })}

          <h3 className={classes.heading} style={{ textAlign: 'center' }}>
            Testing Call: Your Phone Number
          </h3>
          <div className={classes.subtitle} style={{ textAlign: 'center' }}>
            {reseller_email || campaign_user_email
              ? 'This section is intended for entering the personal phone number of the client who will be the owner of this receptionist'
              : "You will test your receptionist by calling our test number from your phone. Please enter the number you'll use to make the test call."}
          </div>

          <div className='flex justify-center'>
            <PhoneInput
              placeholder='Enter Phone Number'
              country='us'
              value={testingNumber}
              preferredCountries={['us', 'ca', 'gb']}
              autoFormat={true}
              inputStyle={{ height: '30px', fontSize: '14px' }}
              containerStyle={{ width: 'auto' }}
              inputClass={`${classes.input} font-medium`}
              onChange={(value: string) => setTestingNumber(value.startsWith('+') ? value : `+${value}`)}
            />
          </div>

          <Button
            type='primary'
            onClick={handleContinueClick}
            className={`${classes.button} ${classes.centered}`}
            style={{ marginTop: '2rem' }}
            loading={stepLoading}
          >
            Continue
          </Button>
        </>
      </Fade>
    </div>
  );
};

export default Step9;
