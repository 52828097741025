import { useState, useEffect, useRef } from 'react';
import { DragDropContext, Droppable, Draggable, DropResult } from '@hello-pangea/dnd';
import { Card, Space, Button, Tag, Typography, Modal, notification, Tooltip } from 'antd';
import { EditOutlined, DeleteOutlined, InfoCircleOutlined, WarningOutlined } from '@ant-design/icons';
import VirtualList from 'rc-virtual-list';
import { CRMGroup, CRMLead } from '@/types/TCRM';
import CRMGroupCreator, { CRMGroupCreatorRef } from './crmGroupCreator';
import { deleteAGroup, updateAGroup } from '@/api/crm/groups';
import { useMutation } from '@tanstack/react-query';
import { deleteCRMLead, updateCRMLead, updateLeadGroup } from '@/api/crm/leads';
import CRMLeadCreator, { CRMLeadCreatorRef } from './crmLeadCreator';
import { TClient } from '@/types/TClient';

const { Text } = Typography;
import EllipsisText from '@/components/LabelWithdescription/EllipsisText';
import { TCallTransferWorkflow, TTextingWorkflow } from '@/types/TWorkflows';

type BoardViewProps = {
  clientID: string;
  clientData: TClient;
  leads: CRMLead[];
  groups: CRMGroup[];
  handleViewLead: (lead: CRMLead) => void;
  refetchAllLeads: () => void;
  refetchCRMGroups: () => void;
  refetchLead: (leadID: string) => void;
};

const BoardView: React.FC<BoardViewProps> = ({
  clientID,
  clientData,
  leads,
  groups,
  refetchCRMGroups,
  refetchAllLeads,
  refetchLead,
  handleViewLead,
}) => {
  const [leadsState, setLeadsState] = useState<CRMLead[]>(leads ?? []);
  const [selectedGroup, setSelectedGroup] = useState<CRMGroup | null>(null);
  const [editGroupModal, setEditGroupModal] = useState<boolean>(false);
  const [editLeadModal, setEditLeadModal] = useState<CRMLead | null>(null);
  const [deleteLeadId, setDeleteLeadId] = useState<string | null>(null);
  const [deleteGroupId, setDeleteGroupId] = useState<string | null>(null);
  const formRef = useRef<CRMGroupCreatorRef>(null);
  const leadFormRef = useRef<CRMLeadCreatorRef>(null);

  const [textingWorkflow, setTextingWorkflow] = useState<TTextingWorkflow[]>([]);
  const [callTransferWorkflow, setCallTransferWorkflow] = useState<TCallTransferWorkflow[]>([]);

  useEffect(() => {
    try {
      setTextingWorkflow(JSON.parse(clientData?.workflows as string));
      setCallTransferWorkflow(JSON.parse(clientData?.callWorkflows as string));
    } catch (error) {
      console.error('Unable to parse the workflow', error);
    }
  }, [clientData]);

  const isWorkflowValid = (group: CRMGroup) => {
    if (group.trigger_type !== 'workflow') return true;

    const workflowId = group.trigger_details?.workflow?.workflow_id;
    if (!workflowId) return false;

    return textingWorkflow.some(w => w.id === workflowId) || callTransferWorkflow.some(w => w.id === workflowId);
  };

  const updateLeadGroupMutation = useMutation({
    mutationFn: updateLeadGroup,
    onSuccess: (_, variables) => {
      notification.success({
        message: 'Lead Moved',
        description: 'The lead has been successfully moved to the new group.',
      });
      refetchLead(variables.leadID);
    },
    onError: (_, variables) => {
      notification.error({
        message: 'Error',
        description: 'An error occurred while moving the lead.',
      });
      refetchLead(variables.leadID);
    },
  });

  const editCrmGroupMutation = useMutation({
    mutationFn: updateAGroup,
    onSuccess: () => {
      notification.success({
        message: 'Group Updated',
        description: 'The group has been successfully updated.',
      });
      refetchCRMGroups();
      setEditGroupModal(false);
      formRef.current?.resetForm();
    },
    onError: () => {
      notification.error({
        message: 'Error',
        description: 'An error occurred while updating the group.',
      });
    },
  });

  const updateLeadMutation = useMutation({
    mutationFn: updateCRMLead,
    onSuccess: () => {
      notification.success({
        message: 'Lead Updated',
        description: 'The lead has been successfully updated.',
      });
      setEditLeadModal(null);
      refetchLead(editLeadModal?.lead_id as string);
    },
    onError: () => {
      notification.error({
        message: 'Error',
        description: 'An error occurred while updating the lead.',
      });
    },
  });

  const deleteLeadMutation = useMutation({
    mutationFn: deleteCRMLead,
    onSuccess: () => {
      notification.success({
        message: 'Lead Deleted',
        description: 'The lead has been successfully deleted.',
      });
      setDeleteLeadId(null);
      refetchAllLeads();
    },
    onError: () => {
      notification.error({
        message: 'Error',
        description: 'An error occurred while deleting the lead.',
      });
    },
  });

  const deleteGroupMutation = useMutation({
    mutationFn: deleteAGroup,
    onSuccess: () => {
      notification.success({
        message: 'Group Deleted',
        description: 'The group has been successfully deleted.',
      });
      setDeleteGroupId(null);
      refetchCRMGroups();
    },
    onError: error => {
      notification.error({
        message: 'Error',
        description: error.message,
      });
    },
  });

  const handleUpdateGroup = (values: CRMGroup) => {
    editCrmGroupMutation.mutate({
      clientID,
      groupID: selectedGroup?.group_id as string,
      groupData: values,
    });
  };

  const handleUpdateLead = (values: Partial<CRMLead>) => {
    updateLeadMutation.mutate({ clientID, leadID: values.lead_id as string, leadData: values });
  };

  const handleDeleteLead = () => {
    deleteLeadMutation.mutate({ clientID, leadID: deleteLeadId as string });
  };

  const handleDragEnd = (result: DropResult) => {
    const { source, destination, draggableId } = result;

    if (!destination || (source.droppableId === destination.droppableId && source.index === destination.index)) {
      return;
    }

    const targetGroup = groups.find(group => group.group_id === destination.droppableId);
    if (targetGroup && !isWorkflowValid(targetGroup)) {
      notification.error({
        message: 'Invalid Move',
        description:
          'This group has an invalid workflow configuration. Please fix the workflow before moving leads here.',
      });
      return;
    }

    const draggedLead = leadsState.find(lead => lead.lead_id === draggableId);
    if (!draggedLead) return;

    const newLeadsState = leadsState.map(lead =>
      lead.lead_id === draggableId ? { ...lead, group_id: destination.droppableId } : lead
    );
    setLeadsState(newLeadsState);

    updateLeadGroupMutation.mutate({
      leadID: draggableId,
      groupID: destination.droppableId,
    });
  };

  useEffect(() => {
    setLeadsState(leads ?? []);
  }, [leads]);

  const renderLeadCard = (lead: CRMLead, index: number) => (
    <Draggable key={lead.lead_id} draggableId={lead.lead_id} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={{
            ...provided.draggableProps.style,
            opacity: snapshot.isDragging ? 0.8 : 1,
          }}
        >
          <Card size='small' className='hover:bg-gray-50 transition-colors mb-2' style={{ borderRadius: '8px' }}>
            <div className='space-y-1'>
              <div className='flex items-center justify-between'>
                <Text className='text-sm'>{lead.dynamic_fields?.name || lead.phone_number || lead.email}</Text>
                <Tag className='text-xs rounded-full border-0 px-2 py-0 bg-blue-50 text-blue-600'>{lead.lead_type}</Tag>
              </div>

              <Space size='small'>
                <Button
                  type='text'
                  size='small'
                  className='px-1 hover:bg-gray-100'
                  icon={<InfoCircleOutlined className='text-yellow-500 text-xs' />}
                  onClick={() => handleViewLead(lead)}
                />
                <Button
                  type='text'
                  size='small'
                  className='px-1 hover:bg-gray-100'
                  icon={<EditOutlined className='text-blue-500 text-xs' />}
                  onClick={() => setEditLeadModal(lead)}
                />
                <Button
                  type='text'
                  size='small'
                  className='px-1 hover:bg-gray-100'
                  icon={<DeleteOutlined className='text-red-500 text-xs' />}
                  onClick={() => setDeleteLeadId(lead.lead_id)}
                />
              </Space>
            </div>
          </Card>
        </div>
      )}
    </Draggable>
  );

  return (
    <div className='h-full'>
      <DragDropContext onDragEnd={handleDragEnd}>
        <div className='bg-gray-100 overflow-x-auto overflow-y-hidden h-full'>
          <div className='min-w-[1024px] h-full p-4'>
            <div className='grid grid-cols-4 gap-4 h-full'>
              {groups?.map(group => {
                const isValid = isWorkflowValid(group);
                return (
                  <Droppable key={group.group_id} droppableId={group.group_id} isDropDisabled={!isValid}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        className={`flex flex-col h-full min-w-[250px] ${!isValid ? 'opacity-90' : ''}`}
                      >
                        <div className='flex items-center justify-between mb-1 px-2'>
                          <div className='flex items-center gap-1'>
                            <EllipsisText text={group.group_name} length={20} />
                            {!isValid && (
                              <Tooltip title="This group's workflow configuration is invalid. The specified workflow doesn't exist.">
                                <WarningOutlined className='text-red-500 text-sm' />
                              </Tooltip>
                            )}
                          </div>
                          <Space>
                            <Tag
                              className={`rounded-full text-xs px-2 py-0 ${
                                isValid ? 'bg-gray-50 border-0 text-gray-500' : 'bg-red-50 border-0 text-red-500'
                              }`}
                            >
                              {leadsState.filter(lead => lead.group_id === group.group_id).length}
                            </Tag>
                            <Button
                              type='text'
                              size='small'
                              icon={<EditOutlined className='text-blue-500 text-xs' />}
                              onClick={() => {
                                setSelectedGroup(group);
                                setEditGroupModal(true);
                              }}
                            />
                            <Button
                              type='text'
                              size='small'
                              icon={<DeleteOutlined className='text-red-500 text-xs' />}
                              onClick={() => setDeleteGroupId(group.group_id)}
                            />
                          </Space>
                        </div>
                        <div
                          className={`flex-1 overflow-y-auto bg-white rounded-lg shadow-sm p-2 ${
                            !isValid ? 'border-2 border-red-200 bg-red-50/30' : ''
                          }`}
                          style={{
                            backgroundColor: snapshot.isDraggingOver
                              ? isValid
                                ? '#f3f4f6'
                                : '#fee2e2'
                              : !isValid
                                ? '#fff1f2'
                                : 'white',
                          }}
                        >
                          <VirtualList
                            height={430}
                            itemHeight={80}
                            itemKey='lead_id'
                            data={leadsState.filter(lead => lead.group_id === group.group_id)}
                          >
                            {(lead, index) => renderLeadCard(lead, index)}
                          </VirtualList>
                          {provided.placeholder}
                        </div>
                      </div>
                    )}
                  </Droppable>
                );
              })}
            </div>
          </div>
        </div>
      </DragDropContext>

      <Modal open={editGroupModal} onCancel={() => setEditGroupModal(false)} footer={null} width={800} centered>
        {selectedGroup && (
          <CRMGroupCreator
            ref={formRef}
            initialData={selectedGroup}
            onSubmit={handleUpdateGroup}
            isLoading={editCrmGroupMutation.isPending}
            clientData={clientData}
          />
        )}
      </Modal>

      <Modal open={!!editLeadModal} footer={null} onCancel={() => setEditLeadModal(null)} width={900} centered>
        <CRMLeadCreator
          ref={leadFormRef}
          initialData={editLeadModal as CRMLead}
          onSubmit={handleUpdateLead}
          isLoading={updateLeadMutation.isPending}
          groups={groups}
        />
      </Modal>

      <Modal
        open={!!deleteLeadId}
        onCancel={() => setDeleteLeadId(null)}
        footer={
          <Space size='small'>
            <Button size='small' onClick={() => setDeleteLeadId(null)}>
              Cancel
            </Button>
            <Button size='small' danger loading={deleteLeadMutation.isPending} onClick={handleDeleteLead}>
              Delete
            </Button>
          </Space>
        }
        width={400}
      >
        <Text className='text-sm'>Are you sure you want to delete this lead?</Text>
      </Modal>

      <Modal
        open={!!deleteGroupId}
        onCancel={() => setDeleteGroupId(null)}
        footer={
          <Space size='small'>
            <Button size='small' onClick={() => setDeleteGroupId(null)}>
              Cancel
            </Button>
            <Button
              size='small'
              danger
              loading={deleteGroupMutation.isPending}
              onClick={() => {
                deleteGroupMutation.mutate({ clientID, groupID: deleteGroupId as string });
              }}
            >
              Delete
            </Button>
          </Space>
        }
        width={400}
      >
        <Text className='text-sm'>Are you sure you want to delete this group?</Text>
      </Modal>
    </div>
  );
};

export default BoardView;
