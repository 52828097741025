import { useRef, useState } from 'react';
import { Row, Col, Typography, Card, Button, Modal, Divider, Space, notification } from 'antd';
import {
  RobotOutlined,
  PhoneOutlined,
  LineChartOutlined,
  DollarOutlined,
  StarOutlined,
  RocketOutlined,
} from '@ant-design/icons';
import { useMutation } from '@tanstack/react-query';
import { createAGroup } from '@/api/crm/groups';
import CRMGroupCreator, { CRMGroupCreatorRef } from './crmGroupCreator';
import { GroupData } from '@/types/TCRM';

const { Title, Text, Paragraph } = Typography;

interface FeatureCardProps {
  icon: React.ReactNode;
  title: string;
  description: string;
  benefits: string[];
  color: string;
}

const FeatureCard: React.FC<FeatureCardProps> = ({ icon, title, description, benefits, color }) => (
  <Card
    className='feature-card bg-gray-100 rounded-lg shadow-md h-full transition-transform duration-300 ease-in-out hover:transform hover:scale-105'
    hoverable
    bodyStyle={{
      padding: '20px',
      display: 'flex',
      flexDirection: 'column',
    }}
  >
    <div className='flex items-center mb-4'>
      <div
        className='text-2xl mr-4 rounded-full w-14 h-14 flex items-center justify-center'
        style={{ color: color, background: `${color}14` }}
      >
        {icon}
      </div>
      <Title level={4} className='text-lg m-0 text-gray-800'>
        {title}
      </Title>
    </div>

    <Text type='secondary' className='text-sm mb-3 text-gray-600'>
      {description}
    </Text>

    <Divider className='my-3' />

    <Space direction='vertical' size='small'>
      {benefits.map((benefit, index) => (
        <div key={index} className='flex items-center text-xs text-gray-600'>
          <StarOutlined className='mr-2 text-base' style={{ color: color }} />
          {benefit}
        </div>
      ))}
    </Space>
  </Card>
);

const EmptyBoard: React.FC<{ clientID: string; clientData: any; refetchCRMGroups: () => void }> = ({
  clientID,
  clientData,
  refetchCRMGroups,
}) => {
  const formRef = useRef<CRMGroupCreatorRef>(null);
  const [isBoardModalOpen, setIsBoardModalOpen] = useState(false);

  const createGroupMutation = useMutation({
    mutationFn: createAGroup,
    onSuccess: () => {
      notification.success({
        message: 'Group Created',
        description: 'Your new CRM group has been successfully created.',
      });
      refetchCRMGroups();
      setIsBoardModalOpen(false);
      formRef.current?.resetForm();
    },
  });

  const handleSubmitForm = (values: GroupData) => {
    createGroupMutation.mutate({ clientID, groupData: values });
  };

  const features = [
    {
      icon: <RobotOutlined />,
      title: 'AI-Powered Call Answering',
      description: 'Revolutionize your customer communication with intelligent AI',
      benefits: [
        '24/7 automated call handling',
        'Intelligent call routing',
        'Instant lead capture',
        'Zero missed opportunities',
      ],
      color: '#1890ff',
    },
    {
      icon: <PhoneOutlined />,
      title: 'Automated Lead Management',
      description: 'Transform every interaction into a strategic business opportunity',
      benefits: [
        'Automatic lead tracking',
        'Instant contact profiling',
        'Smart segmentation',
        'Real-time lead scoring',
      ],
      color: '#52c41a',
    },
    {
      icon: <LineChartOutlined />,
      title: 'Intelligent Marketing Automation',
      description: 'Personalized marketing strategies powered by AI insights',
      benefits: [
        'Predictive customer journeys',
        'Automated follow-up sequences',
        'Tailored communication',
        'Performance optimization',
      ],
      color: '#722ed1',
    },
    {
      icon: <DollarOutlined />,
      title: 'Enterprise-Grade CRM',
      description: 'Unlock enterprise-level capabilities without enterprise costs',
      benefits: ['Scalable infrastructure', 'Advanced analytics', 'Secure data management', 'Cost-effective solution'],
      color: '#faad14',
    },
  ];

  return (
    <div className='bg-white min-h-screen p-8 flex flex-col items-center justify-start'>
      <div className='text-center max-w-2xl mb-8'>
        <Title level={1} className='text-2xl mb-4 text-gray-800 flex items-center justify-center gap-3'>
          <RocketOutlined className='text-blue-500' />
          AI CRM: Your Business Acceleration Platform
        </Title>

        <Paragraph className='text-base text-gray-600 mb-6'>
          Elevate your business with cutting-edge AI technology. Seamlessly manage leads, automate communications, and
          unlock unprecedented growth potential.
        </Paragraph>
      </div>

      <Row gutter={[24, 24]} className='max-w-5xl w-full'>
        {features.map((feature, index) => (
          <Col key={index} xs={24} sm={12} lg={12} xl={12}>
            <FeatureCard
              icon={feature.icon}
              title={feature.title}
              description={feature.description}
              benefits={feature.benefits}
              color={feature.color}
            />
          </Col>
        ))}
      </Row>

      <Button
        type='primary'
        size='large'
        onClick={() => setIsBoardModalOpen(true)}
        className='mt-8 px-6 py-3 text-lg flex items-center gap-2'
      >
        <RocketOutlined />
        Start Your AI CRM Journey
      </Button>

      <Modal open={isBoardModalOpen} onCancel={() => setIsBoardModalOpen(false)} footer={null} width={800} centered>
        <CRMGroupCreator
          ref={formRef}
          onSubmit={handleSubmitForm}
          isLoading={createGroupMutation.isPending}
          clientData={clientData}
        />
      </Modal>
    </div>
  );
};

export default EmptyBoard;
