const CountryDateFormats: Record<string, string> = {
  US: 'MM/DD/YYYY', // United States
  PH: 'MM/DD/YYYY', // Philippines
  GB: 'DD/MM/YYYY', // United Kingdom
  FR: 'DD/MM/YYYY', // France
  IN: 'DD/MM/YYYY', // India
  IT: 'DD/MM/YYYY', // Italy
  ES: 'DD/MM/YYYY', // Spain
  AU: 'DD/MM/YYYY', // Australia
  SA: 'DD/MM/YYYY', // Saudi Arabia
  NZ: 'DD/MM/YYYY', // New Zealand
  AE: 'DD/MM/YYYY', // United Arab Emirates
  BR: 'DD/MM/YYYY', // Brazil
  MX: 'DD/MM/YYYY', // Mexico
  BE: 'DD/MM/YYYY', // Belgium
  TH: 'DD/MM/YYYY', // Thailand
  SG: 'DD/MM/YYYY', // Singapore
  MY: 'DD/MM/YYYY', // Malaysia
  ID: 'DD/MM/YYYY', // Indonesia
  VN: 'DD/MM/YYYY', // Vietnam
  PK: 'DD/MM/YYYY', // Pakistan
  NG: 'DD/MM/YYYY', // Nigeria
  AR: 'DD/MM/YYYY', // Argentina
  BD: 'DD/MM/YYYY', // Bangladesh
  CL: 'DD/MM/YYYY', // Chile
  CO: 'DD/MM/YYYY', // Colombia
  EG: 'DD/MM/YYYY', // Egypt
  GR: 'DD/MM/YYYY', // Greece
  PT: 'DD/MM/YYYY', // Portugal
  IL: 'DD/MM/YYYY', // Israel
  JP: 'YYYY/MM/DD', // Japan
  CN: 'YYYY/MM/DD', // China
  ZA: 'YYYY/MM/DD', // South Africa
  DE: 'DD.MM.YYYY', // Germany
  RU: 'DD.MM.YYYY', // Russia
  NO: 'DD.MM.YYYY', // Norway
  CH: 'DD.MM.YYYY', // Switzerland
  TR: 'DD.MM.YYYY', // Turkey
  PL: 'DD.MM.YYYY', // Poland
  FI: 'DD.MM.YYYY', // Finland
  CZ: 'DD.MM.YYYY', // Czech Republic
  SK: 'DD.MM.YYYY', // Slovakia
  CA: 'YYYY-MM-DD', // Canada (official), but regional variations
  SE: 'YYYY-MM-DD', // Sweden
  DK: 'DD-MM-YYYY', // Denmark
  NL: 'DD-MM-YYYY', // Netherlands
  KR: 'YYYY.MM.DD', // South Korea
  HU: 'YYYY.MM.DD', // Hungary
};

export default CountryDateFormats;
