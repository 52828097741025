import { CustomModal } from '@/components/CustomModal';
import { MODAL_IDS } from 'src/enums/EModal';
import { Divider, Tag } from 'antd';
import { Timer, PhoneCall, Wallet } from 'lucide-react';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { getModalData } from '@/store/modal/selector';

interface PriceDetailModalProps {
  duration?: string;
  transferredCallDuration?: number;
  costCoveredByFreeMinutes?: number;
  aiCallCost?: number;
  transferredCallCost?: number;
}

const PriceDetailModal = () => {
  const data = useAppSelector(getModalData) as PriceDetailModalProps;

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.round(seconds % 60);
    return `${minutes}m ${remainingSeconds}s`;
  };

  // Calculate durations
  const totalDuration = data?.duration ? parseInt(data?.duration) + (data?.transferredCallDuration || 0) : 0;

  // Calculate costs
  const freeCost = Number(data?.costCoveredByFreeMinutes) || 0;
  const aiCost = Number(data?.aiCallCost) || 0;
  const transferCost = Number(data?.transferredCallCost) || 0;
  const totalCost = Number(aiCost + transferCost + freeCost);
  const finalChargedAmount = Number(totalCost - freeCost);

  return (
    <CustomModal
      modalId={MODAL_IDS.CALL_LOG_PRICE_DETAIL_MODAL}
      footer={null}
      title={
        <div className='flex items-center gap-2'>
          <Wallet className='w-6 h-6' />
          <span>Call Pricing Details</span>
        </div>
      }
    >
      <div className='space-y-6'>
        {/* Duration Details Card */}
        <div>
          <div className='flex items-center gap-2 mb-4'>
            <Timer className='w-5 h-5' />
            <h3 className='text-base font-medium'>Duration Details</h3>
          </div>

          <div className='grid grid-cols-2 gap-4 mb-4'>
            {data?.duration && (
              <div className='bg-gray-50 p-3 rounded'>
                <div className='text-sm text-gray-600 mb-1'>AI Reception</div>
                <div className='text-lg font-medium'>{formatTime(parseInt(data?.duration))}</div>
              </div>
            )}
            <div className='bg-gray-50 p-3 rounded'>
              <div className='text-sm text-gray-600 mb-1'>Call Transfer</div>
              <div className='text-lg font-medium'>{formatTime(data?.transferredCallDuration || 0)}</div>
            </div>
          </div>

          <div className='flex justify-between items-center pt-3 border-t border-gray-100'>
            <span className='text-gray-600'>Total Duration</span>
            <span className='font-medium'>{formatTime(totalDuration)}</span>
          </div>
        </div>

        {/* Cost Details Card */}
        <div>
          <div className='flex items-center gap-2 mb-4'>
            <PhoneCall className='w-5 h-5' />
            <h3 className='text-base font-medium'>Cost Details</h3>
          </div>

          {/* 1. Free Cost */}
          {!!freeCost && (
            <div className='bg-blue-50 p-3 rounded mb-3'>
              <div className='flex justify-between items-center'>
                <div className='flex items-center gap-2'>
                  <span>Free Minutes</span>
                  <Tag className='m-0' color='success'>
                    FREE
                  </Tag>
                </div>
                <span className='text-green-600 font-medium'>${freeCost.toFixed(4)}</span>
              </div>
            </div>
          )}

          {/* 2. AI Reception Cost */}
          {!!aiCost && (
            <div className='bg-gray-50 p-3 rounded mb-3'>
              <div className='flex justify-between items-center'>
                <span className='text-gray-600'>AI Receptionist Call Cost</span>
                <span className='font-medium'>${aiCost.toFixed(4)}</span>
              </div>
            </div>
          )}

          {/* 3. Transfer Cost */}
          <div className='bg-gray-50 p-3 rounded mb-3'>
            <div className='flex justify-between items-center'>
              <span className='text-gray-600'>Transfer Call Cost</span>
              <span className='font-medium'>${transferCost.toFixed(4)}</span>
            </div>
          </div>

          {/* 4. Total of Everything */}
          <Divider className='my-3' />
          <div className='flex justify-between items-center mb-3 p-3'>
            <span className='font-medium'>Total Cost</span>
            <span className='font-medium'>${totalCost.toFixed(4)}</span>
          </div>

          {/* 5. Grand Total (Total - Free) */}
          <div className='bg-gray-100 p-3 rounded'>
            <div className='flex justify-between items-center'>
              <div>
                <span className='font-medium'>Amount Charged</span>
                <div className='text-sm text-gray-500'>(Total Cost - Free Minutes)</div>
              </div>
              <span className='text-lg text-blue-600 font-bold'>${finalChargedAmount.toFixed(4)}</span>
            </div>
          </div>
        </div>
      </div>
    </CustomModal>
  );
};

export default PriceDetailModal;
