import { Alert, Button, Form, Input } from 'antd';
import { LoginFormData } from '@/types/TLogin';
import './WhitelabelCampaignLogin.scss';
import { Link, useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { useAppDispatch } from '@/store/hooks/useAppDispatch';
import LocalStorageUtility from '@/utils/localStorage';
import { LocalStorageKeys } from 'src/enums/ELocalStorage';
import PublicLayout from '@/components/Layout/PublicLayout';
import { useEffect } from 'react';
import { LoginForm } from '@/components/Form/LoginForm/LoginForm';
import { AppRoutes } from 'src/enums/ERoutes';
import ResellerImage from '../../../../../ResellerLogin/ResellerImage';
import { loginCampaign } from '@/api/novi/campaigns';
import { setNoviCurrentPage, setNoviSelectedCampaign, setNoviSelectedCampaignId } from '@/store/novi/user/slice';
import { ENoviDashboardSider } from '@/pages/Novi/enums/ENoviDashboardSider';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { getResellerData } from '@/store/reseller/selector';

export const WhitelabelCampaignLogin: React.FC = () => {
  const [form] = Form.useForm<LoginFormData>();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const currentReseller = useAppSelector(getResellerData);
  const outletContext = useOutletContext<any>();
  const {
    from,
    debrand: debrandFromLocation,
    resellerCode: resellerCodeFromLocation,
  } = (location.state as {
    from: string;
    debrand: boolean;
    resellerCode: string;
  }) || {
    from: '/',
    debrand: false,
  };

  const debrand = outletContext?.debrand || debrandFromLocation;
  const resellerCode = outletContext?.resellerCode || resellerCodeFromLocation;

  const mutation = useMutation({
    mutationKey: ['loginCampaignApi'],
    mutationFn: loginCampaign,
    onSuccess(data: any) {
      LocalStorageUtility.setLocalData(LocalStorageKeys.AUTH_TOKEN, data.token);
      dispatch(setNoviSelectedCampaign(data));
      dispatch(setNoviSelectedCampaignId(data.number));
      LocalStorageUtility.setLocalData(LocalStorageKeys.NOVI_RECEPTIONIST_AUTH_TOKEN, data.token);
      dispatch(setNoviCurrentPage(ENoviDashboardSider.LEADS));

      navigate(
        debrand && from === '/' ? AppRoutes.WHITE_LABEL_CAMPAIGN_DASHBOARD : AppRoutes.WHITE_LABEL_CAMPAIGN_DASHBOARD,
        { replace: true }
      );
    },
  });

  const handleSubmit = (values: LoginFormData) => {
    mutation.mutate(values);
  };

  //   const filteredItems = useMemo(
  //     () =>
  //       items.filter(item => {
  //         if (location.pathname === AppRoutes.RECEPTIONIST_LOGIN && item.label === 'Login') {
  //           return false;
  //         }
  //         if (location.pathname === AppRoutes.REGISTER && item.label === 'Register') {
  //           return false;
  //         }
  //         return true;
  //       }),
  //     [location.pathname]
  //   );

  useEffect(() => {
    if (typeof window !== 'undefined' && currentReseller?.reseller_name) {
      try {
        // Update the document title (tab title)
        document.title = `${currentReseller.reseller_name} Portal`;

        // Update the meta description
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
          metaDescription.setAttribute('content', `${currentReseller.reseller_name} Campaign/Portal`);
        }

        // If you still need to update an element with id 'page-title'
        const pageTitle = document.getElementById('page-title');
        if (pageTitle) {
          pageTitle.textContent = `${currentReseller.reseller_name} Portal`;
        }
      } catch (err) {
        console.error('Error updating page metadata:', err);
      }
    }
  }, [currentReseller]);

  const baseClass = 'campaign-login-form-container';

  const FormItems = () => (
    <>
      {debrand && <ResellerImage debrand={debrand} resellerCode={resellerCode} />}

      <Form.Item className={`${baseClass}__heading`}>
        <h2>Welcome Back</h2>
        <span>Log in to view and manage your campaign</span>
      </Form.Item>
      <Form.Item
        label='Username'
        name='username'
        className={`${baseClass}__input-item`}
        rules={[
          {
            required: true,
            message: 'Username is required!',
          },
        ]}
      >
        <Input placeholder='Username' />
      </Form.Item>
      <Form.Item
        className={`${baseClass}__password-input-item`}
        name='password'
        label='Password'
        rules={[
          {
            required: true,
            message: 'Password is required!',
          },
        ]}
      >
        <Input.Password placeholder='Password' type={debrand ? 'text' : 'password'} />
      </Form.Item>

      <Form.Item>
        <Button loading={mutation.status === 'pending'} htmlType='submit' className={`${baseClass}__submit-button`}>
          Submit
        </Button>
      </Form.Item>
      {mutation.isError && (
        <Form.Item className={`${baseClass}__error-response`}>
          <Alert message={mutation.error?.message} type='error' />
        </Form.Item>
      )}

      {debrand ? (
        ''
      ) : (
        <Form.Item className={`${baseClass}__footer`}>
          <span className='register-now'>
            Don't have an account? <Link to='/register'>Register Now</Link>
          </span>
          <br />
          <span className='forgot-password'>
            <Link to='/forgot-password'>Forgot Password?</Link>
          </span>
        </Form.Item>
      )}

      {debrand && (currentReseller?.privacyPolicyURL || currentReseller?.termsOfUseURL) && (
        <div className=' text-sm text-center'>
          Please check our{' '}
          {debrand && currentReseller?.privacyPolicyURL && (
            <Link to={currentReseller?.privacyPolicyURL} className='text-blue-600 font-medium hover:font-semibold'>
              Privacy Policy
            </Link>
          )}
          {(debrand && currentReseller?.termsOfUseURL) || !debrand ? (
            <>
              {debrand && currentReseller?.privacyPolicyURL && currentReseller?.termsOfUseURL && ' & '}
              {debrand && currentReseller?.termsOfUseURL && (
                <Link to={currentReseller?.termsOfUseURL} className='text-blue-600 font-medium hover:font-semibold'>
                  Terms of Use
                </Link>
              )}
            </>
          ) : null}
        </div>
      )}
    </>
  );

  return (
    <PublicLayout debrand={true} items={[]}>
      <LoginForm
        form={form}
        customClassName='!my-auto'
        formItems={<FormItems />}
        handleSubmit={handleSubmit}
        autoComplete={debrand ? 'off' : 'on'}
      />
    </PublicLayout>
  );
};
