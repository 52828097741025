import React from 'react';
import Fade from 'react-reveal/Fade';
import classes from '../RegisterPageV3.module.css';
import { Button, notification } from 'antd';
import TextEditor from '../components/TextEditor/TextEditor';

const Step7A = ({
  callWorkflows,
  setShowCallTransferringModal,
  isCampaign,
  campaignVariables,
  handleCallWorkflowDescriptionChange,
  setCampaignVariables,
  handleCallWorkflowVoiceResponseChange,
  handleCallWorkflowCountryChange,
  countries,
  handleCallWorkflowForwardingNumber,
  removeCallWorkflow,
  addCallWorkflow,
  setCallTransferTemplateModalActive,
  isUsingATemplate,
  systemPhrase,
  businessType,
  reseller_email,
  stepState,
  showCallTransferringModal,
  handleStepTransition,
  stepLoading,
}) => {
  return (
    <>
      <div className={classes.box + ' ' + classes.modal + ' ' + (showCallTransferringModal && classes.active)}>
        <div
          className={classes.close}
          onClick={() => {
            if (
              callWorkflows.some(
                workflow =>
                  !workflow.description ||
                  !workflow.voiceResponse ||
                  !workflow.workflowForwardingNumber ||
                  !workflow.country.name ||
                  workflow.workflowForwardingNumber.length < 6
              )
            ) {
              notification.error({
                message: 'Please fill out all workflows, or remove empty workflows',
                duration: 1,
              });
              return;
            }
            setShowCallTransferringModal(false);
          }}
        >
          &times;
        </div>
        <Fade duration={1000} bottom>
          <h3
            className={classes.heading}
            style={{
              textAlign: 'center',
            }}
          >
            Call Transferring
          </h3>

          <div className={classes.subtitle}>
            Describe when you want your receptionist to transfer calls during a conversation. For example, you can
            program your receptionist to transfer the call to the billing department anytime the caller asks for a
            refund or receipt.
          </div>

          {callWorkflows.map((phoneWorkflow, index) => (
            <div
              key={index}
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginBottom: '1rem',
                border: '2px dashed #ccc',
                borderRadius: '10px',
                padding: '1rem',
              }}
            >
              <label className={classes.label}>Trigger</label>
              <div
                className={classes.subtitle}
                style={{
                  marginBottom: '10px',
                }}
              >
                Describe the scenario in which you would like to transfer the call.
              </div>

              {isCampaign ? (
                <TextEditor
                  html={phoneWorkflow.description}
                  vvariables={campaignVariables}
                  onChange={(value, variables) => {
                    handleCallWorkflowDescriptionChange(
                      {
                        preventDefault: () => {},
                        target: { value },
                      },
                      index
                    );
                    setCampaignVariables(variables);
                  }}
                  placeholder='e.g. Transfer the caller to the billing department. Execute whenever caller asks for a refund or receipt.'
                />
              ) : (
                <textarea
                  style={{
                    marginBottom: '16px',
                    fontFamily: 'Quicksand, sans-serif',
                  }}
                  rows={4}
                  className={classes.input + ' ' + classes.textarea}
                  value={phoneWorkflow.description}
                  required
                  onChange={e => handleCallWorkflowDescriptionChange(e, index)}
                  placeholder='e.g. Transfer the caller to the billing department. Execute whenever caller asks for a refund or receipt.'
                />
              )}

              <label className={classes.label}>Voice Response</label>
              <div
                className={classes.subtitle}
                style={{
                  marginBottom: '10px',
                }}
              >
                Enter the response to be spoken by the phone receptionist right before the call is transferred.
              </div>

              {isCampaign ? (
                <TextEditor
                  html={phoneWorkflow.voiceResponse}
                  vvariables={campaignVariables}
                  onChange={(value, variables) => {
                    handleCallWorkflowVoiceResponseChange(
                      {
                        preventDefault: () => {},
                        target: { value },
                      },
                      index
                    );
                    setCampaignVariables(variables);
                  }}
                  placeholder='e.g. Sounds good, I am transferring you to the billing department right now. Please hold.'
                />
              ) : (
                <textarea
                  style={{
                    marginBottom: '16px',
                    fontFamily: 'Quicksand, sans-serif',
                  }}
                  rows={4}
                  className={classes.input + ' ' + classes.textarea}
                  value={phoneWorkflow.voiceResponse}
                  required
                  onChange={e => handleCallWorkflowVoiceResponseChange(e, index)}
                  placeholder='e.g. Sounds good, I am transferring you to the billing department right now. Please hold.'
                />
              )}
              <label className={classes.label}>Phone Number</label>
              <div
                className={classes.subtitle}
                style={{
                  marginBottom: '10px',
                }}
              >
                Enter the phone number the receptionist should call when this scenario is triggered.
              </div>
              <select
                value={phoneWorkflow.country.name}
                onChange={e => handleCallWorkflowCountryChange(e, index)}
                className={classes.input}
                style={{
                  marginBottom: '16px',
                  width: '100%',
                  maxWidth: 'unset',
                }}
              >
                <option value=''>Select</option>
                {countries.map(c => (
                  <option key={c.name} value={c.name}>
                    {c.name}
                  </option>
                ))}
              </select>

              <input
                className={classes.input}
                style={{
                  marginBottom: '16px',
                  width: '100%',
                  maxWidth: 'unset',
                }}
                type='tel'
                value={phoneWorkflow.workflowForwardingNumber}
                onChange={e => handleCallWorkflowForwardingNumber(e, index)}
                required
                placeholder={phoneWorkflow.country.code}
                autoComplete='username'
              />

              <button
                type='button'
                style={{
                  background: 'rgb(237 35 35)',
                  color: '#fff',
                  border: 'none',
                  height: '36px',
                  borderRadius: '4px',
                  cursor: 'pointer',
                }}
                onClick={e => removeCallWorkflow(e, index)}
              >
                Remove
              </button>
            </div>
          ))}

          <button onClick={addCallWorkflow} className={classes.whiteButton} style={{ maxWidth: '300px' }}>
            + Add Call Transferring Workflow?
          </button>
          <>
            {callWorkflows.length !== 0 && (
              <button
                className={classes.whiteButton + ' ' + classes.flexCentered}
                style={{
                  marginTop: '1rem',
                  width: 'max-content',
                  maxWidth: 'max-content',
                }}
                onClick={event => {
                  event.preventDefault(); // Prevent the button from scrolling up

                  setCallTransferTemplateModalActive(true);
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    style={{
                      strokeWidth: '1.5px',
                      height: '16px',
                      width: '16px',
                      marginRight: '8px',
                    }}
                    viewBox='0 0 24 24'
                    stroke-width='1.5'
                    stroke='currentColor'
                    aria-hidden='true'
                  >
                    <path
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      d='M2.25 12.75V12A2.25 2.25 0 014.5 9.75h15A2.25 2.25 0 0121.75 12v.75m-8.69-6.44l-2.12-2.12a1.5 1.5 0 00-1.061-.44H4.5A2.25 2.25 0 002.25 6v12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9a2.25 2.25 0 00-2.25-2.25h-5.379a1.5 1.5 0 01-1.06-.44z'
                    ></path>
                  </svg>
                  {isUsingATemplate && systemPhrase ? `Using ${businessType} Template` : 'Use a Template'}
                </div>
              </button>
            )}
          </>

          <Button
            type='primary'
            onClick={async () => {
              if (
                callWorkflows.some(
                  workflow =>
                    !workflow.description ||
                    !workflow.voiceResponse ||
                    !workflow.workflowForwardingNumber ||
                    !workflow.country.name ||
                    workflow.workflowForwardingNumber.length < 6
                )
              ) {
                notification.error({
                  message: 'Please fill out all workflows, or remove empty workflows',
                  duration: 1,
                });
                return;
              }
              if (reseller_email) {
                await handleStepTransition(15);
              } else {
                await handleStepTransition(stepState + 1);
              }
              setShowCallTransferringModal(false);
            }}
            className={classes.button + ' ' + classes.centered}
            style={{ marginTop: '2rem' }}
            loading={stepLoading}
          >
            Continue
          </Button>
        </Fade>
      </div>
    </>
  );
};

export default Step7A;
