import { ResellerSubAccountFeatures, User } from '@/types/TAccounts';
import apiClient from '@/utils/apiClient';
import LocalStorageUtility from '@/utils/localStorage';
import posthog from 'posthog-js';

const LOGIN_API_PATH = '/api/v2/receptionist-dashboard/login';
const GET_USER_API_PATH = '/api/v2/receptionist-dashboard/';
const GET_SUGGESTIONS_API_PATH = '/api/v2/receptionist-dashboard/get-suggestions';
const MARK_RESOLVED_SUGGESTIONS_API_PATH = '/api/v2/receptionist-dashboard/mark-resolve-suggestions';
const UPDATE_USER_API_PATH = '/api/v2/receptionist-dashboard/';
const UPDATE_EXTENSION_DIGIT_API_PATH = '/api/v2/receptionist-dashboard/extension-digit';
const UPDATE_PASSWORD_API_PATH = '/api/v2/receptionist-dashboard/password';
const GET_ZAPIER_KEY_PATH = '/api/v2/receptionist-dashboard/zapier-configuration';
const GET_STRIPE_CLIENT_SECRET_PATH = '/api/clients/getCustomerSecret';
const CLIENT_PORTAL_SESSION_PATH = '/stripeCancelOrUpdate';
const GRANDFATHERED_PATH = '/api/clients/price-grandfathered';
const POST_CALL_WEBHOOK_PATH = '/api/v2/receptionist-dashboard/post-call-webhook';
const POST_CALL_MESSAGE_PATH = '/api/v2/receptionist-dashboard/post-call-message';
const UPDATE_USAGE_LIMIT_PATH = '/api/v2/receptionist-dashboard/usage-limit-setting';
const REACTIVATE_CHECKOUT_PATH = '/api/v2/receptionist-dashboard/reactivate/checkout-url';
const CANCEL_SUBSCRIPTION_REASON_PATH = '/api/v2/receptionist-dashboard/cancel-subscription-reason';
const GET_BUSINESS_INFO_FROM_URL = '/api/v2/receptionist-dashboard/get-business-information-from-url';
const DELETE_PRONUNCIATION_PATH = '/api/v2/receptionist-dashboard/pronunciation/delete';
const GENERATE_PRONUNCIATION_PATH = '/api/v2/receptionist-dashboard/pronunciation/generate';
const BILLING_PORTAL_PATH = '/api/v2/receptionist-dashboard/customerPortal/client';
const BILLING_OVERVIEW_PATH = '/api/v2/receptionist-dashboard/billing-overview';
const GET_SIGNEDURL_API_PATH = '/api/v2/receptionist-dashboard/generate-presigned';
const WATERMARK_REMOVAL_CHECKOUT_PATH = '/api/v2/receptionist-dashboard/text-watermark-removal-payment';
const APPLY_COUPON_PATH = '/api/v2/receptionist-dashboard/apply-coupon';
const isClientOnTrial = '/api/stripe/isClientOnTrial';
const extendClientTrial = '/api/stripe/extendClientTrial';

const UPDATE_USER_CAMPAIGN_PORTAL_API_PATH = '/api/v2/receptionist-dashboard/by-number/';
const CLIENT_EXISTS_NO_PASSWORD_API_PATH = 'clientExistsNoPassword';
const CREATE_CAMPAIGN_WITH_CREATE_CLIENT_API_PATH = '/createClientV2';
const CREATE_RECEPTIONIST_CLIENT = '/createClient';
const REMOVE_DISCOUNT_API_PATH = '/api/v2/receptionist-dashboard/remove-discount';
const RECEPTIONIST_V2_CAREATE_CLIENT = '/api/v2/receptionist-dashboard/create-client';

const GENERATE_OAUTH_TOKENS_PATH = (provider: string) => `/api/v2/receptionist-dashboard/oauth/tokens/${provider}`;

const API_BASE = '/api/v2/receptionist-dashboard';

export const GENERATE_KNOWLEDGE_BASE_API = `${API_BASE}/generate-knowledge-base`;
export const UPDATE_KNOWLEDGE_BASE_ACTIVATION_API = `${API_BASE}/update-knowledge-base-activation`;
export const DELETE_KNOWLEDGE_BASE_API = `${API_BASE}/delete-knowledge-base`;
export const MANUAL_SYNC_KNOWLEDGE_BASE_API = `${API_BASE}/manual-sync-knowledge-base`;
export const UPDATE_KNOWLEDGE_BASE_CONTENT_API = `${API_BASE}/update-content-knowledge-base`;
export const RESELLER_CLIENT_FEATURES = `${API_BASE}/reseller-client-features`;

export const createClient = async (payload: any): Promise<any> => {
  try {
    const response = await apiClient.request({
      method: 'PUT',
      endPoint: RECEPTIONIST_V2_CAREATE_CLIENT,
      body: payload,
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};

export const loginApi = async (payload: {
  username: string;
  password: string;
}): Promise<{ demo: boolean; token: string; email: string; number: string }> => {
  try {
    const response = await apiClient.request({
      method: 'POST',
      endPoint: LOGIN_API_PATH,
      body: payload,
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};

export const getUser = async (): Promise<User> => {
  try {
    const response = await apiClient.request({
      method: 'GET',
      endPoint: GET_USER_API_PATH,
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};
export const generateSuggestions = async (): Promise<User> => {
  try {
    const response = await apiClient.request({
      method: 'GET',
      endPoint: GET_SUGGESTIONS_API_PATH,
    });

    return response?.suggestions;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};
export const markSuggestionResolved = async ({ suggestionId }: { suggestionId: string }): Promise<any> => {
  try {
    const response = await apiClient.request({
      method: 'DELETE',
      endPoint: MARK_RESOLVED_SUGGESTIONS_API_PATH,
      body: { suggestionId },
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};

export const updateUser = async (payload: any): Promise<{}> => {
  try {
    const response = await apiClient.request({
      method: 'PUT',
      endPoint: UPDATE_USER_API_PATH,
      body: payload,
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};

//TODO: Need to enhance this as per the requirement, for now it is just a basic implementation
export const logout = () => {
  LocalStorageUtility.clearLocalData();
  window.location.reload();
  posthog.reset();
};

export const getZapierKey = async ({
  isDebrand,
}: {
  isDebrand: boolean;
}): Promise<{
  apiKey: string;
  inviteUrl: string;
  username: string;
}> => {
  try {
    const response = await apiClient.request({
      method: 'GET',
      endPoint: `${GET_ZAPIER_KEY_PATH}/${isDebrand}`,
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};

export const updateStepsCompleted = async (payload: {
  stepNumber: number;
}): Promise<{ success: boolean; updated_instruction_steps: number[] }> => {
  try {
    const response = await apiClient.request({
      method: 'PUT',
      endPoint: '/api/v2/receptionist-dashboard/steps-completed',
      body: payload,
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};

export const updateMultipleStepsCompleted = async (payload: {
  steps: any[];
}): Promise<{ success: boolean; updated_instruction_steps: number[] }> => {
  try {
    const response = await apiClient.request({
      method: 'PUT',
      endPoint: '/api/v2/receptionist-dashboard/multiple-steps-completed',
      body: payload,
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};

export const updatePendingTestingStatus = async (payload: {
  pendingTesting: boolean;
}): Promise<{ success: boolean; pendingTesting: boolean }> => {
  try {
    const response = await apiClient.request({
      method: 'PUT',
      endPoint: '/api/v2/receptionist-dashboard/update-pending-testing-status',
      body: payload,
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};
export const incrementTotalTestCalls = async (): Promise<{ success: boolean; totalTestCalls: number }> => {
  try {
    const response = await apiClient.request({
      method: 'POST',
      endPoint: '/api/v2/receptionist-dashboard/increment-test-calls',
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};

export const updateExtensionDigit = async (payload: {
  extension_digits: { phoneNumber: string; extension: string }[];
}): Promise<{}> => {
  try {
    const response = await apiClient.request({
      method: 'PUT',
      endPoint: UPDATE_EXTENSION_DIGIT_API_PATH,
      body: payload,
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};

export const updatePassword = async (payload: { oldPassword: string; newPassword: string }): Promise<{}> => {
  try {
    const response = await apiClient.request({
      method: 'PUT',
      endPoint: UPDATE_PASSWORD_API_PATH,
      body: payload,
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};

export const getStripeClientSecret = async (payload: {
  username: string;
}): Promise<{ success: boolean; customerSecret?: string }> => {
  try {
    const response = await apiClient.request({
      method: 'POST',
      endPoint: GET_STRIPE_CLIENT_SECRET_PATH,
      body: payload,
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};

export const createClientPortalSession = async (payload: {
  username: string;
  action: 'subscription_update' | 'payment_method_update' | 'subscription_cancel';
}): Promise<{ status: string; redirectPaymentURL?: string; message?: string; success?: boolean }> => {
  try {
    const response = await apiClient.request({
      method: 'POST',
      endPoint: CLIENT_PORTAL_SESSION_PATH,
      body: payload,
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};

export const userGrandfathered = async (payload: {
  username: string;
}): Promise<{
  success: boolean;
  data: {
    grandfathered: boolean;
    oldAmount?: number;
    newAmount?: number;
    interval?: string;
    name?: string;
  };
}> => {
  try {
    const response = await apiClient.request({
      method: 'POST',
      endPoint: GRANDFATHERED_PATH,
      body: payload,
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};

export const updatePostCallWebhook = async (payload: {
  endpoint: string;
  method: 'POST' | 'PUT' | 'PATCH';
}): Promise<{
  success: boolean;
  data?: {
    endpoint: string;
    method: 'POST' | 'PUT' | 'PATCH';
  };
  message?: string;
}> => {
  try {
    const response = await apiClient.request({
      method: 'PUT',
      endPoint: POST_CALL_WEBHOOK_PATH,
      body: payload,
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};

export const deletePostCallWebhook = async (): Promise<{
  success: boolean;
  message?: string;
}> => {
  try {
    const response = await apiClient.request({
      method: 'DELETE',
      endPoint: POST_CALL_WEBHOOK_PATH,
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};

export const updatePostCallMessage = async (payload: {
  endpoint: string;
  method: 'POST' | 'PUT' | 'PATCH';
}): Promise<{
  success: boolean;
  data?: {
    endpoint: string;
    method: 'POST' | 'PUT' | 'PATCH';
  };
  message?: string;
}> => {
  try {
    const response = await apiClient.request({
      method: 'PUT',
      endPoint: POST_CALL_MESSAGE_PATH,
      body: payload,
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};

export const deletePostCallMessage = async (): Promise<{
  success: boolean;
  message?: string;
}> => {
  try {
    const response = await apiClient.request({
      method: 'DELETE',
      endPoint: POST_CALL_MESSAGE_PATH,
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};

export const updateUsageLimit = async (payload: {
  usage_notification_emails: string[];
  max_minutes: number;
}): Promise<{}> => {
  try {
    const response = await apiClient.request({
      method: 'PUT',
      endPoint: UPDATE_USAGE_LIMIT_PATH,
      body: payload,
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};

export const getReactivateCheckoutURL = async (
  username: string
): Promise<{ success: boolean; data?: { url: string }; message?: string }> => {
  try {
    const response = await apiClient.request({
      method: 'GET',
      endPoint: `${REACTIVATE_CHECKOUT_PATH}/${username}`,
    });

    return response;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const cancelSubReason = async (payload: { reason: string }): Promise<{ success: boolean }> => {
  try {
    const response = await apiClient.request({
      method: 'POST',
      endPoint: CANCEL_SUBSCRIPTION_REASON_PATH,
      body: payload,
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};
export const getBusinessInfoFromUrl = async (payload: {
  url: string;
}): Promise<{ success: boolean; businessName: string; businessInfo: string }> => {
  try {
    const response = await apiClient.request({
      method: 'POST',
      endPoint: GET_BUSINESS_INFO_FROM_URL,
      body: payload,
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};

export const deletePronunciation = async (payload: { word: string }): Promise<{}> => {
  try {
    const response = await apiClient.request({
      method: 'PUT',
      endPoint: DELETE_PRONUNCIATION_PATH,
      body: payload,
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};

export const generatePronunciation = async (payload: {
  word: string;
  phonetic?: string;
}): Promise<{ data?: string; message?: string }> => {
  try {
    const response = await apiClient.request({
      method: 'POST',
      endPoint: GENERATE_PRONUNCIATION_PATH,
      body: payload,
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};

export const doesUserExist = async (payload: {
  usernameOrEmail: string;
}): Promise<{ userExists: boolean; message: string }> => {
  try {
    const response = await apiClient.request({
      method: 'POST',
      endPoint: '/api/v2/receptionist-dashboard/user-exists',
      body: payload,
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};

export const isResetPasswordLinkValid = async (payload: {
  username: string;
  token: string;
}): Promise<{ isValid: boolean }> => {
  try {
    const response = await apiClient.request({
      method: 'POST',
      endPoint: '/api/v2/receptionist-dashboard/reset-password-link-valid',
      body: payload,
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};

export const resetPassword = async (payload: { username: string; password: string }): Promise<{ success: boolean }> => {
  try {
    const response = await apiClient.request({
      method: 'POST',
      endPoint: '/api/v2/receptionist-dashboard/reset-password',
      body: payload,
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};

export const getBillingPortal = async (): Promise<{
  success: boolean;
  data?: { redirectionURL: string };
  error?: string;
}> => {
  try {
    const response = await apiClient.request({
      method: 'POST',
      endPoint: BILLING_PORTAL_PATH,
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};

export const getBillingOverview = async (): Promise<{
  success: boolean;
  data?: {
    basePlan: string;
    basePlanStatus: string;
    remainingFreeMinutes: number;
    currentVoiceUsage: number;
    voiceUsageCharge: number;
    freeMinutes: number;
    transferredCallUsage: number;
    appliedCoupons:
      | {
          id: string;
          name: string;
          amountOff: number | null;
          percentOff: number | null;
          duration: string;
          durationInMonths: number | null;
          validUntil: string | null;
          startedOn: string | null;
          appliedOn: string;
        }[]
      | null;
  };
  message?: string;
}> => {
  try {
    const response = await apiClient.request({
      method: 'GET',
      endPoint: BILLING_OVERVIEW_PATH,
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};

export const textWatermarkRemovalCheckout = async (): Promise<{
  success: boolean;
  data?: { url: string };
  message?: string;
}> => {
  try {
    const response = await apiClient.request({
      method: 'GET',
      endPoint: WATERMARK_REMOVAL_CHECKOUT_PATH,
    });

    return response;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const generateSignedUrl = async ({
  bucketType,
  objectKey,
}: {
  bucketType: string;
  objectKey: string;
}): Promise<{ success: boolean; url: string | undefined }> => {
  try {
    const response = await apiClient.request({
      method: 'POST',
      endPoint: `${GET_SIGNEDURL_API_PATH}/${bucketType}`,
      body: {
        objectKey,
      },
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};

//Novi Portal API

export const ClientExistsNoPassword = async (username: string): Promise<{}> => {
  try {
    const response = await apiClient.request({
      method: 'GET',
      endPoint: CLIENT_EXISTS_NO_PASSWORD_API_PATH,
      body: { username },
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};

export const createCampaignWithCreateClient = async (payload: {
  username: string;
  campaignName: string;
  greetingPhrase: string;
  variables: any;
}): Promise<{}> => {
  try {
    const response = await apiClient.request({
      method: 'POST',
      endPoint: CREATE_CAMPAIGN_WITH_CREATE_CLIENT_API_PATH,
      body: payload,
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};

export const updateUserCampaignPortal = async (payload: any): Promise<{}> => {
  console.log('payload in api', payload);
  try {
    const response = await apiClient.request({
      method: 'PUT',
      endPoint: UPDATE_USER_CAMPAIGN_PORTAL_API_PATH + payload?.username,
      body: payload,
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};

export const GetResellerBrandingImage = async (payload: {
  key: string;
}): Promise<{ success: boolean; data: { image: string } }> => {
  try {
    const response = await apiClient.request({
      method: 'POST',
      endPoint: '/download_reseller_logo',
      body: payload,
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};

// Function to generate knowledge base
export const generateKnowledgeBase = async (payload: FormData): Promise<{}> => {
  try {
    const response = await apiClient.request({
      method: 'POST',
      endPoint: GENERATE_KNOWLEDGE_BASE_API,
      body: payload,
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong with generateKnowledgeBase', err);
    throw err;
  }
};

// Function to update knowledge base activation status
export const updateKnowledgeBaseActivation = async (payload: {
  number: string;
  entryIndex: number;
  isActive: boolean;
}): Promise<{}> => {
  try {
    const response = await apiClient.request({
      method: 'PUT',
      endPoint: UPDATE_KNOWLEDGE_BASE_ACTIVATION_API,
      body: payload,
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong with updateKnowledgeBaseActivation', err);
    throw err;
  }
};

// Function to delete knowledge base entry
export const deleteKnowledgeBase = async (payload: { number: string; entryIndex: number }): Promise<{}> => {
  try {
    const response = await apiClient.request({
      method: 'DELETE',
      endPoint: DELETE_KNOWLEDGE_BASE_API,
      body: payload,
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong with deleteKnowledgeBase', err);
    throw err;
  }
};

// Function to manually sync knowledge base
export const manualSyncKnowledgeBase = async (payload: {
  number: string;
  entryIndex: number;
  entry: string;
}): Promise<{}> => {
  try {
    const response = await apiClient.request({
      method: 'POST',
      endPoint: MANUAL_SYNC_KNOWLEDGE_BASE_API,
      body: payload,
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong with manualSyncKnowledgeBase', err);
    throw err;
  }
};

// Function to update knowledge base content
export const updateKnowledgeBaseContent = async (payload: {
  number: string;
  entryIndex: number;
  content: string;
}): Promise<{}> => {
  try {
    const response = await apiClient.request({
      method: 'POST',
      endPoint: UPDATE_KNOWLEDGE_BASE_CONTENT_API,
      body: payload,
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong with updateKnowledgeBaseContent', err);
    throw err;
  }
};

export const createReceptionistClient = async (
  bodyObject: any
): Promise<{
  success: boolean;
  data: any;
  message?: string;
}> => {
  try {
    const response = await apiClient.request({
      method: 'POST',
      endPoint: CREATE_RECEPTIONIST_CLIENT,
      body: bodyObject,
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong with createReceptionistClient', err);
    throw err;
  }
};

export const generateOAuthIntegrationTokens = async (
  code: any,
  provider: string
): Promise<{
  accessToken: string;
}> => {
  try {
    const response = await apiClient.request({
      method: 'POST',
      endPoint: GENERATE_OAUTH_TOKENS_PATH(provider),
      body: {
        authCode: code,
      },
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong with createReceptionistClient', err);
    throw err;
  }
};

export const updateResellerClientFeatures = async (payload: {
  number: string;
  featuresSubAccount?: ResellerSubAccountFeatures;
}): Promise<{
  success: boolean;
  data: any;
  message?: string;
}> => {
  try {
    const response = await apiClient.request({
      method: 'PUT',
      endPoint: RESELLER_CLIENT_FEATURES,
      body: payload,
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong with updateResellerClientFeatures', err);
    throw err;
  }
};

export const applyCouponCode = async (couponCode: string): Promise<{ success: boolean; message?: string }> => {
  try {
    const response = await apiClient.request({
      method: 'POST',
      endPoint: APPLY_COUPON_PATH,
      body: { couponCode },
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong with applyCouponCode', err);
    throw err;
  }
};

export const isClientOnTrialApi = async (subscriptionId: string): Promise<{ status: string; data: boolean }> => {
  try {
    const response = await apiClient.request({
      method: 'POST',
      endPoint: isClientOnTrial,
      body: {
        subscriptionId: subscriptionId,
      },
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong with isClientOnTrialApi', err);
    throw err;
  }
};

export const extendClientTrialApi = async (
  subscriptionId: string
): Promise<{ status: string; data: any; error?: string }> => {
  try {
    const response = await apiClient.request({
      method: 'POST',
      endPoint: extendClientTrial,
      body: {
        subscriptionId: subscriptionId,
      },
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong with extendClientTrialApi', err);
    throw err;
  }
};

export const removeDiscount = async (payload: {
  appliedOn: string;
}): Promise<{
  success: boolean;
  message?: string;
}> => {
  try {
    const response = await apiClient.request({
      method: 'POST',
      endPoint: REMOVE_DISCOUNT_API_PATH,
      body: payload,
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};
