import React from 'react';
import Fade from 'react-reveal/Fade';
import classes from '../RegisterPageV3.module.css';
import { Button } from 'antd';
import posthog from 'posthog-js';

const Step3 = ({
  isCampaign,
  reseller_email,
  campaign_user_email,
  businessName,
  setBusinessName,
  stepState,
  handleStepTransition,
  step4StateMutation,
  step4Validation,
  stepLoading,
}) => {
  return (
    <>
      <div className={classes.box}>
        <Fade duration={1000} bottom>
          {React.createElement(() => {
            posthog.capture('business_name_entered');
            return null;
          })}
          <h3
            className={classes.heading}
            style={{
              textAlign: 'center',
            }}
          >
            {isCampaign ? 'Campaign Name' : 'Business Name'}
          </h3>
          <div
            className={classes.subtitle}
            style={{
              textAlign: 'center',
            }}
          >
            The name of{' '}
            {reseller_email ? " your client's business" : campaign_user_email ? ' your campaign' : ' your business'}
          </div>

          <input
            value={businessName}
            className={classes.input + ' ' + classes.centered}
            required
            onChange={event => {
              let value = event.target.value;
              setBusinessName(value);
            }}
            placeholder={businessName}
          />

          <Button
            type='primary'
            onClick={() => handleStepTransition(stepState + 1, step4Validation, step4StateMutation)}
            className={classes.button + ' ' + classes.centered}
            style={{ marginTop: '2rem' }}
            loading={stepLoading}
          >
            Continue
          </Button>
        </Fade>
      </div>
    </>
  );
};

export default Step3;
