import { ENoviDashboardSider } from '@/pages/Novi/enums/ENoviDashboardSider';
import { NoviUser, NoviUserState } from '@/pages/Novi/types/NoviUser';
import LocalStorageUtility from '@/utils/localStorage';
import { PayloadAction, Slice, createSlice } from '@reduxjs/toolkit';
import { LocalStorageKeys } from 'src/enums/ELocalStorage';

const initialState: NoviUserState = {
  user: {
    email_address: '',
    business_name: '',
    logo: '',
    twilio_access_token: '',
    twilio_account_id: '',
    is_terms_accepted: false,
    onboardingSteps: [],
    totalCampaigns: 0,
    configuredCampaigns: 0,
    unConfiguredCampaigns: 0,
    subscribedProductId: '',
    subscriptionId: '',
    subscriptionStatus: '',
    voiceSubscriptionId: '',
    voiceSubscriptionStatus: '',
    subCancelOn: '',
  },
  currentPage: ENoviDashboardSider.MY_CAMPAIGNS,
  crmGroups: null,
  selectedCampaignId: null,
  selectedCampaign: null,
};

export const slice: Slice<
  NoviUserState,
  {
    cleanState: () => void;
    setNoviUser: (state: NoviUserState, action: PayloadAction<NoviUser>) => void;
    setNoviCurrentPage: (state: NoviUserState, action: PayloadAction<ENoviDashboardSider>) => void;
    setNoviSelectedCampaignId: (state: NoviUserState, action: PayloadAction<string | null>) => void;
    setNoviSelectedCampaign: (state: NoviUserState, action: PayloadAction<any | null>) => void;
    setNoviCRMGroups: (state: NoviUserState, action: PayloadAction<any>) => void;
  },
  'noviUserAccount'
> = createSlice({
  name: 'noviUserAccount',
  initialState,
  reducers: {
    cleanState: () => initialState,
    setNoviUser: (state: NoviUserState, action: PayloadAction<NoviUser>) => {
      state.user = {
        email_address: action.payload.email_address,
        business_name: action.payload.business_name,
        logo: action.payload.logo,
        twilio_access_token: action.payload.twilio_access_token,
        twilio_account_id: action.payload.twilio_account_id,
        is_terms_accepted: action.payload.is_terms_accepted,
        subscribedProductId: action.payload.subscribedProductId,
        subscriptionId: action.payload.subscriptionId,
        subscriptionStatus: action.payload.subscriptionStatus,
        voiceSubscriptionId: action.payload.voiceSubscriptionId,
        voiceSubscriptionStatus: action.payload.voiceSubscriptionStatus,
        totalCampaigns: action.payload.totalCampaigns,
        configuredCampaigns: action.payload.configuredCampaigns,
        unConfiguredCampaigns: action.payload.unConfiguredCampaigns,
        onboardingSteps: action.payload.onboardingSteps,
        subCancelOn: action.payload.subCancelOn,
      };
      LocalStorageUtility.setLocalData(LocalStorageKeys.NOVI_USER, action.payload);
    },
    setNoviCurrentPage: (state: NoviUserState, action: PayloadAction<ENoviDashboardSider>) => {
      state.currentPage = action.payload;
    },
    setNoviSelectedCampaignId: (state: NoviUserState, action: PayloadAction<string | null>) => {
      state.selectedCampaignId = action.payload;
    },
    setNoviSelectedCampaign: (state: NoviUserState, action: PayloadAction<any | null>) => {
      state.selectedCampaign = action.payload;
    },
    setNoviCRMGroups: (state: NoviUserState, action: PayloadAction<any>) => {
      state.crmGroups = action.payload;
    },
  },
});

export const {
  cleanState,
  setNoviUser,
  setNoviCurrentPage,
  setNoviSelectedCampaignId,
  setNoviSelectedCampaign,
  setNoviCRMGroups,
} = slice.actions;

export default slice.reducer;
