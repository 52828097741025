import { Theme } from '@/interfaces/IThemeInterface';
import { AppState } from '@/types/TApp';
import { PayloadAction, Slice, createSlice } from '@reduxjs/toolkit';
import { POSTHOG_FEATURE_FLAGS } from 'src/constants';

export type FeatureFlagsState = {
  [key in keyof typeof POSTHOG_FEATURE_FLAGS]: string; // Ensure each key corresponds to the expected variant type
};

const initialState: AppState = {
  isLoading: false,
  featureFlags: Object.keys(POSTHOG_FEATURE_FLAGS).reduce((acc, key) => {
    const featureKey = key as keyof typeof POSTHOG_FEATURE_FLAGS;
    acc[featureKey] = POSTHOG_FEATURE_FLAGS[featureKey].variants.OLD;
    return acc;
  }, {} as FeatureFlagsState),
};

export const slice: Slice<
  AppState,
  {
    setAppLoading: (state: AppState, action: PayloadAction<boolean>) => void;
    setAppTheme: (state: AppState, action: PayloadAction<Partial<Theme>>) => void;
    setFeatureFlag: (
      state: AppState,
      action: PayloadAction<{ key: keyof typeof POSTHOG_FEATURE_FLAGS; variant: string | boolean }>
    ) => void;
  },
  'app'
> = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setAppLoading: (state: AppState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setAppTheme: (state: AppState, action: PayloadAction<Partial<Theme>>) => {
      state.dashboardTheme = action.payload;
      const root = document.documentElement;
      Object.entries(action.payload).forEach(([key, value]) => {
        const cssKey = key.replace(/([A-Z])/g, '-$1').toLowerCase();
        root.style.setProperty(`--${cssKey}`, value as string);
      });
    },
    setFeatureFlag: (
      state: AppState,
      action: PayloadAction<{ key: keyof typeof POSTHOG_FEATURE_FLAGS; variant: string | boolean }>
    ) => {
      const { key, variant } = action.payload;
      if (POSTHOG_FEATURE_FLAGS[key]) {
        state.featureFlags[key] = variant;
      }
    },
  },
});

export const { setAppLoading, setAppTheme, setFeatureFlag } = slice.actions;
export default slice.reducer;
