import { useEffect, useState } from 'react';
import { Button, Progress, Tooltip } from 'antd';
import { CheckCircleFilled, LockOutlined } from '@ant-design/icons';
import ConfettiExplosion from 'react-confetti-explosion';
import { Playground } from '../ReceptionistDashboard/Playground';
import { motion } from 'framer-motion';
import { useMutation } from '@tanstack/react-query';
import { updateMultipleStepsCompleted, updateStepsCompleted } from '@/api/user';
import { setCurrentPage, setInstructionSteps, updateProvisionedNumber } from '@/store/account/slice';
import { getProvisionedNumber } from '@/api/reservedNumbers';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { getLoggedInUser } from '@/store/account/selector';
import { ReceptionistDashboardSiderItem } from 'src/enums/EReceptionistDashboardSiderItem';
import PlansModal from '@/components/PlansModal/PlansModal';
import { PhoneNumberSettings } from '../ReceptionistDashboard/TestItOut/v2/PhoneNumberSettings';
import ForwardingSetupInstructions from './ForwardingSetupInstructions';
import CopyableText from '@/components/LabelWithdescription/CopyableText';
import { useMobileDeviceCheck } from 'src/common/hooks/useMobile';
import { ArrowLeft } from '@mui/icons-material';
import { LockIcon } from 'lucide-react';

interface GetStartedProps {
  setTourOpened: (opened: boolean) => void;
  currentStep: number;
  setCurrentStep: (step: any) => void;
  openDrawer: any;
  isReceptionistLoading?: boolean;
}

const GetStarted = ({
  setTourOpened,
  currentStep,
  setCurrentStep,
  openDrawer,
  isReceptionistLoading,
}: GetStartedProps) => {
  const [confettiTriggered, setConfettiTriggered] = useState(false);
  const loggedInUser = useAppSelector(getLoggedInUser);
  const [provisionedNumber, setProvisionedNumber] = useState<string>('');
  const [isCompleted, setIsCompleted] = useState(loggedInUser.instruction_steps?.length === 7 || false);
  const [tourLaunched, setTourLaunched] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { isMobile } = useMobileDeviceCheck();

  const dispatch = useDispatch();

  const getProvisionNumberMutation = useMutation({
    mutationKey: ['provisionedNumberApi'],
    mutationFn: getProvisionedNumber,
    onSuccess(data) {
      setProvisionedNumber(data.provisioned_number || '');
      dispatch(updateProvisionedNumber({ provisionedNumber: data.provisioned_number || '' }));
    },
  });

  useEffect(() => {
    getProvisionNumberMutation.mutate();
  }, [loggedInUser.number]);

  const MultipleStepsUpdateMutation = useMutation({
    mutationKey: ['updateMultipleStepsCompleted'],
    mutationFn: updateMultipleStepsCompleted,
    onMutate: () => {
      setIsLoading(true);
    },
    onSettled: () => {
      setIsLoading(false);
    },
  });

  const UpdateStepsDoneMutation = useMutation({
    mutationKey: ['updateStepsDone'],
    mutationFn: updateStepsCompleted,
    onMutate: () => {
      setIsLoading(true);
    },
    onSuccess(data: { success: boolean; updated_instruction_steps: number[] }) {
      dispatch(setInstructionSteps({ instructionSteps: data.updated_instruction_steps }));
    },
    onSettled: () => {
      setIsLoading(false);
    },
  });

  const updateStepsDone = async (stepNumber: number) => {
    if (loggedInUser.instruction_steps?.includes(stepNumber)) return;
    setCurrentStep(stepNumber + 1);
    UpdateStepsDoneMutation.mutate({ stepNumber });
  };

  const handleLaunchTour = () => {
    if (isMobile) {
      openDrawer();
    }
    setTourLaunched(true);
    setTourOpened(true);
  };

  const handleNext = () => {
    switch (currentStep) {
      case 6:
        setConfettiTriggered(true);
        setCurrentStep(7);
        MultipleStepsUpdateMutation.mutate({ steps: [5] });
        break;

      case 7:
        setConfettiTriggered(true);
        setIsCompleted(true);
        MultipleStepsUpdateMutation.mutate({ steps: [6] });
        break;

      default:
        if (currentStep < steps.length) {
          setCurrentStep((prev: number) => prev + 1);
          updateStepsDone(currentStep);
        } else if (currentStep === steps.length) {
          setIsCompleted(true);
          setCurrentStep((prev: number) => prev + 1);
          updateStepsDone(currentStep);
        }
        break;
    }
  };

  const isOptionalStep = currentStep === 5;
  const isAutomatedStep = currentStep === 4 && loggedInUser.demo;
  const softNext = loggedInUser.instruction_steps?.includes(currentStep);

  const skipAndContinue = () => {
    setCurrentStep(currentStep + 1);
  };

  const getProgressDescription = (currentStep: number, totalSteps: number) => {
    const progress = (currentStep / totalSteps) * 100;

    if (progress === 0) return "Let's get started with your setup!";
    if (progress <= 25) return 'Great start! Taking your first steps...';
    if (progress <= 50) return 'Making good progress! Keep going...';
    if (progress <= 75) return 'Well done! Almost there...';
    if (progress < 100) return "Final steps! You're nearly finished...";
    return 'Setup complete! Your AI assistant is ready!';
  };

  const stepsData = [
    {
      title: 'Welcome Aboard!',
      description: "Welcome aboard! Let's get you set up and make your business live with AI. 💪",
    },
    {
      title: "Let's take a 30 second tour of AI Front Desk",
      stepTitle: 'Take the Platform Tour',
      description: (
        <div>
          <p className='text-lg text-gray-900 mb-6 text-center'>
            Explore all the powerful features of our platform. Ready to dive in?
          </p>
          <div className='relative flex items-center justify-center'>
            <span className='animate-ping absolute inline-flex h-16 w-16 rounded-full bg-blue-400 opacity-75'></span>
            <Button
              className='bg-gradient-to-r py-6 from-blue-500 to-blue-700 text-white font-bold text-lg px-8 rounded-xl shadow-lg hover:from-blue-600 hover:to-blue-800 transform hover:scale-105 transition-transform duration-300 relative'
              onClick={handleLaunchTour}
            >
              Launch Tour 🚀
            </Button>
          </div>
        </div>
      ),
    },
    {
      title: "Let's Make your AI Receptionist Shine",
      stepTitle: 'Explore Your Dashboard',
      description: (
        <div className='flex flex-col items-center space-y-6'>
          <p className='text-lg text-gray-900 mb-6'>
            Your AI is already great at handling calls. Let's make it even better. 💪
          </p>
          <div className='w-full grid grid-cols-1 md:grid-cols-2 gap-6 mt-4'>
            <div className='flex flex-col items-start justify-start border p-6 bg-white rounded-lg hover:shadow-xl transition-shadow duration-300'>
              <h3 className='text-xl font-semibold text-black mb-2'>🎙️ Try New Voices</h3>
              <p className='text-sm text-gray-600 text-start'>
                Find the perfect voice for your assistant and match it to your brand!
              </p>
              <Button
                className='bg-gradient-to-r py-6 from-blue-500 to-blue-700 text-white font-bold sm:text-base w-full sm:w-[unset] text-xs px-8 rounded-xl hover:from-blue-600 hover:to-blue-800 relative'
                onClick={() => {
                  dispatch(setCurrentPage(ReceptionistDashboardSiderItem.VOICE_LIBRARY));
                }}
              >
                {isMobile ? 'Explore Voices' : "Change Your Receptionist's Voice"}
              </Button>
            </div>

            <div className='flex flex-col items-start justify-start p-6 border bg-white rounded-lg hover:shadow-xl transition-shadow duration-300'>
              <h3 className='text-xl font-semibold text-black mb-2'>⚙️ Tweak your Receptionist</h3>
              <p className='text-sm text-gray-600 text-start'>
                Adjust your assistant's features to suit your business needs perfectly.
              </p>
              <Button
                className='bg-gradient-to-r w-full text-xs sm:w-[unset] sm:text-base py-6 from-blue-500 to-blue-700 text-white font-bold px-8 rounded-xl hover:from-blue-600 hover:to-blue-800 relative'
                onClick={() => {
                  dispatch(setCurrentPage(ReceptionistDashboardSiderItem.CONFIGURE));
                }}
              >
                Train Your Receptionist
              </Button>
            </div>

            <div className='flex flex-col items-start justify-start p-6 border bg-white rounded-lg hover:shadow-xl transition-shadow duration-300'>
              <h3 className='text-xl font-semibold text-black mb-2'>📞 Call Your Receptionist</h3>
              <p className='text-sm text-gray-600 text-start'>
                Experience your assistant in action with a live call test.
              </p>
              <Button
                className='bg-gradient-to-r py-6 from-blue-500 to-blue-700 text-white font-bold w-full text-xs sm:w-[unset] sm:text-base  px-8 rounded-xl hover:from-blue-600 hover:to-blue-800 relative'
                onClick={() => {
                  dispatch(setCurrentPage(ReceptionistDashboardSiderItem.PLAYGROUND));
                }}
              >
                Call Your Receptionist
              </Button>
            </div>

            <div className='flex flex-col items-start justify-start p-6 border bg-white rounded-lg hover:shadow-xl transition-shadow duration-300'>
              <h3 className='text-xl font-semibold text-black mb-2'> 📊 Explore your new CRM</h3>
              <p className='text-sm text-gray-600 text-start'>
                Manage customer interactions and grow your business with ease.
              </p>
              <Button
                className='bg-gradient-to-r py-6 from-blue-500 to-blue-700 text-white font-bold w-full text-xs sm:w-[unset] sm:text-base  px-8 rounded-xl hover:from-blue-600 hover:to-blue-800 relative'
                onClick={() => {
                  dispatch(setCurrentPage(ReceptionistDashboardSiderItem.CRM));
                }}
              >
                Explore CRM
              </Button>
            </div>
          </div>
        </div>
      ),
    },

    {
      title: "Let's Start By Testing your AI Receptionist",
      stepTitle: 'Test your AI Receptionist',
      description: (
        <>
          <p className='text-lg text-gray-900 mb-6'>
            Test your receptionist and see how it handles tasks. 👩‍💻 Let's make sure everything is running smoothly!
          </p>
          <div className='rounded-lg p-4'>
            <Playground showCardsOnly debrand />
          </div>
        </>
      ),
    },
    {
      title: '🎉 Start Your 7-Day Free Trial',
      description: (
        <div>
          <p className='text-lg text-gray-900 mb-6'>
            {loggedInUser?.demo
              ? 'Start your 7-day free trial and explore all the premium features today. 🚀'
              : 'Your trial is already started!'}
          </p>

          {(loggedInUser?.subscriptionStatus === 'canceled' ||
            loggedInUser?.subscriptionStatus === undefined ||
            loggedInUser?.subscriptionStatus === null) && (
            <PlansModal isComponentMode username={loggedInUser.number || ''} />
          )}
        </div>
      ),
    },
    {
      title: '📞 (Optional) Book an Onboarding Call',
      description: (
        <div className='space-y-6'>
          <div className='bg-gradient-to-r from-blue-50 to-indigo-50 p-6 rounded-lg border border-blue-100'>
            <div className='flex items-center justify-center space-x-2 mb-4'>
              <span className='text-lg line-through text-gray-400'>$300</span>
              <span className='sm:text-2xl text-xl font-bold text-green-600'>FREE</span>
              <span className='px-3 py-1 bg-green-100 text-green-700 text-sm font-medium rounded-full'>
                Limited Time
              </span>
            </div>
            <p className='text-gray-600 text-center'>
              Book a personalized onboarding call with our team to get started quickly and make the most of your AI
              assistant.
            </p>
          </div>

          <div className='h-96'>
            <iframe
              src='https://calendly.com/d/cmq2-bzn-bth/ai-front-desk-onboarding-setup-help'
              title='Calendly'
              className='w-full h-full mb-4 rounded-lg shadow-sm'
            />
          </div>
        </div>
      ),
    },
    {
      title: provisionedNumber ? 'Congrats 🎉 Your AI number is ready!' : 'Get Your Exclusive AI Number',
      description: (
        <>
          <p className='text-lg text-gray-900 mb-6'>
            Your exclusive AI number is {provisionedNumber ? 'ready' : 'waiting'}! This will help you interact with your
            AI-powered assistant. {provisionedNumber ? '' : "Let's activate it! ✨"}
          </p>
          <PhoneNumberSettings renderCentralComponent debrand setExtProvisionedNumber={setProvisionedNumber} />
        </>
      ),
    },
    {
      title: '(Optional) Connect your Business',
      description: (
        <>
          <p className='text-lg text-gray-900 mb-6'>
            Awesome! You've completed all the necessary steps, now your business is live with your very own AI
            receptionist. 🚀
          </p>
          <Tooltip title='Your AI Phone Number.'>
            <h1 className='sm:text-4xl text-2xl font-bold text-button-success mb-2'>
              📞
              <CopyableText text={provisionedNumber} />
            </h1>
          </Tooltip>
          <ForwardingSetupInstructions />
        </>
      ),
    },
  ];

  const steps = stepsData.map((step, index) => {
    let completed = index < currentStep;
    if (currentStep === 7 && index === 7) completed = true;
    return {
      ...step,
      completed,
    };
  });

  const stepPercentage = Math.ceil((currentStep / (steps.length - 1)) * 100);

  const disableMarkAsCompleteTour = currentStep === 1 && !tourLaunched && !loggedInUser.instruction_steps?.includes(1);
  const disableMarkAsCompleteGetAINumber = currentStep === 6 && !provisionedNumber;
  const renderStepper = () => {
    if (isMobile) {
      return (
        <div className='flex flex-col items-center'>
          <div className='flex justify-center mb-4 space-x-2'>
            {steps.map((_, index) => (
              <div
                key={index}
                className={`w-8 h-8 flex items-center justify-center rounded-full text-white ${
                  index < currentStep ? 'bg-green-500' : index === currentStep ? 'bg-blue-500' : 'bg-gray-300'
                }`}
              >
                {index < currentStep ? <CheckCircleFilled /> : index + 1}
              </div>
            ))}
          </div>
          <p className='text-sm text-gray-600'>{getProgressDescription(currentStep, steps.length - 1)}</p>
        </div>
      );
    }

    return (
      <div className='w-1/4 bg-gray-50 border-r overflow-y-auto rounded-lg h-fit shadow-lg border'>
        <header className='p-4 flex items-center justify-between'>
          <div className='flex flex-col items-start'>
            <h2 className='text-xl font-bold'>Getting Started 🚀</h2>
            <p>Takes five minutes to go live!</p>
          </div>
          <Progress
            type='circle'
            percent={stepPercentage}
            width={50}
            strokeColor={stepPercentage > 80 ? 'green' : stepPercentage > 50 ? 'yellow' : 'red'}
          />
        </header>
        <div className='p-4'>
          {steps.map((step, index) => (
            <div
              key={index}
              className={`flex items-center p-2 my-2 space-x-4 rounded-lg ${
                index === currentStep ? 'bg-blue-100 border-button-success border' : 'hover:bg-gray-100'
              }`}
            >
              <div
                className={`flex items-center justify-center w-8 h-8 rounded-full ${
                  index < currentStep ? 'bg-green-500 text-white' : 'bg-blue-50 text-blue-500'
                }`}
              >
                {index < currentStep ? <CheckCircleFilled /> : index + 1}
              </div>
              <span
                className={`text-sm font-medium ${index < currentStep ? 'line-through text-gray-500' : 'text-gray-800'}`}
              >
                {step.title}
              </span>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const viewPreviousStep = () => {
    if (currentStep > 0) {
      setCurrentStep((prev: number) => prev - 1);
      setIsCompleted(false);
    }
  };

  const getButtonText = () => {
    if (loggedInUser.instruction_steps?.includes(currentStep)) {
      return 'Next';
    }

    if (currentStep === steps.length - 1) {
      return 'Finish';
    }

    if (isOptionalStep) {
      return 'Okay, Continue.';
    }

    return 'Mark as Completed';
  };

  return (
    <div
      className={`flex w-full relative ${isMobile ? 'flex-col items-center justify-center space-y-2' : 'space-x-10'}`}
    >
      {confettiTriggered && <ConfettiExplosion />}
      {/* Sidebar */}
      {!isMobile ? (
        <div className='w-1/4 bg-gray-50 border-r overflow-y-auto rounded-lg h-fit shadow-lg border'>
          <header className='p-4 flex items-center justify-between'>
            <div className='flex flex-col items-start'>
              <h2 className='text-xl font-bold'>Getting Started 🚀</h2>
              <p className='text-sm text-gray-600'>{getProgressDescription(currentStep, steps.length - 1)}</p>
            </div>
            <Progress
              type='circle'
              percent={stepPercentage}
              width={50}
              strokeColor={stepPercentage > 80 ? 'green' : stepPercentage > 50 ? 'yellow' : 'red'}
            />
          </header>
          <div className='p-4'>
            {steps.map((step, index) => {
              const isStepCompleted = loggedInUser.instruction_steps?.includes(index);
              const nextIncompleteStep = steps.findIndex((_, i) => !loggedInUser.instruction_steps?.includes(i));
              const isClickable = isStepCompleted || index === nextIncompleteStep;

              return (
                <div
                  key={index}
                  onClick={() => (isClickable ? setCurrentStep(index) : null)}
                  className={`flex items-center p-2 my-2 space-x-4 rounded-lg transition-all duration-200
        ${index === currentStep ? 'bg-blue-100 border-button-success border' : 'hover:bg-gray-100'}
        ${isClickable ? 'cursor-pointer' : 'cursor-not-allowed opacity-60'}
      `}
                >
                  <div
                    className={`flex items-center justify-center w-8 h-8 rounded-full transition-colors
          ${isStepCompleted ? 'bg-button-success text-white' : 'bg-blue-50 text-blue-500'}
        `}
                  >
                    {isStepCompleted ? <CheckCircleFilled /> : <span>{index + 1}</span>}
                  </div>
                  <span
                    className={`text-sm font-medium ${isStepCompleted ? 'line-through text-gray-500' : 'text-gray-800'}`}
                  >
                    {step.stepTitle || step.title}
                  </span>

                  {!isClickable && (
                    <Tooltip title='Complete current step first' placement='right'>
                      <LockIcon className='w-4 h-4 text-gray-400 ml-auto' />
                    </Tooltip>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        renderStepper()
      )}

      {/* Main Content */}
      <div className={`p-6 bg-gray-50 rounded-lg h-fit shadow-lg border ${isMobile ? 'w-full' : 'w-3/4'}`}>
        {isCompleted ? (
          <>
            <div className='text-center space-y-8'>
              <motion.h1
                className='text-4xl font-bold bg-gradient-to-r from-blue-600 via-purple-600 to-pink-600 bg-clip-text text-transparent'
                initial={{ y: -20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.2 }}
              >
                Congratulations! You're ready to thrive 💪
              </motion.h1>
              <motion.p
                className='text-gray-500'
                initial={{ y: -10, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.3 }}
              >
                You've completed all the necessary onboarding steps, you can tweak your AI settings any time as per your
                requirements.
              </motion.p>
              <div className='!my-10'>
                <Tooltip title='Your AI Phone Number.'>
                  <h1 className='text-4xl font-bold text-button-success'>
                    📞
                    <CopyableText text={provisionedNumber} />
                  </h1>
                </Tooltip>
              </div>

              <div className='w-full grid grid-cols-1 md:grid-cols-2 gap-6 mt-4'>
                <div className='flex flex-col items-start justify-start border p-6 bg-white rounded-lg hover:shadow-xl transition-shadow duration-300'>
                  <h3 className='text-xl font-semibold text-black mb-2'>🎙️ Voice Library</h3>
                  <p className='text-sm text-gray-600 text-start'>
                    Choose from a variety of voices and accents to match your brand's personality.
                  </p>
                  <Button
                    className='bg-gradient-to-r py-6 from-blue-500 to-blue-700 text-white font-bold text-base px-8 rounded-xl hover:from-blue-600 hover:to-blue-800 relative'
                    onClick={() => dispatch(setCurrentPage(ReceptionistDashboardSiderItem.VOICE_LIBRARY))}
                  >
                    Go to Voice Library
                  </Button>
                </div>

                <div className='flex flex-col items-start justify-start p-6 border bg-white rounded-lg hover:shadow-xl transition-shadow duration-300'>
                  <h3 className='text-xl font-semibold text-black mb-2'>⚙️ Settings</h3>
                  <p className='text-sm text-gray-600 text-start'>
                    Customize your AI assistant's behavior and communication style.
                  </p>
                  <Button
                    className='bg-gradient-to-r py-6 from-blue-500 to-blue-700 text-white font-bold text-base px-8 rounded-xl hover:from-blue-600 hover:to-blue-800 relative'
                    onClick={() => dispatch(setCurrentPage(ReceptionistDashboardSiderItem.CONFIGURE))}
                  >
                    Open Settings
                  </Button>
                </div>

                <div className='flex flex-col items-start justify-start p-6 border bg-white rounded-lg hover:shadow-xl transition-shadow duration-300'>
                  <h3 className='text-xl font-semibold text-black mb-2'>📊 CRM</h3>
                  <p className='text-sm text-gray-600 text-start'>
                    Track customer interactions and manage leads efficiently.
                  </p>
                  <Button
                    className='bg-gradient-to-r py-6 from-blue-500 to-blue-700 text-white font-bold text-base px-8 rounded-xl hover:from-blue-600 hover:to-blue-800 relative'
                    onClick={() => dispatch(setCurrentPage(ReceptionistDashboardSiderItem.CRM))}
                  >
                    Open CRM
                  </Button>
                </div>

                <div className='flex flex-col items-start justify-start p-6 border bg-white rounded-lg hover:shadow-xl transition-shadow duration-300'>
                  <h3 className='text-xl font-semibold text-black mb-2'>📈 Analytics</h3>
                  <p className='text-sm text-gray-600 text-start'>
                    Monitor performance metrics and track customer engagement.
                  </p>
                  <Button
                    className='bg-gradient-to-r py-6 from-blue-500 to-blue-700 text-white font-bold text-base px-8 rounded-xl hover:from-blue-600 hover:to-blue-800 relative'
                    onClick={() => dispatch(setCurrentPage(ReceptionistDashboardSiderItem.ANALYTICS))}
                  >
                    View Analytics
                  </Button>
                </div>
              </div>
              {currentStep > 1 && (
                <Button
                  onClick={viewPreviousStep}
                  className='text-gray-600 hover:text-gray-800 border border-gray-300 rounded-lg sm:text-base text-sm sm:p-4 sm:py-6 p-2 py-6'
                  icon={<ArrowLeft />}
                >
                  Go back
                </Button>
              )}
            </div>
          </>
        ) : (
          <>
            {' '}
            <div>
              <div className='text-center space-y-4'>
                <motion.h1
                  className='text-4xl font-bold bg-gradient-to-r from-blue-600 via-purple-600 to-pink-600 bg-clip-text text-transparent'
                  initial={{ y: -20, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ delay: 0.2 }}
                >
                  {steps[currentStep]?.title}
                </motion.h1>
                <motion.p
                  className='text-gray-500'
                  initial={{ y: -10, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ delay: 0.3 }}
                >
                  {steps[currentStep]?.description}
                </motion.p>
              </div>
            </div>
            <div className='mt-8 flex justify-between sm:space-x-4 flex-row-reverse sm:flex-row'>
              <Button
                type='primary'
                onClick={isAutomatedStep ? undefined : isOptionalStep || softNext ? skipAndContinue : handleNext}
                disabled={
                  isAutomatedStep ||
                  disableMarkAsCompleteTour ||
                  disableMarkAsCompleteGetAINumber ||
                  isLoading ||
                  isReceptionistLoading
                }
                className={`bg-blue-500 text-white rounded-lg sm:text-base text-sm sm:p-4 sm:py-6 p-2 py-6 ${
                  !(currentStep > 1) && isMobile ? 'w-full' : ''
                }`}
              >
                {getButtonText()}
                {isAutomatedStep ? <LockOutlined /> : <CheckCircleFilled />}
              </Button>
              {currentStep > 1 && (
                <Button
                  onClick={viewPreviousStep}
                  className='text-gray-600 hover:text-gray-800 border border-gray-300 rounded-lg sm:text-base text-sm sm:p-4 sm:py-6 p-2 py-6'
                  icon={<ArrowLeft />}
                >
                  Go back
                </Button>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default GetStarted;
