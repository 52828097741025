import { FC, useEffect, useState } from 'react';
import momentTimeZone from 'moment-timezone';
import { DatePicker, Select, Spin, Alert } from 'antd';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { getLoggedInUser } from '@/store/account/selector';

import { getTextLogsAnalytics, getWeeklyUsageData, getYearlyUsageData } from '@/api/analytics';
import { WeeklyCallLogs } from './Charts/WeeklyCallLogs';
import { HourlyCallLogs } from './Charts/HourlyCalLogs';
import { AllCallLogs } from './Charts/AllCallLogs';
import { WeeklyCallDuration } from './Charts/WeeklyCallDuration';
import { AllTextLogs } from './Charts/AllTextLogs';
import { updateUserTimeZone } from '@/api/logs';
import { setUserTimeZone } from '@/store/account/slice';

import './Analytics.scss';
import { getShortURLAnalytics } from '@/api/shortURL';
import { ShortUrl } from './Table/ShortUrl';
import { useLocalTimezone } from 'src/common/hooks/useLocalTimezone';
import { CalendarOutlined } from '@ant-design/icons';
import { AnalyticsCard } from './AnalyticsCards';
import dayjs, { Dayjs } from 'dayjs';
import LearnMore from '@/components/Buttons/LearnMore';
import { TUTORIALS } from 'src/enums/ETutorials';

const timeZoneList = momentTimeZone.tz.names();

const EmptyState = ({ message }: { message: string }) => (
  <div className='flex flex-col items-center justify-center h-64 text-gray-500'>
    <CalendarOutlined className='text-4xl' />
    <p className='text-center'>{message}</p>
  </div>
);

const IsLoadingState = () => (
  <div className='flex flex-col items-center justify-center h-64'>
    <Spin />
  </div>
);

export const Analytics: FC<{ debrand: boolean }> = ({ debrand }) => {
  const loggedInUser = useAppSelector(getLoggedInUser);
  const dispatch = useDispatch();
  const { RangePicker } = DatePicker;

  const { timeZone, setTimeZone } = useLocalTimezone(loggedInUser?.time_zone);
  const [dateRange, setDateRange] = useState<[number, number]>([
    dayjs().startOf('week').valueOf(),
    dayjs().endOf('week').valueOf(),
  ]);

  const updateTimeZoneMutation = useMutation({
    mutationKey: ['updateTimeZone'],
    mutationFn: updateUserTimeZone,
    onSuccess(_, values) {
      dispatch(setUserTimeZone({ timeZone: values.timeZone }));
    },
  });

  const {
    data: weeklyData,
    refetch: refetchWeekly,
    isLoading: isLoadingWeekly,
    isRefetching,
  } = useQuery({
    queryKey: ['weeklyUsageData'],
    queryFn: () =>
      getWeeklyUsageData({
        startDate: dateRange[0],
        endDate: dateRange[1],
      }),
  });

  const { data: yearlyData, isLoading: isLoadingYearly } = useQuery({
    queryKey: ['yearlyUsageData'],
    queryFn: getYearlyUsageData,
  });

  const { data: textLogsData, isLoading: isLoadingTextLogs } = useQuery({
    queryKey: ['textLogsAnalytics'],
    queryFn: () => getTextLogsAnalytics(),
  });

  const { data: shortUrlData, isLoading: isLoadingShortUrl } = useQuery({
    queryKey: ['shortURLAnalytics', loggedInUser.number],
    queryFn: () => getShortURLAnalytics(loggedInUser.number as string),
  });

  const handleDateRangeChange = (dates: [Dayjs | null, Dayjs | null] | null) => {
    if (dates && dates[0] && dates[1]) {
      setDateRange([dates[0].startOf('day').valueOf(), dates[1].endOf('day').valueOf()]);
    } else {
      // Reset to current week if cleared
      setDateRange([dayjs().startOf('week').valueOf(), dayjs().endOf('week').valueOf()]);
    }
  };

  const handleTimeZoneChange = (value: string) => {
    setTimeZone(value);
    updateTimeZoneMutation.mutate({ timeZone: value });
  };

  useEffect(() => {
    refetchWeekly();
  }, [dateRange]);

  const baseClass = 'analytics';
  return (
    <div className={baseClass}>
      <div className={`${baseClass}_header gap-4`}>
        <h1>Analytics</h1>
        {!debrand && <LearnMore tutorialId={TUTORIALS.ANALYTICS} />}
      </div>
      <Alert className='overview-info' closable description='Get all your insights on Analytics Dashboard! 👇' />
      <div className='mt-8'>
        <AnalyticsCard
          title='Links Clicked'
          description='If your AI sends links through the texting workflows feature and you have short URLs enabled, we track clicks and show open rates here.'
        >
          {isLoadingShortUrl ? (
            <IsLoadingState />
          ) : (
            shortUrlData && <ShortUrl analyticsData={shortUrlData?.data} debrand={debrand} />
          )}
        </AnalyticsCard>
      </div>

      <div className={`${baseClass}_input-container`}>
        <RangePicker
          placement='bottomRight'
          allowClear={false}
          onChange={handleDateRangeChange}
          defaultValue={[dayjs().startOf('week'), dayjs().endOf('week')]}
          format='MMM DD YYYY'
        />
        <Select
          value={timeZone}
          onChange={handleTimeZoneChange}
          showSearch
          placeholder='Select a time zone'
          filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
          options={timeZoneList.map(zone => ({ label: zone, value: zone }))}
        />
      </div>
      <div className='grid grid-cols-1 md:grid-cols-2 gap-8'>
        <AnalyticsCard
          title='Call Volume by Week'
          description='Visualize your weekly call trends at a glance. Track the ebb and flow of incoming calls throughout your selected date range.'
        >
          {isLoadingWeekly || isRefetching ? (
            <IsLoadingState />
          ) : weeklyData && weeklyData?.weeklyCallLogs?.length > 0 ? (
            <WeeklyCallLogs analyticsData={weeklyData.weeklyCallLogs} />
          ) : (
            <EmptyState message='No call volume data for the selected date range.' />
          )}
        </AnalyticsCard>
        <AnalyticsCard
          title='Call Distribution by Hour'
          description='Uncover peak calling hours with our intuitive heat map. Identify busy periods and optimize your availability accordingly.'
        >
          {isLoadingWeekly || isRefetching ? (
            <IsLoadingState />
          ) : weeklyData && weeklyData?.hourlyCallLogs?.length > 0 ? (
            <HourlyCallLogs analyticsData={weeklyData.hourlyCallLogs} timeZone={timeZone} />
          ) : (
            <EmptyState message='No hourly distribution data for the selected date range.' />
          )}
        </AnalyticsCard>
      </div>
      <div>
        <AnalyticsCard
          title='Average Call Duration by Week'
          description='Monitor call efficiency over time. Spot trends in call duration to refine your communication strategies and improve customer interactions.'
        >
          {isLoadingWeekly || isRefetching ? (
            <IsLoadingState />
          ) : weeklyData && weeklyData?.callDuration?.length > 0 ? (
            <WeeklyCallDuration analyticsData={weeklyData.callDuration} />
          ) : (
            <EmptyState message='No call duration data for the selected date range.' />
          )}
        </AnalyticsCard>
      </div>
      <div className='mt-8'>
        <AnalyticsCard title='Call Logs' description='Access a comprehensive yearly overview of your call history.'>
          {isLoadingYearly ? (
            <IsLoadingState />
          ) : yearlyData ? (
            <AllCallLogs analyticsData={yearlyData} />
          ) : (
            <EmptyState message='No Call Logs available for the year' />
          )}
        </AnalyticsCard>
      </div>
      <div className='mt-8'>
        <AnalyticsCard
          title='Text Logs'
          description='Explore your annual text communication landscape. Analyze messaging trends and refine your text-based customer interactions.'
        >
          {isLoadingTextLogs ? (
            <IsLoadingState />
          ) : textLogsData && textLogsData.allTextLogs.length > 0 ? (
            <AllTextLogs analyticsData={textLogsData.allTextLogs} />
          ) : (
            <EmptyState message='No Text Logs available for the year' />
          )}
        </AnalyticsCard>
      </div>
    </div>
  );
};
