import { FC } from 'react';
import { Typography, Space, Button } from 'antd';
import { CreditCardOutlined } from '@ant-design/icons';
import { useAppDispatch } from '@/store/hooks/useAppDispatch';
import { closeModal } from '@/store/modal/slice';
import { MODAL_IDS } from 'src/enums/EModal';
import { CustomModal } from '@/components/CustomModal';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { getModalData } from '@/store/modal/selector';

const { Text, Title } = Typography;

interface ModalData {
  message?: string;
  title?: string;
  onRetry?: () => void;
}

export const PaymentFailedModal: FC = () => {
  const dispatch = useAppDispatch();
  const modalData = useAppSelector(getModalData) as ModalData;

  const handleClose = () => {
    dispatch(closeModal());
  };

  const handleRetry = () => {
    if (modalData.onRetry) {
      modalData.onRetry();
      handleClose();
    }
  };

  return (
    <CustomModal width={440} title={null} modalId={MODAL_IDS.PAYMENT_FAILED_MODAL} onClose={handleClose} footer={null}>
      <Space
        direction='vertical'
        size='large'
        style={{
          width: '100%',
          textAlign: 'center',
          padding: '32px 24px',
        }}
      >
        <div
          style={{
            background: '#FFF1F0',
            borderRadius: '50%',
            width: '80px',
            height: '80px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '0 auto',
          }}
        >
          <CreditCardOutlined
            style={{
              fontSize: 40,
              color: '#FF4D4F',
            }}
          />
        </div>

        <Space direction='vertical' size='small'>
          <Title level={4} style={{ margin: 0, fontSize: '24px' }}>
            {modalData?.title || 'Payment Unsuccessful'}
          </Title>

          <Text type='secondary' style={{ fontSize: '16px', display: 'block', marginTop: '8px' }}>
            {modalData?.message || 'We were unable to process your purchase request.'}
          </Text>
        </Space>

        <div style={{ marginTop: '16px' }}>
          <Space size={16}>
            {modalData?.onRetry && (
              <Button
                type='primary'
                size='large'
                onClick={handleRetry}
                style={{
                  minWidth: '120px',
                  height: '44px',
                  fontSize: '16px',
                }}
              >
                Try Again
              </Button>
            )}
            <Button
              onClick={handleClose}
              size='large'
              style={{
                minWidth: '120px',
                height: '44px',
                fontSize: '16px',
              }}
            >
              Cancel
            </Button>
          </Space>
        </div>
      </Space>
    </CustomModal>
  );
};

export default PaymentFailedModal;
