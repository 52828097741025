import { useEffect, forwardRef, useImperativeHandle, useState } from 'react';
import { Form, Input, Select, Button, Card, Space, Typography, Alert, Tabs } from 'antd';
import {
  InfoCircleOutlined,
  MailOutlined,
  PhoneOutlined,
  UserOutlined,
  PlusOutlined,
  DeleteOutlined,
  BuildOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import { CRMGroup, CRMLead } from '@/types/TCRM';

const { Title } = Typography;
const { Option } = Select;
const { TabPane } = Tabs;

const PREDEFINED_FIELDS = [
  { key: 'name', label: 'Full Name', icon: <UserOutlined /> },
  { key: 'company_name', label: 'Company Name', icon: <BuildOutlined /> },
  { key: 'position', label: 'Job Position', icon: <TeamOutlined /> },
  { key: 'website', label: 'Website', icon: <InfoCircleOutlined /> },
];

interface CRMLeadCreatorProps {
  phoneNumber?: string;
  initialData?: CRMLead;
  onSubmit: (leadData: Partial<CRMLead>) => void;
  isLoading: boolean;
  groups: CRMGroup[];
}

export interface CRMLeadCreatorRef {
  resetForm: () => void;
}

const CRMLeadCreator = forwardRef<CRMLeadCreatorRef, CRMLeadCreatorProps>(
  ({ phoneNumber, initialData, onSubmit, isLoading, groups }, ref) => {
    const [form] = Form.useForm();
    const [customFields, setCustomFields] = useState<Array<{ key: string; value: string }>>(
      initialData?.dynamic_fields
        ? Object.entries(initialData.dynamic_fields)
            .filter(([key]) => !PREDEFINED_FIELDS.map(f => f.key).includes(key))
            .map(([key, value]) => ({ key, value }))
        : [{ key: '', value: '' }]
    );

    useEffect(() => {
      if (initialData) {
        const formValues = {
          phoneNumber: initialData?.phone_number,
          email: initialData?.email,
          groupId: initialData.group_id,
          ...PREDEFINED_FIELDS.reduce(
            (acc, field) => ({
              ...acc,
              [field.key]: initialData.dynamic_fields?.[field.key] || '',
            }),
            {}
          ),
        };
        form.setFieldsValue(formValues);
      }
    }, [initialData, form]);

    useImperativeHandle(ref, () => ({
      resetForm: () => {
        form.resetFields();
        setCustomFields([{ key: '', value: '' }]);
      },
    }));

    const handleSubmit = () => {
      form.validateFields().then(values => {
        const predefinedValues = PREDEFINED_FIELDS.reduce(
          (acc, field) => ({
            ...acc,
            [field.key]: values[field.key],
          }),
          {}
        );

        const customValues = customFields.reduce((acc: { [key: string]: string }, field) => {
          if (field.key && field.value) {
            acc[field.key] = field.value;
          }
          return acc;
        }, {});

        const dynamic_fields = {
          ...predefinedValues,
          ...customValues,
        };

        const leadData: Partial<CRMLead> = {
          ...(initialData && { ...initialData }),
          phone_number: values.phoneNumber,
          email: values.email,
          group_id: values.groupId,
          dynamic_fields,
        };

        onSubmit(leadData);
      });
    };

    return (
      <Card className='max-w-2xl mx-auto shadow-sm rounded-lg bg-[#fafafa]'>
        <Title level={4} className='text-center mb-4 flex items-center justify-center gap-2 text-gray-800'>
          <UserOutlined className='text-blue-500' />
          {initialData ? 'Edit Lead' : 'Create New Lead'}
        </Title>

        <Form form={form} layout='vertical' className='space-y-4'>
          <Form.Item
            name='phoneNumber'
            label='Phone Number'
            initialValue={phoneNumber || ''}
            rules={[
              { message: 'Please enter phone number' },
              {
                pattern: /^\+?[\d\s-]+$/,
                message: 'Please enter a valid phone number',
              },
            ]}
          >
            <Input prefix={<PhoneOutlined />} placeholder='+1 234 567 8900' />
          </Form.Item>

          <Form.Item name='email' label='Email' rules={[{ type: 'email', message: 'Please enter a valid email' }]}>
            <Input prefix={<MailOutlined />} placeholder='example@email.com' />
          </Form.Item>

          <Form.Item name='groupId' label='Lead Group' rules={[{ required: true, message: 'Please select a group' }]}>
            <Select placeholder='Select lead group'>
              {groups?.map(group => (
                <Option key={group.group_id} value={group.group_id}>
                  {group.group_name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Tabs defaultActiveKey='1' className='mt-4'>
            <TabPane tab='Standard Fields' key='1'>
              <Alert
                message='Required Information'
                description='These fields are standardized across all leads for consistent data organization.'
                type='info'
                showIcon
                className='mb-4'
              />
              {PREDEFINED_FIELDS.map(field => (
                <Form.Item
                  key={field.key}
                  name={field.key}
                  label={field.label}
                  rules={[
                    {
                      required: field.key === 'name' && !!phoneNumber,
                      validator: (_: any, value: any) => {
                        if (field.key === 'name' && phoneNumber && !value) {
                          return Promise.reject(new Error('Name is required!'));
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Input prefix={field.icon} placeholder={`Enter ${field.label}`} />
                </Form.Item>
              ))}
            </TabPane>

            <TabPane tab='Custom Fields' key='2'>
              <Alert
                message='Additional Information'
                description='Add any custom fields specific to this lead.'
                type='info'
                showIcon
                className='mb-4'
              />
              {customFields.map((field, index) => (
                <Space key={index} className='w-full mb-4' align='baseline'>
                  <Form.Item label='Field Name' className='mb-0 flex-1'>
                    <Input
                      value={field.key}
                      onChange={e => {
                        const newFields = [...customFields];
                        newFields[index].key = e.target.value;
                        setCustomFields(newFields);
                      }}
                      placeholder='e.g., lead_source'
                    />
                  </Form.Item>
                  <Form.Item label='Value' className='mb-0 flex-1'>
                    <Input
                      value={field.value}
                      onChange={e => {
                        const newFields = [...customFields];
                        newFields[index].value = e.target.value;
                        setCustomFields(newFields);
                      }}
                      placeholder='Enter value'
                    />
                  </Form.Item>
                  <Button
                    type='text'
                    danger
                    icon={<DeleteOutlined />}
                    onClick={() => {
                      const newFields = customFields.filter((_, i) => i !== index);
                      setCustomFields(newFields.length ? newFields : [{ key: '', value: '' }]);
                    }}
                  />
                </Space>
              ))}
              <Button
                type='dashed'
                onClick={() => setCustomFields([...customFields, { key: '', value: '' }])}
                block
                icon={<PlusOutlined />}
              >
                Add Custom Field
              </Button>
            </TabPane>
          </Tabs>

          <div className='flex justify-center mt-6'>
            <Button type='primary' onClick={handleSubmit} loading={isLoading} icon={<UserOutlined />}>
              {initialData ? 'Update Lead' : 'Create Lead'}
            </Button>
          </div>
        </Form>
      </Card>
    );
  }
);

export default CRMLeadCreator;
