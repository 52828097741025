import React, { useRef, useState } from 'react';
import { Button, Card, Empty, Space, Typography, Tag, notification, FormInstance, Spin, Alert } from 'antd';
import { MessageOutlined, PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@tanstack/react-query';
import TextConfigureForm from './TextConfigureForm';
import {
  createTextingCampaign,
  deleteATextingCampaign,
  editTextingCampaign,
  getAllTextingCampaigns,
} from '@/api/novi/textingCampaign';
import DOMPurify from 'dompurify';

const { Title, Text } = Typography;

interface Campaign {
  id: string;
  campaign_id: string;
  name: string;
  message: string;
  schedule: {
    type: 'immediate' | 'delayed';
    delay?: {
      value: number;
    };
    customTime?: string;
  };
  created_at: string;
  updated_at: string;
  sent_count: number;
  delivered_count: number;
  failed_count: number;
  is_active: boolean;
}

interface TextScheduleProps {
  clientId: string;
}

const sanitizeAndFormatText = (text: string) => {
  const sanitized = DOMPurify.sanitize(text);
  return sanitized.replace(/\n/g, '<br />');
};

const TextConfigurationInfo: React.FC<TextScheduleProps> = ({ clientId }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editCampaign, setEditCampaign] = useState<any>(null);
  const formRef = useRef<FormInstance>(null);

  const { data, refetch, isLoading } = useQuery({
    queryKey: ['textCampaigns', clientId],
    queryFn: () => getAllTextingCampaigns(clientId),
  });

  const createCampaignMutation = useMutation({
    mutationFn: createTextingCampaign,
    onSuccess: () => {
      setIsEditing(false);
      formRef.current?.resetFields();
      refetch();
      notification.success({
        message: 'Campaign Created',
        description: 'Your campaign has been created successfully.',
      });
    },
    onError: (error: Error) => {
      notification.error({
        message: 'Failed to create campaign',
        description: error.message,
      });
    },
  });

  const deleteCampaignMutation = useMutation({
    mutationFn: (textCampaignId: string) => deleteATextingCampaign(clientId, textCampaignId),
    onSuccess: () => refetch(),
  });

  const editCampaignMutation = useMutation({
    mutationFn: editTextingCampaign,
    onSuccess: () => {
      setIsEditing(false);
      setEditCampaign(null);
      refetch();
      notification.success({
        message: 'Campaign Updated',
        description: 'Your campaign has been updated successfully.',
      });
    },
    onError: (error: Error) => {
      notification.error({
        message: 'Failed to update campaign',
        description: error.message,
      });
    },
  });

  const handleFormSubmit = (values: any) => {
    createCampaignMutation.mutate({
      campaignId: clientId,
      campaignData: values,
    });
  };

  const handleUpdateCampaign = (values: any) => {
    editCampaignMutation.mutate({
      campaignId: clientId,
      textCampaignId: editCampaign.id,
      campaignData: values,
    });
  };

  const handleEditCampaign = (textCampaign: any) => {
    setEditCampaign(textCampaign);
    setIsEditing(true);
  };

  const renderEmptyState = () => (
    <Card bordered={false} className='text-center py-12'>
      <Empty
        image={<MessageOutlined className='text-6xl text-blue-500' />}
        description={
          <Space direction='vertical' size='small'>
            <Title level={5}>No Text Campaign Created</Title>
            <Text type='secondary'>Create a text campaign to send automated messages to your contacts.</Text>
          </Space>
        }
      >
        <Button
          type='primary'
          icon={<PlusOutlined />}
          onClick={() => setIsEditing(true)}
          size='large'
          className='mt-4'
          disabled={!!(data?.length || 0 > 0)}
        >
          Create Text Campaign
        </Button>
      </Empty>
    </Card>
  );

  const renderCampaign = () => (
    <>
      <Alert
        type='info'
        message='Important Notice'
        showIcon
        description={
          <Text>
            Please set up and activate your delivery schedule to begin sending text messages. Messages will only be sent
            once a schedule is configured and enabled.
          </Text>
        }
        className='my-3'
      />
      <Card className='shadow-sm'>
        {data?.map((campaign: Campaign) => (
          <div key={campaign.id} className='space-y-6'>
            <div className='flex justify-between items-start'>
              <Space direction='vertical' size={1}>
                <Title level={4} className='!mb-1'>
                  {campaign.name}
                </Title>
                <Space size={4}>
                  {campaign.schedule && (
                    <Tag color='blue'>
                      {campaign.schedule.type === 'immediate'
                        ? 'Sends Immediately'
                        : `${campaign.schedule.delay?.value} hours delay`}
                    </Tag>
                  )}
                  <Tag color={campaign.is_active ? 'success' : 'error'}>
                    {campaign.is_active ? 'Active' : 'Inactive'}
                  </Tag>
                </Space>
              </Space>

              <Space>
                <Button icon={<EditOutlined />} onClick={() => handleEditCampaign(campaign)} type='primary' ghost>
                  Edit
                </Button>
                <Button
                  type='text'
                  danger
                  icon={<DeleteOutlined />}
                  onClick={() => deleteCampaignMutation.mutate(campaign.id)}
                  loading={deleteCampaignMutation.isPending}
                />
              </Space>
            </div>

            <Card className='bg-gray-50'>
              <div
                className='prose max-w-none'
                dangerouslySetInnerHTML={{
                  __html: sanitizeAndFormatText(campaign.message),
                }}
              />
            </Card>

            <div className='grid grid-cols-3 gap-4'>
              <Card className='text-center bg-gray-50'>
                <Text type='secondary' className='block mb-1'>
                  Total Sent
                </Text>
                <Text strong className='text-lg'>
                  {campaign.sent_count}
                </Text>
              </Card>
              <Card className='text-center bg-gray-50'>
                <Text type='secondary' className='block mb-1'>
                  Delivered
                </Text>
                <Text strong className='text-lg'>
                  {campaign.delivered_count}
                </Text>
              </Card>
              <Card className='text-center bg-gray-50'>
                <Text type='secondary' className='block mb-1'>
                  Failed
                </Text>
                <Text strong className='text-lg'>
                  {campaign.failed_count}
                </Text>
              </Card>
            </div>
          </div>
        ))}
      </Card>
    </>
  );

  if (isEditing || (!data?.length && !editCampaign)) {
    return (
      <div>
        <TextConfigureForm
          ref={formRef}
          mode={editCampaign ? 'edit' : 'create'}
          initialValues={editCampaign}
          onSubmit={handleFormSubmit}
          onEdit={handleUpdateCampaign}
          showCancelBtn={!!data?.length}
          onCancel={() => {
            setIsEditing(false);
            setEditCampaign(null);
          }}
          isLoading={createCampaignMutation.isPending || editCampaignMutation.isPending}
        />
      </div>
    );
  }

  return <Spin spinning={isLoading}>{!data?.length ? renderEmptyState() : renderCampaign()}</Spin>;
};

export default TextConfigurationInfo;
