import { GetVoiceModelsParams, GetVoiceModelsResponse } from '@/interfaces/IVoice';
import { TVoiceModel } from '@/types/TVoiceModel';
import apiClient from '@/utils/apiClient';

const UPDATE_VOICE_MODAL_API_PATH = '/api/v2/receptionist-dashboard/update-voice-model';
const GET_CALL_RECORDING_API_PATH = '/api/v2/receptionist-dashboard/call-recording';
const POST_VOICE_CLONE_API_PATH = '/api/cloned-voices/upload';
const POST_VOICE_API_PATH = '/api/voices/upload';
const POST_CUSTOM_VOICE_API_PATH = '/api/voices/custom/upload';
const GET_VOICE_MODELS_API_PATH = '/api/v2/receptionist-dashboard/voice-models';
const GET_VOICE_MODELS_FILTER = '/api/v2/receptionist-dashboard/voice-filter';
const GET_CLONE_VOICE_MODELS_API_PATH = '/api/v2/receptionist-dashboard/cloned-voices';
const POST_IMAGE_API_PATH = '/api/image/upload';
const UPDATE_VOICE_CONFIG = '/api/v2/receptionist-dashboard/update-config';

export const getCallRecording = async (callSid: string) => {
  const response = await apiClient.request({
    method: 'GET',
    endPoint: `${GET_CALL_RECORDING_API_PATH}/${callSid}`,
    isBlobResponse: true,
  });

  const blob = new Blob([response], { type: 'audio/mpeg' });
  const url = URL.createObjectURL(blob);

  return { callSid, url };
};

export const getVoiceFilters = async (): Promise<{}> => {
  try {
    const response = await apiClient.request({
      method: 'GET',
      endPoint: `${GET_VOICE_MODELS_FILTER}`,
    });

    return response.data;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};

export const updateVoiceModal = async (payload: {
  voiceSelected: string;
  language: string;
}): Promise<{ success: boolean }> => {
  try {
    const response = await apiClient.request({
      method: 'PUT',
      endPoint: UPDATE_VOICE_MODAL_API_PATH,
      body: payload,
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};

export const updateVoiceConfig = async ({
  voiceId,
  voiceConfig,
  addedBy,
}: {
  voiceId: string;
  voiceConfig: any;
  addedBy: string;
}): Promise<{ success: boolean }> => {
  try {
    const response = await apiClient.request({
      method: 'PUT',
      endPoint: `${UPDATE_VOICE_CONFIG}/${voiceId}`,
      body: { voiceConfig: voiceConfig, addedBy: addedBy },
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};

export const uploadVoiceClone = async (payload: FormData): Promise<{ message?: string }> => {
  try {
    const response = await apiClient.request({
      method: 'POST',
      endPoint: POST_VOICE_CLONE_API_PATH,
      body: payload, // Send FormData directly
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};

export const uploadVoice = async (payload: FormData): Promise<{ message?: string }> => {
  try {
    const response = await apiClient.request({
      method: 'POST',
      endPoint: POST_VOICE_API_PATH,
      body: payload, // Send FormData directly
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};

export const getVoiceModels = async ({
  page,
  limit,
  search,
  provider,
  gender,
  language,
  accent,
}: GetVoiceModelsParams): Promise<GetVoiceModelsResponse> => {
  try {
    const queryParams = new URLSearchParams();

    if (page) queryParams.append('page', page.toString());
    if (limit) queryParams.append('limit', limit.toString());
    if (search) queryParams.append('search', encodeURIComponent(search));
    if (provider && provider !== 'All') queryParams.append('provider', provider);
    if (gender && gender !== 'All') queryParams.append('gender', gender);
    if (language && language !== 'All') queryParams.append('language', language);
    if (accent && accent !== 'All') queryParams.append('accent', accent);

    const queryString = queryParams.toString() ? `?${queryParams.toString()}` : '';

    const response = await apiClient.request({
      method: 'GET',
      endPoint: GET_VOICE_MODELS_API_PATH + queryString,
    });
    return response;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};

export const getClonedVoiceModels = async ({
  page,
  limit,
  search,
}: {
  page?: number;
  limit?: number;
  search?: string;
}): Promise<{
  success: boolean;
  data: {
    data: TVoiceModel[];
    total: number;
    lastEvaluatedKey: number;
  };
}> => {
  const queryParams = new URLSearchParams();

  if (page) queryParams.append('page', page.toString());
  if (limit) queryParams.append('limit', limit.toString());
  if (search) queryParams.append('search', search);

  const queryString = queryParams.toString() ? `?${queryParams.toString()}` : '';

  try {
    const response = await apiClient.request({
      method: 'GET',
      endPoint: GET_CLONE_VOICE_MODELS_API_PATH + queryString,
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};

export const deleteVoiceModel = async ({
  voiceId,
  fileName,
}: {
  voiceId: string;
  fileName: string;
}): Promise<{
  success: boolean;
  message?: string;
}> => {
  try {
    const response = await apiClient.request({
      method: 'DELETE',
      endPoint: `${GET_VOICE_MODELS_API_PATH}/${voiceId}?file=${encodeURIComponent(fileName)}`,
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};

export const deleteClonedVoiceModel = async ({
  voiceId,
  fileName,
}: {
  voiceId: string;
  fileName: string;
}): Promise<{
  success: boolean;
  message?: string;
}> => {
  try {
    const response = await apiClient.request({
      method: 'DELETE',
      endPoint: `${GET_CLONE_VOICE_MODELS_API_PATH}/${voiceId}?file=${encodeURIComponent(fileName)}`,
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};

export const uploadCustomVoice = async (payload: FormData): Promise<{ message?: string }> => {
  try {
    const response = await apiClient.request({
      method: 'POST',
      endPoint: POST_CUSTOM_VOICE_API_PATH,
      body: payload,
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};

export const uploadToS3 = async (payload: FormData): Promise<{ message?: string }> => {
  try {
    const response = await apiClient.request({
      method: 'POST',
      endPoint: POST_IMAGE_API_PATH,
      body: payload, // Send FormData directly
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};
