import { NoviUser } from '@/pages/Novi/types/NoviUser';
import apiClient from '@/utils/apiClient';

const REGISTER_USER = '/api/novi/register';
const LOGIN_USER = '/api/novi/login';
const GET_USER_DETAILS = '/api/novi/users/details';
const UPDATE_LOGO = '/api/novi/users/logo';
const UPDATE_DETAILS = '/api/novi/users/details';
const ACCEPT_TERMS_AND_CONDITIONS = '/api/novi/users/terms';
const ADD_TWILIO_KEYS = '/api/novi/users/twilio';
const GET_CUSTOMER_SECRET = '/api/novi/get-customer-secret';
const UPDATE_ONBOARDING_STEPS = '/api/novi/update-onboarding-steps';
const UPDATE_PENDING_ONBOARDING_STATUS = '/api/novi/update-pending-onboarding-status';
const BILLING_OVERVIEW_API_PATH = '/api/novi/billing-overview';
const STRIPE_CANCEL_OR_UPDATE_API_PATH = '/api/novi/stripe-cancel-or-update';
const HANDLE_CUSTOMER_PORTAL_API_PATH = '/api/stripe/customerPortal/reseller';
const CANCEL_SUBSCRIPTION_REASON_PATH = '/api/novi/cancel-subscription-reason';
const CLIENT_PORTAL_SESSION_PATH = '/api/novi/stripe-cancel-or-update';
const BUY_MORE_CAMPAIGNS = '/api/novi/buy-more-campaigns';
const APPLY_COUPON_PATH = '/api/v2/receptionist-dashboard/apply-coupon';
const REMOVE_DISCOUNT_API_PATH = '/api/v2/receptionist-dashboard/remove-discount';

const NOVI = 'NOVI';

export const registerNoviUser = async (payload: {
  email_address: string;
  password: string;
  logo?: File;
  business_name: string;
}): Promise<{
  success: boolean;
  auth_token: string;
  user: NoviUser;
}> => {
  try {
    const formData = new FormData();
    formData.append('email_address', payload.email_address);
    formData.append('password', payload.password);
    formData.append('business_name', payload.business_name);

    if (payload.logo) {
      formData.append('logo', payload.logo);
    }

    const response = await apiClient.request({
      method: 'POST',
      endPoint: REGISTER_USER,
      body: formData,
    });

    return response;
  } catch (error) {
    throw error;
  }
};

export const loginNoviUser = async (payload: {
  email_address: string;
  password: string;
}): Promise<{
  success: boolean;
  auth_token: string;
  user: NoviUser;
}> => {
  try {
    const response = await apiClient.request({
      method: 'POST',
      endPoint: LOGIN_USER,
      body: payload,
    });

    return response;
  } catch (error) {
    throw error;
  }
};

export const getNoviCustomerSecret = async (): Promise<{
  customerSecret: any;
  success: boolean;
}> => {
  try {
    const response = await apiClient.request({
      method: 'GET',
      endPoint: GET_CUSTOMER_SECRET,
      clientType: NOVI,
    });

    return response;
  } catch (error) {
    throw error;
  }
};

export const updateNoviOnboardingSteps = async (payload: {
  stepNumber: number;
}): Promise<{ success: boolean; updated_onboarding_steps: number[] }> => {
  try {
    const response = await apiClient.request({
      method: 'PUT',
      endPoint: UPDATE_ONBOARDING_STEPS,
      clientType: NOVI,
      body: payload,
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};

export const updateNoviPendingOnboardingStatus = async (payload: {
  pendingOnboarding: boolean;
}): Promise<{ success: boolean; pendingOnboarding: boolean }> => {
  try {
    const response = await apiClient.request({
      method: 'PUT',
      endPoint: UPDATE_PENDING_ONBOARDING_STATUS,
      clientType: NOVI,
      body: payload,
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};

export const getNoviUserDetails = async (): Promise<{ success: boolean; user: NoviUser }> => {
  try {
    const response = await apiClient.request({
      method: 'GET',
      endPoint: GET_USER_DETAILS,
      clientType: NOVI,
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};
export const updateLogo = async (logo: File): Promise<{ message: string; logo: string }> => {
  const formData = new FormData();
  formData.append('logo', logo);

  try {
    const response = await apiClient.request({
      method: 'PATCH',
      endPoint: UPDATE_LOGO,
      clientType: NOVI,
      body: formData,
    });

    return response;
  } catch (error) {
    throw error;
  }
};

export const updateUserDetails = async (business_name: string): Promise<{ message: string }> => {
  try {
    const response = await apiClient.request({
      method: 'PATCH',
      endPoint: UPDATE_DETAILS,
      clientType: NOVI,
      body: { business_name },
    });

    return response;
  } catch (error) {
    throw error;
  }
};

export const acceptTermsAndConditions = async (): Promise<{ message: string }> => {
  try {
    const response = await apiClient.request({
      method: 'PATCH',
      clientType: NOVI,
      endPoint: ACCEPT_TERMS_AND_CONDITIONS,
    });

    return response;
  } catch (error) {
    throw error;
  }
};

export const addTwilioKeys = async (payload: {
  twilio_account_id: string;
  twilio_access_token: string;
}): Promise<{ message: string }> => {
  try {
    const response = await apiClient.request({
      method: 'PATCH',
      endPoint: ADD_TWILIO_KEYS,
      clientType: NOVI,
      body: payload,
    });

    return response;
  } catch (error) {
    throw error;
  }
};

export const getBillingOverview = async (): Promise<{
  success: boolean;
  message?: string;
  data?: {
    basePlanStatus: string;
    voiceUsageCharge: number;
    appliedCoupons?:
      | {
          id: string;
          name: string;
          amountOff: number | null;
          percentOff: number | null;
          duration: string;
          durationInMonths: number | null;
          validUntil: string | null;
        }[]
      | null;
  };
}> => {
  try {
    const response = await apiClient.request({
      method: 'GET',
      endPoint: BILLING_OVERVIEW_API_PATH,
      clientType: NOVI,
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};

export const stripeCancelOrUpdate = async (payload: {
  username: string;
  action: string;
  reseller: boolean;
}): Promise<any> => {
  try {
    const response = await apiClient.request({
      method: 'POST',
      endPoint: STRIPE_CANCEL_OR_UPDATE_API_PATH,
      body: payload,
      clientType: NOVI,
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};

export const handleCustomerPortal = async (payload: { emailAddress: string }): Promise<any> => {
  try {
    const response = await apiClient.request({
      method: 'POST',
      endPoint: HANDLE_CUSTOMER_PORTAL_API_PATH,
      body: payload,
      clientType: NOVI,
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};

export const cancelSubReason = async (payload: { reason: string }): Promise<{ success: boolean }> => {
  try {
    const response = await apiClient.request({
      method: 'POST',
      endPoint: CANCEL_SUBSCRIPTION_REASON_PATH,
      body: payload,
      clientType: NOVI,
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};

export const createClientPortalSession = async (payload: {
  username: string;
  action: 'subscription_update' | 'payment_method_update' | 'subscription_cancel';
}): Promise<{ status: string; redirectPaymentURL?: string; message?: string; success?: boolean }> => {
  try {
    const response = await apiClient.request({
      method: 'POST',
      endPoint: CLIENT_PORTAL_SESSION_PATH,
      body: payload,
      clientType: NOVI,
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};

export const buyMoreCampaigns = async (payload: {
  quantity: number;
}): Promise<{
  success: boolean;
  message?: string;
  totalCampaigns?: number;
  unConfiguredCampaigns?: number;
}> => {
  try {
    const response = await apiClient.request({
      method: 'PUT',
      endPoint: BUY_MORE_CAMPAIGNS,
      body: payload,
      clientType: NOVI,
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};

export const applyCouponCode = async (couponCode: string): Promise<{ success: boolean; message?: string }> => {
  try {
    const response = await apiClient.request({
      method: 'POST',
      endPoint: APPLY_COUPON_PATH,
      body: { couponCode },
      clientType: NOVI,
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong with applyCouponCode', err);
    throw err;
  }
};

export const removeDiscount = async (payload: {
  appliedOn: string;
}): Promise<{
  success: boolean;
  message?: string;
}> => {
  try {
    const response = await apiClient.request({
      method: 'POST',
      endPoint: REMOVE_DISCOUNT_API_PATH,
      body: payload,
      clientType: NOVI,
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};
