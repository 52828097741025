import { useEffect } from 'react';
import { Form, Input, Button, Space } from 'antd';
import { Edit } from 'lucide-react';
import { TCRMNote } from '@/types/TCRM';

type NoteFormProps = {
  isLoading?: boolean;
  onSubmit: (note: any) => void;
  onCancel?: () => void;
  editingNote?: TCRMNote;
  initialValues?: TCRMNote;
};

const NoteForm: React.FC<NoteFormProps> = ({ isLoading, onSubmit, onCancel, editingNote, initialValues }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (editingNote) {
      form.setFieldsValue({ notes: editingNote.content });
    }
  }, [editingNote, form]);

  const handleFinish = (values: any) => {
    if (editingNote) {
      onSubmit({ ...editingNote, content: values.notes });
    } else {
      onSubmit({ content: values.notes });
    }
    form.resetFields();
  };

  const handleCancel = () => {
    form.resetFields();
    if (onCancel) onCancel();
  };

  return (
    <Form form={form} layout='vertical' onFinish={handleFinish} initialValues={initialValues}>
      <Form.Item name='notes' rules={[{ required: true, message: 'Please enter your note.' }]} className='mb-3'>
        <Input.TextArea rows={3} placeholder='Enter your note here...' className='w-full rounded-md bg-gray-50' />
      </Form.Item>

      <Space className='w-full justify-end' size='middle'>
        <Button type='text' size='small' icon={<Edit size={16} />} htmlType='submit' loading={isLoading}>
          {editingNote ? 'Update Note' : 'Add Note'}
        </Button>

        {editingNote && (
          <Button type='text' size='small' onClick={handleCancel}>
            Cancel
          </Button>
        )}
      </Space>
    </Form>
  );
};

export default NoteForm;
