import React from 'react';
import Fade from 'react-reveal/Fade';
import classes from '../RegisterPageV3.module.css';

const Step5B = ({
  setBusinessInformationTemplateModalActive,
  setSelectedBusinessInfoTemplate,
  businessInformationsTemplates,
  businessName,
  greetingsPromptTemplates,
  selectedBusinessInfoTemplate,
  setCompanyInfo,
  businessInformationTemplateModalActive,
}) => {
  return (
    <>
      <div
        className={classes.box + ' ' + classes.modal + ' ' + (businessInformationTemplateModalActive && classes.active)}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <div className={classes.close} onClick={() => setBusinessInformationTemplateModalActive(false)}>
          &times;
        </div>
        <Fade duration={1000} bottom>
          <h3
            className={classes.heading}
            style={{
              textAlign: 'center',
            }}
          >
            Prompt Templates
          </h3>

          <div className={classes.subtitle + ' '} style={{ marginBottom: '.5rem' }}>
            Select a template
          </div>

          <select
            style={{ marginBottom: '1rem' }}
            className={classes.input}
            // value={selectedGreetingTemplate}
            onChange={e =>
              setSelectedBusinessInfoTemplate(
                businessInformationsTemplates
                  .find(el => el.label === e.target.value)
                  .template.replace('[BUSINESS NAME HERE]', businessName)
              )
            }
          >
            <option value=''>Choose an option</option>
            {greetingsPromptTemplates.map((el, idx) => {
              return <option key={el.label}>{el.label}</option>;
            })}
          </select>

          <p>{selectedBusinessInfoTemplate}</p>

          <button
            type='button'
            onClick={() => {
              setCompanyInfo(selectedBusinessInfoTemplate);
              setBusinessInformationTemplateModalActive(false);
            }}
            className={classes.button}
            style={{
              marginTop: '2rem',
            }}
          >
            Use Template
          </button>
        </Fade>
      </div>
    </>
  );
};

export default Step5B;
