import { LabelWithDescription } from '@/components/LabelWithdescription';
import { OverViewCardInfo } from '../OverviewCardInfo';
import { RefetchOptions } from '@tanstack/react-query';
import { MODAL_IDS } from 'src/enums/EModal';
import { TextAssistForm } from './TextAssitForm';
import { Card, Switch } from 'antd';

export const TextAssistInfo: React.FC<{
  data: any;
  refetch: (options?: RefetchOptions) => Promise<any>;
}> = ({ data, refetch }) => {
  const textAssist = data?.textAssist;

  const InfoComponent = () => {
    if (!textAssist) return null;

    return (
      <Card className='mt-4 mb-4'>
        <div>
          <LabelWithDescription
            customClassName='mt-2'
            label='Enable Text Assist'
            description='Enable or disable automated text messages for failed calls.'
          />
          <Switch checked={textAssist.is_active} disabled className='mt-2 mb-4' />
        </div>

        <div>
          <LabelWithDescription
            customClassName='mt-2 mb-2'
            label='Message Template'
            description='The template used for sending automated text messages.'
          />
          <span className='w-9/12 mt-1 pt-2 pb-4' dangerouslySetInnerHTML={{ __html: textAssist.template }}></span>
        </div>
      </Card>
    );
  };

  return (
    <OverViewCardInfo
      info={<InfoComponent />}
      form={<TextAssistForm textAssistConfig={textAssist} refetch={refetch} />}
      formActionType='Edit'
      width={'80%'}
      footer={null}
      modalId={MODAL_IDS.TEXT_ASSIST_FORM_MODAL}
    />
  );
};
