import React from 'react';
import { Typography, Spin } from 'antd';
import { useQuery } from '@tanstack/react-query';
import { getCampaignOnboarding } from '@/api/novi/campaigns';
import { CampaignSetupContent } from './CampaignSetupContent';

const { Title } = Typography;

interface CampaignSetupPageProps {
  isPageView?: boolean;
  totalSteps?: number;
  campaignId: string;
  resellerView: boolean;
  standAloneCampaignView: boolean;
}

export const CampaignSetup: React.FC<CampaignSetupPageProps> = ({
  isPageView = false,
  totalSteps = 6,
  campaignId,
  resellerView,
  standAloneCampaignView = false,
}) => {
  // Move the fetch logic here
  const { data: onboardingData, isLoading } = useQuery({
    queryKey: ['campaign-onboarding', campaignId],
    queryFn: getCampaignOnboarding,
  });

  // Early return conditions
  if (isLoading) {
    return isPageView ? (
      <div className='flex items-center justify-center min-h-screen'>
        <Spin size='large' />
      </div>
    ) : null;
  }

  // If not page view and all steps are done, return null
  if (!isPageView && (onboardingData?.steps || []).length >= totalSteps) {
    return null;
  }

  if (isPageView) {
    return (
      <div className='p-6 max-w-7xl mx-auto'>
        <Title level={2} className='mb-8'>
          Campaign Setup
        </Title>
        <CampaignSetupContent
          doneSteps={onboardingData?.steps || []}
          totalSteps={standAloneCampaignView ? 7 : totalSteps}
          isPageView={true}
          standAloneCampaignView={standAloneCampaignView}
        />
      </div>
    );
  }
  // Default Modal + Float view
  return (
    <CampaignSetupContent
      resellerView={resellerView}
      doneSteps={onboardingData?.steps || []}
      totalSteps={standAloneCampaignView ? 7 : totalSteps}
      isPageView={false}
      standAloneCampaignView={standAloneCampaignView}
    />
  );
};
