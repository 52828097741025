import { Button, Typography } from 'antd';
import { RocketOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { openModal } from '@/store/modal/slice';
import { MODAL_IDS } from 'src/enums/EModal';
import NoviPlansModal from '../NoviPlansModal';

const { Title, Text } = Typography;

export interface NoSubscriptionViewProps {
  title?: string;
  subtitle?: string;
  resellerView?: boolean;
}

const NoSubscriptionView = ({
  title = 'Upgrade Your Experience',
  subtitle = 'Take your business to the next level with our premium features',
  resellerView = false,
}: NoSubscriptionViewProps) => {
  const dispatch = useDispatch();

  const handleSubscribe = () => {
    dispatch(
      openModal({
        modalId: MODAL_IDS.NOVI_PLANS_MODAL,
        data: { resellerView },
      })
    );
  };

  return (
    <div className='relative min-h-screen w-full bg-gradient-to-br from-blue-50 to-indigo-50 overflow-hidden'>
      {/* Animated Background Blobs */}
      <div
        className='absolute top-0 left-0 w-96 h-96 bg-blue-200 rounded-full mix-blend-multiply filter blur-xl opacity-70'
        style={{
          animation: 'moveBlob 7s infinite',
          transform: 'translate(0px, 0px) scale(1)',
        }}
      />
      <div
        className='absolute top-0 right-0 w-96 h-96 bg-purple-200 rounded-full mix-blend-multiply filter blur-xl opacity-70'
        style={{
          animation: 'moveBlob 7s infinite',
          animationDelay: '2s',
          transform: 'translate(0px, 0px) scale(1)',
        }}
      />
      <div
        className='absolute -bottom-8 left-20 w-96 h-96 bg-pink-200 rounded-full mix-blend-multiply filter blur-xl opacity-70'
        style={{
          animation: 'moveBlob 7s infinite',
          animationDelay: '4s',
          transform: 'translate(0px, 0px) scale(1)',
        }}
      />

      <style>
        {`
          @keyframes moveBlob {
            0% {
              transform: translate(0px, 0px) scale(1);
            }
            33% {
              transform: translate(30px, -50px) scale(1.1);
            }
            66% {
              transform: translate(-20px, 20px) scale(0.9);
            }
            100% {
              transform: translate(0px, 0px) scale(1);
            }
          }

          .feature-card {
            transition: all 0.3s ease;
          }

          .feature-card:hover {
            transform: translateY(-4px);
          }

          .feature-icon {
            transition: transform 0.3s ease;
          }

          .feature-card:hover .feature-icon {
            transform: scale(1.1);
          }
        `}
      </style>

      <div className='relative flex flex-col items-center justify-center min-h-screen p-8'>
        <div className='max-w-6xl w-full mx-auto bg-white/80 backdrop-blur-lg rounded-2xl shadow-xl p-8 md:p-12'>
          <div className='grid md:grid-cols-2 gap-12 items-center'>
            {/* Left Column - Main Content */}
            <div className='space-y-6'>
              <div className='inline-flex items-center px-4 py-2 rounded-full bg-blue-50 text-blue-600 font-medium text-sm'>
                <RocketOutlined className='mr-2' />{' '}
                {resellerView ? 'Launch your first outbound campaign today' : 'Premium Features Await'}
              </div>

              <div>
                <Title level={1} className='!text-4xl md:!text-5xl !font-bold !mb-4'>
                  {title}
                </Title>
                <Text className='text-lg text-gray-600'>{subtitle}</Text>
              </div>

              <div className='space-y-4'>
                {(resellerView
                  ? [
                      'campaign management',
                      'Advanced analytics and reporting',
                      'Seamless integration with your white label dashboard',
                    ]
                  : ['campaign management', 'Advanced analytics and reporting', 'Priority customer support 24/7']
                ).map((feature, index) => (
                  <div key={index} className='flex items-center space-x-3'>
                    <CheckCircleOutlined className='text-green-500 text-xl' />
                    <span className='text-gray-700'>{feature}</span>
                  </div>
                ))}
              </div>

              <div className='pt-6'>
                <Button
                  type='primary'
                  size='large'
                  onClick={handleSubscribe}
                  className='h-12 px-8 text-lg shadow-lg hover:scale-105 transition-transform duration-300'
                >
                  Get Started Now
                </Button>
              </div>
            </div>

            {/* Right Column - Feature Cards */}
            <div className='grid gap-6'>
              {[
                {
                  icon: '💼',
                  title: 'Campaign Management',
                  description: 'Manage multiple campaigns effortlessly from a single dashboard',
                },
                {
                  icon: '📊',
                  title: 'Analytics & Insights',
                  description: 'Make data-driven decisions with comprehensive analytics',
                },
                {
                  icon: '🎯',
                  title: 'Dynamically import leads',
                  description:
                    'Our outbound solution gives you an option to import leads and make outbound calling a part of your funnel.',
                },
              ].map((card, index) => (
                <div
                  key={index}
                  className='feature-card p-6 bg-white rounded-xl shadow-sm hover:shadow-md border border-gray-100'
                >
                  <div className='flex items-start space-x-4'>
                    <div className='feature-icon text-3xl'>{card.icon}</div>
                    <div>
                      <Title level={5} className='!mb-2'>
                        {card.title}
                      </Title>
                      <Text className='text-gray-500'>{card.description}</Text>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <NoviPlansModal />
    </div>
  );
};

export default NoSubscriptionView;
