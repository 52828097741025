import { RequestOptions } from '@/interfaces/IHttpRequest';
import LocalStorageUtility from './localStorage';
import { LocalStorageKeys } from 'src/enums/ELocalStorage';
import { logout } from 'src/api/user';
import { getAuthToken } from './helper';

const apiClient = {
  async request(requestOptions: RequestOptions) {
    const { body, endPoint, headers, method, isBlobResponse, params, clientType = 'RECEPTIONIST' } = requestOptions;

    const baseUrl = import.meta.env.VITE_APP_API_BASE_URL;

    if (!baseUrl) {
      throw Error('Backend endpoint cannot be undefined!');
    }

    const customHeaders: HeadersInit = {
      ...headers,
    };

    let authToken = null;

    if (
      (window.location.href.includes('novi-portal') &&
        !endPoint.includes('novi') &&
        !endPoint.includes('/apply-coupon') &&
        !endPoint.includes('/remove-discount')) ||
      window.location.href.includes('/campaign/dashboard')
    ) {
      authToken = LocalStorageUtility.getLocalData(LocalStorageKeys.NOVI_RECEPTIONIST_AUTH_TOKEN);
    } else {
      authToken = getAuthToken(clientType);
    }

    if (authToken) {
      //@ts-ignore
      (customHeaders as HeadersInit)['Authorization'] = `Bearer ${authToken}`;
    }

    // Remove 'Content-Type' header if body is FormData
    if (body instanceof FormData) {
      //@ts-ignore
      delete (customHeaders as HeadersInit)['Content-Type']; // Allow browser to set this
    } else {
      // Default to 'application/json' if not FormData
      //@ts-ignore
      (customHeaders as HeadersInit)['Content-Type'] = 'application/json';
    }

    const defaultOptions: RequestInit = {
      headers: customHeaders,
      method,
    };

    // Set body directly if it's FormData; otherwise, stringify
    if (body) {
      defaultOptions.body = body instanceof FormData ? body : JSON.stringify(body);
    }

    try {
      let apiUrl = `${baseUrl}${endPoint}`;
      if (params) {
        // Convert all param values to strings before creating URLSearchParams to handle boolean values
        const stringParams: Record<string, string> = Object.fromEntries(
          Object.entries(params).map(([key, value]) => [key, String(value)])
        );
        const queryParams = new URLSearchParams(stringParams).toString();
        apiUrl += `?${queryParams}`;
      }
      const response = await fetch(apiUrl, defaultOptions);
      const responseData = isBlobResponse ? await response.blob() : await response.json();

      if (response.ok) {
        return responseData;
      }

      // Make sure to update the status text, If updated in backend
      if (responseData?.message === 'Token Expired') {
        logout();
      }

      if (responseData?.message) {
        throw new Error(responseData.message);
      }

      throw new Error(`Failed to make request:: ${endPoint}`);
    } catch (err) {
      console.log('Failed to request', err);
      throw err;
    }
  },
};

export default apiClient;
