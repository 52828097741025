import React from 'react';
import TipsLoadingScreen from '../TipsLoader';
import { Button } from 'antd';

type StepWrapperProps = {
  children: React.ReactNode;
  handleNext?: () => void;
  isLoading?: boolean;
  disableNext?: boolean;
  continueBtnText?: string;
};

const StepWrapper = ({ children, isLoading, handleNext, disableNext, continueBtnText }: StepWrapperProps) => {
  return (
    <div className='w-full h-full flex flex-col items-center overflow-hidden'>
      {isLoading ? (
        <div className='flex flex-col items-center justify-center mt-10 space-y-4 h-full'>
          <TipsLoadingScreen title='Please wait… Our AI is learning from your website!' variant='scraping' />
        </div>
      ) : (
        <div className='flex-grow w-full flex flex-col items-center sm:px-12 sm:py-4 p-4'>
          <div className='bg-white w-full rounded-lg my-1'>{children}</div>
          <div className='justify-center sm:flex hidden w-full my-4'>
            <Button
              type='primary'
              onClick={handleNext}
              disabled={disableNext}
              className='px-10 font-semibold py-6 text-white text-lg bg-blue-600 hover:bg-blue-700 rounded-md shadow-sm w-full !font-merriweather'
            >
              {continueBtnText ? continueBtnText : 'Continue'}
            </Button>
          </div>
        </div>
      )}

      <div className='fixed block sm:hidden bottom-0 left-0 w-full bg-white shadow-md border-t z-50 py-4 px-4'>
        <div className='flex justify-center'>
          <Button
            type='primary'
            onClick={handleNext}
            disabled={disableNext}
            className='px-10 font-semibold py-6 text-white text-lg bg-blue-600 hover:bg-blue-700 rounded-md shadow-lg w-full max-w-sm !font-merriweather'
          >
            {continueBtnText ? continueBtnText : 'Continue'}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default StepWrapper;
