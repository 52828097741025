import { useEffect, useState } from 'react';
import { Button, Card, Layout, Typography, Space, Image } from 'antd';
import { useNavigate } from 'react-router-dom';
import ConfettiExplosion from 'react-confetti-explosion';
import logo from '@/assets/images/My AI Front Desk Dashboard_files/Blue_Logo.png';
import backgroundImage from '@/assets/images/My AI Front Desk Dashboard_files/receptionist.jpg';
import './PaymentSuccess.scss';

const { Content } = Layout;
const { Title, Text } = Typography;

const PaymentSuccess = ({ reseller = false }) => {
  const [timer, setTimer] = useState(10);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer(prevTimer => {
        if (prevTimer > 1) {
          return prevTimer - 1;
        } else {
          clearInterval(interval);
          setIsButtonEnabled(true);
          return 0;
        }
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Layout className='payment-success-container' style={{ backgroundImage: `url(${backgroundImage})` }}>
      <Content>
        <ConfettiExplosion zIndex={1000} force={1} height='220vh' width={3000} particleCount={200} duration={5000} />
        <Card bordered={false} className='payment-success-card'>
          <Space direction='vertical' size='large' style={{ width: '100%' }}>
            <Image src={logo} preview={false} width={100} className='logo' />
            <Title level={2} className='heading'>
              {reseller ? 'Unbranded Receptionist Reserved' : 'Payment Received!'}
            </Title>
            <Text className='paragraph'>
              Thank you for your payment! Your AI receptionist is now set up and ready to manage calls. Start leveraging
              its capabilities immediately by creating campaigns!
            </Text>
            <Button
              type='primary'
              size='large'
              block
              className='button'
              onClick={() => navigate('/novi-portal')}
              disabled={!isButtonEnabled}
            >
              {isButtonEnabled ? 'Go to Dashboard' : `Finalizing setup: ${timer}s...`}
            </Button>
          </Space>
        </Card>
      </Content>
    </Layout>
  );
};

export default PaymentSuccess;
