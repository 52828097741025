import { Show } from '@/interfaces/INotifications';
import { IntakeForm } from '@/types/TCallLogs';
import { NotifKey } from 'src/enums/ENotifKey';

const caller = '+12341234550';
const business = 'XYZ Consultancy';
const transcript =
  "assistant@ We are New Delhi based startup. How may I help you?  \nuser@ Hi. I want to schedule a calendar meet.\nassistant@ Sure, I can help you with that. Could you please tell me your name?\nuser@ Yeah. My name is\nassistant@ It seems like the response was cut off. Could you please tell me your name again?\nuser@ Charles.\nassistant@ Great, Charles. When would you like to schedule the event? Please provide a date.\nuser@ 29th September.\nassistant@ Thank you, Charles. And what time would work best for you on September 29th?\nuser@ Time will be\nassistant@ It looks like your response was cut off. Could you please tell me the time that you'd like to schedule the event on September 29th?\nuser@ 11 AM.\nassistant@ Perfect, Charles. So, we have an event scheduled for September 29th at 11 AM. Could you please confirm that all the details are correct?\nuser@ Yes.\nassistant@ Alright, Charles. Just to confirm, your event is scheduled for September 29th at 11 AM. I'll proceed with the booking now. Please hold on for a moment. Please hold on for a moment while I schedule the calendar meet for you. It seems like there was an issue while scheduling the calendar meet. Could you please try again later? Sorry for the inconvenience.  The calendar meeting has been scheduled successfully. Do you need any further assistance today?\nuser@ Yeah.\nassistant@ Okay, how can I assist you further?\n";
const callInformationLinkMain =
  'http://app.xyz.com/short-url/redirect/a7080801-c308-402e-8123-22e976a68226?encryptedKey=932d17229aae437cd1bef3c7aea0a031:1741c16db8ba3567e2fe7c208f96c1380919cdbbdbc269a5aad38aa0729407b6d7c60e7618fd214cf0be5bb55b7f315a';
const callSummary = `The user inquired about the services offered by ${business} after providing their customer ID, SUB121. I shared information about our expertise in software development and IT consulting. The conversation ended with the user expressing gratitude, which I acknowledged before concluding the call.`;
const intakeQA = `{\"Can you please tell me your customer id?\":\"CUS 454\",\"From which city you are calling in?\":\"Los Angeles\",\"what is your name?\":\"Jane\"}`;
const timestamp = 'Mon Oct 21, 2024 05:32:03 PM';
const workflow_scenario = 'Calendar Workfklow';
const voicemail = 'I am recording this message such that some one revert my call';

function formatTranscript(transcript: string) {
  return `<div>${transcript
    ?.trim()
    .split('\n')
    .map(line => {
      const [name, text] = line.split('@ ');
      return `<p style="font-size: 18px; color: #333;"><strong>${name}:</strong> ${text}</p>`;
    })
    .join('')}
      </div>`;
}

function formatIntake(intakeResponse: string) {
  let htmlString = '';

  // Parse the intakeResponse JSON string into an object
  const parsedResponse = JSON.parse(intakeResponse);

  // Transform the object into a list of questions and answers
  const transformedResponse = Object.entries(parsedResponse)
    .map(([key, value]) => [
      { key: 'Question', value: key },
      { key: 'Answer', value: value },
    ])
    .flat() as IntakeForm[];

  // Generate the HTML string
  htmlString = `
      <div>
        <ul>
          ${transformedResponse
            .map((item, index) => {
              if (item.key === 'Question') {
                const answer = transformedResponse[index + 1]?.value;
                return `
                <li style="font-size: 18px; color: #333;">
                  <strong>Q:</strong> ${item.value}<br />
                  <strong>A:</strong> ${answer}
                </li>
                `;
              }
              return '';
            })
            .join('')}
        </ul>
      </div>
    `;

  return htmlString;
}

function renderSentence(key: string) {
  switch (key) {
    case NotifKey.DirectSMS:
      return 'got an SMS';
    case NotifKey.Voicemail:
      return 'got a Voicemail';
    case NotifKey.Workflow:
      return 'recieved a custom scenario "Billing Details" from client';
    case NotifKey.Robocall:
      return `recieved a Robocall. We placed it under Robocall Blocklist. You can whitelist it by visiting to: 
              <b>Dashboard > Tweak Advanced Settings > Robo Call Numbers</b>`;
    default:
      return 'answered a call';
  }
}

function renderSentenceSms(key: string) {
  switch (key) {
    case NotifKey.DirectSMS:
      return `
      <span>New Text Alert!</span>
          <span>Hi ${business},</span>
      Your receptionist got a new Text SMS`;
    case NotifKey.Voicemail:
      return `
      <span>New Voicemail Alert!</span>
          <span>Hi ${business},</span>
      There is a voicemail for you.`;
    case NotifKey.CRM:
      return `
      <span>New CRM Alert!</span>
          <span>Hi ${business},</span>
      Lead "Leadxyz" entered your CRM group "Groupxyz"`;
    case NotifKey.Workflow:
      return `
      <span>A Workflow Triggered!</span>
          <span>Hi ${business}y,</span>
      During a call, custom scenario "Billing Details" triggered`;
    case NotifKey.Calendar:
      return `
        <span>New Calendar meet scheduled!</span>
            <span>Hi ${business},</span>`;
    case NotifKey.Robocall:
      return `
          <span>New Robocall detected!</span>
                  <span>Hi ${business},</span>
              There is a Robocall detected and we placed it under Robocall Blocklist. You can whitelist it by visiting to: 
              <b>Dashboard > Tweak Advanced Settings > Robo Call Numbers</b>`;
    default:
      return `
      <span>New Call Alert!</span>
          <span>Hi ${business},</span>
      Your receptionist answered a call`;
  }
}

export function generateNotificationEmailHtml({
  show,
  notifKey,
  resellerName,
  resellerLogo,
  resellerClient,
}: {
  show: Show | null;
  notifKey: string;
  resellerName?: string;
  resellerLogo?: string;
  resellerClient?: boolean;
}) {
  const commonStyles = `
      background-color: #ffecd1;
      font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
      margin: 0;
      padding: 0;
    `;

  const emailStyles = `
      background-color: #ffffff;
      border-radius: 5px;
      box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
      margin: 20px;
      padding: 20px;
    `;

  var logo = `
      <div style="text-align: center; padding: 20px 0;">
        <img src=${resellerClient ? resellerLogo : 'https://uploads-ssl.webflow.com/646676436cb9dc8974098e06/6466c59f166aa987edbeecfe_headphones%20(1)-p-500.png'} alt="Logo" style="max-width: 150px; height: auto;" />
      </div>
    `;

  var dashboardLink = 'https://app.xyz.com/';

  var callInformationLink =
    callInformationLinkMain &&
    notifKey !== NotifKey.DirectSMS &&
    notifKey !== NotifKey.Voicemail &&
    notifKey !== NotifKey.CRM &&
    notifKey !== NotifKey.Calendar &&
    notifKey !== NotifKey.Robocall
      ? `<a href="${callInformationLinkMain}">Click here</a> to view the call information.` + '<br/>'
      : '';

  var footer = `
      <br/>
      <br/>
      
      <div style="text-align: center; padding: 20px 0; color: #777; background-color: #ffecd1;">
        &copy; 2024 ${resellerName || 'My AI Front Desk'}. <br /> All rights reserved.
      </div>
    `;

  let content;

  var workflowElement = ``;
  if (workflow_scenario && show?.workflow) {
    workflowElement = `
      <h3> Internal Workflow Triggered </h3>
      <p style="font-size: 18px; color: #333;">
        <b> Workflow Scenario: </b> ${workflow_scenario}
      </p>
      <hr style="border: 1px solid #ff5733; margin: 20px 0;">
      <h1>1 New Call!</h1>
      `;
  }

  var textTriggered = ``;
  if (notifKey === NotifKey.DirectSMS) {
    textTriggered = `
    <h3> Texting Platform Triggered </h3>
    <p style="font-size: 18px; color: #333;">
      You have a direct SMS to your receptionist
    </p>
    `;
  }

  var voicemailTriggered = ``;
  if (notifKey === NotifKey.Voicemail) {
    voicemailTriggered = `
    <h3> Voicemail Triggered </h3>
    <p style="font-size: 18px; color: #333;">
      You have a Voicemail for your receptionist
    </p>
    `;
  }

  var callSum = '';
  if (show?.callSummary) {
    callSum = `<h3> Call Summary </h3><p style="font-size: 18px; color: #333;">${callSummary}</p><hr style="border: 1px solid #ff5733; margin: 20px 0;">`;
  }

  var transcr = '';
  if (show?.transcript) {
    transcr = `<h3> Full Transcript </h3><p class="transcript" style="font-size: 18px; color: #333;">${formatTranscript(transcript)}</p><hr style="border: 1px solid #ff5733; margin: 20px 0;">`;
  }

  var intake = '';
  if (show?.intakeForms) {
    intake = `<h3> Intake Q&A </h3><p class="transcript" style="font-size: 18px; color: #333;">${formatIntake(intakeQA)}</p><hr style="border: 1px solid #ff5733; margin: 20px 0;">`;
  }

  var voicemailText = '';
  if (notifKey === NotifKey.Voicemail) {
    voicemailText = `<h3> Voicemail </h3><p style="font-size: 18px; color: #333;">${voicemail}</p><hr style="border: 1px solid #ff5733; margin: 20px 0;">`;
  }

  var calendarContent = '';
  if (notifKey === NotifKey.Calendar) {
    calendarContent = `<p>A calendar Meet scheduled with a customer John Smith</p>
    <p>Event Date: Wed Oct 30, 2024 </p>
    <p>Event Time: 8:00 PM - 8:30 PM</p>
    <p>Event Timezone: America/Los_Angeles</p>
    <p>Event Link: https://calendar-ics-files.s3.amazonaws.com/kmord-john-231028261521.ics?Expires=1730717124</p>
    <p>Google Meet: https://meet.google.com/szp-zrqs-ntq</p>`;
  }

  content = `
      ${workflowElement}
      ${textTriggered}
      ${voicemailTriggered}
      <p style="font-size: 16px; color: #333;">Hi ${business},</p>
      <p style="font-size: 16px; color: #333;">
        ${
          notifKey === NotifKey.Calendar
            ? ''
            : notifKey === NotifKey.CRM
              ? `Lead "Leadxyz" entered your CRM group "Groupxyz"`
              : `You are receiving this email because your AI phone receptionist just ${renderSentence(notifKey)}`
        }.
        ${
          dashboardLink && notifKey !== NotifKey.Calendar
            ? `<br/><br/><a href="${dashboardLink}">Click here</a> to view your dashboard or change your notification settings.`
            : ``
        }
      </p>
      <div style="font-size: 20px; color: #333; margin-top: 20px; padding-top: 20px; border-top: 2px solid #ff5733;">
        <div style="color: #777;">
          ${notifKey === NotifKey.CRM || notifKey === NotifKey.Calendar ? '' : `<p style="font-size: 18px; color: #333;">${notifKey === NotifKey.DirectSMS || notifKey === NotifKey.Voicemail ? 'Sender' : 'Caller'}: ${caller}</p>`}
          <p style="font-size: 18px; color: #333;">Time: ${timestamp} UTC</p>
          <hr style="border: 1px solid #ff5733; margin: 20px 0;">
          ${callSum}
          ${transcr}
          ${intake}
          ${callInformationLink}
          ${voicemailText}
          ${calendarContent}
        </div>
      </div>
    `;

  const html = `
      <!DOCTYPE html>
      <html>
        <head></head>
        <body style="${commonStyles}">
          <div style="${emailStyles}">
            ${logo}
            ${content}
          </div>
          ${footer}
        </body>
      </html>
    `;

  return html;
}

export function generateNotificationSMSHtml({
  showCallSummary,
  showCallInfoLink,
  notifKey,
}: {
  showCallSummary: boolean | undefined;
  showCallInfoLink: boolean | undefined;
  notifKey: string;
}) {
  var callSum = '';
  if (showCallSummary) {
    callSum = `<span>Call Summary: ${callSummary}</span>`;
  }

  var callInfo = '';
  if (showCallInfoLink) {
    callInfo = `<span>Call Information Link: ${callInformationLinkMain}</span>`;
  }

  var voicemailText = '';
  if (notifKey === NotifKey.Voicemail) {
    voicemailText = `<span>Voicemail: ${voicemail}</span>`;
  }

  var textingPfText = '';
  if (notifKey === NotifKey.DirectSMS) {
    voicemailText = `<span>Message: Hello, how to subscribe?</span>`;
  }

  var calendarContent = '';
  if (notifKey === NotifKey.Calendar) {
    calendarContent = `<span>A calendar Meet scheduled with a customer John Smith</span>
    <span>Event Date: Wed Oct 30, 2024 </span>
    <span>Event Time: 8:00 PM - 8:30 PM</span>
    <span>Event Timezone: America/Los_Angeles</span>
    <span>Event Link: https://calendar-ics-files.s3.amazonaws.com/kmord-john-231028261521.ics?Expires=1730717124</span>
    <span>Google Meet: https://meet.google.com/szp-zrqs-ntq</span>`;
  }

  const html = `
      <!DOCTYPE html>
      <html>
        <head></head>
        <body>
          <div style="display: flex; flex-direction: column; gap: 8px" >
          ${renderSentenceSms(notifKey)}
            <span>${notifKey === NotifKey.DirectSMS || notifKey === NotifKey.Voicemail ? 'Sender' : 'Caller'}: ${caller}</span>
            <span>Time: ${timestamp}</span>
            ${callSum}
            ${callInfo}
            ${voicemailText}
            ${textingPfText}
            ${calendarContent}
          </div>
        </body>
      </html>
    `;

  return html;
}

export function generateNotificationEmailHtmlForTextSMS({ show }: { show: Show }) {
  const commonStyles = `
      background-color: #ffecd1;
      font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
      margin: 0;
      padding: 0;
    `;

  const emailStyles = `
      background-color: #ffffff;
      border-radius: 5px;
      box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
      margin: 20px;
      padding: 20px;
    `;

  var logo = `
      <div style="text-align: center; padding: 20px 0;">
        <img src="https://uploads-ssl.webflow.com/646676436cb9dc8974098e06/6466c59f166aa987edbeecfe_headphones%20(1)-p-500.png" alt="Logo" style="max-width: 150px; height: auto;" />
      </div>
    `;

  var dashboardLink = 'https://app.xyz.com/';

  var callInformationLink = callInformationLinkMain
    ? `<a href="${callInformationLinkMain}">Click here</a> to view the call information.` + '<br/>'
    : '';

  var footer = `
      <br/>
      <br/>
      
      <div style="text-align: center; padding: 20px 0; color: #777; background-color: #ffecd1;">
        &copy; 2024 My AI Front Desk. <br /> All rights reserved.
      </div>
    `;

  let content;

  var workflowElement = ``;
  if (workflow_scenario && show.workflow) {
    workflowElement = `
      <h3> Internal Workflow Triggered </h3>
      <p style="font-size: 18px; color: #333;">
        <b> Workflow Scenario: </b> ${workflow_scenario}
      </p>
      <hr style="border: 1px solid #ff5733; margin: 20px 0;">
      `;
  }

  var callSum = '';
  if (show.callSummary) {
    callSum = `<h3> Call Summary </h3><p style="font-size: 18px; color: #333;">${callSummary}</p><hr style="border: 1px solid #ff5733; margin: 20px 0;">`;
  }

  var transcr = '';
  if (show.transcript) {
    transcr = `<h3> Full Transcript </h3><p class="transcript" style="font-size: 18px; color: #333;">${formatTranscript(transcript)}</p><hr style="border: 1px solid #ff5733; margin: 20px 0;">`;
  }

  var intake = '';
  if (show.intakeForms) {
    intake = `<h3> Intake Q&A </h3><p class="transcript" style="font-size: 18px; color: #333;">${formatIntake(intakeQA)}</p><hr style="border: 1px solid #ff5733; margin: 20px 0;">`;
  }

  content = `
      ${workflowElement}
      <h1>1 New Call!</h1>
      <p style="font-size: 16px; color: #333;">Hi ${business},</p>
      <p style="font-size: 16px; color: #333;">
        You are receiving this email because your AI phone receptionist just answered a call.
        ${
          dashboardLink
            ? `<a href="${dashboardLink}">Click here</a> to view your dashboard or change your notification settings.`
            : ``
        }
      </p>
      <div style="font-size: 20px; color: #333; margin-top: 20px; padding-top: 20px; border-top: 2px solid #ff5733;">
        <div style="color: #777;">
          <p style="font-size: 18px; color: #333;">Caller: ${caller}</p>
          <p style="font-size: 18px; color: #333;">Time: ${timestamp} UTC</p>
          <hr style="border: 1px solid #ff5733; margin: 20px 0;">
          ${callSum}
          ${transcr}
          ${intake}
          ${callInformationLink}
        </div>
      </div>
    `;

  const html = `
      <!DOCTYPE html>
      <html>
        <head></head>
        <body style="${commonStyles}">
          <div style="${emailStyles}">
            ${logo}
            ${content}
          </div>
          ${footer}
        </body>
      </html>
    `;

  return html;
}
