import { User } from '@/types/TAccounts';
import apiClient from '@/utils/apiClient';

const FETCH_CAMPAIGNS = '/api/novi/campaigns';
const FETCH_RECEPTIONIST_TOKEN = '/api/novi/campaigns/:campaign_id/token';
const UPDATE_CAMPAIGN_NAME = '/api/novi/campaigns/name';
const DUPLICATE_CAMPAIGN = '/api/novi/campaigns/duplicate';
const DELETE_CAMPAIGN = '/api/novi/campaigns/delete';
const DEDUCT_CAMPAIGN = '/api/novi/deduct-campaign-subscription-quantity';
const CAMPAIGN_LOGIN = '/api/novi/campaign/login';
const GET_CAMPAIGN_DETAILS = '/api/novi/campaign/details';
const GET_RESERVED_NUMBER = '/api/novi/campaign/reserved-number';
const CAMPAIGN_ONBOARDING = '/api/novi/campaign/onboarding';

const NOVI = 'NOVI';
const NOVI_RECEPTIONIST = 'NOVI_RECEPTIONIST';

export const getNoviCampaigns = async (): Promise<{ campaigns: any[] }> => {
  try {
    const response = await apiClient.request({
      method: 'GET',
      clientType: NOVI,
      endPoint: FETCH_CAMPAIGNS,
    });

    return response;
  } catch (error) {
    console.error('Error fetching campaigns:', error);
    throw error;
  }
};

export const getCampaignToken = async (campaign_id: string): Promise<{ success: boolean; auth_token: string }> => {
  try {
    const response = await apiClient.request({
      method: 'GET',
      clientType: NOVI,
      endPoint: FETCH_RECEPTIONIST_TOKEN.replace(':campaign_id', campaign_id),
    });

    return response;
  } catch (error) {
    console.error('Error fetching campaigns:', error);
    throw error;
  }
};

export const updateCampaignName = async (payload: {
  campaign_id: string;
  business_name: string;
}): Promise<{ success: boolean }> => {
  try {
    const response = await apiClient.request({
      method: 'PATCH',
      clientType: NOVI,
      endPoint: UPDATE_CAMPAIGN_NAME,
      body: payload,
    });

    return response;
  } catch (error) {
    console.error('Error fetching campaigns:', error);
    throw error;
  }
};

export const duplicateACampaign = async (payload: {
  campaign_id: string;
  business_name?: string;
  username?: string;
  password?: string;
}): Promise<{ success: boolean }> => {
  try {
    const response = await apiClient.request({
      method: 'POST',
      clientType: NOVI,
      endPoint: DUPLICATE_CAMPAIGN,
      body: payload,
    });

    return response;
  } catch (error) {
    console.error('Error fetching campaigns:', error);
    throw error;
  }
};

export const deleteCampaign = async (campaign_id: string): Promise<{ success: boolean; message: string }> => {
  try {
    const response = await apiClient.request({
      method: 'PUT',
      endPoint: DELETE_CAMPAIGN,
      body: {
        campaign_id: campaign_id,
      },
      clientType: NOVI,
    });

    return response;
  } catch (error) {
    console.error('Error fetching campaigns:', error);
    throw error;
  }
};

export const deductCampaignFromSubscription = async (): Promise<{ success: boolean; message: string }> => {
  try {
    const response = await apiClient.request({
      method: 'PUT',
      endPoint: DEDUCT_CAMPAIGN,
      clientType: NOVI,
    });

    return response;
  } catch (error) {
    console.error('Error fetching campaigns:', error);
    throw error;
  }
};

export const loginCampaign = async (payload: { username: string; password: string }): Promise<any> => {
  try {
    const response = await apiClient.request({
      method: 'POST',
      endPoint: CAMPAIGN_LOGIN,
      body: payload,
    });

    return response;
  } catch (error) {
    console.error('Error fetching campaigns:', error);
    throw error;
  }
};

export const getCampaignDetails = async (): Promise<{
  success: boolean;
  data?: User;
  message?: string;
}> => {
  try {
    const response = await apiClient.request({
      method: 'GET',
      endPoint: GET_CAMPAIGN_DETAILS,
      clientType: NOVI_RECEPTIONIST,
    });

    return response;
  } catch (error) {
    console.error('Error fetching campaigns:', error);
    throw error;
  }
};

export const getCampaignReservedNumber = async (): Promise<{
  success: boolean;
  message?: string;
  data?: {
    reservedNumber: string;
    status: boolean;
  };
}> => {
  try {
    const response = await apiClient.request({
      method: 'GET',
      endPoint: GET_RESERVED_NUMBER,
      clientType: NOVI_RECEPTIONIST,
    });

    return response;
  } catch (error) {
    console.error('Error fetching campaigns:', error);
    throw error;
  }
};

export const getCampaignOnboarding = async (): Promise<{
  success: boolean;
  message?: string;
  steps?: Array<number>;
}> => {
  try {
    const response = await apiClient.request({
      method: 'GET',
      endPoint: CAMPAIGN_ONBOARDING,
      clientType: NOVI_RECEPTIONIST,
    });

    return response;
  } catch (error) {
    console.error('Error fetching campaign onboarding:', error);
    throw error;
  }
};

export const updateCampaignOnboarding = async (payload: {
  step?: number;
  steps?: Array<number>;
}): Promise<{
  success: boolean;
  message?: string;
  steps?: Array<number>;
}> => {
  try {
    const response = await apiClient.request({
      method: 'PUT',
      endPoint: CAMPAIGN_ONBOARDING,
      clientType: NOVI_RECEPTIONIST,
      body: payload,
    });

    return response;
  } catch (error) {
    console.error('Error fetching campaign onboarding:', error);
    throw error;
  }
};
