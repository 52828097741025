import React, { ChangeEvent } from 'react';
import Fade from 'react-reveal/Fade';
import classes from '../Register.module.css';
import posthog from 'posthog-js';
import { Button } from 'antd';

type Step8Props = {
  emailAddress: string;
  setEmailAddress: (email: string) => void;
  businessName: string;
  stepState: number;
  handleStepTransition: (
    nextStep: number,
    validationFn?: () => Promise<boolean>,
    stateMutationFn?: () => Promise<void>
  ) => Promise<void>;
  step0Validation: () => Promise<boolean>;
  step0StateMutation: () => Promise<void>;
  stepLoading: boolean;
};

const Step8: React.FC<Step8Props> = ({
  emailAddress,
  setEmailAddress,
  businessName,
  stepState,
  handleStepTransition,
  step0Validation,
  step0StateMutation,
  stepLoading,
}) => {
  return (
    <>
      <div className={classes.box}>
        <Fade duration={1000} bottom>
          <>
            {React.createElement(() => {
              posthog.capture('email_address_entered');
              return null;
            })}
            <h3
              className={classes.heading}
              style={{
                textAlign: 'center',
              }}
            >
              Email Address
            </h3>
            <div
              className={classes.subtitle}
              style={{
                textAlign: 'center',
              }}
            >
              {' '}
              Your email address. We will never share this with anyone, or send you spam.
            </div>

            <input
              type='email'
              value={emailAddress}
              className={`${classes.input} ${classes.centered}`}
              required
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                const value = event.target.value;
                setEmailAddress(value);
              }}
              placeholder={`e.g. john@${businessName.toLocaleLowerCase().replace(' ', '')}.com`}
            />

            {/* <button
            type='button'
            onClick={() => {
              if (!emailAddress) {
                notification.error({ message: 'Please enter your email address', duration: 1 });
              } else if (!validateEmail(emailAddress)) {
                notification.error({ message: 'Please enter a valid email address', duration: 1 });
              } else {
                setStepState(stepState + 1);
              }
            }}
            className={`${classes.button} ${classes.centered}`}
            style={{
              marginTop: '2rem',
            }}
          >
            Continue
          </button> */}
            <Button
              type='primary'
              onClick={() => handleStepTransition(stepState + 1, step0Validation, step0StateMutation)}
              className={classes.button + ' ' + classes.centered}
              style={{ marginTop: '2rem' }}
              loading={stepLoading}
            >
              Continue
            </Button>
          </>
        </Fade>
      </div>
    </>
  );
};

export default Step8;
