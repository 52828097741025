import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { generateRandomColour } from '../../../utils/helper';
import { prod } from '../../../config';
import {
  businessInformationsTemplates,
  businessNameTemplates,
  greetingsPromptTemplates,
} from '../../../constants/prompt-templates';
import classes from './RegisterPageV2.module.css';
import { notification } from 'antd';
import { AppRoutes } from '../../../enums/ERoutes';
import StepAOne from './Steps/StepAOne';
import StepATwo from './Steps/StepATwo';
import StepAThree from './Steps/StepAThree';
import StepBOne from './Steps/StepBOne';
import StepCOne from './Steps/StepCOne';
import StepCTwo from './Steps/StepCTwo';
import TipsLoadingScreen from './TipsLoader';
import StepFinal from './Steps/StepFinal';
import Logo from '@/assets/images/desktop-logo.png';
import { soundMapping } from 'src/constants/voiceModels';
import StepZero from './Steps/StepZero';
import StepAThreeII from './Steps/StepAThreeII';
import StepCOneII from './Steps/StepCOneII';
import StepCTwoII from './Steps/StepCTwoII';
import CustomSteps from './Steps/CustomStepper';

declare global {
  interface Window {
    gtag: (...args: any[]) => void;
    _rwq?: (...args: any[]) => void;
    rewardful?: {
      (...args: any[]): void;
      q?: any[];
      referral?: string | null;
    };
    Rewardful?: {
      referral?: string | null;
    };
  }
}

interface RegisterPageProps {
  debrand?: boolean;
  reseller_email?: string;
  reseller_name?: string;
  campaign_user_email?: string;
  purchase_quotes?: string;
  campaign_user_name?: string;
  campaign_user_password?: string;
  debranded_registration_modal_active_setter?: (active: boolean) => void;
  debrand_modal_instructions_setter?: (instructions: string) => void;
  logo_s3?: string;
  reroute_to_unbranded_login?: boolean;
  isCampaign?: boolean;
  auto_scale?: boolean;
  unreservedReceptionists?: number | any;
  test?: boolean;
  features?: any;
}

const RegisterPageV2: React.FC<RegisterPageProps> = ({
  debrand,
  reseller_email = '',
  campaign_user_email = '',
  purchase_quotes = '',
  campaign_user_name = '',
  campaign_user_password = '',
  debranded_registration_modal_active_setter,
  debrand_modal_instructions_setter,
  reroute_to_unbranded_login,
  isCampaign,
  auto_scale = false,
  unreservedReceptionists,
  test = false,
  features = {},
}) => {
  const [testingNumber, setTestingNumber] = useState<string>('');
  const [businessName, setBusinessName] = useState<string>('');
  const [businessWebsite, setBusinessWebsite] = useState<string>('');
  const [businessType, setBusinessType] = useState<string>('');
  const [emailAddress, setEmailAddress] = useState<string>('');
  const [referralCode, setReferralCode] = useState<string>('');
  const [findOutAboutUs] = useState<string>('');
  const [systemPhrase, setSystemPhrase] = useState<string>('');
  const [companyInfo, setCompanyInfo] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [username, setUsername] = useState<string>('');
  const [whereFoundUs, setWhereFoundUs] = useState<string>('');
  const [voiceModel, setVoiceModel] = useState<string>('');
  const [voiceProvider, setVoiceProvider] = useState<string>('');
  const [playingModel, setPlayingModel] = useState<string>('');
  const soundPlayerRef = useRef<HTMLAudioElement | null>(null);
  const [stepState, setStepState] = useState<number>(0);
  const [subStepState, setSubStepState] = useState<number>(0);
  const isDarkMode = false;
  const sampleQA = '';
  const [isUsingATemplate, setIsUsingATemplate] = useState<boolean>(false);
  const navigate = useNavigate();

  const handlePlayPause = (model: any) => {
    if (playingModel === model.value) {
      // Pause the currently playing sound
      soundPlayerRef?.current?.pause();
      setPlayingModel('');
    } else {
      // Play a new sound
      if (soundPlayerRef.current) {
        soundPlayerRef.current.pause();
      }
      soundPlayerRef.current = new Audio(soundMapping[model.value]);
      soundPlayerRef.current.load();

      const promise = soundPlayerRef.current.play();
      if (promise !== undefined) {
        promise
          .then(() => {
            setPlayingModel(model.value); // Set the current model as playing
          })
          .catch(error => {
            console.log(error);
          });
      }

      // Add an event listener to handle when the sound ends
      soundPlayerRef.current.onended = () => {
        setPlayingModel(''); // Reset when the sound finishes
      };
    }
  };
  // v2
  const [selectedFeatures, setSelectedFeatures] = React.useState<string[]>([]);
  const [selectedPeople, setSelectedPeople] = useState<string | null>(null);
  const [selectedRevenue, setSelectedRevenue] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false); // Loading state

  const handleSubStepTransition = () => {
    setSubStepState(prev => prev + 1);
  };

  const handleStepTransition = async (nextStep: number): Promise<void> => {
    setStepState(nextStep);
    setSubStepState(0);
  };

  useEffect(() => {
    if (!isUsingATemplate) {
      if (!systemPhrase) {
        setSystemPhrase('');
      }
    } else {
      if (!businessName) {
        const businessNameTemplate = businessNameTemplates.find(el => el.label === businessType)?.template;
        if (businessNameTemplate) {
          setBusinessName(businessNameTemplate);
        }
      }

      if (!systemPhrase) {
        const greetingTemplate = greetingsPromptTemplates.find(el => el.label === businessType)?.template;
        if (greetingTemplate) {
          setSystemPhrase(greetingTemplate.replace('[BUSINESS NAME HERE]', businessName || ''));
        }
      }

      if (!companyInfo) {
        const businessInfoTemplate = businessInformationsTemplates.find(el => el.label === businessType)?.template;
        if (businessInfoTemplate) {
          setCompanyInfo(businessInfoTemplate.replace('[BUSINESS NAME HERE]', businessName || ''));
        }
      }
    }
  }, [
    businessType,
    isUsingATemplate,
    businessName,
    systemPhrase,
    companyInfo,
    businessNameTemplates,
    greetingsPromptTemplates,
    businessInformationsTemplates,
  ]);

  useEffect(() => {
    window.gtag('event', 'RegisterPageView', {
      event_category: 'Pageview',
    });
  }, []);

  useEffect(() => {
    if (!reseller_email && !campaign_user_email) {
      console.log('Creating');
      const script = document.createElement('script');
      script.id = 'ze-snippet';
      script.src = 'https://static.zdassets.com/ekr/snippet.js?key=a1895b38-5ada-4a38-b4b1-580c900fcc1e';
      script.async = true;

      document.body.appendChild(script);

      return () => {
        // Cleanup when component unmounts
        document.body.removeChild(script);
      };
    }
  }, [reseller_email, campaign_user_email]);

  // useEffect for step state changing to fire GTag analytics
  useEffect(() => {
    // just record what step number we are on
    const eventName = `RegisterPageStep${stepState}`;

    // Trigger the Google Analytics event
    window.gtag('event', eventName, {
      event_category: 'RegisterPage.js',
      event_label: `Step # ${stepState}`,
    });
  }, [stepState]);

  // Type for the environment variable (assuming it's a string URL)
  const serverURL: string = import.meta.env.VITE_APP_API_BASE_URL as string;

  // Disable console logs and errors in production
  if (prod) {
    console.log = function () {};
    console.error = function () {};
  }

  useEffect(() => {
    (function (w: Window, r: 'rewardful') {
      w._rwq = w[r];
      w[r] =
        w[r] ||
        function (...args: any[]) {
          (w[r]!.q = w[r]!.q || []).push(args);
        };
    })(window, 'rewardful');

    const s = document.createElement('script');
    s.async = true;
    s.src = 'https://r.wdfl.co/rw.js';
    s.setAttribute('data-rewardful', '30c5c6');
    s.setAttribute('data-domains', 'myaifrontdesk.com, myaifrontdeskdashboard.com');
    document.head.appendChild(s);

    window.rewardful?.('ready', () => {
      if (window.Rewardful?.referral) {
        console.log('Current referral ID:', window.Rewardful.referral);
      } else {
        console.log('No referral present.');
      }
    });
  }, []);

  useEffect(() => {
    // Create a URLSearchParams object to access the query parameters
    const queryParams = new URLSearchParams(window.location.search);

    // Get the 'referralCode' parameter
    const referralCode = queryParams.get('referralCode');

    if (referralCode) {
      // Do something with the referralCode
      console.log('Referral Code:', referralCode);

      // setIsReferralUser(true);
      setReferralCode(referralCode);
    } else {
      // Handle the case when referralCode is not present in the URL
      // console.log("Referral Code is not present in the URL");
    }
  }, []);

  interface CreateClientBody {
    email: string;
    businessName: string;
    testingNumber: string;
    systemPhrase: string;
    companyInfo: any;
    password: string;
    referralCode: string | null;
    findOutAboutUs: string;
    sampleQA: any;
    selectedLanguages: string[];
    languageGreetings: Record<string, string>;
    workflows: any;
    callWorkflows: any;
    businessType: string;
    reseller_email: string | null;
    campaign_user_email: string | null;
    purchase_quotes: any;
    username: string;
    english_voice_selected: string;
    auto_scale: boolean;
    unreservedReceptionists: boolean;
    avatarBg: string;
    voicemails_enabled: boolean;
    call_recording_enabled: boolean;
    test: boolean;
    variables?: Record<string, any>;
    featuresSubAccount: any;
    featurePreferences: string[];
    businessEmployeeSize: string | null;
    businessRevenue: string | null;
    businessWebsite: string | null;
    whereFoundUs?: string | null;
  }

  let selectedLanguages = ['English'];
  let languageGreetings = { English: '' };

  const handleSubmit = async () => {
    const d = Date.now();
    const campaignUsername = `${campaign_user_name}-${businessName}-${d}`.replace(/ /g, '-');

    try {
      setIsSubmitting(true);
      let usernameTaken = false;

      // Check if the username exists
      await fetch(`${serverURL}/clientExistsNoPassword`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: isCampaign ? campaignUsername : username,
        }),
      })
        .then(response => response.json())
        .then(data => {
          if (data.status === 'true') {
            notification.error({
              message: 'Username Already Taken',
              description: 'This username is already registered. Please login or use a different username.',
            });
            usernameTaken = true;
            window.gtag('event', 'phone_number_exists', {
              event_category: 'RegisterPage.js',
            });
          }
        })
        .catch(error => {
          console.error(error);
          window.gtag('event', 'couldnt_create_client', {
            event_category: 'RegisterPage.js',
            event_label: 'error',
          });
          notification.error({
            message: 'Registration Error',
            description: 'We are having trouble registering you. Please try again or email contact@myaifrontdesk.com.',
          });
        });

      if (usernameTaken) return;

      let email = emailAddress;
      if (reseller_email) email = reseller_email;
      if (campaign_user_email) email = campaign_user_email;

      // Prepare the request body
      let featuresSubAccount = { ...features };
      if (test && Object.keys(featuresSubAccount).length > 0) {
        featuresSubAccount.visibility.featureVisibilitySidebar = features.visibility.featureTrialVisibilitySideBar;
        featuresSubAccount.gating.featureGatingSidebar = features.gating.featureTrialGatingSideBar;
        featuresSubAccount.visibility.configureVisibility = features.visibility.configureVisibilityTrial;
        featuresSubAccount.gating.configureGating = features.gating.configureGatingTrial;
        featuresSubAccount.visibility.advanceFeaturesVisibility = features.visibility.advanceFeaturesVisibilityTrial;
        featuresSubAccount.gating.advanceFeaturesGating = features.gating.advanceFeaturesGatingTrial;
      }

      const bodyObject: CreateClientBody = {
        email,
        businessName,
        testingNumber: `+${testingNumber}`,
        systemPhrase,
        companyInfo,
        password: isCampaign ? campaign_user_password || Date.now().toString() : password,
        referralCode,
        findOutAboutUs,
        sampleQA,
        selectedLanguages,
        languageGreetings,
        workflows: [],
        callWorkflows: [],
        businessType,
        reseller_email,
        campaign_user_email,
        purchase_quotes,
        username: isCampaign ? campaignUsername : username,
        english_voice_selected: JSON.stringify({
          voiceId: voiceModel,
          provider: voiceProvider,
        }),
        auto_scale,
        unreservedReceptionists,
        avatarBg: generateRandomColour(),
        voicemails_enabled: true,
        call_recording_enabled: true,
        test,
        featuresSubAccount,
        featurePreferences: selectedFeatures,
        businessEmployeeSize: selectedPeople,
        businessRevenue: selectedRevenue,
        businessWebsite,
        whereFoundUs,
      };

      const response = await fetch(`${serverURL}/createClient`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(bodyObject),
      });
      if (!response.ok) {
        window.gtag('event', 'couldnt_create_client', {
          event_category: 'RegisterPage.js',
          event_label: 'error',
        });
        notification.error({
          message: 'Registration Failed',
          description: 'We encountered an issue while creating your account. Please try again.',
        });
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      if (data.success) {
        window.gtag('event', 'create_client', {
          event_category: 'RegisterPage.js',
          event_label: 'success',
        });

        notification.success({
          message: 'Registration Successful',
          description: 'Your account has been created successfully.',
        });

        if (data.token) {
          if (isCampaign) {
            navigate('/novi-portal/login');
          } else if (!window.location.href.includes(AppRoutes.RESELLER_PORTAL)) {
            localStorage.setItem('AUTH_TOKEN', JSON.stringify(data.token));
          }

          if (!debrand) navigate('/');
          if (debrand && reroute_to_unbranded_login) {
            navigate('/dashboard', {
              state: { username, password },
            });
          }

          if (debrand) {
            debrand_modal_instructions_setter?.('true');
            debranded_registration_modal_active_setter?.(false);
          }
        }
      }
    } catch (error) {
      console.error(error);
      notification.error({
        message: 'Unexpected Error',
        description: 'An unexpected error occurred. Please try again later.',
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const header = (
    <div className='flex items-center justify-between bg-white shadow-md p-4'>
      {/* Logo Section */}
      <div className='flex-shrink-0'>
        <img src={Logo} alt='Logo' className='w-16 sm:w-44' />
      </div>

      {/* Navigation Items */}
      <div className='flex items-center sm:space-x-4 space-x-1 sm:text-lg text-xs'>
        <button
          onClick={() => window.open('https://www.myaifrontdesk.com', '_blank')}
          className='text-gray-700 hover:text-blue-500 sm:px-3 px-2 py-2 focus:outline-none'
        >
          Product
        </button>
        <button
          onClick={() => (window.location.href = '/')}
          className='text-gray-700 hover:text-blue-500 sm:px-3 px-2 py-2 focus:outline-none'
        >
          Login
        </button>
        {/* <button
          onClick={() => window.open('https://www.myaifrontdesk.com/book-a-consultation', '_blank')}
          className='text-gray-700 hover:text-blue-500 sm:px-3 px-2 py-2 focus:outline-none'
        >
          Book Consultation
        </button> */}
        <button
          onClick={() =>
            (window.location.href =
              'mailto:contact@myaifrontdesk.com?subject=Contact Us&body=Please let us know how we can assist you.')
          }
          className='text-gray-700 hover:text-blue-500 sm:px-3 px-2 py-2 focus:outline-none'
        >
          Contact
        </button>
      </div>

      {/* Spacer (only visible on larger screens) */}
      <div className='hidden sm:block w-32'></div>
    </div>
  );
  const isNextDisabled = () => {
    switch (stepState) {
      case 0:
        return !voiceModel;

      case 1:
        switch (subStepState) {
          case 0:
            return false;
          case 1:
            return !businessType || isUsingATemplate === null;
          case 2:
            return !selectedPeople;
          case 3:
            return !selectedRevenue;
          default:
            return false;
        }

      case 2:
        return !selectedFeatures;

      case 3:
        switch (subStepState) {
          case 0:
          case 1:
            return !emailAddress || !testingNumber;
          case 2:
          case 3:
            return !username || !password;
          case 4:
            return !whereFoundUs;
          default:
            return false;
        }

      default:
        return false;
    }
  };

  const renderStepContent = () => {
    switch (stepState) {
      case 0:
        return (
          <StepZero
            handlePlayPause={handlePlayPause}
            handleStepTransition={handleStepTransition}
            playingModel={playingModel}
            setStepState={setStepState}
            setVoiceModel={setVoiceModel}
            setVoiceProvider={setVoiceProvider}
            soundPlayerRef={soundPlayerRef}
            stepState={stepState}
            voiceModel={voiceModel}
          />
        );

      case 1:
        switch (subStepState) {
          case 0:
            return (
              <StepAOne
                businessName={businessName}
                setBusinessName={setBusinessName}
                handleStepTransition={handleSubStepTransition}
                websiteLink={businessWebsite}
                setWebsiteLink={setBusinessWebsite}
                setCompanyInfo={setCompanyInfo}
                companyInfo={companyInfo}
              />
            );
          case 1:
            return (
              <StepATwo
                setIsUsingATemplate={setIsUsingATemplate}
                businessType={businessType}
                setBusinessType={setBusinessType}
                isUsingATemplate={isUsingATemplate}
                handleStepTransition={handleSubStepTransition}
              />
            );
          case 2:
            return (
              <StepAThree
                handleStepTransition={handleSubStepTransition}
                stepState={stepState}
                selectedPeople={selectedPeople}
                selectedRevenue={selectedRevenue}
                setSelectedPeople={setSelectedPeople}
                setSelectedRevenue={setSelectedRevenue}
                businessType={businessType}
              />
            );
          case 3:
            return (
              <StepAThreeII
                handleStepTransition={handleStepTransition}
                stepState={stepState}
                selectedPeople={selectedPeople}
                selectedRevenue={selectedRevenue}
                setSelectedPeople={setSelectedPeople}
                setSelectedRevenue={setSelectedRevenue}
                businessType={businessType}
              />
            );
          default:
            return null;
        }

      case 2:
        return (
          <StepBOne
            handleStepTransition={handleStepTransition}
            selectedFeatures={selectedFeatures}
            setSelectedFeatures={setSelectedFeatures}
            stepState={stepState}
          />
        );

      case 3:
        switch (subStepState) {
          case 0:
            return (
              <StepCOne
                emailAddress={emailAddress}
                setEmailAddress={setEmailAddress}
                phoneNumber={testingNumber}
                setPhoneNumber={setTestingNumber}
                handleNext={handleSubStepTransition}
              />
            );
          case 1:
            return (
              <StepCOneII
                emailAddress={emailAddress}
                setEmailAddress={setEmailAddress}
                phoneNumber={testingNumber}
                setPhoneNumber={setTestingNumber}
                handleNext={handleSubStepTransition}
              />
            );
          case 2:
            return (
              <StepCTwo
                username={username}
                setUsername={setUsername}
                password={password}
                setPassword={setPassword}
                handleNext={handleSubStepTransition}
              />
            );
          case 3:
            return (
              <StepCTwoII
                username={username}
                setUsername={setUsername}
                password={password}
                setPassword={setPassword}
                handleNext={handleSubStepTransition}
              />
            );
          case 4:
            return (
              <StepFinal
                handleNext={handleSubmit}
                setWhereFoundUs={setWhereFoundUs}
                stepState={stepState}
                whereFoundUs={whereFoundUs}
              />
            );
          default:
            return null;
        }

      default:
        return null;
    }
  };

  return (
    <>
      <div
        className={(isDarkMode ? classes.darkMode : '') + ' ' + classes.rootPage}
        style={{
          height: '100%',
          overflow: 'auto',
          overflowX: 'hidden',
          transition: '.4s ease',
        }}
      >
        {header}

        <script src='https://www.google.com/recaptcha/api.js' async defer></script>

        <div
          className='
            flex 
            items-center 
            justify-start 
            flex-col 
            max-w-full 
            p-4 
            pb-24 
            rounded-[10px] 
            m-4 
            mb-auto 
            min-h-[calc(100vh-163px)] 
            relative
            font-merriweather
            sm:pb-12
          '
        >
          <div className={classes.registerPageContainer}>
            <div
              style={{
                width: '100%',
              }}
            >
              <div className='w-full sm:w-[60vw] border h-full mx-auto bg-white shadow-md rounded-lg flex flex-col'>
                <CustomSteps currentStep={stepState} />

                <div className='flex h-full overflow-y-auto sm:mt-2 mt-0 items-center justify-center flex-col'>
                  {isSubmitting ? (
                    <TipsLoadingScreen title="Please Wait... We're preparing your AI!" />
                  ) : (
                    renderStepContent()
                  )}
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      padding: '16px 32px',
                      gap: '16px',
                    }}
                    className='w-full border-t'
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        opacity: stepState === 0 && subStepState === 0 ? 0.5 : 1,
                        pointerEvents: stepState === 0 && subStepState === 0 ? 'none' : 'all',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        if (subStepState > 0) {
                          setSubStepState(prev => prev - 1); // Move to the previous sub-step
                        } else if (stepState > 0) {
                          setStepState(prev => prev - 1); // Move to the previous main step
                          // Adjust sub-step for the previous main step
                          setSubStepState(stepState - 1 === 1 ? 3 : stepState - 1 === 3 ? 4 : 0);
                        }
                      }}
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        fill='#6e79d6'
                        height='24px'
                        width='24px'
                        viewBox='0 0 219.151 219.151'
                      >
                        <g>
                          <path d='M109.576,219.151c60.419,0,109.573-49.156,109.573-109.576C219.149,49.156,169.995,0,109.576,0S0.002,49.156,0.002,109.575 C0.002,169.995,49.157,219.151,109.576,219.151z M109.576,15c52.148,0,94.573,42.426,94.574,94.575 c0,52.149-42.425,94.575-94.574,94.576c-52.148-0.001-94.573-42.427-94.573-94.577C15.003,57.427,57.428,15,109.576,15z' />
                          <path d='M94.861,156.507c2.929,2.928,7.678,2.927,10.606,0c2.93-2.93,2.93-7.678-0.001-10.608l-28.82-28.819l83.457-0.008 c4.142-0.001,7.499-3.358,7.499-7.502c-0.001-4.142-3.358-7.498-7.5-7.498l-83.46,0.008l28.827-28.825 c2.929-2.929,2.929-7.679,0-10.607c-1.465-1.464-3.384-2.197-5.304-2.197c-1.919,0-3.838,0.733-5.303,2.196l-41.629,41.628 c-1.407,1.406-2.197,3.313-2.197,5.303c0.001,1.99,0.791,3.896,2.198,5.305L94.861,156.507z' />
                        </g>
                      </svg>
                    </div>

                    {/* Combined Indicators for Main Steps and Sub-Steps */}
                    {new Array(4).fill(0).map((_, mainIdx) => (
                      <div
                        key={`main-step-${mainIdx}`}
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          gap: '8px',
                        }}
                      >
                        {/* Main Step */}
                        <div
                          style={{
                            width: '14px',
                            height: '14px',
                            borderRadius: '50%',
                            backgroundColor: stepState === mainIdx ? '#6e79d6' : '#d9d9d9',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            setStepState(mainIdx);
                            setSubStepState(0); // Reset to the first sub-step when switching main steps
                          }}
                        ></div>
                      </div>
                    ))}

                    {/* Next Button */}
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        opacity: isNextDisabled() ? 0.5 : 1,
                        pointerEvents: isNextDisabled() ? 'none' : 'all',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        if (isNextDisabled()) {
                          return;
                        }
                        if (subStepState < (stepState === 0 ? 0 : stepState === 1 ? 3 : stepState === 3 ? 4 : 0)) {
                          setSubStepState(subStepState + 1);
                        } else if (stepState < 3) {
                          setStepState(stepState + 1);
                          setSubStepState(0);
                        }
                      }}
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        fill='#6e79d6'
                        height='24px'
                        width='24px'
                        viewBox='0 0 219.151 219.151'
                      >
                        <g>
                          <path d='M109.576,219.151c60.419,0,109.573-49.156,109.573-109.576C219.149,49.156,169.995,0,109.576,0S0.002,49.156,0.002,109.575 C0.002,169.995,49.157,219.151,109.576,219.151z M109.576,15c52.148,0,94.573,42.426,94.574,94.575 c0,52.149-42.425,94.575-94.574,94.576c-52.148-0.001-94.573-42.427-94.573-94.577C15.003,57.427,57.428,15,109.576,15z' />
                          <path d='M124.29,62.644c-2.93-2.93-7.679-2.93-10.608,0c-2.929,2.929-2.929,7.679,0,10.608l28.819,28.819l-83.457,0.008 c-4.142,0-7.499,3.358-7.499,7.502c0,4.142,3.358,7.498,7.5,7.498l83.46-0.008l-28.827,28.825c-2.929,2.929-2.929,7.679,0,10.607 c1.465,1.464,3.384,2.197,5.304,2.197c1.919,0,3.838-0.733,5.303-2.196l41.629-41.628c1.407-1.406,2.197-3.313,2.197-5.303 c-0.001-1.99-0.791-3.896-2.198-5.305L124.29,62.644z' />
                        </g>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegisterPageV2;
