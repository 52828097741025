// CommonSider.tsx
import React, { useEffect, useState } from 'react';
import { Menu, MenuProps, Spin, Tooltip } from 'antd';
import Sider from 'antd/es/layout/Sider';
import './Sider.scss';
import { ResellerSiderItem } from 'src/enums/IResellerSiderItems';
import OnboardingStepsList from '@/pages/ReceptionistDashboard/TestItOut/v2/OnboardingStepsList';
import { useMutation } from '@tanstack/react-query';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { getLoggedInUser } from '@/store/account/selector';
import { getProvisionedNumber } from '@/api/reservedNumbers';
import { useDispatch } from 'react-redux';
import { setCurrentPage, updateProvisionedNumber } from '@/store/account/slice';
import { ReceptionistDashboardSiderItem } from 'src/enums/EReceptionistDashboardSiderItem';
import { getFeatureFlags } from '@/store/app/selector';
import { POSTHOG_FEATURE_FLAGS } from 'src/constants';
// import { getResellerData } from '@/store/reseller/selector';

interface CommonSiderProps {
  items: MenuProps['items'];
  logo: React.ReactNode;
  activeMenuItem: ResellerSiderItem | string;
  className?: string;
  menuRef?: React.RefObject<HTMLDivElement>;
  handleClick: MenuProps['onClick'];
  setOpenedOnboarding?: any;
  openedOnboarding?: boolean;
  tourOpen?: boolean;
  debrand?: boolean;
  openKeys?: string[];
  onOpenChange?: (openKeys: string[]) => void;
  resellerSider?: boolean;
}

const CommonSider: React.FC<CommonSiderProps> = ({
  items,
  logo,
  activeMenuItem,
  className = '',
  menuRef,
  handleClick,
  openKeys,
  onOpenChange,
  setOpenedOnboarding,
  openedOnboarding,
  tourOpen,
  debrand = false,
  resellerSider,
}) => {
  const baseClass = 'common-sider';
  const loggedInUser = useAppSelector(getLoggedInUser);
  const [provisionedNumber, setProvisionedNumber] = useState<string | null>(null);
  const dispatch = useDispatch();
  const featureFlags = useAppSelector(getFeatureFlags);
  const registrationFeatureFlagKey = featureFlags.ONBOARDING_REGISTRATION_REVAMP;
  const newRegistrationFlow =
    registrationFeatureFlagKey === POSTHOG_FEATURE_FLAGS.ONBOARDING_REGISTRATION_REVAMP.variants.NEW;
  // const [isModalOpen, setIsModalOpen] = useState(false);
  // const currentReseller = useAppSelector(getResellerData);

  // const showModal = () => {
  //   setIsModalOpen(true);
  // };

  // const handleOk = () => {
  //   setIsModalOpen(false);
  // };

  // const handleCancel = () => {
  //   setIsModalOpen(false);
  // };

  const getProvisionNumberMutation = useMutation({
    mutationKey: ['provisionedNumberApi'],
    mutationFn: getProvisionedNumber,
    onSuccess(data) {
      setProvisionedNumber(data.provisioned_number);
      dispatch(updateProvisionedNumber({ provisionedNumber: data.provisioned_number || '' }));
    },
  });

  useEffect(() => {
    getProvisionNumberMutation.mutate();
  }, [loggedInUser.number]);

  const handleReservedNumberClick = () => {
    if (provisionedNumber || (!provisionedNumber && !loggedInUser.demo)) {
      dispatch(setCurrentPage(ReceptionistDashboardSiderItem.SETUP_INSTRUCTIONS));
      return;
    }
    if (!provisionedNumber && loggedInUser.demo) {
      setOpenedOnboarding(true);
    }
  };

  return (
    <>
      {/* {data?.reseller.theme_color && <style>{getMenuItemStyles()}</style>} */}
      <Sider
        collapsed={false}
        className={`${baseClass} ${className}`}
        collapsible={false}
        theme='light'
        breakpoint='md'
      >
        <div>{logo}</div>
        {!resellerSider && (
          <Spin spinning={getProvisionNumberMutation.isPending}>
            <div className='flex items-center justify-center'>
              <div
                onClick={handleReservedNumberClick}
                className={`inline-flex mx-auto border cursor-pointer bg-gray-50 shadow-lg mb-6 rounded-lg items-center justify-center p-4 font-bold text-button-success ${loggedInUser.demo ? 'border-red-600' : ''}`}
              >
                <Tooltip
                  title={
                    provisionedNumber
                      ? "Your AI's reserved phone number. Click for more info."
                      : 'Click here to reserve a phone number for your AI.'
                  }
                  className={`sm:${provisionedNumber ? 'text-lg' : 'text-sm'} text-sm ${loggedInUser.demo ? 'text-red-700' : ''}`}
                >
                  📞 {provisionedNumber ? provisionedNumber : 'No reserved number'}
                </Tooltip>
              </div>
            </div>
          </Spin>
        )}
        <div ref={menuRef}>
          <Menu
            items={items}
            selectedKeys={[activeMenuItem]}
            onClick={handleClick}
            mode='inline'
            openKeys={openKeys}
            onOpenChange={onOpenChange}
          />
        </div>
        {!openedOnboarding && !tourOpen && !debrand && !newRegistrationFlow && (
          <div className='mt-8'>
            <OnboardingStepsList setOpenedOnboarding={setOpenedOnboarding} />
          </div>
        )}
      </Sider>
    </>
  );
};

export default CommonSider;
