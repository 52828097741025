import React from 'react';
import { Card, Typography, Button, message, notification, Space } from 'antd';
import { DollarOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useAppDispatch } from '@/store/hooks/useAppDispatch';
import CouponRedemption from '@/components/SubscriptionComponents/CouponRedemption';
import { removeDiscount, applyCouponCode } from '@/api/novi/user';
import RenderCouponInfo from '@/components/SubscriptionComponents/RenderCouponInfo/RenderCouponInfo';
import { cancelSubReason, createClientPortalSession, getBillingOverview, stripeCancelOrUpdate } from '@/api/novi/user';
import { getLoggedInNoviUser } from '@/store/novi/user/selector';
import CancelSubReasonModal from '@/components/CancelSubReasonModal/CancelSubReasonModal';
import { openModal } from '@/store/modal/slice';
import { MODAL_IDS } from 'src/enums/EModal';
import NoviPlansModal from '@/pages/Novi/components/NoviPlansModal';

const { Title, Text } = Typography;

const Subscription: React.FC = () => {
  const dispatch = useAppDispatch();

  const noviUser = useAppSelector(getLoggedInNoviUser);

  const {
    data: billingOverview,
    isPending: isLoadingBillingOverview,
    refetch: refreshOverview,
  } = useQuery({
    queryKey: ['billingOverview'],
    queryFn: getBillingOverview,
    enabled: !!noviUser?.email_address,
  });

  const stripeSubscriptionUpdateMutation = useMutation({
    mutationFn: stripeCancelOrUpdate,
    onSuccess: data => {
      if (data.status === 'success') {
        window.location.href = data.redirectPaymentURL;
      } else {
        message.error('Failed to cancel subscription');
      }
    },
    onError: () => {
      message.error('An error occurred while cancelling the subscription');
    },
  });

  const cancelReasonMutation = useMutation({
    mutationKey: ['cancel-subscription-reason'],
    mutationFn: cancelSubReason,
    onSuccess: data => {
      if (!data?.success) {
        notification.error({ message: 'Error canceling subscription' });
      } else {
        cancelSubMutation.mutate({ username: noviUser.email_address as string, action: 'subscription_cancel' });
      }
    },
    onError: () => {
      notification.error({ message: 'Error canceling subscription' });
    },
  });

  const cancelSubMutation = useMutation({
    mutationKey: ['cancel-subscription'],
    mutationFn: createClientPortalSession,
    onSuccess: data => {
      if (data?.status !== 'success') {
        notification.error({ message: 'Error canceling subscription' });
      } else {
        notification.success({
          message: 'Redirecting to subscription portal...',
        });
        window.location.href = data.redirectPaymentURL as string;
      }
    },
  });

  const handleCancelSubscription = () => {
    if (noviUser.subscriptionStatus === 'canceled') {
      notification.error({
        message:
          "You don't have an active subscription to cancel. Please subscribe to a subscription or contact support.",
      });
      return;
    }
    dispatch(openModal({ modalId: MODAL_IDS.CANCEL_REASON_MODAL }));
  };

  const handleUpdatePaymentMethodConfirm = () => {
    stripeSubscriptionUpdateMutation.mutate({
      username: noviUser.email_address,
      action: 'payment_method_update',
      reseller: true,
    });
  };

  const handlePurchaseSeats = () => {
    dispatch(openModal({ modalId: MODAL_IDS.NOVI_PLANS_MODAL }));
  };

  const isSubscriptionNA = billingOverview?.data?.basePlanStatus === 'N/A';

  return (
    <div className='h-full p-6'>
      <Title level={2}>Subscription Management</Title>
      <Text>Manage your subscription below. Cancel or update your payment information as needed.</Text>

      <Card loading={isLoadingBillingOverview} title='Subscription Overview' className='m-3'>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <Text strong>Base Plan Status: </Text>
            <Text
              type={
                billingOverview?.data?.basePlanStatus === 'active' ||
                billingOverview?.data?.basePlanStatus === 'trialing'
                  ? 'success'
                  : 'danger'
              }
            >
              {billingOverview?.data?.basePlanStatus}
            </Text>
          </div>
          <div>
            <Text strong>Voice Usage Charge: </Text>
            <Text type='danger'>${billingOverview?.data?.voiceUsageCharge || 0}</Text>
          </div>
        </div>
      </Card>

      <div className='my-4'>
        <RenderCouponInfo
          appliedCoupons={billingOverview?.data?.appliedCoupons as any}
          onUpdate={refreshOverview}
          removeDiscount={removeDiscount}
        />
      </div>

      <div>
        <CouponRedemption updateSuccess={refreshOverview} applyCouponCode={applyCouponCode} />
      </div>

      <Card title='Actions' style={{ marginTop: 24 }}>
        {isSubscriptionNA ? (
          <Button icon={<ShoppingCartOutlined />} onClick={handlePurchaseSeats} type='primary'>
            Purchase Subscription
          </Button>
        ) : (
          <Space>
            <Button
              icon={<DollarOutlined />}
              onClick={handleUpdatePaymentMethodConfirm}
              loading={stripeSubscriptionUpdateMutation.isPending}
              type='primary'
            >
              Update Payment Method
            </Button>
            <Button
              disabled={!!noviUser.subCancelOn}
              icon={<DollarOutlined />}
              onClick={handleCancelSubscription}
              type='primary'
              danger
            >
              Cancel Subscription
            </Button>
          </Space>
        )}
      </Card>

      <NoviPlansModal />
      <CancelSubReasonModal mutation={cancelReasonMutation} />
    </div>
  );
};

export default Subscription;
