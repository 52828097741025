import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Input, Modal, Typography, Tag, Space, Checkbox } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { message } from 'antd';
import cloneDeep from 'clone-deep';

const { Text } = Typography;

interface Variable {
  isMandatory: boolean;
  defaultValue?: string;
}

interface TextEditorProps {
  value?: string;
  onChange?: (value: string, variables?: Record<string, Variable>) => void;
  html?: string;
  variables?: Record<string, Variable>;
  placeholder?: string;
}

const TextEditor: React.FC<TextEditorProps> = ({ value, onChange, html, variables = {}, placeholder }) => {
  const inputRef = useRef<HTMLDivElement>(null);
  const rootRef = useRef<HTMLDivElement>(null);
  const selectionRef = useRef<Range | null>(null);
  const [initialHTML] = useState<string>(html || value || '');
  const [isEmpty, setIsEmpty] = useState<boolean>((html || value || '').length === 0);
  const [internalValue, setInternalValue] = useState<string>(html || value || '');

  // Modal states
  const [addModalVisible, setAddModalVisible] = useState<boolean>(false);
  const [editModalVisible, setEditModalVisible] = useState<boolean>(false);

  // Add variable states
  const [newVariable, setNewVariable] = useState<Variable & { text: string }>({
    text: '',
    isMandatory: true,
    defaultValue: '',
  });

  // Edit variable states
  const [editingVariable, setEditingVariable] = useState<Variable & { originalText: string; text: string }>({
    originalText: '',
    text: '',
    isMandatory: true,
    defaultValue: '',
  });

  // Selection handling functions
  const saveSelection = (): Range | null => {
    const sel = window.getSelection();
    return sel && sel.rangeCount > 0 ? sel.getRangeAt(0) : null;
  };

  const restoreSelection = (range: Range | null) => {
    if (range) {
      const sel = window.getSelection();
      sel?.removeAllRanges();
      sel?.addRange(range);
    }
  };

  const insertTextAtCursor = (element: HTMLElement) => {
    const range = selectionRef.current;
    if (range && window.getSelection) {
      range.deleteContents();
      range.insertNode(element);
      range.setStartAfter(element);
      range.setEndAfter(element);
      const sel = window.getSelection();
      sel?.removeAllRanges();
      sel?.addRange(range);
    }
  };

  const placeCaretAtEnd = (el: HTMLElement) => {
    el.focus();
    if (window.getSelection && document.createRange) {
      const range = document.createRange();
      range.selectNodeContents(el);
      range.collapse(false);
      const sel = window.getSelection();
      sel?.removeAllRanges();
      sel?.addRange(range);
    }
  };

  useEffect(() => {
    if (value !== undefined && value !== internalValue) {
      setInternalValue(value);
      if (inputRef.current) {
        inputRef.current.innerHTML = value;
      }
    }
  }, [value]);

  // Event handlers
  const handleContentChange = useCallback(() => {
    const newValue = inputRef?.current?.innerHTML || '';
    setIsEmpty(newValue.length === 0);
    setInternalValue(newValue);
    onChange?.(newValue, variables);
    selectionRef.current = saveSelection();
  }, [onChange, variables]);

  const handleVariableClick = useCallback(
    (e: MouseEvent) => {
      const target = e.currentTarget as HTMLSpanElement;
      const variableText = target.textContent?.replace('{{', '').replace('}}', '') || '';
      setEditingVariable({
        originalText: variableText,
        text: variableText,
        isMandatory: variables[variableText]?.isMandatory ?? true,
        defaultValue: variables[variableText]?.defaultValue ?? '',
      });
      setEditModalVisible(true);
    },
    [variables]
  );

  // Variable management functions
  const insertVariable = useCallback(
    (varData: Variable & { text: string }, useExisting = false) => {
      const { text, isMandatory, defaultValue } = varData;

      if (!useExisting) {
        if (!text) {
          message.error('Variable name cannot be empty');
          return;
        }
        if (!isMandatory && !defaultValue) {
          message.error('Default value is required for non-mandatory variables');
          return;
        }
      }

      const element = document.createElement('span');
      element.innerHTML = `{{${text}}}`;
      element.classList.add('highlight');
      element.contentEditable = 'false';
      element.addEventListener('click', handleVariableClick as EventListener);

      restoreSelection(selectionRef.current);
      insertTextAtCursor(element);

      const updatedVariables = cloneDeep(variables);
      if (!useExisting) {
        updatedVariables[text] = { isMandatory, defaultValue };
      }

      onChange?.(inputRef?.current?.innerHTML || '', updatedVariables);
      setAddModalVisible(false);
      setNewVariable({ text: '', isMandatory: true, defaultValue: '' });
    },
    [variables, onChange, handleVariableClick]
  );

  const handleSaveEdit = useCallback(() => {
    const { originalText, text, isMandatory, defaultValue } = editingVariable;

    if (!text) {
      message.error('Variable name cannot be empty');
      return;
    }
    if (!isMandatory && !defaultValue) {
      message.error('Default value is required for non-mandatory variables');
      return;
    }

    const elements = rootRef.current?.querySelectorAll('span.highlight') || [];
    elements.forEach(element => {
      if (element.textContent === `{{${originalText}}}`) {
        element.innerHTML = `{{${text}}}`;
      }
    });

    const updatedVariables = { ...variables };
    delete updatedVariables[originalText];
    updatedVariables[text] = { isMandatory, defaultValue };

    onChange?.(inputRef?.current?.innerHTML || '', updatedVariables);
    setEditModalVisible(false);
  }, [editingVariable, variables, onChange]);

  // Effect hooks
  useEffect(() => {
    const elements = rootRef.current?.querySelectorAll('span.highlight') || [];
    const clickListeners: EventListener[] = [];

    elements.forEach(el => {
      const listener = handleVariableClick as EventListener;
      el.addEventListener('click', listener);
      clickListeners.push(listener);
    });

    return () => {
      elements.forEach((el, index) => {
        el.removeEventListener('click', clickListeners[index]);
      });
    };
  }, [handleVariableClick]);

  useEffect(() => {
    const events = ['click', 'input', 'DOMNodeInserted', 'DOMNodeRemoved', 'DOMCharacterDataModified'] as const;
    const ref = inputRef.current;
    if (ref) {
      events.forEach(event => {
        ref.addEventListener(event, handleContentChange);
      });

      return () => {
        events.forEach(event => {
          ref.removeEventListener(event, handleContentChange);
        });
      };
    }
  }, [handleContentChange]);

  // Rest of the component remains the same as in the previous version...
  // (The JSX and other methods are identical to the previous artifact)

  return (
    <div className='relative' ref={rootRef}>
      <div className='relative mb-4'>
        <div
          className='w-full min-h-[100px] p-4 border rounded resize-y overflow-auto focus:outline-none focus:border-primary'
          contentEditable
          ref={inputRef}
          onInput={handleContentChange}
          dangerouslySetInnerHTML={{ __html: initialHTML }}
        />
        {isEmpty && <Text className='absolute left-4 top-4 text-gray-400 pointer-events-none'>{placeholder}</Text>}
      </div>

      <Button
        type='primary'
        icon={<PlusOutlined />}
        onClick={() => {
          const sel = saveSelection();
          const inputElement = inputRef.current;

          if (inputElement && sel && sel.startContainer && inputElement.contains(sel.startContainer)) {
            selectionRef.current = sel;
          } else {
            if (inputElement) {
              placeCaretAtEnd(inputElement);
              selectionRef.current = saveSelection();
            }
          }

          setAddModalVisible(true);
        }}
      >
        Add Variable
      </Button>

      <Modal
        title='Add Variable'
        open={addModalVisible}
        onCancel={() => setAddModalVisible(false)}
        onOk={() => insertVariable(newVariable)}
        width={400}
      >
        <Space direction='vertical' className='w-full'>
          <Input
            placeholder='Variable name'
            value={newVariable.text}
            onChange={e => setNewVariable({ ...newVariable, text: e.target.value })}
          />
          <Checkbox
            checked={newVariable.isMandatory}
            onChange={e => setNewVariable({ ...newVariable, isMandatory: e.target.checked })}
          >
            Mandatory Variable
          </Checkbox>
          {!newVariable.isMandatory && (
            <Input
              placeholder='Default Value'
              value={newVariable.defaultValue}
              onChange={e => setNewVariable({ ...newVariable, defaultValue: e.target.value })}
            />
          )}
          {Object.keys(variables).length > 0 && (
            <div>
              <Text strong>Existing variables:</Text>
              <div className='mt-2 flex flex-wrap gap-2'>
                {Object.keys(variables).map(varName => (
                  <Tag
                    key={varName}
                    color='blue'
                    className='cursor-pointer'
                    closable
                    onClose={e => {
                      e.preventDefault();
                      const newVariables = { ...variables };
                      delete newVariables[varName];
                      onChange?.(inputRef?.current?.innerHTML || '', newVariables);
                    }}
                    onClick={() =>
                      insertVariable(
                        {
                          text: varName,
                          isMandatory: variables[varName].isMandatory,
                          defaultValue: variables[varName].defaultValue,
                        },
                        true
                      )
                    }
                  >
                    {varName}
                  </Tag>
                ))}
              </div>
            </div>
          )}
        </Space>
      </Modal>

      <Modal
        title='Edit Variable'
        open={editModalVisible}
        onCancel={() => setEditModalVisible(false)}
        onOk={handleSaveEdit}
        width={400}
      >
        <Space direction='vertical' className='w-full'>
          <Input
            placeholder='Variable name'
            value={editingVariable.text}
            onChange={e => setEditingVariable({ ...editingVariable, text: e.target.value })}
          />
          <Checkbox
            checked={editingVariable.isMandatory}
            onChange={e => setEditingVariable({ ...editingVariable, isMandatory: e.target.checked })}
          >
            Mandatory Variable
          </Checkbox>
          {!editingVariable.isMandatory && (
            <Input
              placeholder='Default Value'
              value={editingVariable.defaultValue}
              onChange={e => setEditingVariable({ ...editingVariable, defaultValue: e.target.value })}
            />
          )}
        </Space>
      </Modal>
    </div>
  );
};

export default TextEditor;
