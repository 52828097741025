import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { Modal, Typography, Image } from 'antd';
import { openModal } from '@/store/modal/slice';
import { setInstructionSteps } from '@/store/account/slice';
import { NoviOnboardingSteps } from 'src/enums/IReceptionistTestingSteps';
import CustomOnboardingButton from '@/components/Onboarding/CustomButton';
import step_0_image from '@/assets/images/onboarding/step_0.gif';
import step_4_image from '@/assets/images/onboarding/step_4.gif';
import step_5_image from '@/assets/images/onboarding/step_5.gif';
import step_6_image from '@/assets/images/onboarding/step_6.gif';
import { MODAL_IDS } from 'src/enums/EModal';
import { useLocation, useNavigate } from 'react-router-dom';
import NoviPlansModal from './NoviPlansModal';
import { getNoviUserDetails, updateNoviOnboardingSteps, updateNoviPendingOnboardingStatus } from '@/api/novi/user';

const { Title, Paragraph } = Typography;

interface OnboardingProps {
  tourOpened?: boolean;
  setOpenedOnboarding: (open: boolean) => void;
  setCreateCampaignTourOpen: (open: boolean) => void;
  openedOnboarding: boolean;
  currentStep: number;
  setCurrentStep: (step: any) => void;
  tourOpen?: boolean;
  setTourOpen: (step: any) => void;
  createCampaignTourOpen?: boolean;
}

export const NoviOnboarding: React.FC<OnboardingProps> = ({
  openedOnboarding,
  setOpenedOnboarding,
  tourOpened,
  currentStep,
  setCurrentStep,
  setTourOpen,
  createCampaignTourOpen,
  setCreateCampaignTourOpen,
}) => {
  const dispatch = useDispatch();
  const [loggedInUser, setLoggedInUser] = useState<any>({});
  const [stepsDone, setStepsDone] = useState<number[]>(loggedInUser.onboardingSteps || []);

  useEffect(() => {
    if (loggedInUser?.onboardingSteps?.length < 4) {
      setCurrentStep(loggedInUser.onboardingSteps.length);
      setStepsDone(loggedInUser.onboardingSteps);
    }
  }, [loggedInUser, loggedInUser.onboardingSteps]);

  const { data } = useQuery({
    queryKey: ['getNoviUser'],
    queryFn: () => getNoviUserDetails(),
  });

  useEffect(() => {
    if (data) {
      setLoggedInUser(data.user);
    }
  }, [data]);

  const UpdateStepsDoneMutation = useMutation({
    mutationKey: ['updateNoviOnboardingSteps'],
    mutationFn: updateNoviOnboardingSteps,
    onSuccess(data: { success: boolean; updated_onboarding_steps: number[] }) {
      setStepsDone(data.updated_onboarding_steps);
      dispatch(setInstructionSteps({ instructionSteps: data.updated_onboarding_steps }));
    },
  });

  const UpdatePendingTestingStatusMutation = useMutation({
    mutationKey: ['updateNoviPendingOnboardingStatus'],
    mutationFn: updateNoviPendingOnboardingStatus,
  });

  const handleNext = (forward = true) => {
    if (!stepsDone.includes(currentStep)) {
      updateStepsDone(currentStep);
    }
    if (currentStep !== steps.length - 1) {
      if (forward) {
        setCurrentStep((prevActiveStep: number) => prevActiveStep + 1);
      }
    }
  };

  const handleSubscription = async () => {
    dispatch(openModal({ modalId: MODAL_IDS.NOVI_PLANS_MODAL }));
  };

  const handleSetPendingTesting = (status: boolean) => {
    UpdatePendingTestingStatusMutation.mutate({ pendingOnboarding: status });
    setOpenedOnboarding(!status);
  };

  const updateStepsDone = (stepNumber: number) => {
    if (loggedInUser.pendingOnboarding) {
      handleSetPendingTesting(false);
    }
    if (loggedInUser.onboardingSteps?.includes(stepNumber)) return;
    UpdateStepsDoneMutation.mutate({ stepNumber });
  };

  const handleTourGuide = () => {
    setTourOpen(true);
    setOpenedOnboarding(false);
  };

  const steps = [
    {
      title: NoviOnboardingSteps.STEP_0,
      description:
        'Welcome to your AI Front Desk! Let’s get started with setting up your outbound calls to boost customer engagement like never before.',
      image: step_0_image,
      content: (
        <CustomOnboardingButton
          onClick={() => {
            handleNext();
          }}
        >
          Let’s Go 🚀
        </CustomOnboardingButton>
      ),
    },
    {
      title: NoviOnboardingSteps.STEP_1,
      description: 'Discover how outbound calling can transform your business. Take a quick tour to see it in action.',
      image: step_4_image,
      content: (
        <CustomOnboardingButton
          onClick={() => {
            handleTourGuide();
          }}
        >
          Explore Now ✨
        </CustomOnboardingButton>
      ),
    },
    {
      title: NoviOnboardingSteps.STEP_2,
      description:
        'You’re just one step away from unlocking the full potential of AI-powered outbound calls. Choose a plan to get started today!',
      image: step_5_image,
      content: (
        <div className='flex flex-col items-center justify-center space-y-4'>
          <CustomOnboardingButton onClick={handleSubscription} className='!text-sm !px-8'>
            Start 20 Day Free Trial 🎉
          </CustomOnboardingButton>
        </div>
      ),
    },
    {
      title: NoviOnboardingSteps.STEP_3,
      description:
        'You’ve just made an amazing investment for your business. We’ll provide you free support forever. Let’s get started!',
      image: step_6_image,
      content: (
        <>
          <CustomOnboardingButton
            onClick={() => {
              handleNext();
              setOpenedOnboarding(false);
              setCreateCampaignTourOpen(true);
            }}
            className='!text-sm !px-8'
          >
            Get Started ⚙️
          </CustomOnboardingButton>
        </>
      ),
    },
  ];

  useEffect(() => {
    if (
      loggedInUser &&
      Object.keys(loggedInUser).length > 0 &&
      !loggedInUser.demo &&
      !tourOpened &&
      !openedOnboarding &&
      !loggedInUser.pendingOnboarding &&
      !(loggedInUser.onboardingSteps?.length === steps.length) &&
      !createCampaignTourOpen
    ) {
      setOpenedOnboarding(true);
    }
  }, [loggedInUser]);

  const baseClass = 'onboarding-container';

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const refresh = params.get('refresh');

    if (refresh === 'true') {
      params.delete('refresh');

      navigate(
        {
          pathname: location.pathname,
          search: params.toString(),
        },
        { replace: true }
      );

      window.location.reload();
    }
  }, [location, navigate]);

  return (
    <div>
      <Modal
        open={openedOnboarding}
        centered
        footer={null}
        closable={[5, 6].includes(currentStep)}
        styles={{
          content: {
            borderRadius: '50px',
          },
        }}
        className={`bg-opacity-50 backdrop-blur-lg ${baseClass}`}
        onClose={() => setOpenedOnboarding(false)}
        onCancel={() => setOpenedOnboarding(false)}
      >
        <div className='flex flex-col items-center p-4 bg-white rounded-lg'>
          {/* Image */}
          {steps[currentStep].image ? (
            <Image
              src={steps[currentStep].image}
              alt={`Step ${currentStep}`}
              preview={false}
              className='w-full !h-[300px] object-cover rounded-[100px]'
            />
          ) : null}
          {/* Title */}
          <Title level={3} className='mt-4 text-center'>
            {steps[currentStep].title}
          </Title>

          {/* Description */}
          {steps[currentStep].description && (
            <Paragraph className='text-center px-6'>{steps[currentStep].description}</Paragraph>
          )}

          {/* Content */}
          <div className='mt-4'>{steps[currentStep].content}</div>

          {/* Pagination Dots */}
          <div className='flex justify-center mt-6'>
            {steps.map((_, index) => (
              <div
                key={index}
                className={`w-3 h-3 rounded-full mx-1 ${currentStep === index ? 'bg-black' : 'bg-gray-300'}`}
              />
            ))}
          </div>
        </div>

        {/* Plans Modal */}
        <NoviPlansModal />
      </Modal>
    </div>
  );
};
