import React from 'react';
import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Calendar, Bell, Link, Phone, MessageSquare, ClipboardList, Database, BarChart2 } from 'lucide-react';
import StepWrapper from './StepWrapper';

interface FeatureSelectorProps {
  stepState: number;
  selectedFeatures: string[];
  setSelectedFeatures: (features: string[]) => void;
  handleStepTransition: (
    nextStep: number,
    validationFn?: () => Promise<boolean>,
    stateMutationFn?: () => Promise<void>
  ) => Promise<void>;
}

const features = [
  {
    label: 'Schedule Appointments',
    Icon: Calendar,
    info: 'Effortlessly book, reschedule, and manage appointments to keep your calendar organized and stress-free.',
    bgColor: 'bg-blue-50',
  },
  {
    label: 'Smart Notifications',
    Icon: Bell,
    info: 'Stay ahead with real-time alerts tailored to your business needs.',
    bgColor: 'bg-yellow-50',
  },
  {
    label: 'API/Zapier',
    Icon: Link,
    info: 'Connect with popular apps using Zapier to automate workflows and simplify tasks.',
    bgColor: 'bg-purple-50',
  },
  {
    label: 'Smart Call Transfer',
    Icon: Phone,
    info: 'Ensure every call reaches the right person with intelligent call routing.',
    bgColor: 'bg-green-50',
  },
  {
    label: 'Smart Texting',
    Icon: MessageSquare,
    info: 'Send personalized messages and follow-ups to leads effortlessly.',
    bgColor: 'bg-pink-50',
  },
  {
    label: 'Lead Qualification',
    Icon: ClipboardList,
    info: 'Assess potential leads using dynamic forms to boost conversions.',
    bgColor: 'bg-orange-50',
  },
  {
    label: 'CRM',
    Icon: Database,
    info: 'Streamline customer management with an intuitive CRM.',
    bgColor: 'bg-cyan-50',
  },
  {
    label: 'Analytics',
    Icon: BarChart2,
    info: 'Gain insights with in-depth analytics to make data-driven decisions.',
    bgColor: 'bg-teal-50',
  },
];

const FeatureSelector: React.FC<FeatureSelectorProps> = ({
  stepState,
  selectedFeatures,
  setSelectedFeatures,
  handleStepTransition,
}) => {
  const handleFeatureClick = (feature: string) => {
    const updatedFeatures = selectedFeatures.includes(feature)
      ? selectedFeatures.filter(item => item !== feature)
      : [...selectedFeatures, feature];
    setSelectedFeatures(updatedFeatures);
  };

  return (
    <StepWrapper handleNext={() => handleStepTransition(stepState + 1)} disableNext={selectedFeatures.length === 0}>
      <div className='flex flex-col items-start w-full'>
        <h3 className='sm:text-3xl text-xl font-bold text-gray-900 text-start mb-0'>Choose your AI features</h3>
        <p className='text-gray-500 sm:text-lg text-sm'>Select the capabilities you want your AI assistant to have</p>
      </div>

      <div className='grid grid-cols-2 gap-4'>
        {features.map(({ label, Icon, info, bgColor }) => (
          <div
            key={label}
            className={`relative rounded-lg cursor-pointer !p-2 transition-all duration-200 border hover:shadow-md ${
              selectedFeatures.includes(label) ? 'ring-2 ring-button-success shadow-sm' : 'hover:border-gray-300'
            }`}
            onClick={() => handleFeatureClick(label)}
          >
            <div className='p-2 flex flex-col items-center justify-center space-y-3'>
              <div className={`p-3 rounded-full ${bgColor}`}>
                <Icon
                  className={`w-4 h-4 ${selectedFeatures.includes(label) ? 'text-button-success' : 'text-gray-600'}`}
                />
              </div>
              <div className='flex items-center space-x-2'>
                <span className='font-semibold sm:text-sm text-xs text-gray-900'>{label}</span>
                <Tooltip title={info}>
                  <InfoCircleOutlined className='text-gray-400 hover:text-gray-600 sm:text-lg text-sm' />
                </Tooltip>
              </div>
            </div>
          </div>
        ))}
      </div>
    </StepWrapper>
  );
};

export default FeatureSelector;
